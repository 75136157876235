import React from "react";

const VectorIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.125 6.875H5.04167V4.125H4.125V6.875ZM4.58333 3.20833C4.71319 3.20833 4.82212 3.16433 4.91012 3.07633C4.99782 2.98864 5.04167 2.87986 5.04167 2.75C5.04167 2.62014 4.99782 2.51121 4.91012 2.42321C4.82212 2.33551 4.71319 2.29167 4.58333 2.29167C4.45347 2.29167 4.34469 2.33551 4.257 2.42321C4.169 2.51121 4.125 2.62014 4.125 2.75C4.125 2.87986 4.169 2.98864 4.257 3.07633C4.34469 3.16433 4.45347 3.20833 4.58333 3.20833ZM4.58333 9.16667C3.94931 9.16667 3.35347 9.04628 2.79583 8.8055C2.23819 8.56503 1.75313 8.23854 1.34063 7.82604C0.928125 7.41354 0.601639 6.92847 0.361167 6.37083C0.120389 5.81319 0 5.21736 0 4.58333C0 3.94931 0.120389 3.35347 0.361167 2.79583C0.601639 2.23819 0.928125 1.75313 1.34063 1.34063C1.75313 0.928125 2.23819 0.601486 2.79583 0.360708C3.35347 0.120236 3.94931 0 4.58333 0C5.21736 0 5.81319 0.120236 6.37083 0.360708C6.92847 0.601486 7.41354 0.928125 7.82604 1.34063C8.23854 1.75313 8.56503 2.23819 8.8055 2.79583C9.04628 3.35347 9.16667 3.94931 9.16667 4.58333C9.16667 5.21736 9.04628 5.81319 8.8055 6.37083C8.56503 6.92847 8.23854 7.41354 7.82604 7.82604C7.41354 8.23854 6.92847 8.56503 6.37083 8.8055C5.81319 9.04628 5.21736 9.16667 4.58333 9.16667Z"
        fill="black"
      />
    </svg>
  );
};

export default VectorIcon;
