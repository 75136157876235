import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useState, useEffect } from "react";

export default function DiscreteSliderValues({
  db,
  firebase,
  currUserId,
  currentUserObj,
  selectedScore,
  selectedLabel,
  setSelectedScore,
  setSelectedLabel,
  setFeedbackSubmitted,
}) {
  const [devFeedback, setDevFeedback] = useState([]);
  const [minScore, setMinScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0);

  useEffect(() => {
    const getDevFeedbackScores = async () => {
      const snapshot = await db
        .doc("/organisations/T6BAcTjwbXleCFpmWTmu")
        .get();
      const feedbackScoresData = snapshot?.data();
      let categories = [];
      if (feedbackScoresData) {
        const devFeedbackData = feedbackScoresData?.devFeedback;
        setDevFeedback(devFeedbackData);
        const scores = devFeedbackData?.map((obj) => obj?.score);
        setMinScore(Math.min(...scores));
        setMaxScore(Math.max(...scores));
      }
    };
    getDevFeedbackScores();
  }, []);

  const handleScoreChange = (event, value) => {
    setFeedbackSubmitted(false);
    setSelectedScore(value);
  };

  const valueLabelFormat = (value) => {
    setSelectedScore(value);
    const feedbackObj = devFeedback?.find((obj) => obj?.score === value);
    setSelectedLabel(feedbackObj ? feedbackObj?.label : "");
    return feedbackObj ? feedbackObj?.label : "";
  };

  return (
    <Box sx={{ width: "98%", margin: "0 auto" }}>
      <Slider
        sx={{
          "& .MuiSlider-track": {
            color: "black",
          },
          "& .MuiSlider-thumb": {
            color: "black",
          },
          "& .MuiSlider-rail": {
            color: "black",
          },
        }}
        value={selectedScore}
        onChange={handleScoreChange}
        step={1}
        min={minScore}
        max={maxScore}
        valueLabelDisplay="on"
        valueLabelFormat={valueLabelFormat}
      />
    </Box>
  );
}
