import { Mixpanel } from "../helpers/Mixpanel";
import React, { useState, useEffect } from "react";

export default function ReportContentIssues({
  isOpen,
  setIsOpen,
  questionFeedback,
  setQuestionFeedback,
  setShowConfirmation,
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isCustomFeedback, setIsCustomFeedback] = useState(false);
  const [customFeedback, setCustomFeedback] = useState(
    "e.g. There's a typo..."
  );

  const options = [
    { id: "1", text: "Nestria did not understand my voice." },
    { id: "2", text: "The feedback is not helpful." },
    { id: "3", text: "The question is poor." },
    { id: "4", text: "The score is incorrect." },
    { id: "custom", text: "Something else went wrong." },
  ];

  const toggleOption = (optionId) => {
    if (selectedOptions.includes(optionId)) {
      if (optionId === "custom") {
        setIsCustomFeedback(false);
      }

      setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
    } else {
      if (optionId === "custom") {
        setIsCustomFeedback(true);
      }
      setSelectedOptions([...selectedOptions, optionId]);
    }

    console.log("Option ID", optionId);
  };

  const submitFeedback = () => {
    const selectedFeedbackTexts = selectedOptions.map((optionId) => {
      const selectedFeedback = options.find((option) => option.id === optionId);
      return selectedFeedback ? selectedFeedback.text : "";
    });

    if (isCustomFeedback) {
      selectedFeedbackTexts.push(customFeedback);
    }

    setQuestionFeedback({
      ...questionFeedback,
      question_feedback: selectedFeedbackTexts.join(", "),
    });

    setShowConfirmation(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 100);

    setSelectedOptions([]);
    setCustomFeedback("");
    setIsCustomFeedback(false);
  };

  useEffect(() => {
    if (questionFeedback && questionFeedback?.question_feedback !== "") {
      console.log(
        "Question feedback multiple options selected",
        questionFeedback
      );
      Mixpanel.track("Question Feedback", questionFeedback);
      setQuestionFeedback((prevQuestionFeedback) => ({
        ...prevQuestionFeedback,
        question_feedback: "",
      }));
      console.log("Mixpanel tracking is done", questionFeedback);
    }
  }, [questionFeedback]);

  return (
    <div style={{ zIndex: 99 }}  className="report-feedback fixed duration-2000 bg-gray-100 rounded-2xl font-bold text-green-600 bottom-2 left-2 right-2">
      <div className="flex max-w-5xl flex-col gap-4 py-4 items-center sm:mx-auto sm:flex-row sm:items-center sm:justify-center sm:py-14">
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="w-full">
            {options.map((option, index) => (
              <p
                key={option.id}
                className={`text-blue-400 py-2 text-left border-b-2 border-gray-200  ${
                  selectedOptions.includes(option.id) ? "bg-blue-200" : ""
                }`}
                onClick={() => toggleOption(option.id)}
              >
                <input
                  type="checkbox"
                  style={{ marginRight: "8px" }}
                  checked={selectedOptions.includes(option.id)}
                  onChange={() => toggleOption(option.id)}
                />
                {option.text}
              </p>
            ))}
            {isCustomFeedback ? (
              <div
                className="text-gray-400 py-2 text-left border-b-2 border-gray-200"
                contentEditable="true"
                onBlur={(e) => setCustomFeedback(e.target.innerText)}
                onFocus={(e) => {
                  if (e.target.innerText === "e.g. There's a typo...") {
                    e.target.innerText = "";
                  }
                }}
                style={{ outline: "none" }}
              >
                {customFeedback}
              </div>
            ) : null}
          </div>
          <div className="w-full flex justify-center gap-4">
            <p
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-400 cursor-pointer"
            >
              Cancel
            </p>
            <p
              onClick={submitFeedback}
              className="text-gray-400 cursor-pointer"
            >
              Submit
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
