import React from 'react';

const InactiveCheckinIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13.5"
        cy="13.5"
        r="13.5"
        fill="#FF3636"
      />
    </svg>
  );
};

export default InactiveCheckinIcon;