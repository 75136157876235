import { useContext, useEffect, useRef, useState } from "react";
import VideoAnswer from "./VideoAnswer";
import { FeedbackSection } from "./FeedbackSection";
import AudioAnswer from "./AudioAnswer";
import VideoRecorder from "../VideoRecorder/Recorder";
import ReportContentIssues from "../Lesson/ReportContentIssues";
import AnswerCard from "./AnswerCard";
import { FirebaseContext } from "../Context/Nestria";

export default function UserAnswerFeed({ userAnswers, setQuitMessageShown, setShowConfirmation, gptAnswer, audioContentBase64, setShowFeed, questionText, nextQuestionElmCtx, currentAnswerTranscript, rawGPTFeedback }) {
 
    const firebase = useContext(FirebaseContext);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);
    const initialQuestionFeedback = {
        unit_name: "",
        question: "",
        user_answer: "",
        gpt_feedback: "",
        question_feedback: "",
    };

    const [questionFeedback, setQuestionFeedback] = useState(
        initialQuestionFeedback
    );

    const swiperElRef = useRef(null);

    function moveToNextQuestion() {
        console.log(`move to next question called...`);
        nextQuestionElmCtx.goToNextQuestion();
        setShowFeed(false);
    }

    useEffect(() => {
        // listen for Swiper events using addEventListener
        swiperElRef.current.addEventListener('swiperslidechange', (e) => {
            console.log('slide changed');
            console.log(e.detail[0].activeIndex);
            setCurrentIndex(e.detail[0].activeIndex);
        });

        const swiperContainer = swiperElRef.current;
        const params = {
            injectStyles: [`.swiper-pagination-bullets{
                display: none;
              }`]
        };

        Object.assign(swiperContainer, params);
        swiperContainer.initialize();

        const horizontalElms = document.getElementsByClassName("horizontal-swiper");
        const horizontalSliderParams = {
            injectStyles: [`.swiper-pagination-bullets{
                display: none;
              }`]
        };

        [...horizontalElms].map((horizontalElm) => {
            horizontalElm.addEventListener('swiperslidechange', (e) => {
                console.log('horizontal slide changed');
                moveToNextQuestion();
            });

            Object.assign(horizontalElm, horizontalSliderParams);
            horizontalElm.initialize();
        });

        console.log(`userAnswers: `, userAnswers);
        console.log(`gptAnswer: `, gptAnswer);

        return () => {
            if (swiperElRef?.current) {
                swiperElRef.current.removeEventListener('swiperslidechange', (e) => {

                });
            }
        }
    }, [userAnswers]);

    function renderSlides(userAnswers) {
        if (userAnswers?.length === 0) {
            return <></>
        } else {
            return (
                userAnswers.map((item, index) => {
                    const { url, firstName, lastName, score, likeCount, submissionDocRef, contentType, textAnswer } = item;

                    return (<swiper-slide>
                        <swiper-container
                            init="false"
                            class="horizontal-swiper"
                            direction="horizontal"
                            pagination="true"
                            short-swipes="true"
                        >
                            <swiper-slide>
                                <AnswerCard
                                    firebase={firebase}
                                    question={questionText}
                                    index={index}
                                    url={url}
                                    currentIndex={currentIndex}
                                    userName={`${firstName} ${lastName}`}
                                    score={score}
                                    likeCount={likeCount}
                                    currentUserId={nextQuestionElmCtx.currentUser?.id}
                                    retakePreviousQuestion={() => setShowFeed(false)}
                                    setShowUserAnswers={setShowFeed}
                                    setIsFeedbackPopupOpen={setIsFeedbackPopupOpen}
                                    submissionDocRef={submissionDocRef}
                                    contentType={contentType}
                                    correctAnswer={textAnswer}
                                />
                            </swiper-slide>

                            <swiper-slide>
                                <VideoRecorder
                                    currentUser={nextQuestionElmCtx.currentUserObj}
                                    firebase={nextQuestionElmCtx.firebase}
                                    setQuitMessageShown={nextQuestionElmCtx.setQuitMessageShown}
                                    quitMessageShown={nextQuestionElmCtx.quitMessageShown}
                                    maxVideoAnswerLengthInSeconds={
                                        nextQuestionElmCtx.selectedQuestions[nextQuestionElmCtx.questionIndex + 1]?.maxVideoAnswerLengthInSeconds
                                    }
                                    questionText={nextQuestionElmCtx.selectedQuestions[nextQuestionElmCtx.questionIndex + 1]?.question}
                                    question={nextQuestionElmCtx.selectedQuestions[nextQuestionElmCtx.questionIndex + 1]}
                                    onContinue={nextQuestionElmCtx.onContinue}
                                    onSubmit={nextQuestionElmCtx.onSubmit}
                                    isAnswerSubmitted={nextQuestionElmCtx.isAnswerSubmitted}
                                    hasReachedMaxSubmissions={nextQuestionElmCtx.hasReachedMaxSubmissions}
                                    setShowSnackBar={nextQuestionElmCtx.setShowSnackbar}
                                    snackBarRoutingSeverity={nextQuestionElmCtx.snackbarSeverity}
                                    snackBarRoutingMsg={nextQuestionElmCtx.snackbarMsg}
                                    isVisible={nextQuestionElmCtx.showUserAnswersFeed}
                                />
                            </swiper-slide>
                        </swiper-container>
                    </swiper-slide>)
                })
            )
        }
    }

    function renderNestriaAnswerSlide() {
        return (<swiper-slide>
            <swiper-container
                init="false"
                class="horizontal-swiper"
                direction="horizontal"
                pagination="true"
                short-swipes="true"
            >
                <swiper-slide>
                    <AudioAnswer
                        question={questionText}
                        audioContentBase64={audioContentBase64}
                        index={userAnswers?.length}
                        currentIndex={currentIndex}
                        userName={"Nestria"}
                        likeCount={10}
                        retakePreviousQuestion={() => setShowFeed(false)}
                        setIsFeedbackPopupOpen={setIsFeedbackPopupOpen}
                        setShowUserAnswers={setShowFeed}
                        correctAnswer={gptAnswer} />
                </swiper-slide>

                <swiper-slide>
                    <VideoRecorder
                        currentUser={nextQuestionElmCtx.currentUserObj}
                        firebase={nextQuestionElmCtx.firebase}
                        setQuitMessageShown={nextQuestionElmCtx.setQuitMessageShown}
                        quitMessageShown={nextQuestionElmCtx.quitMessageShown}
                        maxVideoAnswerLengthInSeconds={
                            nextQuestionElmCtx.selectedQuestions[nextQuestionElmCtx.questionIndex + 1]?.maxVideoAnswerLengthInSeconds
                        }
                        questionText={nextQuestionElmCtx.selectedQuestions[nextQuestionElmCtx.questionIndex + 1]?.question}
                        question={nextQuestionElmCtx.selectedQuestions[nextQuestionElmCtx.questionIndex + 1]}
                        onContinue={nextQuestionElmCtx.onContinue}
                        onSubmit={nextQuestionElmCtx.onSubmit}
                        isAnswerSubmitted={nextQuestionElmCtx.isAnswerSubmitted}
                        hasReachedMaxSubmissions={nextQuestionElmCtx.hasReachedMaxSubmissions}
                        setShowSnackBar={nextQuestionElmCtx.setShowSnackbar}
                        snackBarRoutingSeverity={nextQuestionElmCtx.snackbarSeverity}
                        snackBarRoutingMsg={nextQuestionElmCtx.snackbarMsg}
                        isVisible={nextQuestionElmCtx.showUserAnswersFeed}
                    />
                </swiper-slide>
            </swiper-container>
        </swiper-slide>)
    }

    return (
        <>
            <swiper-container
                init={"false"}
                ref={swiperElRef}
                slides-per-view={"1"}
                pagination={"true"}
                direction={"vertical"}
                show-pagination={"false"}
                style={{ height: "100vh", width: "100vw", transition: "all 1s ease-out" }}>

                {renderSlides(userAnswers)}
                {renderNestriaAnswerSlide()}
                <swiper-slide>
                    <FeedbackSection
                        setQuitMessageShown={setQuitMessageShown}
                        currentUserObj={nextQuestionElmCtx.currentUser}
                        rawGPTFeedback={rawGPTFeedback}
                        questionText={questionText}
                        loading={false}
                        questionLength={120}
                        onContinue={() => moveToNextQuestion()}
                        currentAnswerTranscript={currentAnswerTranscript}
                        setIsFeedbackPopupOpen={setIsFeedbackPopupOpen}
                        retakePreviousQuestion={() => setShowFeed(false)}
                        setShowUserAnswers={setShowFeed}
                    />
                </swiper-slide>
            </swiper-container>

            {isFeedbackPopupOpen && (
                <ReportContentIssues
                    isOpen={isFeedbackPopupOpen}
                    setIsOpen={setIsFeedbackPopupOpen}
                    questionFeedback={questionFeedback}
                    setQuestionFeedback={setQuestionFeedback}
                    setShowConfirmation={setShowConfirmation}
                />
            )}
        </>
    )

};