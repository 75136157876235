import React, { useEffect, useState } from "react";

export const CourseHeader = ({ courseNumber, courseName, courseType }) => {
    const [isOrientationCourse, setIsOrientationCourse] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState<string | undefined>(undefined);
    const [pillText, setPillText] = useState<string | null>(null);
    const [formattedCourseName, setFormattedCourseName] = useState(courseName);
  
    useEffect(() => {
      const courseNameLowerCase = courseName.toLowerCase();
      if (courseNameLowerCase === "orientation") {
        setBackgroundColor(`bg-[#58cc02]`); // Green
        setIsOrientationCourse(true);
        setFormattedCourseName(courseName);
      } else {
        if (courseType === "assessment") {
          setBackgroundColor(`bg-[#0a4a82]`); // Set the assessment color to blue
        } else {
          setBackgroundColor(`bg-[#58cc02]`); // Green
        }
        setIsOrientationCourse(false);
      }
  
      // Set the pill text
      // Format the course name if needed
      if (courseNameLowerCase.indexOf("practice") !== -1) {
        setPillText("Practice");
        let _formattedCourseName = formatCourseName(courseName, "Practice");
        setFormattedCourseName(_formattedCourseName);
      }
  
      if (courseNameLowerCase.indexOf("final") !== -1) {
        setPillText("Assessment");
        let _formattedCourseName = formatCourseName(courseName, "Final");
        setFormattedCourseName(_formattedCourseName);
      }
    }, [courseName]);
  
    return (
      <article
        style={{ backgroundColor: backgroundColor }}
        className={["max-w-2xl text-white sm:rounded-xl", backgroundColor].join(
          " "
        )}
      >
        <header className="flex items-center justify-between gap-4 p-4">
          <div className="flex flex-col gap-1" style={{ width: "100%" }}>
            <h2 className="text-2xl font-bold">{`Unit ${courseNumber}`}</h2>
            <div className="flex justify-between gap-2">
              <h3 className="text-xl">{formattedCourseName}</h3>
              {courseType === "assessment" &&
                !isOrientationCourse &&
                pillText && (
                  <span
                    className="bg-white py-1 px-3 rounded-full"
                    style={{ height: "fit-content", color: "black" }}
                  >
                    {pillText}
                  </span>
                )}
            </div>
            {/* <h3 className="text-xl">{courseName}</h3> */}
          </div>
        </header>
      </article>
    );
  };

export const formatCourseName = (courseName, type: "Final" | "Practice"):string => {
  return courseName
          .replace(type, "")
          .replace(/[0-9]/g, "").trimEnd();
} 