import { createContext } from "react";
import { AssessmentModeProps, Auth, CurrentUser, Db, Firebase } from "./Types";

export const AuthContext = createContext<null | Auth>(null);

export const CurrentUserContext = createContext<null | CurrentUser>(null);

export const DbContext = createContext<null | Db>(null);

export const FirebaseContext = createContext<null | Firebase>(null);

export const AssessmentModeContext = createContext(<AssessmentModeProps>{
    mode: localStorage.getItem("activeMode") ?? "Practice",
    setMode: () => { },
    activeSubject: localStorage.getItem("activeCourse") ?? "Orientation",
    setActiveSubject: () => { }
})