async function getGeoLocation() {
  //Ask for geoLoc access & return user's lat&long
  // Try HTML5 geolocation.
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const pos = {
          ts: Date.now(),
          status: "Location found",
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        return pos;
      },
      () => {
        const pos = {
          ts: Date.now(),
          status: "Error",
        };
        return pos;
      }
    );
  } else {
    // Browser doesn't support Geolocation (or access not granted)
    const pos = {
      ts: Date.now(),
      status: "Not granted",
    };
    return pos;
  }
}

export default getGeoLocation;
