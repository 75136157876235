/**
 * Saves key value pairs to localStorage. Includes `validTill` field
 * from x minutes from the time of saving the key value pair.
 * For example, if the key is 'isDarkMode'
 *  value is true
 *  and x is 10
 * The value gets stored in the localStorage as follows
 * 'isDarkMode' : { 'validTill': 1714060520606, 'value': true}
 * 
 * @param {String} key 
 * @param {String | Boolean | Number} value 
 * @param {Number} x - Number of minutes
 * @returns 
 */
export const saveStateForXMinutes = (key, value, x) => {
    try {
        if (!key || value === null || value === undefined || value === '') {
            throw Error();
        }

        if (!x || typeof(x) !== 'number' || x <= 0) {
            throw Error();
        }

        const now = new Date();

        const validTill = now.setMinutes(now.getMinutes() + x);

        return localStorage.setItem(key, JSON.stringify({ validTill, value }));
    } catch (e) {
        return;
    }
}

export const getState = (key) => {
    try {
        if (!key) {
            throw Error();
        }

        const record = localStorage.getItem(key);

        if (!record) {
            return;
        }

        const { validTill, value } = JSON.parse(record);

        if (!validTill || value === null || value === undefined || value === '') {
            return;
        }

        // Check if the value is valid
        const now = new Date();

        if (validTill >= now.getTime()) {
            return value;
        }

        return null;
    } catch (e) {
        
    }
}

export const clearState = (key) => {
    try {
        if (!key) {
            throw Error();
        }
        
        return localStorage.removeItem(key);
    } catch (e) {
        return e;
    }
} 