export interface QuestionType {
    id: string,
    unitId: string,
    originalUnitID: string,
    originalQuestionID: string,
    courseName: string,
    courseID: string,
    courseType: string,
    type: string,
    question: string,
    description: string,
    tileRef: object,
    tileObject: {
        isAnsweredInVideo?: boolean,
        questionData: {
            type: string,
        }
    },
    isSkipped: boolean,
    isAnswered: boolean,
    skippable: boolean,
    maxSubmissions: number,
    videoAnswerRequired: boolean,
    maxVideoAnswerLengthInSeconds: number,
    xp: number,
    index: number,
    routingType: string,
    courseSubject: string,
    isCourseFinalAssessment: boolean,
};

export interface UnitType {
    id: string,
    unitNumber: number,
    courseName: string,
    courseID: string,
    courseType: string,
    unitName: string,
    textColor: string,
    borderColor: string,
    backgroundColor: string,
    unitRef: object,
    unitIndex: number,
    type: string,
    isSkipped: boolean,
    isAnswered: boolean,
    xp: number,
    unitQuestions: Array<QuestionType>,
    courseSubject: string,
    isCourseFinalAssessment: boolean,
};

export const createUnit = (
    units,
    unitID,
    courseName,
    courseID,
    courseType,
    unitName,
    assignedUnitDoc,
    unitIndex,
    unitQuestions,
    courseSubject,
    isCourseFinalAssessment
): UnitType => {
    return {
        id: unitID,
        unitNumber: units.length + 1,
        courseName: `${courseName}`,
        courseID,
        courseType,
        unitName: `${unitName}`,
        textColor: "text-[#58cc02]",
        borderColor: "border-[#46a302]",
        backgroundColor: "bg-[#58cc02]",
        unitRef: assignedUnitDoc?.ref,
        unitIndex: unitIndex,
        type: "star",
        isSkipped: false,
        isAnswered: false,
        xp: 10 * Number(unitQuestions?.length),
        unitQuestions: unitQuestions,
        courseSubject,
        isCourseFinalAssessment,
    };
};

export const createQuestion = (
    questionRef,
    questionDocData,
    unitID,
    courseName,
    courseID,
    courseType,
    tileType,
    originalUnitID,
    originalQuestionID,
    courseSubject,
    isCourseFinalAssessment
): QuestionType => {
    const question = {
        id: questionRef?.id,
        unitId: unitID,
        originalUnitID,
        originalQuestionID,
        courseName,
        courseID,
        courseType,
        type: tileType,
        question: questionDocData?.questionData?.question,
        description: questionDocData?.questionData?.question,
        tileRef: questionRef,
        tileObject: questionDocData,
        isSkipped: questionDocData?.isQuestionSkipped,
        isAnswered: questionDocData?.isAnswered,
        skippable: questionDocData?.questionData?.skippable,
        maxSubmissions: questionDocData?.questionData?.maxSubmissions,
        videoAnswerRequired: questionDocData?.questionData?.videoAnswerRequired,
        maxVideoAnswerLengthInSeconds:
            questionDocData?.questionData?.maxVideoAnswerLengthInSeconds,
        xp: questionDocData?.xp,
        index: questionDocData?.index || -1,
        routingType: questionDocData?.questionData?.routing_type || "",
        courseSubject,
        isCourseFinalAssessment,
    };
    return question;
};