import { Flag, Like, Retake } from "./Svg";

export default function Controls({ hideLike, likeHandler, flagHandler, retakeHandler, likeCount, isLiked }) {
    return (
        <>
            {/* Controls */}
            <div
                onClick={(e) => { console.log(`controls clicked`); e.stopPropagation() }}
                style={{ display: "flex", flexDirection: "column", gap: "25px", position: "absolute", bottom: "18vh", right: "10px" }}
            >

                {hideLike !== true &&
                    <div style={{ textAlign: "center" }}>
                        <Like isLiked={isLiked} onClickHandler={likeHandler} />
                        <span style={{ fontSize: "12px", visibility: likeCount > 0 ? "initial" : "hidden" }}>{likeCount || 0}</span>
                    </div>
                }

                <Flag onClickHandler={flagHandler} />

                <Retake onClickHandler={retakeHandler} />
            </div>
        </>
    )
}