import React from "react";
import ReportConfirmation from "../ReportConfirmation";
import QuestionHeader from "../QuestionHeader";
import CheckAnswer from "../CheckAnswer";

export const MiscQuestion = ({
    showConfirmation, 
    setShowConfirmation, 
    lessonComplete, 
    questionDesc, 
    setQuitMessageShown, 
    onSkip, 
    onContinue, 
    onSubmit, 
    onLessonComplete, 
    question,
    message,
    isAnswerSubmitted,
    isSubmittingAnswer,
    questionType,
    questionFeedback,
    setQuestionFeedback,
    hasReachedMaxSubmissions,
    isFinalAssessmentError
}) => {
    return (
        <div className="flex min-h-screen flex-col gap-5 px-4 spy sm:px-0 sm:py-0">
            <div className="flex grow flex-col items-center gap-5">
                <div className="flex w-full h-fit max-h-lesson-header max-w-5xl sm:mt-8 sm:px-5 w-full">
                    {showConfirmation && (
                        <ReportConfirmation
                            setShowConfirmation={setShowConfirmation}
                        />
                    )}
                </div>
                <div>
                    <div className="flex w-full h-fit max-h-lesson-header max-w-5xl sm:mt-8 sm:px-5">
                        {!lessonComplete && (
                            <QuestionHeader
                                question={questionDesc}
                                setQuitMessageShown={setQuitMessageShown}
                            />
                        )}
                    </div>
                    <div className="flex flex-col w-full max-w-5xl sm:mt-8 sm:px-5 h-full">
                        {!lessonComplete && (
                            <CheckAnswer
                                onSkip={onSkip}
                                onContinue={onContinue}
                                onSubmit={onSubmit}
                                onLessonComplete={onLessonComplete}
                                question={question}
                                lessonComplete={lessonComplete}
                                message={message}
                                isAnswerSubmitted={isAnswerSubmitted}
                                isSubmittingAnswer={isSubmittingAnswer}
                                questionType={questionType}
                                questionFeedback={questionFeedback}
                                setQuestionFeedback={setQuestionFeedback}
                                setShowConfirmation={setShowConfirmation}
                                hasReachedMaxSubmissions={hasReachedMaxSubmissions}
                                isFinalAssessmentError={isFinalAssessmentError}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}