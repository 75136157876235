import React, { useEffect, useState } from 'react';
import OptionsIcon from '../svgs/OptionsIcon';

const Channel = ({
  channelRef,
  chatObj,
  setChatObj,
  truncateString,
  setAddUserBtn,
  setShowGroupChatSection,
  setShowMessageSection,
  optionsModal,
  db,
  userId,
  windowSize
}) => {
  const [channelData, setChannelData] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [updatedChannelData, setUpdatedChannelData] = useState({});
  const [docChangeType, setDocChangeType] = useState('none');
  const [channelLastMessageData, setChannelLastMessageData] = useState(null);

  // streams channelRef (the channels) which came in from ChatGroup component
  useEffect(() => {
    const unsubscribe = channelRef.onSnapshot((doc) => {
      if (doc.exists) { // check if doc exists
        const data = doc?.data();
        const channelRef = doc?.ref;
        const dataObj = { ...data, ref: channelRef };
        setChannelData(dataObj);

        // filter the unread messages to get only those that belong to the current channel
        const unreadMsgs = unreadMessages.filter(msg => msg.chatGroupRef?.id === channelData?.ref?.id);

        // add the unread messages as a new property to the channelData object
        const channelDataWithUnreadMsgs = {
          ...channelData,
          unreadMsgs: unreadMsgs.length ? unreadMsgs : null,
        };

        // makes a deep copy of the channel data combined with unread messages
        const updatedChannelDataWithUnreadMsgs = {
          ...channelDataWithUnreadMsgs
        };

        // check if the channelDataWithUnreadMsgs.ref.id matches the channelLastMsgData.channelReference.id
        if (channelDataWithUnreadMsgs?.ref?.id === channelLastMessageData?.channelReference?.id) {
          // add channelLastMessageData object to the updated object
          updatedChannelDataWithUnreadMsgs.channelLastMsgData = channelLastMessageData;
        }

        // setUpdatedChannelData(prevState => ({...prevState, ...updatedChannelDataWithUnreadMsgs}));
        setUpdatedChannelData(updatedChannelDataWithUnreadMsgs);

      };
    });

     // Add a listener for child_removed events to the parent collection
     const parentCollectionRef = channelRef.parent;
     const unsubscribeParent = parentCollectionRef.onSnapshot((snapshot) => {
       snapshot.docChanges().forEach((change) => {
         if (change?.type === 'removed' && change?.doc?.id === channelRef?.id) {
           // If the deleted channel is the current channel, reset the chatObj state
           if (chatObj?.ref?.id === channelRef?.id) {
             setChatObj(null);
           }
           setChannelData(null);
         }
       });
     });

     return () => {
      unsubscribe();
      unsubscribeParent();
    };
  }, [channelRef, channelLastMessageData]);

  // listening to channel msgs for last messages and unread msg counts
  useEffect(() => {
    const getChannelLastMsgData = () => {
      const messagesRef = db.collection(`${channelRef?.path}/msg`).orderBy("ts", "desc").limit(50);
      let allUnreadMessages = new Map();
      let channelLastMsgData = {};
      let channelMsgRef = {};
      let channelLastMsg = {};
  
      const msgsRes = messagesRef?.onSnapshot(async (snapshot) => {
        // loops through each document snapshot and
        // retrieves every message not sent by the current user whose received key is false
        snapshot.docs.forEach((doc) => {
          const msgData = doc?.data();
          const msgId = doc?.ref?.id;
          const chatGroupRef = doc?.ref?.parent?.parent;
  
          const authorId = msgData.author.id;
          if (!msgData?.received && authorId !== userId) {
            const msg = {
              author: authorId,
              msg: msgData.msg,
              file: msgData.file,
              received: msgData.received,
              ts: msgData.ts,
              msgId: msgId,
              chatGroupRef: chatGroupRef
            };
            // retrieves an existing list of unread messages for the current chat 
            // member (specified by authorId), or creates an empty array if no msg exist yet. 
            const existingUnreadMsgs = allUnreadMessages.get(authorId) || [];
            // adds the new msg to the end of existingUnreadMsgs array and updates the allUnreadMessages map with the new array.
            allUnreadMessages.set(authorId, [...existingUnreadMsgs, msg]);
          }
        });
        // extracts Map vals and flattens the array into a single array and stores the vals in unreadMsgs
        let unreadMessages = Array.from(allUnreadMessages.values()).flat();
  
        // removes dupliactes message data obj (msgDataObj) in unreadMessages variable
  
        unreadMessages = unreadMessages.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.msgId === value.msgId
          ))
        );
  
        snapshot.docChanges().forEach(async (msgChange) => {
          if (msgChange.type === 'modified') {
            setDocChangeType('modified');
            const modifiedMsgRef = msgChange?.doc?.ref;
            const authorId = msgChange?.doc?.data()?.author?.id;
        
            // remove all read messages from a specific author from the unreadMessages array
            unreadMessages = unreadMessages?.filter((msg) => {
              return msg?.author !== authorId;
            });
        
            // remove the read message from the corresponding array in the allUnreadMessages map
            const existingUnreadMsgs = allUnreadMessages.get(authorId) || [];
            const updatedUnreadMsgs = existingUnreadMsgs.filter((msg) => {
              return msg?.msgId !== modifiedMsgRef?.id;
            });
            // updates allUnreadMessages map with the updated (filtered) unread msgs
            allUnreadMessages.set(authorId, updatedUnreadMsgs);
        
            // update the state with the updated array
            setUnreadMessages(unreadMessages);
          } else if (msgChange.type === 'removed') {
            setDocChangeType('removed');
            const deletedMsgRef = msgChange?.doc?.ref;
            unreadMessages = unreadMessages?.filter(msg => {
              return msg?.msgId !== deletedMsgRef?.id;
            });
        
            // remove the deleted message from the corresponding array in the allUnreadMessages map
            const authorId = msgChange?.doc?.data()?.author?.id;
            const existingUnreadMsgs = allUnreadMessages.get(authorId) || [];
            const updatedUnreadMsgs = existingUnreadMsgs.filter((msg) => {
              return msg?.msgId !== deletedMsgRef?.id;
            });
            // updates allUnreadMessages map with the updated (filtered) unread msgs
            allUnreadMessages.set(authorId, updatedUnreadMsgs);
        
            setUnreadMessages(unreadMessages);
          } else {
            setDocChangeType('added');
          };
        });
        setUnreadMessages(unreadMessages);
  
        // channel last msg document
        const channelLastMsgDoc = snapshot?.docs[0];
        const channelMsgData = channelLastMsgDoc?.data();
        const channelReference = channelLastMsgDoc?.ref?.parent?.parent;
        channelMsgRef =  channelLastMsgDoc?.ref;
  
        // sets last message to email subject if the last chat is an email
        // then last message becomes the last msg if the last chat isn't an email
        if (channelMsgData?.message) {
          channelLastMsg = channelMsgData?.message?.subject;
        } else {
          channelLastMsg = channelMsgData?.msg;
        };
        // saves the author of a specific message in a specific channel
         const channelMsgAuthorRef = channelMsgData?.author;
         const channelMsgAuthorDoc = await channelMsgAuthorRef?.get();
         const channelMsgAuthorData = channelMsgAuthorDoc?.data();
         
        channelLastMsgData = {
          ...channelMsgData,
          channelMsgRef,
          channelReference,
          channelLastMsg,
          channelMsgAuthorData,
          channelMsgAuthorRef
        };
  
        setChannelLastMessageData(channelLastMsgData);
      })
      
      return msgsRes;
    };
  
    // in order to pevent unnecessary re-rendering,
    // run getChannelLastMsgData() only when updatedChannelData has no ref property || when updatedChannelData is empty
    if ((!updatedChannelData?.ref || !updatedChannelData) ||
        docChangeType === 'modified' ||
        docChangeType === 'removed' ||
        docChangeType === 'added') {
      getChannelLastMsgData();
    };
    setDocChangeType('none');
}, [channelRef, windowSize, updatedChannelData, docChangeType]);


  // highlights selected channel from the active chat group
  // moves to the message/conversation section
  const selectChannel = channel => {
    setChatObj(channel);
    setAddUserBtn(true);
    setShowGroupChatSection(false);
    setShowMessageSection(true);
  };

  return (
    <div className='channel_comp_container'>
      {updatedChannelData &&
      <div className='channels'>
        <div
          className={`channel_data ${chatObj?.ref?.id === updatedChannelData?.ref?.id ?
            'channel_data_active'
            :
            'channel_data_inactive'}`
          }
          onClick={() => {
            if (updatedChannelData?.groupName) {
              selectChannel(updatedChannelData);
            }
          }}
          >
          {/* {windowSize <= 1040 ?
            <> */}
              {updatedChannelData && updatedChannelData.ref?.id === undefined ?
                <p>Loading...</p>
                :
                <p>
                  {`# ${truncateString(updatedChannelData?.groupName, 30)}`}
                  {' '}
                  {updatedChannelData?.channelLastMsgData?.channelLastMsg && 
                    (updatedChannelData?.channelLastMsgData?.channelMsgAuthorData?.first ||
                      updatedChannelData?.channelLastMsgData?.channelMsgAuthorData?.last) && (
                    <span
                      className='channel_last_msg_data'
                    >
                      
                      {truncateString(updatedChannelData?.channelLastMsgData?.channelLastMsg, 20)}{' '} 
                      {updatedChannelData?.channelLastMsgData?.channelMsgAuthorRef?.id !== userId &&
                        `from ${updatedChannelData?.channelLastMsgData?.channelMsgAuthorData?.first}`
                      }
                    </span>
                  )}
                </p>
              }
            {/* </>
            :
            <>
              {updatedChannelData && updatedChannelData.ref?.id === undefined ?
                <p>Loading...</p>
                :
                <p>{`# ${truncateString(updatedChannelData?.groupName, 15)}`}</p>
              }
            </>
          } */}
        </div>
        <div className='options_unread_container'>
          {updatedChannelData?.unreadMsgs?.length > 0 &&
            <span className='unread_msg'>{updatedChannelData?.unreadMsgs?.length}</span>
          }
        </div>
      </div>
      }
    </div>
  );
}

export default Channel;
