import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const MyChart = ({ presenceData, misssingClockOutData }) => {
  const getColor = (bar) => {
    const indexValue = bar.indexValue;
    const result = misssingClockOutData.includes(indexValue);
    return result ? `#F95E2F` : `#D9D9D9`;
  };
  const sortedData = presenceData.sort((a, b) => new Date(a.id) - new Date(b.id));

  
  return (
    <ResponsiveBar
      data={sortedData}
      keys={["value"]}
      indexBy="id"
      padding={0.1}
      colors={getColor}
      axisBottom={null}
      axisLeft={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      gridYLines={false}
      enableGridY={false}
      enableLabel={false}
    />
  );
};

export default MyChart;