import React, { useState, useEffect } from "react";
import logo from "../../static/nestria/astronaut.webp";

export default function PWAInstallPrompt() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isPwaInstalled, setIsPwaInstalled] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      console.log(`beforeInstallPrompt event`);
      setDeferredPrompt(event);
    });
  }, []);

  const handleInstallClick = () => {
    console.log(`Install Handler Called...`);
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the PWA installation");
          setIsPwaInstalled(true)
        } else {
          console.log("User dismissed the PWA installation");
        }
        setDeferredPrompt(null);
      });
    }
    window.addEventListener('appinstalled', () => {
      // If visible, hide the install promotion
      setPopupOpen(false)
      // Log install to analytics
      console.log('INSTALL: Success');
    });
  };
  const [isPopupOpen, setPopupOpen] = useState(true);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  if (deferredPrompt) {
    return (
        <div className={`pwa-install-popup ${isPwaInstalled ? 'hidden' : ''}`}>
        <div className={`bottom-popup ${isPopupOpen ? "open" : ""}`}>
            <div className="bottom-popup-content">
            <button className="close-button" onClick={togglePopup}>
                <span>X</span>
            </button>
            <div className="bottom-popup-inside-content">
                <div className="popup-inside-content">
                <img src={logo} alt="Nestria Logo" height={40} width={50} />
                <div className="popup-inside-content-text">
                    <h1 className="inside-content-title">Nestria</h1>
                    <p className="inside-content-text">Install app for quick access.</p>
                </div>
                <div className="pwa-install-button">
                    <button onClick={handleInstallClick}>Install</button>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    );
  } else {
    return <></>
  }
}
