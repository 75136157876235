import { useEffect } from "react";

export default function ReportConfirmation({ setShowConfirmation }) {
  useEffect(() => {
    setTimeout(() => {
      setShowConfirmation(false);
    }, 2000);
  }, []);
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-10">
      <header className="flex justify-center items-center bg-lime-100 font-bold text-green-600 gap-4 p-2 w-full">
        <p className="text-center">Thanks! Your feedback has been recorded.</p>
      </header>
    </div>
  );
}
