import React, { useContext, useEffect, useRef, useState } from 'react';
import OratioContext from '../../oratioContext/OratioContext';
import SearchBox from '../searchBox/SearchBox';
import DelChannelIcon from '../svgs/DelChannelIcon';
import EditIcon from '../svgs/EditIcon';
import DeleteGroupModal from './DeleteGroupModal';
import ErrorMsgModal from './ErrorMsgModal';

const NewGroupModal = ({
  header,
  truncateString,
  setNewGroupModal,
  db,
  userId,
  emu,
  chatObj,
  firebase,
  isCreateGroup,
  setIsCreateGroup,
  isAddChannel,
  setIsAddChannel,
  isEditChatGroup,
  setIsEditChatGroup,
  isPublic
}) => {
  const [isOn, setIsOn] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isErrorMsgModal, setIsErrorMsgModal] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  // const [isPublic, setIsPublic] = useState(chatObj?.public);
  const [isDeleteChatGroup, setIsDeleteChatGroup] = useState(false);
  const [inputVal, setInputVal] = useState({
    groupName: '',
    description: '',
    members: []
  });

  const {
    userConnections,
    searchTerm,
    setSearchTerm,
    addedUserRefs,
    setAddedUserRefs,
    addedUsers,
    setAddedUsers,
    filteredResults,
    setFilteredResults
  } = useContext(OratioContext);

  const modalContainerRef = useRef(null);
  const searchResultsOpenRef = useRef(false);
  
  // checks if an active chat group is public
  // const isPublic = chatObj?.public;

  useEffect(() => {
    setIsOn(isPublic !== false);
  }, [isPublic]);

  // sets the toggle button on/off
  // const handleToggle = () => {
  //   if (isPublic || isPublic === undefined) {
  //     setIsOn(!isOn);
  //   }
  // };

  // sets the toggle button on/off
  const handleToggle = () => {
    setIsOn(!isOn);
  };  

  // function to handle search term changes
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value?.toLowerCase();

    // filter the   userConnections based on the new search term
    const newFilteredResults =   userConnections.filter(
      (item) =>
        item?.first?.toLowerCase()?.startsWith(newSearchTerm) ||
        item?.last?.toLowerCase()?.startsWith(newSearchTerm) ||
        item?.email?.toLowerCase()?.startsWith(newSearchTerm)
    );

    // update the state with the new search term and filtered results
    setSearchTerm(newSearchTerm);
    setFilteredResults(newSearchTerm === '' ? [] : newFilteredResults);
  };

  // prefills input fields on edit
  useEffect(() => {
    if (isEditChatGroup) {
      setInputVal({
        groupName: chatObj?.groupName,
        description: chatObj?.description
      });
      setAddedUserRefs(chatObj?.members);
    };
  }, []);

  // Listen for changes to the `addedUserRefs` state variable
  useEffect(() => {
    // Define a helper function to fetch user data
    const fetchEditedGroupMembers = async () => {
      const editedMemsData = [];
      for (const memberRef of addedUserRefs) {
        // Use the `get()` method of the Firebase `DocumentReference` object to fetch the user document
        const memberDoc = await memberRef?.get();
        if (memberDoc.exists) {
          // Extract the necessary data from the user document
          const memData = memberDoc?.data();
          const memRef = memberDoc?.ref;
          const memId = memberDoc?.ref?.id;
          const memDataObj = { ...memData, ref: memRef, id: memId };
          editedMemsData.push(memDataObj);
        }
      }
      // Update the `addedUsers` state variable with the fetched user data
      setAddedUsers(editedMemsData);
    };

    fetchEditedGroupMembers();
  }, [addedUserRefs]);

  // handles input change for groupName and description
  const handleInputChange = e => {
    const { name, value } = e.target;
    setInputVal(prevState => ({ ...prevState, [name]: value }));
  };

  const handleKeyDown = () => {
    setIsTyping(true);
  };

  const handleKeyUp = () => {
    setIsTyping(false);
  };

  // creates new chat group from the new group pop modal
  const createChatGroup = async () => {
    if (inputVal?.groupName === '') {
      setErrorMsg('Group name field cannot be empty.');
      setIsErrorMsgModal(true);
    } else if (!isOn && addedUsers?.length === 0) {
      setErrorMsg('Add at least one group member.');
      setIsErrorMsgModal(true);
    } else {
      // defines the new chat group ref
      let newChatDocRef;
  
      // creates a new chat group if isCreateGroup & isAddChannel is true
      if (isCreateGroup || isAddChannel) {
        const currentUserRef = db.doc(`/users/${userId}`);
        addedUserRefs.push(currentUserRef);
    
        // gets rid of duplicates user refs
        const uniqueUserRefs = addedUserRefs.filter((obj, index, self) => {
          return index === self.findIndex((t) => (
            t.id === obj.id
          ));
        });
    
        const chatGroupData = {
          groupName: inputVal?.groupName,
          description: inputVal?.description,
          members: uniqueUserRefs,
          ...(isOn ? { public: true } : { public: false }), // adds & sets public key to true if the intended group is public
          ...(isAddChannel ? { isChannel: true } : { isChannel: false }), // adds & sets isChannel key to true if it's a channel
          createdOn: new Date()
        };
    
        newChatDocRef = await db.collection('/chats').add(chatGroupData);
      };
  
      // checks if isAddChannel state variable is true
      // adds reference(s) of the created group(s) to channels field of the active chat group
      if (isAddChannel) {
        // Get reference to the known chat doc and add the newly created chat doc as a channel
        const existingChatDocRef = chatObj?.ref;
    
        const groupChannelsData = {
          channels: firebase.firestore.FieldValue.arrayUnion(newChatDocRef)
        };
        await existingChatDocRef.set(groupChannelsData, { merge: true });
      };
  
      // edits the chat group if isEditChatGroup is true
      if (isEditChatGroup) {
        const editChatGroupData = {
          groupName: inputVal?.groupName,
          description: inputVal?.description,
          members: addedUserRefs,
          ...(isOn ? { public: true } : { public: false }), // adds & sets public key to true if the intended group is public
          updatedOn: new Date()
        };
  
        await chatObj?.ref.set(editChatGroupData, { merge: true });
      };
      
      setInputVal({
        groupName: '',
        description: ''
      });
      setAddedUserRefs([]);
      setAddedUsers([]);
      setNewGroupModal(false);
    };
  };

  const deselectUser = (user) => {
    const updatedUserRefs = addedUserRefs.filter(item => item?.id !== user?.id);
    setAddedUserRefs(updatedUserRefs);

    const updatedUsers = addedUsers.filter(item => item?.id !== user?.id);
    setAddedUsers(updatedUsers);
  };

  // clears search input and hide search results dropdown
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // sets searchTerm & filteredResults to '' & [] respectively if outside the search results dropdown is clicked &&
      // does not set searchTerm & filteredResults to '' & [] respectively if the search results dropdown itself is clicked
      if (modalContainerRef.current && modalContainerRef.current.contains(event.target) && !searchResultsOpenRef.current) {
        setSearchTerm('');
        setFilteredResults([]);
      };
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [modalContainerRef]);

  // handles clicks on the search results dropdown
  const handleSearchResultsClick = () => {
    searchResultsOpenRef.current = true;
  };

  // handles clicks outside of the search results dropdown
  const handleSearchResultsBlur = () => {
    searchResultsOpenRef.current = false;
  };

  // closes the newGroupModal
  const closeModal = () => {
    setNewGroupModal(false);
    setIsCreateGroup(false);
    setIsAddChannel(false);
    setIsEditChatGroup(false);

    setInputVal({
      groupName: '',
      description: ''
    });
    setAddedUserRefs([]);
    setAddedUsers([]);
  };

  return (
    <>
      <div className='new_group_modal_container' ref={modalContainerRef}>
        <div className='new_group_header'>
          <div className='header'>
            <p>{header}</p>
            <EditIcon
              width='10'
              height='10'
              top='auto'
              bottom='auto'
            />
          </div>
          <div className='toggle_switch_container'>
            <p>Public Channel</p>
            <div
              className={`toggle_switch ${isOn ? 'on' : 'off'} ${(isPublic || isPublic === undefined) ? 'public' : 'private'}`}
              // className={`toggle_switch ${isOn ? 'on' : 'off'} ${isEditChatGroup ? (isOn ? 'public' : 'private') : ((isPublic || isPublic === undefined) ? 'public' : 'private')}`}
              onClick={handleToggle}
            >
              <div className="toggle_handle" />
            </div>
            <p>Private Channel</p>
          </div>
        </div>
        <div className='group_name_container'>
          <label>Name your Group</label>
          <input
            type='text'
            name='groupName'
            placeholder='The Avengers'
            value={inputVal.groupName}
            onChange={handleInputChange}
            disabled={isErrorMsgModal}
          />
        </div>
        <div className='group_description_container'>
          <textarea
            type='text'
            name='description'
            placeholder="Tell members what this channel is all about"
            rows="4"
            cols="50"
            value={inputVal.description}
            onChange={handleInputChange}
          />
        </div>
        <div className='search_users_container'>
          <label>Include</label>
          <SearchBox
            parent='NewGroupModal'
            handleChange={handleSearchChange}
            searchData={filteredResults}
            searchTerm={searchTerm}
            truncateString={truncateString}
            handleSearchResultsClick={handleSearchResultsClick}
            handleSearchResultsBlur={handleSearchResultsBlur}
            isEditChatGroup={isEditChatGroup}
          />
          <div className='selected_result_container'>
            {addedUsers?.map(user => (
              <div
                className='selected_results'
                key={user?.id}
              >
                <img src={user?.avatar ?? user?.logoStorage ?? emu} className='search_results_avatar' alt="avatar" />
                <div className='selected_result_data'>
                  <p>
                    {truncateString(user?.first + " " + user?.last, 21)}
                  </p>
                  <small onClick={() => deselectUser(user)}>X</small>
                </div>
              </div>
            ))}
            </div>
        </div>
        <div className='del_save_btns_container'>
          {isEditChatGroup &&
            <div
              className='del_btn_container'
              onClick={() => setIsDeleteChatGroup(true)}
            >
              <DelChannelIcon
                width='15'
                height='15'
                top='auto'
                bottom='auto'
              />
              <p>Delete this channel</p>
            </div>
          }
          <div className='btns'>
            <button
              // className='close_modal'
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              onClick={() => createChatGroup()}
              onMouseDown={handleKeyDown}
              onMouseUp={handleKeyUp}
            >
              {isEditChatGroup ? 'Save' : 'Create and Save'}
            </button>
          </div>
        </div>

        {/* error message modal */}
        {isErrorMsgModal &&
          <ErrorMsgModal
            errorMsg={errorMsg}
            isErrorMsgModal={isErrorMsgModal}
            setIsErrorMsgModal={setIsErrorMsgModal}
            setErrorMsg={setErrorMsg}
            isTyping={isTyping}
          />
        }
        {/* delete chat group/channel modal */}
        {isDeleteChatGroup &&
          <DeleteGroupModal
            header='Delete?'
            subHeader='Are you sure you want to delete'
            chatObj={chatObj}
            truncateString={truncateString}
            closeModal={() => setIsDeleteChatGroup(false)}
            closeNewGroupModal={closeModal}
          />
        }
      </div>
    </>
  );
};

export default  NewGroupModal;