import React, { useState } from 'react';

const ResetPasswordModal = ({ auth, setResetPassSuccess, closeModal }) => {
  const [email, setEmail] = useState('');
  const [errMessage, setErrMessage] = useState('');

  const handleChange = e => {
    setEmail(e.target.value);
  };

  const resetPassword = async (e) => {
    e.preventDefault();

    try{
        await auth.sendPasswordResetEmail(email);
        setResetPassSuccess(`Reset password link has been sent to ${email}`);
        setErrMessage('');
        closeModal();
    }catch(err){
      if (err.code === 'auth/missing-email') {
        setErrMessage('Email field cannot be empty.');
      } else if (err.code === 'auth/user-not-found') {
        setErrMessage('There is no user record corresponding to this identifier. The user may have been deleted.');
      } else if (err.code === 'auth/invalid-email') {
        setErrMessage('The email address is badly formatted.');
      } else {
        setErrMessage('Something went wrong.');
      }
      setResetPassSuccess('');
    }
    setEmail('');
  };

  return(
    <>
    <div className='reset_pass_back'>
      <div className='reset_pass_modal'>
        <p
          className='close_modal'
          onClick={closeModal}
        >
          X
        </p>
        <div className='reset_pass_header'>
          <h2>Reset Password</h2>
          <p>Enter your email address to reset password</p>
        </div>
        <div className='input_container'>
          <label>Email</label>
          <input
            type='text'
            name='email'
            placeholder='example@email.com'
            value={email}
            onChange={handleChange}
          />
          <p>{errMessage}</p>
        </div>
        <div className='reset_pass_btns'>
          <button
            className='cancel_reset_btn'
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className='reset_btn'
            onClick={resetPassword}
          >
            Reset
          </button>
        </div>
      </div>
      </div>
    </>
  );
};

export default ResetPasswordModal;
