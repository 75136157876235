/* eslint-disable react-hooks/exhaustive-deps */
import { default as React, useEffect, useState, useRef } from "react";
import * as io from "socket.io-client";
const mic = require("./mic.svg");

let SPEECH_TO_TEXT_WS_URL;

const ENV = process.env.REACT_APP_ENV || "development";

console.log(`Speech Service: `, ENV);

if (ENV === "staging") {
  SPEECH_TO_TEXT_WS_URL = "https://nestria-speech-to-text-uhpibtxgea-el.a.run.app";
} else if (ENV === "production") {
  SPEECH_TO_TEXT_WS_URL = "https://nestria-speech-to-text-jtfkgztbza-el.a.run.app"; 
} else {
  SPEECH_TO_TEXT_WS_URL = "https://nestria-speech-to-text-vadqfrngpq-el.a.run.app"; 
}

const AudioToText = ({
  setRecognizedText,
  disabled,
  questionReference,
  resetAudio,
  startRecording,
  headless = false,
  passedStream,
  setIsAudioToTextReady,
  showSnackbar,
}) => {
  const connection = useRef<io.Socket>(); // Stores socket connection
  const [isSocketConnected, setIsSocketConnected] = useState(connection.current ? true : false);
  const [isAudioWorkletReady, setIsAudioWorkletReady] = useState(false);
  
  const currRecognition = useRef<string>();
  const recognitionHist = useRef<string[]>([]);
  const finalTransc = useRef<string[]>([]);

  const [isRecording, setIsRecording] = useState(false);

  const processorRef = useRef<AudioWorkletNode>();
  const audioContextRef = useRef<null | AudioContext>();
  const audioInputRef = useRef<MediaStreamAudioSourceNode>();

  const stream = useRef<MediaStream>();

  /**
   * 1. On mount connect WS
   * 2. On "startRecording", emit "startGoogleCloudStream"
   * 3. Start Audio Worker
   */

  useEffect(() => {
    initialize()
    .catch((e) => {
      console.error(e);
      showSnackbar("error", "Error RTT1 Please try again.");
      setIsAudioToTextReady(false);
    })

    return () => {
      cleanup();
      if (stream.current) {
        const tracks = stream.current?.getTracks();
        if (tracks) {
          tracks.forEach((track) => track.stop());
        }
      }
    }
  }, []);

  useEffect(() => {
    if (startRecording !== undefined && !connection.current) {
      console.error(`WS connection not established!`);
      showSnackbar("error", "Error RTT2 Please try again.");
      return;
    }

    if (startRecording === false) {
      endSession();
    }

    if (startRecording === true && isRecording === false) {
      startSession();
    }
  }, [startRecording]);

  useEffect(() => {
    sessionResetHandler();
  }, [questionReference, resetAudio]);

  useEffect(() => {
    if (disabled && isRecording) {
      cleanup();
    }
  }, [disabled, isRecording]);

  const setUpAudioWorklet = async () => {
    try {
      stream.current = passedStream;

      if (!stream.current) {
        throw Error(`Passed stream is empty. ${passedStream}`);
      }

      if (!audioContextRef.current) {
        audioContextRef.current = new window.AudioContext();

        audioContextRef.current.addEventListener("statechange", (event) =>{
          // console.log(`state change: `, event);
        });

        await audioContextRef.current.audioWorklet.addModule(
          "/src/worklets/recorderWorkletProcessor.js"
        );
      }

      if (audioContextRef.current?.state === 'closed') {
        throw Error(`audioContextRef state is closed: ${audioContextRef.current?.state}`);
      }

      audioContextRef.current.resume();

      const audioTrack = stream.current.getAudioTracks()[0];

      if (!audioTrack) {
        throw Error(`audio track empty`);
      }

      // Create a new MediaStream containing only the audio track
      const audioStream = new MediaStream([audioTrack]);

      audioInputRef.current =
        audioContextRef.current.createMediaStreamSource(audioStream);

      processorRef.current = new AudioWorkletNode(
        audioContextRef.current,
        "recorder.worklet"
      );

      setIsAudioWorkletReady(true);      
    } catch (e) {
      console.error(e);
      showSnackbar("error", "Error RTT3 Please try again.");
      setIsAudioWorkletReady(false);
    }
  };

  const startAudioWorklet = async () => {
    try {
      console.log(`starting audioWorklet...`);
      console.log(`audioContext state: `, audioContextRef.current?.state);

      if (!processorRef.current || !audioContextRef.current || !audioInputRef.current) {
        throw Error(`processorRef: ${processorRef.current} | audioContextRef: ${audioContextRef.current} | audioInputRef: ${audioInputRef.current} cannot be undefined.`)
      }

      if (audioContextRef.current?.state === "closed") {
        setIsAudioWorkletReady(false);
        await setUpAudioWorklet();
      }

      if (audioContextRef.current?.state !== "closed") {
        processorRef.current.connect(audioContextRef.current.destination);
        audioContextRef.current.resume();
      }

      audioInputRef.current.connect(processorRef.current);

      processorRef.current.port.onmessage = (event) => {
        if (event.data && connection.current) {
          const audioData = event.data;
          console.log("Sending Audio Data...");
          connection.current.emit("send_audio_data", { audio: audioData });
        }
      };
    } catch (e) {
      console.error(e);
      showSnackbar("error", "Error RTT4 Please try again.");
      setIsAudioWorkletReady(false);
    }
  };

  const connect = async () => {
    try {
      connection.current?.disconnect();

      const socket = io.connect(SPEECH_TO_TEXT_WS_URL, { transports: ["websocket", "polling"] });
      
      console.log(`trying to connect`);

      socket.on("connect", () => {
        console.log("connected", socket.id);
        connection.current = socket;
        setIsSocketConnected(true);

        if (audioContextRef.current?.state === "running") {
          // console.log(`[INFO] audio to text is ready!`);
          // setIsAudioToTextReady(true);
          // return Promise.resolve(socket);
        }
      });

      socket.on("receive_message", (data) => {
        // console.log("received message", data);
      });

      socket.on("receive_audio_text", (data) => {
        speechRecognized(data);
        // console.log("received audio text", data);
      });

      socket.on("disconnect", () => {
        console.log("disconnected", socket.id);
        setIsSocketConnected(false);
        setIsAudioToTextReady(false);
      });

      return Promise.resolve(socket);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  };

  const cleanup = () => {
    if (!connection) return;
    connection.current?.emit("endGoogleCloudStream");
    connection.current?.disconnect();
    processorRef.current?.disconnect();
    audioInputRef.current?.disconnect();
    audioContextRef.current?.close();
    audioContextRef.current = null;
    connection.current = undefined;
    setIsRecording(false);
  };

  const endSession = () => {
    connection.current?.emit("endGoogleCloudStream");
    processorRef.current?.disconnect();
    audioInputRef.current?.disconnect();
  }

  const startSession = () => {
    endSession();

    connection.current?.emit("send_message", "hello world");

    connection.current?.emit("startGoogleCloudStream");

    setUpAudioWorklet()
    .then(async () => {
      await startAudioWorklet();
    })
    .catch((e) => {
      console.error(e);
      showSnackbar("error", "Error RTT5 Please try again.");
    })
  }

  const toggleRecording = () => {
    if (isRecording) {
      cleanup();
    } else {
      connectHandler();
    }
  };

  const speechRecognized = (data) => {
    if (data.isFinal) {
      recognitionHist.current.push(data.text);

      finalTransc.current.push(data.text);
      
      setRecognizedText.current = finalTransc.current.join(" ");
    } else {
        currRecognition.current = recognitionHist.current.join(" ") + data.text;

        if (currRecognition.current) {
          setRecognizedText.current = currRecognition.current;
        }
    }
  };

  const resetState = () => {
    recognitionHist.current = [];

    finalTransc.current = [];

    currRecognition.current = "";

    setRecognizedText.current = "";
  };

  const connectHandler = () => {
    resetState();
  };

  useEffect(() => {
    if (isSocketConnected && isAudioWorkletReady) {
      setIsAudioToTextReady(true);
    } else {
      setIsAudioToTextReady(false);
    }
  }, [isSocketConnected, isAudioWorkletReady])

  const initialize = async () => {
    try {
      if (connection.current) {
        console.log(`WS Connection already exists.`);
        return Promise.resolve();
      }

      resetState();

      await connect();

      await setUpAudioWorklet();

      console.log(`Audio worklet setup complete.`);

      return Promise.resolve();
      
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  const sessionResetHandler = () => {
    endSession();
    resetState();
  }

  return !headless ? (
    <React.Fragment>
      <button
        className={isRecording ? "btn-danger" : "btn-outline-light"}
        onClick={toggleRecording}
        disabled={disabled}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <img data-recording={isRecording} src={mic} alt="microphone" />
      </button>
    </React.Fragment>
  ) : (
    <></>
  );
};

export default AudioToText;
