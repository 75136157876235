import React from 'react';

const NotificationIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="58"
        height="58"
        rx="4"
        stroke="#F5F5F5"
        strokeWidth="2"
      />
      <path
        d="M16.9153 37.882C18.7672 35.6154 20.0746 34.4616 20.0746 28.2128C20.0746 22.4904 22.9968 20.4517 25.4018 19.4615C25.7213 19.3303 26.022 19.0289 26.1194 18.7007C26.5412 17.2649 27.7239 16 29.296 16C30.8682 16 32.0516 17.2656 32.4691 18.7022C32.5665 19.0339 32.8672 19.3303 33.1867 19.4615C35.5946 20.4531 38.5139 22.4846 38.5139 28.2128C38.5175 34.4616 39.8249 35.6154 41.6768 37.882C42.4441 38.821 41.772 40.2308 40.43 40.2308H18.1549C16.8201 40.2308 16.1523 38.8166 16.9153 37.882Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6813 40.2305V41.3843C24.6813 42.6084 25.1676 43.7823 26.0331 44.6479C26.8987 45.5134 28.0726 45.9997 29.2967 45.9997C30.5208 45.9997 31.6947 45.5134 32.5603 44.6479C33.4258 43.7823 33.9121 42.6084 33.9121 41.3843V40.2305"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NotificationIcon;