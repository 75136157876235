import React, { useMemo, useState } from "react";
import logo from '../../static/spryte-logo.png'
import { styles } from './styles'

const Avatar = props => {
  const [hovered, setHovered] = useState(false);
  const [isAvatarShow, setIsAvatarShown] = useState(true);
  const windowSize = window.innerWidth;

  // launches the app on first render on tablet and mobile
  useMemo(() => {
    if (windowSize <= 1040) {
      setIsAvatarShown(false);
    };
  },[]);

  return (
    <>
      {isAvatarShow &&   
        <div style={props.style}>
          <div 
            className='transition-3'
            style={{
              ...styles.avatarHello,
              ...{ opacity: hovered ? '1' : '0' }
            }}
          >
            Hey it's Stephane 🤙
          </div>

          <div 
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => props.onClick && props.onClick()}
            className='transition-3'
            style={{
              ...styles.chatWithMeButton,
              ...{ border: hovered ? '0.063rem solid #f9f0ff' : '0.25rem solid #7a39e0' },
              backgroundImage : `url(${logo})`
            }}
          />
        </div>
      }
    </>
  );
};

export default Avatar; 