import React from 'react';

const DisconnectModal = ({ closeModal, connectionObj, disconnect }) => {
  return(
    <>
      <div className='modal_overlay' onClick={closeModal}></div>
      <div className='confirm_modal'>
        <p
          className='close_modal'
          onClick={closeModal}
          >
          X
        </p>
        <h2>Delete Connection?</h2>
        <p>
          This action will delete {" "}
          {connectionObj?.ref?.parent?.id === 'lists' ?
            connectionObj?.name
            :
            connectionObj?.ref?.parent?.id === 'users' ?
              connectionObj?.first !== undefined || connectionObj?.last !== undefined ?
                connectionObj?.first + " " + connectionObj?.last
                :
                null :
              (connectionObj?.ref?.parent?.id === 'companies' || connectionObj?.ref?.parent?.id === 'technologies') ?
                connectionObj?.crm?.data?.properties?.name?.value ?? connectionObj?.profile?.name
                :
                null
          },
          associated group chat, and all conversations.
        </p>
        <h3>Are you sure you want to do this?</h3>
        <div className='confirm_btn_container'>
          <button
            className='cancel_confirm_btn'
            onClick={closeModal}
          >
            No
          </button>
          <button
            className='confirm_btn'
            onClick={() => {
              disconnect(connectionObj?.connectionRef?.path);
              closeModal();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </>
  );
};

export default DisconnectModal;
