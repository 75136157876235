import { useState, useEffect, useContext } from "react";
import {
  RANDOM_UNIT_COMPLETE_RESPONSES,
  generateRandomResponse,
} from "../helpers/utils";
import {
  getLatestScore,
  getRecentScoresFromSelectedCourseUnits,
  getRecentScoresFromSelectedQuestions,
} from "../helpers/store";
import { ScoreBox } from "./ScoreBox";
import {
  clearState,
  saveStateForXMinutes,
  getState,
} from "../helpers/localStorage";
import { CurrentUserContext } from "../Context/Nestria";

const CUTOFF_SCORE = 3;
const MAX_SCORE = 5;
const AVG_NUM_OF_QUESTIONS_PER_UNIT = 6;

export const LessonComplete = ({
  courseType = "default",
  courseName,
  unitName,
  isLastUnitOfTheCourse = null,
  selectedQuestions,
  selectedUnit,
  allUnits,
  onLessonComplete,
}) => {
  const currentUserObj = useContext(CurrentUserContext);
  const [randomResponse, setRandomResponse] = useState("");
  const [label, setLabel] = useState("");
  const [stateReady, setStateReady] = useState(false);
  const [unitScore, setUnitScore] = useState(null);
  const [level, setLevel] = useState(null);
  const [finalUnitScoresArray, setFinalUnitScoresArray] = useState([]);
  const [pendingUnitScoresArray, setPendingUnitScoresArray] = useState([]);
  const [finalAssessmentScore, setFinalAssessmentScore] = useState(null);
  const [finalAssessmentScoresArray, setFinalAssessmentScoresArray] = useState(
    []
  );
  const [pendingAssessmentScoresArray, setPendingAssessmentScoresArray] =
    useState([]);

  const [listenersActive, setListenersActive] = useState(false);

  const [hideScoreBoxIfOrientation, setHideScoreBoxIfOrientation] =
    useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const courseUnits = allUnits.filter(
    (u) => u.courseName === selectedUnit.courseName
  );

  const [continueBtnText, setContinueBtnText] = useState("Continue");

  useEffect(() => {
    // On lesson complete reset video on off preference
    clearState("isVideoOn");
  }, []);

  useEffect(() => {
    let _randomResponse = generateRandomResponse(
      RANDOM_UNIT_COMPLETE_RESPONSES,
      "You're turning your dreams into reality, one step at a time."
    );

    if (courseType === "assessment" && isLastUnitOfTheCourse === false) {
      setLabel("Unit");
      const { currentUnitIndex, courseLength } = getAssessmentString(
        selectedUnit,
        courseUnits
      );
      _randomResponse = `Section ${currentUnitIndex} of ${courseLength} complete, please continue to the next section.`;
    } else if (courseType === "assessment" && isLastUnitOfTheCourse === true) {
      setLabel("Assessment");
    } else {
      setLabel("Lesson");
    }
    setRandomResponse(_randomResponse);

    // If this is Orientation course, do not show the scores
    if (selectedUnit?.courseName?.toLowerCase() === "orientation") {
      setHideScoreBoxIfOrientation(true);
    } else {
      setHideScoreBoxIfOrientation(false);
    }
  }, []);

  useEffect(() => {
    let totalScore = 0;

    console.log(`finalAssessmentScoresArray: `, finalAssessmentScoresArray);

    const maxDifficultyByUnit = {};

    if (finalAssessmentScoresArray.length > 0) {
      for (const scoreObj of finalAssessmentScoresArray) {
        // Compute total score
        totalScore += scoreObj.score;

        // Compute max difficulty reached per unit
        if (scoreObj.score >= CUTOFF_SCORE) {
          let { unitID, difficulty } = scoreObj;

          if (maxDifficultyByUnit[unitID]) {
            if (maxDifficultyByUnit[unitID] > difficulty) {
              maxDifficultyByUnit[unitID] = difficulty;
            }
          } else {
            maxDifficultyByUnit[unitID] = difficulty;
          }
        }
      }

      let percentageScore;

      if (!isLastUnitOfTheCourse) {
        percentageScore =
          totalScore /
          (Math.max(courseUnits.length * AVG_NUM_OF_QUESTIONS_PER_UNIT, 1) *
            MAX_SCORE);
      } else {
        percentageScore =
          totalScore /
          (Math.max(finalAssessmentScoresArray?.length, 1) * MAX_SCORE);
      }

      setFinalAssessmentScore(percentageScore.toPrecision(2));
    }

    // Compute Difficulty Level
    let finalLevel = 0;

    for (let unitID of Object.keys(maxDifficultyByUnit)) {
      finalLevel += Number(maxDifficultyByUnit[unitID]);
    }

    finalLevel = Math.round(finalLevel / courseUnits.length);

    setLevel(finalLevel);
  }, [finalAssessmentScoresArray]);

  useEffect(() => {
    let totalScore = 0;

    console.log(`finalUnitScoresArray: `, finalUnitScoresArray);

    if (finalUnitScoresArray.length > 0) {
      for (const scoreObj of finalUnitScoresArray) {
        totalScore += scoreObj.score;
      }

      const percentageScore =
        totalScore / (Math.max(finalUnitScoresArray?.length, 1) * 5);

      setUnitScore(percentageScore.toPrecision(2));
    } else {
      setUnitScore(totalScore);
    }
  }, [finalUnitScoresArray]);

  useEffect(() => {
    if (pendingAssessmentScoresArray.length > 0 && !listenersActive) {
      setIsLoading(true);
      Promise.all(
        pendingAssessmentScoresArray.map((pendingScoreDocRef) => {
          const evaluateListener = pendingScoreDocRef.onSnapshot((doc) => {
            const data = doc.data();
            const gptResponseRef = data.gptResponseRef;
            if (data && data.evaluate === false) {
              console.log(`listener active: new score`);
              getLatestScore(gptResponseRef.parent.parent).then((score) => {
                setFinalAssessmentScoresArray((previousScores) => [
                  ...previousScores,
                  {
                    score: isNaN(score) ? 0 : score,
                  },
                ]);
                evaluateListener();
                // TODO: Calling this here resets loader each time a pending question's score changes.
                setIsLoading(false);
              });
            }
          });
        })
      ).then(() => {
        setListenersActive(true);
      });
    } else {
      setIsLoading(false);
    }
  }, [pendingAssessmentScoresArray]);

  useEffect(() => {
    if (pendingUnitScoresArray.length > 0 && !listenersActive) {
      setIsLoading(true);
      Promise.all(
        pendingUnitScoresArray.map((pendingScoreDocRef) => {
          const evaluateListener = pendingScoreDocRef.onSnapshot((doc) => {
            const data = doc.data();
            const gptResponseRef = data.gptResponseRef;
            if (data && data.evaluate === false) {
              console.log(`listener active: new score`);
              getLatestScore(gptResponseRef.parent.parent).then((score) => {
                setFinalUnitScoresArray((previousScores) => [
                  ...previousScores,
                  {
                    score: isNaN(score) ? 0 : score,
                  },
                ]);
                evaluateListener();
                // TODO: Calling this here resets loader each time a pending question's score changes.
                setIsLoading(false);
              });
            }
          });
        })
      ).then(() => {
        setListenersActive(true);
      });
    } else {
      setIsLoading(false);
    }
  }, [pendingUnitScoresArray]);

  function getAssessmentString(selectedUnit, courseUnits) {
    let currentUnitIndex;
    for (let i = 0; i < courseUnits.length; i++) {
      let unit = courseUnits[i];
      if (unit.id === selectedUnit.id) {
        currentUnitIndex = i + 1; // Since index starts from 0
        break;
      }
    }
    const courseLength = courseUnits.length;
    return {
      currentUnitIndex,
      courseLength,
    };
  }

  // Compute Assessment Scores if required
  useEffect(() => {
    // if (isLastUnitOfTheCourse === true) {
    setIsLoading(true);

    getRecentScoresFromSelectedCourseUnits(courseUnits, currentUserObj).then(
      (assessmentScores) => {
        const { finalAssessmentScores, pendingScores } = assessmentScores;

        setFinalAssessmentScoresArray(finalAssessmentScores);

        setPendingAssessmentScoresArray(pendingScores);
      }
    );
    // }
  }, []);

  // Compute Unit Scores if required
  useEffect(() => {
    if (label !== "Assessment") {
      setIsLoading(true);

      getRecentScoresFromSelectedQuestions(
        selectedQuestions,
        currentUserObj
      ).then((unitScores) => {
        const { finalUnitScores, pendingScores } = unitScores;

        setFinalUnitScoresArray(finalUnitScores);

        setPendingUnitScoresArray(pendingScores);
      });
    }
  }, [label, selectedQuestions]);

  useEffect(() => {
    if (label !== "") {
      setStateReady(true);
    } else {
      setStateReady(false);
    }
  }, [label]);

  return (
    <>
      {stateReady && (
        <div className="flex min-h-lesson-complete-screen flex-col gap-5 px-4 py-5 sm:px-0 sm:py-0">
          <div className="lesson-complete">
            <h1 className="text-center text-3xl text-yellow-400 flex flex-col">
              <span className="mb-6">{label}</span>
              <span>Complete!</span>
            </h1>

            <img
              alt="nestria-avatar"
              src="https://info.nestria.org/hubfs/Tombstone%20Static%20Website%20Images/astronaut.png"
              style={{
                width: "8rem",
                height: "8rem",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "2rem",
              }}
            ></img>
          </div>

          <div className="flex grow flex-col items-center justify-center gap-8 font-bold">
            <section className="border-gray-200 sm:border-t-2 sm:p-10">
              <div
                style={{
                  paddingBottom: "30px",
                  color: "#36495d",
                  fontSize: "28px",
                  textAlign: "center",
                }}
              >
                Congratulations
              </div>

              {courseName && unitName && (
                <>
                  <div
                    style={{
                      textAlign: "center",
                      color: "grey",
                      fontSize: "20px",
                    }}
                  >
                    {courseName}
                  </div>

                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: "10px",
                      marginTop: "5px",
                      color: "grey",
                    }}
                  >
                    {unitName}
                  </div>
                </>
              )}

              <div>
                <div></div>
              </div>
            </section>
          </div>

          <ScoreBox
            hide={hideScoreBoxIfOrientation}
            unitScore={unitScore}
            finalAssessmentScore={finalAssessmentScore}
            level={level}
            isLoading={isLoading}
          />

          <section className="border-gray-200 max-h-lesson-section-buttons sm:border-t-2 sm:p-10">
            <div
              style={{
                textAlign: "center",
                color: "grey",
                fontWeight: "600",
                marginBottom: "30px",
              }}
            >
              {randomResponse}
            </div>

            <div className="mx-auto flex max-w-5xl sm:justify-between">
              <button
                onClick={onLessonComplete}
                disabled={continueBtnText !== "Continue"}
                className="grow rounded-2xl border-b-4 border-green-600 bg-green-500 p-9 font-bold uppercase text-white sm:min-w-[150px] sm:max-w-[250px] sm:grow-0"
                style={{
                  flex: "1 1 96%",
                  boxShadow: "0 2px 0 rgb(88, 167, 0), 0 0 0 rgb(24, 153, 214)",
                  opacity: continueBtnText !== "Continue" ? "0.5" : "1",
                }}
              >
                {continueBtnText}
              </button>
            </div>
          </section>
        </div>
      )}
    </>
  );
};
