import { createContext, useState } from "react";

const OratioContext = createContext(null);

export const OratioProvider = ({ children }) => {
  const [connectionId, setConnectionId] = useState('');
  const [connectionObj, setConnectionObj] = useState();
  const [connectionsCount, setConnectionsCount] = useState(0);
  const [discussionCount, setDiscussionCount] = useState(0);
  const [requestCount, setRequestCount] = useState(0);
  const [showGroupChatSection, setShowGroupChatSection] = useState(true);
  const [showMessageSection, setShowMessageSection] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [userConnections, setUserConnections] = useState();
  const [addedUserRefs, setAddedUserRefs] = useState([]);
  const [addedUsers, setAddedUsers] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  
  return(
    <OratioContext.Provider
      value={{
        connectionId,
        setConnectionId,
        connectionObj,
        setConnectionObj,
        connectionsCount,
        setConnectionsCount,
        discussionCount,
        setDiscussionCount,
        requestCount,
        setRequestCount,
        showGroupChatSection,
        setShowGroupChatSection,
        showMessageSection,
        setShowMessageSection,
        searchTerm,
        setSearchTerm,
        userConnections,
        setUserConnections,
        addedUserRefs,
        setAddedUserRefs,
        addedUsers,
        setAddedUsers,
        filteredResults,
        setFilteredResults
      }}
    >
      {children}
    </OratioContext.Provider>
  )
};

export default OratioContext;