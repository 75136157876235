import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/remote-config";
// import { getMessaging, getToken } from "firebase/messaging";

// import Analytics from "analytics";
// import mixpanelPlugin from "@analytics/mixpanel";
// import googleAnalytics from "@analytics/google-analytics";
// import hubSpotPlugin from "@analytics/hubspot";

// set up configs to access firebase database

console.log(process.env.REACT_APP_ENV);

const ENV = process.env.REACT_APP_ENV || "development";

export let config = {};

if (ENV === "development") {
    config = {
      apiKey: "AIzaSyBf2E4ohaG2WFQA06UzP40lb2dPgT5z4OA",
      authDomain: "spryte-app.firebaseapp.com",
      databaseURL: "https://spryte-app.firebaseio.com",
      projectId: "spryte-app",
      storageBucket: "spryte-app.appspot.com",
      messagingSenderId: "721672789701",
      appId: "1:721672789701:web:a7607a96cb7038b01d5aac",
      measurementId: "G-35F9Y3D2SP",
    };
} else if (ENV === "staging") {
  config = {
    apiKey: "AIzaSyDxYAVQW2rp18N9IvGtavc1t65LYG5CA28",
    authDomain: "spryte-s.firebaseapp.com",
    databaseURL: "https://spryte-s.firebaseio.com",
    projectId: "spryte-s",
    storageBucket: "spryte-s.appspot.com",
    messagingSenderId: "609379530518",
    appId: "1:609379530518:web:6761e959cf4b40b7710237",
    measurementId: "G-BY2J9KFE8F",
  };
} else if (ENV === "production") {
    config = {
        apiKey: "AIzaSyAbja9XMqe-Lp29-IqnG_PSs6gHYMwupaE",
        authDomain: "spryte-p.firebaseapp.com",
        databaseURL: "https://spryte-p.firebaseio.com",
        projectId: "spryte-p",
        storageBucket: "spryte-p.appspot.com",
        messagingSenderId: "311298464829",
        appId: "1:311298464829:web:16bb428d82ab4f0d829e54",
        measurementId: "G-G1BPP8WQKN"
      };
}

// const config = {
//   apiKey: "AIzaSyCseWymNjBL4M44SlPROUrQMJIoTjEd3f4",
//   authDomain: "com.sprytelabs.chatone",
//   databaseURL: "https://chattest1-b7121.firebaseio.com",
//   projectId: "chattest1-b7121",
//   storageBucket: 'chattest1-b7121.appspot.com',
//   //messagingSenderId: ,
// };

// export const config = {
//   apiKey: "AIzaSyBf2E4ohaG2WFQA06UzP40lb2dPgT5z4OA",
//   authDomain: "spryte-app.firebaseapp.com",
//   databaseURL: "https://spryte-app.firebaseio.com",
//   projectId: "spryte-app",
//   storageBucket: "spryte-app.appspot.com",
//   messagingSenderId: "721672789701",
//   appId: "1:721672789701:web:a7607a96cb7038b01d5aac",
//   measurementId: "G-35F9Y3D2SP",
// };

// export const config = {
//   apiKey: "AIzaSyDxYAVQW2rp18N9IvGtavc1t65LYG5CA28",
//   authDomain: "spryte-s.firebaseapp.com",
//   databaseURL: "https://spryte-s.firebaseio.com",
//   projectId: "spryte-s",
//   storageBucket: "spryte-s.appspot.com",
//   messagingSenderId: "609379530518",
//   appId: "1:609379530518:web:6761e959cf4b40b7710237",
//   measurementId: "G-BY2J9KFE8F",
// };

// const config = {
// export const config = {
//   apiKey: "AIzaSyAbja9XMqe-Lp29-IqnG_PSs6gHYMwupaE",
//   authDomain: "spryte-p.firebaseapp.com",
//   databaseURL: "https://spryte-p.firebaseio.com",
//   projectId: "spryte-p",
//   storageBucket: "spryte-p.appspot.com",
//   messagingSenderId: "311298464829",
//   appId: "1:311298464829:web:16bb428d82ab4f0d829e54",
//   measurementId: "G-G1BPP8WQKN"
// };

let app;
if (firebase.apps.length === 0) {
  app = firebase.initializeApp(config);
} else {
  app = firebase.app();
}
export const db = app.firestore();
export const auth = app.auth();
export const fire = app.firestore;

export default firebase;

// export const funct = app.functions();

/**
 * Takes an extension to redirect the user to the sprytelabs link
 * @param {string} ext
 * @returns the partner platform link
 */
export const getSpryteLinks = (ext = "") => {
  if (config.projectId === "spryte-app") {
    return `https://spryte-partner.web.app/${ext}`;
  }
  if (config.projectId === "spryte-s") {
    return `https://spryte-s.web.app/${ext}`;
  }
  if (config.projectId === "spryte-p") {
    return `https://partners.sprytelab.com/${ext}`;
  }
};

/**
 * Sets up the Firebase Messaging Authentication
 * @returns the correct VAPID KEY
 */
export const getVapidKey = () => {
  if (config.projectId === "spryte-app") {
    return "BDXp7LZZIPFEvtREUU-xGAAKHpbzGAqHB1ODGZr9-r2xIyMWK71ofROmYJQIs41ibe4WFGN9vzemJHkbQuMTICQ";
  }
  if (config.projectId === "spryte-s") {
    return "BA3R08FlDylJOHUiBMA2j8FYLI1cCVN5WKLrdbsvcDgGXF0lo_ZluYHHJ6aMy2IjxSAEj2f429mJRnO6gXiwRRs";
  }
  if (config.projectId === "spryte-p") {
    return "BLeUPTDNWChg3dlgn0wHP0s7S5GZ6pLeW1QFbIPE4jX5oSQz8QmHiMNC11Kh7yyu1qMCQzXJP86sJFuuieOP9Us";
  }
};

/**
 * This functions signs up new user with email and password as params
 * @param {string} email
 * @param {string} password
 * @returns user
 */
export const signUpWithEmailPassword = async (email, password) => {
  let userRef = await auth.createUserWithEmailAndPassword(email, password);
  return userRef;
};

/**
 * This functions logs in new user with email and password as params
 * @param {string} email
 * @param {string} password
 * @returns user
 */
export const signInWithEmailPassword = async (email, password) => {
  let userRef = await auth.signInWithEmailAndPassword(email, password);
  return userRef;
};

// export const getInvitedUserId = async (email, password) => {
//   let addAuth = funct.httpsCallable("addAuth");
//   let uid = await addAuth({ email, password });
//   return uid;
// };

/**
 * This function checks if email exists
 * @param {string} email
 * @returns email ref
 */
export const fetchSignMethods = async (email) => {
  let emailRef = await auth.fetchSignInMethodsForEmail(email);
  return emailRef;
};

/**
 * This function will return a document reference by the document path
 *
 * @param {string} _path document path
 * @example docRef("/devs/smdhue")
 * @returns document ref
 */
export const docRef = (_path) => {
  return db.doc(_path);
};

/**
 * this function checks wether it is a collection group or a collection and
 * return its reference for manipulation
 * @example colleRef('/devs/02O7gsQWZF62aRWNQKoP/stacks/')
 * @param {string} _collection
 * @param {boolean} isGroup
 * @returns collection reference
 */
export const colRef = (_collection, isGroup = false) => {
  let colRef = isGroup
    ? db.collectionGroup(_collection)
    : db.collection(_collection);
  return colRef;
};

/**
 * This function will add a document in the specified collection
 * @example createDocFromCollection('vendors', {'companyName':'test'...})
 * @param {string} _collection collection path
 * @param {object} _data object containing document data
 * @returns document ref
 */
export const createDocFromCollection = async (_collection, _data) => {
  let docRef = await db.collection(_collection).add(_data);
  return docRef;
};

/**
 * This function will create a document with a given id if the
 * document does not exist or update the document if the given
 * document id exist
 * @example
 * addOrUpdateDocFromPath('devs/testDevId/', {'first':'john', 'last':'doe',...})
 * @param {string} _path document path
 * @param {object} _data document data
 * @returns document reference
 */
export const addOrUpdateDocFromPath = async (_path, _data, merge = true) => {
  let ref = await db.doc(_path).set(_data, { merge: true });
  return ref;
};

/**
 * This functions takes a document path then delete the documents
 * @example deleteDocument('/devs/devIdToDelete')
 * @param {string} _path path of the document
 */
export const deleteDocument = async (_path) => {
  await docRef(_path).delete();
};

/**
 * This function listen to stream on documents changes in
 * collection or group collection
 * @example collectionStreamer('devs', getData)
 * @param {string} _collection
 * @param {function} _callBack
 * @param {boolean} isGroup
 * @returns
 */
export const collectionStreamer = (_collection, _callBack, isGroup = false) => {
  return colRef(_collection, isGroup).onSnapshot(_callBack);
};

/**
 * this function takes a path of a given document and return
 * that document
 * @example getDoc('devs/devId/')
 * @param {string} _path
 * @returns docment
 */
export const getDoc = async (_path) => {
  let doc = await docRef(_path).get();

  return doc;
};

/**
 * This function takes a collection path then return collection data with array of docs
 * @param {string} _collection
 * @param {boolean} isGroup
 * @returns
 */
export const getDocs = async (_collection, isGroup = false) => {
  let docsRef = await colRef(_collection, isGroup).get();
  return docsRef;
};

//Returns firebase timestamp for current time.
export function GetNowTimeStamp() {
  return firebase.firestore.Timestamp.now();
}

/**
 * This function runs a query in firebase. It is as generic as possible,
 * allowing for variegated use.
 *
 * @example
 * // Gets 1 document from the partnerPublic collectionGroup where the companyName is equal to "Cicero".
 * // Does not order results in any particular way.
 * await getDocuments("partnerPublic", true, "companyName", "==", "Cicero", false, null, null, true, 1, true);
 *
 * @param {String} collection - The basic collection or CollectionGroup to search. See group, below, for more.
 * @param {Boolean} where - whether to add a where clause.
 * @param {String} whereKey - the field to search for the whereFieldValue. Disregarded if !where.
 * @param {String} whereOperand - the operand to use in the where clause. Disregarded if !where.
 * @param {any} whereFieldValue - the value of the where query to match. Disregarded if !where.
 * @param {Boolean} orderBy - whether to add an orderBy clause.
 * @param {String} orderByKey - the key to order results by. Disregarded if !orderBy.
 * @param {Boolean} [orderByDesc=false] - whether to order results in a descending manner. Disregarded if !orderBy.
 * @param {Boolean} [limit=false] - whether to add a limit clause.
 * @param {Number} [limitNumber=1] - the number of documents returned. Disregarded if !limit.
 * @param {Boolean} [group=false] - whether the "collection" string refers to a collection or to a CollectionGroup.
 *
 * @returns {Promise<Array<firebase.firestore.DocumentSnapshot>>} This function returns an array of DocumentSnapshots.
 */
export async function getDocuments(
  collection,
  where,
  whereKey,
  whereOperand,
  whereFieldValue,
  orderBy,
  orderByKey,
  orderByDesc = false,
  limit = false,
  limitNumber = 1,
  group = false
) {
  // Attempt to get the requested documents.
  try {
    // Create the queryReference.
    let queryReference = group
      ? db.collectionGroup(collection)
      : db.collection(collection);

    // Add the where clause, if required.
    if (where)
      queryReference = queryReference.where(
        whereKey,
        whereOperand,
        whereFieldValue
      );

    // Add the orderBy clause, if required.
    if (orderBy)
      queryReference = queryReference.orderBy(
        orderByKey,
        orderByDesc ? "desc" : "asc"
      );

    // Add the limit clause, if required.
    if (limit) queryReference = queryReference.limit(limitNumber);

    // Grab the data.
    const querySnapshot = await queryReference.get();

    // Return the documents.
    return querySnapshot.docs;

    // Should something go wrong, return an empty array.
  } catch (error) {
    return [];
  }
}

//Returns DocumentReference for the path string passed.
export function GetRefFromPath(_path) {
  try {
    return db.doc(_path);
  } catch (error) {
    console.log(error);
    return;
  }
}

// Default Spryte Organization's reference.
export const spryteOrgRef = GetRefFromPath(
  "organisations/T6BAcTjwbXleCFpmWTmu"
);

// Default Spryte's vendor admin reference.
export const spryteVendorAdminRef = GetRefFromPath(
  "/vendors/qauSGdJN1WxZUOjHZmO7"
);

export const nestriaVendorRef = GetRefFromPath("/vendors/JgBWheTauAx6butJu01i");
export const nestriaOrgRef = GetRefFromPath("/organisations/ecXE1xwRclqrX1L0XT2v");

/**
 * This function registers new user in firebase and creates a doc in 'users' and 'clients' collections
 * and link them in 'accounts' sub collection of 'users' collection.
 * returns uid of newly created user
 *
 * @example
 * await registerNewUser(data);
 *
 * @param {Map} data - Object containing email, password, first and last of the new user.
 *
 * @returns {String} Returns UID of newly created user.
 */
export async function registerNewUser(data, isNestriaUser=false) {
  var tempData = { ...data };
  var registeredUser = {};
  const _addedOn = GetNowTimeStamp();
  var docData = {
    first: tempData["first"],
    last: tempData["last"],
    email: tempData["email"],
    phone: tempData["phone"],
    addedOn: _addedOn,
    companies: [tempData?.companyRef ?? null],
    companyInfo: {
      companyName: tempData["companyName"],
      industry: tempData["industry"],
      numEmployees: tempData["numEmployees"],
      website: tempData["website"],
    },
    orgRef: isNestriaUser ? nestriaOrgRef : spryteOrgRef,
  };

  var devDocData = {
    avatarURL: null,
    city: null,
    companyName: tempData["companyName"],
    companyWebsite: tempData["website"],
    firstName: tempData["first"],
    lastName: tempData["last"],
    phone: tempData["phone"],
    freelancer: false,
    geoTag: new firebase.firestore.GeoPoint(-18.1236158, 178.4279689),
    gitHubHandle: "",
    linkedInProfile: "",
    position: "",
  };

  var devDocDataForDevsCol = {
    first: tempData["first"],
    last: tempData["last"],
    status: false,
    draft: true,
    vendorRef: isNestriaUser ? nestriaVendorRef : spryteVendorAdminRef,
    orgRef: isNestriaUser ? nestriaOrgRef : spryteOrgRef,
    addedOn: _addedOn,
  };

  var clientDocData = {
    companyName: tempData["companyName"],
    name: tempData["first"] + " " + tempData["last"],
    phone: tempData["phone"],
    createdOn: _addedOn,
    orgRef: isNestriaUser ? nestriaOrgRef : spryteOrgRef,
    public: { publicUrlEnabled: false },
  };

  try {
    var registeredUserObject = await auth
      .createUserWithEmailAndPassword(tempData["email"], tempData["password"])
      .catch((e) => {
        console.log("error while registration: ", e?.message);
        registeredUser = { error: e?.message };
      });
    if (registeredUser["error"] != null) return registeredUser;

    try {
      if (tempData["createCompany"]) {
        //is generated slug unique flag.
        var isSlugUnique = false;

        //generates slug with company name.
        var tempCompanySlug = tempData["companyName"]
          .replace(/[^a-zA-Z0-9-_ ]/g, "")
          .split(" ")
          .filter((a) => a)
          .join("-")
          .toLocaleLowerCase();

        // Checks if slug is unique or generate a new slug by adding -1 or -2 and so on..
        var confirmedUniqueSlug = tempCompanySlug;
        var count = 1;
        while (!isSlugUnique) {
          var existingComapanySlugs = await getDocuments(
            "companies",
            true,
            "profileSlug",
            "==",
            confirmedUniqueSlug,
            false,
            null,
            false,
            false,
            null,
            false
          );
          if (existingComapanySlugs?.length) {
            confirmedUniqueSlug = tempCompanySlug + "-" + count;
            count++;
          } else {
            isSlugUnique = true;
          }
        }

        // New company info.
        let companyData = {
          profile: {
            name: tempData?.["companyName"] ?? null,
            domain:
              tempData?.["website"]?.replace(
                /^(?:https?:\/\/)?(?:www\.)?/i,
                ""
              ) ?? null,
            motto: null,
            logoUrl: "",
          },
          profileSlug: confirmedUniqueSlug,
          addedOn: _addedOn,
        };

        //creating new company.
        // var newCompanyRef = await addDocument("/companies", companyData);
        const newCompanyRef = await db
          .collection("/companies")
          .add(companyData);

        // Updating users companies array to save it in users document.
        docData["companies"] = [newCompanyRef];
      }
    } catch (error) {
      throw "Server error. Please try again.";
    }
    try {
      let _existingContact;
      const existingContact = await db
        .collection("/contacts")
        .where("email", "==", tempData["email"])
        .limit(1)
        .get();
      if (existingContact.docs.length > 0) {
        _existingContact = existingContact.docs[0];
      }
      if (!_existingContact) {
        const existingContact = await db
          .collection("/contacts")
          .where("crm.data.properties.email", "==", tempData["email"])
          .limit(1)
          .get();
        if (existingContact.docs.length > 0) {
          _existingContact = existingContact.docs[0];
        }
      }
      let contactDoc = _existingContact?.ref;
      if (!contactDoc) {
        contactDoc = await db.collection("contacts").add({
          email: docData["email"],
          firstname: docData["first"],
          lastname: docData["last"],
          phone: docData["phone"],
          position: "",
          spryteCompanyRef: docData["companies"][0],
        });
      }
      docData["contactRef"] = contactDoc;
    } catch (error) {
      throw "Server error. Please try again.";
    }

    var devsReportingDocRef = await db
      .collection("devsReporting")
      .add(devDocData);

    devDocDataForDevsCol["devsReportingId"] = devsReportingDocRef;
    
    if (registeredUserObject?.user && registeredUserObject?.user?.uid) {
      devDocDataForDevsCol["userRef"] = GetRefFromPath(`/users/${registeredUserObject.user.uid}`);
    }

    var devsDocRef = await db.collection("devs").add(devDocDataForDevsCol);

    await devsReportingDocRef.update({ devId: devsDocRef });

    clientDocData["mainAccountHolder"] = db.doc(
      `users/${registeredUserObject.user.uid}`
    );

    var clientDocRef = await db.collection("client").add(clientDocData);

    var devsReportingDocData = {
      status: true,
      clientId: clientDocRef,
      devsReportingId: devsReportingDocRef,
      role: isNestriaUser ? db.doc(
        "/userRoles/IMR1p6SEgcgzEe5tYU8y/PLATFORMS/SPRYTE-DEV/ROLES/CLIENT"
      ) : db.doc("/userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-DEV/ROLES/CLIENT"),
    };

    var partnerAccountData = {
      status: true,
      role: isNestriaUser ? db.doc("/userRoles/IMR1p6SEgcgzEe5tYU8y/PLATFORMS/SPRYTE-PARTNER/ROLES/RESOURCE") : db.doc("/userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-PARTNER/ROLES/RESOURCE"),
      devRef: devsDocRef,
    };

    const regUserObj = await db
      .collection("users")
      .doc(registeredUserObject.user.uid)
      .set(docData);

    const regUserObjSpryteDev = await db
      .doc(`users/${registeredUserObject.user.uid}/accounts/spryte-dev`)
      .set(devsReportingDocData);

    const regUserObjSprytePartner = await db.doc(`users/${registeredUserObject.user.uid}/accounts/spryte-partner`).set(partnerAccountData);

    return {
      uid: registeredUserObject.user.uid,
    };
  } catch (e) {
    await auth?.currentUser?.delete();
    return { error: e?.message ?? "Server error. Please try again." };
  }
}
