import React, { useContext, useEffect, useMemo, useState } from "react";
import Lesson from "../Lesson/Lesson";
import { TopBar } from "../TopBar/TopBar";
import Spinner from "../../spinner/Spinner";
import { Mixpanel } from "../helpers/Mixpanel";
import {
  getUserUnitsCollectionRef,
  getCourseDataFromUnitSource,
  getUserQuestionsRefs,
  waitForElm,
  signupMessages,
  isPWAInstalled,
  waitingForContentToLoadMessages,
} from "../helpers/utils";
import { CourseSection } from "./CourseSection";
import { Welcome } from "./Welcome";
import PWAInstallPrompt from "../PWAInstallPrompt";
import { assignOrientationCourse, getAllCourseIconUrls } from "../helpers/store";
import { clearState } from "../helpers/localStorage";
import {
  createQuestion,
  createUnit,
  QuestionType,
  UnitType,
} from "./helpers/Content";
import { computeTotalScore, computeXP } from "./helpers/Analytics";
import { JumpToTopButton } from "./JumpToTopButton";
import { EndOfStreamMessage } from "./EndOfStreamMessage";
import {
  DbContext,
  FirebaseContext,
  CurrentUserContext,
  AssessmentModeContext,
} from "../Context/Nestria";

interface RecentScore {
  courseID: string;
  courseType: string;
  unitID: string;
  questionID: string;
  score: number;
}

interface UnitQuestions extends Array<QuestionType> {}
interface Units extends Array<UnitType> {}

interface Course {
  courseName: string;
  courseType: string;
  unitNumber: number;
}

interface CourseArray extends Array<Course> {}

interface RecentScores extends Array<RecentScore> {}

let units: Units = [];
let _questions: UnitQuestions = [];
let _questionUnitMap = {};
let questionCompleted = 0;
let totalQuestions = 0;
let totalUnits = 0;
let unitsCompleted = 0;
let completedUnitIDs: Array<string> = [];
let courseUnitMap = {};
let coursesCompleted = 0;
let recentScores: RecentScores = [];
let totalEngagement = 0;
let questionSkipped = 0;

let xpsAcquiredMap = {};

// TODO: Move this out
const getTopBarColors = (scrollY) => {
  const defaultColors = {
    backgroundColor: "bg-[#58cc02]",
    borderColor: "border-[#46a302]",
  };

  if (scrollY < 680) {
    return defaultColors;
  } else if (scrollY < 1830) {
    return units[1] ?? defaultColors;
  } else {
    return units[2] ?? defaultColors;
  }
};

const Nestria = ({ justRegistered }) => {
  const db = useContext(DbContext);
  const firebase = useContext(FirebaseContext);
  const currentUserObj = useContext(CurrentUserContext);
  const [scrollY, setScrollY] = useState(0);
  const [userUnits, setUserUnits] = useState<[] | Units>([]);
  const [userQuestions, setUserQuestions] = useState<[] | UnitQuestions>([]);
  const [userCourses, setUserCourses] = useState<[] | CourseArray>([]);
  const [unAnsweredUnits, setUnAnsweredUnits] = useState(0);
  const [unAnsweredQuestions, setUnAnsweredQuestions] = useState(0);
  const [answeredUnits, setAnsweredUnits] = useState(0);
  const [answeredCourses, setAnsweredCourses] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);
  const [unitsLoaded, setUnitsLoaded] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<null | UnitType>(null);
  const [isReviewing, setIsReviewing] = useState(null);
  const [showLesson, setShowLesson] = useState(false);
  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(false);
  const [areAllUnitsCompleted, setAreAllUnitsCompleted] = useState(false);
  const [currentXP, setCurrentXP] = useState(0);
  const [currentScore, setCurrentScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [avgScorePerQuestion, setAvgScorePerQuestion] = useState(0);
  const [totalAnsweredQuestions, setTotalAnsweredQuestions] = useState<
    null | number
  >(null);
  const [
    haveToCompleteProfilingQuestionsInVideo,
    setHaveToCompleteProfilingQuestionsInVideo,
  ] = useState<boolean | null>(null);
  const [
    isTakingProfilingQuestionsInFinal,
    setIsTakingProfilingQuestionsInFinal,
  ] = useState<boolean | null>(null);
  const [allProfileVideoQuestions, setAllProfileVideoQuestions] = useState<
    UnitQuestions | []
  >([]);

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [welcomeModalDismissed, setWelcomeModalDismissed] = useState(false);

  const [PWAIsInstalled, setPWAIsInstalled] = useState(true);
  const [courseIconURLs, setCourseIconURLs] = useState<null | {}>(null)
  const [activeCourseIndex, setActiveCourseIndex] = useState(0);

  const [currentCourses, setCurrentCourses] = useState<Array<Course>>([]);
  const [currentActiveSubject, _setCurrentActiveSubject] = useState(localStorage.getItem("activeCourse") ?? "Orientation")
  const [assessmentMode, _setAssessmentMode] = useState(localStorage.getItem("activeMode") ?? "Practice");

  const setAssessmentMode = (mode:string) => {
    _setAssessmentMode(mode);
    localStorage.setItem("activeMode", mode ?? "Practice");
  }

  const setCurrentActiveSubject = (currentActiveSubject:string) => {
    _setCurrentActiveSubject(currentActiveSubject);
    localStorage.setItem("activeCourse", currentActiveSubject ?? "Orientation");
  }

  const assessmentModeContextValue = { mode: assessmentMode, setMode: setAssessmentMode, activeSubject: currentActiveSubject, setActiveSubject: setCurrentActiveSubject };

  // We can list out the subject names here.
  const allNestriaSubjects = [
    "Java",
    "Quality Assurance",
    "Python",
    "HTML",
    "CSS",
    "Sales",
    "JavaScript",
    "Machine Learning",
    "Soft Skills",
    "ReactJS",
    "Data Science",
    "Swift",
    "SQL",
    "Orientation",
  ];


  useEffect(() => {
    const _allCourses = [...userCourses];
    const subjectRegex = new RegExp(`\\b${currentActiveSubject}\\b`, 'i');

    const _filteredCourses = _allCourses.filter((c) => {
        if (subjectRegex.test("orientation")) {
            return subjectRegex.test(c.courseName);
        } else {
            return subjectRegex.test(c.courseName) && c.courseName.includes(assessmentMode);
        }
    });
    if (_filteredCourses.length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    setCurrentCourses(_filteredCourses);
  }, [currentActiveSubject, userCourses, assessmentMode])

  useEffect(() => {
    if (selectedUnit && currentCourses) {
      const index = currentCourses.findIndex(course => course.courseName === selectedUnit.courseName);
      if (index !== -1) {
        setActiveCourseIndex(index);
      }
    }
  }, [selectedUnit, userCourses]);

  useEffect(() => {
    const isInstalled = isPWAInstalled();

    console.log(`is installed as PWA: `, isInstalled);

    Mixpanel.people.set({ installedAsPWA: isInstalled });

    setPWAIsInstalled(isInstalled);

    // On App start clear certain localStorage states
    clearState("isVideoOn");
  }, []);

  useEffect(() => {
    if (!courseIconURLs) {
      getAllCourseIconUrls(firebase, allNestriaSubjects)
        .then(urls => {
          setCourseIconURLs(urls);
          console.log(`courseIcons set: `);
          console.log(urls);
        })
        .catch(error => console.error(error));
    }
  }, [firebase]);

  useEffect(() => {
    const updateScrollY = () => setScrollY(window.scrollY ?? scrollY);
    updateScrollY();
    document.addEventListener("scroll", updateScrollY);
    return () => document.removeEventListener("scroll", updateScrollY);
  }, [scrollY]);

  useEffect(() => {
    // 1. Compute Total Score when userUnits are loaded
    computeTotalScore(recentScores).then((_totalScore) => {
      setTotalScore(_totalScore);
    });

    // 2. Compute XP points when userUnits are loaded
    if (userUnits.length > 0) {
      computeXP(xpsAcquiredMap).then((totalXPs) => {
        setCurrentXP(totalXPs);
      });
    }
  }, [userUnits]);

  // Compute score percentage and average score per question
  useEffect(() => {
    const scorePercentage =
      totalScore / (Math.max(recentScores?.length, 1) * 5);
    setCurrentScore(Number(scorePercentage?.toFixed(2)));

    const avgScorePerQuestion = totalScore / Math.max(recentScores?.length, 1);
    setAvgScorePerQuestion(Number(avgScorePerQuestion.toFixed(2)));
  }, [totalScore]);

  // Listener detects change in units docs assigned to the user
  useEffect(() => {
    const userUnitsCollectionRef = getUserUnitsCollectionRef(
      db,
      currentUserObj?.ref
    );

    if (justRegistered === false) {
      userUnitsCollectionRef
        ?.get()
        .then((careerPrepSnapshot) => {
          console.log(`careerPrepSnapshot?.empty: `, careerPrepSnapshot?.empty);
          console.log(`justRegistered: `, justRegistered);
          if (careerPrepSnapshot?.empty) {
            // If nothing is assigned to the user, assign Orientation course.
            assignOrientationCourse(firebase, currentUserObj?.id)
              .then(() => {
                console.log(`Assigning Orientation Course...`);
              })
              .catch((e) => {
                console.error(e);
              });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }

    if (userUnitsCollectionRef) {
      // Open a listener to watch any changes made to the course/unit data
      const unsubscribe = userUnitsCollectionRef?.onSnapshot(
        async (snapshot) => {
          // TODO: Should this be moved to processUnitSnapshot function?
          resetQuestionAndUnitCounters();
          processUnitSnapshot(snapshot);
        }
      );

      return () => unsubscribe();
    }
  }, []);

  // Listener detects change in questions docs assigned to the user
  useEffect(() => {
    const userQuestionsRef = getUserQuestionsRefs(db, currentUserObj?.ref);
    if (userQuestionsRef) {
      // Open a listener to watch any changes made to the question data
      const unsubscribe = userQuestionsRef?.onSnapshot(async (snapshot) => {
        processQuestionSnapshot(snapshot);
      });

      return () => unsubscribe();
    }
  }, []);
  
  
  // Scrolling to the ActiveCourse
  useEffect(() => {

    if (activeCourseIndex >= 0 && activeCourseIndex < currentCourses.length) {
        // Timeout is to ensure that the DOM has the updated with all elements
        setTimeout(() => {
            const activeCourseSection = document.getElementById('currentActiveCourse');
            if (activeCourseSection) {
                activeCourseSection.scrollIntoView({ block: "center", behavior: "auto" });
            } else {
                console.warn(`Active course section with id = currentActiveCourse not found.`);
            }
        }, 0);
    } else {
        console.warn(`Invalid active course index: ${activeCourseIndex}`);
    }
}, [reload, userUnits, userQuestions]);



  // Scroll the home feed to current question
  useEffect(() => {
    waitForElm("#activeQuestionId").then((elm) => {
      document
        ?.getElementById("activeQuestionId")
        ?.scrollIntoView({ block: "center", behavior: "auto" });
    });
  }, [reload, userUnits, userQuestions]);

  useEffect(() => {
    if (justRegistered === true && welcomeModalDismissed === false) {
      setShowWelcomeModal(true);
    } else {
      setShowWelcomeModal(false);
    }
  }, [justRegistered, welcomeModalDismissed]);

  const handleQuestionUpdated = async (questionDoc) => {
    const questionData = questionDoc.data();
    const questionID = questionDoc.id;
    const { isAnswered, isQuestionSkipped, xp, skippable, maxSubmissions } =
      questionData;

    // Compute XP points if the question is answered for the first time
    // TODO: This condition is vague. This works for now because the question
    // is only updated when it is either answered or skipped.
    if (isAnswered === true && !xpsAcquiredMap[questionID]) {
      let xpsAcquired = xp || 0;
      xpsAcquiredMap[questionID] = xpsAcquired;
    }

    setUserQuestions((prevState) => {
      const newState = prevState?.map((question) => {
        if (question.id === questionID) {
          return {
            ...question,
            isAnswered: isAnswered,
            isSkipped: isQuestionSkipped,
          };
        }

        return question;
      });
      return newState;
    });
  };

  useEffect(() => {
    // Compute Active Index
    let _totalAnsweredQuestions = 0;

    Promise.all(
      userQuestions.map((question) => {
        if (question.isAnswered === true) {
          _totalAnsweredQuestions += 1;
        }
      })
    ).then(() => {
      setTotalAnsweredQuestions(_totalAnsweredQuestions);

      if (_totalAnsweredQuestions >= userQuestions.length) {
        setAreAllUnitsCompleted(true);
      } else {
        setAreAllUnitsCompleted(false);
      }
    });
  }, [userQuestions, currentActiveSubject, assessmentMode]);

  // Instantly show the acquired XPs
  useEffect(() => {
    if (userUnits.length > 0) {
      computeXP(xpsAcquiredMap).then((totalXPs) => {
        setCurrentXP(totalXPs);
      });
    }
  }, [userQuestions]);
  

  useEffect(() => {
    if (allProfileVideoQuestions) {
      const profileQuestionsYetToBeAnsweredInVideo =
        allProfileVideoQuestions.filter(
          (q) => q.tileObject.isAnsweredInVideo !== true
        );
      setHaveToCompleteProfilingQuestionsInVideo(
        profileQuestionsYetToBeAnsweredInVideo?.length > 0
      );
    }
  }, [allProfileVideoQuestions]);

  const processUnitSnapshot = (snapshot) => {
    try {
      // Reset units & questions array
      units = [];
      _questions = [];
      _questionUnitMap = {};

      Promise.all(
        // Iterate over each assigned unit
        snapshot.docs.map(async (assignedUnitDoc, unitIndex) => {
          const unitID = assignedUnitDoc?.ref?.id;
          const unitData = await assignedUnitDoc.data();
          const unitPopulationInComplete = unitData.show;

          // Do not process if unit population is not complete
          // The cloud function takes a second to populate question data
          if (unitPopulationInComplete === false) {
            const unitName = unitData.name;
            const questions = unitData.questions || [];
            const originalUnitID = unitData.source?.id; // This references to the original unit ID within /catalog
            const unitRef = await unitData.source?.get();

            let courseName;
            let courseID;
            let courseType;
            let courseSubject;
            let isCourseFinalAssessment;
            try {
              const _courseData = await getCourseDataFromUnitSource(unitRef);

              courseName = _courseData?.courseName;
              courseID = _courseData?.courseID;
              courseType = _courseData?.courseType
                ? _courseData.courseType.toLowerCase()
                : "default";

              courseSubject = _courseData?.courseSubject
                ? _courseData?.courseSubject.toLowerCase()
                : "orientation";

              isCourseFinalAssessment = _courseData?.isFinalAssessment
                ? _courseData?.isFinalAssessment
                : false;
            } catch (e) {
              console.error(
                "An error occurred while fetching course name from unit reference:",
                e.message
              );
            }

            const unitQuestions: UnitQuestions = [];
            let questionsCompletedInTheUnit = 0;
            let questionsSkippedInTheUnit = 0;

            await Promise.all(
              // Iterate over each question within the assigned unit
              questions.map(async (questionRef, index) => {
                let ref = await questionRef.get();
                let questionDocData = await ref.data();

                let originalQuestionRef = questionDocData?.source;
                let originalQuestionID = originalQuestionRef?.id;

                // Compute total score
                let latestSubmission = await getLatestSubmission(questionRef);

                if (!latestSubmission.empty) {
                  const { msg } = await latestSubmission.docs[0].data();
                  if (msg) {
                    const score = Number(msg.split("<>")[0].split("/")[0]);
                    // Scores list
                    recentScores.push({
                      courseID: courseID,
                      courseType,
                      unitID: unitID,
                      questionID: questionRef?.id,
                      score: Number.isNaN(score) ? 0 : score,
                    });
                  }
                }

                let tileType = "star"; // default tile type is "star"

                // Compute metrics
                const updatedMetrics = computeMetrics(
                  questionDocData,
                  questionRef,
                  questionsCompletedInTheUnit,
                  questionsSkippedInTheUnit
                );

                questionsCompletedInTheUnit =
                  updatedMetrics?.questionsCompletedInTheUnit;
                questionsSkippedInTheUnit =
                  updatedMetrics?.questionsSkippedInTheUnit;
                questionCompleted = updatedMetrics?.questionCompleted;
                questionSkipped = updatedMetrics?.questionSkipped;

                let question = createQuestion(
                  questionRef,
                  questionDocData,
                  unitID,
                  courseName,
                  courseID,
                  courseType,
                  tileType,
                  originalUnitID,
                  originalQuestionID,
                  courseSubject,
                  isCourseFinalAssessment
                );

                unitQuestions.push(question);

                _questionUnitMap[questionRef?.id] = unitID;
              })
            );

            // Check if the unit is completed
            if (
              questionsCompletedInTheUnit + questionsSkippedInTheUnit ===
              unitQuestions.length
            ) {
              unitsCompleted += 1;
              completedUnitIDs.push(unitID);
            }

            totalQuestions += unitQuestions.length;

            let sortedUnitQuestions = unitQuestions?.sort((a, b) => {
              return a?.index - b?.index; // Sort the questions within units by "index" field
            });

            _questions.push(...sortedUnitQuestions);

            let _unit = createUnit(
              units,
              unitID,
              courseName,
              courseID,
              courseType,
              unitName,
              assignedUnitDoc,
              unitIndex,
              unitQuestions,
              courseSubject,
              isCourseFinalAssessment
            );

            if (courseName !== undefined && courseName !== "undefined") {
              units.push(_unit);
            }

            if (courseUnitMap[courseID]) {
              courseUnitMap[courseID].push(unitID);
            } else {
              courseUnitMap[courseID] = [unitID];
            }
          }
        })
      ).then(async () => {
        // Finally, set units
        const assignedQuestions = _questions
          .flat()
          .filter((q) => q.courseID !== undefined); // Filter any questions that belong to undefined courses

        // First, retrieve unique units and set userUnits state
        const uniqueUnits = await getUniqueItemsFromTheArray(units);
        uniqueUnits.sort((a, b) => a.unitIndex - b.unitIndex);
        uniqueUnits.forEach((unit, index) => {
          unit.unitNumber = index + 1;
        });
        setUserUnits(uniqueUnits);

        const uniqueQuestions = await getUniqueItemsFromTheArray(
          assignedQuestions
        );
        uniqueQuestions.forEach((question, index) => {
          const correspondingUnit = uniqueUnits.find(
            (unit) => unit.id === question.unitId
          );
          if (correspondingUnit) {
            question.unitNumber = correspondingUnit.unitNumber;
          }
        });

        let QuestionsWithoutRouting = uniqueQuestions.filter(
          (q) =>
            q?.routingType !== "switch_prog_lang" &&
            q?.routingType !== "switch_practice_final"
        );

        QuestionsWithoutRouting.sort((a, b) => {
          // First, compare the unitNumber
          if (a.unitNumber !== b.unitNumber) {
            return a.unitNumber - b.unitNumber;
          }
          // If unitNumber is the same, compare index in that unit, so this makes, sorting of questions in the same unit based on index
          return a.index - b.index;
        });

        setUserQuestions(QuestionsWithoutRouting);

        const _allProfileVideoQuestions = QuestionsWithoutRouting.filter(
          (q) =>
            q?.courseName?.toLowerCase()?.includes("orientation") &&
            q?.videoAnswerRequired === true
        );

        setAllProfileVideoQuestions(_allProfileVideoQuestions);

        const uniqueCourseNames = Array.from(
          new Set(units.map((u) => u.courseName))
        );
        const _userCourses: CourseArray = [];
        Promise.all(
          uniqueCourseNames.map((courseName) => {
            const { courseType: _courseType, unitNumber: _unitNumber } =
              units.filter((u) => u.courseName === courseName)[0];
            _userCourses.push({
              courseName,
              courseType: _courseType,
              unitNumber: _unitNumber,
            });
          })
        ).then(() => {
          // Sort User Courses by unit number to keep them in order
          _userCourses.sort((a, b) => a.unitNumber - b.unitNumber);
          setUserCourses(_userCourses);
        });

        setTotalAnsweredQuestions(questionCompleted + questionSkipped);

        totalUnits = units.length;

        // Compute number of courses completed
        for (let courseID of Object.keys(courseUnitMap)) {
          const courseUnits = courseUnitMap[courseID];
          const courseComplete = courseUnits.map((unitID) => {
            return completedUnitIDs.includes(unitID);
          });

          if (courseComplete.filter(Boolean).length === courseUnits.length) {
            coursesCompleted += 1;
          }
        }

        // Metrics
        setMetrics();

        // Check how many questions are left to answer/skip
        const difference =
          totalQuestions - (questionCompleted + questionSkipped);
        const allUnitsCompleted = difference <= 0;
        setAreAllUnitsCompleted(allUnitsCompleted);

        setUnitsLoaded(true);
      });
    } catch (e) {
      console.error(e);
    }
  };

  // TODO: Move this out
  const getUniqueItemsFromTheArray = async (array) => {
    const uniqueIDs = [...new Set(array.map((item) => item.id))];
    const uniqueItems = await Promise.all(
      uniqueIDs.map((itemID) => {
        return array.filter((i) => i.id === itemID)[0];
      })
    );
    return uniqueItems;
  };

  // TODO: Move this out
  const getLatestSubmission = (questionRef) => {
    return questionRef
      .collection("submissions")
      .where("author", "==", db?.doc("organisations/nestria/plugins/chatGPT"))
      .orderBy("ts", "desc")
      .get();
  };

  // TODO: Move this function out.
  /**
   * Compute metrics like
   * - questionCompleted
   * - questionsCompletedInTheUnit
   * - xpsAcquiredMap
   * - questionSkipped
   * - questionsSkippedInTheUnit
   *
   * @param {*} questionDocData
   * @param {*} questionRef
   * @param {*} questionsCompletedInTheUnit
   * @param {*} questionsSkippedInTheUnit
   */
  const computeMetrics = (
    questionDocData,
    questionRef,
    questionsCompletedInTheUnit,
    questionsSkippedInTheUnit
  ) => {
    // Compute XP points
    if (questionDocData?.isAnswered === true) {
      let xpsAcquired = questionDocData?.xp || 0;
      xpsAcquiredMap[questionRef?.id] = xpsAcquired;
    }

    if (
      questionDocData?.isAnswered !== undefined &&
      questionDocData?.isAnswered === true
    ) {
      questionCompleted += 1;
      questionsCompletedInTheUnit += 1;
    }

    if (
      questionDocData?.isQuestionSkipped !== undefined &&
      questionDocData?.isQuestionSkipped === true
    ) {
      questionSkipped += 1;
      questionsSkippedInTheUnit += 1;
    }

    return {
      questionsCompletedInTheUnit,
      questionCompleted,
      questionsSkippedInTheUnit,
      questionSkipped,
    };
  };

  const processQuestionSnapshot = (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
      }
      if (change.type === "modified") {
        console.log("[INFO] Question Snapshot Modified");
        handleQuestionUpdated(change.doc);
      }
    });
  };

  // Analytics
  useEffect(() => {
    console.log(`[INFO] Sending PageView event to mixpanel.`);
    Mixpanel.track("PageView", { screenName: "StudentHome" });
  }, []);

  // Analytics
  useEffect(() => {
    // TODO: This currently includes both answered and skipped questions
    Mixpanel.people.set({ "Units-Completed": answeredUnits });
    if (currentUserObj?.ref) {
      currentUserObj?.ref.update({
        "nestriaStats.units_completed": answeredUnits,
      });
    }
  }, [answeredUnits, currentUserObj]);

  // Analytics
  useEffect(() => {
    if (currentUserObj?.ref) {
      currentUserObj?.ref.update({
        "nestriaStats.total_score_percent": currentScore,
      });
    }
  }, [currentScore, currentUserObj]);

  // Analytics
  useEffect(() => {
    if (currentUserObj?.ref) {
      currentUserObj?.ref.update({
        "nestriaStats.avg_score_per_unit":
          answeredUnits > 0
            ? Number((totalScore / answeredUnits).toFixed(2))
            : 0,
      });
    }
  }, [totalScore, answeredUnits, currentUserObj]);

  // Analytics
  useEffect(() => {
    if (currentUserObj?.ref) {
      currentUserObj?.ref.update({
        "nestriaStats.avg_score_per_course":
          answeredCourses > 0
            ? Number((totalScore / answeredCourses).toFixed(2))
            : 0,
        "nestriaStats.courses_completed": Number(answeredCourses),
      });
    }
  }, [totalScore, answeredCourses, currentUserObj]);

  // Analytics
  useEffect(() => {
    if (currentUserObj?.ref) {
      currentUserObj?.ref.update({
        "nestriaStats.avg_score_per_question": avgScorePerQuestion,
      });
    }
  }, [avgScorePerQuestion, currentUserObj]);

  // Analytics
  useEffect(() => {
    if (currentUserObj?.ref) {
      currentUserObj?.ref.update({
        "nestriaStats.total_xp": currentXP,
      });
    }
  }, [currentXP, currentUserObj]);

  // Analytics
  useEffect(() => {
    Mixpanel.people.set({ "Questions-Answered": answeredQuestions });
    if (currentUserObj?.ref) {
      currentUserObj?.ref.update({
        "nestriaStats.questions_completed": answeredQuestions,
      });
    }
  }, [answeredQuestions, currentUserObj]);

  // Analytics
  useEffect(() => {
    Mixpanel.people.set({ "Units-Remaining": unAnsweredUnits });
    if (currentUserObj?.ref) {
      currentUserObj?.ref.update({
        "nestriaStats.units_remaining": unAnsweredUnits,
      });
    }
  }, [unAnsweredUnits, currentUserObj]);

  // Analytics
  useEffect(() => {
    Mixpanel.people.set({ "Questions-Remaining": unAnsweredQuestions });
    if (currentUserObj?.ref) {
      currentUserObj?.ref.update({
        "nestriaStats.questions_remaining": unAnsweredQuestions,
      });
    }
  }, [unAnsweredQuestions, currentUserObj]);

  useEffect(() => {
    const isNotOrientation = !selectedUnit?.courseName
      ?.toLowerCase()
      ?.includes("orientation");
    const isAnAssessment = selectedUnit?.courseName
      ?.toLowerCase()
      ?.includes("final");

    if (
      haveToCompleteProfilingQuestionsInVideo &&
      isNotOrientation &&
      isAnAssessment
    ) {
      setIsTakingProfilingQuestionsInFinal(true);
    } else {
      setIsTakingProfilingQuestionsInFinal(false);
    }
  }, [selectedUnit, haveToCompleteProfilingQuestionsInVideo]);

  const resetQuestionAndUnitCounters = () => {
    questionCompleted = 0;
    totalQuestions = 0;
    totalUnits = 0;
    unitsCompleted = 0;
    totalEngagement = 0;
    questionSkipped = 0;
  };

  const getSelectedQuestions = (
    userQuestions,
    selectedUnit,
    haveToCompleteProfilingQuestionsInVideo,
    allProfileVideoQuestions
  ) => {
    let selectedQuestions = userQuestions?.filter(
      (x) => x?.unitId === selectedUnit?.id
    );

    const thisIsAnAssessment = selectedUnit?.courseName
      ?.toLowerCase()
      ?.includes("final");
    const thisIsNotOrientation = !selectedUnit?.courseName
      ?.toLowerCase()
      ?.includes("orientation");

    // If required, append current unit questions to profiling questions that are remaining (to answer in video)
    if (
      haveToCompleteProfilingQuestionsInVideo === true &&
      thisIsNotOrientation &&
      thisIsAnAssessment &&
      allProfileVideoQuestions.length > 0
    ) {
      selectedQuestions = [...allProfileVideoQuestions, ...selectedQuestions];
    }

    return selectedQuestions; // Routing questions, if present, will be removed
  };

  const getSelectedQuestionsMemoized = useMemo(
    () =>
      getSelectedQuestions(
        userQuestions,
        selectedUnit,
        haveToCompleteProfilingQuestionsInVideo,
        allProfileVideoQuestions
      ),
    [
      userQuestions,
      selectedUnit,
      haveToCompleteProfilingQuestionsInVideo,
      allProfileVideoQuestions,
    ]
  );

  const setMetrics = () => {
    // Metrics
    setAnsweredUnits(unitsCompleted);

    setAnsweredQuestions(questionCompleted);

    setUnAnsweredUnits(totalUnits - unitsCompleted);

    setAnsweredCourses(coursesCompleted);

    setUnAnsweredQuestions(
      totalQuestions - (questionCompleted + questionSkipped)
    );
  };

  const topBarColors = getTopBarColors(scrollY);

  if (unitsLoaded) {
    return (
      <>
        {!showLesson && unitsLoaded && (
          <AssessmentModeContext.Provider value={assessmentModeContextValue}>
            <TopBar
              backgroundColor={topBarColors.backgroundColor}
              borderColor={topBarColors.borderColor}
              xp={currentXP}
              loading={loading}
              userCourses={userCourses}
              courseIconURLs={courseIconURLs}
              allNestriaSubjects={allNestriaSubjects}
            />
          </AssessmentModeContext.Provider>
        )}

          <>
            {unitsLoaded && currentCourses && !showLesson && (
              <div>
                <div className="flex justify-center gap-3 pt-14 sm:p-6 sm:pt-10 lg:gap-12">
                  <div
                    className="flex max-w-2xl grow flex-col"
                    style={{ maxHeight: "100vh" }}
                  >
                    {loading ? (
                      <Spinner
                        overlayHeight={"50vh"}
                        signupMessages={currentUserObj?.email ? waitingForContentToLoadMessages : signupMessages}
                        justRegistered={justRegistered}
                      />
                    ) : (
                      <>
                        {currentCourses.map((course, index) => (
                          <CourseSection
                            setIsReviewing={setIsReviewing}
                            isActiveCourse={index === activeCourseIndex}
                            course={{
                              courseName: course.courseName,
                              courseType: course.courseType,
                              index,
                            }}
                            units={userUnits?.filter(
                              (u) => u?.courseName === course?.courseName
                            )}
                            questions={userQuestions?.filter(
                              (x) => x?.courseName === course?.courseName
                            )}
                            userQuestions={userQuestions}
                            key={index}
                            setShowLesson={setShowLesson}
                            setSelectedUnit={setSelectedUnit}
                            isLastCourse={index === userCourses.length - 1}
                            currentActiveSubject = {currentActiveSubject}
                            assessmentMode = {assessmentMode}
                            setAreAllUnitsCompleted={setAreAllUnitsCompleted}
                          />
                        ))}

                        <Welcome
                          show={showWelcomeModal}
                          onclickHandler={setWelcomeModalDismissed}
                        />
                      </>
                    )}

                    {scrollY > 100 && !areAllUnitsCompleted && (
                      <JumpToTopButton />
                    )}

                    {areAllUnitsCompleted && (
                      <>
                        {(justRegistered && !(userCourses.length > 0)) || (
                          <EndOfStreamMessage
                          assessmentMode = {assessmentMode}
                          currentActiveSubject = {currentActiveSubject}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>

        {showLesson && (
          <div>
            <div className="flex justify-center lg:gap-12">
              <div className="flex max-w-2xl grow flex-col">
                <Lesson
                  isReviewing={isReviewing}
                  isTakingProfilingQuestionsInFinal={
                    haveToCompleteProfilingQuestionsInVideo &&
                    !selectedUnit?.courseName
                      .toLowerCase()
                      .includes("orientation") &&
                    selectedUnit?.courseName.toLowerCase().includes("final")
                  }
                  setShowLesson={setShowLesson}
                  selectedUnit={selectedUnit}
                  selectedQuestions={getSelectedQuestionsMemoized}
                  setReload={setReload}
                  userUnits={userUnits}
                />
              </div>
            </div>
          </div>
        )}

        {!showWelcomeModal && !PWAIsInstalled && (
          <>
            <PWAInstallPrompt />
          </>
        )}
      </>
    );
  } else {
    return <Spinner overlayHeight={"50vh"}></Spinner>;
  }
};

export default Nestria;
