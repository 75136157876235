import Spinner from "../../spinner/Spinner";
import "../../../styles/feedback.scss";
import { useContext, useEffect, useState } from "react";
import { DisLike, Like } from "../helpers/Svgs";
import { VideoThumbnail } from "../VideoRecorder/VideoThumbnail.js";
import { getUserScoreAndFeedbackFromGPTResponse } from "../helpers/store";
import Controls from "./Controls";
import { CurrentUserContext } from "../Context/Nestria";

export function FeedbackSection({
  // messageData,
  setQuitMessageShown,
  rawGPTFeedback,
  questionText,
  loading,
  questionLength,
  onContinue,
  currentAnswerTranscript,
  setIsFeedbackPopupOpen,
  retakePreviousQuestion,
  setShowUserAnswers,
}) {
  const currentUserObj = useContext(CurrentUserContext);
  const [thumbsUpCount, setThumbsUpCount] = useState(0);
  const [thumbsDownCount, setThumbsDownCount] = useState(0);
  const [textFeedback, setTextFeedback] = useState();
  const [score, setScore] = useState();

  useEffect(() => {
    if (!rawGPTFeedback) {
      setTextFeedback(null);
      setScore(null);
      return;
    }

    const processedFeedback =
      getUserScoreAndFeedbackFromGPTResponse(rawGPTFeedback);

    setScore(processedFeedback?.score);
    setTextFeedback(processedFeedback?.feedback);
  }, [rawGPTFeedback]);

  function renderUserChatSection() {
    return (
      <div className="feedback-chat-section">
        <div className="circle">
          <div className="avatar-initials">
            <span style={{ fontSize: "15px" }}>{initials}</span>
          </div>
        </div>

        <div className="message-container">
          <p
            className={`message
            } ${currentAnswerTranscript < 100 ? "" : "long-question-feedback"}`}
          >
            {currentAnswerTranscript?.length > 0
              ? currentAnswerTranscript
              : "Your answer is blank."}
          </p>
        </div>
      </div>
    );
  }
  function renderNestriaFeedbackChatSection() {
    return (
      <div className="feedback-chat-section">
        <div
          className="avatar-rating-container"
          style={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          <div className="circle">
            <img
              alt="nestria-avatar"
              src="https://info.nestria.org/hubfs/Tombstone%20Static%20Website%20Images/astronaut.png"
              className="avatar-image"
            />
          </div>
          {score !== undefined && score !== null && score !== "" ? (
            <span
              className="rating"
              style={{ color: "#afafaf", fontSize: "x-large" }}
            >
              {score}
            </span>
          ) : (
            <>
              <div
                style={{
                  color: "orange",
                  height: "15px",
                  width: "150px",
                  marginBottom: "10px",
                }}
              >
                Getting your Score...
              </div>
            </>
          )}
        </div>

        {textFeedback ? (
          <div className="message-container-feedback">
            <p
              className={`message evaluator-message ${
                textFeedback < 100 ? "" : "long-question-feedback"
              }`}
            >
              {textFeedback}
            </p>
          </div>
        ) : (
          <>
            <div className="gap-1">
              <div
                style={{
                  backgroundColor: "#E5E5E5",
                  height: "15px",
                  width: "100px",
                  marginBottom: "10px",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: "#E5E5E5",
                  height: "15px",
                  width: "160px",
                }}
              ></div>
            </div>
          </>
        )}

        {/* <div className="likedislike">
          <button onClick={handleThumbsUpClick}><Like height={"20px"} width={"20px"}/></button>
          <button onClick={handleThumbsDownClick}><DisLike height={"20px"} width={"20px"}/></button>
        </div> */}

        <div className="continue">
          <button
            onClick={onContinue}
            className="grow rounded-2xl border-b-4 border-green-600 bg-green-500 p-9 font-bold uppercase text-white sm:min-w-[50px] sm:max-w-[100px] sm:grow-0"
            style={{
              flex: "1 1 20%",
              marginRight: "10px",
              boxShadow: "0 2px 0 rgb(88, 167, 0), 0 0 0 rgb(24, 153, 214)",
              padding: "14px",
              fontSize: "14px",
            }}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }

  const handleThumbsUpClick = () => {
    setThumbsUpCount(thumbsUpCount + 1);
  };

  const handleThumbsDownClick = () => {
    setThumbsDownCount(thumbsDownCount + 1);
  };

  const initials =
    currentUserObj?.first?.charAt(0) + currentUserObj?.last?.charAt(0);

  return (
    <>
      <div onClick={() => setQuitMessageShown(true)}>
        <div className="feedback-close-btn">X</div>
      </div>

      <div className="feedback-chat">
        <div
          className={`feedback-message-chat ${
            questionText > 100 ? "long-question-feedback" : ""
          }`}
        >
          <div className="question">{questionText}</div>
          {loading && (
            <Spinner width="6.25rem" height="6.25rem" overlayHeight="50vh" />
          )}

          {loading || (
            <>
              {renderUserChatSection()}
              {renderNestriaFeedbackChatSection()}
              <Controls
                likeHandler={() => {
                  console.log("like clicked");
                  // if (isLikedByThisUser) { unLikeASubmission(firebase, submissionDocRef, currentUserId); }
                  // else { likeASubmission(firebase, submissionDocRef, currentUserId); }
                }}
                flagHandler={() => setIsFeedbackPopupOpen(true)}
                retakeHandler={() => {
                  retakePreviousQuestion();
                  setShowUserAnswers(false);
                }}
                likeCount={0}
                isLiked={false}
                hideLike={true}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
