const StatusBox=({ color }) =>{
    return (
      <div
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "3px",
          marginRight: "5px",
          backgroundColor: color,
        }}
      />
    );
  };
  export default StatusBox;
  