import React, { useState } from 'react';

const SettingsNotificationModal = ({
  firebase,
  db,
  userId,
  category,
  currentUserObj,
  notificationFreq,
  notificationChannel
}) => {
  const [freqDescription, setFreqDescription] = useState(false);
  const [freqId, setFreqId] = useState(null);

  const handleCheckBox = async (e, freq, channel) => {
    const { checked } = e.target;

    const freqName = freq?.name?.toUpperCase();
    const channelRef = channel?.ref;
    const currUserRef = db.doc(`users/${userId}`);
    let connectionCat = '';

    const userConnectionCat = db.doc('organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/user');
    const companyConnectionCat = db.doc('organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/company');
    const techConnectionCat = db.doc('organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/Technology');
    const industryConnectionCat = db.doc('organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/industry');
    const projectConnectionCat = db.doc('organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/project');
    
    if (category?.label === 'People') {
      connectionCat = channel?.userNotificationSettings?.connectionCat;
      connectionCat = userConnectionCat;
    } else if (category?.label === 'Companies') {
      connectionCat = channel?.companyNotificationSettings?.connectionCat;
      connectionCat = companyConnectionCat;
    } else if (category?.label === 'Technologies') {
      connectionCat = channel?.techNotificationSettings?.connectionCat;
      connectionCat = techConnectionCat;
    } else if (category?.label === 'Industry Sectors') {
      connectionCat = channel?.industryNotificationSettings?.connectionCat;
      connectionCat = industryConnectionCat;
    } else {
      connectionCat = channel?.projectNotificationSettings?.connectionCat;
      connectionCat = projectConnectionCat;
    };
    
    if (checked) {
      let frequencyData = {};
      currentUserObj?.defaultNotificationSettings?.map(async (data) => {
        if (category?.label === 'People' && data?.connectionCat?.id === 'user') {
          if (data?.frequency !== undefined) {
            frequencyData = data?.frequency;
          }
          const removeUserFreqData = [{
            connectionCat: data?.connectionCat,
            frequency: frequencyData
          }];
          
          const removeUserFrequency = {
            defaultNotificationSettings: firebase.firestore.FieldValue.arrayRemove(...removeUserFreqData)
          };
          await currUserRef.update(removeUserFrequency);
        } else if (category?.label === 'Companies' && data?.connectionCat?.id === 'company') {
          if (data?.frequency !== undefined) {
            frequencyData = data?.frequency;
          }
          const removeCompanyFreqData = [{
            connectionCat: data?.connectionCat,
            frequency: frequencyData
          }]
          
          const removeCompanyFrequency = {
            defaultNotificationSettings: firebase.firestore.FieldValue.arrayRemove(...removeCompanyFreqData)
          };
          await currUserRef.update(removeCompanyFrequency);
        } else if (category?.label === 'Technologies' && data?.connectionCat?.id === 'Technology') {
          if (data?.frequency !== undefined) {
            frequencyData = data?.frequency;
          }
          const removeTechFreqData = [{
            connectionCat: data?.connectionCat,
            frequency: frequencyData
          }]
          
          const removeTechFrequency = {
            defaultNotificationSettings: firebase.firestore.FieldValue.arrayRemove(...removeTechFreqData)
          };
          await currUserRef.update(removeTechFrequency);
        } else if (category?.label === 'Industry Sectors' && data?.connectionCat?.id === 'industry') {
          if (data?.frequency !== undefined) {
            frequencyData = data?.frequency;
          }
          const removeIndustryFreqData = [{
            connectionCat: data?.connectionCat,
            frequency: frequencyData
          }]
          
          const removeIndustryFrequency = {
            defaultNotificationSettings: firebase.firestore.FieldValue.arrayRemove(...removeIndustryFreqData)
          };
          await currUserRef.update(removeIndustryFrequency);
        } else if (category?.label === 'Projects Types' && data?.connectionCat?.id === 'project') {
          if (data?.frequency !== undefined) {
            frequencyData = data?.frequency;
          }
          const removeProjectFreqData = [{
            connectionCat: data?.connectionCat,
            frequency: frequencyData
          }]
          
          const removeProjectFrequency = {
            defaultNotificationSettings: firebase.firestore.FieldValue.arrayRemove(...removeProjectFreqData)
          };
          await currUserRef.update(removeProjectFrequency);
        } else {
          return;
        };
      });

      let frequency = {};
      const freqKeys = Object.keys(frequencyData);
      if (freqKeys?.length > 0) {
        let freqDataObj = {};
        for (const [key, value] of Object.entries(frequencyData)) {
          // if the selected frequency name (e.g REALTIME, DAILY, etc) is the same as any one in the db
          // if the length of the value/channel (e.g EMAIL channel ref, PUSH channel ref) of the frequency name is greater than 0
          // pushes the selected channel ref into the value variable
          if (key === freqName) {
            if (value?.length > 0) {
              value.push(channelRef);
            }
          };
          // the key and value of the updated frequency data
          const dataObj = {
            [key]: value
          };
          // pushes the updated frequency data into freqDataObj variable
          freqDataObj = { ...freqDataObj, ...dataObj };
          // conditionally updates frequency data object depending on whether
          // the selected frequency name is included in freqKeys (i.e ['REALTIME', 'DAILY', etc] etc])
          frequency = {
            ...(freqKeys.indexOf(freqName) > -1 ?
              { ...freqDataObj }
              :
              { ...freqDataObj, [freqName]: [channelRef] }
            )
          };
        };

      } else {
        // updates the frequency data object if the user has no frequency data
        frequency = {
          [freqName]: [channelRef]
        }
      };

      if (connectionCat === userConnectionCat) {
        const userFreqData = [{
          connectionCat: userConnectionCat,
          frequency
        }];
  
        const updateUserFrequency = {
          defaultNotificationSettings: firebase.firestore.FieldValue.arrayUnion(...userFreqData)
        };
        await currUserRef.set(updateUserFrequency, { merge: true });
      } else if (connectionCat === companyConnectionCat) {
        const companyFreqData = [{
          connectionCat: companyConnectionCat,
          frequency
        }];
  
        const updateCompanyFrequency = {
          defaultNotificationSettings: firebase.firestore.FieldValue.arrayUnion(...companyFreqData)
        };
        await currUserRef.set(updateCompanyFrequency, { merge: true });
      } else if (connectionCat === techConnectionCat) {
        const techFreqData = [{
          connectionCat: techConnectionCat,
          frequency
        }];
  
        const updateTechFrequency = {
          defaultNotificationSettings: firebase.firestore.FieldValue.arrayUnion(...techFreqData)
        };
        await currUserRef.set(updateTechFrequency, { merge: true });
      } else if (connectionCat === industryConnectionCat) {
        const industryFreqData = [{
          connectionCat: industryConnectionCat,
          frequency
        }];
  
        const updateIndustryFrequency = {
          defaultNotificationSettings: firebase.firestore.FieldValue.arrayUnion(...industryFreqData)
        };
        await currUserRef.set(updateIndustryFrequency, { merge: true });
      } else if (connectionCat === projectConnectionCat) {
        const projectFreqData = [{
          connectionCat: projectConnectionCat,
          frequency
        }];
  
        const updateProjectFrequency = {
          defaultNotificationSettings: firebase.firestore.FieldValue.arrayUnion(...projectFreqData)
        };
        await currUserRef.set(updateProjectFrequency, { merge: true });
      } else {
        return;
      };
    } else {
      let frequencyDetails = {};
      if (category?.label === 'People') {
        currentUserObj?.defaultNotificationSettings?.map(async (data) => {
          if (data?.connectionCat?.id === 'user') {
            if (data?.frequency !== undefined) {
              frequencyDetails = data?.frequency;
            }
            const removeUserFreqData = [{
              connectionCat: data?.connectionCat,
              frequency: frequencyDetails
            }];
            
            const removeUserFrequency = {
              defaultNotificationSettings: firebase.firestore.FieldValue.arrayRemove(...removeUserFreqData)
            };
            await currUserRef.update(removeUserFrequency);

            if (Object.keys(frequencyDetails).length > 0) {
              let freqDetails = {};
              //updates freqDetails variable with user's frequency data in the db if the user has frequency data
              freqDetails = data?.frequency;

              let frequency = {};
              let freqDataObj = {};
              // checks if selected frequency name (e.g REALTIME, DAILY, etc) exists freqDetails variable
              const freqNameExists = freqDetails.hasOwnProperty(freqName);
              
              if (freqNameExists === true) {
                let isDoubleChannels = true;
                Object.entries(freqDetails)?.forEach(([key, val]) => {
                  let newVal = [];
                  // if the selected frequency name is the same as any one in the db
                  // if the length of the value/channel (e.g EMAIL channel ref, PUSH channel ref) of the frequency name is more than 1
                  // loop through it and push the value whose ID is different from the unchecked value into newVal variable
                  if (key === freqName) {   
                    if (val?.length > 1) {
                      isDoubleChannels= true;
                      val = val?.forEach(item => {
                        if (item?.id !== channelRef?.id) {
                          newVal.push(item);
                          return item;
                        }
                      })
                    } else {
                      // if the length of the value/channel (e.g EMAIL channel ref, PUSH channel ref) of the frequency name is not more than 1
                      // delete the frequency name (e.g REALTIME, DAILY, etc) completely when unchecked
                      isDoubleChannels= false;
                      delete freqDetails?.[freqName]
                    }
                  }
                  if (val === undefined) {
                    val = newVal;
                  }
                  // the key and value of the updated frequency data
                  const dataObj = {
                    [key]: val
                  };
                  // pushes the updated frequency data into freqDataObj variable
                  freqDataObj = { ...freqDataObj, ...dataObj };

                  // conditionally updates frequency object depending on whether isDoubleChannels var is true or false
                  if (isDoubleChannels) {
                    frequency = {...freqDataObj}
                  } else {
                    frequency = {...freqDetails}
                  };
                })
              };
              const userFreqData = [{
                connectionCat: userConnectionCat,
                frequency
              }];
        
              const updateUserFrequency = {
                defaultNotificationSettings: firebase.firestore.FieldValue.arrayUnion(...userFreqData)
              };
              await currUserRef.set(updateUserFrequency, { merge: true });
            };
          };
        });
      } else if (category?.label === 'Companies') {
        currentUserObj?.defaultNotificationSettings?.map(async (data) => {
          if (data?.connectionCat?.id === 'company') {
            if (data?.frequency !== undefined) {
              frequencyDetails = data?.frequency;
            }
            const removeCompanyFreqData = [{
              connectionCat: data?.connectionCat,
              frequency: frequencyDetails
            }]
            
            const removeCompanyFrequency = {
              defaultNotificationSettings: firebase.firestore.FieldValue.arrayRemove(...removeCompanyFreqData)
            };
            await currUserRef.update(removeCompanyFrequency);

            if (Object.keys(frequencyDetails).length > 0) {
              let freqDetails = {};
                freqDetails = data?.frequency;

              let frequency = {};
              let freqDataObj = {};

              const freqNameExists = freqDetails.hasOwnProperty(freqName);
              
              if (freqNameExists === true) {
                let isDoubleChannels = true;
                Object.entries(freqDetails)?.forEach(([key, val]) => {
                  let newVal = [];
                  if (key === freqName) {   
                    if (val?.length > 1) {
                      isDoubleChannels= true;
                      val = val?.forEach(item => {
                        if (item?.id !== channelRef?.id) {
                          newVal.push(item);
                          return item;
                        }
                      })
                    } else {
                      isDoubleChannels= false;
                      delete freqDetails?.[freqName]
                    }
                  }
                  if (val === undefined) {
                    val = newVal;
                  }
                  
                  const dataObj = {
                    [key]: val
                  };
                  
                  freqDataObj = { ...freqDataObj, ...dataObj };

                  if (isDoubleChannels) {
                    frequency = {...freqDataObj}
                  } else {
                    frequency = {...freqDetails}
                  };
                })
              };
              const companyFreqData = [{
                connectionCat: companyConnectionCat,
                frequency
              }];
        
              const updateCompanyFrequency = {
                defaultNotificationSettings: firebase.firestore.FieldValue.arrayUnion(...companyFreqData)
              };
              await currUserRef.set(updateCompanyFrequency, { merge: true });
            };
          };
        });
      } else if (category?.label === 'Technologies') {
        currentUserObj?.defaultNotificationSettings?.map(async (data) => {
          if (data?.connectionCat?.id === 'Technology') {
            if (data?.frequency !== undefined) {
              frequencyDetails = data?.frequency;
            }
            const removeTechFreqData = [{
              connectionCat: data?.connectionCat,
              frequency: frequencyDetails
            }]
            
            const removeTechFrequency = {
              defaultNotificationSettings: firebase.firestore.FieldValue.arrayRemove(...removeTechFreqData)
            };
            await currUserRef.update(removeTechFrequency);

            if (Object.keys(frequencyDetails).length > 0) {
              let freqDetails = {};
                freqDetails = data?.frequency;

              let frequency = {};
              let freqDataObj = {};

              const freqNameExists = freqDetails.hasOwnProperty(freqName);
              
              if (freqNameExists === true) {
                let isDoubleChannels = true;
                Object.entries(freqDetails)?.forEach(([key, val]) => {
                  let newVal = [];
                  if (key === freqName) {   
                    if (val?.length > 1) {
                      isDoubleChannels= true;
                      val = val?.forEach(item => {
                        if (item?.id !== channelRef?.id) {
                          newVal.push(item);
                          return item;
                        }
                      })
                    } else {
                      isDoubleChannels= false;
                      delete freqDetails?.[freqName]
                    }
                  }
                  if (val === undefined) {
                    val = newVal;
                  }
                  
                  const dataObj = {
                    [key]: val
                  };
                  
                  freqDataObj = { ...freqDataObj, ...dataObj };

                  if (isDoubleChannels) {
                    frequency = {...freqDataObj}
                  } else {
                    frequency = {...freqDetails}
                  };
                })
              };
              const techFreqData = [{
                connectionCat: techConnectionCat,
                frequency
              }];
        
              const updateTechFrequency = {
                defaultNotificationSettings: firebase.firestore.FieldValue.arrayUnion(...techFreqData)
              };
              await currUserRef.set(updateTechFrequency, { merge: true });
            };
          };
        });
      } else if (category?.label === 'Industry Sectors') {
        currentUserObj?.defaultNotificationSettings?.map(async (data) => {
          if (data?.connectionCat?.id === 'industry') {
            if (data?.frequency !== undefined) {
              frequencyDetails = data?.frequency;
            }
            const removeIndustryFreqData = [{
              connectionCat: data?.connectionCat,
              frequency: frequencyDetails
            }]
            
            const removeIndustryFrequency = {
              defaultNotificationSettings: firebase.firestore.FieldValue.arrayRemove(...removeIndustryFreqData)
            };
            await currUserRef.update(removeIndustryFrequency);

            if (Object.keys(frequencyDetails).length > 0) {
              let freqDetails = {};
                freqDetails = data?.frequency;

              let frequency = {};
              let freqDataObj = {};

              const freqNameExists = freqDetails.hasOwnProperty(freqName);
              
              if (freqNameExists === true) {
                let isDoubleChannels = true;
                Object.entries(freqDetails)?.forEach(([key, val]) => {
                  let newVal = [];
                  if (key === freqName) {   
                    if (val?.length > 1) {
                      isDoubleChannels= true;
                      val = val?.forEach(item => {
                        if (item?.id !== channelRef?.id) {
                          newVal.push(item);
                          return item;
                        }
                      })
                    } else {
                      isDoubleChannels= false;
                      delete freqDetails?.[freqName]
                    }
                  }
                  if (val === undefined) {
                    val = newVal;
                  }
                  
                  const dataObj = {
                    [key]: val
                  };
                  
                  freqDataObj = { ...freqDataObj, ...dataObj };

                  if (isDoubleChannels) {
                    frequency = {...freqDataObj}
                  } else {
                    frequency = {...freqDetails}
                  };
                })
              };
              const industryFreqData = [{
                connectionCat: industryConnectionCat,
                frequency
              }];
        
              const updateIndustryFrequency = {
                defaultNotificationSettings: firebase.firestore.FieldValue.arrayUnion(...industryFreqData)
              };
              await currUserRef.set(updateIndustryFrequency, { merge: true });
            };
          };
        });
      } else if (category?.label === 'Projects Types') {
        currentUserObj?.defaultNotificationSettings?.map(async (data) => {
          if (data?.connectionCat?.id === 'project') {
            if (data?.frequency !== undefined) {
              frequencyDetails = data?.frequency;
            }
            const removeProjectFreqData = [{
              connectionCat: data?.connectionCat,
              frequency: frequencyDetails
            }]
            
            const removeProjectFrequency = {
              defaultNotificationSettings: firebase.firestore.FieldValue.arrayRemove(...removeProjectFreqData)
            };
            await currUserRef.update(removeProjectFrequency);

            if (Object.keys(frequencyDetails).length > 0) {
              let freqDetails = {};
                freqDetails = data?.frequency;

              let frequency = {};
              let freqDataObj = {};

              const freqNameExists = freqDetails.hasOwnProperty(freqName);
              
              if (freqNameExists === true) {
                let isDoubleChannels = true;
                Object.entries(freqDetails)?.forEach(([key, val]) => {
                  let newVal = [];
                  if (key === freqName) {   
                    if (val?.length > 1) {
                      isDoubleChannels= true;
                      val = val?.forEach(item => {
                        if (item?.id !== channelRef?.id) {
                          newVal.push(item);
                          return item;
                        }
                      })
                    } else {
                      isDoubleChannels= false;
                      delete freqDetails?.[freqName]
                    }
                  }
                  if (val === undefined) {
                    val = newVal;
                  }
                  
                  const dataObj = {
                    [key]: val
                  };
                  
                  freqDataObj = { ...freqDataObj, ...dataObj };

                  if (isDoubleChannels) {
                    frequency = {...freqDataObj}
                  } else {
                    frequency = {...freqDetails}
                  };
                })
              };
              const projectFreqData = [{
                connectionCat: projectConnectionCat,
                frequency
              }];
        
              const updateProjectFrequency = {
                defaultNotificationSettings: firebase.firestore.FieldValue.arrayUnion(...projectFreqData)
              };
              await currUserRef.set(updateProjectFrequency, { merge: true });
            };
          };
        });
      } else {
        return;
      };
    }
  };

  return(
    <>
      <div className='settings_notification_container'>
        <div className='settings_notification_modal'>
          <div className='settings_notifications_contents'>
            <div className='settings_notification_header'>
              <h3>Frequency</h3>
              <h3>Channels</h3>
            </div>
            {notificationFreq &&
              notificationFreq?.map(freq => {
                return(
                  <div
                    className='settings_notification_data'
                    key={freq?.rank}
                  >
                    <div
                      className='frequency'
                      onMouseOver={() => {
                        setFreqDescription(true);
                        setFreqId(freq?.rank)
                      }}
                      onMouseOut={() => {
                        setFreqDescription(false);
                        setFreqId(null)
                      }}
                    >
                      <p>
                        {freq?.name}
                      </p>
                      {(freqDescription && freqId === freq?.rank) &&
                        <div className='freq_description'>
                          <span className='description'>{freq?.desc}</span>
                        </div>
                      }
                    </div>
                    <>
                      {notificationChannel &&
                        notificationChannel?.map(chan => {
                          let channelFreq = {};
                          if (category?.label === 'People') {
                            channelFreq = chan?.userNotificationSettings?.frequency;
                          };
                          if (category?.label === 'Companies') {
                            channelFreq = chan?.companyNotificationSettings?.frequency;
                          };
                          if (category?.label === 'Technologies') {
                            channelFreq = chan?.techNotificationSettings?.frequency;
                          };
                          if (category?.label === 'Industry Sectors') {
                            channelFreq = chan?.industryNotificationSettings?.frequency;
                          };
                          if (category?.label === 'Projects Types') {
                            channelFreq = chan?.projectNotificationSettings?.frequency;
                          };

                          // email channels
                          const realtimeEmailId = channelFreq?.REALTIME?.map(realtimeId => realtimeId?.id);
                          const dailyEmailId = channelFreq?.DAILY?.map(dailyId => dailyId?.id);
                          const weeklyEmailId = channelFreq?.WEEKLY?.map(weeklyId => weeklyId?.id);
                          const monthlyEmailId = channelFreq?.MONTHLY?.map(quarterlyId => quarterlyId?.id);
                          const quarterlyEmailId = channelFreq?.QUARTERLY?.map(quarterlyId => quarterlyId?.id);
                          const yearlyEmailId = channelFreq?.YEARLY?.map(yearlyId => yearlyId?.id);
                          const emailId = 'Email';
                          // push channels
                          const realtimePushId = channelFreq?.REALTIME?.map(realtimeId => realtimeId?.id);
                          const dailyPushId = channelFreq?.DAILY?.map(dailyId => dailyId?.id);
                          const weeklyPushId = channelFreq?.WEEKLY?.map(weeklyId => weeklyId?.id);
                          const monthlyPushId = channelFreq?.MONTHLY?.map(monthlyId => monthlyId?.id);
                          const quarterlyPushId = channelFreq?.QUARTERLY?.map(quarterlyId => quarterlyId?.id);
                          const yearlyPushId = channelFreq?.YEARLY?.map(yearlyId => yearlyId?.id);
                          const pushId = 'Push';
  
                          const emailFreqLabel = [];
                          const pushFreqLabel = [];

                          if (realtimeEmailId?.includes('EMAIL')) {
                            emailFreqLabel.push('Realtime');
                          };
                          if (dailyEmailId?.includes('EMAIL')) {
                            emailFreqLabel.push('Daily');
                          };
                          if (weeklyEmailId?.includes('EMAIL')) {
                            emailFreqLabel.push('Weekly');
                          };
                          if (monthlyEmailId?.includes('EMAIL')) {
                            emailFreqLabel.push('Monthly');
                          };
                          if (quarterlyEmailId?.includes('EMAIL')) {
                            emailFreqLabel.push('Quarterly');
                          };
                          if (yearlyEmailId?.includes('EMAIL')) {
                            emailFreqLabel.push('Yearly');
                          };

                          if (realtimePushId?.includes('PUSH')) {
                            pushFreqLabel.push('Realtime');
                          };
                          if (dailyPushId?.includes('PUSH')) {
                            pushFreqLabel.push('Daily');
                          };
                          if (weeklyPushId?.includes('PUSH')) {
                            pushFreqLabel.push('Weekly');
                          };
                          if (monthlyPushId?.includes('PUSH')) {
                            pushFreqLabel.push('Monthly');
                          };
                          if (quarterlyPushId?.includes('PUSH')) {
                            pushFreqLabel.push('Quarterly');
                          };
                          if (yearlyPushId?.includes('PUSH')) {
                            pushFreqLabel.push('Yearly');
                          };

                          return(
                            <div
                              className='channel'
                              key={chan?.name}
                            >
                              <input
                                type='checkbox'
                                name='channel'
                                value={chan?.name}
                                defaultChecked={(emailFreqLabel?.includes(freq?.name) && chan?.name === emailId) ||
                                  (pushFreqLabel?.includes(freq?.name) && chan?.name === pushId) ||
                                  (emailFreqLabel?.includes(freq?.name) && chan?.name === emailId &&
                                  pushFreqLabel?.includes(freq?.name) && chan?.name === pushId)}
                                onChange={(e) => {
                                  handleCheckBox(e, freq, chan);
                                }}
                              />
                              <label>{chan?.name}</label>
                            </div>
                          )
                        })
                      }
                    </>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsNotificationModal;
