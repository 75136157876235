import React from 'react';


const TechConnections = ({
  techConnections
}) => {

  return(
    <div className='tech_connections'>
      {techConnections &&
        techConnections.map((connection) => {
        return(
          <div className="tech_card" key={connection?.id}>
            <div className="tech_avatar">
              <img src={connection?.logoStorage} className='avatar_image_tech'/>
            </div>
            <div className="tech_section">  
              <div className="tech_info">
                <span>
                  {connection?.name}
                </span>
                {connection?.types?.map((item, index) => {
                  return (<small key={index}> | {item}</small>)
                })} 
                <p>{connection?.description ?? 'Sprytelabs'}</p>
              </div>       
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default TechConnections;