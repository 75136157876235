import React, { useState, useEffect } from "react";

const Spinner = ({
  width = "6.25rem",
  height = "6.25rem",
  overlayHeight,
  signupMessages = [],
  justRegistered = false,
}) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(() => {
    if (signupMessages.length > 0) {
      // Set a random start index
      const maxIndex = signupMessages.length - 1;
      return Math.floor((Math.random() * (maxIndex - 0 + 1)) + 0)
    } else {
      return 0;
    }
  });

  const displayNextMessage = () => {
    setCurrentMessageIndex((prevIndex) => {
      if (prevIndex < signupMessages.length - 1) {
        return prevIndex + 1;
      } else {
        return 0;
      }
    });
  };

  useEffect(() => {
    if (signupMessages && signupMessages.length > 0) {
      const interval = setInterval(() => {
        displayNextMessage();
      }, 7000);

      return () => clearInterval(interval);
    }
  }, [signupMessages]);

  return (
    <div className="spinner_overlay" style={{ height: overlayHeight }}>
      <div className="spinner_container" style={{ width, height }}></div>
      {signupMessages && signupMessages.length > 0 && (
        <div className="spinner_phrase_container">
            <span className="spinner_phrase">
              {signupMessages[currentMessageIndex]}
            </span>
        </div>
      )}
    </div>
  );
};

export default Spinner;
