import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import {
  FireSvg,
  GemSvg,
  LingotsTreasureChestSvg,
} from "../helpers/Svgs";
import { Calendar } from "./Calendar";
import logo from "../../../static/nestria/astronaut.webp";
import {
  countStreak,
  formatDateToISO8601,
  getStreak,
  getUserStreakDocRef,
} from "../helpers/store";
import { AssessmentModeContext, AuthContext, CurrentUserContext, FirebaseContext } from "../Context/Nestria";
import CourseSwitcher from "./CourseSwitcher";

const EmptyFireTopBarSvg = (props) => {
  return (
    <svg width="25" height="30" viewBox="0 0 25 30" fill="none" {...props}>
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9697 2.91035C13.2187 1.96348 11.7813 1.96348 11.0303 2.91035L7.26148 7.66176L4.83362 6.36218C4.61346 6.24433 4.1221 6.09629 3.88966 6.05712C2.72329 5.86056 2.04098 6.78497 2.04447 8.03807L2.06814 16.5554C2.02313 16.9355 2 17.322 2 17.7137C2 23.2979 6.70101 27.8248 12.5 27.8248C18.299 27.8248 23 23.2979 23 17.7137C23 15.3518 22.1591 13.1791 20.7498 11.4581L13.9697 2.91035ZM11.7198 13.1888C12.0889 12.6861 12.8399 12.6861 13.209 13.1888L15.7324 16.6249C16.5171 17.4048 17 18.4679 17 19.6396C17 22.0329 14.9853 23.973 12.5 23.973C10.0147 23.973 8 22.0329 8 19.6396C8 18.6017 8.37893 17.649 9.01085 16.9029C9.0252 16.8668 9.04457 16.8315 9.06935 16.7978L11.7198 13.1888Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

const EmptyGemTopBarSvg = (props) => {
  return (
    <svg width="24" height="30" viewBox="0 0 24 30" fill="none" {...props}>
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.63705 7.31556C2.62104 7.92872 2 9.02888 2 10.2156V19.8818C2 21.0685 2.62104 22.1687 3.63705 22.7819L10.1117 26.6893C11.1881 27.3389 12.5356 27.3389 13.612 26.6894L20.087 22.7818C21.1031 22.1687 21.7241 21.0685 21.7241 19.8818V10.2156C21.7241 9.0289 21.1031 7.92872 20.087 7.31557L13.612 3.40806C12.5356 2.7585 11.1881 2.75851 10.1117 3.40809L3.63705 7.31556ZM11.8902 6.37281C11.8902 5.52831 10.9645 5.01055 10.2449 5.45256L4.91163 8.72852C4.24944 9.13527 4.22068 10.0873 4.85711 10.5332L7.24315 12.2053C7.59354 12.4508 8.05585 12.4663 8.42194 12.2449L11.3692 10.462C11.6926 10.2664 11.8902 9.91591 11.8902 9.53794V6.37281Z"
          fill="black"
        />
      </g>
    </svg>
  );
};


export const TopBar = ({
  backgroundColor = "bg-[#58cc02]",
  borderColor = "border-[#46a302]",
  xp,
  loading,
  userCourses,
  courseIconURLs,
  allNestriaSubjects,
}) => {
  const auth = useContext(AuthContext);
  const currentUserObj = useContext(CurrentUserContext);
  const firebase = useContext(FirebaseContext);
  const [menu, setMenu] = useState("HIDDEN");
  const [now, setNow] = useState(dayjs());
  const [activeDates, setActiveDates] = useState<any>([]);
  const [streak, setStreak] = useState(0);
  const [assignedSubjects, setAssignedSubjects] = useState<Array<string>>([]);

  const { activeSubject } = useContext(AssessmentModeContext);

  const initials =
    currentUserObj?.first && currentUserObj?.last
      ? currentUserObj?.first?.charAt(0) + currentUserObj?.last?.charAt(0)
      : "T";

  useEffect(() => {
    const uniqueSubjects = new Set();
    Promise.all(userCourses.map((course) => {
      let formattedCourseName = course.courseName
      .replace("Final", "")
      .replace("Practice", "")
      .replace(/[0-9]/g, "").trimEnd();

      uniqueSubjects.add(formattedCourseName);
    }))
    .then(() => {
      setAssignedSubjects(Array.from(uniqueSubjects) as Array<string>)
    })
  }, [userCourses]);

  useEffect(() => {
    const uid = currentUserObj?.id;
    const streakDocRef = getUserStreakDocRef(uid);
    const unsubscribe = streakDocRef.onSnapshot(async (snapshot) => {
      if (snapshot.exists) {
        const docData = snapshot.data();

        if (docData?.nestriaStats) {
          const { streak } = docData.nestriaStats;

          if (streak) {
            getStreak(uid).then(async (streak) => {
              // Format Dates
              const formattedStreakData = await Promise.all(
                streak.map((date) => {
                  return formatDateToISO8601(date.toDate());
                })
              );
              setActiveDates(formattedStreakData);
            });
          }
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [currentUserObj?.id]);

  useEffect(() => {
    countStreak(activeDates).then((streakCount) => {
      setStreak(streakCount);
    });
  }, [activeDates]);

  return (
    <header className="fixed z-20 h-[58px] w-full">
      <div
        className={`topbar-container relative flex h-full w-full items-center justify-between border-b-2 px-[10px] transition duration-500 ${borderColor} ${backgroundColor}`}
      >
        <button
          className="flex items-center justify-center gap-2 font-bold text-white"
          aria-label="Toggle new menu"
          style={{ width: "2.5rem", height: "2rem" }} // Adjust width and height as needed
          // disabled={!loading ? false : true}
          onClick={() => setMenu((x) => (x === "BOOK" ? "HIDDEN" : "BOOK"))}
        >
          <div className="w-full h-full flex justify-center items-center">
          {courseIconURLs && activeSubject && (
              <img
                src={courseIconURLs[activeSubject]}
                alt={activeSubject}
                className="w-full h-full rounded-lg object-cover object-center"
              />
            )}
          </div>
        </button>

        <button
          className="flex items-center gap-2 font-bold text-white"
          aria-label="Toggle streak menu"
          onClick={() => setMenu((x) => (x === "STREAK" ? "HIDDEN" : "STREAK"))}
        >
          {streak > 0 ? <FireSvg /> : <EmptyFireTopBarSvg />}{" "}
          <span className={streak > 0 ? "text-white" : "text-black opacity-20"}>
            {streak}
          </span>
        </button>

        <button
          className="flex items-center gap-2 font-bold"
          aria-label="Toggle lingot menu"
          onClick={() => setMenu((x) => (x === "GEMS" ? "HIDDEN" : "GEMS"))}
        >
          {xp > 0 ? <GemSvg /> : <EmptyGemTopBarSvg />}{" "}
          <span className={xp > 0 ? "text-white" : "text-black opacity-20"}>
            {xp}
          </span>
        </button>

        <button
          className="flex items-center gap-2"
          aria-label="Toggle user menu"
          onClick={() => setMenu((x) => (x === "USER" ? "HIDDEN" : "USER"))}
        >
          <img
            style={{ height: "40px", width: "40px" }}
            src={logo}
            alt="Nestria Logo"
          />
        </button>
        <div
          className={[
            "absolute top-full left-0 right-0 bg-white transition duration-300",
            menu === "HIDDEN" ? "opacity-0" : "opacity-100",
          ].join(" ")}
          aria-hidden={menu === "HIDDEN"}
        >
          {(() => {
            switch (menu) {
              case "BOOK":
                return <CourseSwitcher 
                setMenu={setMenu} 
                assignedCourseSubjects={assignedSubjects} 
                courseIcons={courseIconURLs}
                allNestriaSubjects = {allNestriaSubjects}
                />
              case "STREAK":
                return (
                  <div className="flex grow flex-col items-center gap-3 p-5">
                    <h2 className="text-xl font-bold">Streak</h2>
                    <center>
                      <p className="text-sm text-gray-400">
                        {`Complete at least one section each day so your streak won't reset!`}
                      </p>
                    </center>
                    <div className="self-stretch">
                      <Calendar
                        now={now}
                        setNow={setNow}
                        activeDates={activeDates}
                      />
                    </div>
                  </div>
                );

              case "GEMS":
                return (
                  <div className="flex grow items-center gap-3 p-5">
                    <LingotsTreasureChestSvg className="h-24 w-24" />
                    <div className="flex flex-col gap-3">
                      <h2 className="text-xl font-bold text-black">XP</h2>
                      <p className="text-lg font-normal text-gray-400">
                        You have {xp} {xp === 1 ? "xp" : "xp's"}.
                      </p>
                    </div>
                  </div>
                );

              case "USER":
                return (
                  <div className="flex grow items-center gap-4 p-6">
                    {currentUserObj?.first && currentUserObj?.last ? (
                      <div className="rounded-full text-black flex items-center gap-4">
                        <div
                          className="rounded-full text-black flex items-center justify-center"
                          style={{
                            height: "70px",
                            width: "70px",
                            zIndex: "10",
                            backgroundColor: "#58cc02",
                          }}
                        >
                          {currentUserObj?.avatar ? (
                            <img
                              src={currentUserObj.avatar}
                              alt="profile"
                              className="object-cover rounded-full h-full w-full"
                              // fill
                            />
                          ) : (
                            <span className="text-3xl">{initials}</span>
                          )}
                        </div>
                        <div className="flex flex-col gap-3">
                          <div className="gap-1">
                            <h2 className="text-xl font-bold text-black">
                              {currentUserObj.first + " " + currentUserObj.last}
                            </h2>
                            <p className="text-sm font-normal text-gray-400">
                              {currentUserObj.email}
                            </p>
                          </div>
                          <button
                            className="flex items-center gap-2 font-bold"
                            onClick={async () => {
                              await auth?.signOut();
                            }}
                          >
                            <span className="text-gray-400 text-sm">
                              Logout
                            </span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="rounded-full text-black flex items-center gap-4">
                        <div
                          className="rounded-full text-black flex items-center justify-center"
                          style={{
                            height: "70px",
                            width: "70px",
                            backgroundColor: "#E5E5E5",
                          }}
                        ></div>
                        <div className="flex flex-col gap-3">
                          <div className="gap-1">
                            <div
                              style={{
                                backgroundColor: "#E5E5E5",
                                height: "15px",
                                width: "100px",
                                marginBottom: "10px",
                              }}
                            ></div>
                            <div
                              style={{
                                backgroundColor: "#E5E5E5",
                                height: "15px",
                                width: "150px",
                              }}
                            ></div>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#E5E5E5",
                              height: "15px",
                              width: "80px",
                              marginBottom: "10px",
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              case "HIDDEN":
                return null;
              default:
                return null;
            }
          })()}
          <div
            className={[
              "absolute left-0 top-full h-screen w-full bg-black opacity-30 lg:w-[642px] lg:w-[642px]",
              menu === "HIDDEN" ? "pointer-events-none" : "",
            ].join(" ")}
            onClick={() => setMenu("HIDDEN")}
            aria-label="Hide menu"
            role="button"
          ></div>
        </div>
      </div>
    </header>
  );
};
