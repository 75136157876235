
export const styles = {
    chatWithMeButton: {
        cursor: 'pointer',
        boxShadow: '0 0 1rem 0.375rem rgba(0, 0, 0, 0.33)',
        // Border
        borderRadius: '50%',
        // Background 
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '5.25rem',
        // Size
        width: '5.25rem',
        height: '5.25rem',
    },
    avatarHello: { 
        // Position
        position: 'absolute', 
        left: 'calc(-100% - 5rem - 1.75rem)', 
        top: 'calc(50% - 1.5rem)', 
        // Layering
        zIndex: '10000',
        boxShadow: '0 0 1rem 0.375rem rgba(0, 0, 0, 0.33)',
        // Border
        padding: '0.75rem 0.75rem 0.75rem 1rem',
        borderRadius: '1.5rem', 
        // Color
        backgroundColor: '#f9f0ff',
        color: 'black',
    },
    supportWindow: {
        // Position
        position: 'fixed',
        bottom: '7.25rem',
        right: '1.5rem',
        // Size
        width: '26.5rem',
        height: '33.125rem',
        maxWidth: 'calc(100% - 3rem)',
        maxHeight: 'calc(100% - 3rem)',
        backgroundColor: 'white',
        // Border
        borderRadius: '0.75rem',
        border: `0.125rem solid #7a39e0`,
        overflow: 'hidden',
        // Shadow
        boxShadow: '0 0 1rem 0.375rem rgba(0, 0, 0, 0.33)',
    },
    stripe: {
        position: 'relative',
        top: '-2.813rem',
        width: '100%',
        height: '19.25rem',
        backgroundColor: '#7a39e0',
        transform: 'skewY(-12deg)',
    },
    topText: { 
        position: 'relative',
        width: '100%', 
        top: '15%', 
        color: 'white', 
        fontSize: '1.5rem', 
        fontWeight: '600',
    },
    emailInput: { 
        width: '66%',
        textAlign: 'center',
        outline: 'none',
        padding: '0.75rem',
        borderRadius: '0.75rem',
        border: '0.125rem solid #7a39e0',
    },
    bottomText: { 
        position: 'absolute', 
        width: '100%', 
        top: '60%', 
        color: '#7a39e0', 
        fontSize: '1.5rem', 
        fontWeight: '600' 
    },
    loadingDiv: { 
        position: 'absolute', 
        height: '100%', 
        width: '100%', 
        textAlign: 'center', 
        backgroundColor: 'white',
    },
    loadingIcon: { 
        color: '#7a39e0', 
        position: 'absolute', 
        top: 'calc(50% - 3.188rem)', 
        left: 'calc(50% - 3.188rem)',  
        fontWeight: '600',
    },
    chatEngineWindow: {
        width: '100%',  
        backgroundColor: '#fff',
    }
}