import mixpanel from 'mixpanel-browser';

const ENV = process.env.REACT_APP_ENV || "development";

const sendEvents = ENV === "production" ? true : false; // Send Events only in production

if(sendEvents) {
  console.log(`Mixpanel ready!`);
} else {
  console.log(`Mixpanel disabled!`);
}

if (ENV === "production") {
    // TODO: Use remote config or secret manager to pass these keys
    mixpanel.init('defadace7d68ec8ec55976d085cdfdb5');
} else {
    mixpanel.init('defadace7d68ec8ec55976d085cdfdb5');
    console.log(`[INFO] Mixpanel initialized in dev environment.`);
}

const actions = {
  identify: (id) => {
    if (sendEvents) mixpanel.identify(id);
  },
  alias: (id) => {
    if (sendEvents) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (sendEvents) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
        if (sendEvents) mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;