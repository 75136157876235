import React from 'react';

const TimeSheetNextIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.45955 0.399408C6.90935 0.931862 6.90925 1.79531 7.45964 2.32786L20.5541 14.9997L7.45955 27.6721C6.90935 28.2046 6.90925 29.068 7.45964 29.6006C8.00994 30.1331 8.90208 30.1331 9.45238 29.6006L23.5433 15.9639C23.8075 15.7081 23.956 15.3613 23.956 14.9997C23.956 14.638 23.8074 14.2911 23.5432 14.0355L9.45229 0.3995C8.90208 -0.133137 8.00985 -0.133139 7.45955 0.399408Z"
        fill="#A5A6A8"
      />
    </svg>
  );
};

export default TimeSheetNextIcon;
