import React, { useState,useEffect } from "react";

const ShowMidNightModal = ({
  isNextDay,
  setisNextDay,
  setIsShowCheckIn,
  setFirstDayofWeek,
  setFirstDayofMonth,
  setTimeSheetData,
  setDevUserDetails,
  devsReportingReference,


}) => {
    const [weekfirstday, setweekfirstday] = useState(new Date());
    const [monthfirstday, setmonthfirstday] = useState(new Date());
    const [colleaguesUserDetails, setColleaguesUserDetails] = useState({});
    useEffect(() => {
        getFirstDayOfweek();
        if (devsReportingReference) getColleagues(devsReportingReference);
      }, []);
      
  const getFirstDayOfweek = () => {
    let currentDate = new Date();
    let _currentday = currentDate.getDay();
    let _firstDayOfweek = new Date();
    let diff =
      currentDate.getDate() - _currentday + (_currentday == 0 ? -6 : 1); // adjust when day is sunday
    _firstDayOfweek = new Date(currentDate.setDate(diff));
    setweekfirstday(_firstDayOfweek);
  };
  const getFirstDayOfMonth = () => {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let _firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);
    setmonthfirstday(new Date(_firstDay));
  };

  const getLocalTime = (tzoffsetStr) => {
    let offsetHour =
      tzoffsetStr !== null
        ? tzoffsetStr?.charAt(0) === "-"
          ? parseInt(tzoffsetStr?.substr(1), 10)
          : parseInt(tzoffsetStr?.substr(0), 10)
        : 0; // e.g. 7
    let offsetMinutes =
      tzoffsetStr !== null
        ? tzoffsetStr?.charAt(0) === "-"
          ? parseInt(tzoffsetStr?.substr(4), 10)
          : parseInt(tzoffsetStr?.substr(2), 10)
        : 0; // e.g. 7
    const sign = tzoffsetStr?.charAt(0) === "-" ? -1 : 1;
    const gmtsign = tzoffsetStr?.charAt(0) === "-" ? "-" : "+";
    const tzoffset3 =
      sign *
      ((parseInt(offsetHour) * 60 + parseInt(offsetMinutes)) * 60 * 1000);
    const gmtString =
      offsetMinutes > 0
        ? `GMT${gmtsign}${Math.abs(offsetHour)}:${Math.abs(offsetMinutes)
            .toString()
            .padStart(2, "0")}`
        : `GMT${gmtsign}${Math.abs(offsetHour)}`;
    const localDate3 = new Date();
    const utcOffset3 = localDate3?.getTimezoneOffset() * 60 * 1000;
    const offsetMilliseconds3 = tzoffset3 + utcOffset3;
    const utcTime3 = localDate3?.getTime();
    const localTime3 = utcTime3 + offsetMilliseconds3;
    const colleagueTime = new Date(localTime3);
    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const formattedColleagueTime = formatter.format(colleagueTime);
    const myTime = new Date();
    const timeDiffInMs = Math.abs(myTime - colleagueTime);
    const timeDiffInHours = timeDiffInMs / (1000 * 60 * 60);
    const outputString = `Current local time- ${formattedColleagueTime} ${gmtString} | ${Math.abs(
      timeDiffInHours?.toFixed(2)
    )}h ${timeDiffInHours < 0 ? "ahead" : "behind"} of you.`;
    return outputString;
  };

  const getHoursworked = (timeSheetData) => {
    let tempTimeWorked = 0;
    let workingtime;
    {
      timeSheetData &&
        timeSheetData?.presenceData?.map((data, i) => {
          if (
            data?.type === "checkIn" ||
            data?.type === "checkin" ||
            data?.type === "unpause"
          ) {
            workingtime = data?.dateTime?.toDate();
          }
          if (data?.type === "pause") {
            let timedifference = data?.dateTime?.toDate() - workingtime;
            tempTimeWorked += timedifference;
          }
          if (i === timeSheetData?.presenceData?.length - 1) {
            if (data?.type === "unpause") {
              let timedifference = new Date() - data?.dateTime?.toDate();
              tempTimeWorked += timedifference;
            }
          }
        });

      let timeworkedObj = new Date(tempTimeWorked);
      let seconds = timeworkedObj?.getUTCSeconds();
      let minutes = timeworkedObj?.getUTCMinutes();
      let hours = timeworkedObj?.getUTCHours();
      let timeworked =
        "(" +
        hours?.toString()?.padStart(2, "0") +
        "hr " +
        minutes?.toString()?.padStart(2, "0") +
        "min " +
        "worked today)";
      return timeworked;
    }
  };
  const getColleagues = async (devsReportingReference) => {
    let colleagues;
    let activeSprints = [];
    let checkInDate = new Date();
    let userDetails = {};
    const unsubscribe = devsReportingReference?.onSnapshot(
      async (activeSprintsSnapshot) => {
        let sprints = activeSprintsSnapshot?.data()?.sprints ?? [];
        await Promise.all(
          sprints?.map(async (data, i) => {
            let sprintDoc = await data?.get();
            let sprintStartDate = sprintDoc?.data()?.start;
            let sprintEndDate = sprintDoc?.data()?.end;
            if (
              checkInDate > sprintStartDate?.toDate() &&
              sprintEndDate?.toDate() > checkInDate
            ) {
              activeSprints.push(data);
            }
          })
        );
        await Promise.all(
          activeSprints?.map(async (data, i) => {
            let sprintRef = await data?.get();
            let teamRef = sprintRef?.data()?.team;
            let teaminformation = await teamRef?.get();
            let teamMembersMap = teaminformation?.data()?.members;
            await Promise.all(
              teamMembersMap.map(async (data, i) => {
                let lastlogin;
                let devref = data?.id;
                let devInformation = await devref?.get();
                let devID = devInformation?.data()?.devID;
                let firstname = devInformation?.data()?.first;
                let lastname = devInformation?.data()?.last;
                let colleaguename = firstname + " " + lastname;
                const logostorageData = await devref
                  .collection("devPublic")
                  .doc("profile")
                  ?.get();
                let _logostrorage;
                if (logostorageData.exists) {
                  _logostrorage = logostorageData?.data()?.logoStorage;
                }
                let devreportingref = devInformation?.data()?.devsReportingId;
                const isDocRefEqual =
                  devsReportingReference?.isEqual(devreportingref);
                if (isDocRefEqual) return;

                if (devreportingref === undefined) {
                  const user = {
                    name: "This Resource has conflicting records        ",
                    localtime: "",
                    checkIn: "",
                    status: "",
                    usertimeworked: "",
                  };
                  userDetails[devref?.path] = user;
                  //return;
                } else {
                  const today = new Date();
                  const startOfDay = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate()
                  );
                  const endOfDay = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() + 1
                  );
                  const presenceQuery = devreportingref
                    ?.collection("presence")
                    ?.orderBy("checkIn")
                    ?.startAt(startOfDay)
                    ?.endBefore(endOfDay);
                  let presenceDataMap = {};
                  let timeworked;
                  let colleaguestatus;
                  let colleagueTimeWorked;
                  let colleaguesLocalTime;
                  let colleagueCheckin;
                  let datamodified = false;
                  presenceQuery?.onSnapshot(async (snapshot) => {
                    if (!snapshot.empty) {
                      snapshot.docChanges().forEach((change) => {
                        if (
                          change.type === "added" ||
                          change.type === "modified"
                        ) {
                          presenceDataMap = change?.doc?.data();
                        } else {
                          presenceDataMap = snapshot?.data() ?? {}; // Set presenceData to an empty object if snapshot.data() is undefined
                        }
                    const presenceMap = presenceDataMap?.presenceData ?? [];
                      presenceMap &&
                      presenceMap.forEach((data, i) => {
                        if (i === presenceMap.length - 1) {
                          colleaguestatus = data?.type;
                        }
                      });
                      let tzoffsetStr = presenceDataMap?.tzOffset;
                      let checkindate = presenceDataMap?.checkIn?.toDate();
                      const localDateString = checkindate?.toLocaleString(
                        undefined,
                        {
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      );
                      colleagueCheckin =
                        "Checked-in since " + localDateString || "";
                      colleaguesLocalTime = getLocalTime(tzoffsetStr) || {};
                      colleagueTimeWorked = getHoursworked(presenceDataMap);
                      const user = {
                        name: colleaguename,
                        avatar: _logostrorage,
                        localtime: colleaguesLocalTime,
                        checkIn: colleagueCheckin,
                        status: colleaguestatus,
                        usertimeworked: colleagueTimeWorked,
                      };
                      userDetails[devref?.path] = user;
                        if (change.type === "modified") {
                          setColleaguesUserDetails((prevState) => {
                            return {...prevState, ...userDetails}; // Merge the newState object with the previous state using spread syntax
                          });
                        }
                      });
                    }
                  });
                  const latestPresenceQuery = await devreportingref
                    .collection("presence")
                    .orderBy("checkIn", "desc")
                    .limit(1);
                  let latestPresence = await latestPresenceQuery.get();
                  if (!latestPresence.empty) {
                    latestPresence.forEach((latestDoc) => {
                      let timesheetData = latestDoc?.data();
                      let checkindate = latestDoc?.data()?.checkIn?.toDate();
                      const localDateString = checkindate.toLocaleString(
                        undefined,
                        {
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      );
                      colleagueCheckin =
                        "Checked-in since " + localDateString;
                      colleaguestatus = "NOT CHECKED-IN";
                      colleagueTimeWorked = getHoursworked(timesheetData);
                      let tzoffsetStr = latestDoc?.data()?.tzOffset;
                      colleaguesLocalTime = getLocalTime(tzoffsetStr);
                    });
                  }
                  const user = {
                    name: colleaguename,
                    avatar: _logostrorage,
                    localtime: colleaguesLocalTime,
                    checkIn: colleagueCheckin,
                    status: colleaguestatus,
                    usertimeworked: colleagueTimeWorked,
                  };
                  if (!Object.keys(userDetails).includes(devref?.path))
                    userDetails[devref?.path] = user;
                }
              })
            );
          })
        );
        setColleaguesUserDetails(userDetails);
        return unsubscribe;
      }
    );
  };

  return (
    <>
      {isNextDay && (
        <div className="show_midnight_form_container">
          <div className="show_midnight_modal">
            <p>It's past midnight!</p>
            <p>Please check-in again.</p>
            <p>
              You haven't checked-out for 
              yesterday, you'll have to resolve.
            </p>
            <div className="understand_btn_container">
              <button
                onClick={() => {
                  setIsShowCheckIn(true);
                  setisNextDay(false);
                  setFirstDayofWeek(weekfirstday)
                  setFirstDayofMonth(monthfirstday)
                  setDevUserDetails(colleaguesUserDetails)
                  setTimeSheetData(null)
                }}
              >
                I understand
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ShowMidNightModal;
