import React, { useContext, useEffect, useRef, useState } from "react";
import EditIcon from "../../svgs/EditIcon";
import DelChannelIcon from "../../svgs/DelChannelIcon";
import RemoveIcon from "../../svgs/RemoveIcon";
import ListIcon from "../../svgs/ListIcon";
import AddIcon from "../../svgs/AddIcon";
import TimeSheetPrevIcon from "../../svgs/TimeSheetPrevIcon";

const CreateCourse = ({
  db,
  currentUser,
  setCreateCoursesOpen,
  selectedCourse,
  isCreateCoursesOpen,
  isViewCoursesOpen,
  setViewCoursessOpen,
  setMessage,
  setShowMessage,
  coursesStack,
  setSelectedCourse,
}) => {
  const [isOn, setIsOn] = useState(false);
  const [unitsStack, setUnitsStack] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [addedUnits, setAddedUnits] = useState([]);
  const [isAssign, setIsAssign] = useState(true);
  const [courseName, setCourseName] = useState(selectedCourse?.name);
  const [courseDesc, setCourseDesc] = useState(selectedCourse?.desc);
  const [assignedUnits, setAssignedUnits] = useState(selectedCourse?.units);
  const [adminNotes, setAdminNotes] = useState(
    selectedCourse?.adminNotes || ""
  );
  const [courseNameError, setCourseNameError] = useState(false);
  const [courseDescError, setCourseDescError] = useState(false);
  const [adminNotesError, setAdminNotesError] = useState(false);
  const [assignedUnitsError, setAssignedUnitsError] = useState("");
  const [questionXP, setQuestionXP] = useState({});

  useEffect(() => {
    const getUnitsStack = async () => {
      try {
        const unitSet = new Set(); // Set to store unique unit names
        const unitList = []; // Array to store the units

        const nestriaCatalogStack = db?.collection(`catalog`);
        const catalogStack = await nestriaCatalogStack?.get();

        catalogStack?.forEach(async (catalogDoc) => {
          try {
            const catalogData = catalogDoc?.data();
            const catalogId = catalogDoc?.id;

            const unitsSubcollection = await catalogDoc?.ref
              ?.collection("units")
              ?.get();

            console.log("unitsSubcollection", unitsSubcollection);

            setUnitsStack([]);
            setUnitsStack((prevUnitList) =>
              (prevUnitList ?? []).concat(unitList)
            );

            await processUnitsSubcollection(
              unitsSubcollection,
              unitList,
              unitSet,
              catalogId
            );
            console.log("unitList", unitList);
          } catch (error) {
            console.error("Error accessing units subcollection", error);
          }
        });
      } catch (error) {
        console.error("Error retrieving units", error);
      }
    };

    const processUnitsSubcollection = async (
      subcollection,
      unitList,
      unitSet,
      catalogId
    ) => {
      await Promise.all(
        subcollection?.docs.map(async (unitDoc) => {
          try {
            const unitData = unitDoc?.data();
            const unitName = unitData?.name;

            // Check if the unit name is already in the set
            if (!unitSet.has(unitName)) {
              unitSet.add(unitName);

              const questionsSubcollection = await unitDoc?.ref
                ?.collection("questions")
                ?.get();

              const questionList = await Promise.all(
                questionsSubcollection.docs.map(async (questionDoc) => {
                  try {
                    const questionData = questionDoc?.data();
                    const questionReference = questionData?.ref;

                    const questionSnapshot = await questionReference?.get();
                    const questionName = questionSnapshot?.data()?.question;
                    return {
                      question: questionName,
                      xp: questionData?.xp,
                      reference: questionData?.ref,
                    };
                  } catch (error) {
                    console.error("Error processing question document", error);
                    return null;
                  }
                })
              );

              unitList.push({
                name: unitName,
                questions: questionList.filter((question) => question !== null),
                reference: unitDoc?.ref,
                index: unitData?.index,
              });

              const filteredUnitsStack = unitList?.filter((unit) => {
                return !assignedUnits?.some(
                  (assignedUnit) => assignedUnit?.name === unit?.name
                );
              });

              filteredUnitsStack?.sort((a, b) => {
                return a?.index - b?.index;
              });

              console.log(`filteredUnitsStack`);
              console.log(filteredUnitsStack);
              setUnitsStack(filteredUnitsStack);
            }
          } catch (error) {
            console.error("Error processing unit document", error);
          }
        })
      );
    };
    getUnitsStack();
  }, []);

  const handleAddUnit = (unit) => {
    const updatedAddedUnits = [...addedUnits, unit];
    setAddedUnits(updatedAddedUnits);
  };
  const validateInputs = () => {
    let isValid = true;

    if ((courseName ?? "").trim() === "") {
      setCourseNameError("Course name is required");
      isValid = false;
    } else if (
      isCreateCoursesOpen &&
      coursesStack.some((course) => course.name === courseName)
    ) {
      setCourseNameError("Course already exists");
      isValid = false;
    } else {
      setCourseNameError("");
    }

    if ((courseDesc ?? "").trim() === "") {
      setCourseDescError("Course description is required");
      isValid = false;
    } else {
      setCourseDescError("");
    }

    if (assignedUnits?.length + addedUnits?.length === 0) {
      setAssignedUnitsError("Please assign at least one unit to the course.");
      isValid = false;
    } else {
      setAssignedUnitsError("");
    }

    return isValid;
  };

  const handleCreateAndSave = async () => {
    if (!validateInputs()) {
      return;
    }

    const courseNameInput = document?.querySelector(".course_name").value;
    const adminNotesInput = document?.querySelector(".admin_notes").value;
    const courseDescInput = document?.querySelector(".course-desc").value;

    const courseRef = await db
      ?.collection("catalog")
      ?.add({ name: courseNameInput, desc: courseDescInput });

    let allQuestionsAdded = true;
    await Promise.all(
      addedUnits?.map(async (addedUnit) => {
        const unitRef = await courseRef?.collection("units")?.add({
          name: addedUnit.name,
        });
        if (unitRef) {
          const questionPromises = addedUnit.questions.map(async (question) => {
            try {
              await unitRef.collection("questions")?.add(question);
            } catch (error) {
              allQuestionsAdded = false;
              console.error("Failed to add question:", error);
            }
          });

          await Promise.all(questionPromises);
        }
      })
    );
    if (courseRef && allQuestionsAdded) {
      setCreateCoursesOpen(false);
      setMessage("Course created successfully!");
      setShowMessage(true);
    } else {
      setMessage("Failed to create course. Please try again.");
      setShowMessage(true);
    }
    document.querySelector(".course_name").value = "";
    document.querySelector(".admin_notes").value = "";
    document.querySelector(".course-desc").value = "";
  };

  const handleRemoveUnit = (unit) => {
    const updatedAddedUnits = addedUnits?.filter(
      (addedUnits) => addedUnits?.reference !== unit?.name
    );
    setAddedUnits(updatedAddedUnits);

    const updatedAssignedUnits = assignedUnits?.filter(
      (assignedUnit) => assignedUnit?.name !== unit?.name
    );
    setAssignedUnits(updatedAssignedUnits);
    const updatedUnitsStack = [...unitsStack, unit];
    setUnitsStack(updatedUnitsStack);
  };

  const handleCourseUpdate = async () => {
    if (!validateInputs()) {
      return;
    }

    const updatedUnits = [...assignedUnits, ...addedUnits];
    const selectedCourseRefrence = selectedCourse?.courseReference;
    try {
      await selectedCourseRefrence?.update({
        name: courseName,
        desc: courseDesc,
      });

      const unitsCollectionRef = selectedCourseRefrence?.collection("units");
      const existingunitsSnapshot = await unitsCollectionRef?.get();
      const deletePromises = existingunitsSnapshot?.docs?.map((doc) =>
        doc?.ref?.delete()
      );
      await Promise.all(deletePromises);
      let allQuestionsAdded = true;

      console.log("updatedUnits", updatedUnits);
      await Promise.all(
        updatedUnits?.map(async (addedUnit) => {
          const unitRef = await selectedCourseRefrence
            ?.collection("units")
            ?.add({
              name: addedUnit.name,
            });
          if (unitRef) {
            const questionPromises = addedUnit?.questions?.map(
              async (question) => {
                try {
                  await unitRef?.collection("questions")?.add(question);
                } catch (error) {
                  allQuestionsAdded = false;
                  console.error("Failed to add question:", error);
                }
              }
            );

            await Promise.all(questionPromises);
          }
        })
      );
      if (allQuestionsAdded) {
        setViewCoursessOpen(false);
        setMessage("Course updated successfully!");
        setShowMessage(true);
      } else {
        setMessage("Failed to update course. Please try again.");
        setShowMessage(true);
        setViewCoursessOpen(false);
      }
    } catch (error) {
      console.error("Error updating course:", error);
    }
  };

  const handleToggle = () => {
    setIsOn(!isOn);
  };
  return (
    <>
      <div className="create-course-container">
        <div className="back_btn">
          <TimeSheetPrevIcon width="12" height="12" icon="backArrow" />
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              setViewCoursessOpen(false);
              setCreateCoursesOpen(false);
              setSelectedCourse(null);
            }}
          >
            Back to all Courses
          </p>
        </div>
        <div className="create-course-header">
          <div className="header">
            {isViewCoursesOpen ? (
              <label>View Course</label>
            ) : (
              <>
                <label>Create a Course</label>
                <EditIcon width="10" height="10" top="auto" bottom="auto" />
              </>
            )}
          </div>

          <div className="toggle_switch_container">
            <p>Draft Unit</p>
            <div
              className={`toggle_switch ${isOn ? "on" : "off"} ${"private"}`}
              // className={`toggle_switch ${isOn ? 'on' : 'off'} ${isEditChatGroup ? (isOn ? 'public' : 'private') : ((isPublic || isPublic === undefined) ? 'public' : 'private')}`}
              onClick={handleToggle}
            >
              <div className="toggle_handle" />
            </div>
            <p>Published Unit</p>
          </div>
        </div>

        <div className="course-container">
          <label>Course Name</label>

          <input
            type="text"
            className="course_name"
            value={courseName}
            onChange={(e) => {
              setIsAssign(true);
              setCourseName(e.target.value);
            }}
          />
          {courseNameError && (
            <span className="course-error-message">{courseNameError}</span>
          )}
        </div>
        {/* <div className="course-desc-container">
          <input
            type="text"
            class="course-desc"
            value={courseDesc}
            onChange={(e) => setCourseDesc(e.target.value)}
          />
          {courseDescError && (
            <span className="course-error-message">{courseDescError}</span>
          )}
        </div> */}

        <div className="course-desc-container">
          <textarea
            className="course-desc"
            value={courseDesc}
            onChange={(e) => setCourseDesc(e.target.value)}
            placeholder="Course Description"
          ></textarea>
          {courseDescError && (
            <span className="course-error-message">{courseDescError}</span>
          )}
        </div>
        <div
          className={`assigned-course-unit-list ${
            assignedUnits && assignedUnits.length === 0 ? "empty" : ""
          }`}
        >
          {assignedUnits
            ?.sort((a, b) => {
              console.log(a);
              return a?.index - b?.index;
            })
            ?.map((unit, index) => {
              return (
                <div className="course-unit" key={index}>
                  <div className="list-unit">
                    <ListIcon width="6" height="6" top="auto" bottom="auto" />
                    <span className="unit-text">{unit.name}</span>
                  </div>
                  <div className="xp-add-rem">
                    <span
                      className="gray-icon"
                      onClick={() => handleRemoveUnit(unit)}
                    >
                      <RemoveIcon
                        width="6"
                        height="6"
                        top="auto"
                        bottom="auto"
                      />
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="include">
          <label>Include</label>
        </div>
        <div className="search_box_container">
          <input
            type="text"
            placeholder="Search units"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="course-unit-list">
          {searchQuery &&
            unitsStack
              ?.filter((unit) =>
                unit.name.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((unit, index) => {
                return (
                  <div className="course-unit" key={index}>
                    <div className="list-unit">
                      <ListIcon width="6" height="6" top="auto" bottom="auto" />

                      <span className="unit-text">{unit.name}</span>
                    </div>
                    <div className="xp-add-rem">
                      <span
                        className="gray-icon"
                        onClick={() =>
                          isAssign
                            ? handleAddUnit(unit)
                            : handleRemoveUnit(unit)
                        }
                      >
                        {isAssign ? (
                          addedUnits.some(
                            (addedUnit) => addedUnit.name === unit.name
                          ) ? (
                            <RemoveIcon
                              width="6"
                              height="6"
                              top="auto"
                              bottom="auto"
                            />
                          ) : (
                            <AddIcon
                              width="6"
                              height="6"
                              top="auto"
                              bottom="auto"
                            />
                          )
                        ) : (
                          <RemoveIcon
                            width="6"
                            height="6"
                            top="auto"
                            bottom="auto"
                          />
                        )}
                      </span>
                    </div>
                  </div>
                );
              })}
          {assignedUnitsError && (
            <span className="unit-error-message">{assignedUnitsError}</span>
          )}
        </div>

        <div className="course-admin-notes-container">
          <textarea
            className="admin_notes"
            placeholder="Admin Notes"
            value={adminNotes}
            onChange={(e) => setAdminNotes(e.target.value)}
          ></textarea>

          {adminNotesError && (
            <span className="course-error-message">{adminNotesError}</span>
          )}
        </div>
        <div className="delete-ques-container">
          <p></p>
        </div>

        <div class="create_save_btn">
          {isViewCoursesOpen ? (
            <div className="button" onClick={handleCourseUpdate}>
              View and update
            </div>
          ) : (
            <div className="button" onClick={handleCreateAndSave}>
              Create and save
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default CreateCourse;
