import React from 'react';

const ActiveCheckinIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6564 11.4205C24.614 12.5831 24.614 15.4169 22.6564 16.5795L5.28179 26.8973C3.28202 28.0848 0.749999 26.6436 0.749999 24.3178L0.75 3.68218C0.75 1.35637 3.28203 -0.0848244 5.2818 1.10273L22.6564 11.4205Z"
        fill="#3FDD98"
      />
    </svg>
  );
};

export default ActiveCheckinIcon;
