import React, { useEffect, useState } from 'react';

const AcceptModal = ({
  closeModal,
  acceptConnection,
  db,
  selectedData,
  setCurrentUserRef2,
  acceptConnectTypes
}) => {
  const [validConnectTypes, setValidConnectTypes] = useState();
  const [disable, setDisable] = useState(false);
  const [disableUnknown, setDisableUnknown] = useState(false);
  // const [note, setNote] = useState('');
  const [inputValues, setInputValues] = useState({
    connectTypes: []
  });
  
  useEffect(() => {
    const getConnectionTypes = async () => {
      const snapshot = await db.doc(`/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/${acceptConnectTypes}`).get();
      const snapshotData = snapshot?.data();
      let userConnectTypeList = [];
      let data = await Promise.all(snapshotData?.validConTypes?.map(async (doc) => {
        const connectTypesDoc = await doc?.get();
        const connectTypesData = connectTypesDoc?.data();
        userConnectTypeList.push(connectTypesData);
      }))
      setValidConnectTypes(userConnectTypeList);
    };
    // if (connectTypes === undefined)
    getConnectionTypes();
  }, []);

  const handleCheckBox = async (e) => {
    const { value, checked } = e.target;
    const { connectTypes } = inputValues;
  
    // Case 1: The user checks the "unknown" checkbox
    if (value === 'unknown' && checked) {
      setInputValues({
        connectTypes: [value],
      });
      setDisable(false);
      setDisableUnknown(false);
      const checkboxes = document.querySelectorAll('input[type=checkbox]:not([value="unknown"])');
      checkboxes.forEach((checkbox) => (checkbox.disabled = true));
    }
    // Case 2: The user checks a checkbox other than "unknown"
    else if (checked) {
      setInputValues({
        connectTypes: [...connectTypes, value],
      });
      setDisableUnknown(true);
      if (connectTypes.includes('unknown')) {
        setDisable(true);
      }
    }
    // Case 3: The user unchecks a checkbox
    else {
      setInputValues({
        connectTypes: connectTypes.filter((e) => e !== value),
      });
      setDisable(false);
      setDisableUnknown(false);
      const checkboxes = document.querySelectorAll('input[type=checkbox]');
      checkboxes.forEach((checkbox) => (checkbox.disabled = false));
    }
  };

  return (
    <>
      <div className='modal_container'>  
        <div className='connect_modal'>
          <p
            className='close_modal'
            onClick={() => {
              closeModal();
              setCurrentUserRef2(null);
            }}
          >
            X
          </p>
          <h3>Connect to {' '}
            {selectedData?.first !== undefined || selectedData?.last !== undefined ?
            selectedData?.first + " " + selectedData?.last
            :
            selectedData?.companyName !== undefined ?
            selectedData?.companyName
            :
            'this user/company'}
          </h3>
          <p>+ How do you know {' '}
            {selectedData?.first !== undefined ?
            selectedData?.first
            :
            selectedData?.first === undefined && selectedData?.last !== undefined ?
            selectedData?.last
            :
            selectedData?.companyName !== undefined ?
            selectedData?.companyName
            :
            'this user/company'}?
          </p>
          <div className='checkbox_container'>
            {validConnectTypes &&
              validConnectTypes?.map(item => (
                <div key={item?.label}>
                  {item?.type === 'unknown' ? (
                    <div>
                      <input
                        type='checkbox'
                        name='unknown'
                        value={item?.type?.toLocaleLowerCase()}
                        onChange={(e) => handleCheckBox(e)}
                        disabled={disableUnknown}
                        checked={inputValues.connectTypes.includes(item?.type?.toLocaleLowerCase())}
                      />
                      <label className='checkbox-input'>{item?.label}</label>
                    </div>
                  ) : (
                    <div>
                      <input
                        type='checkbox'
                        name='relative'
                        value={item?.type?.toLocaleLowerCase()}
                        onChange={(e) => handleCheckBox(e)}
                        disabled={disable}
                        checked={inputValues.connectTypes.includes(item?.type?.toLocaleLowerCase())}
                      />
                      <label className='checkbox-input'>{item?.label}</label>
                    </div>
                  )}
                </div>
              ))
            }
            <button
              className='connect_btn'
              onClick={() => {
                acceptConnection(selectedData?.id, inputValues.connectTypes);
                closeModal();
              }}
            >
              Connect
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptModal;