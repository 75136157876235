import React, { useContext, useState } from 'react';
import Spinner from '../spinner/Spinner';
import OratioContext from '../../oratioContext/OratioContext';
import ConfirmationModal from '../modal/DisconnectModal';
import NoAvatar from '../noAvatar/NoAvatar';

const CompanyConnections = ({
  companyConnections,
  switchWindowDiscussion,
  loading,
  // createChatGroup,
  disconnect,
  truncateString,
  setActiveTab
}) => {
  const [isOptions, setIsOptions] = useState(false);
  const [currConnectionId, setCurrConnectionId] = useState('');
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  // destructures prop/value from the OratioContext component
  const {
    setConnectionId,
    setConnectionObj,
    connectionObj
  } = useContext(OratioContext);

  // moves to the message section when the message button is clicked
  const switchToMsgSection = connection => {
    switchWindowDiscussion();
    setConnectionId(connection?.id);
    setConnectionObj(connection);
    setActiveTab('Discussions');
  };

  // pulls down the options' dropdown
  const updateOptionsData = connection => {
    setIsOptions(prev => !prev);
    setCurrConnectionId(connection?.id);
    setConnectionId(connection?.id);
    setConnectionObj(null);
  };

  return(
    <div className='companies-connections'>
      {loading ?
        <Spinner
          width='6.25rem'
          height='6.25rem'
          overlayHeight='50vh'
        />
        :
        companyConnections?.map((connection) => {
          return(<div className="comp-card" key={connection.id}>
            {connection?.profile?.logoUrl ?
              <div className="comp-avatar">
                <img src={connection?.profile?.logoUrl} className='avatar_image' alt="logo" />
              </div>
              :
              <div className="comp-avatar">
                <NoAvatar text={connection?.crm?.data?.properties?.name?.value ?? connection?.profile?.name} size={3.5} radius={50} />
              </div>
            }
            <div className="info-section">  
              <div className="comp-info">
                <p className='title'>
                  {truncateString(connection?.crm?.data?.properties?.name?.value ?? connection?.profile?.name, 41)}
                </p>
                {connection?.types?.map((item, index) => {
                  return (<small key={index}> | {item}</small>)
                })} 
                <p className='motto'>{connection?.profile?.motto ??' No motto'}</p>
              </div>
              <div className="comp-desc">
                <p>{connection?.crm?.data?.properties?.description?.value ?? 'No description'}</p>
              </div>          
            </div>
            <div className="message-btn">
              {connection?.reciprocal &&
                <>
                  <div
                    className="bn3"
                    onClick={() => switchToMsgSection(connection)}
                  >
                    Message
                  </div>
                  <div
                    className="comp_options"
                  >
                    <svg
                      onClick={() => updateOptionsData(connection)}
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="currentColor" className="mercado-match" width="24" height="24" focusable="false"
                    >
                      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                    </svg>
                    {(isOptions && currConnectionId === connection?.id) &&
                      <div
                        className="disconnect_btn" 
                        // onClick={() => disconnect(connection?.connectionRef?.path)}
                        onClick={() => {
                          setIsConfirmModal(true);
                          setConnectionObj(connection);
                        }}
                      >
                        Disconnect
                      </div>
                    }
                  </div>
                </>
              }
            </div>  
          </div>)
        })
      }
      {/* confirmation modal */}
      {isConfirmModal &&
        <ConfirmationModal
          connectionObj={connectionObj}
          closeModal={() => setIsConfirmModal(false)}
          disconnect={disconnect}
        />
      }
    </div>
  );
};

export default CompanyConnections;