import { useEffect, useState } from "react";
import { VideoOnSvg, VideoOffSvg } from "../../UserAnswers/Svg";
import { saveStateForXMinutes } from "../../helpers/localStorage";

export default function VideoToggle({ show, isVideoOn, setIsVideoOn }) {
  if (show !== true) {
    return <></>
  }

  return (
    <>
      {isVideoOn ? (
        <VideoOn setIsVideoOn={setIsVideoOn} />
      ) : (
        <VideoOff setIsVideoOn={setIsVideoOn} />
      )}
    </>
  );
  }
  

function VideoOn({ setIsVideoOn }) {
  return (
    <>
    <div
      style={{
        background: "white",
        padding: "2px",
        borderRadius: "100px",
        height: "48px",
        width: "48px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: "80px",
      }}
      onClick={() => {
        setIsVideoOn(false);
        saveStateForXMinutes('isVideoOn', false, 30);
      }}
    >
      <VideoOnSvg />
    </div>
  </>
  

  );
}

function VideoOff({ setIsVideoOn }) {
  return (
    <>
      <div
        style={{
            background: "white",
            padding: "2px",
            borderRadius: "100px",
            height: "48px",
            width: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            right: "80px", 
        }}
        onClick={() => {
            setIsVideoOn(true);
            saveStateForXMinutes('isVideoOn', true, 30);
        }}
      >
         <VideoOffSvg/>
        </div>
        
    </>
  );
}
