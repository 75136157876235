import React from 'react';
import NoAvatar from '../noAvatar/NoAvatar';
import OptionsIcon from '../svgs/OptionsIcon';

const GroupMemberSection = ({
  currentMembers,
  isAdmin,
  isAdmin2,
  truncateString,
  showAddUser,
  setShowAddUser,
  handleSearchUsers,
  users,
  showDropdown,
  setShowDropdown,
  addUsersToGroupChat,
  usersSearchTerm,
  addUserBtn,
  setNewGroupModal,
  setIsAddChannel,
  setIsCreateGroup,
  setIsEditChatGroup,
  chatObj,
  setShowMessageSection,
  setShowGroupChatSection,
  optionsModal
}) => {

  // opens the cogwheel/options modal
  const invokeOptionsModal = () => {
    setNewGroupModal(true);
    setIsAddChannel(true);
    setIsCreateGroup(false);
    setIsEditChatGroup(false);
    setShowMessageSection(false);
    setShowGroupChatSection(true);
  };
  
  return(
    <>
      <div className='group_mem_container'>
        {currentMembers?.length > 0 && <h5>Group Members</h5>}
        <div className="group-users-show">
          {currentMembers?.map((item) => {
            return(
              <div
                className="group-user-single"
                key={item?.ref?.id}
              >
                <div className="group-user-avatar">
                  {item?.avatar || item?.profile?.logoUrl || item?.logoStorage ?
                    <img
                      src={item?.avatar ?? item?.profile?.logoUrl ?? item?.logoStorage}
                      className={currentMembers?.length > 3 ? "multi_group_avatar_image" : "group_avatar_image"}
                      alt="avatar"
                    />
                    :
                    <NoAvatar
                      text={item?.first ?? item?.last}
                      size={currentMembers?.length > 3 ? 2 : 3}
                      radius={0}
                    />
                  }
                </div>
                <div className={currentMembers?.length > 3 ? "multi_group_mem_name" : "group_mem_name"}>
                  {item?.first === undefined || item?.last === undefined ?
                    truncateString(item?.crm?.data?.properties?.name?.value ?? item?.profile?.name ?? item?.name, 21)
                    :
                    truncateString(item?.first + ' ' + item?.last ?? '', 21)
                  }
                </div>
              </div>
            )
          })}
          <div className='add_user_container'>
            {(isAdmin || isAdmin2) && addUserBtn ?
              <div className='add_btns'>
                {!chatObj?.isChannel &&
                  <button
                    className='add_channel'
                    onClick={invokeOptionsModal}
                  >
                    Add Channel
                  </button>
                }
                <button className='add_user' onClick={() => { setShowAddUser(prev => !prev) }}>+</button>
                <div
                  className='options_icon'
                  onClick={() => optionsModal(chatObj)}
                >
                  <OptionsIcon
                    width='20'
                    height='20'
                    color='#000000'
                  />
                </div>
              </div>
              :
              addUserBtn ?
              <>
                {!chatObj?.isChannel &&
                  <div className='add_btns'>
                    <button
                      className='add_channel'
                      onClick={invokeOptionsModal}
                    >
                      Add Channel
                    </button>
                    <div
                      className='options_icon'
                      onClick={() => optionsModal(chatObj)}
                    >
                      <OptionsIcon
                        width='20'
                        height='20'
                        color='#000000'
                      />
                    </div>
                  </div>
                }
              </>
              :
              null
            }
            {showAddUser && 
              <div className="group_search_box">
                <input
                  type="text"
                  placeholder="Search users"
                  onChange={handleSearchUsers}
                />
                {(users.length > 0 && !showDropdown) ?
                  <div className='users_search_dropdown'>
                    {users?.map((item, idx) => (
                      <div
                        key={idx}
                        className='group_search_results'
                      >
                        {item?.avatar ?
                          <img src={item?.avatar} className='group_search_results_avatar' alt="avatar" />
                          :
                          <div className='no_avatar_container'>
                            <NoAvatar
                              text={item?.first ?? item?.last}
                              size={1.875}
                              radius={50}
                            />
                          </div>
                        }
                        <div>
                          <p>
                            {item?.groupName !== undefined ?
                            item?.groupName
                            :
                            item?.first !== undefined || item?.last !== undefined ?
                            truncateString(item?.first + ' ' + item?.last, 21)
                            :
                            null}
                          </p>
                          <p>
                            {item?.description !== undefined ?
                            item?.description ?? 'No chat description'
                            :
                            item?.email !== undefined ?
                            item?.email
                            :
                            null}
                          </p>
                        </div>
                        <button
                          className='add_user_btn'
                          onClick={(e) => {
                            addUsersToGroupChat(e, item?.ref?.path);
                            setShowDropdown(true);
                          }}
                        >
                          Add +
                        </button>
                      </div>
                    ))}
                  </div>
                  :
                  (usersSearchTerm !== '' && !showDropdown) && <div className='group_no_data_found'>No matching data found!</div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupMemberSection;