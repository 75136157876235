import React, { useContext, useState } from 'react';
import logo from '../../static/nestria/astronaut.webp';
import eye from '../../static/eye.png';
import ResetPasswordModal from '../../components/modal/ResetPasswordModal';
import OratioContext from '../../oratioContext/OratioContext';
import { Mixpanel } from '../../components/nestria/helpers/Mixpanel';

const NestriaLogin = ({ auth, loginVisible, setLoginVisible, setRegisterVisible }) => {
  const [isPassShown, setIsPassShown] = useState(false);
  const [emailErrMsg, setEmailErrMsg] = useState('');
  const [passwordErrMsg, setPasswordErrMsg] = useState('');
  const [resetPassSuccess, setResetPassSuccess] = useState('');
  const [showResetPassModal, setShowResetPassModal] = useState(false);
  const [inputValues, setInputValues] = useState({
    email: '',
    password: ''
  });

  const handleChange = e => {
    setInputValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const signIn = async (e) => {
    e.preventDefault();

    try{
      await auth.signInWithEmailAndPassword(inputValues.email, inputValues.password);
    }catch(err){
      if (err.code === 'auth/user-not-found') {
        Mixpanel.track("LoginAttempt", {"errorCode": err.code});
        setEmailErrMsg('There is no user record corresponding to this identifier. The user may have been deleted.');
        setPasswordErrMsg('');
      } else if (err.code === 'auth/wrong-password') {
        Mixpanel.track("LoginAttempt", {"errorCode": err.code});
        setPasswordErrMsg('The password is invalid or the user does not have a password.');
        setEmailErrMsg('');
      } else {
        Mixpanel.track("LoginAttempt", {"errorCode": err.code, "err": err, "errorMsg": err?.message});
        setEmailErrMsg('Something went wrong, try again.');
      }
    }
    setResetPassSuccess('');
  };

  return(
    <>
      {loginVisible &&
        <div className='login_contents'>
          <div className='spryte'>
          </div>
          <div className='login_container'>
            <form>
              <div className='logo_container' style={{ height:'80px', width: '80px', marginBottom: '50px'}}>
                <img src={logo} alt='Nestria logo' />
              </div>
              {/* <h3>Nestria</h3> */}
              <div className='input_container'>
                <label>Email</label>
                <input
                  type='text'
                  name='email'
                  placeholder='Enter your email...'
                  value={inputValues.email}
                  onChange={handleChange}
                />
                <span>{emailErrMsg}</span>
                <span>{resetPassSuccess}</span>
              </div>
              <div className='input_container'>
                <label>Password</label>
                <input
                  type={isPassShown ? 'text' : 'password'}
                  name='password'
                  placeholder='Enter your password...'
                  value={inputValues.password}
                  onChange={handleChange}
                />
                <img
                  src={eye}
                  alt='password icon'
                  className={isPassShown ? 'shown_password' : ''}
                  onClick={() => setIsPassShown(prev => !prev)}
                />
                <span className='password_error_msg'>{passwordErrMsg}</span>
              </div>
              <div className='signIn_btn'>
                <button
                  onClick={signIn}
                >
                  Login
                </button>
                <div className='forgot_password_account'>
                  <p onClick={() => setShowResetPassModal(true)}>Forgot Password?</p>
                  <p>OR</p>
                  <p>Don't have an account?</p>
                </div>
                <div className='register_btn_container'>
                  <button
                    type='button'
                    onClick={() => {
                      setLoginVisible(false);
                      setRegisterVisible(true);
                    }}
                  >
                    Register
                  </button>
                </div>
              </div>
            </form>
          </div>

          {/* reset password modal */}
          {showResetPassModal &&
            <ResetPasswordModal
              auth={auth}
              setResetPassSuccess={setResetPassSuccess}
              closeModal={() => setShowResetPassModal(false)}
            />
          }
        </div>
      }
    </>
  );
};

export default NestriaLogin;