import React from 'react';

const AttachmentIcon = ({ width, height, isAttachmentIcon }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 124 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{marginLeft: '0.15rem'}}
    >
      <rect x="0.893555"
        width="122.143"
        height="114"
        fill={isAttachmentIcon ? '#656667' : '#F2F2F2'}
      />
      <path
        d="M79.0802 53.4888L62.5989 38.519C57.5421 33.926 49.4887 33.926 44.6192 38.519C39.5625 43.112 39.5625 50.4268 44.6192 54.8496L68.5921 76.6239C71.9633 79.6859 77.2073 79.6859 80.5785 76.6239C83.9497 73.5619 83.9497 68.7988 80.5785 65.7368L59.6023 46.6843C57.9167 45.1533 55.2946 45.1533 53.6091 46.6843C51.9235 48.2153 51.9235 50.5969 53.6091 52.1279L67.0938 64.3759"
        stroke={isAttachmentIcon ? 'white' : 'black'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AttachmentIcon;
