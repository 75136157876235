import React, { useEffect, useState } from "react";
import { LessonCompletionSvg0, LessonCompletionSvg1, LessonCompletionSvg2, LessonCompletionSvg3 } from "../helpers/Svgs";

export const LessonCompletionSvg = ({
  status,
  unitQuestions,
  style = {},
}) => {

  const [currentPercentComplete, setCurrentPercentComplete] = useState<number>();

  const getCompletionSvg = (currentPercentComplete) => {
    if (currentPercentComplete === 0 || currentPercentComplete < 25) {
      return <LessonCompletionSvg0 style={style} />;
    } else if (currentPercentComplete >= 25 && currentPercentComplete <= 50) {
      return <LessonCompletionSvg1 style={style} />;
    } else if (currentPercentComplete > 50 && currentPercentComplete <= 75) {
      return <LessonCompletionSvg2 style={style} />;
    } else if (currentPercentComplete > 75) {
      return <LessonCompletionSvg3 style={style} />;
    } else {
      return <></>;
    }
  }

  useEffect(() => {
    let questionsAnswered = 0;
    Promise.all(unitQuestions.map((question) => {

      if (question.isAnswered === true) {
        questionsAnswered += 1;
      }
    }))
      .then(() => {
        const _currentPercentComplete = Math.round((questionsAnswered / unitQuestions.length) * 100);
        setCurrentPercentComplete(_currentPercentComplete);
      })
      .catch((e) => {
        console.error(e);
      })
  }, [unitQuestions]);

  if (status === "ACTIVE") {
    return <>
      {currentPercentComplete !== null &&
        getCompletionSvg(currentPercentComplete)
      }
    </>;
  } else {
    return <></>;
  }
};