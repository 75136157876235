import React, { useEffect, useState } from 'react';
import DeleteModal from '../modal/DeleteModal';
import MessageIcon from '../svgs/MessageIcon';
import EmailIcon from '../svgs/EmailIcon';
import EmailTextArea from '../emailTextArea/EmailTextArea';
import AttachmentIcon from '../svgs/AttachmentIcon';
import pdfLogo from '../../static/pdf.png';
import docxIcon from '../../static/docx-icon.png';
import fileIcon from '../../static/file-icon.png';
import UnreadReceiptsIcon from '../svgs/UnreadReceiptsIcon';
import ReadReceiptsIcon from '../svgs/ReadReceiptsIcon';
import DeleteIcon from '../svgs/DeleteIcon';
import EditIcon from '../svgs/EditIcon';
import MessageTextArea from '../messageTextArea/MessageTextArea';
import NoAvatar from '../noAvatar/NoAvatar';

const MessageSection = ({
  currentMessages,
  newMessage,
  setNewMessage,
  imagePaste,
  handleKeypress,
  addMessage,
  files,
  setFiles,
  isAttachmentIcon,
  setIsAttachmentIcon,
  filePreview,
  setFilePreview,
  firebase,
  db,
  chatObj,
  userId,
  myDiscussion,
  pubDiscussion,
  allDiscussion,
  isAdmin,
  isAdmin2,
  chatMembersRef,
  setChatMembersRef,
  truncateString,
  msgReceivedRef,
  updateReadReceipts,
  nextUserData,
  nextUserRef,
  currentMembers,
  scrolledUp
}) => {
  const [showEditInput, setShowEditInput] = useState(false);
  const [currentMessageId, setCurrentMessageId] = useState('');
  const [currentMsg, setCurrentMsg] = useState('');
  const [deleteMsgModal, setDeleteMsgModal] = useState(false);
  const [messageObj, setMessageObj] = useState(null);
  const [isMessageTextArea, setIsMessageTextArea] = useState(true);
  const [isEmailTextArea, setIsEmailTextArea] = useState(false);
  const [to, setTo] = useState('');
  const [cc, setCc] = useState('');
  const [bcc, setBcc] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [emailErrMsg, setEmailErrMsg] = useState('');
  const [imageFiles, setImageFiles] = useState([]);
  const [ currUserData, setCurrUserData] = useState(null);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  // const ref = useRef(null);

  // user email forwarding data from the db
  useEffect(() => {
    const getCurrUserData =async () => {
      // current user reference
      const currUserRef = db.doc(`users/${userId}`);
      // users data
      const currUserDoc = await currUserRef?.get();
      const currentUserData = currUserDoc?.data();
      const currUserObj = { ...currentUserData, ref: currUserRef };
      setCurrUserData(currUserObj);
    };
    if (currUserData !== null) return;
    getCurrUserData();
  }, [currUserData]);

  // chat members in a specific chat group
  useEffect(() => {
    const membersRef = [];
    chatObj?.members?.map(member => {
      membersRef.push(member?.path);
    });
    setChatMembersRef(membersRef);
  }, [chatObj]);

  
  // converts a url to a hyperlink
  // const urlify = (text) => {
    //   const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    //   //const urlRegex = /(https?:\/\/[^\s]+)/g;
    //   return text.replace(urlRegex, function(url,b,c) {
      //       const url2 = (c == 'www.') ?  'http://' + url : url;
      //       return '<a href="' + url2 + '" target="_blank">' + url + '</a>';
      //   })
      // };
      
  // checks if a string is a url
  // const hyperLinkRegex = /(http|https|www)/;
  const hyperLinkRegex2 = /(((https?:\/\/)|(www\.))[^\s]+)/g;

  const urlify = (text) => {
    if (!text) {
      return ""; // returns an empty string instead if text is falsy (null, '', undefined, 0, NaN or false)
    };
    
    const urlRegex = /((?:www|(?:https?|ftp):\/\/)[^\s]+(?<![.,;!?]))/g;
    return text.replace(urlRegex, (url) => {
      const hasProtocol = /^https?:\/\//i.test(url) || /^ftp:\/\//i.test(url);
      const hasWww = /^www\./i.test(url);
      const hasTld = /\.(co|com|io|app|net|org|edu|gov|biz|info|mil|name|pro|aero|asia|cat|coop|int|jobs|mobi|museum|post|tel|travel)$/i.test(url);
      if (hasProtocol || hasWww || hasTld) {
        if (!hasProtocol) {
          url = `http://${url}`;
        }
        return `<a href="${url}" target="_blank">${url}</a>`;
      } else {
        return url;
      }
    });
  };   
  
  // const handleScroll = () => {
  // ref.current?.scrollIntoView({behavior: 'smooth'});
  // };

  // updates currentMsg state on message input change
  const handleEditMessage = e => {
    setCurrentMsg(e.target.value);
  };

  // updates messages when edited and saved
  const editMessage = async (e, ref) => {
    e.preventDefault();

    const updateMessageData = { 
      msg: currentMsg,
      // file: file,
      isEdited: true,
      updatedOn: new Date()
    };

    await db.doc(ref).set(updateMessageData, { merge: true });

    setShowEditInput(false);
  };

  // // removes a specific message obj
  // const deleteMessage = async (e, ref) => {
  //   e.preventDefault();
  //   // console.log('ref', ref);

  //   await db.doc(ref).delete();
  // };

  // opens base64 url on a new tab
  const debugBase64 = (base64URL) => {
    var win = window.open();
    win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  };

  // handle file attachments for emails
  const imageMimeType = /image\/(png|jpg|jpeg|svg)/i;
  const pdfMimeType = /application\/(pdf)/i;
  const docxMimeType = /application\/(msword|vnd.openxmlformats-officedocument.wordprocessingml.document)/i;
  // initializes the cloud storage
  const storage = firebase.storage();
  
  // handles file(s) upload, saves the files and their corresponding previous to state variables
  const attachFile = async e => {
    const files = e.target.files;
    const validImageFiles = [];
    const filePreviews = [];
    
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file?.type?.match(imageMimeType) || file?.type?.match(pdfMimeType) || file?.type?.match(docxMimeType)) {
        validImageFiles.push(file);
        const preview = URL.createObjectURL(file);
        const previewObj = { preview: preview, fileType: file?.type };
        filePreviews.push(previewObj);
      } else {
        setEmailErrMsg('Invalid file(s)');
      };
    };
    setImageFiles(validImageFiles);
    setFilePreview(filePreviews);
  };

  // upload files to storage and get theur download url
  useEffect(() => {
    const getDownloadUrl = () => {
      if (imageFiles?.length > 0) {
        const selectedFiles = [];
        const currentDate = new Date();
        imageFiles?.map(async (image) => {
          const path = chatObj?.ref?.path;
          const ref = storage.ref(`${path}/${image?.name}`);
          await ref.put(image);
          const url = await ref.getDownloadURL();
          const uniqueFileName = image?.name + ' ' + currentDate;
          const fileObj = {
            fileName: uniqueFileName,
            path: url,
            fileType: image?.type
          };
          selectedFiles.push(fileObj);
        })
        setFiles(selectedFiles);
      }
    };
    getDownloadUrl();
  }, [imageFiles]);

    // remove nested array(s) using JS flat()
    const flattenedNextUserData = nextUserData.flat(2);
    let nextUserEmail = '';
    flattenedNextUserData?.map(data => {
      if (data?.nextUserRef?.path === nextUserRef?.path) {
        // assings the selected user's email to a var from nextUserData
        nextUserEmail = data?.email;
      };
    });

  // sends an email
  const sendEmail = async (e) => {
    e.preventDefault();

    // chats email data
    const documentRef = chatObj?.ref?.path;
    const authorRef = db.doc(`/users/${userId}`);

    const chatEmailData = {
      author : authorRef , 
      ts : new Date(),
      // file : file,
      // to: [to],
      to: [nextUserEmail],
      cc: [cc],
      bcc: [bcc],
      message: {
        subject: subject,
        html: message,
        attachments: files
      },
      received: false
    };

    const userEmailFwdChatData = {
      author : authorRef , 
      ts : new Date(),
      to: [nextUserEmail],
      cc: [cc],
      bcc: [bcc],
      message: {
        subject: subject,
        html: message,
        attachments: files
      },
      fwdThrough: true,
      received: false
    };

    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
    if (!emailRegex.test(to) && to !== '') {
      setEmailErrMsg(`${to} is not valid email address`);
    } else if (!emailRegex.test(cc) && cc !== '') {
      setEmailErrMsg(`${cc} is not valid email address`);
    } else if (!emailRegex.test(bcc) && bcc !== '') {
      setEmailErrMsg(`${bcc} is not valid email address`);
    } else if (nextUserEmail === '') {
      setEmailErrMsg('"To" field cannot be empty!')
    } else {
      if (currUserData?.emailFwd?.status) {
        // drops email in chats col with fwdThrough key set to true
        await db.collection(`${documentRef}/msg/`).add(userEmailFwdChatData);
      } else {
        // drops email in chats col 
        await db.collection(`${documentRef}/msg/`).add(chatEmailData);
      };

      setTo('');
      setCc('');
      setBcc('');
      setSubject('');
      setMessage('');
      setFiles([]);
      setFilePreview([]);
      setIsAttachmentIcon(false);
    };
  };

  // removes previewed file when the x button is clicked
  const resetFilePreview = (prev, idx) => {
    if (prev?.fileType !== '') {
      // make a copy of the state object
      const newPreviewState = [...filePreview];
      // splice out element of given index
      newPreviewState.splice(idx, 1);
      // set the new state
      setFilePreview(newPreviewState);
    };
  };

  // adds chatObj and chat member refs (refs for all members in a selected group chat) in the current messages array
  const currMessages = currentMessages?.map(msg => {
    const flattenedChatMemRef = chatMembersRef?.flat(2);
    const uniqueChatMemRef = [...new Set(flattenedChatMemRef)];
    return { msg, uniqueChatMemRef, chatObj };
  });

  // get the selected chat group members from the chatObj
  // const selectedChatGroupMembers = chatObj?.selectedGroupChatMsgs?.map(groupMsg => {
  //   return groupMsg?.members?.map(mem => {
  //     // console.log('mem', mem?.path);
  //     return mem?.path;
  //   });
  // });

  // remove nested array(s) using JS flat()
  // remove duplicates using Set()
  // const flattenedSelChatGroupMems = selectedChatGroupMembers?.flat(2);
  // const uniqueSelChatGroupMems = [...new Set(flattenedSelChatGroupMems)];

  // next user reference and current members references
  let nextUserReference = {};
  let currentMemReferences = [];
  currentMembers?.map(mem => {
    const currUserRef = db.doc(`users/${userId}`);
    if (mem?.ref?.path !== currUserRef?.path) {
      nextUserReference = mem?.ref;
    }
    // current members references
    currentMemReferences.push(mem?.ref);
  });

  // next user reference and current members references
  // let nextUserReference = {};
  // let currentMemReferences = [];
  // currentMembers?.map(mem => {
  //   const currUserRef = db.doc(`users/${userId}`);
  //   if (mem?.ref?.path !== currUserRef?.path) {
  //     nextUserReference = mem?.ref;
  //   }
  //   // current members references
  //   currentMemReferences.push(mem?.ref);
  // });

  const messageMemberRefs = [];
  let uniqueMsgMemberRefs = [];

  const handleIconHover = (icon) => {
    setHoveredIcon(icon);
  };

  const handleIconLeave = () => {
    setHoveredIcon(null);
  };

  return (
    <>
      <div
        className="message_section"
        onScroll={() => {
          updateReadReceipts(currentMessages[0]);
          scrolledUp();
        }}
      >
        {currMessages?.map((item, idx) => {
          const msg = item?.msg?.msg;
          const urlifiedMsg = urlify(msg);

          return (
            <div
              className="message_contents"
              key={idx}
            >
              {" "}
                <> 
                {item?.msg?.message ?
                  <>
                    <div className="msg-user-avatar">
                      {item?.msg?.avatar ?
                        <img src={item?.msg?.avatar} className="msg-avatar_image" alt="avatar" />
                        :
                        <NoAvatar
                          text={item?.msg?.last ?? item?.msg?.first}
                          size={3}
                          radius={0}
                        />
                      }
                    </div>
                    <div className='mail_info_container'>
                      <div className="mail-info-section">
                        <div className='author_identity'>
                          <div className='name_email_container'>
                            <p>{truncateString(item?.msg?.last + ' ' + item?.msg?.first, 21)}{' | '}</p>
                            <span> {item?.msg?.email}</span>
                          </div>
                          {item?.msg?.msg !== undefined ?
                            <small>{item?.msg?.msg}</small>
                            :
                            <small>Sent at {item?.msg?.ts?.toDate().toString().substring(0, 24).replace("2022"," ")}</small>
                          }
                        </div>
                        <div className='mail_contents'>
                          <div className='to_cc_bcc_container'>
                            {item?.msg?.to.join(', ').length > 0 && <span>To: {item?.msg?.to?.join(', ')}</span>}
                            {' '}
                            {item?.msg?.cc.join(', ').length > 0 && <span>Cc: {item?.msg?.cc?.join(', ')}</span>}
                            {' '}
                            {item?.msg?.bcc.join(', ').length > 0 && <span>Bcc: {item?.msg?.bcc?.join(', ')}</span>}
                          </div>
                          <div className='subject'>
                            Email: <b>{item?.msg?.message?.subject}</b>
                          </div>
                          <div className='body'>
                            <p>{item?.msg?.message?.html}</p>
                          </div>
                          {item?.msg?.message?.attachments?.length > 0 &&
                            <div className='attachment_container'>
                              <p>{item?.msg?.message?.attachments?.length} Attachments</p>
                              <div className='attachments'>
                                  {item?.msg?.message?.attachments?.map((file, i) => (
                                    <div
                                      className='pdf_container'
                                      key={i}
                                      // onClick={() => debugBase64(file.path)}
                                    >
                                      {file?.fileType === "application/pdf" ?
                                        <div className='file_data'>
                                          <img
                                            src={pdfLogo}
                                            alt="pdf_logo"
                                          />
                                          <span>{truncateString(file?.fileName, 20)}</span>
                                        </div>
                                        :
                                        file?.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                        file?.fileType === "application/msword" ?
                                        <div className='file_data'>
                                          <img
                                            src={docxIcon}
                                            alt="docx_logo"
                                          />
                                          <span>{truncateString(file?.fileName, 20)}</span>
                                        </div>
                                        :
                                        <div className='file_data'>
                                          <img
                                            src={fileIcon}
                                            alt="file_logo"
                                          />
                                          <span>{truncateString(file?.fileName, 20)}</span>
                                        </div>
                                      }
                                      <div className='pdf_downloap_link'>
                                        <a href={file?.path} target='_blank'>Download file</a>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    {item?.chatObj?.members?.map(mem => {
                      messageMemberRefs.push(mem?.path);
                      uniqueMsgMemberRefs = [...new Set(messageMemberRefs)];
                    })}
                      <>
                        <div className="msg-user-avatar">
                          {item?.msg?.avatar ?
                            <img src={item?.msg?.avatar} className="msg-avatar_image" alt="avatar" />
                            :
                            <NoAvatar
                              text={item?.msg?.last ?? item?.msg?.first}
                              size={3}
                              radius={0}
                            />
                          }
                        </div>
                        <div
                          className='msg_info_container'
                          ref={msgReceivedRef}
                        >
                          <div className="msg-info-section">
                            <div className='name_edit'>
                              <p>
                                {truncateString(item?.msg?.last + ' ' + item?.msg?.first, 21)}
                                {' | '}
                                <small>{item?.msg?.ts?.toDate().toString().substring(0, 24).replace("2022"," ")}</small>
                              </p>
                              {/* show cancel, edit, and delete msg buttons if current user */}
                              {userId === item?.msg?.author?.id &&
                                <>
                                  <div
                                    className='edit_icon'
                                    onClick={() => {
                                      setShowEditInput(true);
                                      setCurrentMessageId(item?.msg?.ref?.id);
                                      setCurrentMsg(item?.msg?.msg);
                                    }}
                                  >
                                    <EditIcon
                                      width="10"
                                      height="10"
                                    />
                                  </div>
                                  <div
                                    className='delete_msg_icon'
                                    onClick={() => {
                                      setMessageObj(item?.msg);
                                      setDeleteMsgModal(true);
                                    }}
                                  >
                                    <DeleteIcon
                                      width="18"
                                      height="18"
                                    />
                                  </div>
                                </>
                              }
                            </div>
                            <div
                              style={{ fontSize: "smaller", color: "#808080", fontWeight: 400 }}
                            >
                              {item?.msg?.msg &&
                                <div className={ item?.msg?.author?.id === userId ? 'msg_section author_msg_section' : 'msg_section'}>
                                  {hyperLinkRegex2.test(String(item?.msg?.msg).toLowerCase()) === true ?
                                    // <a title={`go to ${item?.msg?.msg}`} href={item?.msg?.msg} target='blank'>{item?.msg?.msg}</a>
                                    // <a title={`go to ${item?.msg?.msg}`} href={urlify(item?.msg?.msg)} target='blank'>{item?.msg?.msg}</a>
                                    <div dangerouslySetInnerHTML={{__html: urlifiedMsg}} />
                                    :
                                    item?.msg?.msg
                                  }
                                  {item?.msg?.isEdited && <small className='edited'>{`(edited)`}</small>}
                                  {item?.msg?.author?.id === userId &&
                                    <>
                                      {item?.msg?.received === true ?
                                        <div className='read_receipts_container'>
                                          <ReadReceiptsIcon
                                            width='12'
                                            height='8'
                                          />
                                        </div>
                                        :
                                        <div className='unread_receipts_conatainer'>
                                          <UnreadReceiptsIcon
                                            width='12'
                                            height='8'
                                          />
                                        </div>
                                      }
                                    </>
                                  }
                                </div>
                              }
                            </div>
                            {item?.msg?.file?.files ?
                              <div className='file_container'>
                                {item?.msg?.file?.files?.map((file, i) => (
                                  // <div
                                  //   className='file_content'
                                  //   key={i}
                                  // >
                                  //   <div className='file_data'>
                                  //     <img
                                  //       src={file?.path}
                                  //       alt={truncateString(file?.fileName, 20)}
                                  //     />
                                  //     <span>{truncateString(file?.fileName, 20)}</span>
                                  //   </div>
                                  //   <div className='downloap_link'>
                                  //     <a href={file?.path} target='_blank'>Download file</a>
                                  //   </div>
                                  // </div>
                                  <div
                                    className='file_content'
                                    key={i}
                                  >
                                    {file?.fileType === "application/pdf" ?
                                      <div className='file_data'>
                                        <img
                                          src={pdfLogo}
                                          alt={truncateString(file?.fileName, 20)}
                                        />
                                        <span>{truncateString(file?.fileName, 20)}</span>
                                      </div>
                                      :
                                      file?.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                      file?.fileType === "application/msword" ?
                                      <div className='file_data'>
                                        <img
                                          src={docxIcon}
                                          alt={truncateString(file?.fileName, 20)}
                                        />
                                        <span>{truncateString(file?.fileName, 20)}</span>
                                      </div>
                                      :
                                      <div className='file_data'>
                                        <img
                                          src={fileIcon}
                                          alt={truncateString(file?.fileName, 20)}
                                        />
                                        <span>{truncateString(file?.fileName, 20)}</span>
                                      </div>
                                    }
                                    <div className='pdf_downloap_link'>
                                      <a href={file?.path} target='_blank'>Download file</a>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              :
                              <>
                                {item?.msg?.file &&
                                  <img
                                    src={item?.msg?.file}
                                    className="image-message"
                                    alt='img'
                                    onClick={() => debugBase64(item?.msg?.file)}
                                  />
                                }
                              </>
                            }
                            {/* <div ref={ref}></div> */}
                          </div>
                          {userId === item?.msg?.author?.id &&
                            <div className='save_cancel'>
                              {(showEditInput && currentMessageId === item?.msg?.ref?.id) &&
                                <>
                                  <input
                                    type="text"
                                    className="edit_msg_input"
                                    value={currentMsg}
                                    onChange={handleEditMessage}
                                  />
                                  <div>
                                    <button
                                      className='cancel_btn'
                                      onClick={() => {
                                        setShowEditInput(false);
                                        setCurrentMessageId('');
                                        setCurrentMsg('');
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className='save_btn'
                                      onClick={(e) => editMessage(e, item?.msg?.ref?.path)}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </>
                              }
                            </div>
                          }
                        </div>
                      </>
                    {/* } */}
                  </>
                }
              </>
            </div>
          )
        })}
      </div>
      {isMessageTextArea &&
        <MessageTextArea
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          imagePaste={imagePaste}
          handleKeypress={handleKeypress}
        />
      }
      {isEmailTextArea &&
        <EmailTextArea
          to={to}
          setTo={setTo}
          cc={cc}
          setCc={setCc}
          bcc={bcc}
          setBcc={setBcc}
          subject={subject}
          setSubject={setSubject}
          message={message}
          setMessage={setMessage}
          nextUserRef={nextUserRef}
          nextUserData={nextUserData}
          nextUserEmail={nextUserEmail}
        />
      }
      {filePreview?.length > 0 &&
        <div
          className='file_preview_container'
        >
          {filePreview?.map((prev, i) => (
            <div key={i}>
              {prev?.fileType === "application/pdf" ?
              <>
                <img src={pdfLogo} alt='pdf_logo' />
                <span
                  onClick={() => resetFilePreview(prev, i)}
                >
                  x
                </span>
              </>
              :
              prev?.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              prev?.fileType === "application/msword" ?
              <>
                <img src={docxIcon} alt='docx_logo' />
                <span
                  onClick={() => resetFilePreview(prev, i)}
                >
                  x
                </span>
              </>
              :
              <>
                <img
                  src={prev?.preview}
                  alt='preview'
                />
                <span
                  onClick={() => resetFilePreview(prev, i)}
                >
                  x
                </span>
              </>
              }
            </div>
          ))}
        </div>
      }
      <div className={isEmailTextArea ? 'message_btns' : 'email_btns'}>
        <div className='discussion_icons'>
          <div
            className='message_icon'
            onClick={() => {
              setIsMessageTextArea(true);
              setIsEmailTextArea(false);
              setIsAttachmentIcon(false);
            }}
            onMouseEnter={() => handleIconHover('send-message')}
            onMouseLeave={handleIconLeave}
          >
            <MessageIcon
              width='35'
              height='29'
              isMessageTextArea={isMessageTextArea}
            />
          </div>
          {isMessageTextArea &&
            <div
              className='message_icon'
              onClick={() => {
                setIsAttachmentIcon(true);
                setIsEmailTextArea(false);
              }}
              onMouseEnter={() => handleIconHover('attach-files')}
              onMouseLeave={handleIconLeave}
            >
              <label htmlFor="filePicker">
                {/* message attachment icon */}
                <AttachmentIcon
                  width='35'
                  height='29'
                  isAttachmentIcon={isAttachmentIcon}
                />
              </label>
              <input
                id="filePicker"
                type={"file"}
                style={{ visibility:"hidden" }}
                onChange={attachFile}
                accept="image/png, image/jpg, image/jpeg, application/pdf"
                multiple
              />
            </div>
          }
          <div
            className='message_icon email_icon'
            onClick={() => {
              setIsEmailTextArea(true);
              setIsMessageTextArea(false);
              setIsAttachmentIcon(false);
            }}
            onMouseEnter={() => handleIconHover('send-email')}
            onMouseLeave={handleIconLeave}
          >
            <EmailIcon
              width='35'
              height='29'
              isEmailTextArea={isEmailTextArea}
            />
          </div>
          {isEmailTextArea &&
            <div
              className='message_icon'
              onClick={() => {
                setIsAttachmentIcon(true);
                setIsMessageTextArea(false);
              }}
              onMouseEnter={() => handleIconHover('attach-files')}
              onMouseLeave={handleIconLeave}
            >
              <label htmlFor="filePicker">
                {/* email attachment icon */}
                <AttachmentIcon
                  width='35'
                  height='29'
                  isAttachmentIcon={isAttachmentIcon}
                />
              </label>
              <input
                id="filePicker"
                type={"file"}
                style={{ visibility:"hidden" }}
                onChange={attachFile}
                accept="image/png, image/jpg, image/jpeg, application/pdf"
                multiple
              />
            </div>
          }
          <div className="text_container">
            {hoveredIcon === 'send-message' && (
              <p className="send_message_text">Send a message</p>
            )}
            {hoveredIcon === 'send-email' && (
              <p className="send_email_text">Send an email</p>
            )}
            {hoveredIcon === 'attach-files' && (
              <p className="attach_files_text">Attach file(s)</p>
            )}
          </div>
        </div>
        <>
          {((myDiscussion && chatMembersRef?.indexOf(`users/${userId}`) > -1) ||
          (pubDiscussion && chatMembersRef?.indexOf(`users/${userId}`) > -1) ||
          (allDiscussion && chatMembersRef?.indexOf(`users/${userId}`) > -1 && 
          (isAdmin || isAdmin2))) &&
          <>
            {/* message send button */}
            {isMessageTextArea && 
              <div className="msg_send_btn">
                <button
                  className="msg-bn1"
                  onClick={() => {
                    addMessage();
                    // handleScroll();
                  }}
                >
                  Send
                </button>
              </div>
            }
            {/* email send button */}
            {isEmailTextArea &&
              <div className="email_send_btn">
                <button
                  className="msg-bn1"
                  onClick={sendEmail}
                  >
                  Send Now
                </button>
              </div>
            }
          </>
          }
        </>
      </div>
        {/* invalid email error message */}
        {emailErrMsg !== '' &&
          <div className='invalid_email'>
            <p
              className='close_err_popup'
              onClick={() => setEmailErrMsg('')}
            >
              X
            </p>
            <p>{emailErrMsg}</p>
            <div onClick={() => setEmailErrMsg('')}>Ok</div>
          </div>
        }
        {/* delete message modal */}
        {deleteMsgModal &&
        <DeleteModal
          header='Delete?'
          subHeader='Are you sure you want to delete'
          db={db}
          messageObj={messageObj}
          setShowEditInput={setShowEditInput}
          setCurrentMessageId={setCurrentMessageId}
          setCurrentMsg={setCurrentMsg}
          closeModal={() => setDeleteMsgModal(false)}
          truncateString={truncateString}
        />
      }
    </>
  );
};

export default MessageSection;