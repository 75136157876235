import React, { useEffect, useState } from "react";
// import '../../styles_css/checkin.css';
import ClockOutFormModal from "../modal/ClockOutFormModal";
import EditTimePickerModal from "../modal/EditTimePickerModal";
import ClockOutResolveModal from "../modal/ClockOutResolveModal";
import ClockInTimePicker from "../modal/ClockInTimePicker";
import ShowMidNightModal from "../modal/ShowMidNightModal";
import ClockIcon from "../svgs/ClockIcon";
import StatusBox from "../svgs/StatusBox";
import PauseIcon from "../svgs/PauseIcon";
import ResumeIcon from "../svgs/ResumeIcon";
import InvolvementIcon from "../svgs/InvolvementIcon";
import TimeWorkedIcon from "../svgs/TimeWorkedIcon";
import TimeSheetDownArrow from "../svgs/TimeSheetDownArrow";
import TimeSheetPrevIcon from "../svgs/TimeSheetPrevIcon";
import TimeSheetNextIcon from "../svgs/TimeSheetNextIcon";
import ColleaguesIcon from "../svgs/ColleaguesAvatarIcon";
import noAvatar from "../../static/no-avatar.png";
import ReviewModal from "../modal/ReviewModal";

const AddCheckIn = ({
  db,
  currentUserObj,
  firebase,
  devsReportingReference,
  isShowcheckIn,
  setIsShowCheckIn,
  isShowPause,
  setIsShowPause,
  currUserId,
}) => {
  const [docRef, setDocRef] = useState(null);
  const [isShowClockOutModal, setIsShowClockOutModal] = useState(false);
  const [isAccountNotGood, setIsAccountNotGood] = useState(false);
  const [isTimesheetActive, setIsTimesheetActive] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [editDate, setEditDate] = useState(false);
  const [editType, setEditType] = useState(false);
  const [timeSheetData, setTimeSheetData] = useState(null);
  const [misssingClockOutData, setMisssingClockOutData] = useState(null);
  const [presenceMap, setPresenceMap] = useState(null);
  const [showPrevious, setShowPrevious] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [checkinDtObj, setCheckinDtObj] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(null);
  const [currentDateObj, setCurrentDateObj] = useState(null);
  const [previousDate, setPreviousDate] = useState(null);
  const [previousDateObj, setPreviousDateObj] = useState(null);
  const [nextDate, setNextDate] = useState(null);
  const [nextDateObj, setNextDateObj] = useState(null);
  const [noRecordsMsg, setNoRecordsMsg] = useState(false);
  const [timeWorked, setTimeWorked] = useState();
  const [clockOutResolve, setClockOutResolve] = useState(false);
  const [presenceDataIndex, setPresenceDataIndex] = useState(0);
  const [isToday, setIsToday] = useState(false);
  const [clockOutTime, setClockOutTime] = useState(new Date());
  const [clockInTime, setClockInTime] = useState(null);
  const [lastIndexType, setLastIndexType] = useState(null);
  const [lastIndexTime, setLastIndexTime] = useState(null);
  const [timeWorkedInSeconds, setTimeWorkedInSeconds] = useState(0);
  const [isShowClockIn, setIsShowClockIn] = useState(false);
  const [firstDayofWeek, setFirstDayofWeek] = useState(new Date());
  const [firstDayofMonth, setFirstDayofMonth] = useState(null);
  const [weeklyAverages, setWeeklyAverages] = useState(0);
  const [monthlyAverages, setMonthlyAverages] = useState(0);
  const [weeklyTotal, setWeeklyTotal] = useState(0);
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [activeDate, setActiveDate] = useState("current_date");
  const [isNextDay, setisNextDay] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [devUserDetails, setDevUserDetails] = useState({});
  const [currentdaydata, setCurrentdaydata] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [reviewModal, setReviewModal] = useState(false);
  const [hideCancel, setHideCancel] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const toggleAccordion = () => setIsOpen(!isOpen);
  const [updatecheckOutTime, setupdatecheckOutTime] = useState(false);

  const months_arr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // Boolean to show/hide selection of dates on checkout resolve modal
  const [showClockDates, setShowClockDates] = useState(true);
  const [showTimePicker, setShowTimePicker] = useState(false);

  useEffect(() => {
    let _currentday = new Date();
    checkinDtObj?.getDate() === _currentday?.getDate() &&
    checkinDtObj?.getMonth() === _currentday?.getMonth() &&
    checkinDtObj?.getFullYear() === _currentday?.getFullYear()
      ? setIsToday(true)
      : setIsToday(false);
  }, [checkinDtObj]);

  useEffect(() => {
    const setData = () => {
      let _currentdate = new Date();
      let currDate = _currentdate?.getDate();
      if (currDate && currDate?.toString()?.length === 1) {
        currDate = "0".concat(currDate);
      }
      let currentmonth = months_arr[_currentdate?.getMonth()];
      let currentyear = _currentdate?.getFullYear();
      let checkinPresenceSnapshot = devsReportingReference
        ?.collection(`presence`)
        ?.orderBy("checkIn", "asc");

      let res = checkinPresenceSnapshot?.onSnapshot((snap) => {
        let checkinPrsenceDataMap = {};
        let clockOutReportMap = [];
        let dates = [];
        let weeklyPresenceMap = [];
        let monthlyPresenceMap = [];
        var todaysKey = currentmonth
          ?.concat(" " + currDate)
          ?.concat(" " + currentyear);
        snap.forEach((doc, id) => {
          let _presenceData = doc?.data();
          let checkIn = doc?.data()?.checkIn?.toDate();
          let checkOut = doc?.data()?.checkOut?.toDate();
          let lastIndexDateTime;
          checkinPrsenceDataMap[checkIn?.toString()?.substring(4, 15)] = doc;
          _presenceData?.presenceData?.map((data, i) => {
            if (i === _presenceData?.presenceData?.length - 1) {
              lastIndexDateTime = data?.dateTime?.toDate();
            }
          });
          if (
            !_presenceData ||
            !_presenceData?.checkoutReport ||
            (checkOut &&
              lastIndexDateTime &&
              checkOut?.getUTCHours() !== lastIndexDateTime?.getUTCHours()) ||
            checkOut?.getUTCMinutes() !== lastIndexDateTime?.getUTCMinutes() ||
            checkOut?.getUTCSeconds() !== lastIndexDateTime?.getUTCSeconds()
          ) {
            if (
              checkIn &&
              todaysKey &&
              checkIn?.toString().substring(4, 15) !== todaysKey?.toString() &&
              checkIn < _currentdate
            ) {
              clockOutReportMap.push(doc);
            }
          }
        });
        setMisssingClockOutData(clockOutReportMap);
        // setHideCancel(clockOutReportMap.length > 1 ? true : false);
        // setIsAccountNotGood(clockOutReportMap.length > 1 ? false : true);

        setHideCancel(
          clockOutReportMap.length > 1 ? true : clockOutResolve ? true : false
        );
        setIsAccountNotGood(clockOutReportMap.length >= 1 ? true : false);

        // setClockOutResolve(clockOutReportMap.length > 1 ? true : false);
      });

      if (timeSheetData?.data()?.presenceData) {
        const lastIndex = timeSheetData.data().presenceData.length - 1;
        const lastData = timeSheetData.data().presenceData[lastIndex];

        setClockInTime(timeSheetData.data().checkIn?.toDate());
        setIsTimesheetActive(true);
        setLastIndexType(lastData?.type);
        setLastIndexTime(lastData?.dateTime?.toDate());

        if (["unpause", "checkin", "checkIn"].includes(lastData?.type)) {
          setIsShowPause(true);
        } else if (lastData?.type === "pause") {
          setIsShowPause(false);
        }
      }

      if (timeSheetData && timeSheetData.data().presenceData) {
        let workingtime = null;
        let tempTimeWorked = 0;

        timeSheetData.data().presenceData.forEach((data, i) => {
          if (["checkin", "checkIn", "unpause"].includes(data?.type)) {
            workingtime = data?.dateTime?.toDate();
          }
          if (["checkout", "pause"].includes(data?.type)) {
            let timedifference = data?.dateTime?.toDate() - workingtime;
            tempTimeWorked += timedifference;
          }
          if (
            checkinDtObj?.getDate() === _currentdate?.getDate() &&
            checkinDtObj?.getMonth() === _currentdate?.getMonth() &&
            checkinDtObj?.getFullYear() === _currentdate?.getFullYear() &&
            i === timeSheetData.data().presenceData.length - 1 &&
            ["checkin", "checkIn", "unpause"].includes(data?.type)
          ) {
            let timedifference = new Date() - data?.dateTime?.toDate();
            tempTimeWorked += timedifference;
          }
        });

        let timeworkedObj = new Date(tempTimeWorked);
        let seconds = timeworkedObj.getUTCSeconds();
        let minutes = timeworkedObj.getUTCMinutes();
        let hours = timeworkedObj.getUTCHours();
        setTimeWorked(
          `${hours.toString().padStart(2, "0")} hr ${minutes
            .toString()
            .padStart(2, "0")} min worked`
        );
      }
      if (timeSheetData && timeSheetData?.data()?.presenceData?.length > 0) {
        setNoRecordsMsg(false);
        setDocRef(timeSheetData?.ref);
      } else {
        setNoRecordsMsg(true);
        setTimeWorked("");
        setDocRef(null);
        setTimeSheetData(null);
      }
    };
    setData();
  }, [timeSheetData]);

  useEffect(() => {
    getFirstDayOfweek();
    getFirstDayOfMonth();
    if (devsReportingReference) getColleagues(devsReportingReference);
  }, []);

  useEffect(() => {
    if (clockOutResolve) {
      setTimeout(() => {
        // open resolve window here
      }, 0);
    }
  }, [clockOutResolve]);

  useEffect(() => {
    if (timeSheetData) return;
    const setDate = () => {
      const _currentdate = new Date();
      setSelectedDate(
        _currentdate?.getFullYear() +
          "-" +
          (_currentdate?.getMonth() + 1) +
          "-" +
          _currentdate?.getDate()
      );
      let currentDayInMilli = new Date(
        _currentdate?.getFullYear() +
          "-" +
          (_currentdate?.getMonth() + 1) +
          "-" +
          _currentdate?.getDate()
      )?.getTime();
      let oneDay = 1000 * 60 * 60 * 24;
      let twoDay = 2 * oneDay;
      let currDate = _currentdate?.getDate();
      if (currDate && currDate?.toString()?.length === 1) {
        currDate = "0".concat(currDate);
      }
      let currentmonth = months_arr[_currentdate?.getMonth()];
      let currentyear = _currentdate?.getFullYear();
      setCheckinDtObj(new Date());
      setCurrentDateObj(_currentdate);
      setCurrentDate(
        currentmonth?.concat(" " + currDate)?.concat(" " + currentyear)
      );

      let previousDayInMilli = currentDayInMilli - oneDay;
      let previousDate = new Date(previousDayInMilli);
      setPreviousDateObj(previousDate);
      let premonth = months_arr[previousDate?.getMonth()];
      let preyear = previousDate?.getFullYear();
      let preDate = previousDate?.getDate();
      if (preDate && preDate?.toString()?.length === 1) {
        preDate = "0".concat(preDate);
      }
      setPreviousDate(premonth?.concat(" " + preDate)?.concat(" " + preyear));

      let nextDayInMilli = currentDayInMilli + oneDay;
      let nextDate = new Date(nextDayInMilli);
      setNextDateObj(nextDate);
      let nextmonth = months_arr[nextDate?.getMonth()];
      let nextyear = nextDate?.getFullYear();
      let nxtDate = nextDate?.getDate();
      if (nxtDate && nxtDate?.toString()?.length === 1) {
        nxtDate = "0".concat(nxtDate);
      }
      setNextDate(nextmonth?.concat(" " + nxtDate)?.concat(" " + nextyear));
      let checkinPresenceSnapshot = devsReportingReference
        ?.collection(`presence`)
        ?.orderBy("checkIn", "asc");
      let res = checkinPresenceSnapshot?.onSnapshot((snap) => {
        let checkinPrsenceDataMap = {};
        let clockOutReportMap = [];
        let dates = [];
        let weeklyPresenceMap = [];
        let monthlyPresenceMap = [];
        var todaysKey = currentmonth
          ?.concat(" " + currDate)
          ?.concat(" " + currentyear);
        snap.forEach((doc, id) => {
          let _presenceData = doc?.data();
          let checkIn = doc?.data()?.checkIn?.toDate();
          let checkOut = doc?.data()?.checkOut?.toDate();
          let lastIndexDateTime;
          checkinPrsenceDataMap[checkIn?.toString()?.substring(4, 15)] = doc;
          _presenceData?.presenceData?.map((data, i) => {
            if (i === _presenceData?.presenceData?.length - 1) {
              lastIndexDateTime = data?.dateTime?.toDate();
            }
          });

          if (
            !_presenceData ||
            !_presenceData?.checkoutReport ||
            (checkOut &&
              lastIndexDateTime &&
              checkOut?.getUTCHours() !== lastIndexDateTime?.getUTCHours()) ||
            checkOut?.getUTCMinutes() !== lastIndexDateTime?.getUTCMinutes() ||
            checkOut?.getUTCSeconds() !== lastIndexDateTime?.getUTCSeconds()
          ) {
            if (
              checkIn &&
              todaysKey &&
              checkIn?.toString().substring(4, 15) !== todaysKey?.toString() &&
              checkIn < _currentdate
            ) {
              clockOutReportMap.push(doc);
            }
          }
          if (
            checkIn > firstDayofWeek ||
            (checkIn?.getMonth() === firstDayofWeek?.getMonth() &&
              checkIn?.getDate() === firstDayofWeek?.getDate() &&
              checkIn?.getFullYear() === firstDayofWeek?.getFullYear())
          ) {
            weeklyPresenceMap.push(_presenceData);
          }
          if (
            checkIn > firstDayofMonth ||
            (checkIn?.getMonth() === firstDayofMonth?.getMonth() &&
              checkIn?.getDate() === firstDayofMonth?.getDate() &&
              checkIn?.getFullYear() === firstDayofMonth?.getFullYear())
          ) {
            monthlyPresenceMap.push(_presenceData);
          }
        });
        var keys = Object.keys(checkinPrsenceDataMap);
        keys &&
          keys?.map((key) => {
            if (key?.toString() === todaysKey?.toString()) {
              setIsShowCheckIn(false);
              setDocRef(checkinPrsenceDataMap[key]?.ref);
              setTimeSheetData(checkinPrsenceDataMap[key]);
            }
          });
        setPresenceMap(checkinPrsenceDataMap);
        setMisssingClockOutData(clockOutReportMap);
        // setHideCancel(clockOutReportMap.length > 1 ? true : false);
        // setIsAccountNotGood(clockOutReportMap.length > 0 ? true : false);
        // setClockOutResolve(clockOutReportMap.length > 1 ? true : false);
        // Calculating weekly and monthly averages
        let _weeklytimeWorked = 0;
        let _monthlytimeWorked = 0;
        let _weeklyaverages = 0;
        let _monthlyaverages = 0;
        let weeklytotal = 0;
        let monthlyTotal = 0;

        weeklyPresenceMap &&
          weeklyPresenceMap?.map((data) => {
            let _timeworked = getTotalTimeWorkedInSeconds(data);
            _weeklytimeWorked += _timeworked;
          });

        weeklytotal =
          _weeklytimeWorked !== 0 ? Math.floor(_weeklytimeWorked / 3600) : 0;
        _weeklyaverages =
          weeklytotal !== 0
            ? Math.floor(weeklytotal / weeklyPresenceMap?.length)
            : 0;
        setWeeklyTotal(weeklytotal);
        setWeeklyAverages(_weeklyaverages);

        monthlyPresenceMap &&
          monthlyPresenceMap?.map((data) => {
            let _timeworked = getTotalTimeWorkedInSeconds(data);
            _monthlytimeWorked += _timeworked;
          });
        monthlyTotal =
          _monthlytimeWorked !== 0 ? Math.floor(_monthlytimeWorked / 3600) : 0;
        _monthlyaverages =
          monthlyTotal !== 0
            ? Math.floor(monthlyTotal / monthlyPresenceMap?.length)
            : 0;
        setMonthlyTotal(monthlyTotal);
        setMonthlyAverages(_monthlyaverages);
        //curret day presence data
      });
      return res;
    };
    if (firstDayofWeek != null || firstDayofMonth != null) setDate();
  }, [firstDayofWeek, firstDayofMonth, devUserDetails]);

  const getFirstDayOfweek = () => {
    let currentDate = new Date();
    let _currentday = currentDate?.getDay();
    let _firstDayOfweek = new Date();
    let diff =
      currentDate?.getDate() - _currentday + (_currentday == 0 ? -6 : 1); // adjust when day is sunday
    _firstDayOfweek = new Date(currentDate?.setDate(diff));
    setFirstDayofWeek(_firstDayOfweek);
  };

  const getTotalTimeWorkedInSeconds = (timeSheetData) => {
    let tempTimeWorked = 0;
    var currentday = new Date();
    let checkIn = timeSheetData?.checkIn?.toDate();
    let currentDate = new Date();

    let workingtime;
    {
      timeSheetData &&
        timeSheetData?.presenceData?.map((data, i) => {
          if (["checkin", "checkIn", "unpause"].includes(data?.type)) {
            workingtime = data?.dateTime?.toDate();
          }
          if (["checkout", "pause"].includes(data?.type)) {
            let timedifference = data?.dateTime?.toDate() - workingtime;
            tempTimeWorked += timedifference;
          }
          if (
            checkIn?.getMonth() === currentDate?.getMonth() &&
            checkIn?.getDate() === currentDate?.getDate() &&
            checkIn?.getFullYear() === currentDate?.getFullYear() &&
            i === timeSheetData?.presenceData?.length - 1 &&
            ["unpause", "checkin", "checkIn"].includes(data?.type)
          ) {
            tempTimeWorked += new Date() - data?.dateTime?.toDate();
          }
        });
      return tempTimeWorked ? Math.floor(tempTimeWorked / 1000) : 0; // convert milliseconds to seconds
    }
  };

  const getFirstDayOfMonth = () => {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let _firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);
    setFirstDayofMonth(new Date(_firstDay));
  };

  const getLocalTime = (tzoffsetStr) => {
    tzoffsetStr = tzoffsetStr || "+00:00";
    let offsetHour =
      tzoffsetStr !== null
        ? tzoffsetStr?.charAt(0) === "-"
          ? parseInt(tzoffsetStr?.substr(1), 10)
          : parseInt(tzoffsetStr?.substr(0), 10)
        : 0; // e.g. 7
    let offsetMinutes =
      tzoffsetStr !== null
        ? tzoffsetStr?.charAt(0) === "-"
          ? parseInt(tzoffsetStr?.substr(4), 10)
          : parseInt(tzoffsetStr?.substr(2), 10)
        : 0; // e.g. 7
    if (isNaN(offsetMinutes)) {
      offsetMinutes = 0;
    }
    const sign = tzoffsetStr?.charAt(0) === "-" ? -1 : 1;
    const gmtsign = tzoffsetStr?.charAt(0) === "-" ? "-" : "+";
    const tzoffset3 =
      sign *
      ((parseInt(offsetHour) * 60 + parseInt(offsetMinutes)) * 60 * 1000);
    const gmtString =
      offsetMinutes > 0
        ? `GMT${gmtsign}${Math.abs(offsetHour)}:${Math.abs(offsetMinutes)
            .toString()
            .padStart(2, "0")}`
        : `GMT${gmtsign}${Math.abs(offsetHour)}`;
    const localDate3 = new Date();
    const utcOffset3 = localDate3?.getTimezoneOffset() * 60 * 1000;
    const offsetMilliseconds3 = tzoffset3 + utcOffset3;
    const utcTime3 = localDate3?.getTime();
    const localTime3 = utcTime3 + offsetMilliseconds3;
    const colleagueTime = new Date(localTime3);
    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const formattedColleagueTime = formatter?.format(colleagueTime);
    const myTime = new Date();
    const timeDiffInMs = Math.abs(myTime - colleagueTime);
    const timeDiffInHours = (localTime3 - myTime) / (1000 * 60 * 60);
    const outputString = `Current local time- ${formattedColleagueTime} ${gmtString} | ${Math.abs(
      timeDiffInHours?.toFixed(2)
    )}h ${timeDiffInHours > 0 ? "ahead" : "behind"} of you.`;
    return outputString;
  };

  const getHoursworked = (timeSheetData, isCurrentDay) => {
    let tempTimeWorked = 0;
    let workingtime;

    timeSheetData?.presenceData?.forEach((data, i) => {
      if (["checkIn", "checkin", "unpause"].includes(data?.type)) {
        workingtime = data?.dateTime?.toDate();
      }
      if (data?.type === "pause") {
        tempTimeWorked += data?.dateTime?.toDate() - workingtime;
      }
      if (
        isCurrentDay &&
        i === timeSheetData?.presenceData?.length - 1 &&
        ["unpause", "checkin", "checkIn"].includes(data?.type)
      ) {
        tempTimeWorked += new Date() - data?.dateTime?.toDate();
      }
    });

    const timeWorkedObj = new Date(tempTimeWorked);
    const seconds = timeWorkedObj?.getUTCSeconds();
    const minutes = timeWorkedObj?.getUTCMinutes();
    const hours = timeWorkedObj?.getUTCHours();
    const timeworked = `( ${hours?.toString()?.padStart(2, "0")}hr ${minutes
      .toString()
      .padStart(2, "0")}min worked today )`;

    return timeworked ? timeworked : "";
  };

  const clockIn = async (e, checkinDateObj) => {
    e.preventDefault();
    setIsShowCheckIn(false);
    let date = new Date();
    let timezoneOffsetInMinutes = date?.getTimezoneOffset();
    let sign = timezoneOffsetInMinutes < 0 ? "+" : "-";
    let absoluteOffsetInMinutes = Math.abs(timezoneOffsetInMinutes);
    let hours = Math.floor(absoluteOffsetInMinutes / 60);
    let minutes = absoluteOffsetInMinutes % 60;
    let formattedOffset =
      timezoneOffsetInMinutes > 0
        ? `${sign}${hours.toString()}:${minutes
            .toString()
            .padStart(2, "0")}:00.000000`
        : `${hours.toString()}:${minutes
            .toString()
            .padStart(2, "0")}:00.000000`;
    const presenceDataMap = [{ dateTime: new Date(), type: "checkIn" }];
    const clockInObj = {
      checkIn: checkinDtObj,
      presenceData: presenceDataMap,
      tzOffset: formattedOffset,
    };
    const clockInRef = await devsReportingReference
      .collection(`presence`)
      .add(clockInObj);
    const docReferencePath = clockInRef?.path;
    setDocRef(clockInRef);
    const timesheetDt = await db.doc(clockInRef?.path)?.get();
    setTimeSheetData(timesheetDt);
  };

  const getColleagues = async (devsReportingReference) => {
    let colleagues;
    let activeSprints = [];
    let checkInDate = new Date();
    let userDetails = {};
    const unsubscribe = devsReportingReference?.onSnapshot(
      async (activeSprintsSnapshot) => {
        let sprints = activeSprintsSnapshot?.data()?.sprints ?? [];
        await Promise.all(
          sprints?.map(async (data, i) => {
            let sprintDoc = await data?.get();
            let sprintStartDate = sprintDoc?.data()?.start;
            let sprintEndDate = sprintDoc?.data()?.end;
            if (
              checkInDate > sprintStartDate?.toDate() &&
              sprintEndDate?.toDate() > checkInDate
            ) {
              activeSprints.push(data);
            }
          })
        );
        await Promise.all(
          activeSprints?.map(async (data, i) => {
            let sprintDoc = await data?.get();
            let projectRef = sprintDoc?.data()?.projectRef;
            let projectDoc = await projectRef.get();
            let stakeholders = projectDoc.data()?.stakeholders ?? [];
            let teamRef = sprintDoc?.data()?.team;
            let teamDoc = await teamRef?.get();
            let teamMembersMap = teamDoc?.data()?.members;
            const currentUserRef = currentUserObj?.ref;
            const isStakeholder = stakeholders.some((stakeholder) => {
              return stakeholder?.user?.path === currentUserRef?.path;
            });
            await Promise.all(
              teamMembersMap.map(async (data, i) => {
                let lastlogin;
                let devref = data?.id;
                let devInformation = await devref?.get();
                let devID = devInformation?.data()?.devID;
                let firstname = devInformation?.data()?.first;
                let lastname = devInformation?.data()?.last;
                let colleaguedesignation = devInformation?.data()?.position;
                let colleaguelocation = devInformation?.data()?.locTag;
                let colleaguename = firstname + " " + lastname;
                const stakeHolderDetails = {};
                const currentUserRef = currentUserObj?.ref;
                const logostorageData = await devref
                  ?.collection("devPublic")
                  ?.doc("profile")
                  ?.get();
                let _logostrorage;
                if (logostorageData?.exists) {
                  _logostrorage = logostorageData?.data()?.logoStorage;
                }

                let devreportingref = devInformation?.data()?.devsReportingId;
                const isDocRefEqual =
                  devsReportingReference?.isEqual(devreportingref);
                if (isDocRefEqual) return;

                if (devreportingref === undefined) {
                  const user = {
                    name: colleaguename,
                    avatar: "",
                    localtime: "This Resource has conflicting records",
                    checkIn: "",
                    status: "conflict",
                    devtimeworked: "",
                    canReview: "",
                    devreportingRefrence: "",
                    designation: "",
                    location: "",
                    lastlogin: "",
                    activedays: "",
                  };
                  userDetails[devref?.path] = user;
                  //return;
                } else {
                  const today = new Date();
                  const startOfDay = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate()
                  );
                  const endOfDay = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() + 1
                  );

                  const presenceRef = devreportingref?.collection("presence");
                  const presenceSnapshot = await presenceRef.get();
                  const presenceCount = presenceSnapshot.size;

                  const presenceQuery = devreportingref
                    ?.collection("presence")
                    ?.orderBy("checkIn")
                    ?.startAt(startOfDay)
                    ?.endBefore(endOfDay);
                  let presenceDataMap = {};
                  let timeworked;
                  let colleaguestatus;
                  let colleagueTimeWorked;
                  let colleaguesLocalTime;
                  let colleagueCheckin;
                  presenceQuery?.onSnapshot(async (snapshot) => {
                    if (!snapshot.empty) {
                      snapshot.docChanges().forEach((change) => {
                        if (
                          change.type === "added" ||
                          change.type === "modified"
                        ) {
                          presenceDataMap = change?.doc?.data();
                        } else {
                          presenceDataMap = snapshot?.data() ?? {}; // Set presenceData to an empty object if snapshot.data() is undefined
                        }
                        let presenceMap = presenceDataMap?.presenceData ?? [];
                        presenceMap &&
                          presenceMap.forEach((data, i) => {
                            if (i === presenceMap.length - 1) {
                              colleaguestatus = data?.type;
                            }
                          });
                        let tzoffsetStr = presenceDataMap?.tzOffset;
                        let checkindate = presenceDataMap?.checkIn?.toDate();
                        const localDateString = checkindate?.toLocaleString(
                          undefined,
                          {
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        );
                        colleagueCheckin =
                          "Checked-in since " + localDateString || "";
                        colleaguesLocalTime = getLocalTime(tzoffsetStr) || {};
                        colleagueTimeWorked = getHoursworked(
                          presenceDataMap,
                          true
                        );
                        const user = {
                          name: colleaguename,
                          avatar: _logostrorage,
                          localtime: colleaguesLocalTime,
                          checkIn: colleagueCheckin,
                          status: colleaguestatus,
                          devtimeworked: colleagueTimeWorked,
                          canReview: isStakeholder,
                          devreportingRefrence: devreportingref,
                          designation: colleaguedesignation,
                          location: colleaguelocation,
                          lastlogin: localDateString,
                          activedays: presenceCount,
                        };
                        userDetails[devref?.path] = user;
                        if (change.type === "modified") {
                          setDevUserDetails((prevState) => {
                            return { ...prevState, ...userDetails }; // Merge the newState object with the previous state using spread syntax
                          });
                        }
                      });
                    }
                  });
                  const latestPresenceQuery = await devreportingref
                    .collection("presence")
                    .orderBy("checkIn", "desc")
                    .limit(1);
                  let latestPresence = await latestPresenceQuery.get();
                  let devlastlogin;
                  if (!latestPresence.empty) {
                    latestPresence.forEach((latestDoc) => {
                      let timesheetData = latestDoc?.data();
                      let checkindate = latestDoc?.data()?.checkIn?.toDate();
                      const localDateString = checkindate.toLocaleString(
                        undefined,
                        {
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      );
                      colleagueCheckin = "Checked-in since " + localDateString;
                      devlastlogin = localDateString;
                      colleaguestatus = "NOT CHECKED-IN";
                      colleagueTimeWorked = getHoursworked(
                        timesheetData,
                        false
                      );
                      let tzoffsetStr = latestDoc?.data()?.tzOffset;
                      colleaguesLocalTime = getLocalTime(tzoffsetStr);
                    });
                  }
                  const user = {
                    name: colleaguename,
                    avatar: _logostrorage,
                    localtime: colleaguesLocalTime,
                    checkIn: colleagueCheckin,
                    status: colleaguestatus,
                    devtimeworked: colleagueTimeWorked,
                    canReview: isStakeholder,
                    devreportingRefrence: devreportingref,
                    designation: colleaguedesignation,
                    location: colleaguelocation,
                    lastlogin: devlastlogin,
                    activedays: presenceCount,
                  };
                  if (!Object.keys(userDetails).includes(devref?.path))
                    userDetails[devref?.path] = user;
                }
              })
            );
          })
        );
        setDevUserDetails(userDetails);
        setIsloading(false);
        return unsubscribe;
      }
    );
  };

  const clockOut = async (e, checkinDateObj) => {
    let date = new Date();
    let timezoneOffsetInMinutes = date?.getTimezoneOffset();

    let sign = timezoneOffsetInMinutes < 0 ? "-" : "+";
    let absoluteOffsetInMinutes = Math.abs(timezoneOffsetInMinutes);
    let hours = Math.floor(absoluteOffsetInMinutes / 60);
    let minutes = absoluteOffsetInMinutes % 60;
    let formattedOffset = `${sign}${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:00.000000`;

    let _currentdate = new Date();

    if (
      _currentdate > checkinDateObj &&
      !(
        _currentdate?.getMonth() === checkinDateObj?.getMonth() &&
        _currentdate?.getDate() === checkinDateObj.getDate() &&
        _currentdate?.getFullYear() === checkinDateObj.getFullYear()
      )
    ) {
      setisNextDay(true);
    } else {
      let timedifference = 0;
      timedifference = new Date() - lastIndexTime;
      let editPresenceData = timeSheetData?.data();
      editPresenceData["ref"] = timeSheetData?.ref;
      if (timedifference > 0) {
        if (editPresenceData.presenceData?.length % 2 === 0) {
          let editLastPresenceDataDateTime =
            editPresenceData?.presenceData[
              editPresenceData?.presenceData.length - 1
            ];
          editLastPresenceDataDateTime = firebase.firestore.Timestamp.fromDate(
            new Date()
          );
        } else {
          const presenceDataMap = {
            dateTime: firebase.firestore.Timestamp.fromDate(new Date()),
            type: "pause",
          };
          editPresenceData?.presenceData?.push(presenceDataMap);
        }
      }
      let tempTimeWorked = 0;
      let workingtime;
      if (editPresenceData) {
        editPresenceData?.presenceData?.forEach((data, i) => {
          if (["checkIn", "checkin", "unpause"].includes(data?.type)) {
            workingtime = data?.dateTime?.toDate();
          } else if (data?.type === "pause") {
            let timedifference = data?.dateTime?.toDate() - workingtime;
            tempTimeWorked += timedifference;
          }
          if (isToday && i === editPresenceData.presenceData.length - 1) {
            if (["checkin", "checkIn", "unpause"].includes(data?.type)) {
              let timedifference = new Date() - data?.dateTime?.toDate();
              tempTimeWorked += timedifference;
            }
          }
        });
        setTimeWorkedInSeconds(tempTimeWorked);
      }
      setIsShowClockOutModal(true);
    }
  };

  const pause = async (doc, checkinDateObj) => {
    let _currentdate = new Date();

    if (
      _currentdate > checkinDateObj &&
      !(
        _currentdate?.getMonth() === checkinDateObj?.getMonth() &&
        _currentdate?.getDate() === checkinDateObj?.getDate() &&
        _currentdate?.getFullYear() === checkinDateObj?.getFullYear()
      )
    ) {
      setisNextDay(true);
    } else {
      let timedifference = 0;
      timedifference = new Date() - lastIndexTime;
      const presenceDataMap = { dateTime: new Date(), type: "pause" };
      const clockInObj = {
        presenceData: firebase.firestore.FieldValue.arrayUnion(presenceDataMap),
      };
      if (timedifference > 0) doc?.update(clockInObj);
      const timesheetDt = await db.doc(doc?.path)?.get();
      setTimeSheetData(timesheetDt);
      setDocRef(timesheetDt?.ref);
    }
  };

  const unPause = async (doc, checkinDateObj) => {
    let _currentdate = new Date();

    if (
      _currentdate > checkinDateObj &&
      !(
        _currentdate?.getMonth() === checkinDateObj?.getMonth() &&
        _currentdate?.getDate() === checkinDateObj?.getDate() &&
        _currentdate?.getFullYear() === checkinDateObj?.getFullYear()
      )
    ) {
      setisNextDay(true);
    } else {
      let timedifference = 0;
      timedifference = new Date() - lastIndexTime;
      const presenceDataMap = { dateTime: new Date(), type: "unpause" };
      const clockInObj = {
        presenceData: firebase.firestore.FieldValue.arrayUnion(presenceDataMap),
      };
      if (timedifference > 0) doc?.update(clockInObj);
      const timesheetDt = await db.doc(doc?.path)?.get();
      setTimeSheetData(timesheetDt);
      setNoRecordsMsg(false);
    }
  };

  const getPresenceData = (checkinDate, checkinDateObj) => {
    setCheckinDtObj(checkinDateObj);
    setTimeSheetData(null);
    let keys = Object.keys(presenceMap);
    keys &&
      keys?.map((key) => {
        if (key?.toString() === checkinDate?.toString()) {
          setNoRecordsMsg(false);
          setDocRef(presenceMap[key]?.ref);
          setTimeSheetData(presenceMap[key]);
        }
      });
  };
  const clickToResolve = () => {
    setClockOutResolve(true);
    setShowClockDates(true);
    setShowTimePicker(false);
    setHideCancel(false);
  };

  const previous = () => {
    setShowPrevious(true);
    setTimeSheetData(null);
    setActiveDate("current_date");
    let todayDateinMilli = new Date(selectedDate)?.getTime();
    let oneDay = 1000 * 60 * 60 * 24;
    let twoDay = 2 * oneDay;
    let threeDay = 3 * oneDay;
    let currentDayInMilli = todayDateinMilli - oneDay;
    let currentDate = new Date(currentDayInMilli);
    currentDate?.setSeconds(new Date()?.getSeconds());
    currentDate?.setMinutes(new Date()?.getMinutes());
    currentDate?.setHours(new Date()?.getHours());
    setCheckinDtObj(currentDate);
    setCurrentDateObj(currentDate);
    let currentmonth = months_arr[currentDate?.getMonth()];
    let currentyear = currentDate?.getFullYear();
    let currDate = currentDate?.getDate();
    if (currDate && currDate?.toString()?.length === 1) {
      currDate = "0".concat(currDate);
    }
    setCurrentDate(
      currentmonth?.concat(" " + currDate)?.concat(" " + currentyear)
    );
    let nextDate = new Date(todayDateinMilli);
    setNextDateObj(nextDate);
    let nextmonth = months_arr[nextDate?.getMonth()];
    let nextyear = nextDate?.getFullYear();
    let nxtDate = nextDate?.getDate();
    if (nxtDate && nxtDate?.toString()?.length === 1) {
      nxtDate = "0".concat(nxtDate);
    }
    setNextDate(nextmonth?.concat(" " + nxtDate)?.concat(" " + nextyear));
    let previousDayInMilli = todayDateinMilli - twoDay;
    let previousDate = new Date(previousDayInMilli);
    setPreviousDateObj(previousDate);
    let previousmonth = months_arr[previousDate?.getMonth()];
    let previousyear = previousDate?.getFullYear();
    let preDate = previousDate?.getDate();
    if (preDate && preDate?.toString()?.length === 1) {
      preDate = "0".concat(preDate);
    }
    setPreviousDate(
      previousmonth?.concat(" " + preDate)?.concat(" " + previousyear)
    );
    setSelectedDate(currentDate);
    var todaysKey = currentmonth
      ?.concat(" " + currDate)
      ?.concat(" " + currentyear);
    let keys = Object.keys(presenceMap);
    keys &&
      keys?.map((key) => {
        if (key?.toString() === todaysKey?.toString()) {
          setTimeSheetData(presenceMap[key]);
        }
      });
  };

  const next = () => {
    setTimeSheetData(null);
    setActiveDate("current_date");
    let todayDateinMilli = new Date(selectedDate).getTime();
    let oneDay = 1000 * 60 * 60 * 24;
    let currentDayInMilli = todayDateinMilli + oneDay;
    let currentDate = new Date(currentDayInMilli);
    currentDate?.setSeconds(new Date()?.getSeconds());
    currentDate?.setMinutes(new Date()?.getMinutes());
    currentDate?.setHours(new Date()?.getHours());
    setCheckinDtObj(currentDate);
    setCurrentDateObj(currentDate);
    let currentmonth = months_arr[currentDate?.getMonth()];
    let currentyear = currentDate?.getFullYear();
    let currDate = currentDate?.getDate();
    if (currDate && currDate?.toString().length === 1) {
      currDate = "0".concat(currDate);
    }
    setCurrentDate(
      currentmonth?.concat(" " + currDate)?.concat(" " + currentyear)
    );
    let previousDayInMilli = currentDayInMilli - oneDay;
    let previousDate = new Date(previousDayInMilli);
    setPreviousDateObj(previousDate);
    let previousmonth = months_arr[previousDate?.getMonth()];
    let previousyear = previousDate?.getFullYear();
    let preDate = previousDate?.getDate();
    if (preDate && preDate?.toString()?.length === 1) {
      preDate = "0".concat(preDate);
    }
    setPreviousDate(
      previousmonth?.concat(" " + preDate)?.concat(" " + previousyear)
    );
    let nextDayInMilli = currentDayInMilli + oneDay;
    let nextDate = new Date(nextDayInMilli);
    setNextDateObj(nextDate);
    let nextmonth = months_arr[nextDate?.getMonth()];
    let nextyear = nextDate?.getFullYear();
    let nxtDate = nextDate?.getDate();
    if (nxtDate && nxtDate?.toString()?.length === 1) {
      nxtDate = "0".concat(nxtDate);
    }
    setNextDate(nextmonth?.concat(" " + nxtDate)?.concat(" " + nextyear));
    setSelectedDate(currentDate);
    var todaysKey = currentmonth
      ?.concat(" " + currDate)
      ?.concat(" " + currentyear);
    let keys = Object.keys(presenceMap);
    keys &&
      keys?.map((key) => {
        if (key?.toString() === todaysKey?.toString()) {
          setTimeSheetData(presenceMap[key]);
        }
      });
  };

  return (
    <div className="checkin-container">
      <div className="checkin-welcome">
        {isAccountNotGood ? (
          <>
            <p className="welcome">
              Welcome Back, {currentUserObj?.first + " " + currentUserObj?.last}
              {". "}
              Your account is not in good standing.
            </p>
            <span className="resolve" onClick={() => clickToResolve()}>
              You forgot to clock out. Click to resolve
            </span>
          </>
        ) : (
          <p className="welcome">
            Welcome Back, {currentUserObj?.first + " " + currentUserObj?.last}
            {". "}
            Your account is in good standing.
          </p>
        )}
        <div className="checkin_btn_container">
          {isShowcheckIn && isToday && (
            <button
              className="checkin-button spacing clockIn"
              onClick={(e) => clockIn(e, checkinDtObj)}
            >
              <ClockIcon width="20" height="20" />
              <b>Clock In</b>
            </button>
          )}
          {!isShowcheckIn && isToday && (
            <button
              className="checkin-button spacing clockOut"
              onClick={(e) => clockOut(e, checkinDtObj)}
            >
              <ClockIcon width="20" height="20" />
              <b>Clock Out</b>
            </button>
          )}
          {isShowPause && !isShowcheckIn && isToday && (
            <button
              className="checkin-button spacing pause"
              onClick={(e) => pause(docRef, checkinDtObj)}
            >
              <PauseIcon width="20" height="20" />
              <b>Pause</b>
            </button>
          )}
          {!isShowPause && !isShowcheckIn && isToday && (
            <button
              className="checkin-button spacing resume"
              onClick={(e) => unPause(docRef, checkinDtObj)}
            >
              <ResumeIcon width="20" height="20" />
              <b>Resume</b>
            </button>
          )}
        </div>
        <div className="checkin-item-parent-container">
          <div className="checkin-item-container">
            <div className="checkin-item">
              <div className="checkin-timesheet">
                <div
                  className="checkin-title"
                  onClick={() => setIsTimesheetActive(!isTimesheetActive)}
                >
                  <p className="involvement">
                    <InvolvementIcon width="20" height="20" />
                    <b>Your involvement</b>
                  </p>
                  <p className="worked">
                    {timeWorked !== "" && (
                      <TimeWorkedIcon width="20" height="20" />
                    )}
                    <b>{timeWorked}</b>
                  </p>
                  <p className="accordion_arrows">
                    {isTimesheetActive ? (
                      "-"
                    ) : (
                      <TimeSheetDownArrow width="12" height="12" />
                    )}
                  </p>
                </div>
                <div className="checkin-presence-data">
                  <div style={{ padding: "1rem 0" }} onClick={previous}>
                    <TimeSheetPrevIcon width="12" height="12" />
                  </div>
                  <div
                    className={
                      activeDate === "prev_date"
                        ? "header prev_date active_date"
                        : "header prev_date"
                    }
                    onClick={() => {
                      getPresenceData(previousDate, previousDateObj);
                      setActiveDate("prev_date");
                    }}
                  >
                    {previousDate?.toString()?.substring(0, 6)}
                  </div>
                  <div
                    className={
                      activeDate === "current_date"
                        ? "header current_date active_date"
                        : "header current_date"
                    }
                    onClick={() => {
                      getPresenceData(currentDate, currentDateObj);
                      setActiveDate("current_date");
                    }}
                  >
                    {currentDate?.toString()?.substring(0, 6)}
                  </div>
                  {nextDateObj < new Date() ? (
                    <div
                      className={
                        activeDate === "next_date"
                          ? "header next_date active_date"
                          : "header next_date"
                      }
                      onClick={() => {
                        getPresenceData(nextDate, nextDateObj);
                        setActiveDate("next_date");
                      }}
                      z
                    >
                      {nextDate?.toString()?.substring(0, 6)}
                    </div>
                  ) : (
                    <div
                      className={
                        activeDate === "next_date"
                          ? "header next_date active_date"
                          : "header next_date"
                      }
                    >
                      {nextDate?.toString()?.substring(0, 6)}
                    </div>
                  )}
                  {showPrevious &&
                    checkinDtObj < new Date() &&
                    !(
                      currentDateObj?.getDate() === new Date()?.getDate() &&
                      currentDateObj?.getMonth() === new Date()?.getMonth() &&
                      currentDateObj?.getFullYear() ===
                        new Date()?.getFullYear()
                    ) && (
                      <div style={{ padding: "1rem 0" }} onClick={next}>
                        <TimeSheetNextIcon width="12" height="12" />
                      </div>
                    )}
                </div>
                {isTimesheetActive && (
                  <div className="checkin-content">
                    <div className="checkin-content-header">
                      <div className="header header_type">Type</div>
                      <div className="header header_time">Time</div>
                      <div className="header header_access">Action</div>
                    </div>
                    {timeSheetData !== null ? (
                      timeSheetData?.data()?.presenceData?.map((data, i) => {
                        const isLastRecord =
                          i === timeSheetData.data().presenceData.length - 1; // check if this is the last record in the presenceData array
                        const dateTime = data?.dateTime?.toDate();

                        // Check if this is a check-out record
                        let isCheckOutRecord = false;
                        if (timeSheetData?.data()?.checkOut && dateTime) {
                          const checkOutDateTime = timeSheetData
                            ?.data()
                            ?.checkOut?.toDate();
                          if (
                            checkOutDateTime.getTime() === dateTime.getTime()
                          ) {
                            isCheckOutRecord = true;
                          }
                        }
                        if (!dateTime) {
                          return null;
                        }
                        const hours = dateTime.getHours();
                        const minutes = dateTime.getMinutes();
                        const isPM = hours > 11;
                        const amPmString = isPM ? "PM" : "AM";
                        const hourString =
                          hours % 12 === 0 ? "12" : (hours % 12).toString();
                        const minuteString =
                          minutes < 10
                            ? "0" + minutes.toString()
                            : minutes.toString();
                        const finalString = `${hourString}:${minuteString} ${amPmString}`;
                        return (
                          <div className="checkin-content-data" key={i}>
                            <div className="child font-green uppercase">
                              {isCheckOutRecord ? "checkOut" : data?.type}
                            </div>
                            <div className="child time">{finalString}</div>
                            <div
                              className="child font-blue"
                              onClick={() => {
                                setIsShowEdit(true);
                                setEditDate(data?.dateTime?.toDate());
                                setEditType(data?.type);
                                setPresenceDataIndex(i);
                                setupdatecheckOutTime(isCheckOutRecord);
                              }}
                            >
                              Edit
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        {!isToday && (
                          <div className="empty_timesheet">
                            <p>NOT CHECKED-IN</p>
                            <p>-</p>
                            <p
                              // className="checkin-button spacing clockIn"
                              onClick={() => {
                                setIsShowClockIn(true);
                              }}
                            >
                              Clock-in for that day
                            </p>
                          </div>
                        )}
                      </>
                    )}
                    {isTimesheetActive && (
                      <div className="checkin-content-report">
                        <button className="checkin-button week">
                          <span className="first_child">
                            <b>This Week</b>
                          </span>
                          <span className="border">|</span>
                          <span>
                            <b>{weeklyAverages}</b> per day
                          </span>
                          <span className="border">|</span>
                          <span>
                            <b>{weeklyTotal}</b> total
                          </span>
                        </button>

                        <button className="checkin-button button-spacing month">
                          <span className="first_child">
                            <b>This Month</b>
                          </span>
                          <span className="border">|</span>
                          <span>
                            <b>{monthlyAverages}</b> per day
                          </span>
                          <span className="border">|</span>
                          <span>
                            <b>{monthlyTotal}</b> total
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="colleagues-container">
            <div className="colleagues-accordion">
              <div>
                {isloading ? (
                  <div className="loading">Loading...</div>
                ) : Object.keys(devUserDetails).length === 0 ? (
                  <div className="no-colleagues">
                    <span>You have no colleagues</span>
                  </div>
                ) : (
                  <>
                    <div className="colleagues-item">
                      <div
                        className="colleagues-accordion"
                        onClick={toggleAccordion}
                      >
                        <p className="colleagues">
                          <ColleaguesIcon width="27" height="27" />
                          <b>Your Colleagues</b>
                        </p>
                        <p className="accordion_arrows">
                          {isOpen ? (
                            "-"
                          ) : (
                            <TimeSheetDownArrow width="12" height="12" />
                          )}
                        </p>
                      </div>
                      {isOpen && (
                        <div className="colleagues_contents">
                          {Object.values(devUserDetails).map((user1, i) => {
                            return (
                              <div className="whatever" key={i}>
                                <div
                                  className="avatar-container"
                                  style={{
                                    borderColor:
                                      user1.status === "checkIn"
                                        ? "#60C4AB"
                                        : user1.status === "pause"
                                        ? "#484848"
                                        : user1.status === "NOT CHECKED-IN"
                                        ? "#E8693F"
                                        : user1.status === "checkin"
                                        ? "#60C4AB"
                                        : user1.status === "unpause"
                                        ? "#60C4AB"
                                        : user1.status === "conflict"
                                        ? "#D9D9D9"
                                        : user1.avatar
                                        ? "#Ff0000"
                                        : "transparent", // set border color to transparent if avatar is empty or null
                                  }}
                                >
                                  {user1.avatar ? (
                                    <img src={user1.avatar} alt={user1.name} />
                                  ) : (
                                    <img src={noAvatar} alt={user1.name} />
                                  )}
                                </div>
                                <div className="details">
                                  <h3>{user1.name}</h3>
                                  <p>
                                    {user1.checkIn} {user1.devtimeworked}
                                  </p>
                                  <p>{user1.localtime}</p>
                                </div>
                                <div
                                  className="review"
                                  onClick={() => {
                                    setSelectedUser(user1);
                                    setReviewModal(true);
                                  }}
                                >
                                  {user1.canReview && <p>Review</p>}
                                  {/* <p>Review</p> */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {isOpen && (
                        <div className="legends">
                          <div className="legend-box">
                            <StatusBox color="#E8693F" />
                            <div className="legend-text">Not Checked In</div>
                          </div>
                          <div className="legend-box">
                            <StatusBox color="#484848" />
                            <div className="legend-text">Paused</div>
                          </div>
                          <div className="legend-box">
                            <StatusBox color="#60C4AB" />
                            <div className="legend-text">Active</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit time picker modal */}
      {isShowClockIn && (
        <ClockInTimePicker
          isShowClockIn={isShowClockIn}
          db={db}
          devsReportingReference={devsReportingReference}
          checkinDtObj={checkinDtObj}
          setTimeSheetData={setTimeSheetData}
          setIsShowClockIn={setIsShowClockIn}
        />
      )}
      {isShowEdit && (
        <EditTimePickerModal
          isShowEdit={isShowEdit}
          db={db}
          editDate={editDate}
          docRef={docRef}
          editType={editType}
          presenceDataIndex={presenceDataIndex}
          timeSheetData={timeSheetData}
          firebase={firebase}
          setTimeSheetData={setTimeSheetData}
          setIsShowEdit={setIsShowEdit}
          clockInTime={clockInTime}
          setClockInTime={setClockInTime}
          updatecheckOutTime={updatecheckOutTime}
          setupdatecheckOutTime={setupdatecheckOutTime}
        />
      )}
      {clockOutResolve && (
        <ClockOutResolveModal
          db={db}
          misssingClockOutData={misssingClockOutData}
          setClockOutResolve={setClockOutResolve}
          firebase={firebase}
          devsReportingReference={devsReportingReference}
          isAccountNotGood={isAccountNotGood}
          setIsAccountNotGood={setIsAccountNotGood}
          setTimeSheetData={setTimeSheetData}
          hideCancel={hideCancel} // Pass the hideCancel prop
          setHideCancel={setHideCancel}
          showClockDates={showClockDates}
          setShowClockDates={setShowClockDates}
          showTimePicker={showTimePicker}
          setShowTimePicker={setShowTimePicker}
        />
      )}
      {isShowClockOutModal && (
        <ClockOutFormModal
          isShowClockOutModal={isShowClockOutModal}
          db={db}
          docRef={docRef}
          clockOutTime={new Date()}
          date={editDate}
          clockInTime={clockInTime}
          setIsShowClockOutModal={setIsShowClockOutModal}
          timeSheetData={timeSheetData}
          setTimeSheetData={setTimeSheetData}
          firebase={firebase}
          devsReportingReference={devsReportingReference}
          timeWorkedInSeconds={timeWorkedInSeconds}
          setShowClockDates={setShowClockDates}
          setHideCancel={setHideCancel}
          setClockOutResolve={setClockOutResolve}
          setShowTimePicker={setShowTimePicker}
        />
      )}

      {isNextDay && (
        <ShowMidNightModal
          isNextDay={isNextDay}
          setisNextDay={setisNextDay}
          setIsShowCheckIn={setIsShowCheckIn}
          setFirstDayofWeek={setFirstDayofWeek}
          setFirstDayofMonth={setFirstDayofMonth}
          setTimeSheetData={setTimeSheetData}
          setDevUserDetails={setDevUserDetails}
          devsReportingReference={devsReportingReference}
        />
      )}

      {reviewModal && (
        <ReviewModal
          devsReportingReference={devsReportingReference}
          selectedUser={selectedUser}
          setReviewModal={setReviewModal}
          db={db}
          firebase={firebase}
          currUserId={currUserId}
          currentUserObj={currentUserObj}
        />
      )}
    </div>
  );
};
export default AddCheckIn;
