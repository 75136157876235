import React from 'react';

const ChatGroupAvatar = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="17.5"
        cy="17.5"
        r="17.5"
        fill="#6349FF"
      />
      <path
        d="M24.1739 21.1786C25.2512 21.8111 25.8666 22.938 25.7894 24.0649C25.7377 24.7519 25.7123 24.7801 24.8918 24.89C24.379 24.9727 21.1479 25 17.8151 25C14.0457 25 10.0975 24.9173 9.81525 24.8346C8.68707 24.532 9.30244 22.3327 10.79 21.2885C11.9436 20.4915 14.3025 19.2819 14.9441 19.1438C15.8163 18.9512 15.9189 18.3741 14.9441 16.5047C14.7136 16.0921 14.4568 14.828 14.4313 13.5085C14.4059 11.3638 14.7907 9.90697 16.5343 9.19265C16.8936 9.05542 17.2522 9 17.5853 9C18.7389 9 19.8162 9.68705 20.2519 10.7049C20.8927 12.0517 20.6367 15.6532 19.9697 16.9455C19.2 18.4022 19.2772 18.8702 20.1239 19.1174C20.6884 19.2828 22.432 20.1625 24.1755 21.1795L24.1739 21.1786ZM10.2255 20.3543C9.81525 20.6569 9.43043 21.0413 9.12275 21.4267C8.148 21.4267 7.40464 21.3994 7.32751 21.3712C6.68671 21.2058 7.04526 19.9417 7.89201 19.3919C8.53282 18.9521 9.84068 18.265 10.2001 18.1823C10.662 18.0724 10.7646 17.7697 10.2001 16.7255C10.0721 16.5056 9.91781 15.7904 9.91781 15.0488C9.89237 13.8392 10.1229 13.0141 11.0977 12.6569C11.9182 12.327 12.8412 12.7396 13.1743 13.4821C13.5075 14.2518 13.3286 16.2311 12.9692 16.9463C12.559 17.7988 12.6361 18.0187 13.0972 18.1559C13.1998 18.1832 13.4049 18.2932 13.6872 18.4313C12.559 18.9265 11.0206 19.8335 10.2255 20.3561V20.3543ZM27.0973 19.3092C27.7127 19.6664 28.0458 20.2716 27.9949 20.9041C27.9695 21.2894 27.9695 21.3167 27.5075 21.3712C27.3795 21.3985 26.7896 21.4267 26.02 21.4267C25.6869 20.9595 25.2249 20.5197 24.6613 20.2171C23.6102 19.5573 22.3278 18.8421 21.3793 18.4022C21.6361 18.2923 21.8667 18.2096 21.9692 18.1823C22.4566 18.0724 22.5337 17.7697 21.9692 16.7255C21.8667 16.5056 21.687 15.7904 21.687 15.0488C21.6615 13.8392 21.8667 13.0141 22.8406 12.6569C23.6865 12.327 24.6096 12.7396 24.9172 13.4821C25.2504 14.2518 25.1224 16.2311 24.763 16.9463C24.3527 17.7988 24.4036 18.0187 24.8401 18.1559C25.1478 18.2659 26.1226 18.7612 27.0965 19.311L27.0973 19.3092Z"
        fill="white"
      />
    </svg>
  );
};

export default ChatGroupAvatar;
