import React, { useEffect, useState } from "react";
import SettingsNotificationModal from "../../components/modal/SettingsNotificationModal";
import UserStatusModal from "../../components/modal/UserStatusModal";
import SettingsCatsDotsIcon from "../../components/svgs/SettingsCatsDotsIcon";
import StatusArrow from "../../components/svgs/StatusArrow";
import pencil from "../../static/pencil.png";
import CryptoJS from "crypto-js";
import NoAvatar from "../../components/noAvatar/NoAvatar";

const SettingsPanel = ({
  firebase,
  currentUser,
  settingsVisible,
  db,
  auth,
  currentUserObj,
  truncateString,
  isNestria,
}) => {
  const [file, setFile] = useState(null);
  // const [url, setURL] = useState("");
  const [firstPen, setFirstPen] = useState(false);
  const [firstInput, setFirstInput] = useState(false);
  const [lastPen, setLastPen] = useState(false);
  const [lastInput, setLastInput] = useState(false);
  const [aboutMeInput, setAboutMeInput] = useState(false);
  const [categories, setCategories] = useState(null);
  const [notificationModal, setNotificationModal] = useState(false);
  const [catClassName, setCatClassName] = useState("");
  const [notificationFreq, setNotificationFreq] = useState(null);
  const [notificationChannel, setNotificationChannel] = useState(null);
  const [focused, setFocused] = useState(false);
  const [inputName, setInputName] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [phoneVal, setPhoneVal] = useState("");
  const [currUserData, setCurrUserData] = useState(null);
  const [isUserStatusModal, setIsUserStatusModal] = useState(false);
  const [userEmailFwdData, setUserEmailFwdData] = useState({});
  const [inputValues, setInputValues] = useState({
    first: "",
    last: "",
    aboutMe: "",
  });
  const [channelInput, setChannelInput] = useState({
    email: "",
    sms: "",
  });

  const [emailFwdingInput, setEmailFwdingInput] = useState({
    emailFwding: "",
    emailCred: "",
    server: "",
    port: 0,
  });

  // current user ID
  const userId = currentUser?.uid;

  // initializes the cloud storage
  const storage = firebase.storage();

  // current user reference
  const currUserRef = db.doc(`users/${userId}`);

  // selects image, uploads to firebase storage, and firstore db simultaneously
  const uploadPhoto = async (e) => {
    const path = `users/${userId}`;
    const ref = storage.ref(path);
    const file = e.target.files[0];
    await ref.put(file);

    const fileUrl = await ref.getDownloadURL();

    const fileData = {
      avatar: fileUrl,
    };

    await db.doc(`users/${userId}`).set(fileData, { merge: true });
    setFile(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const editFirstName = async (e) => {
    e.preventDefault();
    const firstNameData = {
      first: inputValues.first,
    };

    await db
      .doc(`users/${currentUser?.uid}`)
      .set(firstNameData, { merge: true });

    setFirstInput(false);
  };

  const editLastName = async (e) => {
    e.preventDefault();
    const lastNameData = {
      last: inputValues.last,
    };

    await db
      .doc(`users/${currentUser?.uid}`)
      .set(lastNameData, { merge: true });

    setLastInput(false);
  };

  const editBio = async (e) => {
    e.preventDefault();
    const aboutMeData = {
      aboutMe: inputValues.aboutMe,
    };

    await db.doc(`users/${currentUser?.uid}`).set(aboutMeData, { merge: true });

    setAboutMeInput(false);
  };

  const logOut = async (e) => {
    e.preventDefault();
    await auth.signOut();
  };

  // connection categories
  useEffect(() => {
    const getSettingsCats = async () => {
      let catsRef = db
        .collection("organisations/T6BAcTjwbXleCFpmWTmu/connectionCats")
        .orderBy("position", "asc");
      const catsDoc = await catsRef?.get();
      let categories = [];
      catsDoc.forEach((doc) => {
        const catData = doc.data();
        const ref = doc?.ref;
        const catDataObj = { ...catData, catRef: ref };
        categories.push(catDataObj);
      });
      setCategories(categories);
    };
    getSettingsCats();
  }, []);

  // notification frequency
  useEffect(() => {
    const getNotificationFrequency = async () => {
      const notificationFreqSnapshot = await db
        .collection("/organisations/T6BAcTjwbXleCFpmWTmu/notificationFrequency")
        .orderBy("rank", "asc")
        .get();
      const notificationFreqData = [];
      notificationFreqSnapshot?.docs?.map((doc) => {
        const frequencyData = doc?.data();
        const ref = doc?.ref;
        const frequencyDataObj = { ...frequencyData, ref: ref };
        notificationFreqData.push(frequencyDataObj);
      });
      setNotificationFreq(notificationFreqData);
    };
    getNotificationFrequency();
  }, []);

  // notification channels
  useEffect(() => {
    const getNotificationChannelData = async () => {
      const notificationChannelSnapshot = await db
        .collection("/organisations/T6BAcTjwbXleCFpmWTmu/notificationChannels")
        .get();
      const notificationChannelData = [];
      let currUserRes;
      notificationChannelSnapshot?.docs?.map(async (doc) => {
        const channelData = doc?.data();
        const ref = doc?.ref;

        // users data
        currUserRes = currUserRef?.onSnapshot((snapshot) => {
          const currUserObj = snapshot?.data();
          if (currUserData !== null) return;
          setCurrUserData(currUserObj);
        });

        // updates users channel data (email, phone) values
        currUserData?.notificationChannelData?.map((data) => {
          const emailTypeRef = db.doc(
            "organisations/T6BAcTjwbXleCFpmWTmu/notificationChannels/EMAIL"
          );
          const smsTypeRef = db.doc(
            "organisations/T6BAcTjwbXleCFpmWTmu/notificationChannels/PUSH"
          );

          if (data?.type?.id === emailTypeRef?.id) {
            setEmailVal(data?.email);
          }
          if (data?.type?.id === smsTypeRef?.id) {
            setPhoneVal(data?.phone);
          }
        });

        // default notification settings by category
        let userNotificationSettings = {};
        let companyNotificationSettings = {};
        let techNotificationSettings = {};
        let industryNotificationSettings = {};
        let projectNotificationSettings = {};

        currUserData?.defaultNotificationSettings?.map((settings) => {
          if (settings?.connectionCat?.id === "user") {
            userNotificationSettings = settings;
            return userNotificationSettings;
          }
          if (settings?.connectionCat?.id === "company") {
            companyNotificationSettings = settings;
            return companyNotificationSettings;
          }
          if (settings?.connectionCat?.id === "Technology") {
            techNotificationSettings = settings;
            return techNotificationSettings;
          }
          if (settings?.connectionCat?.id === "industry") {
            industryNotificationSettings = settings;
            return industryNotificationSettings;
          }
          if (settings?.connectionCat?.id === "project") {
            projectNotificationSettings = settings;
            return projectNotificationSettings;
          }
        });

        const ChannelDataObj = {
          ...channelData,
          ref: ref,
          currentUserObj,
          userNotificationSettings: userNotificationSettings,
          companyNotificationSettings: companyNotificationSettings,
          techNotificationSettings: techNotificationSettings,
          industryNotificationSettings: industryNotificationSettings,
          projectNotificationSettings: projectNotificationSettings,
        };
        notificationChannelData.push(ChannelDataObj);
      });
      setNotificationChannel(notificationChannelData);
      return currUserRes;
    };
    getNotificationChannelData();
  }, [currUserData]);

  // handle channel data input values
  const handleChannelInput = (e) => {
    const { name, value } = e.target;
    setChannelInput((prevState) => ({ ...prevState, [name]: value }));
  };

  // updates email notification channel data in user's doc
  const saveEmailChannel = (e) => {
    e.preventDefault();

    const emailTypeRef = db.doc(
      "organisations/T6BAcTjwbXleCFpmWTmu/notificationChannels/EMAIL"
    );

    currentUserObj?.notificationChannelData?.map(async (data) => {
      if (data?.type?.id === emailTypeRef?.id) {
        const removeEmailChanData = [
          {
            email: data?.email,
            type: data?.type,
          },
        ];

        const removeEmailChannel = {
          notificationChannelData: firebase.firestore.FieldValue.arrayRemove(
            ...removeEmailChanData
          ),
        };
        await currUserRef.update(removeEmailChannel);
      }

      const emailChannelData = [
        {
          email: channelInput.email,
          type: emailTypeRef,
        },
      ];

      const updateEmailChannel = {
        notificationChannelData: firebase.firestore.FieldValue.arrayUnion(
          ...emailChannelData
        ),
      };
      await currUserRef.set(updateEmailChannel, { merge: true });
    });
    setFocused(false);
  };

  // updates tel notification channel data for sms in user's doc
  const saveSmsTelChannel = async (e) => {
    e.preventDefault();

    const smsTypeRef = db.doc(
      "organisations/T6BAcTjwbXleCFpmWTmu/notificationChannels/PUSH"
    );

    currentUserObj?.notificationChannelData?.map(async (data) => {
      if (data?.type?.id === smsTypeRef?.id) {
        const removePhoneChanData = [
          {
            phone: data?.phone,
            type: data?.type,
          },
        ];

        const removePhoneChannel = {
          notificationChannelData: firebase.firestore.FieldValue.arrayRemove(
            ...removePhoneChanData
          ),
        };
        await currUserRef.update(removePhoneChannel);
      }

      const phoneChannelData = [
        {
          phone: channelInput.sms,
          type: smsTypeRef,
        },
      ];

      const updatePhoneChannel = {
        notificationChannelData: firebase.firestore.FieldValue.arrayUnion(
          ...phoneChannelData
        ),
      };
      await currUserRef.set(updatePhoneChannel, { merge: true });
    });
    setFocused(false);
  };

  // activates/deactivates user email fowrding on toggle
  const isUserEmailFwdingActive = async (e) => {
    const { checked } = e.target;

    const setUserEmailFwdTrue = {
      emailFwd: { status: true },
    };

    const setUserEmailFwdFalse = {
      emailFwd: { status: false },
    };

    if (checked) {
      await currUserRef.set(setUserEmailFwdTrue, { merge: true });
    } else {
      await currUserRef.set(setUserEmailFwdFalse, { merge: true });
    }
  };

  // handle email forwarding data input values
  const handleEmailFwdingInput = (e) => {
    const { name, value } = e.target;
    setEmailFwdingInput((prevState) => ({ ...prevState, [name]: value }));
  };

  // updates user email forwarding email address within emailFwd map
  const saveUserFwdingEmail = async (e) => {
    e.preventDefault();

    const userEmailFwdingData = {
      emailFwd: { email: emailFwdingInput?.emailFwding },
    };

    await currUserRef.set(userEmailFwdingData, { merge: true });

    setFocused(false);
  };

  // updates user email forwarding cred within emailFwd map
  const saveUserFwdingCred = async (e) => {
    e.preventDefault();

    // START OF ENCRYPTION

    // This portion of the code deals with encrypting the user's password.
    // The following steps are used:
    // 1. Generate a random string.
    // 2. Encrypt the random string.
    // 3. Encrypt the user's password with the random string.
    // 4. Save the encrypted random string and the encrypted password in the database.

    // 1. Generate a random string.
    let randString = CryptoJS.lib.WordArray.random(20).toString();
    for (let i; i < 3; i++)
      randString = CryptoJS.lib.WordArray.random(20).toString();

    // 2. Encrypt the random string.
    const pass = CryptoJS.AES.encrypt(randString, "", {
      mode: CryptoJS.mode.CFB,
      padding: CryptoJS.pad.AnsiX923,
    }).toString();

    // 3. Encrypt the user's password with the random string.
    const encryptedPassword = CryptoJS.AES.encrypt(
      emailFwdingInput?.emailCred,
      randString,
      {
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.AnsiX923,
      }
    ).toString();

    // 4. Save the encrypted random string and the encrypted password in the database.
    // TODO: Save the encrypted random string and the encrypted password in the database.

    // END OF ENCRYPTION

    const userEmailFwdingData = {
      emailFwd: { cred: encryptedPassword, pass, lastUpdated: new Date() },
    };

    await currUserRef.set(userEmailFwdingData, { merge: true });

    setFocused(false);
  };

  // updates user email forwarding server within emailFwd map
  const saveUserFwdingServer = async (e) => {
    e.preventDefault();

    const userEmailFwdingData = {
      emailFwd: { server: emailFwdingInput?.server },
    };

    await currUserRef.set(userEmailFwdingData, { merge: true });

    setFocused(false);
  };

  // updates user email forwarding port within emailFwd map
  const saveUserFwdingPort = async (e) => {
    e.preventDefault();

    const userEmailFwdingData = {
      emailFwd: { port: parseInt(emailFwdingInput?.port, 10) },
    };

    await currUserRef.set(userEmailFwdingData, { merge: true });

    setFocused(false);
  };

  // user email forwarding data from the db
  useEffect(() => {
    // updates users email forwarding data (email, cred, server, port) values
    if (currUserData !== null) {
      setUserEmailFwdData(currUserData?.emailFwd);
    }
  }, [currUserData]);

  // determines default value of email forwarding input fields
  const getDefaultEmailFwdingValue = (fieldName) => {
    switch (fieldName) {
      case "emailFwding":
        if (emailFwdingInput.emailFwding !== "") {
          return emailFwdingInput.emailFwding;
        } else if (focused && emailFwdingInput.emailFwding !== "") {
          return emailFwdingInput.emailFwding;
        } else if (!focused && emailFwdingInput.emailFwding === "") {
          return userEmailFwdData?.email;
        } else {
          return null;
        }
      case "emailCred":
        if (emailFwdingInput.emailCred !== "") {
          return emailFwdingInput.emailCred;
        } else if (focused && emailFwdingInput.emailCred !== "") {
          return emailFwdingInput.emailCred;
        } else if (!focused && emailFwdingInput.emailCred === "") {
          return userEmailFwdData?.cred;
        } else {
          return null;
        }
      case "server":
        if (emailFwdingInput.server !== "") {
          return emailFwdingInput.server;
        } else if (focused && emailFwdingInput.server !== "") {
          return emailFwdingInput.server;
        } else if (!focused && emailFwdingInput.server === "") {
          return userEmailFwdData?.server;
        } else {
          return null;
        }
      case "port":
        if (emailFwdingInput.port > 0) {
          return emailFwdingInput.port;
        } else if (focused && emailFwdingInput.port > 0) {
          return emailFwdingInput.port;
        } else if (!focused && emailFwdingInput.port === 0) {
          return userEmailFwdData?.port;
        } else {
          return null;
        }
      default:
        return null;
    }
  };

  return (
    <>
      {settingsVisible && (
        <>
          <div className="center-div">
            <div className="main_settings">
              <h3 className="user_settings">User Settings</h3>
              <section className="settings_modal">
                <div className="profile_container">
                  {currentUserObj?.avatar !== undefined ? (
                    <img src={currentUserObj?.avatar} alt="profile" />
                  ) : (
                    <NoAvatar
                      text={currentUserObj?.first ?? currentUserObj?.last}
                      size={4.063}
                      radius={50}
                    />
                  )}
                  <label htmlFor="filePicker">Upload</label>
                  <input
                    id="filePicker"
                    type={"file"}
                    style={{ visibility: "hidden" }}
                    onChange={uploadPhoto}
                  />
                </div>
                <div className="profile_details">
                  <div className="name_status_container">
                    <p
                      className="first_name"
                      onMouseOver={() => setFirstPen(true)}
                      onMouseLeave={() => setFirstPen(false)}
                    >
                      First name
                      <br />
                      <b>{truncateString(currentUserObj?.first ?? "", 21)}</b>
                      {firstPen && !firstInput && (
                        <img
                          src={pencil}
                          alt="pencil"
                          className="first_pencil"
                          onClick={() => {
                            setFirstInput(true);
                            setFirstPen(false);
                            setInputValues({
                              first: currentUserObj?.first ?? "",
                            });
                          }}
                        />
                      )}
                      {firstInput && (
                        <div className="first_container">
                          <input
                            type="text"
                            name="first"
                            value={inputValues.first}
                            onMouseOver={() => setFirstPen(false)}
                            onChange={handleInputChange}
                          />
                          <button
                            className="cancel"
                            onClick={() => {
                              setFirstInput(false);
                              setFirstPen(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="save"
                            onClick={(e) => {
                              editFirstName(e);
                              setFirstPen(false);
                            }}
                            disabled={
                              inputValues?.first?.length === 0 ||
                              inputValues?.first?.trim()?.length === 0
                            }
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </p>
                    <p
                      className="last_name"
                      onMouseOver={() => setLastPen(true)}
                      onMouseLeave={() => setLastPen(false)}
                    >
                      Last name
                      <br />
                      <b>{truncateString(currentUserObj?.last ?? "", 21)}</b>
                      {lastPen && !lastInput && (
                        <img
                          src={pencil}
                          alt="pencil"
                          className="last_pencil"
                          onClick={() => {
                            setLastInput(true);
                            setLastPen(false);
                            setInputValues({
                              last: currentUserObj?.last ?? "",
                            });
                          }}
                        />
                      )}
                      {lastInput && (
                        <div className="last_container">
                          <input
                            type="text"
                            name="last"
                            value={inputValues.last}
                            onMouseOver={() => setLastPen(false)}
                            onChange={handleInputChange}
                          />
                          <button
                            className="cancel"
                            onClick={() => {
                              setLastInput(false);
                              setLastPen(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="save"
                            onClick={(e) => {
                              editLastName(e);
                              setLastPen(false);
                            }}
                            disabled={
                              inputValues?.last?.length === 0 ||
                              inputValues?.last?.trim()?.length === 0
                            }
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </p>
                    <div
                      className="current_status"
                      onClick={() => setIsUserStatusModal((prev) => !prev)}
                    >
                      <div className="status_container">
                        <div className="status_header">
                          <p>Current Status</p>
                          <StatusArrow width="8" height="8" />
                        </div>
                        <div className="status">
                          <span className="status_indicator"></span>
                          <span className="my_status">Online</span>
                        </div>
                      </div>
                      {isUserStatusModal && <UserStatusModal />}
                    </div>
                  </div>
                  <p className="aboutMe">
                    About Me
                    <button
                      className="edit_Btn"
                      onClick={() => {
                        setAboutMeInput(true);
                        setInputValues({ aboutMe: currentUserObj?.aboutMe });
                      }}
                    >
                      Edit
                    </button>
                    <br />
                    <b>{currentUserObj?.aboutMe}</b>
                    {aboutMeInput && (
                      <div className="aboutMe_container">
                        <input
                          type="text"
                          name="aboutMe"
                          value={inputValues.aboutMe}
                          onChange={handleInputChange}
                        />
                        <button
                          className="cancel"
                          onClick={() => {
                            setAboutMeInput(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="save"
                          onClick={(e) => {
                            editBio(e);
                          }}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </p>
                </div>
              </section>
              <section className="connection_cats">
                <h3>Connection categories to view</h3>
                <p>
                  Spryte’s Oratio tool allows you to set alerts and
                  notifications to keep in touch not just with people, but also
                  work opportunities, news about your favorite technologies, and
                  industry sectors of interest. Toggling which categories are
                  displayed won’t delete any data, you can always toggle
                  categories back on to view relevant connections you’ve made
                  previously.
                </p>
                <div className="checkboxes">
                  <div>
                    <input type="checkbox" />
                    <label>People</label>
                  </div>
                  <div>
                    <input type="checkbox" />
                    <label>Industry Sectors</label>
                  </div>
                  <div>
                    <input type="checkbox" />
                    <label>Project Types</label>
                  </div>
                  <div>
                    <input type="checkbox" />
                    <label>Companies</label>
                  </div>
                  <div>
                    <input type="checkbox" />
                    <label>Searches</label>
                  </div>
                  <div>
                    <input type="checkbox" />
                    <label>Technologies</label>
                  </div>
                  <div>
                    <input type="checkbox" />
                    <label>Requirements</label>
                  </div>
                </div>
              </section>
              <section className="notification_settings">
                <h3>Default notification settings</h3>
                <p>
                  These settings will apply to any new connections you make, but
                  will not effect existing connections
                </p>
                <div className="default_notification">
                  {categories &&
                    categories?.map((category) => {
                      return (
                        <div
                          className={
                            (catClassName === category?.label) &
                            notificationModal
                              ? "cat_label_container active"
                              : "cat_label_container inactive"
                          }
                          key={category?.position}
                        >
                          <div
                            onClick={() => {
                              setNotificationModal((prev) => !prev);
                              setCatClassName(category?.label);
                            }}
                          >
                            {/* <span className='dots'></span> */}
                            <SettingsCatsDotsIcon
                              width="13"
                              height="13"
                              bottom="-0.4rem"
                              right="0.5rem"
                            />
                            <span>{category?.label}</span>
                          </div>
                          {/* notification modal */}
                          {notificationModal &&
                            catClassName === category?.label && (
                              <SettingsNotificationModal
                                firebase={firebase}
                                db={db}
                                userId={userId}
                                currentUserObj={currentUserObj}
                                category={category}
                                notificationFreq={notificationFreq}
                                notificationChannel={notificationChannel}
                              />
                            )}
                        </div>
                      );
                    })}
                </div>
              </section>
              <section className="notification_channel">
                <h3>Notification channel data</h3>
                <p>Set the data points for each notification channel here</p>
                <div className="channel_data_inputs">
                  {/* <div>
                    <label>Email</label>
                    <input
                      type='text'
                      name='email'
                      placeholder='email@example.com'
                      // value={channelInput.email}
                      value={channelInput.email !== '' ? channelInput.email : emailVal}
                      onFocus={() => {
                        setFocused(true);
                        setInputName('email');
                      }}
                      onChange={handleChannelInput}
                    />
                    {(focused && inputName === 'email') &&
                      <div className='input_btns'>
                        <button className='cancel' onClick={() => setFocused(false)}>Cancel</button>
                        <button className='save' onClick={saveEmailChannel}>Save</button>
                      </div>
                    }
                  </div> */}
                  <div>
                    <label>Phone</label>
                    <input
                      type="tel"
                      name="sms"
                      // value={channelInput.sms}
                      value={
                        channelInput.sms !== "" ? channelInput.sms : phoneVal
                      }
                      onFocus={() => {
                        setFocused(true);
                        setInputName("sms");
                      }}
                      // onBlur={() => setFocused(false)}
                      onChange={handleChannelInput}
                    />
                    {focused && inputName === "sms" && (
                      <div className="input_btns">
                        <button
                          className="cancel"
                          onClick={() => setFocused(false)}
                        >
                          Cancel
                        </button>
                        <button className="save" onClick={saveSmsTelChannel}>
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="checkbox_container">
                    <label>Use Email Forwarding</label>
                    <input
                      type="checkbox"
                      name="use_email_forwarding"
                      defaultChecked={userEmailFwdData?.status}
                      onChange={isUserEmailFwdingActive}
                    />
                  </div>
                  <div>
                    <label>Email Forwarding</label>
                    <input
                      type="text"
                      name="emailFwding"
                      placeholder="username@email.com"
                      // value={emailFwdingInput.emailFwding !== '' || (focused && emailFwdingInput.emailFwding === '') ? emailFwdingInput.emailFwding : userEmailFwdData?.email}
                      value={getDefaultEmailFwdingValue("emailFwding")}
                      onFocus={() => {
                        setFocused(true);
                        setInputName("email_forwarding");
                      }}
                      // onBlur={() => setFocused(false)}
                      onChange={handleEmailFwdingInput}
                    />
                    {focused && inputName === "email_forwarding" && (
                      <div className="input_btns">
                        <button
                          className="cancel"
                          onClick={() => setFocused(false)}
                        >
                          Cancel
                        </button>
                        <button className="save" onClick={saveUserFwdingEmail}>
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                  <div>
                    <label>Email Credentials</label>
                    <input
                      type="password"
                      name="emailCred"
                      placeholder="password"
                      // value={emailFwdingInput.emailCred !== '' ? emailFwdingInput.emailCred : userEmailFwdData?.cred}
                      value={getDefaultEmailFwdingValue("emailCred")}
                      onFocus={() => {
                        setFocused(true);
                        setInputName("email_cred");
                      }}
                      onChange={handleEmailFwdingInput}
                    />
                    {focused && inputName === "email_cred" && (
                      <div className="input_btns">
                        <button
                          className="cancel"
                          onClick={() => setFocused(false)}
                        >
                          Cancel
                        </button>
                        <button className="save" onClick={saveUserFwdingCred}>
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                  <div>
                    <label>Server</label>
                    <input
                      type="text"
                      name="server"
                      placeholder="smtp.mail.google.com"
                      // value={emailFwdingInput.server !== '' ? emailFwdingInput.server : userEmailFwdData?.server}
                      value={getDefaultEmailFwdingValue("server")}
                      onFocus={() => {
                        setFocused(true);
                        setInputName("server");
                      }}
                      onChange={handleEmailFwdingInput}
                    />
                    {focused && inputName === "server" && (
                      <div className="input_btns">
                        <button
                          className="cancel"
                          onClick={() => setFocused(false)}
                        >
                          Cancel
                        </button>
                        <button className="save" onClick={saveUserFwdingServer}>
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                  <div>
                    <label>Port</label>
                    <input
                      type="number"
                      name="port"
                      placeholder="587"
                      value={getDefaultEmailFwdingValue("port")}
                      onFocus={() => {
                        setFocused(true);
                        setInputName("port");
                      }}
                      onChange={handleEmailFwdingInput}
                    />
                    {focused && inputName === "port" && (
                      <div className="input_btns">
                        <button
                          className="cancel"
                          onClick={() => setFocused(false)}
                        >
                          Cancel
                        </button>
                        <button className="save" onClick={saveUserFwdingPort}>
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className='spryte_email'>
                  <label>Spryte Email</label>
                  <p>useremail@sprytelab.com</p>
                  <button>Reset</button>
                </div> */}
              </section>
              <div className="logout">
                <button className="logout_btn" onClick={logOut}>
                  Logout
                </button>
              </div>
            </div>
          </div>
          {/* {currentUserObj?.avatar !== '' &&
            <div className='profile_footer'>
              <img src={currentUserObj?.avatar !== '' ? currentUserObj?.avatar : emu} alt='profile_photo' />                
            </div>
          } */}
        </>
      )}
    </>
  );
};

export default SettingsPanel;
