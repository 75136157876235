import React from 'react';

const ReadReceiptsIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66667 11.1346L0 6.46797L1.63333 4.83464L4.66667 7.86797L12.3667 0.167969L14 1.8013L4.66667 11.1346Z"
        fill="#25C7AA"
      />
      <path
        d="M16.6667 11.1346L12 6.46797L13.6333 4.83464L16.6667 7.86797L24.3667 0.167969L26 1.8013L16.6667 11.1346Z"
        fill="#25C7AA"
      />
    </svg>
  );
};

export default ReadReceiptsIcon;