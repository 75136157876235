import React, { useContext, useState } from "react";
import Spinner from "../spinner/Spinner";
import OratioContext from "../../oratioContext/OratioContext";
import DisconnectModal from "../modal/DisconnectModal";
import NotificationModal from "../modal/NotificationModal";
// import DownArrow from '../svgs/DownArrow';
import NotificationIcon from "../svgs/NotificationIcon";
import OptionsIcon from "../svgs/OptionsIcon";
import NoAvatar from "../noAvatar/NoAvatar";
import AdminUserView from "../nestria/Admin/AdminUserView";

const UserConnections = ({
  userConnections,
  firebase,
  db,
  loading,
  disconnect,
  truncateString,
  userId,
  notificationFreq,
  notificationChannel,
  windowSize,
  SwitchToMessageSection,
  currConnectionId,
  showTruncatedBio,
  isOptions,
  isDisconnectModal,
  setIsDisconnectModal,
  setShowTruncatedBio,
  updateOptionsData,
  isAsciiOnly,
  isNestria,
  SwitchToViewsSection,
}) => {
  const [userConnId, setUSerConnId] = useState("");
  const [notificationModal, setNotificationModal] = useState(false);
  const [itemId, setItemId] = useState("");
  // destructures prop/value from the OratioContext component
  const { connectionObj, setConnectionObj } = useContext(OratioContext);
  const { showUserViewSceen, setShowUserViewSceen } = useState(false);

  const showUserView = () => {
    setShowUserViewSceen(true);
  };

  return (
    <div className="users-connections">
      {loading ? (
        <Spinner width="6.25rem" height="6.25rem" overlayHeight="50vh" />
      ) : (
        userConnections?.map((connection) => {
          const allTypesArr = connection?.types.join(" | ");
          return (
            <div className="user-card" key={connection?.id}>
              {connection?.avatar ? (
                <div className="user-avatar">
                  <img
                    src={connection?.avatar}
                    className="avatar_image"
                    alt="avatar"
                  />
                </div>
              ) : (
                <div className="user-avatar">
                  <NoAvatar text={connection?.first} size={3.5} radius={0} />
                </div>
              )}
              <div className="info-section">
                <div className="user-info">
                  <p className="name">
                    {connection?.first + " " + connection?.last}
                    <small>
                      {windowSize <= 1040 && windowSize > 280
                        ? truncateString(allTypesArr, 8)
                        : windowSize <= 280
                        ? truncateString(allTypesArr, 3)
                        : truncateString(allTypesArr, 35)}
                    </small>
                  </p>
                  {(connection?.userRolesData?.role?.id ||
                    connection?.userRolesData2?.role?.id) && (
                    <p className="user_role">
                      {connection?.userRolesData?.role?.id ??
                        connection?.userRolesData2?.role?.id}{" "}
                      @ {truncateString(connection?.orgData?.name, 12)}
                    </p>
                  )}
                </div>
                <div className="user-desc">
                  {connection?.aboutMe?.length > 80 ? (
                    <>
                      {showTruncatedBio ? (
                        <>
                          <p className="bio">
                            {isAsciiOnly(connection?.aboutMe) === true ? (
                              <>{truncateString(connection?.aboutMe, 25)}</>
                            ) : (
                              <>{truncateString(connection?.aboutMe, 80)}</>
                            )}
                          </p>
                          <p
                            onClick={() => {
                              setShowTruncatedBio(false);
                              setUSerConnId(connection?.id);
                            }}
                            className="show_btn"
                          >
                            Show more
                          </p>
                        </>
                      ) : (
                        <>
                          {userConnId === connection?.id ? (
                            <>
                              <p className="bio">{connection?.aboutMe}</p>
                              <p
                                onClick={() => {
                                  setShowTruncatedBio(true);
                                  setUSerConnId("");
                                }}
                                className="show_btn"
                              >
                                Show less
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="bio">
                                {isAsciiOnly(connection?.aboutMe) === true ? (
                                  <>{truncateString(connection?.aboutMe, 25)}</>
                                ) : (
                                  <>{truncateString(connection?.aboutMe, 80)}</>
                                )}
                              </p>
                              <p
                                onClick={() => {
                                  setShowTruncatedBio(false);
                                  setUSerConnId(connection?.id);
                                }}
                                className="show_btn"
                              >
                                Show more
                              </p>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <p className="bio">{connection?.aboutMe}</p>
                  )}
                </div>
              </div>
              <div className="msg_notifs_option_btn">
                {connection?.reciprocal && (
                  <>
                    {isNestria ? (
                      <div
                        className="bn3"
                        onClick={() => SwitchToViewsSection(connection)}
                      >
                        Views
                      </div>
                    ) : (
                      <div
                        className="bn3"
                        onClick={() => SwitchToMessageSection(connection)}
                      >
                        Message
                      </div>
                    )}
                    <div className="notifications">
                      <div
                        className="notification_btn_container"
                        onClick={() => {
                          setNotificationModal((prev) => !prev);
                          setItemId(connection?.id);
                        }}
                      >
                        <NotificationIcon width="24" height="24" />
                      </div>
                      {/* notification modal */}
                      {notificationModal && itemId === connection?.id && (
                        <NotificationModal
                          firebase={firebase}
                          db={db}
                          connection={connection}
                          userId={userId}
                          notificationFreq={notificationFreq}
                          notificationChannel={notificationChannel}
                        />
                      )}
                    </div>
                    <div className="options">
                      <div onClick={() => updateOptionsData(connection)}>
                        <OptionsIcon width="24" height="24" color="black" />
                      </div>
                      {isOptions && currConnectionId === connection?.id && (
                        <div
                          className="disconnect_btn"
                          onClick={() => {
                            setIsDisconnectModal(true);
                            setConnectionObj(connection);
                          }}
                        >
                          Disconnect
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })
      )}
      {/* confirmation modal */}
      {isDisconnectModal && (
        <DisconnectModal
          connectionObj={connectionObj}
          closeModal={() => setIsDisconnectModal(false)}
          disconnect={disconnect}
        />
      )}
    </div>
  );
};

export default UserConnections;
