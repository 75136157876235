import React, { useContext, useState } from "react";
import { useEffect } from "react";
import OratioContext from "../../oratioContext/OratioContext";
import AcceptModal from "../../components/modal/AcceptModal";
import EmailInvites from "../../components/emailInvites/EmailInvites";
import NoAvatar from "../../components/noAvatar/NoAvatar";

const ConnectionRequest = ({
  db,
  currentUser,
  requestVisible,
  currentUserObj,
  setShowInviteUser,
  setShowInviteDismissBtn,
  truncateString,
  windowSize,
  isNestria,
}) => {
  const [notificationVal, setNotificationVal] = useState("");
  const [connectionRequest, setConnectionRequest] = useState();
  const [isAcceptModal, setIsAcceptModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [acceptConnectTypes, setAcceptConnectTypes] = useState("");
  const [currentUserRef2, setCurrentUserRef2] = useState("");

  // current user ID
  const userId = currentUser?.uid;

  // destructures prop/value from the OratioContext component
  const { setRequestCount } = useContext(OratioContext);

  console.log("Inside connection request page");
  useEffect(() => {
    const notificationTypes = async () => {
      const snapshot = await db
        .doc(`/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/user`)
        .get();
      const snapshotData = snapshot?.data();
      const notificationTypeRef = snapshot?.ref;
      const notificationTypeSnapshot =
        await snapshotData?.defaultNotificationType?.get();
      const notificationTypeData = notificationTypeSnapshot?.data();
      // console.log('notificationTypeData', notificationTypeData);
      const notificationTypeObj = {
        ...notificationTypeData,
        ref: notificationTypeRef,
      };
      setNotificationVal(notificationTypeObj);
    };
    notificationTypes();
  }, []);

  // creates connections
  const acceptConnection = async (id, connectionTypesList) => {
    // doc references
    const userConnectionCatRef = db.doc(
      "/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/user"
    );
    const companyConnectionCatRef = db.doc(
      "/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/company"
    );
    const techConnectionCatRef = db.doc(
      "/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/Technology"
    );

    let connectionTypesArr = connectionTypesList?.map((item) => {
      return db.doc(
        `/organisations/T6BAcTjwbXleCFpmWTmu/connectionTypes/${item}`
      );
    });

    const currentUserRef = db.doc(`${currentUserRef2}`);
    const userRef2 = selectedData?.ref;

    const notificationValue = db.doc(
      `organisations/T6BAcTjwbXleCFpmWTmu/notificationTypes/${notificationVal?.type?.toLocaleLowerCase()}`
    );

    const key = selectedData?.ref?.parent?.id;

    // connection data
    const connectData = {
      connectedOn: new Date(),
      connectionCat:
        key === "users"
          ? userConnectionCatRef
          : key === "companies"
          ? companyConnectionCatRef
          : techConnectionCatRef,
      connectionTypes: connectionTypesArr,
      lastUpdated: new Date(),
      ref: currentUserRef,
      notificationType: notificationValue,
      // note: note,
      reciprocal: true,
    };

    await db.collection(`/users/${userId}/connections`).add(connectData);

    // const acceptData = {
    //   reciprocal: true
    // };
    // selectedData.sentRequestRef.set(acceptData, { merge: true });
  };

  const currUserRef = db.doc(`users/${userId}`);

  useEffect(() => {
    const getConnectionRequests = async () => {
      const requestRef = db
        .collectionGroup("connections")
        .where("reciprocal", "==", false)
        .where("ref", "==", currUserRef)
        .orderBy("connectedOn", "desc");
      const requestRes = requestRef?.onSnapshot(async (snapshot) => {
        const data = await Promise.all(
          snapshot?.docs?.map(async (doc) => {
            // console.log('doc', doc);
            const docId = doc?.id;
            const sentRequestRef = doc?.ref;
            const sentReqUserRef = doc?.ref?.parent?.parent?.path;
            const sentReqUserDataSnap = await doc?.ref?.parent?.parent?.get();
            const sentReqUserData = sentReqUserDataSnap?.data();
            const connectRequestData = doc?.data();
            const connectionTypes = connectRequestData?.connectionTypes?.map(
              (types) => types.id
            );
            return {
              id: docId,
              ...connectRequestData,
              ...sentReqUserData,
              sentRequestRef: sentRequestRef,
              sentReqUserRef: sentReqUserRef,
              types: connectionTypes,
            };
          })
        );
        setConnectionRequest(data);
        setRequestCount(data?.length);
      });
      return requestRes;
    };
    getConnectionRequests();
  }, []);

  // updates request data obj for accepting connection requests
  const updateRequestData = (request) => {
    const key = request?.ref?.parent?.id;
    setIsAcceptModal(true);
    setSelectedData(request);
    setCurrentUserRef2(request.sentReqUserRef);
    setAcceptConnectTypes(
      key === "users" ? "user" : key === "companies" ? "company" : "Technology"
    );
  };

  // deletes connection request
  // const deleteRequest = async (ref) => {
  //   await ref?.delete();
  // };

  // hides email invite dismiss button
  useEffect(() => {
    setShowInviteDismissBtn(false);
  }, []);

  return (
    <>
      {requestVisible && (
        <div className="request-center-div transition-6">
          <div className="request_main">
            {connectionRequest &&
              connectionRequest?.map((request) => {
                const allConnTypesArr = request?.types.join(" | ");
                return (
                  <div className="req_user_card" key={request?.id}>
                    <div className="req_user_avatar">
                      {request?.avatar ? (
                        <img
                          src={request?.avatar}
                          className="req_avatar_image"
                          alt="avatar"
                        />
                      ) : (
                        <NoAvatar
                          text={request?.first ?? request?.last}
                          size={3.438}
                          radius={0}
                        />
                      )}
                    </div>
                    <div className="req_info_section">
                      <div className="req_user_info">
                        <p className="user_req_name">
                          {request?.first + " " + request?.last}
                          <small>
                            {windowSize <= 1040 && windowSize > 280
                              ? truncateString(` | ${allConnTypesArr}`, 8)
                              : windowSize <= 280
                              ? truncateString(` | ${allConnTypesArr}`, 3)
                              : truncateString(` | ${allConnTypesArr}`, 35)}
                          </small>
                        </p>
                        {/* <span className='user_req_role'>
                            {request?.userRolesData?.role?.id && request?.name &&
                              request?.userRolesData?.role?.id + ' @ ' + request?.name
                            }
                          </span> */}
                      </div>
                      <div className="request_note">
                        <p>{request?.note}</p>
                      </div>
                    </div>
                    <div className="message-btn">
                      <div
                        className="request_btn"
                        onClick={() => updateRequestData(request)}
                      >
                        Accept
                      </div>
                      {/* <div
                        className={requestCount > 0 ? "request_btn disable" : "request_btn"}
                        onClick={() => deleteRequest(request?.sentRequestRef)}
                      >
                        Decline
                      </div> */}
                    </div>
                  </div>
                );
              })}
            {/* email invite component */}
            <div>
              <EmailInvites
                db={db}
                currentUserObj={currentUserObj}
                setShowInviteUser={setShowInviteUser}
              />
            </div>
          </div>
          {/* accept connection request modal */}
          {isAcceptModal && (
            <AcceptModal
              closeModal={() => setIsAcceptModal(false)}
              acceptConnection={acceptConnection}
              db={db}
              selectedData={selectedData}
              setCurrentUserRef2={setCurrentUserRef2}
              acceptConnectTypes={acceptConnectTypes}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ConnectionRequest;
