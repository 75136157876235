import React from 'react';

const SpryteLogoIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.83929 6.13744C8.38807 6.86639 8.61767 7.81891 9.35587 8.26604L19.5791 14.4613C19.8406 14.6204 20 14.9006 20 15.2029C20 16.1318 19.4994 16.9883 18.6862 17.4511L11.317 21.6517C10.5038 22.1161 9.50096 22.1161 8.68622 21.6517L1.31378 17.4511C0.500638 16.9867 0 16.1302 0 15.2029V12.3264C0.0478316 11.9155 0.400191 11.5975 0.827487 11.5975C0.974171 11.5975 1.11448 11.6353 1.23246 11.7014L1.29783 11.7423L9.35268 16.623C9.60778 16.7773 9.88999 16.8513 10.1706 16.8513C10.6967 16.8513 11.2117 16.5884 11.5067 16.1129C11.9595 15.3855 11.7299 14.433 10.9917 13.9859L0.420918 7.5796C0.159439 7.42058 0 7.14034 0 6.83805V6.80026C0 5.87136 0.500638 5.01331 1.31378 4.55043L8.68304 0.348338C9.49777 -0.116113 10.5006 -0.116113 11.3138 0.348338L18.683 4.54886C19.4962 5.01331 19.9968 5.87136 19.9968 6.79869V9.72079C19.949 10.1317 19.5966 10.4482 19.1677 10.4482C19.0545 10.4482 18.9429 10.4261 18.8425 10.3836C18.8409 10.3836 18.8393 10.382 18.8377 10.382L18.6368 10.2592H18.6352L10.9933 5.62733C10.2551 5.1802 9.2905 5.40691 8.83769 6.13586L8.83929 6.13744Z"
        fill="#6349FF"
      />
    </svg>
  );
};

export default SpryteLogoIcon;
