import { useEffect, useRef } from "react";
import logo from "../../../static/nestria/astronaut.webp";
import { IosShare } from "./Svgs";

export function Welcome({show, onclickHandler}) {
    const sliderRef = useRef();

    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.initialize();
        }
    }, []);

    if (show === true) {
        return (
            <>
            <div className="welcome-container">
                
                <div className="top-overlay"></div>

                <swiper-container
                    initialize={false}
                    ref={sliderRef}
                    slides-per-view={"1"}
                    pagination={"true"}
                    direction={"horizontal"}
                    show-pagination={"false"}
                    style={{ width: "100vw", transition: "all 1s ease-out" }}
                >
                    <swiper-slide>
                        <div className="bottom-modal-container">
                            <div className="modal">
                                
                                <img className="logo" style={{ height: "80px", width: "80px" }} src={logo} alt="Nestria logo" />

                                <h1 className="text-2xl font-bold centered-white-text">Welcome to Nestria</h1>
                            
                                <h2 className="text-l centered-white-text">Practice for Interviews and get assessed for your dream job</h2>

                                <div className="mx-auto flex max-w-5xl sm:justify-between">
                                    <button
                                        onClick={() => {
                                            // onclickHandler(true);
                                            console.log("slide to next");
                                            sliderRef.current.swiper.slideNext();
                                        }}      
                                        className="begin-btn grow rounded-2xl border-b-4 border-green-600 bg-green-500 p-9 font-bold uppercase text-white sm:min-w-[150px] sm:max-w-[250px] sm:grow-0"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div className="bottom-modal-container">
                            <div className="modal">
                                
                                <img className="logo" style={{ height: "80px", width: "80px" }} src={logo} alt="Nestria logo" />

                                <h3 style={{ fontSize: "20px"}} className="centered-white-text">For best experience add Nestria to your Home Screen</h3>

                                <ol style={{ color: "white", fontSize: "18px", gap: "15px", display: "grid" }}>
                                
                                    <li>
                                        <span style={{ display: "flex", alignItems: "center" }}>1. Within browser or beside the address bar, tap Share <br/> <IosShare height="20px" width="20px"/></span>
                                    </li>

                                    <li>
                                        <span>2. Find and tap Add to Home Screen</span>
                                    </li>

                                </ol>

                                <div className="mx-auto flex max-w-5xl sm:justify-between">
                                    <button
                                        onClick={() => {
                                            onclickHandler(true);
                                        }}      
                                        className="begin-btn grow rounded-2xl border-b-4 border-green-600 bg-green-500 p-9 font-bold uppercase text-white sm:min-w-[150px] sm:max-w-[250px] sm:grow-0"
                                    >
                                        Let's begin
                                    </button>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </div>
            </>
        )
    } else {
        return (
            <></>
        );
    }
}