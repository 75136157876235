import React from 'react';

const PauseIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="7.58297"
        height="46"
        rx="3.79149"
        fill="#4960E1"
        stroke="#E1E1E1"
      />
      <rect
        x="28.0195"
        y="0.5"
        width="7.58297"
        height="46"
        rx="3.79149"
        fill="#4960E1"
        stroke="#E1E1E1"
      />
    </svg>
  );
};

export default PauseIcon;