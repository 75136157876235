/* eslint-disable default-case */
import React, { useContext, useEffect, useState } from "react";
import {
  getNumberOfQuestionsAnsweredInTheLessonToday,
  isLessonEligibleForStreak,
  setStreakActive,
  getSizeOfUserSubmissions,
} from "../helpers/store";
import NestriaQuestion from "./Questions/DefaultQuestion";
import { CurrentUserContext, FirebaseContext } from "../Context/Nestria";
import { QuestionType } from "../Home/helpers/Content";

/** For mixpanel purpose only */
const _DATA_POINTS = {
  question: "",
  unit_number: "",
  unit_name: "",
  course_name: "",
  xp: "",
};

const Lesson = ({
  isReviewing,
  isTakingProfilingQuestionsInFinal,
  setShowLesson,
  selectedUnit,
  selectedQuestions,
  setReload,
  userUnits,
}) => {
  const firebase = useContext(FirebaseContext);
  const currentUserObj = useContext(CurrentUserContext);
  const [questionReference, setQuestionReference] = useState(null);
  const [quitMessageShown, setQuitMessageShown] = useState(false);
  const [computeProgress, setComputeProgress] = useState(0);
  const [lessonComplete, setLessonComplete] = useState(false);
  const [questionIndex, setQuestionIndex] = useState<number | null>(null);
  const [isLastUnitOfTheCourse, setIsLastUnitOfTheCourse] = useState(false);
  const [hasReachedMaxSubmissions, setHasReachedMaxSubmissions] =
    useState(false);
  const [isFinalAssessmentError, setIsFinalAssessmentError] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState<null | QuestionType>(
    null
  );

  useEffect(() => {
    let currentActiveIndex = computeQuestionIndex(
      selectedQuestions,
      isReviewing,
      isTakingProfilingQuestionsInFinal
    );

    setQuestionIndex(currentActiveIndex);

    const activeQuestion = selectedQuestions[currentActiveIndex];

    let totalSubmissions = 0;

    if (activeQuestion) {
      const { tileRef } = activeQuestion;

      setQuestionReference(tileRef);

      setQuestionIndex(
        selectedQuestions.findIndex((t) => t.id === activeQuestion.id)
      );

      getSizeOfUserSubmissions(tileRef, currentUserObj)
        .then((totalSubmissions) => {
          setHasReachedMaxSubmissions(
            totalSubmissions >= activeQuestion.maxSubmissions
          );
        })
        .catch((error) => {
          console.error("Error fetching total submissions", error);
        });
    }

    if (activeQuestion) {
      _DATA_POINTS["question"] = activeQuestion?.description;
      _DATA_POINTS["xp"] = activeQuestion?.xp;
      _DATA_POINTS["unit_number"] = selectedUnit?.unitNumber;
      _DATA_POINTS["unit_name"] = selectedUnit?.unitName;
      _DATA_POINTS["course_name"] = activeQuestion?.courseName;
    }

    const maxSubmissions = activeQuestion?.maxSubmissions;
    setHasReachedMaxSubmissions(totalSubmissions >= maxSubmissions);
  }, []);

  const computeQuestionIndex = (
    selectedQuestions,
    isReviewing,
    isTakingProfilingQuestionsInFinal
  ): number => {
    try {
      let currentActiveIndex = 0;

      if (isReviewing) {
        return currentActiveIndex; // Start from beginning
      } else {
        if (isTakingProfilingQuestionsInFinal) {
          for (let i = 0; i < selectedQuestions.length; i++) {
            if (!selectedQuestions[i]?.tileObject?.isAnsweredInVideo) {
              currentActiveIndex = i;
              break;
            }
          }
          return currentActiveIndex;
        } else {
          for (let i = 0; i < selectedQuestions.length; i++) {
            if (selectedQuestions[i].isAnswered === false) {
              currentActiveIndex = i;
              break;
            }
          }
          return currentActiveIndex;
        }
      }
    } catch (e) {
      console.error(e);
      return 0;
    }
  };

  useEffect(() => {
    async function fetchData() {
      let _lessons = selectedQuestions;

      // const activeQuestion = userQuestions[activeQuestionIndex];
      // let selectedTile;
      // const tileData = selectedQuestions;
      // const activeTileData = tileData.find((t) => t.id === activeQuestion?.id);
      // let _currentLessonIndex = 1;
      // if (activeTileData) {
      //   _currentLessonIndex = 0;
      // } else {
      //   selectedTile = tileData[questionIndex];

      //   for (let lesson of _lessons) {
      //     if (lesson.some((l) => l.id === selectedTile.id)) {
      //       break;
      //     }
      //     _currentLessonIndex += 1;
      //   }
      // }

      const isEligibleForStreak = await isLessonEligibleForStreak(
        _lessons,
        currentUserObj
      ).catch((e) => {
        console.error(e);
      });

      if (isEligibleForStreak) {
        const numOfQuestionsAnswered =
          await getNumberOfQuestionsAnsweredInTheLessonToday(
            _lessons,
            currentUserObj
          );

        if (numOfQuestionsAnswered / Math.max(_lessons.length, 1) === 1) {
          setStreakActive(firebase, currentUserObj?.id, new Date());
        }
      }
    }

    fetchData();
  }, [computeProgress]);

  useEffect(() => {
    const courseUnits = userUnits.filter(
      (u) => selectedUnit.courseName === u.courseName
    );

    if (courseUnits?.length > 0) {
      if (selectedUnit.id === courseUnits[courseUnits.length - 1].id) {
        setIsLastUnitOfTheCourse(true);
      } else {
        setIsLastUnitOfTheCourse(false);
      }
    } else {
      setIsLastUnitOfTheCourse(false);
    }
  }, []);

  useEffect(() => {
    if (questionIndex !== null) {
      const _question = selectedQuestions[questionIndex];
      if (_question) {
        setCurrentQuestion(_question);
      }
    }
  }, [questionIndex, selectedQuestions]);

  if (currentQuestion && questionIndex !== null) {
    return (
      <NestriaQuestion
        isReviewing={isReviewing}
        selectedQuestions={selectedQuestions}
        question={currentQuestion}
        questionIndex={questionIndex}
        setQuestionIndex={setQuestionIndex}
        setQuitMessageShown={setQuitMessageShown}
        quitMessageShown={quitMessageShown}
        setShowLesson={setShowLesson}
        questionReference={questionReference}
        selectedUnit={selectedUnit}
        isLastUnitOfTheCourse={isLastUnitOfTheCourse}
        setQuestionReference={setQuestionReference}
        setReload={setReload}
        userUnits={userUnits}
        setComputeProgress={setComputeProgress}
        lessons={[selectedQuestions]}
        hasReachedMaxSubmissions={hasReachedMaxSubmissions}
        isFinalAssessmentError={isFinalAssessmentError}
        lessonComplete={lessonComplete}
        setLessonComplete={setLessonComplete}
        _DATA_POINTS={_DATA_POINTS}
        isTakingProfilingQuestionsInFinal={isTakingProfilingQuestionsInFinal}
      />
    );
  } else {
    return <></>;
  }
};

export default Lesson;
