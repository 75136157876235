export const computeXP = async (xpsAcquiredMap) => {
    let totalXPs = 0;
    await Promise.all(
        Object.values(xpsAcquiredMap).map((xp) => {
            totalXPs += Number(xp);
        })
    ).catch((e) => {
        console.error(e);
        return Promise.resolve(totalXPs);
    });
    return Promise.resolve(totalXPs);
};

export const computeTotalScore = async (recentScores) => {
    let _totalScore = 0;
    await Promise.all(
        recentScores.map((score) => {
            _totalScore += Number(score.score);
        })
    ).catch((e) => {
        console.error(e);
        return Promise.resolve(_totalScore);
    });
    return Promise.resolve(_totalScore);
};