import React, { useState } from "react";
import QuestionIcon from "../../svgs/QuestionIcon";
import NoAvatar from "../../noAvatar/NoAvatar";
import noAvatar from "../../../static/no-avatar.png";

const AdminUserQuestions = () => {
  // Define the chat data as an array of objects
  const chatData = [
    {
      avatar: noAvatar,
      sender: "Nestria",
      time: "Thu Oct 13 14:12:19",
      message: "What type of tech roles are you interested in?",
    },
    // Add more chat sections as needed
    // { ... }
  ];

  return (
    <>
      <div className="questions-desc-container">
        <div className="question-desc">
          <div className="question-header">
            <div className="questions-role">
              Question #3: What type of tech roles are you interested in?
            </div>
            <div className="question-tech-roles">
              <img src={noAvatar} alt="roger" height="20px" width="20px" />{" "}
              <span>Roger Murtaugh</span>
              <img src={noAvatar} alt="Chat gpt" height="20px" width="20px" />
              <span>ChatGPT</span>
              <img src={noAvatar} alt="Kai" height="20px" width="20px" />
              <span>Kai</span>
            </div>
          </div>

          <hr />

          {/* Map over the chatData array and render the chat sections */}
          {chatData.map((chat, index) => (
            <div className="nestria-chat-section" key={index}>
              <div class="circle">
                {chat.avatar ? (
                  <img
                    src={chat.avatar}
                    alt="avatar"
                    height="20px"
                    width="20px"
                  />
                ) : (
                  <NoAvatar text="" size={2.1} radius={50} />
                )}
              </div>
              <div>
                <div class="unit-header">{`${chat.sender} | ${chat.time}`}</div>
                <div className="personal-work">{chat.message}</div>
              </div>
            </div>
          ))}

          <hr />

          <div className="write-msg">
            <input
              type="text"
              class="write-msg_notes"
              placeholder="Write a message"
            ></input>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUserQuestions;
