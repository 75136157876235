import React, { useEffect, useState } from 'react';

const ConnectModal = ({
  closeModal,
  createConnection,
  db,
  selectedSearchData,
  connectTypes
}) => {
  const [validConnectTypes, setValidConnectTypes] = useState();
  const [disable, setDisable] = useState(false);
  const [disableUnknown, setDisableUnknown] = useState(false);
  const [note, setNote] = useState('');
  const [inputValues, setInputValues] = useState({
    connectTypes: []
  });
  
  useEffect(() => {
    const getConnectionTypes = async () => {
      const snapshot = await db.doc(`/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/${connectTypes}`).get();
      const snapshotData = snapshot?.data();
      let userConnectTypeList = [];
      let data = await Promise.all(snapshotData?.validConTypes?.map(async (doc) => {
        const connectTypesDoc = await doc?.get();
        const connectTypesData = connectTypesDoc?.data();
        userConnectTypeList.push(connectTypesData);
      }))
      setValidConnectTypes(userConnectTypeList);
    };
    // if (connectTypes === undefined)
    getConnectionTypes();
  }, []);
  
  const handleCheckBox = async (e) => {
    const { value, checked } = e.target;
    const { connectTypes } = inputValues;
     
    // Case 1 : The user checks the box
    if (checked) {
      setInputValues({
        connectTypes: [...connectTypes, value],
      });
    }
    // Case 2  : The user unchecks the box
    else {
      setInputValues({
        connectTypes: connectTypes.filter((e) => e !== value),
      });
    }

    setDisableUnknown(true);
    if (value === 'unknown') {
      setDisable(true);
    }
    // if (value === 'friend' || value === 'relative' || value === 'colleague' || value === 'cquaintance') {
    //   setDisableUnknown(true);
    //   setDisable(false);
    // }
  };

  return (
    <>
      <div className='modal_container'>
        <div className='connect_modal'>
          <p
            className='close_modal'
            onClick={closeModal}
          >
            X
          </p>
          <h3>Connect to {' '}
            {selectedSearchData?.key === 'user' ?
            selectedSearchData?.first + " " + selectedSearchData?.last
            :
            selectedSearchData?.key === 'company' ?
            selectedSearchData?.crm?.data?.properties?.name?.value ?? selectedSearchData?.profile?.name
            :
            (selectedSearchData?.key === 'tech' || selectedSearchData?.key === 'requirements') ?
            selectedSearchData?.name
            :
            null}
          </h3>
          <p>+ How do you know {' '}
            {selectedSearchData?.key === 'user' ?
            selectedSearchData?.first + " " + selectedSearchData?.last
            :
            selectedSearchData?.key === 'company' ?
            selectedSearchData?.crm?.data?.properties?.name?.value ?? selectedSearchData?.profile?.name
            :
            (selectedSearchData?.key === 'tech' || selectedSearchData?.key === 'requirements') ?
            selectedSearchData?.name
            :
            null}
          </p>
          <div className='checkbox_container'>
            {validConnectTypes &&
              validConnectTypes?.map(item => (
                <div key={item?.label}>
                  <input
                    type='checkbox'
                    name='relative'
                    value={item?.type?.toLocaleLowerCase()}
                    onChange={(e) => handleCheckBox(e)}
                    disabled={item?.type === 'unknown' ? !disable : disable}
                    />
                    <label className='checkbox-input'>{item?.label}</label>
                </div>
              ))
            }
            <div>
            <br/>
              <span className='note'>Additionally leave a note for
                {' '}
                {selectedSearchData?.first ?? 
                selectedSearchData?.name ?? 
                selectedSearchData?.crm?.data?.properties?.name?.value ?? 
                selectedSearchData?.profile?.name}
                {' '}
                on your connection request
              </span>
              {/* <input
                type='text'
                className='note_input'
                placeholder='Write your notes here'
                value={note}
                onChange={(e) => setNote(e.target.value)}
              /> */}
              <textarea
                type='text'
                className='note_input'
                placeholder='Write your notes here'
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
            <button
              className='connect_btn'
              onClick={() => {
                createConnection(selectedSearchData?.id, inputValues.connectTypes, note);
                closeModal();
              }}
            >
              Connect
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectModal;