import Spinner from "../../spinner/Spinner";
import { CloseSvg } from "../helpers/Svgs";

/**
 * Top section of the Question Answer screen, where question is displayed.
 */
export default function QuestionHeader({ question, setQuitMessageShown }) {
  const fontProperty = question?.length > 100 ? "header-font" : "text-2xl";
  return (
    <header className="flex items-start gap-4">
      <button
        className="text-gray-400"
        onClick={() => setQuitMessageShown(true)}
      >
        {question ? <CloseSvg /> : <></>}
        <span className="sr-only">Exit lesson</span>
      </button>
      <h1 className={`mb-3 ${fontProperty} font-bold sm:text-3xl`}>
        {question ? question : <Spinner width="2rem" height="2rem"/>}
      </h1>
    </header>
  );
}
