import React from 'react';

const ResumeIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.1568 23.5L2.5 44.6207L2.5 2.37926L37.1568 23.5Z"
        stroke="#4361E9"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ResumeIcon;