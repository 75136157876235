import React from "react";

const VideoOffBackground = ({ userObj }) => {
  if (!userObj) {
    return;
  }

  const eitherAvatarOrFirstAndLastNamePresent = userObj?.avatar || (userObj?.first && userObj?.last);

  if (!eitherAvatarOrFirstAndLastNamePresent) {
    return;
  }
  
  const avatar = userObj.avatar !== undefined ? userObj.avatar : "";  // If userobject have profilepicture, then we get avatar
  const initials = userObj?.first?.charAt(0) + userObj?.last?.charAt(0);

  return (
    <div
      style={{
        width: "250px",
        height: "250px",
        borderRadius: "50%",
        backgroundColor: "rgba(62,169,205,255)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: "120px",
        fontWeight: "lighter",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -120%)",
        zIndex: 1,
      }}
    >
      {avatar ? (
        <img
          src={avatar}
          alt={userObj?.first + ' ' + userObj?.last}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            borderRadius: "50%", 
          }}
        />
      ) : (
        <React.Fragment>
          {initials}
        </React.Fragment>
      )}
    </div>
  );
};

export default VideoOffBackground;
