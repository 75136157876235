import React from 'react';

const ChatGroupSelectors = ({
  isAdmin,
  isAdmin2,
  myDiscussion,
  setMyDiscussion,
  pubDiscussion,
  setPubDiscussion,
  allDiscussion,
  setAllDiscussion,
  chats,
  publicChats,
  allChats
}) => {
  return (
    <>
      <div className="disc_selectors">
        <>
          <div
            className={
              !(isAdmin || isAdmin2) && myDiscussion ? 
              'my_discussion_is_non_admin_active'
              :
              !(isAdmin || isAdmin2) && !myDiscussion ? 
              'my_discussion_is_non_admin'
              :
              myDiscussion ?
              'my_discussion_active mine' 
              : 
              'my_discussion mine' 
            }
            onClick={() => {
              setMyDiscussion(true);
              setAllDiscussion(false);
              setPubDiscussion(false);
            }}
          >
            <p className='group_header'>Mine</p>
            {/* <span className={myDiscussion ? 'my_disc_counts' : 'disc_counts'}>{chats?.length}</span> */}
          </div>
          <div
            className={
              !(isAdmin || isAdmin2) && pubDiscussion ? 
              'pub_discussion_is_non_admin_active'
              :
              !(isAdmin || isAdmin2) && !pubDiscussion ? 
              'pub_discussion_is_non_admin'
              :
              pubDiscussion ? 
              'pub_discussion_active public'
              :
              'pub_discussion public'
            }
            onClick={() => {
              setPubDiscussion(true);
              setMyDiscussion(false);
              setAllDiscussion(false);
            }}
          >
            <p className='group_header'>Public</p>
            {/* <span className={pubDiscussion ? 'pub_disc_counts' : 'disc_counts'}>{publicChats?.length}</span> */}
          </div>
        </>
        {(isAdmin || isAdmin2) &&
          <>
            <div
              className={allDiscussion ? 'all_discussion_active all' : 'all_discussion all'}
              onClick={() => {
                setAllDiscussion(true);
                setMyDiscussion(false);
                setPubDiscussion(false);
              }}
            >
              <p className='group_header'>All</p>
              {/* <span className={allDiscussion ? 'all_disc_counts' : 'disc_counts'}>
                {(isAdmin || isAdmin2) ? allChats?.length : 0}
              </span> */}
            </div>    
          </>
        }
      </div>
    </>
  );
};

export default ChatGroupSelectors;
