import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useState } from "react";

export default function DiscreteSliderSteps({
  timeSheetData,
  sprintFeatureMap,
  timeWorkedInSeconds,
  setSprintFeatureMap,
}) {
  const [sprintTimeAllocated, setSprintTimeAllocated] = useState(null);
  const [sprintTimeWorked, setSprintTimeWorked] = useState("");
  const [featureTimeWorked, setFeatureTimeWorked] = useState("");
  const [sprintPercentage, setSprintPercentage] = useState(0);
  const [featurePercentage, setFeaturePercentage] = useState(0);
  const [sprintErrorMessage, setSprintErrorMessage] = useState("");
  const [featureErrorMessage, setFeatureErrorMessage] = useState("");

  function getSprintsValue(e, value, item) {
    let _sprintFeatureMap = sprintFeatureMap;
    let sprintsTimeWorked = 0;
    let sprintAllocatedTime = (value * timeWorkedInSeconds) / 100;
    _sprintFeatureMap[item].sprintAllocation = sprintAllocatedTime;
    _sprintFeatureMap[item].sprintPercentage = value;
    {
      _sprintFeatureMap !== null &&
        Object.keys(_sprintFeatureMap).map((key, i) => {
          sprintsTimeWorked += _sprintFeatureMap[key]?.sprintAllocation;
        });
      if (timeWorkedInSeconds - sprintsTimeWorked < 0) {
        setSprintErrorMessage(
          "You cannot allocate more than 100% of time spent on a sprint spread amongst issues."
        );
      } else {
        setSprintErrorMessage("");
      }
    }
    let timeworkedObj = new Date(sprintAllocatedTime);
    let seconds = timeworkedObj?.getUTCSeconds();
    let minutes = timeworkedObj?.getUTCMinutes();
    let hours = timeworkedObj?.getUTCHours();
    _sprintFeatureMap[item].timeInHrMin =
      value +
      "%: " +
      hours?.toString()?.padStart(2, "0") +
      " hr " +
      minutes?.toString()?.padStart(2, "0") +
      " min ";
    setSprintFeatureMap(_sprintFeatureMap);
    return `${value}°C`;
  }

  function getfeaturesValue(e, value, item, j) {
    let _sprintFeatureMap = sprintFeatureMap;
    setFeaturePercentage(value);
    let featuresTimeWorked = 0;
    let featureAllocatedTime = 0;
    let sprintTimeAllocated = _sprintFeatureMap[item]?.sprintAllocation;
    featureAllocatedTime = (value * timeWorkedInSeconds) / 100;
    _sprintFeatureMap[item].feature[j].featureAllocation = featureAllocatedTime;
    _sprintFeatureMap[item].feature[j].featurePercentage = value;
    _sprintFeatureMap[item]?.feature?.map(
      (data, j) =>
        (featuresTimeWorked +=
          _sprintFeatureMap[item]?.feature?.[j]?.featureAllocation ?? 0)
    );
    if (sprintTimeAllocated - featuresTimeWorked < 0) {
      setFeatureErrorMessage(
        "You cannot allocate more than 100% of time spent on a sprint spread amongst issues."
      );
    } else {
      setFeatureErrorMessage("");
    }
    let timeworkedObj = new Date(featureAllocatedTime);
    let seconds = timeworkedObj?.getUTCSeconds();
    let minutes = timeworkedObj?.getUTCMinutes();
    let hours = timeworkedObj?.getUTCHours();
    _sprintFeatureMap[item].feature[j].featureTimeInHrMin =
      value +
      "%: " +
      hours?.toString()?.padStart(2, "0") +
      " hr " +
      minutes?.toString()?.padStart(2, "0") +
      " min ";
    setSprintFeatureMap(_sprintFeatureMap);
    return `${value}°C`;
  }
  return (
    <>
      {sprintFeatureMap !== null &&
        Object.keys(sprintFeatureMap).map((item, i) => (
          <div className="checkOut-section" key={i}>
            <Box>
              <div className="sprint_data">
                <span className="sprint_name">
                  {sprintFeatureMap[item]?.sprintData?.data()?.name}
                </span>
                <span className="client_name">
                  {sprintFeatureMap[item]?.clientData?.data()?.name}
                </span>
                <span className="time">
                  {sprintFeatureMap[item]?.timeInHrMin}
                </span>
              </div>
              {sprintErrorMessage}
              <Slider
                // sx={{ width: 600 }}
                sx={{ color: "#CAE6FB" }}
                defaultValue={sprintFeatureMap[item]?.sprintPercentage}
                step={1}
                marks
                min={0}
                max={100}
                //getAriaLabel={valueText}
                valueLabelDisplay="on"
                onChange={(e, value) => getSprintsValue(e, value, item)}
              />
              <div
                className={
                  sprintFeatureMap[item]?.feature?.length > 0
                    ? "sprint_feature"
                    : ""
                }
              >
                {sprintFeatureMap[item]?.feature?.map((data, j) => (
                  <div key={j}>
                    {featureErrorMessage}
                    {data?.name}
                    <Slider
                      sx={{ width: "94%", color: "#9E9E9E" }}
                      defaultValue={data?.featurePercentage}
                      aria-labelledby="non-linear-slider"
                      step={1}
                      marks
                      min={0}
                      max={100}
                      aria-label={data?.name}
                      onChange={(e, value) =>
                        getfeaturesValue(e, value, item, j)
                      }
                      valueLabelDisplay="on"
                    />
                  </div>
                ))}
              </div>
            </Box>
          </div>
        ))}
    </>
  );
}
