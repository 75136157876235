import React from 'react';

const MessageIcon = ({ width, height, isMessageTextArea }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 123 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="122.143"
        height="114"
        fill={isMessageTextArea ? '#656667' : '#F2F2F2'}
      />
      <path
        d="M85.6656 31.6847L51.4133 31.6191C48.1328 31.6191 45.4642 34.1098 45.4642 37.1717V41.9695L37.3681 41.9855C34.0875 41.9855 31.4189 44.4761 31.4189 47.538V67.3333C31.4189 70.3952 34.0875 72.8859 37.3671 72.8859H43.4577V82.2494C43.4577 82.6361 43.7125 82.9835 44.0988 83.123C44.2172 83.1661 44.3396 83.1857 44.4609 83.1857C44.7378 83.1857 45.0097 83.079 45.2033 82.8795L54.9387 72.884L71.6184 72.8204C74.899 72.8204 77.5676 70.3297 77.5676 67.2678V67.162L82.8445 72.5797C83.0382 72.7791 83.31 72.8859 83.5869 72.8859C83.7083 72.8859 83.8307 72.8662 83.9481 72.8232C84.3353 72.6846 84.5902 72.3363 84.5902 71.9495V62.5861H85.6636C88.9442 62.5861 91.6128 60.0954 91.6128 57.0335V37.2372C91.6128 34.1754 88.9452 31.6856 85.6656 31.6847ZM75.5611 67.2659C75.5611 69.295 73.7924 70.9458 71.6144 70.9458L54.4892 71.0113C54.2073 71.0123 53.9405 71.1237 53.7509 71.3175L45.4642 79.8252V71.9477C45.4642 71.4299 45.0147 71.0113 44.4609 71.0113H37.3671C35.1941 71.0113 33.4254 69.3605 33.4254 67.3315V47.5371C33.4254 45.508 35.1941 43.8572 37.3701 43.8572L46.4674 43.8394H46.4694L71.4198 43.7917H71.6184C73.7924 43.7917 75.5611 45.4425 75.5611 47.4715V64.7378V67.2659ZM89.6063 57.0316C89.6063 59.0607 87.8376 60.7115 85.6636 60.7115H83.5869C83.0322 60.7115 82.5837 61.13 82.5837 61.6478V69.5253L77.5676 64.3754V47.4715V47.4706C77.5676 44.4087 74.899 41.919 71.6174 41.919H71.5131L47.4706 41.9649V37.1708C47.4706 35.1417 49.2393 33.4909 51.4113 33.4909L85.6626 33.5564C85.6636 33.5564 85.6636 33.5564 85.6646 33.5564C87.8386 33.5564 89.6073 35.2072 89.6073 37.2363V57.0316H89.6063Z"
        fill={isMessageTextArea ? 'white' : 'black'}
      />
      <path
        d="M43.4617 54.0762C41.2485 54.0762 39.4487 55.756 39.4487 57.8216C39.4487 59.8872 41.2485 61.567 43.4617 61.567C45.6748 61.567 47.4746 59.8872 47.4746 57.8216C47.4746 55.756 45.6748 54.0762 43.4617 54.0762ZM43.4617 59.6943C42.3551 59.6943 41.4552 58.8544 41.4552 57.8216C41.4552 56.7888 42.3551 55.9489 43.4617 55.9489C44.5682 55.9489 45.4681 56.7888 45.4681 57.8216C45.4681 58.8544 44.5682 59.6943 43.4617 59.6943Z"
        fill={isMessageTextArea ? 'white' : 'black'}
      />
      <path
        d="M54.4934 54.0762C52.2803 54.0762 50.4805 55.756 50.4805 57.8216C50.4805 59.8872 52.2803 61.567 54.4934 61.567C56.7065 61.567 58.5063 59.8872 58.5063 57.8216C58.5063 55.756 56.7065 54.0762 54.4934 54.0762ZM54.4934 59.6943C53.3868 59.6943 52.4869 58.8544 52.4869 57.8216C52.4869 56.7888 53.3868 55.9489 54.4934 55.9489C55.6 55.9489 56.4999 56.7888 56.4999 57.8216C56.4999 58.8544 55.6 59.6943 54.4934 59.6943Z"
        fill={isMessageTextArea ? 'white' : 'black'}
      />
      <path
        d="M65.532 54.0762C63.3188 54.0762 61.519 55.756 61.519 57.8216C61.519 59.8872 63.3188 61.567 65.532 61.567C67.7451 61.567 69.5449 59.8872 69.5449 57.8216C69.5449 55.756 67.7451 54.0762 65.532 54.0762ZM65.532 59.6943C64.4254 59.6943 63.5255 58.8544 63.5255 57.8216C63.5255 56.7888 64.4254 55.9489 65.532 55.9489C66.6385 55.9489 67.5384 56.7888 67.5384 57.8216C67.5384 58.8544 66.6385 59.6943 65.532 59.6943Z"
        fill={isMessageTextArea ? 'white' : 'black'}
      />
    </svg>
  );
};

export default MessageIcon;
