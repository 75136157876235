import React, { useState } from "react";

const EmailInvites = ({
  db,
  currentUserObj,
  setShowInviteUser,
  showInviteDismissBtn
}) => {
  // const [inputValues, setInputValues] = useState({
  //   userMail: ''
  // });
  // const [showInviteUser, setShowInviteUser] = useState(false);
  const [emailError, setEmailError] = useState('')
  const [emailErrorMsg, setEmailErrMsg] = useState('')
  const [userMail, setUserMail] = useState('');

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setShowInviteUser(true);
  //   }, 259200000);
  //   return () => clearInterval(interval);
  // }, []);

  const validateEmail = async (e) => {
    e.preventDefault();
    var email = e.target.value;
    const regex = /\S+@\S+\.\S+/;
    if (!regex.test(userMail) && userMail !== '') {
      setEmailError('Invalid email address!');
    } else if (userMail == '') {
      setEmailError('Please enter email address!');
    } else {

      const templateDataMap = 
        {
          ctaLink: "https://spryte-chat.web.app",
          firstName: currentUserObj?.first,
          imgAltText: currentUserObj?.first +" "+currentUserObj?.last,
          //imgLink: <img src={currentUserObj?.avatar ?? logo} alt='avatar'/>,
          imgLink: currentUserObj?.avatar,
          lastName: currentUserObj?.last,
        }
      ;
      const docData = {
        to: [userMail],
        template: { data: templateDataMap, name: 'invitationMessageTemplate' }
      };
      const newEmail = await db.collection('/mail').add(docData);
      setEmailError('Email successfully sent! Try again with another contact & earn more points!');
      setUserMail('');    
    }
  };

  const handleEmail = e => {
    setUserMail(e.target.value);
  };

  return (
    <>
      <div className="invite_container">
        <p className="title">Invite a friend to join and earn points!</p>
        <div className='input_container'>
          <input
            type='text'
            name='email'
            value={userMail}
            onChange={handleEmail}
            placeholder='Enter emails, comma-separated'
            />
          <button onClick={(e) => validateEmail(e)}>Submit</button>
        </div>
        <div className="dismiss_container">
          <p className="error-message">{emailError}</p>
          {showInviteDismissBtn &&
            <p
              className="dismiss"
              onClick={() => {
                setShowInviteUser(false);
                setUserMail('');
                setEmailError('')
              }}
            >
              Dismiss this
            </p>
          }
        </div>
      </div>
    </>
  )
}
export default EmailInvites;