import React, { useContext, useEffect, useState } from "react";
import { getDevIdFromUserId } from "../helpers/store";

const VideoThumbnail = ({
  questionID,
  unitID,
  courseID,
  currentUser,
  firebase,
}) => {
  const [videoURL, setVideoURL] = useState(null);
  useEffect(() => {
    async function getDownloadURL() {
      const userId = currentUser?.id;
      const devId = await getDevIdFromUserId(userId) || userId;
      const storage = firebase.storage();
      
      const path = `devs/${devId}/assessments/${courseID}/${unitID}/${questionID}`;
      console.log("Video path", path);
      const ref = storage.ref(path);

      console.log("ref", ref);

      try {
        const metadata = await ref.getMetadata();

        const currentDate = new Date();
        const url = await ref.getDownloadURL();

        console.log("url", url);
        setVideoURL(url);

        const uniqueFileName = unitID + " " + questionID + " " + currentDate;
        const fileExtension = url.split(".").pop();
        const fileObj = {
          fileName: uniqueFileName,
          path: url,
          fileType: fileExtension,
        };
      } catch (error) {
        console.error("Error fetching download URL:", error.message);
        setVideoURL(null);
      }
    }
    getDownloadURL();
  }, [currentUser?.id, firebase, setVideoURL, courseID, unitID, questionID]);

  return (
    <>
      {videoURL && (
        <div>
          <video src={videoURL} width="150" height="160" controls>
            {/* <source src={videoURL} type="video/mp4" /> */}
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </>
  );
};

export default VideoThumbnail;
