import React, { useContext, useEffect, useRef, useState } from "react";
import EditIcon from "../../svgs/EditIcon";
import DelChannelIcon from "../../svgs/DelChannelIcon";
import RemoveIcon from "../../svgs/RemoveIcon";
import ListIcon from "../../svgs/ListIcon";
import AddIcon from "../../svgs/AddIcon";
import TimeSheetPrevIcon from "../../svgs/TimeSheetPrevIcon";

const CreateUnits = ({
  db,
  currentUser,
  setCreateUnitsOpen,
  selectedUnit,
  isCreateUnitsOpen,
  isViewUnitsOpen,
  setViewUnitsOpen,
  setMessage,
  setShowMessage,
  unitsStack,
  setSelectedUnit,
}) => {
  const [isOn, setIsOn] = useState(false);
  const [questionsStack, setQuestionsStack] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [addedQuestions, setAddedQuestions] = useState([]);
  const [isAssign, setIsAssign] = useState(true);
  const [unitName, setUnitName] = useState(selectedUnit?.name);
  const [assignedQuestions, setAssignedQuestions] = useState(
    selectedUnit?.questions
  );
  const [adminNotes, setAdminNotes] = useState(selectedUnit?.adminNotes || "");
  const [questionXP, setQuestionXP] = useState({});
  const [unitNameError, setUnitNameError] = useState("");
  const [adminNotesError, setAdminNotesError] = useState("");
  const [unitDesc, setUnitDesc] = useState(selectedUnit?.desc);
  const [unitDescError, setUnitDescError] = useState("");
  const [assignedQuestionError, setAssignedQuestionError] = useState("");
  const [assignedQuestionsOrder, setAssignedQuestionsOrder] = useState(
    []
  );

  const handleToggle = () => {
    setIsOn(!isOn);
  };

  useEffect(() => {
    // First try sorting the questions by index variable (introduced Aug 21st)
    selectedUnit?.questions?.sort((a,b) => { return a?.index - b?.index;});
    const unitQuestionsInOrder = selectedUnit?.questions?.map((question) => question.reference) || []
    setAssignedQuestionsOrder(unitQuestionsInOrder);
  }, [selectedUnit, ]);

  useEffect(() => {
    const getQuestionsStack = async () => {
      let quesList = []; // Array to store the documents

      let nestriaQuesStack = db?.collection(`topics`);
      let quesStack = await nestriaQuesStack?.get();
      quesStack?.forEach((doc) => {
        let documentData = doc?.data();
        quesList?.push({
          question: documentData?.question,
          reference: doc?.ref?.path,
          xp: 0,
        });

        const filteredQuestionsStack = quesList?.filter((question) => {
          return !assignedQuestions?.some(
            (assignedQuestion) =>
              assignedQuestion.reference.path === question.reference
          );
        });

        setQuestionsStack(filteredQuestionsStack);
      });
    };
    getQuestionsStack();
  }, []);

  const handleCreateAndSave = async () => {
    if (!validateInputs()) {
      return;
    }
    const questionData = {
      name: unitName,
    };

    const unitRef = await db
      ?.collection("catalog/DEFAULT/units")
      ?.add({ name: unitName, desc: unitDesc });

    let allQuestionsAdded = true;
    try {
      await Promise.all(
        addedQuestions?.map(async (addedQuestion) => {
          const questionRef = await unitRef?.collection("questions")?.add({
            ref: db.doc(addedQuestion?.reference), // Convert the string reference to a Firestore document reference
            xp: addedQuestion?.xp || 0,
          });
        })
      );
    } catch (error) {
      allQuestionsAdded = false;
      console.error("Error occurred while adding questions:", error);
    }
    if (unitRef && allQuestionsAdded) {
      setCreateUnitsOpen(false);
      setMessage("Unit created successfully!");
      setShowMessage(true);
    } else {
      setMessage("Failed to create unit. Please try again.");
      setShowMessage(true);
    }
  };

  const handleAddQuestion = (question) => {
    const { reference } = question;
    const questionXPKeys = Object.keys(questionXP);

    if (reference && questionXPKeys.includes(reference)) {
      const updatedXP = questionXP[reference];
      const updatedQuestion = { ...question, xp: updatedXP };
      const updatedAddedQuestions = [...addedQuestions, updatedQuestion];
      setAddedQuestions(updatedAddedQuestions);
    } else {
      const updatedAddedQuestions = [...addedQuestions, question];
      setAddedQuestions(updatedAddedQuestions);
    }
  };

  const handleDelete = () => {
    if (selectedUnit) {
      selectedUnit?.unitReference?.delete();
    }
    setViewUnitsOpen(false);
  };

  const validateInputs = () => {
    let isValid = true;

    if ((unitName ?? "").trim() === "") {
      setUnitNameError("Unit name is required");
      isValid = false;
    } else if (
      isCreateUnitsOpen &&
      unitsStack.some((unit) => unit.name === unitName)
    ) {
      setUnitNameError("Unit already exists");
      isValid = false;
    } else {
      setUnitNameError("");
    }

    if ((unitDesc ?? "").trim() === "") {
      setUnitDescError("Unit description is required");
      isValid = false;
    } else {
      setUnitDescError("");
    }

    if (assignedQuestions?.length + addedQuestions?.length === 0) {
      setAssignedQuestionError(
        "Please assign at least one question to the unit."
      );
      isValid = false;
    } else {
      setAssignedQuestionError("");
    }

    return isValid;
  };

  const handleUnitUpdate = async () => {
    if (!validateInputs()) {
      return;
    }
    const updatedQuestions = [...assignedQuestions, ...addedQuestions];

    const selectedUnitRefrence = selectedUnit?.unitReference;
    try {
      await selectedUnitRefrence?.update({
        name: unitName,
        desc: unitDesc,
      });
      const questionsCollectionRef =
        selectedUnitRefrence?.collection("questions");

      const existingQuestionsSnapshot = await questionsCollectionRef?.get();
      const deletePromises = existingQuestionsSnapshot?.docs?.map((doc) =>
        doc?.ref?.delete()
      );
      await Promise.all(deletePromises);

      const addQuestionPromises = updatedQuestions?.map(async (question) => {
        const modifiedQuestion = {
          ...question,
          ref:
            typeof question.reference === "string"
              ? db.doc(question.reference)
              : question.reference,
          xp: question.xp,
        };

        const questionRef = await questionsCollectionRef?.add(modifiedQuestion);
        return questionRef;
      });

      const addedQuestionRefs = await Promise.all(addQuestionPromises);
      if (addedQuestionRefs) {
        setViewUnitsOpen(false);
        setMessage("Unit updated successfully!");
        setShowMessage(true);
      } else {
        setMessage("Failed to update unit. Please try again.");
        setShowMessage(true);
        setViewUnitsOpen(false);
      }
    } catch (error) {
      console.error("Error updating unit:", error);
    }
  };

  const handleRemoveQuestion = (question) => {
    const updatedAddedQuestions = addedQuestions?.filter(
      (addedQuestion) => addedQuestion?.reference !== question?.reference
    );
    setAddedQuestions(updatedAddedQuestions);

    const updatedAssignedQuestions = assignedQuestions?.filter(
      (assignedQuestion) => assignedQuestion?.reference !== question?.reference
    );
    setAssignedQuestions(updatedAssignedQuestions);
    const updatedQuestionsStack = [...questionsStack, question];
    setQuestionsStack(updatedQuestionsStack);
  };

  // const handleQuestionXPChange = (value, question) => {
  //   const updatedXP = value ? parseInt(value) : 0;
  //   setQuestionXP((prevQuestionXP) => ({
  //     ...prevQuestionXP,
  //     [question?.reference]: updatedXP,
  //   }));
  // };

  const handleQuestionXPChange = (value, question) => {
    const updatedXP = value ? parseInt(value) : 0;
    setQuestionXP((prevQuestionXP) => {
      const updatedQuestionXP = { ...prevQuestionXP };
      updatedQuestionXP[question.reference] = updatedXP;

      console.log("updatedQuestionXP", updatedQuestionXP);
      return updatedQuestionXP;
    });
  };

  const handleDragStart = (e, questionRef) => {
    console.log("handleDragStart called with questionRef:", questionRef);
    e.dataTransfer.setData("text/plain", questionRef.path);
  };

  const handleDragOver = (e) => {
    console.log("handleDragOver called with questionRef:");
    e.preventDefault();
  };

  const handleDrop = async (e, targetQuestionRef) => {
    e.preventDefault();

    console.log("handleDrop called with targetQuestionRef:", targetQuestionRef);

    const sourceQuestionRefPath = e.dataTransfer.getData("text/plain");
    const sourceDocRef = db.doc(sourceQuestionRefPath);

    if (sourceDocRef.path !== targetQuestionRef.path) {
      const sourceIndex = assignedQuestionsOrder.findIndex(
        (ref) => ref.path === sourceDocRef.path
      );

      const targetIndex = assignedQuestionsOrder.findIndex(
        (ref) => ref.path === targetQuestionRef.path
      );
      if (sourceIndex !== -1 && targetIndex !== -1) {
        const updatedOrder = [...assignedQuestionsOrder];
        const [removedItem] = updatedOrder.splice(sourceIndex, 1);
        updatedOrder.splice(targetIndex, 0, removedItem);

        setAssignedQuestionsOrder(updatedOrder);

        try {
          const questionsCollectionRef =
            selectedUnit?.unitReference.collection("questions");
          const questionsSnapshot = await questionsCollectionRef.get();
          const updatedOrderPaths = updatedOrder.map((ref) => ref.path);
          const updatedQuestions = updatedOrderPaths.map((path) =>
            db.doc(path)
          );
          questionsSnapshot.docs.forEach(async (doc, index) => {
            await doc.ref.update({ ref: updatedQuestions[index] });
          });

          console.log("Database updated successfully");
        } catch (error) {
          console.error("Error updating database:", error);
        }
      }
    }
  };

  return (
    <>
      <div className="create-unit-container">
        <div className="back_btn">
          <TimeSheetPrevIcon width="12" height="12" icon="backArrow" />
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              setViewUnitsOpen(false);
              setCreateUnitsOpen(false);
              setSelectedUnit(null);
            }}
          >
            Back to all Units
          </p>
        </div>
        <div className="create-unit-header">
          <div className="header">
            {isViewUnitsOpen ? (
              <>
                <label>Auto Assign Unit</label>
                <EditIcon width="10" height="10" top="auto" bottom="auto" />
              </>
            ) : (
              <>
                <label>Create a unit</label>
                <EditIcon width="10" height="10" top="auto" bottom="auto" />
              </>
            )}
          </div>

          <div className="toggle_switch_container">
            <p>Draft Unit</p>
            <div
              className={`toggle_switch ${isOn ? "on" : "off"} ${"private"}`}
              // className={`toggle_switch ${isOn ? 'on' : 'off'} ${isEditChatGroup ? (isOn ? 'public' : 'private') : ((isPublic || isPublic === undefined) ? 'public' : 'private')}`}
              onClick={handleToggle}
            >
              <div className="toggle_handle" />
            </div>
            <p>Published Unit</p>
          </div>
        </div>

        <div className="unit-container">
          <label>Unit Name</label>

          <input
            type="text"
            className="unit_name"
            value={unitName}
            onChange={(e) => {
              setIsAssign(true);
              setUnitName(e.target.value);
            }}
          />
          {unitNameError && (
            <p className="unit-error-message">{unitNameError}</p>
          )}
          {/* <div className="ques-text">
            <span>What inspired you to pursue a career in tech field?</span>
          </div> */}
        </div>

        {/* <div className="unit-text">
          <p>
            This unit focuses on your background, personal work and education
            experience.{" "}
          </p>

       
        </div> */}

        <div className="unit-desc-container">
          <textarea
            className="unit-desc"
            value={unitDesc}
            onChange={(e) => setUnitDesc(e.target.value)}
            placeholder="Unit Description"
          ></textarea>
          {unitDescError && (
            <span className="unit-error-message">{unitDescError}</span>
          )}
        </div>

        <div
          className={`assigned-unit-ques-list ${
            assignedQuestionsOrder.length === 0 ? "empty" : ""
          }`}
        >
          {assignedQuestionsOrder.map((questionRef, index) => {
            const question = assignedQuestions.find(
              (q) => q.reference === questionRef
            );

            if (!question) {
              return null; // or handle this case in a way that makes sense for your app
            }
            return (
              <div
                className="unit-ques"
                key={`${questionRef}-${index}`}
                draggable
                onDragStart={(e) => handleDragStart(e, questionRef)}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, questionRef)}
              >
                <div className="list-ques">
                  <ListIcon width="6" height="6" top="auto" bottom="auto" />
                  <span className="ques-text">{question.question}</span>
                </div>
                <div className="xp-add-rem">
                  <input
                    type="text"
                    className="question-xp"
                    value={question.xp || 0} // Retrieve the XP value from questionXP object
                    onChange={(e) =>
                      handleQuestionXPChange(e.target.value, question)
                    }
                  />
                  <span
                    className="gray-icon"
                    onClick={() => handleRemoveQuestion(question)}
                  >
                    <RemoveIcon width="6" height="6" top="auto" bottom="auto" />
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        <div className="include">
          <label>Include</label>
        </div>
        <div className="search_box_container">
          <input
            type="text"
            placeholder="Search questions"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="unit-ques-list">
          {searchQuery &&
            questionsStack
              ?.filter((question) =>
                question?.question
                  ?.toLowerCase()
                  ?.includes(searchQuery?.toLowerCase())
              )
              .map((question, index) => {
                return (
                  <div className="unit-ques" key={index}>
                    <div className="list-ques">
                      <span>
                        <ListIcon
                          width="6"
                          height="6"
                          top="auto"
                          bottom="auto"
                        />
                      </span>
                      <span className="ques-text">{question?.question}</span>
                    </div>
                    <div className="xp-add-rem">
                      <input
                        type="text"
                        className="question-xp"
                        value={questionXP[question?.reference] || ""}
                        onChange={(e) =>
                          handleQuestionXPChange(e.target.value, question)
                        }
                      />
                      <span
                        className="gray-icon"
                        onClick={() =>
                          isAssign
                            ? handleAddQuestion(question)
                            : handleRemoveQuestion(question)
                        }
                      >
                        {isAssign ? (
                          addedQuestions?.some(
                            (addedQuestion) =>
                              addedQuestion?.reference === question?.reference
                          ) ? (
                            <RemoveIcon
                              width="6"
                              height="6"
                              top="auto"
                              bottom="auto"
                            />
                          ) : (
                            <AddIcon
                              width="6"
                              height="6"
                              top="auto"
                              bottom="auto"
                            />
                          )
                        ) : (
                          <RemoveIcon
                            width="6"
                            height="6"
                            top="auto"
                            bottom="auto"
                          />
                        )}
                      </span>
                    </div>
                  </div>
                );
              })}
          {assignedQuestionError && (
            <span className="unit-error-message">{assignedQuestionError}</span>
          )}
        </div>

        <div className="unit-admin-notes-container">
          <textarea
            className="admin_notes"
            value={adminNotes}
            onChange={(e) => setAdminNotes(e.target.value)}
            placeholder="Admin Notes"
          ></textarea>

          {adminNotesError && (
            <p className="unit-error-message">{adminNotesError}</p>
          )}
        </div>

        <div className="delete-ques-container">
          <p></p>
        </div>

        <div class="create_save_btn">
          {isViewUnitsOpen ? (
            <div className="button" onClick={handleUnitUpdate}>
              View and update
            </div>
          ) : (
            <div className="button" onClick={handleCreateAndSave}>
              Create and save
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default CreateUnits;
