import React from 'react';

const InvolvementIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 51 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="2.28036"
        y1="20.8934"
        x2="18.0788"
        y2="8.81224"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M18.3635 8.9094L30.1815 18.4547"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M29.2378 17.2873C28.594 17.8085 28.4945 18.753 29.0158 19.3969C29.537 20.0408 30.4815 20.1402 31.1254 19.619L29.2378 17.2873ZM50.764 3.15588C50.8507 2.33201 50.2531 1.59382 49.4292 1.5071L36.0034 0.093853C35.1795 0.00712924 34.4413 0.604708 34.3546 1.42858C34.2679 2.25246 34.8655 2.99065 35.6894 3.07737L47.6234 4.33359L46.3672 16.2677C46.2805 17.0915 46.8781 17.8297 47.7019 17.9164C48.5258 18.0032 49.264 17.4056 49.3507 16.5817L50.764 3.15588ZM31.1254 19.619L50.216 4.16472L48.3284 1.83299L29.2378 17.2873L31.1254 19.619Z"
        fill="black"
      />
    </svg>
  );
};

export default InvolvementIcon;