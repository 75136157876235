import React from 'react';

const EmailIcon = ({ width, height, isEmailTextArea }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.633789"
        width="122.143"
        height="114"
        fill={isEmailTextArea ? '#656667' : '#F2F2F2'}
      />
      <path
        d="M36.1705 18H16.8287C15.5508 18 14.5 19.0361 14.5 20.3287V31.6751C14.5 32.9541 15.5403 34.0039 16.8287 34.0039H36.1705C37.4596 34.0039 38.4992 32.9532 38.4992 31.6751V20.3287C38.4992 19.0349 37.4474 18 36.1705 18ZM35.7168 19.2701L26.4996 25.245L17.2826 19.2701H35.7168ZM15.7702 20.3287C15.7702 20.1681 15.8066 20.0122 15.8743 19.8711L21.64 23.6087L15.7702 31.4588V20.3287ZM36.1705 32.7337H16.8287C16.6971 32.7337 16.5714 32.7083 16.4549 32.6642L22.7083 24.3011L26.1542 26.5349C26.3644 26.6712 26.635 26.671 26.8452 26.5349L30.291 24.3011L36.5443 32.6642C36.4278 32.7084 36.3022 32.7337 36.1705 32.7337ZM37.229 31.4588L31.3591 23.6087L37.1249 19.8711C37.1926 20.0122 37.229 20.1681 37.229 20.3287V31.4588Z"
        fill={isEmailTextArea ? 'white' : 'black'}
      />
    </svg>
  );
};

export default EmailIcon;
