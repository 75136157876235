import React from 'react';
import newMsgIcon from '../../static/notification.svg';
import Channel from '../channel/Channel';
import NoAvatar from '../noAvatar/NoAvatar';
import ChatGroupAvatar from '../svgs/ChatGroupAvatar';

const MyChats = ({
  myDiscussion,
  myChats,
  selectChatGroup,
  updateReadReceipts,
  setNextUserRef,
  setIsScrolledUp,
  chatObj,
  truncateString,
  db,
  userId,
  setChatObj,
  setAddUserBtn,
  setShowGroupChatSection,
  setShowMessageSection,
  optionsModal,
  handleShowMore,
  showMore,
  windowSize
}) => {
  return (
    <>
      {myDiscussion &&
        <div className='my_discussion_container'>
          {myChats &&
            myChats?.map((myChat, index) => {
              // gets the refs for every group member
              const groupMemRef = myChat?.chat?.members?.map(mem => {
                return mem?.path;
              });

              // gets the next user data if the group members are less than 3, 
              // ideally, group members should be 2 except for multi-members group chats
              let nextUserInfo = {};
              if (myChat?.chat?.members?.length === 2) {
                myChat?.otherUserData?.map(data => {
                  if (groupMemRef.includes(data?.nextUserRef?.path)) {
                    nextUserInfo = data;
                  }
                })
              }
              // if the author of the unread msg is the same as the any of the users on your chat list (next user)
              // assign the author id to the unreadMsgAuthor and increment unreadMsgCount variable
              let unreadMsgAuthor;
              let unreadMsgCount = 0;
              myChat?.unreadMsgs?.map(data => {
                if (data?.author === nextUserInfo?.nextUserRef?.id) {
                  unreadMsgAuthor = data?.author;
                  unreadMsgCount++;
                }
              });

              // let unreadChannelMsgs = [];
              let channelChatArr = [];
              if (myChat?.chat?.isChannel) {
                // unreadChannelMsgs = myChat?.unreadMsgs;
                channelChatArr.push(myChat);
              }
              // console.log({channelChatArr});

              // const channels = myChat?.chat?.channels?.slice(0, showMore[index] ? undefined : 5);
              // filter out non-existent channels/refs
              const channels = myChat?.chat?.channels?.slice(0, showMore[index] ? undefined : 5)
              ?.filter(async (channelRef) => {
                try {
                  // attempt to access the channel in the database
                  const channelData = await channelRef.get();
                  if (channelData?.exists) {
                    return channelData;
                  }
                } catch (error) {
                  console.error(`Error accessing channel ${channelRef.id}: ${error}`);
                  return false;
                }
              });

              return(
                <React.Fragment key={myChat?.chat?.ref?.id}>
                  {!myChat?.chat?.isChannel && // show only group chats whose `isChannel` key is false (i.e chat groups that aren't channels)
                    <div
                      className='disc_user_container'
                      style={ chatObj?.ref?.id === myChat?.chat?.ref?.id ?
                        {borderLeft: "0.3rem solid black", backgroundColor: "#FFFFFF"}
                        :
                        {cursor :'pointer'}
                      }
                    >
                      <div className="disc_user_card">
                        <div
                          className='disc_user_data'
                          onClick={() => {
                            selectChatGroup(myChat?.chat);
                            updateReadReceipts(myChat?.chat);
                            setNextUserRef(nextUserInfo?.nextUserRef);
                            setIsScrolledUp(false);
                          }}
                        >
                          <>
                            {myChat?.chat?.members?.length === 2 ?
                              <>
                                {groupMemRef.includes(nextUserInfo?.nextUserRef?.path) &&
                                  <>
                                    {nextUserInfo?.avatar ?
                                      <div
                                        className={`disc_user_avatar 
                                          ${chatObj?.ref?.id === myChat?.chat?.ref?.id ? 
                                          'active_disc_user_avatar' : 'inactive_disc_user_avatar'}`
                                        }
                                      >
                                        <img src={nextUserInfo?.avatar} className="disc_avatar_image" alt={nextUserInfo?.first} />
                                      </div>
                                      :
                                      <div
                                        className={`disc_user_avatar 
                                          ${chatObj?.ref?.id === myChat?.chat?.ref?.id ? 
                                          'active_disc_user_avatar' : 'inactive_disc_user_avatar'}`
                                        }
                                      >
                                        <NoAvatar text={nextUserInfo?.first} size={4.3} radius={50} />
                                      </div>
                                    }
                                  </>
                                }
                              </>
                              :
                              myChat?.chat?.isRequirement ?
                                <div
                                    className={`disc_user_avatar 
                                    ${chatObj?.ref?.id === myChat?.chat?.ref?.id ? 
                                    'active_disc_user_avatar' : 'inactive_disc_user_avatar'}`
                                  }
                                >
                                  <NoAvatar text="r" size={4.3} radius={50} />
                                </div>
                              :
                              <div
                                className={`disc_user_avatar 
                                  ${chatObj?.ref?.id === myChat?.chat?.ref?.id ? 
                                  'active_disc_user_avatar' : 'inactive_disc_user_avatar'}`
                                }
                              >
                                <ChatGroupAvatar
                                  width="4.3rem"
                                  height="4.3rem"
                                />
                              </div>
                            }
                          </>
                          <div className="disc-info-section">
                            <div className='groupName_container'>
                              <p
                                style={{paddingLeft:"0px"}}
                              >
                                {myChat?.chat?.members?.length === 2 ?
                                  <>
                                    {groupMemRef.includes(nextUserInfo?.nextUserRef?.path) &&
                                      <span>{nextUserInfo?.first}</span>
                                    }
                                  </>
                                  :
                                  <>
                                    {windowSize <= 1040 ?
                                      truncateString(myChat?.chat?.groupName, 40)
                                      :
                                      truncateString(myChat?.chat?.groupName, 30)
                                    }
                                  </>
                                }
                                {(myChat?.chat?.msgReceived?.received === false && myChat?.chat?.author?.author?.id !== userId) &&
                                  <img
                                    src={newMsgIcon}
                                    alt='new msg'
                                    className='new_msg_icon'
                                  />
                                }
                                {/* <small>{myChat?.chat?.createdOn?.toDate().toString().substring(0, 10)}</small> */}
                              </p>
                            </div>
                            {' '}
                            <p className='last_msg'>
                              {truncateString(myChat?.chat?.lastMessage?.lastMessage, 100)}
                            </p>
                          </div>
                        </div>
                        <>
                          {/* channel jsx portion */}
                          {myChat?.chat?.channels && myChat?.chat?.channels.length > 0 && (
                            <div className={`channel_container ${myChat?.unreadMsgs?.length > 0 ? 'channel_unread_msg' : 'no_channel_unread_msg'}`}>
                              {channels?.map((channelRef) => {
                                return (
                                  <div key={channelRef?.id} className='channel'>
                                    <Channel
                                      channelRef={channelRef}
                                      chatObj={chatObj}
                                      setChatObj={setChatObj}
                                      truncateString={truncateString}
                                      setAddUserBtn={setAddUserBtn}
                                      setShowGroupChatSection={setShowGroupChatSection}
                                      setShowMessageSection={setShowMessageSection}
                                      optionsModal={optionsModal}
                                      db={db}
                                      userId={userId}
                                      windowSize={windowSize}
                                    />
                                  </div>
                                );
                              })}
                              {myChat.chat.channels.length > 5 && (
                                <div className='show_more' onClick={() => handleShowMore(index)}>
                                  {showMore[index] ? 'Show less' : 'Show more'}
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      </div>
                      <div className='chat_group_options'>
                        <>
                          {/* shows the unread msg count if the length of the unread message array is greater than 0 and 
                          if it's the right author */}
                          {/* {unreadMsgAuthor &&
                            <span className='unread_msg'>{unreadMsgCount}</span>
                          } */}
                          {myChat?.unreadMsgs?.length > 0 &&
                            <>
                              {myChat?.chat?.members?.length === 2 ?
                                <>
                                  {unreadMsgAuthor &&
                                    <span className='unread_msg'>{myChat?.unreadMsgs?.length}</span>
                                  }
                                </>
                                :
                                <span className='unread_msg'>{myChat?.unreadMsgs?.length}</span>
                              }
                            </>
                          }
                        </>
                      </div>
                    </div>
                  }
                </React.Fragment>
              )
            })
          }
        </div>
      }
    </>
  );
};

export default MyChats;
