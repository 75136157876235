import { useContext, useEffect, useRef, useState } from "react";
import Spinner from "../../spinner/Spinner";
import Controls from "./Controls";
import { likeASubmission, unLikeASubmission } from "../helpers/store";
import {FirebaseContext} from "../Context/Nestria";

export default function VideoAnswer({ question, currentUserId, url, currentIndex, index, userName, score, retakePreviousQuestion, setShowUserAnswers, setIsFeedbackPopupOpen, submissionDocRef, contentType }) {

    const firebase = useContext(FirebaseContext);
    const videoRef = useRef(null);
    const sourceRef = useRef(null);
    const [showLoader, setShowLoader] = useState(true);
    const [_likedUsers, setLikedUsers] = useState([]);
    const [_likeCount, setLikeCount] = useState();
    const [isLikedByThisUser, setIsLikedByThisUser] = useState();

    useEffect(() => {
        const unsubscribeLikeCount = submissionDocRef?.onSnapshot(async (snapshot) => {
            console.log("snapshot change...");
            const likedUsers = await snapshot.get("likedUsers");
            console.log(likedUsers);
            setLikedUsers(likedUsers ?? []);
        });

        return () => {
            unsubscribeLikeCount();
            console.log(`closing like count listener`);
        }
    }, [submissionDocRef]);

    useEffect(() => {
        setLikeCount(_likedUsers?.length);

        if (_likedUsers?.indexOf(currentUserId) !== -1) {
            setIsLikedByThisUser(true);
        } else {
            setIsLikedByThisUser(false);
        }
    }, [_likedUsers]);

    function showRecording(url) {
        let video = videoRef.current;
        sourceRef.current.src = url;
        video.load();
        video.loop = true;
    }

    useEffect(() => {
        // window.addEventListener("focus", () => {
        //     if (index === currentIndex) {
        //         videoRef?.current?.play();
        //     }
        // });

        // window.addEventListener("blur", () => {
        //     if (index === currentIndex) {
        //         videoRef?.current?.pause();
        //     }
        // });

        showRecording(url);

        return () => {
            window.removeEventListener("focus", () => {
                videoRef?.current?.play();
            });

            window.removeEventListener("blur", () => {
                videoRef?.current?.pause();
            });
        }
    }, []);

    useEffect(() => {
        if (index === currentIndex) {
            playVideoFromBeginning();
        } else {
            videoRef.current.pause();
        }
    }, [currentIndex, index]);

    function playVideoFromBeginning() {
        if (videoRef.current.currentTime !== 0) {
            videoRef.current.currentTime = 0;
        }
        videoRef.current.play();
    }

    return (
        <>

            <div style={{ display: "flex", position: "relative", zIndex: 0 }} className="video-answer"
            onClick={() => {
                if (videoRef.current.paused === true || videoRef.current.ended === true) {
                    videoRef.current.play();
                    if (index !== 0 && index === currentIndex) {

                    }
                } else {
                    videoRef.current.pause();
                }
            }}>
                {showLoader &&
                    <div style={{ height: "100vh", width: "100vw", justifyContent: "center", display: "flex", position: "absolute" }} className="video-answer">
                        <Spinner width="40px" height="40px" />
                    </div>
                }

                <link rel="preload" href={url} />

                <video
                    ref={videoRef}
                    className="video-answer-div video-answer"
                    playsInline={true}
                    autoPlay={index === 0}
                    preload={"auto"}
                    muted={false}
                    onCanPlay={() => setShowLoader(false)}
                >
                    <source ref={sourceRef} type={contentType ? contentType : "video/mp4"} />
                </video>

                {/* Gradient Overlay */}
                <div className="overlay video-answer" style={{ position: "absolute", height: "100vh", width: "100vw", background: "linear-gradient(rgb(255 255 255 / 0%) 0%, #00000082 100%)" }}>
                    <div style={{ position: "absolute", width: "100vw", bottom: "8vh", color: "white", textAlign: "left", padding: "20px", fontWeight: "bold" }} className="video-answer">
                        <h4>{question}</h4>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            {userName &&
                                <p style={{ marginTop: "10px", fontWeight: "100", fontSize: "16px" }}>By {userName}</p>
                            }

                            {score &&
                                <p style={{ marginTop: "10px", fontWeight: "100", fontSize: "16px" }}>Scored: {score}</p>
                            }
                        </div>

                        {/* Controls */}
                        <Controls
                            likeHandler={() => { console.log("like clicked"); if (isLikedByThisUser) { unLikeASubmission(firebase, submissionDocRef, currentUserId); } else { likeASubmission(firebase, submissionDocRef, currentUserId); } }}
                            flagHandler={() => setIsFeedbackPopupOpen(true)}
                            retakeHandler={() => { retakePreviousQuestion(); setShowUserAnswers(false); }}
                            likeCount={_likeCount}
                            isLiked={isLikedByThisUser}
                            hideLike={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}