import React, { useState, useEffect } from 'react';

function TopMessageBar({ message }) {
  const [visible, setVisible] = useState(true);


  const handleDivClose = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div style={{
      position: 'fixed',
      top: '-1px',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: '#1976d2',
      color: 'white',
      padding: '18px',
      borderRadius: '4px',
      fontSize: '14px',
      zIndex: 1400,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: '600px',
    }}>
      <div>{message}</div>
      <button onClick={handleDivClose} style={{
        background: 'none',
        border: 'none',
        color: 'white',
        marginLeft: '16px',
        cursor: 'pointer'
      }}>X</button>
    </div>
  );
}

export default TopMessageBar;
