import React, { useEffect, useState } from "react";

import TimePickerModal from "./TimePickerModal";

const ClockOutResolveModal = ({
  db,
  misssingClockOutData,
  setClockOutResolve,
  firebase,
  devsReportingReference,
  setIsAccountNotGood,
  setTimeSheetData,
  hideCancel,
  setHideCancel,
  showClockDates,
  setShowClockDates,
  showTimePicker,
  setShowTimePicker,
}) => {
  // const [showClockDates, setShowClockDates] = useState(true);

  const [date, setDate] = useState(null);
  const [docRef, setDocRef] = useState(null);
  const [clockInTime, setClockInTime] = useState(null);
  const [resolveTimesheet, setResolveTimesheet] = useState(null);

  const resolveCheckOutTime = async (e, doc) => {
    let _date = doc.data()?.checkIn?.toDate();

    let _timesheet = doc?.data();
    _timesheet &&
      _timesheet?.presenceData?.map((data, i) => {
        if (i === _timesheet?.presenceData?.length - 1) {
          _date?.setSeconds(data?.dateTime?.toDate()?.getSeconds());
          _date?.setMinutes(data?.dateTime?.toDate()?.getMinutes());
          _date?.setHours(data?.dateTime?.toDate()?.getHours());
        }
      });
    setDate(_date);
    setResolveTimesheet(doc);
    setShowTimePicker(true);
    setDocRef(doc?.ref);
    setShowClockDates(false);
  };
  return (
    <>
      {showClockDates && (
        <div className="clock_out_resolve_container">
          <div className="clock_out_modal">
            <p>You forgot to clock out on the following dates</p>
            {misssingClockOutData &&
              misssingClockOutData?.map((doc, i) => {
                let d = doc.data()?.checkIn?.toDate();
                let options = {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                };
                let formattedDate = d?.toLocaleDateString("en-US", options);
                return (
                  <div
                    className="year_month_day"
                    key={i}
                    onClick={(e) => resolveCheckOutTime(e, doc)}
                  >
                    {formattedDate}
                  </div>
                );
              })}
            <div className="cancel_resolve">
              {hideCancel ? null : (
                <button
                  onClick={() => {
                    setClockOutResolve(false);
                  }}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {showTimePicker && (
        <TimePickerModal
          db={db}
          date={date}
          docRef={docRef}
          setClockOutResolve={setClockOutResolve}
          clockInTime={clockInTime}
          resolveTimesheet={resolveTimesheet}
          setResolveTimesheet={setResolveTimesheet}
          firebase={firebase}
          devsReportingReference={devsReportingReference}
          setIsAccountNotGood={setIsAccountNotGood}
          misssingClockOutData={misssingClockOutData}
          setTimeSheetData={setTimeSheetData}
          hideCancel={hideCancel}
          setHideCancel={setHideCancel}
          setShowClockDates={setShowClockDates}
          setShowTimePicker={setShowTimePicker}
        />
      )}
    </>
  );
};

export default ClockOutResolveModal;
