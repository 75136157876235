import favicon from '../../static/favicon.ico';

export const updateFavicon = (unreadMsgCount) => {
  // create a canvas element dynamically on the page
  const canvas = document.createElement('canvas');
  canvas.width = 32;
  canvas.height = 32;
  const context = canvas.getContext('2d');

  // draw the favicon image onto the canvas
  const faviconImage = new Image();
  faviconImage.src = favicon;

  faviconImage.onload = () => {
    context.drawImage(faviconImage, 0, 0, 32, 32);

    // draw a red circle behind the text
    if (unreadMsgCount > 0) {
      context.beginPath();
      context.fillStyle = 'red';
      context.arc(22, 10, 10, 0, 2 * Math.PI);
      context.fill();
    };

    // draw the unread message count text on top of the circle
    context.fillStyle = 'white';
    context.font = '700 20px "Roboto Condensed", sans-serif';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    // context.fillText(unreadMsgCount, 16, 16);
    if (unreadMsgCount > 0) {
      context.fillText(unreadMsgCount, 22, 10);
    }

    // finally, set the resulting image as the favicon:
    const faviconURL = canvas.toDataURL('image/png');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = faviconURL;
  };
};
