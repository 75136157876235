import AudioToText from "../SpeechToText/RealTimeTranslation";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Mixpanel } from "../helpers/Mixpanel";
import Spinner from "../../spinner/Spinner";
import { VideoControls } from "./Controls/Controls";
import { getState } from "../helpers/localStorage";
import VideoOffBackground from "./VideoOffBackground";
import TopMessageBar from "../helpers/TopMessageBar";
import { CurrentUserContext, FirebaseContext } from "../Context/Nestria";
import { getDevIdFromUserId } from "../helpers/store";
import { ReportSpeechToText } from "../IssueReporter/ReportSpeechToText";

const {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useContext,
} = require("react");
const { MediaStreamRecorder } = require("recordrtc");
const RecordRTC = require("recordrtc");

let recorder;
let _stream;

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function VideoRecorder({
  setQuitMessageShown,
  question,
  questionText,
  onSubmit,
  maxVideoAnswerLengthInSeconds,
  isTakingProfilingQuestionsInFinal,
  hasReachedMaxSubmissions,
}) {
  const currentUser = useContext(CurrentUserContext);
  const firebase = useContext(FirebaseContext);
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const transcribedTextRef = useRef();
  const [startRecording, setStartRecording] = useState(undefined);

  const [socketConnection, setSocketConnection] = useState();
  const [cameraStream, setCameraStream] = useState();
  const [recordingConfirmed, setRecordingConfirmed] = useState(false);

  const [isUploadingAnswer, setIsUploadingAnswer] = useState(false);

  const [recordedAnswerBlob, setRecordedAnswerBlob] = useState(undefined);

  const [isDynamicQuestion, setIsDynamicQuestion] = useState(false);

  const [blob, setBlob] = useState();
  const [recordingComplete, setRecordingComplete] = useState(false);
  const [isPlaybackVideoMuted, setIsPlaybackVideoMuted] = useState(true);

  const [isPlayerPaused, setIsPlayerPaused] = useState(false);

  const courseID = useRef(null);
  const unitID = useRef(null);
  const questionID = useRef(null);

  const [reset, setReset] = useState(undefined);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarDiv, setOpenSnackBarDiv] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("error"); // error | warning | success | information

  const [isAudioToTextReady, setIsAudioToTextReady] = useState(false);
  const [isCameraStreamReady, setIsCameraStreamReady] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(true);

  const [isFinalCourse, setIsFinalCourse] = useState(true); // Set as true by default so we always hide the video off button

  const [textareaValue, setTextareaValue] = useState(
    transcribedTextRef.current || ""
  );
  const [changingText, setChangingText] = useState(false); // Even though transcribedTextRef.current is 0, this state helps to display the teatarea.

  const transcriptionTextAreaRef = useRef();

  useEffect(() => {
    if (isVideoOn === false && question.courseName === "Orientation") {
      setOpenSnackBarDiv(true);
      return;
    } else {
      setOpenSnackBarDiv(false);
    }
  }, [isVideoOn]);

  useEffect(() => {
    // To auto scroll the transcription text area
    transcriptionTextAreaRef.current.scrollTop =
      transcriptionTextAreaRef.current.scrollHeight;
  }, [transcribedTextRef.current]);

  useEffect(() => {
    if (
      isFinalCourse === false &&
      isTakingProfilingQuestionsInFinal === false
    ) {
      const lastSavedPref = getState("isVideoOn");

      if (typeof lastSavedPref === "boolean") {
        setIsVideoOn(lastSavedPref);
      }
    } else {
      // For assessments, always keep the video on
      setIsVideoOn(true);
    }
  }, [isFinalCourse]);

  function showSnackbar(severity, msg) {
    setSnackBarSeverity(severity || "error");
    setSnackBarMsg(msg || "Please try again.");
    setOpenSnackBar(true);
  }

  function showCameraFeed(stream) {
    try {
      if (!isVideoOn) {
        return;
      }

      let video = videoRef.current;
      if (video) {
        if ("srcObject" in video) {
          video.srcObject = stream;
          setIsPlaybackVideoMuted(true);
        }
        // TODO: Handle else case
      } else {
        Mixpanel.track("error_recorder_video_doesnot_support_srcObject", {
          screen: "video_question_answer",
          component: "Recorder",
          msg: `video: `,
          video,
        });
      }

      // video.play();
    } catch (e) {
      console.error(e);
      Mixpanel.track("error_recorder", {
        screen: "video_question_answer",
        component: "Recorder",
        msg: String(e),
      });
    }
  }

  function playbackRecording(blob) {
    const blobType = blob?.type?.split(";")[0];

    if (!blobType) {
      // TODO: Throw Error - Show Snackbar
      console.error(`blobType not found for given blob: `, blob);
      return;
    }

    const isAudioFileType = blobType.indexOf("audio") !== -1;
    const isVideoFileType = blobType.indexOf("video") !== -1;

    // Replay Video Recording
    if (isVideoFileType && !isAudioFileType) {
      let video = videoRef.current;
      video.srcObject = null;
      let url = URL.createObjectURL(blob);
      video.src = url;
      video.load();
      video.loop = true;
      setIsPlaybackVideoMuted(false);
      video.play();
    }

    // Replay Audio Recording
    if (isAudioFileType && !isVideoFileType) {
      let audio = audioRef.current;
      audio.srcObject = null;
      let url = URL.createObjectURL(blob);
      audio.src = url;
      audio.load();
      audio.loop = true;
      setIsPlaybackVideoMuted(false);
      audio.play();
    }
  }

  async function uploadAnswer(file, metadata) {
    const userId = currentUser?.id;
    const devId = currentUser?.devId || await getDevIdFromUserId(userId) || userId;

    const storage = firebase.storage();

    const path = `devs/${devId}/assessments/${courseID.current}/${unitID.current}/${questionID.current}`;

    const fileType = file?.type?.split(";")[0] || "";

    if (fileType === "") {
      setSnackBarSeverity("error");
      setSnackBarMsg(`Can't upload the answer. Given file type is null.`);
      setOpenSnackBar(true);
      return;
    }

    const isAudioFileType = fileType.indexOf("audio") !== -1;
    const isVideoFileType = fileType.indexOf("video") !== -1;

    if (!(isAudioFileType || isVideoFileType)) {
      setSnackBarSeverity("error");
      setSnackBarMsg(`Unsupported file type. Must be either video or audio.`);
      setOpenSnackBar(true);
      return;
    }

    const questionRef = question?.tileRef;

    await questionRef?.update({
      isAnsweredInVideo: isVideoFileType,
    });

    // Also update this field locally, so the app doesn't
    // have to wait for the change from the db
    question.tileObject.isAnsweredInVideo = isVideoFileType;

    console.log(`Uploading answer to : ${path} with MIME type ${fileType}`);

    const ref = storage.ref(path);

    setIsUploadingAnswer(true);

    await ref.put(file, {
      contentType: fileType,
      customMetadata: { userID: userId, ...metadata },
    });

    setIsUploadingAnswer(false);
  }

  useEffect(() => {
    return () => {
      console.log(`cleaning up`);
      // Safely turn off access to camera and microphone
      turnOffCameraAndMic(_stream);
      setIsCameraStreamReady(false);
      console.log(`clean up complete!`);
    };
  }, []);

  useEffect(() => {
    courseID.current = question?.courseID;
    unitID.current = question?.originalUnitID;
    questionID.current = question?.originalQuestionID;
    setIsDynamicQuestion(question?.tileObject?.questionData?.isDynamic);
  }, [questionText]);

  useEffect(() => {
    if (question?.courseName && typeof question?.courseName === "string") {
      if (question.courseName.toLowerCase().includes("final")) {
        setIsFinalCourse(true);
      } else {
        if (
          question.courseName.toLowerCase().includes("orientation") ||
          question.courseName.toLowerCase().includes("practice")
        ) {
          setIsFinalCourse(false);
        } else {
          setIsFinalCourse(true);
        }
      }
    }
  }, [questionText]);

  useEffect(() => {
    // Reset states if question is changed
    resetStates();
  }, [questionText, reset]);

  useEffect(() => {
    // Reser timer when question change
    setReset(new Date()); // Set any random value to reset
  }, [questionText]);

  function resetStates() {
    transcribedTextRef.current = "";
    setStartRecording(undefined);
    setSocketConnection(undefined);
    setRecordedAnswerBlob(undefined);
    showCameraFeed(cameraStream);
    setRecordingConfirmed(false);
    setBlob(undefined);
    setRecordingComplete(false);
    stopAudioPlayback();
    setTextareaValue("");
    setChangingText(false);
  }

  const stopVideoTrack = (stream) => {
    if (stream) {
      const videoTrack = stream.getVideoTracks()[0];
      if (videoTrack) {
        console.log(`toggling video tracks`);
        if (videoTrack.enabled) {
          videoTrack.stop();
        }
      }
    }
  };

  const turnOffCameraAndMic = (stream) => {
    // Safely turn off access to camera and microphone
    if (stream) {
      const tracks = stream?.getTracks();
      if (tracks) {
        tracks.forEach((track) => track.stop());
      }
    }
  };

  useEffect(() => {
    try {
      if (
        _stream &&
        isVideoOn !== true &&
        _stream?.getVideoTracks[0]?.enabled
      ) {
        stopVideoTrack(_stream);
      } else {
        navigator.mediaDevices
          .getUserMedia({
            video: isVideoOn ? { facingMode: "user" } : false,
            audio: {
              deviceId: { ideal: "default" },
              sampleRate: { ideal: 16000 },
              sampleSize: { ideal: 16 },
              channelCount: { ideal: 1 },
            },
          })
          .then((stream) => {
            if (stream) {
              console.log(`Using Audio: `, stream.getAudioTracks()[0].label);

              // Close previous stream
              turnOffCameraAndMic(_stream);

              _stream = stream;
              setCameraStream(stream);
              setIsCameraStreamReady(true);

              if (isVideoOn) {
                showCameraFeed(stream);
              }
            } else {
              setSnackBarSeverity("error");
              setSnackBarMsg("Camera Stream Empty");
              Mixpanel.track("error_camera_stream_empty", {
                screen: "video_question_answer",
                component: "Recorder",
                msg: "Camera Stream Empty",
              });
              setOpenSnackBar(true);
              setIsCameraStreamReady(false);
            }
          })
          .catch((e) => {
            console.error(e);
            setSnackBarSeverity("error");
            setSnackBarMsg(String(e));
            setOpenSnackBar(true);
            Mixpanel.track("error_camera", {
              screen: "video_question_answer",
              component: "Recorder",
              msg: String(e),
            });
          });

        navigator.mediaDevices.addEventListener("devicechange", (event) => {
          console.log(`Device changed: `);
          console.log(event);
        });
      }
    } catch (e) {
      console.error(String(e));
      Mixpanel.track("error_camera", {
        screen: "video_question_answer",
        component: "Recorder",
        msg: String(e),
      });
    }

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", (event) => {
        console.log(`Device changed: `);
        console.log(event);
      });
    };
  }, [isVideoOn]);

  useEffect(() => {
    if (startRecording === true) {
      startRecordingStream(cameraStream);
    } else if (startRecording === false) {
      stopRecordingStream(recorder);
    }
  }, [startRecording]);

  // Using server streaming
  useEffect(() => {
    if (socketConnection) {
      console.log(`Sending video blob to server: `);
      socketConnection?.emit("send_video_data", { video: blob });
    }
  }, [blob, socketConnection]);

  useEffect(() => {
    if (recordingConfirmed === true) {
      socketConnection?.emit("save_recording");
    }
  }, [recordingConfirmed, socketConnection]);

  useEffect(() => {
    if (recordedAnswerBlob !== undefined && recordingConfirmed === true) {
      submitAnswerHandler();
    }
  }, [recordedAnswerBlob, recordingConfirmed]);

  function submitAnswerHandler() {
    onSubmit(transcribedTextRef.current);

    const questionDocPath = question?.tileRef?.path;

    uploadAnswer(recordedAnswerBlob, {
      questionDocPath: questionDocPath || "",
    })
    .then(() => {
      console.log(`Video Uploaded`);

      Mixpanel.track("video_uploaded", {
        screen: "video_question_answer",
        component: "Recorder",
        msg: `Video Uploaded to: ${questionDocPath}`,
        path: questionDocPath,
      });
    })
    .catch((e) => {
      setSnackBarSeverity("error");

      setSnackBarMsg(e?.message);

      Mixpanel.track("error_video_upload", {
        screen: "video_question_answer",
        component: "Recorder",
        msg: `Video Upload Error: ${e?.message}`,
        error: e,
      });

      setOpenSnackBar(true);
    });

    // Stop video playback
    stopVideoPlayback();
    stopAudioPlayback();
  }

  function startRecordingStream(camera) {
    try {
      if (!camera) {
        setSnackBarSeverity("error");
        setSnackBarMsg(`Camera Stream is null or undefined`);
        setOpenSnackBar(true);
        return;
      }

      console.log(`camera: `, camera);

      recorder = RecordRTC(camera, {
        recorderType: MediaStreamRecorder,
        mimeType: isVideoOn ? "video/webm" : "audio/webm",
        type: isVideoOn ? "video" : "audio",
        timeSlice: 1000,
        ondataavailable: function (blob) {
          setBlob(blob);
        },
      });

      setRecordingComplete(false);

      setRecordingConfirmed(false);

      recorder.startRecording();
    } catch (e) {
      console.error(e);
      setSnackBarSeverity("error");
      setSnackBarMsg(
        `Unable to start recording. Please go back and try again.`
      );
      setOpenSnackBar(true);

      Mixpanel.track("error_starting_recording", {
        screen: isVideoOn ? "video_question_answer" : "audio_question_answer",
        isVideoOn,
        component: "Recorder",
        error: e,
      });
    }
  }

  function stopRecordingStream(recorder) {
    recorder?.stopRecording(function () {
      let blob = this.getBlob();

      setRecordedAnswerBlob(blob);

      playbackRecording(blob);

      // Show the check button
      setRecordingComplete(true);
    });

    setStartRecording(false);
  }

  function stopVideoPlayback() {
    try {
      let video = videoRef.current;
      if (video) {
        video?.pause();
        video.currentTime = 0;
      }
    } catch (e) {
      console.error(e);
    }
  }

  function stopAudioPlayback() {
    try {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = null;
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (transcribedTextRef.current) {
      setTextareaValue(transcribedTextRef.current); // Update textareaValue with value from transcribedTextRef
    }
  }, [transcribedTextRef.current]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "black",
        }}
      >
        <Snackbar
          open={openSnackBar}
          autoHideDuration={5000}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert
            onClose={() => setOpenSnackBar(false)}
            severity={snackBarSeverity}
            sx={{ width: "100%" }}
          >
            {snackBarMsg}
          </Alert>
        </Snackbar>
        {openSnackBarDiv && (
          <TopMessageBar
            message="For your profile to be considered by the employers, please answer this question in video."
            onClose={() => setOpenSnackBarDiv(false)}
          />
        )}
        <div onClick={() => setQuitMessageShown(true)}>
          <div className="close-btn">X</div>
        </div>

        {isVideoOn || <VideoOffBackground userObj={currentUser} />}

        <div className="video-recorder-body">
          <div className="question-div">
            {questionText ? (
              <span>{questionText}</span>
            ) : (
              <Spinner height="2rem" width="2rem" />
            )}
          </div>

          <div className="msg-audio-container">
            {cameraStream && isCameraStreamReady && (
              <AudioToText
                setRecognizedText={transcribedTextRef}
                disabled={false}
                startRecording={startRecording}
                resetAudio={reset}
                headless={true}
                passedStream={_stream}
                setIsAudioToTextReady={setIsAudioToTextReady}
                showSnackbar={showSnackbar}
              />
            )}
          </div>

          <div>
            <textarea
              className="text-transcription-div delay-2000"
              style={{
                visibility:
                  transcribedTextRef.current?.length > 0 ||
                  startRecording === true ||
                  changingText || recordingComplete
                    ? "visible"
                    : "hidden",
              }}
              placeholder={
                transcribedTextRef.current?.length === 0 &&
                startRecording === true
                  ? "Start speaking to see your transcription here..."
                  : ""
              }
              value={isVideoOn ? transcribedTextRef.current : textareaValue}
              onChange={
                isVideoOn
                  ? null
                  : (e) => {
                      setTextareaValue(e.target.value);
                      transcribedTextRef.current = e.target.value;
                      setChangingText(true);
                    }
              }
              readOnly={isVideoOn} // Set readOnly to true when video is on
              ref={transcriptionTextAreaRef}
            />

              { (transcribedTextRef.current?.length > 0 || startRecording === true || changingText || recordingComplete) &&
                <ReportSpeechToText 
                  transcribedText={transcribedTextRef.current} 
                  answerUploadPath={`devs/${currentUser.devId || currentUser.id}/assessments/${courseID.current}/${unitID.current}/${questionID.current}`}
                  showSnackbar={showSnackbar}
                  autoSubmit={submitAnswerHandler}
                />
              }
          </div>
        </div>

        {isVideoOn && (
          <video
            ref={videoRef}
            className="video-feed-div"
            playsInline={true}
            autoPlay={true}
            muted={isPlaybackVideoMuted} // Added to avoid echo during recording
            onClick={() => {
              // Play Pause toggle
              setIsPlayerPaused((prevValue) => !prevValue);
            }}
          />
        )}

        {isVideoOn || (
          <audio
            ref={audioRef}
            className="video-feed-div"
            playsInline={true}
            autoPlay={true}
            muted={isPlaybackVideoMuted} // Added to avoid echo during recording
            onClick={() => {
              // Play Pause toggle
              setIsPlayerPaused((prevValue) => !prevValue);
            }}
          />
        )}

        <VideoControls
          recordingComplete={recordingComplete}
          isCameraStreamReady={isCameraStreamReady}
          isAudioToTextReady={isAudioToTextReady}
          startRecording={startRecording}
          setRecordingConfirmed={setRecordingConfirmed}
          setStartRecording={setStartRecording}
          maxVideoAnswerLengthInSeconds={maxVideoAnswerLengthInSeconds}
          reset={reset}
          setReset={setReset}
          show={questionText ? true : false}
          disable={isUploadingAnswer}
          isVideoOn={isVideoOn}
          setIsVideoOn={setIsVideoOn}
          hideVideoToggle={isFinalCourse || isTakingProfilingQuestionsInFinal}
        />

        {/* {hasReachedMaxSubmissions === true ||
        (continuePopUp && isAnswerSubmitted === true) ? (
          <LessonContinuePopUp
            isFeedbackPopupOpen={false}
            setIsFeedbackPopupOpen={() => {}}
            onClickHandler={onContinue}
            textPrompt={
              hasReachedMaxSubmissions ? "Max submissions reached!" : "Sure"
            }
          />
        ) : (
          <></>
        )} */}
      </div>
    </>
  );
}
