import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { useState, useEffect } from "react";
import { Button, DialogActions } from "@mui/material";
import TimePickerErrorModal from "../modal/TimePickerErrorModal";

export default function StaticTimePickerLandscape({
  isShowEdit,
  db,
  editDate,
  docRef,
  editType,
  presenceDataIndex,
  timeSheetData,
  firebase,
  setTimeSheetData,
  setIsShowEdit,
  clockInTime,
  setClockInTime,
  updatecheckOutTime,
  setupdatecheckOutTime,
}) {
  const [editTime, setEditTime] = useState(dayjs(editDate));
  const [presenceData, setPresenceData] = useState([]);
  const [minedittime, setMinedittime] = useState(null);
  const [maxedittime, setMaxedittime] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState("");

  useEffect(() => {
    getMinMaxTime();
  }, []);

  useEffect(() => {
    let previousTime = 0;
    let nextTime = 0;
    let current_date = new Date();
    let _currentTime;
    let _minvalidtime;
    let _maxvalidtime;
    let _presenceData = timeSheetData?.data()?.presenceData;
    _presenceData?.map((data, i) => {
      if (i === presenceDataIndex - 1) {
        previousTime = data?.dateTime?.toDate();
      } else if (i === presenceDataIndex + 1) {
        nextTime = data?.dateTime?.toDate();
      }
    });

    _currentTime = getTimeWithAmPm(current_date);

    {
      previousTime > 0
        ? (_minvalidtime = getTimeWithAmPm(previousTime))
        : (_minvalidtime = 0);
    }

    {
      nextTime > 0
        ? (_maxvalidtime = getTimeWithAmPm(nextTime))
        : (_maxvalidtime = 0);
    }

    if (_presenceData && _presenceData.length > 1) {
      const isBeforeMinValidTime = previousTime - editTime?.$d > 0;
      const isAfterMaxValidTime = nextTime > 0 && nextTime - editTime?.$d < 0;
      const isBeforeCurrentTime = current_date - editTime?.$d < 0;

      if (
        (editType === "pause" || editType === "unpause") &&
        isAfterMaxValidTime
      ) {
        setIsDisabled(true);
        setIsErrorMessage(
          `Selected time must be between ${_minvalidtime} and ${_maxvalidtime}. Please try again.`
        );
      } else if (
        (editType === "pause" || editType === "unpause") &&
        isBeforeMinValidTime
      ) {
        setIsDisabled(true);
        setIsErrorMessage(
          `Selected time must be after ${_minvalidtime}. Please try again.`
        );
      } else if (isBeforeCurrentTime) {
        setIsDisabled(true);
        setIsErrorMessage(
          `Selected time must be before ${_currentTime}. Please try again.`
        );
      }

      if (
        editType !== "pause" &&
        editType !== "unpause" &&
        isAfterMaxValidTime
      ) {
        setIsDisabled(true);
        setIsErrorMessage(
          `Selected time must be before ${_maxvalidtime}. Please try again.`
        );
      }
    } else if (current_date - editTime?.$d < 0) {
      setIsDisabled(true);
      setIsErrorMessage(
        `Selected time must be before ${_currentTime}. Please try again.`
      );
    }
  }, [editTime]);

  const getTimeWithAmPm = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const isPM = hours > 11;
    const amPmString = isPM ? "PM" : "AM";
    const hourString = hours % 12 === 0 ? "12" : (hours % 12).toString();
    const minuteString =
      minutes < 10 ? "0" + minutes.toString() : minutes.toString();
    return `${hourString}:${minuteString} ${amPmString}`;
  };

  const getMinMaxTime = () => {
    const setPresenceData = () => {
      let _presenceData = timeSheetData?.data()?.presenceData;
      let _mintime;
      let _maxtime;
      let current_time = new Date();
      _mintime =
        presenceDataIndex - 1 >= 0
          ? dayjs(_presenceData[presenceDataIndex - 1]?.dateTime?.toDate())
          : dayjs().startOf("day");
      _maxtime = _presenceData[presenceDataIndex + 1]
        ? dayjs(_presenceData[presenceDataIndex + 1]?.dateTime?.toDate())
        : dayjs(_presenceData[presenceDataIndex]?.dateTime?.toDate())
            ?.set("hour", current_time?.getHours())
            ?.set("minutes", current_time?.getMinutes());
      setMinedittime(_mintime);
      setMaxedittime(_maxtime);
    };
    setPresenceData();
  };

  const editPresenceData = async (item, index) => {
    setIsShowEdit(false);
    let previousTime = 0;
    let nextTime = 0;
    let _currentTime = new Date();
    // remove the target index
    const editPresenceDt = item?.data()?.presenceData;
    const newPresenceData = [...editPresenceDt];
    newPresenceData?.splice(index, 1);
    setPresenceData(newPresenceData);

    // delete all data in presenceData field in the db
    editPresenceDt?.map(async (data) => {
      const currPresenceData = [
        {
          dateTime: data?.dateTime,
          type: data?.type,
        },
      ];
      const deletePresenceData = {
        presenceData: firebase.firestore.FieldValue.arrayRemove(
          ...currPresenceData
        ),
      };
      await docRef?.update(deletePresenceData);
    });
    // update presenceData and re-add in the db

    const modifiedData = editPresenceDt?.map((data, i) => {
      if (i === index) {
        return { data, dateTime: editTime?.$d, type: editType };
      } else if (i === index - 1) {
        previousTime = data?.dateTime.toDate();
        return { data, dateTime: data?.dateTime, type: data?.type };
      } else if (i === index + 1) {
        nextTime = data?.dateTime.toDate();
        return { data, dateTime: data?.dateTime, type: data?.type };
      } else {
        return { data, dateTime: data?.dateTime, type: data?.type };
      }
    });

    modifiedData?.map(async (x, i) => {
      const updatedData = [
        {
          dateTime: x?.dateTime,
          type: x?.type,
        },
      ];
      const updateData = {
        presenceData: firebase.firestore.FieldValue.arrayUnion(...updatedData),
      };
      const previousData = {
        presenceData: firebase.firestore.FieldValue.arrayUnion(
          ...editPresenceDt
        ),
      };
      if (presenceDataIndex === 0) {
        docRef?.update({ checkIn: editTime?.$d });
      }
      if (updatecheckOutTime) {
        docRef?.update({ checkOut: editTime?.$d });
      }
      await docRef?.set(updateData, { merge: true });
    });
    const timesheetDt = await docRef?.get();
    setTimeSheetData(timesheetDt);
  };
  return (
    <>
      {isShowEdit && (
        <div className="edit_timePicker_container">
          <div className="edit_timePicker_modal">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticTimePicker
                ampm
                orientation="landscape"
                openTo="minutes"
                value={editTime}
                id="timePicker"
                onChange={(newValue) => {
                  setIsDisabled(false);
                  setEditTime(newValue);
                  setIsErrorMessage("");
                }}
                minTime={minedittime}
                maxTime={maxedittime}
                renderInput={(params) => <TextField {...params} />}
                //componentsProps={{ actionBar: { actions: [ 'cancel', 'accept'] } }}
                componentsProps={{ actionBar: { actions: [] } }}
              />
            </LocalizationProvider>

            <DialogActions>
              <span style={{ color: "red" }}>{isErrorMessage}</span>
              <Button
                onClick={() => {
                  setIsShowEdit(false);
                }}
              >
                {" "}
                Cancel{" "}
              </Button>
              <Button
                style={
                  isDisabled ? { color: "grey", pointerEvents: "none" } : {}
                }
                onClick={(e) =>
                  editPresenceData(timeSheetData, presenceDataIndex)
                }
              >
                Set New Time{" "}
              </Button>
            </DialogActions>
          </div>
        </div>
      )}
    </>
  );
}
