import React from 'react';

const PauseCheckinIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="6"
        height="27"
        rx="2"
        fill="#FFCE20"
      />
      <rect
        x="14"
        width="6"
        height="27"
        rx="2"
        fill="#FFCE20"
      />
    </svg>
  );
};

export default PauseCheckinIcon;
