import React, { useEffect, useState } from "react";
import noAvatar from "../../../static/no-avatar.png";
import CreateCourse from "./CreateCourse";
import Spinner from "../../spinner/Spinner";
import { usePapaParse } from "react-papaparse";
import {
  processCSV,
  createAllQuestions,
  getCourseByCourseName,
  getUnitByUnitName,
  getUnitWithinCourse,
} from "../helpers/utils";
import { getSubTopicDocRef, getSubjectDocRef, getTopicDocRef } from "../helpers/store";

const Courses = ({ db, currentUser }) => {
  const [isCreateCoursesOpen, setCreateCoursesOpen] = useState(false);
  const [coursesStack, setCoursesStack] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isViewCoursesOpen, setViewCoursessOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isdataimported, setIsDataImported] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [showMessage, setShowMessage] = useState(false);

  const { readString } = usePapaParse();

  useEffect(() => {
    const getQuestionsStack = async () => {
      let coursesList = []; // Array to store the documents

      let nestriaCoursesStack = db?.collection(`catalog`);
      let coursesStack = await nestriaCoursesStack?.get();

      await Promise.all(
        coursesStack?.docs.map(async (doc) => {
          let documentData = doc?.data();
          let unitList = [];

          try {
            const nestriaUnitsStack = await doc?.ref?.collection("units").get();
            const unitsStack = nestriaUnitsStack.docs;

            await Promise.all(
              unitsStack.map(async (doc) => {
                try {
                  const documentData = doc?.data();
                  const questionsSubcollection = await doc?.ref
                    ?.collection("questions")
                    ?.get();

                  const questionList = [];

                  await Promise.all(
                    questionsSubcollection.docs.map(async (questionDoc) => {
                      try {
                        const questionData = questionDoc?.data();
                        const questionReference = questionData?.ref;
                        const questionSnapshot = await questionReference?.get();
                        const questionName = questionSnapshot?.data()?.question;
                        questionList.push({
                          question: questionName,
                          xp: questionData?.xp,
                          reference: questionReference,
                        });
                      } catch (error) {
                        console.error(
                          "Error processing question document",
                          error
                        );
                      }
                    })
                  );

                  unitList.push({
                    name: documentData.name,
                    questions: questionList,
                    index: documentData?.index,
                    avatar: noAvatar,
                    reference: doc.ref,
                  });
                } catch (error) {
                  console.error(
                    "Error accessing questions subcollection",
                    error
                  );
                }
              })
            );
          } catch (error) {
            console.error("Error retrieving units", error);
          }

          coursesList.push({
            name: documentData.name,
            desc: documentData.desc,
            units: unitList,
            avatar: noAvatar,
            courseReference: doc.ref,
          });
          //}
        })
      );

      setCoursesStack(coursesList);
      if (coursesList !== undefined) {
        setLoading(false);
      }
    };

    getQuestionsStack();
  }, [isCreateCoursesOpen, isViewCoursesOpen, , isdataimported]);

  if (coursesStack?.length > 0) {
    coursesStack.sort((a, b) => a.name.localeCompare(b.name));
  }

  const createCourse = () => {
    setMessage("");
    setShowMessage(false);
    setCreateCoursesOpen(true);
  };

  const viewCourses = (course) => {
    console.log("course", course);
    setMessage("");
    setShowMessage(false);
    setSelectedCourse(course);
    setViewCoursessOpen(true);
  };

  const handleFileUpload = async (event) => {
    setMessage("");
    setShowMessage(false);
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async () => {
      setLoading(true);
      const csvData = reader.result;
      const parsedData = await processCSV(csvData, readString);

      console.log(`parsedData...`);
      console.log(parsedData);

      const questionRefs = await createAllQuestions(db, parsedData);

      console.log(`All question refs: `);
      console.log(questionRefs);

      try {
        // These variables help in caching.
        let courseRef;
        let unitRef;
        let prevCourseName;
        let prevUnitName;

        let unitIndexCount = 0;

        for (let i = 0; i < parsedData.length; i++) {
          const row = parsedData[i];
          const courseName = row.course;
          const courseDescription = row.courseDesc;
          const courseType = row.courseType;
          const courseSubject = row?.subject;
          const isCourseFinalAssessment =
            row?.isFinalAssessment === "true"
              ? true
              : row?.isFinalAssessment === "false"
              ? false
              : false;
          const unitName = row.unit;

          if (courseName !== prevCourseName) {
            courseRef = await getCourseRefNewOrExisting(db, {
              name: courseName,
              description: courseDescription,
              type: courseType,
              subject: courseSubject ? courseSubject : "",
              isFinalAssessment: isCourseFinalAssessment,
            });
            prevCourseName = courseName;
          }

          if (unitName !== prevUnitName) {
            console.log(`Unit Name: ${unitName}`);
            unitRef = await getUnitRefNewOrExisting(
              {
                name: unitName,
                index: unitIndexCount,
              },
              courseRef
            );
            prevUnitName = unitName;
            unitIndexCount += 1;
            console.log(`UnitRef`);
            console.log(unitRef);
          }

          if (unitRef) {
            console.log(`Question Index: ${i}`);
            console.log(`Question: ${row?.question}`);
            const questionRef = await unitRef?.collection("questions")?.add({
              ref: questionRefs[i],
              index: i,
              xp: Number(row?.xp) || 0,
            });
            console.log("questionRef", questionRef);
          }
        }
        console.log("Data saved to the database successfully!");
        setMessage("Data imported successfully!");
        setShowMessage(true);
        setIsDataImported(true);
        setLoading(false);
      } catch (error) {
        console.error("Error saving data to the database:", error);
        setMessage("Error importing data. Please try again.");
        setShowMessage(true);
        setIsDataImported(false);
        setLoading(false);
      }
    };

    reader.readAsText(file);
  };

  const handleQuestionBankFileUpload = async (event) => {
    setMessage("");
    setShowMessage(false);
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async () => {
      setLoading(true);
      const csvData = reader.result;
      const parsedData = await processCSV(csvData, readString);

      console.log(`parsedData...`);
      console.log(parsedData);

      try {
        let prevTopicName;
        let prevSubTopicName;
        let topicDocRef;
        let subTopicDocRef;
        let topicIndexCount = -1;
        let subTopicIndexCount = -1;

        const row = parsedData[0];
        const subject = row.subject;

        if (!subject) {
          throw Error(
            `Required filed subject missing. Got subject: ${subject}`
          );
        }

        const subjectDocRef = await getSubjectDocRef(subject);

        for (let i = 0; i < parsedData.length; i++) {
          const row = parsedData[i];

          const {
            topic,
            subTopic,
            question,
            difficulty,
            isFinalQuestion,
            maxSubmissions,
            maxVideoAnswerLengthInSeconds,
            xp,
            videoAnswerRequired,
            skippable,
            prompt,
          } = await getColumnValuesFromRowWithDefaults(row);

          if (
            !topic ||
            !subTopic ||
            !difficulty ||
            !question ||
            isFinalQuestion === undefined ||
            isFinalQuestion === null
          ) {
            throw Error(
              `Missing required fields. topic: ${topic}, subTopic: ${subTopic}, difficulty: ${difficulty}, question: ${question}, isFinalQuestion: ${isFinalQuestion}`
            );
          }

          if (typeof isFinalQuestion !== typeof Boolean()) {
            throw Error(
              `Required field isFinalQuestion contains invalid value. Got ${isFinalQuestion}, expecting Boolean value.`
            );
          }

          if (topic !== prevTopicName) {
            // Topic changed, create new topic ref
            topicIndexCount += 1;
            topicDocRef = await getTopicDocRef(
              topic,
              subjectDocRef,
              topicIndexCount
            );
            prevTopicName = topic;
          }

          if (subTopic !== prevSubTopicName) {
            // Sub Topic changed, create new sub topic ref
            subTopicIndexCount += 1;
            subTopicDocRef = await getSubTopicDocRef(
              subTopic,
              topicDocRef,
              subTopicIndexCount
            );
            prevSubTopicName = subTopic;
          }

          if (subTopicDocRef) {
            console.log(`Question Index: ${difficulty}`);
            console.log(`Question: ${row?.question}`);

            const questionDocRef = await subTopicDocRef
              ?.collection("questions")
              ?.add({
                difficulty,
                question,
                isFinalQuestion,
                isDynamic: true, // Assuming all the questions within questionBank are used for dynamic assessments
                maxSubmissions,
                skippable,
                videoAnswerRequired,
                xp,
                maxVideoAnswerLengthInSeconds,
                originalParentSourceRef: subTopicDocRef,
                prompt,
              });

            console.log("questionRef", questionDocRef);
          }
        }

        await subjectDocRef.update({
          generateAssessments: true, // Setting this to true will trigger the cloud function to auto create assessments from this question bank
        });

        console.log("Data saved to the database successfully!");
        setMessage("Data imported successfully!");
        setShowMessage(true);
        setIsDataImported(true);
        setLoading(false);
      } catch (error) {
        console.error("Error saving data to the database:", error);
        setMessage("Error importing data. Please try again.");
        setShowMessage(true);
        setIsDataImported(false);
        setLoading(false);
      }
    };

    reader.readAsText(file);
  };

  const getColumnValuesFromRowWithDefaults = (row) => {
    try {
      const { topic, subTopic, question } = row;

      const difficulty = Number(row?.difficulty);

      const isFinalQuestion =
        row?.isFinalQuestion?.toLowerCase() === "true" ? true : false;

      const skippable = row?.skippable?.toLowerCase() === "true" ? true : false;

      const videoAnswerRequired =
        row?.videoAnswerRequired?.toLowerCase() === "true" ? true : false;

      const xp = Number(row?.xp) || 10;

      const maxSubmissions = Number(row?.maxSubmissions) || 3;

      const maxVideoAnswerLengthInSeconds =
        Number(row?.maxVideoAnswerLengthInSeconds) || 90;

      const prompt = row?.prompt ? String(row.prompt) : "";

      return Promise.resolve({
        topic,
        subTopic,
        question,
        difficulty,
        isFinalQuestion,
        skippable,
        videoAnswerRequired,
        xp,
        maxSubmissions,
        maxVideoAnswerLengthInSeconds,
        prompt,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const getCourseRefNewOrExisting = async (db, course) => {
    // TODO: Add more input validation.
    try {
      const { name, description, type, subject, isFinalAssessment } = course;

      if (!name || !description || subject === null || subject === undefined) {
        throw Error(
          `invalid course object. Invalid course.name: ${name} or course.description: ${description} or course.subject: ${subject}.`
        );
      }
      if (
        typeof name !== "string" ||
        typeof description !== "string" ||
        typeof subject !== "string"
      ) {
        throw Error(
          `invalid course data type. Invalid course.name or course.description or course subject types. Expecting string.`
        );
      }

      const existingCourseSnapshot = await getCourseByCourseName(db, name);

      let courseRef;

      if (!existingCourseSnapshot.empty) {
        courseRef = existingCourseSnapshot.docs[0].ref;
      } else {
        courseRef = await db?.collection("catalog")?.add({
          name: name,
          desc: description,
          createdOn: new Date(),
          courseType: type ?? "default",
          subject: subject,
          isFinalAssessment: isFinalAssessment,
        });

        return Promise.resolve(courseRef);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const getUnitRefNewOrExisting = async (unit, courseRef) => {
    // TODO: Add more input validation.
    try {
      const { name, index } = unit;

      if (!name) {
        throw Error(`invalid unit object. Invalid unit.name.`);
      }
      if (typeof name !== "string") {
        throw Error(
          `invalid unit data type. Invalid unit.name. Expecting string.`
        );
      }

      const existingUnitSnapshot = await getUnitWithinCourse(courseRef, name);

      let unitRef;

      if (!existingUnitSnapshot.empty) {
        unitRef = existingUnitSnapshot.docs[0].ref;
      } else {
        unitRef = await courseRef?.collection("units")?.add({
          name: name,
          index,
          createdOn: new Date(),
        });
      }

      return Promise.resolve(unitRef);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return (
    <>
      <div className="course-center-div transition-6">
        <div className="course_main">
          <div className="course-questions">
            <div className="search_box_container">
              <input
                type="text"
                placeholder="Search Courses"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {showMessage && (
              <div className="message-container">
                <p className="message">{message}</p>
              </div>
            )}
            <div className="courses-btn">
              <div className="course-bn3">
                <label htmlFor="importFile" className="import-file-label">
                  Import Course
                </label>
                <input
                  id="importFile"
                  type="file"
                  accept=".CSV"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
              </div>
              <div className="course-bn3">
                <label
                  htmlFor="importQuestionBankFile"
                  className="import-file-label"
                >
                  Import Question Bank
                </label>
                <input
                  id="importQuestionBankFile"
                  type="file"
                  accept=".CSV"
                  style={{ display: "none" }}
                  onChange={handleQuestionBankFileUpload}
                />
              </div>
              <div className="course-bn3" onClick={createCourse}>
                <label>Create Course</label>
              </div>
            </div>
            <div className="courses-stack">
              {loading ? (
                <Spinner
                  width="6.25rem"
                  height="6.25rem"
                  overlayHeight="50vh"
                />
              ) : (
                coursesStack
                  ?.filter((course) =>
                    course.name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  )
                  .map((course) => (
                    <div className="courses-card">
                      <div className="course-avatar">
                        <img
                          src={course.avatar}
                          alt="roger"
                          height="20px"
                          width="20px"
                        />
                      </div>
                      <div className="tech-courses">
                        <div>{course.name}</div>
                      </div>

                      <div className="course_msg_notifs_option_btn">
                        <div
                          className="courses_bn3"
                          onClick={() => viewCourses(course)}
                        >
                          View
                        </div>
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>
        {(isCreateCoursesOpen || isViewCoursesOpen) && (
          <CreateCourse
            db={db}
            currentUser={currentUser}
            setCreateCoursesOpen={setCreateCoursesOpen}
            selectedCourse={selectedCourse}
            isCreateCoursesOpen={isCreateCoursesOpen}
            isViewCoursesOpen={isViewCoursesOpen}
            setViewCoursessOpen={setViewCoursessOpen}
            setMessage={setMessage}
            setShowMessage={setShowMessage}
            coursesStack={coursesStack}
            setSelectedCourse={setSelectedCourse}
          />
        )}
      </div>
    </>
  );
};

export default Courses;
