import { useEffect } from 'react';

import confetti from "canvas-confetti";

export function FirstToAnswerPopup({
  onClickHandler,
}) {

  useEffect(() => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  }, []);

  return (
    <>
      <div className="firsttoanswer-container fixed duration-2000 bg-white font-bold bottom-0 left-0 right-0 flex flex-col justify-center items-center" style={{ zIndex: 2, height: "450px", display: "flex", flexDirection: "column" }}>
        <div className="flex flex-col items-center max-w-5xl p-5 gap-4">
          <div className="text-center flex flex-col items-center">
            <div style={{ width: "100%", maxWidth: "300px", flex:"1" }}> 
              <img
                className="mx-auto mb-4" 
                alt="nestria-avatar"
                src="https://info.nestria.org/hubfs/Tombstone%20Static%20Website%20Images/astronaut.png"
                style={{
                  height: "auto",
                  width : "auto", 
                  maxHeight: "160px",
                  maxWidth: "100%",
                }}
              />
            </div>
            <div className="text-black font-bold text-lg" style={{ fontSize: "30px", marginTop: "30px", flex:"1" }}> 
              Congratulations
            </div>
            <div className="text-yellow-500 font-bold text-sm" style={{ flex:"1", fontSize: "20px", margin: "40px 20px 0px 20px", color: "#f0c419", lineHeight: "1.5" }}>
              You're the first to answer this question!
            </div>
           
          </div>
        </div>
        <div style={{ width: "100%", textAlign:"center", marginTop: "auto" }}> 
          <button
            onClick={onClickHandler}
            className="grow rounded-2xl border-b-4 border-green-600 bg-green-500 p-9 font-bold uppercase text-white sm:min-w-[50px] sm:max-w-[100px] sm:grow-0 mt-4" 
            style={{
              width: "100%",
              fontSize:"15px",
              maxWidth: "350px",
              padding:"15px",
              boxShadow: "0 2px 0 rgb(88, 167, 0), 0 0 0 rgb(24, 153, 214)",
              marginBottom:"20px",
              maxWidth: "94%", // Ensure the button fits within its container
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
}
