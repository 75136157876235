import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Flag, Info } from "../UserAnswers/Svg";
import { CurrentUserContext } from "../Context/Nestria";
import { getSystemInfo } from "./WebVitals";
import { generateRandomResponse } from "../helpers/utils";

enum OPTIONS {
  "PREDEFINED",
  "CUSTOM",
}

type IssueOption = {
  id: number;
  type: OPTIONS;
  text: String;
};

const LIST_OF_ISSUES: Array<IssueOption> = [
  {
    id: 1,
    type: OPTIONS.PREDEFINED,
    text: "I spoke but nothing got transcribed.",
  },
  {
    id: 2,
    type: OPTIONS.PREDEFINED,
    text: "Some words got incorrectly transcribed.",
  },
  {
    id: 3,
    type: OPTIONS.PREDEFINED,
    text: "My initial speech got transcribed but not the rest of it.",
  },
  {
    id: 4,
    type: OPTIONS.PREDEFINED,
    text: "Some technical words got incorrectly transcribed.",
  },
  { id: 5, type: OPTIONS.CUSTOM, text: "Something else went wrong." },
];

const REPORT_CONFIRMATION_MSGS = [
  "Thank you for reporting this. We'll look into it right away",
  "Thanks for bringing this to our attention. We are on it.",
  "We appreciate your report. Our team will address this promptly.",
  "Thank you for letting us know. We're working on it now.",
  "Thanks for the heads-up. We'll investigate this immediately.",
  "Thanks for reporting. Our team will get right on it.",
  "Thank you for informing us. We'll resolve this as soon as possible.",
];

const NOTE = "Submitting this will also submit your current answer.";

const DISCORD_WEBHOOK_URL =
  "https://discord.com/api/webhooks/1260507675796443226/4pH9rNM-0TpW4ayvDQKa1f8csRWV2OBBBnD8OiTgTThiQCzfZYhSjgo2pHdJ8d5AmslG";

export const ReportSpeechToText = ({
  transcribedText,
  answerUploadPath,
  showSnackbar,
  autoSubmit,
}) => {
  const currentUserObj = useContext(CurrentUserContext);
  const customIssueElmRef = useRef<any>();
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedIssues, setSelectedIssues] = useState<IssueOption[]>([]);

  const onCustomIssueInputChangeHandler = () => {
    setIsReadyToSubmit(_isReadyToSubmit());
  };

  const _isReadyToSubmit = useCallback(() => {
    if (selectedIssues.findIndex((i) => i.type === OPTIONS.CUSTOM) !== -1) {
      if (customIssueElmRef.current?.value?.trim() !== "") {
        return true;
      }
      return false;
    } else {
      return selectedIssues.length > 0;
    }
  }, [selectedIssues, customIssueElmRef]);

  useEffect(() => {
    if (selectedIssues.findIndex((i) => i.type === OPTIONS.CUSTOM) !== -1) {
      // If Custom Option is checked
      customIssueElmRef.current.scrollIntoView();
    }

    // Check if form is ready to submit
    setIsReadyToSubmit(_isReadyToSubmit());
  }, [selectedIssues, _isReadyToSubmit]);

  const toggleSelection = (issue: IssueOption) => {
    if (selectedIssues.findIndex((i) => i.id === issue.id) !== -1) {
      setSelectedIssues((prev) => {
        return prev.filter((i) => i.id !== issue.id);
      });
    } else {
      setSelectedIssues((prev) => {
        return [...prev, issue];
      });
    }
  };

  /**
   * This function sends the reported issue payload
   * to discord channel
   */
  const reportIssue = async () => {
    try {
      const reportString = selectedIssuesToString();

      console.log(`reportSTring: `, reportString);

      // Send to discord channel
      await fetch(DISCORD_WEBHOOK_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: buildDiscordWebhookBody(reportString),
      });

      return;
    } catch (e) {
      console.error(e);
      return;
    }
  };

  /**
   * This function converts the selected issues in to
   * a single bulleted string.
   * @returns String
   */
  const selectedIssuesToString = () => {
    let reportString = "";

    for (let i of selectedIssues) {
      if (i.type === OPTIONS.CUSTOM) {
        if (customIssueElmRef.current?.value)
          reportString += `- ${customIssueElmRef.current.value} \n`;
      } else {
        reportString += `- ${i.text} \n`;
      }
    }

    return reportString;
  };

  const buildDiscordWebhookBody = (reportString) => {
    return JSON.stringify({
      content: `Issue Reported (${process.env.REACT_APP_ENV || ""})`,
      embeds: [
        {
          author: {
            name: `${currentUserObj?.first} ${currentUserObj?.last}`,
          },
          title: "Reported Issues",
          description: reportString,
          fields: [
            {
              name: "Registered Email",
              value: currentUserObj?.email || "User email",
            },
            {
              name: "uid",
              value: currentUserObj?.id,
            },
            {
              name: "devID",
              value: currentUserObj?.devId,
            },
            {
              name: "Try looking under this path",
              value: `${answerUploadPath}`,
            },
            {
              name: "Transcribed Text",
              value: transcribedText,
            },
            {
              name: "systemInfo",
              value: "` " + JSON.stringify(getSystemInfo()) + "`",
            },
          ],
        },
      ],
    });
  };

  const submitHandler = async () => {
    try {
      await reportIssue();
    } catch (e) {
      console.error(e);
    } finally {
      autoSubmit();
      showSnackbar(
        "info",
        generateRandomResponse(
          REPORT_CONFIRMATION_MSGS,
          "Thanks for reporting."
        )
      );
      setShowModal(false);
    }
  };

  if (showModal) {
    return (
      <div
        className="fixed duration-1000 bg-white font-bold bottom-0 left-0 right-0 flex flex-col justify-center items-center"
        style={{
          zIndex: 99,
          height: "400px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{ padding: "0px 20px 0px 20px" }}
          className="flex max-w-5xl flex-col gap-4 py-4 items-center sm:mx-auto sm:flex-row sm:items-center sm:justify-center sm:py-14"
        >
          <div className="flex flex-col items-center justify-center gap-2">
            <div
              className="w-full"
              style={{
                fontSize: "16px",
                maxHeight: "250px",
                overflowX: "hidden",
                overflowY: "scroll",
                scrollbarWidth: "thin",
              }}
            >
              {LIST_OF_ISSUES.map((issue) => (
                <p
                  key={issue.id}
                  className={`text-blue-400 py-2 text-left border-b-2 border-gray-200  ${
                    selectedIssues.findIndex((i) => i.id === issue.id) !== -1
                      ? "bg-blue-200"
                      : ""
                  }`}
                  onClick={() => toggleSelection(issue)}
                >
                  <input
                    type="checkbox"
                    style={{ marginRight: "8px" }}
                    checked={
                      selectedIssues.findIndex((i) => i.id === issue.id) !== -1
                    }
                    onChange={() => toggleSelection(issue)}
                  />
                  {issue.text}
                </p>
              ))}

              <textarea
                className="text-gray-400 py-2 text-left border-b-2 border-gray-200"
                contentEditable="true"
                ref={customIssueElmRef}
                onInput={onCustomIssueInputChangeHandler}
                style={{
                  outline: "none",
                  width: "100%",
                  resize: "none",
                  maxLines: 10,
                  visibility:
                    selectedIssues.findIndex(
                      (i) => i.type === OPTIONS.CUSTOM
                    ) !== -1
                      ? "visible"
                      : "hidden",
                }}
                placeholder="Please describe your issue here"
              ></textarea>
            </div>

            <div
              style={{
                margin: "10px, 0px, 10px, 0px",
                display: "flex",
                gap: "20px",
              }}
            >
              <button
                style={{
                  backgroundColor: "rgb(169, 169, 169, 1)",
                  padding: "5px 10px 5px 10px",
                  borderRadius: "10px",
                  color: "white",
                }}
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </button>

              <button
                disabled={!isReadyToSubmit}
                style={{
                  backgroundColor: isReadyToSubmit
                    ? "rgb(169, 169, 169, 1)"
                    : "rgb(169, 169, 169, 0.5)",
                  padding: "5px 10px 5px 10px",
                  borderRadius: "10px",
                  color: "white",
                }}
                onClick={submitHandler}
              >
                Submit
              </button>
            </div>

            <div
              style={{
                alignItems: "center",
                display: "flex",
                fontSize: "12px",
                fontWeight: "700",
                backgroundColor: "#FFFFE0 ",
                padding: "10px",
                borderRadius: "5px",
                width: "100%",
                gap: "5px",
              }}
            >
              <Info width={"15px"} height={"15px"} />
              <span>{NOTE}</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div style={{ position: "absolute", right: "4px", bottom: "12px", backgroundColor: "grey", borderRadius: "100px", padding: "5px" }}>
          <Flag width={"20px"} onClickHandler={() => setShowModal(true)} />
        </div>
      </>
    );
  }
};
