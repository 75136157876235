import React from 'react';

const MessageTextArea = ({
  newMessage,
  setNewMessage,
  imagePaste,
  handleKeypress
}) => {
  return (
    <>
      <div className="msg_textarea_container">
        <textarea
          type="text"
          className="input_message"
          placeholder="Write a message here..."
          // defaultValue={newMessage}
          value={newMessage}
          onChange={(e)=>{setNewMessage(e.target.value)}}
          onPaste={imagePaste}
          onKeyPress={handleKeypress}
        />
      </div>
    </>
  );
};

export default MessageTextArea;
