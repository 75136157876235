import React, { useContext, useEffect, useState } from "react";
import emu from "../../static/emu.jpg";
import UserConnections from "../../components/userConnections/UserConnections";
import NestriaUserConnections from "../../components/nestria/Admin/Users";
import CompanyConnections from "../../components/companyConnections/CompanyConnections";
import ConnectModal from "../../components/modal/ConnectModal";
import OratioContext from "../../oratioContext/OratioContext";
import TechConnections from "../../components/techConnections/TechConnections";
import SearchBox from "../../components/searchBox/SearchBox";
import EmailInvites from "../../components/emailInvites/EmailInvites";
import Requirements from "../../components/requirements/Requirements";
import Categories from "../../components/categories/Categories";
import AdminUserView from "../../components/nestria/Admin/AdminUserView";
// import PrevArrow from '../../components/svgs/PrevArrow';
// import NextArrow from '../../components/svgs/NextArrow';

function ConnectionWindow({
  visible,
  currentUser,
  firebase,
  db,
  truncateString,
  switchWindowDiscussion,
  setActiveTab,
  currentUserObj,
  showInviteUser,
  setShowInviteUser,
  showInviteDismissBtn,
  setShowInviteDismissBtn,
  chatObj,
  setChatObj,
  currentUserRole,
  userRole,
  currentUserRole2,
  userRole2,
  isNestria,
}) {
  const [connections, setConnections] = useState();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [companyConnections, setCompanyConnections] = useState();
  const [techConnections, setTechConnections] = useState();
  const [requirementsConnections, setRequirementsConnections] = useState();
  const [isDefault, setIsDefault] = useState(true);
  const [isUserConnectionsShown, setIsUserConnectionsShown] = useState(false);
  const [isCompConnectionsShown, setIsCompConnectionsShown] = useState(false);
  const [isTechConnectionsShown, setIsTechConnectionsShown] = useState(false);
  const [isRequirementsShown, setIsRequirementsShown] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [isConnectModal, setIsConnectModal] = useState(false);
  const [selectedSearchData, setSelectedSearchData] = useState(null);
  const [connectTypes, setConnectTypes] = useState("");
  const [incomingReqData, setIncomingReqData] = useState();
  const [selectedData, setSelectedData] = useState(null);
  const [acceptConnectTypes, setAcceptConnectTypes] = useState("");
  const [currentUserRef2, setCurrentUserRef2] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [notificationFreq, setNotificationFreq] = useState(null);
  const [showTruncatedBio, setShowTruncatedBio] = useState(true);
  const [notificationChannel, setNotificationChannel] = useState(null);
  const [currConnectionId, setCurrConnectionId] = useState("");
  const [isOptions, setIsOptions] = useState(false);
  const [isDisconnectModal, setIsDisconnectModal] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isAdminUserViewShow, setIsAdminUserViewShow] = useState(false);

  // current user ID
  const userId = currentUser?.uid;

  // checks if user is an admin
  const isAdmin =
    currentUserRole?.role?.path === `${userRole?.path}/ADMIN` ||
    currentUserRole?.role?.path === `${userRole?.path}/SUPER-ADMIN` ||
    currentUserRole?.role?.path === `${userRole?.path}/PARTNER-ADMIN` ||
    currentUserRole?.role?.path === `${userRole?.path}/SALES-ADMIN`;

  const isAdmin2 =
    currentUserRole2?.role?.path === `${userRole2?.path}/ADMIN` ||
    currentUserRole2?.role?.path === `${userRole2?.path}/SUPER-ADMIN` ||
    currentUserRole2?.role?.path === `${userRole2?.path}/PARTNER-ADMIN` ||
    currentUserRole2?.role?.path === `${userRole2?.path}/SALES-ADMIN`;

  // destructures prop/value from the OratioContext component
  const {
    connectionObj,
    setConnectionObj,
    setConnectionsCount,
    searchTerm,
    setSearchTerm,
    userConnections,
    setUserConnections,
    setShowGroupChatSection,
    setShowMessageSection,
  } = useContext(OratioContext);

  // all categories (i.e people, companies, etc)
  useEffect(() => {
    const getCategories = async () => {
      const catsRef = db
        .collection("organisations/T6BAcTjwbXleCFpmWTmu/connectionCats")
        .orderBy("position", "asc");
      const catsDoc = await catsRef?.get();
      let categories = [];
      catsDoc.forEach((doc) => {
        categories.push(doc?.data()?.label);
      });
      setCategories(categories);
    };
    getCategories();
  }, []);

  // filter users, comapnies, and technologies col using searchIndex and nameIndex params
  const search = async (searchVal) => {
    const lowerCaseSearchValue = searchVal?.toLowerCase(); // converts all search values to lower case
    const initialCapSearchValue =
      lowerCaseSearchValue?.charAt(0)?.toUpperCase() +
      lowerCaseSearchValue?.slice(1); // converts first letter to upper case
    const upperCaseSearchValue = searchVal?.toUpperCase(); // converts all search values to upper case

    // an array of converted search values which ignores cases irrespective of search inputs
    let convertedSearchValue = [
      initialCapSearchValue,
      lowerCaseSearchValue,
      upperCaseSearchValue,
    ];

    // queries the db and gets the docs/details of the search parameters e.g searchIndex and nameIndex
    const searchIndex = db
      .collection("users")
      .where("searchIndex", "array-contains-any", convertedSearchValue)
      .limit(10)
      .get();
    const companyIndex = db
      .collection("companies")
      .where("nameIndex", "array-contains-any", convertedSearchValue)
      .limit(10)
      .get();
    const techIndex = db
      .collectionGroup("technologies")
      .where("nameIndex", "array-contains-any", convertedSearchValue)
      .limit(10)
      .get();
    const requirementsIndex = db
      .collectionGroup("lists")
      .where("nameIndex", "array-contains-any", convertedSearchValue)
      .limit(10)
      .get();

    // resolves all promises once using Promise.all()
    const results = await Promise.all([
      searchIndex,
      companyIndex,
      techIndex,
      requirementsIndex,
    ]);

    // supplement individual search result arrays with connectionCat key
    // assign unique key to users search result
    const usersResult = [...results[0].docs];
    const usersResultWithKey = usersResult?.map((result) => {
      return { result, key: "user" };
    });
    // assign unique key to company search result
    const compResult = [...results[1].docs];
    const compResultWithKey = compResult?.map((result) => {
      return { result, key: "company" };
    });
    // assign unique key to tech search result
    const techResult = [...results[2].docs];
    const techResultWithKey = techResult?.map((result) => {
      return { result, key: "tech" };
    });
    // assign unique key to requirements(lists) search result
    const requirementsResult = [...results[3].docs];
    const requirementsResultWithKey = requirementsResult?.map((result) => {
      return { result, key: "requirements" };
    });

    // spreads the details of the resolved promises into the searchDoc array
    // 1st index rep the details of the searchIndex; 2nd index rep the details of the nameIndex
    let searchDoc = [
      ...usersResultWithKey,
      ...compResultWithKey,
      ...techResultWithKey,
      ...requirementsResultWithKey,
    ];

    // looping through the docs snapshots to obtain the search data
    const searchDetails = [];
    searchDoc?.forEach((data) => {
      searchDetails.push({
        ...data,
        ...data?.result?.data(),
        ref: data?.result?.ref,
      });
    });

    // update searchData state with searched usersData values
    setSearchData(searchDetails);
  };

  // searches for users, comapnies, and techs from db
  useEffect(() => {
    search(searchTerm);
  }, [searchTerm]);

  // filter category
  const connectionCategories = () => {
    let userConnectionArray = [];
    let companyConnectionArray = [];
    let techConnectionArray = [];
    let requirementsArray = [];
    connections?.map(async (connection) => {
      let connectionCat = connection?.connectionCat;
      let connectionCatRef = await connectionCat?.get();
      let connectionCatData = connectionCatRef?.data();

      if (connectionCatData?.type === "To User") {
        userConnectionArray.push(connection);
      }

      if (connectionCatData?.type === "To Company") {
        companyConnectionArray.push(connection);
      }

      if (connectionCatData?.type === "To Technology") {
        techConnectionArray.push(connection);
      }

      if (connectionCatData?.type === "To Requirements") {
        requirementsArray.push(connection);
      }
      setUserConnections(userConnectionArray);
      setCompanyConnections(companyConnectionArray);
      setTechConnections(techConnectionArray);
      setRequirementsConnections(requirementsArray);
    });
  };

  // connections stream
  useEffect(() => {
    const getConnections = () => {
      // const connectionsRef = db.collection('/users/0FmYkAmfY5buv53aFEHfF3PudW23/connections');
      const connectionsRef = db
        .collection(`/users/${userId}/connections`)
        .orderBy("connectedOn", "desc");
      // connections stream
      const connectionsRes = connectionsRef?.onSnapshot(async (snapshot) => {
        const data = await Promise.all(
          snapshot?.docs?.map(async (doc) => {
            const connectionReference = doc?.ref;
            const connectData = doc?.data();
            const connectUserRef = connectData?.ref?.path;
            // user roles
            const userRolesRef = db.doc(
              `${connectUserRef}/accounts/spryte-dev`
            );
            const userRolesDoc = await userRolesRef?.get();
            const userRolesData = userRolesDoc?.data();

            const userRolesRef2 = db.doc(
              `${connectUserRef}/accounts/spryte-partner`
            );
            const userRolesDoc2 = await userRolesRef2?.get();
            const userRolesData2 = userRolesDoc2?.data();
            // const connectRef = doc?.ref;
            const connectionTypes = connectData?.connectionTypes?.map(
              (types) => types.id
            );
            const docReference = await doc?.data()?.ref?.get();
            const docData = docReference?.data();
            // organization data
            const orgRef = docData?.orgRef;
            const orgDoc = await orgRef?.get();
            const orgData = orgDoc?.data();

            return {
              id: docReference?.id,
              ...connectData,
              types: connectionTypes,
              ...docData,
              connectionRef: connectionReference,
              userRolesData: userRolesData,
              userRolesData2: userRolesData2,
              orgData: orgData,
            };
          })
        );
        setConnections(data);
        setConnectionsCount(data?.length);
      });
      return connectionsRes;
    };
    // stream connections collection only if connections state is undefined
    if (connections === undefined) {
      getConnections();
    }
    // sets loading to false if connections are available
    if (connections !== undefined) {
      setLoading(false);
    }
    // filters connections into user and company categories
    connectionCategories();
  }, [connections]);

  // updates people category with user connections
  const updatePeopleCategory = () => {
    setIsUserConnectionsShown(true);
    setIsCompConnectionsShown(false);
    setIsTechConnectionsShown(false);
    setIsRequirementsShown(false);
    setIsDefault(false);
  };

  // updates company category with company connections
  const updateCompanyCategory = () => {
    setIsCompConnectionsShown(true);
    setIsUserConnectionsShown(false);
    setIsTechConnectionsShown(false);
    setIsRequirementsShown(false);
    setIsDefault(false);
  };

  // updates technology category with technology connections
  const updateTechCategory = () => {
    setIsTechConnectionsShown(true);
    setIsUserConnectionsShown(false);
    setIsCompConnectionsShown(false);
    setIsRequirementsShown(false);
    setIsDefault(false);
  };

  // updates technology category with technology connections
  const updateRequirementsCategory = () => {
    setIsRequirementsShown(true);
    setIsTechConnectionsShown(false);
    setIsUserConnectionsShown(false);
    setIsCompConnectionsShown(false);
    setIsDefault(false);
  };

  // updates other categories
  const updateOtherCategories = () => {
    setIsCompConnectionsShown(false);
    setIsUserConnectionsShown(false);
    setIsTechConnectionsShown(false);
    setIsRequirementsShown(false);
    setIsDefault(false);
  };

  // moves to the message section when the message button is clicked
  const SwitchToMessageSection = async (connection) => {
    // gets chat data from the chatRef embeded in each connection (only 2-way connections have chat refs)
    const chatRef = connection?.chatRef;
    const chatDoc = await chatRef?.get();
    const chatData = chatDoc?.data();
    const chatDataObj = { ...chatData, ref: chatRef };
    console.log();

    setChatObj(chatDataObj);
    setConnectionObj(connection);
    switchWindowDiscussion();
    setActiveTab("Discussions");
    setShowGroupChatSection(false);
    setShowMessageSection(true);
  };

  // moves to the message section when the message button is clicked
  const SwitchToViewsSection = async (connection) => {
    setConnectionObj(connection);
    setActiveTab("Connections");
    setIsAdminUserViewShow(true);
    setIsCompConnectionsShown(false);
    setIsUserConnectionsShown(false);
    setIsTechConnectionsShown(false);
    setIsRequirementsShown(false);
    setIsDefault(false);
  };

  // incoming requests
  useEffect(() => {
    const incomingRequests = () => {
      const currUserRef = db.doc(`users/${userId}`);
      const requestRef = db
        .collectionGroup("connections")
        .where("reciprocal", "==", false)
        .where("ref", "==", currUserRef);
      const incomingReqRes = requestRef?.onSnapshot(async (snapshot) => {
        const data = await Promise.all(
          snapshot?.docs?.map((doc) => {
            const incomingReqRef = doc?.ref;
            const incomingUserReqRef = incomingReqRef?.parent?.parent;
            const incomingReqData = doc?.data();
            return {
              ...incomingReqData,
              incomingReqRef: incomingReqRef,
              incomingUserReqRef: incomingUserReqRef,
            };
          })
        );
        setIncomingReqData(data);
      });
      return incomingReqRes;
    };
    incomingRequests();
  }, []);

  // handle search input change
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // // creates connections
  const createConnection = async (id, connectionTypesList, note) => {
    // doc references
    const userConnectionCatRef = db.doc(
      "/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/user"
    );
    const companyConnectionCatRef = db.doc(
      "/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/company"
    );
    const techConnectionCatRef = db.doc(
      "/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/Technology"
    );
    const reqConnectionCatRef = db.doc(
      "/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/requirements"
    );
    // const userRef = db.doc(`/users/${id}`);
    // const companyRef = db.doc(`/companies/${id}`);

    // array checkbox values
    let connectionTypesArr = connectionTypesList?.map((item) => {
      return db.doc(
        `/organisations/T6BAcTjwbXleCFpmWTmu/connectionTypes/${item}`
      );
    });

    // connection data
    const connectData = {
      connectedOn: new Date(),
      connectionCat:
        selectedSearchData?.key === "user"
          ? userConnectionCatRef
          : selectedSearchData?.key === "company"
          ? companyConnectionCatRef
          : selectedSearchData?.key === "tech"
          ? techConnectionCatRef
          : selectedSearchData?.key === "requirements"
          ? reqConnectionCatRef
          : null,
      connectionTypes: connectionTypesArr,
      lastUpdated: new Date(),
      ref: selectedSearchData?.ref,
      note: note,
      // reciprocal: false,
      ...(selectedSearchData?.key === "company" ||
      selectedSearchData?.key === "tech" ||
      selectedSearchData?.key === "requirements"
        ? { reciprocal: true }
        : { reciprocal: false }),
    };

    // const newConnection = await db.collection('/users/0FmYkAmfY5buv53aFEHfF3PudW23/connections').add(connectData);
    await db.collection(`/users/${userId}/connections`).add(connectData);
  };

  // creates connections
  const acceptConnection = async (id, connectionTypesList) => {
    // doc references
    const userConnectionCatRef = db.doc(
      "/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/user"
    );
    const companyConnectionCatRef = db.doc(
      "/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/company"
    );
    const techConnectionCatRef = db.doc(
      "/organisations/T6BAcTjwbXleCFpmWTmu/connectionCats/Technology"
    );

    let connectionTypesArr = connectionTypesList?.map((item) => {
      return db.doc(
        `/organisations/T6BAcTjwbXleCFpmWTmu/connectionTypes/${item}`
      );
    });

    const key = selectedData?.ref?.parent?.id;

    // connection data
    const connectData = {
      connectedOn: new Date(),
      connectionCat:
        key === "users"
          ? userConnectionCatRef
          : key === "companies"
          ? companyConnectionCatRef
          : techConnectionCatRef,
      connectionTypes: connectionTypesArr,
      lastUpdated: new Date(),
      ref: currentUserRef2,
      // note: note,
      reciprocal: true,
    };

    await db.collection(`/users/${userId}/connections`).add(connectData);

    if (selectedData.sentRequestRef) {
      const acceptData = {
        reciprocal: true,
      };
      selectedData.sentRequestRef.set(acceptData, { merge: true });
    }
  };

  // closes search dropdown when clicked outside
  const closeSearchDropdown = () => {
    setSearchTerm("");
  };
  document.addEventListener("mousedown", closeSearchDropdown);

  // delete all docs in msg sub-collection
  const deleteMessages = (path) => {
    const ref = db.collection(path);
    const unsub = ref.onSnapshot((snapshot) => {
      snapshot?.docs?.forEach(async (doc) => {
        ref.doc(doc.id).delete();
        // await doc?.id?.delete();
        // console.log('doc', doc);
      });
    });
    unsub();
  };

  // disconnects connected users/companies
  const disconnect = async (ref) => {
    // deletes a connection
    await db.doc(ref)?.delete();
    // deletes group chats
    await db.doc(chatObj?.ref?.path)?.delete();
    // deletes all messages in msg sub collection
    deleteMessages(`${chatObj?.ref?.path}/msg`);

    // empties the group chat member array
    // while (chatObj?.members > 0) {
    //   chatObj?.members.pop();
    // }
    if (chatObj?.members > 0) {
      chatObj?.members.pop();
    }
  };

  // shows the previous category/slide
  const previous = () => {
    const isFirstCategory = currentIndex === 0;
    const newIndex = isFirstCategory
      ? categories?.length - 1
      : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  // shows the next category/slide
  const next = () => {
    const isLastCategory = currentIndex === categories?.length - 1;
    const newIndex = isLastCategory ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  // notification frequency
  useEffect(() => {
    const getNotificationFrequency = async () => {
      const notificationFreqSnapshot = await db
        .collection("/organisations/T6BAcTjwbXleCFpmWTmu/notificationFrequency")
        .orderBy("rank", "asc")
        .get();
      const notificationFreqData = [];
      notificationFreqSnapshot?.docs?.map((doc) => {
        const frequencyData = doc?.data();
        const ref = doc?.ref;
        const frequencyDataObj = { ...frequencyData, ref: ref };
        notificationFreqData.push(frequencyDataObj);
      });
      setNotificationFreq(notificationFreqData);
    };
    getNotificationFrequency();
  }, []);

  // notification channels
  useEffect(() => {
    const getNotificationChannelData = async () => {
      const notificationChannelSnapshot = await db
        .collection("/organisations/T6BAcTjwbXleCFpmWTmu/notificationChannels")
        .get();
      const notificationChannelData = [];
      notificationChannelSnapshot?.docs?.map(async (doc) => {
        const channelData = doc?.data();
        const ref = doc?.ref;
        const ChannelDataObj = {
          ...channelData,
          ref: ref,
        };
        notificationChannelData.push(ChannelDataObj);
      });
      setNotificationChannel(notificationChannelData);
    };
    getNotificationChannelData();
  }, []);

  // updates window size when the window size is changed
  useEffect(() => {
    const resizeWindow = () => setWindowSize(window.innerWidth);
    window.addEventListener("resize", resizeWindow); // Update the width on resize
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  // email invite features pops at 3 days intervals
  useEffect(() => {
    const interval = setInterval(() => {
      setShowInviteUser(true);
      // displays the email invite dismiss button
      setShowInviteDismissBtn(true);
    }, 259200000);
    return () => clearInterval(interval);
  }, []);

  // pulls down the options' dropdown and updates the chatObj state var
  const updateOptionsData = async (connection) => {
    // gets chat data from the chatRef embeded in each connection (only 2-way connections have chat refs)
    const chatRef = connection?.chatRef;
    const chatDoc = await chatRef?.get();
    const chatData = chatDoc?.data();
    const chatDataObj = { ...chatData, ref: chatRef };

    setChatObj(chatDataObj);
    setIsOptions((prev) => !prev);
    setCurrConnectionId(connection?.id);
    setConnectionObj(null);
  };

  // it tests for all ascii characters
  const isAsciiOnly = (str) => {
    for (let i = 0; i < str?.length; i++)
      if (str?.charCodeAt(i) > 127) return true;
    return false;
  };

  return (
    <>
      {visible && (
        <div className="conn-center-div transition-6">
          <div className="connection_main">
            <div className="search_box">
              {!isAdminUserViewShow && !isNestria && (
                <SearchBox
                  handleChange={handleChange}
                  searchData={searchData}
                  emu={emu}
                  truncateString={truncateString}
                  userId={userId}
                  userConnections={userConnections}
                  companyConnections={companyConnections}
                  techConnections={techConnections}
                  requirementsConnections={requirementsConnections}
                  setIsConnectModal={setIsConnectModal}
                  selectedSearchData={selectedSearchData}
                  setSelectedSearchData={setSelectedSearchData}
                  connectTypes={connectTypes}
                  setConnectTypes={setConnectTypes}
                  searchTerm={searchTerm}
                  incomingReqData={incomingReqData}
                  db={db}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  acceptConnectTypes={acceptConnectTypes}
                  setAcceptConnectTypes={setAcceptConnectTypes}
                  setCurrentUserRef2={setCurrentUserRef2}
                  acceptConnection={acceptConnection}
                  switchWindowDiscussion={switchWindowDiscussion}
                  setActiveTab={setActiveTab}
                  parent="ConnectionWindow"
                  setChatObj={setChatObj}
                />
              )}
            </div>

            {!isAdminUserViewShow && !isNestria && (
              <Categories
                categories={categories}
                windowSize={windowSize}
                updatePeopleCategory={updatePeopleCategory}
                updateCompanyCategory={updateCompanyCategory}
                updateTechCategory={updateTechCategory}
                updateRequirementsCategory={updateRequirementsCategory}
                updateOtherCategories={updateOtherCategories}
                isDefault={isDefault}
                isUserConnectionsShown={isUserConnectionsShown}
                isCompConnectionsShown={isCompConnectionsShown}
                isTechConnectionsShown={isTechConnectionsShown}
                isRequirementsShown={isRequirementsShown}
                userConnections={userConnections}
                companyConnections={companyConnections}
                techConnections={techConnections}
                requirementsConnections={requirementsConnections}
                isAdmin={isAdmin}
                isAdmin2={isAdmin2}
              />
            )}
            {/* mobile categories bar commented out since the categories are conditionally renderd based on the size of the window */}
            {/* <div className="mobile_categories_bar">
            <div
              // className="bn1"
              className={category === 'People' || category === 'Companies' || category === 'Technologies' ? 'bn1' : 'none'}
              onClick={() => {
                if (categories[currentIndex] === 'People') {
                  updatePeopleCategory();
                } else if (categories[currentIndex] === 'Companies') {
                  updateCompanyCategory();
                } else if (categories[currentIndex] === 'Technologies') {
                  updateTechCategory();
                }
                else {
                  updateOtherCategories();
                }
              }}
            >
              // slider using prev & next buttons commented out
              {categories[currentIndex] === 'People' ?
                userConnections?.length !== undefined ? categories[currentIndex] + ' ' + userConnections?.length : categories[currentIndex]
                :
                categories[currentIndex] === 'Companies' ?
                companyConnections?.length !== undefined ? categories[currentIndex] + ' ' + companyConnections?.length : categories[currentIndex]
                :
                categories[currentIndex] === 'Technologies' ?
                techConnections?.length !== undefined ? categories[currentIndex] + ' ' + techConnections?.length : categories[currentIndex]
                :
                categories[currentIndex]
              }
            </div>
            // prev & next buttons for sliding categories based on index commented out
            <div
              className='prev_container'
              onClick={previous}
            >
              <PrevArrow
                width={20}
                height={20}
                fill='grey'
              />
            </div>
            <div
              className='next_container'
              onClick={next}
            >
              <NextArrow
                width={20}
                height={20}
                fill='grey'
              />
            </div>
          </div> */}

            {(isUserConnectionsShown || isDefault) && 
              (isNestria ?
                <NestriaUserConnections
                  userConnections={userConnections}
                  switchWindowDiscussion={switchWindowDiscussion}
                  firebase={firebase}
                  db={db}
                  loading={loading}
                  disconnect={disconnect}
                  setActiveTab={setActiveTab}
                  truncateString={truncateString}
                  userId={userId}
                  notificationFreq={notificationFreq}
                  notificationChannel={notificationChannel}
                  windowSize={windowSize}
                  setChatObj={setChatObj}
                  SwitchToMessageSection={SwitchToMessageSection}
                  currConnectionId={currConnectionId}
                  showTruncatedBio={showTruncatedBio}
                  setShowTruncatedBio={setShowTruncatedBio}
                  isOptions={isOptions}
                  setIsOptions={setIsOptions}
                  isDisconnectModal={isDisconnectModal}
                  setIsDisconnectModal={setIsDisconnectModal}
                  updateOptionsData={updateOptionsData}
                  isAsciiOnly={isAsciiOnly}
                  isNestria={isNestria}
                  SwitchToViewsSection={SwitchToViewsSection}
                /> :
              <UserConnections
                userConnections={userConnections}
                switchWindowDiscussion={switchWindowDiscussion}
                firebase={firebase}
                db={db}
                loading={loading}
                disconnect={disconnect}
                setActiveTab={setActiveTab}
                truncateString={truncateString}
                userId={userId}
                notificationFreq={notificationFreq}
                notificationChannel={notificationChannel}
                windowSize={windowSize}
                setChatObj={setChatObj}
                SwitchToMessageSection={SwitchToMessageSection}
                currConnectionId={currConnectionId}
                showTruncatedBio={showTruncatedBio}
                setShowTruncatedBio={setShowTruncatedBio}
                isOptions={isOptions}
                setIsOptions={setIsOptions}
                isDisconnectModal={isDisconnectModal}
                setIsDisconnectModal={setIsDisconnectModal}
                updateOptionsData={updateOptionsData}
                isAsciiOnly={isAsciiOnly}
                isNestria={isNestria}
                SwitchToViewsSection={SwitchToViewsSection}
              />
            )}
            {isCompConnectionsShown && (
              <CompanyConnections
                companyConnections={companyConnections}
                switchWindowDiscussion={switchWindowDiscussion}
                loading={loading}
                disconnect={disconnect}
                truncateString={truncateString}
                setActiveTab={setActiveTab}
              />
            )}
            {isTechConnectionsShown && (
              <TechConnections techConnections={techConnections} />
            )}
            {isRequirementsShown && (
              <Requirements
                requirementsConnections={requirementsConnections}
                windowSize={windowSize}
                truncateString={truncateString}
                currConnectionId={currConnectionId}
                showTruncatedBio={showTruncatedBio}
                setShowTruncatedBio={setShowTruncatedBio}
                SwitchToMessageSection={SwitchToMessageSection}
                isOptions={isOptions}
                setIsOptions={setIsOptions}
                disconnect={disconnect}
                isDisconnectModal={isDisconnectModal}
                setIsDisconnectModal={setIsDisconnectModal}
                updateOptionsData={updateOptionsData}
                isAsciiOnly={isAsciiOnly}
                db={db}
                userId={userId}
              />
            )}

            {isAdminUserViewShow && (
              <AdminUserView
                db={db}
                setIsAdminUserViewShow={setIsAdminUserViewShow}
                setActiveTab={setActiveTab}
                setIsCompConnectionsShown={setIsCompConnectionsShown}
                setIsUserConnectionsShown={setIsUserConnectionsShown}
                setIsTechConnectionsShown={setIsTechConnectionsShown}
                setIsRequirementsShown={setIsRequirementsShown}
                setIsDefault={setIsDefault}
                currentUserObj={currentUserObj}
                firebase={firebase}
              />
            )}
          </div>
          {/* connect modal */}
          {isConnectModal && (
            <ConnectModal
              closeModal={() => setIsConnectModal(false)}
              createConnection={createConnection}
              db={db}
              selectedSearchData={selectedSearchData}
              connectTypes={connectTypes}
            />
          )}
          {/* email invite user component */}
          {showInviteUser && (
            <div className="email_invite_wrapper">
              <EmailInvites
                db={db}
                currentUserObj={currentUserObj}
                setShowInviteUser={setShowInviteUser}
                showInviteDismissBtn={showInviteDismissBtn}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ConnectionWindow;
