import React from "react";

const LocationIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.41669 13.9414L8.11705 13.4216H8.11705L8.41669 13.9414ZM7.5807 13.9414L7.28107 14.4612L7.28107 14.4612L7.5807 13.9414ZM12.0654 7.79134C12.0654 9.29085 11.3807 10.5272 10.5225 11.4903C9.66261 12.4554 8.66055 13.1083 8.11705 13.4216L8.71633 14.4612C9.31808 14.1144 10.4403 13.3864 11.4185 12.2886C12.3984 11.1888 13.2654 9.68025 13.2654 7.79134H12.0654ZM7.9987 3.43301C10.2109 3.43301 12.0654 5.34951 12.0654 7.79134H13.2654C13.2654 4.75635 10.9411 2.23301 7.9987 2.23301V3.43301ZM3.93203 7.79134C3.93203 5.34951 5.78649 3.43301 7.9987 3.43301V2.23301C5.05625 2.23301 2.73203 4.75635 2.73203 7.79134H3.93203ZM7.88034 13.4216C7.33685 13.1083 6.33479 12.4554 5.47488 11.4903C4.61674 10.5272 3.93203 9.29085 3.93203 7.79134H2.73203C2.73203 9.68025 3.59904 11.1889 4.57893 12.2886C5.55705 13.3864 6.67932 14.1144 7.28107 14.4612L7.88034 13.4216ZM8.11705 13.4216C8.04176 13.465 7.95564 13.465 7.88034 13.4216L7.28107 14.4612C7.72728 14.7184 8.27012 14.7184 8.71633 14.4612L8.11705 13.4216ZM9.3987 7.79134C9.3987 8.66836 8.73815 9.31634 7.9987 9.31634V10.5163C9.46839 10.5163 10.5987 9.26153 10.5987 7.79134H9.3987ZM7.9987 6.26634C8.73815 6.26634 9.3987 6.91432 9.3987 7.79134H10.5987C10.5987 6.32115 9.46839 5.06634 7.9987 5.06634V6.26634ZM6.5987 7.79134C6.5987 6.91432 7.25925 6.26634 7.9987 6.26634V5.06634C6.52901 5.06634 5.3987 6.32115 5.3987 7.79134H6.5987ZM7.9987 9.31634C7.25925 9.31634 6.5987 8.66836 6.5987 7.79134H5.3987C5.3987 9.26153 6.52901 10.5163 7.9987 10.5163V9.31634Z"
        fill="#808080"
      />
    </svg>
  );
};

export default LocationIcon;
