import React from 'react';

const ErrorMsgModal = ({
  errorMsg,
  isErrorMsgModal,
  setIsErrorMsgModal,
  setErrorMsg,
  isTyping
}) => {
  return (
    <>
      <div className={`error_msg_container${isErrorMsgModal && isTyping ? ' shake' : ''}`}>
        <p
          className='close_err_msg_modal'
          onClick={() => {
            setIsErrorMsgModal(false);
            setErrorMsg('');
          }}
        >
          X
        </p>
        <p className='err_msg'>{errorMsg}</p>
        <div
          onClick={() => {
            setIsErrorMsgModal(false);
            setErrorMsg('');
          }}
          >
            Ok
          </div>
      </div>
    </>
  );
};

export default ErrorMsgModal;
