import React, { useContext, useEffect, useState } from "react";
import emu from "../../static/emu.jpg";
import NewGroupModal from "../modal/NewGroupModal";
import OratioContext from "../../oratioContext/OratioContext";
import ChatGroupSelectors from "../chatGroupSelectors/ChatGroupSelectors";
import MyChats from "../myChats/MyChats";
import PublicChats from "../publicChats/PublicChats";
import AllChats from "../allChats/AllChats";
import AdminUserView from "../nestria/Admin/AdminUserView";

const ChatGroup = ({
  chats,
  chatObj,
  userId,
  setChatObj,
  allChats,
  myDiscussion,
  setMyDiscussion,
  allDiscussion,
  setAllDiscussion,
  pubDiscussion,
  setPubDiscussion,
  isAdmin,
  isAdmin2,
  truncateString,
  setAddUserBtn,
  setShowGroupChatSection,
  setShowMessageSection,
  optionsModal,
  updateReadReceipts,
  nextUserData,
  setNextUserRef,
  unreadMsgs,
  setIsScrolledUp,
  db,
  newGroupModal,
  setNewGroupModal,
  isCreateGroup,
  setIsCreateGroup,
  isAddChannel,
  setIsAddChannel,
  isEditChatGroup,
  setIsEditChatGroup,
  firebase,
  windowSize,
  isNestria,
}) => {
  const [showMore, setShowMore] = useState({});
  const [isPublic, setIsPublic] = useState(chatObj?.public);

  const { searchTerm, setSearchTerm } = useContext(OratioContext);

  // highlights selected chat group from my discussions section
  // moves to the message/conversation section
  const selectChatGroup = (chat) => {
    setChatObj(chat);
    setAddUserBtn(true);
    setShowGroupChatSection(false);
    setShowMessageSection(true);
  };

  // highlights selected chat group from all discussions section
  // moves to the message/conversation section
  const selectAllChatGroup = (chat) => {
    setChatObj(chat);
    setAddUserBtn(false);
    setShowGroupChatSection(false);
    setShowMessageSection(true);
  };

  // adds next user chat data array to all chat objects
  // adds unreadMsgs array to only chat object(s) with unread msgs
  const myChats = chats?.map((chat) => {
    const otherUserData = nextUserData.flat(2);
    // checks if an unread msg chat ref (chats/chatId in chats/chatId/msg/msgId) equals a chat group id
    // this ensures that only the chat object(s) with unread msgs have unreadMsgs array
    const matchingUnreadMsgs = unreadMsgs.filter(
      (msg) => msg.chatGroupRef?.id === chat?.chatsData?.ref?.id
    );
    if (matchingUnreadMsgs.length > 0) {
      // returns chat obj, other users' data, & unread msgs if there're matching unread msgs
      return {
        chat: chat.chatsData,
        otherUserData,
        unreadMsgs: matchingUnreadMsgs,
      };
    } else {
      // returns only chat obj & other users' data if there're no matching unread msgs
      return { chat: chat.chatsData, otherUserData };
    }
  });

  // handles `See more`/`See less`  feature for channels > 5
  const handleShowMore = (idx) => {
    setShowMore((prev) => ({
      ...prev,
      [idx]: !prev[idx],
    }));
  };

  // adds next user chat data array to all chats array
  const allMyChats = allChats?.map((allChat) => {
    const otherUserData = nextUserData.flat(2);
    return { allChat: allChat?.allChatsObj, otherUserData };
  });

  // public chats
  const publicChats = allMyChats?.filter(
    (chat) => chat?.allChat?.public === true
  );

  useEffect(() => {
    setIsPublic(chatObj?.public);

    if (isCreateGroup && isPublic) {
      setIsPublic(false); // set isPublic to false only if isCreateGroup is true && isPublic is true
    }
  }, [chatObj, isCreateGroup]);

  const launchNewGroupModal = () => {
    setNewGroupModal(true);
    setIsCreateGroup(true);
    setIsAddChannel(false);
    setIsEditChatGroup(false);
  };

  return (
    <>
      {isNestria ? (
        <>
          {" "}
          <AdminUserView />
        </>
      ) : (
        <>
          <ChatGroupSelectors
            isAdmin={isAdmin}
            isAdmin2={isAdmin2}
            myDiscussion={myDiscussion}
            setMyDiscussion={setMyDiscussion}
            pubDiscussion={pubDiscussion}
            setPubDiscussion={setPubDiscussion}
            allDiscussion={allDiscussion}
            setAllDiscussion={setAllDiscussion}
            chats={chats}
            publicChats={publicChats}
            allChats={allChats}
          />
          <MyChats
            myDiscussion={myDiscussion}
            myChats={myChats}
            selectChatGroup={selectChatGroup}
            updateReadReceipts={updateReadReceipts}
            setNextUserRef={setNextUserRef}
            setIsScrolledUp={setIsScrolledUp}
            chatObj={chatObj}
            truncateString={truncateString}
            userId={userId}
            setChatObj={setChatObj}
            setAddUserBtn={setAddUserBtn}
            setShowGroupChatSection={setShowGroupChatSection}
            setShowMessageSection={setShowMessageSection}
            optionsModal={optionsModal}
            handleShowMore={handleShowMore}
            showMore={showMore}
            windowSize={windowSize}
            db={db}
          />
          <PublicChats
            pubDiscussion={pubDiscussion}
            publicChats={publicChats}
            selectChatGroup={selectChatGroup}
            updateReadReceipts={updateReadReceipts}
            setIsScrolledUp={setIsScrolledUp}
            chatObj={chatObj}
            truncateString={truncateString}
            windowSize={windowSize}
            userId={userId}
          />
          <AllChats
            isAdmin={isAdmin}
            isAdmin2={isAdmin2}
            allDiscussion={allDiscussion}
            allMyChats={allMyChats}
            selectAllChatGroup={selectAllChatGroup}
            chatObj={chatObj}
            truncateString={truncateString}
            windowSize={windowSize}
          />
          <div className="new_group_btn" onClick={launchNewGroupModal}>
            <button>Create a new Group</button>
          </div>

          {/* create new group modal */}
          {newGroupModal && (
            <NewGroupModal
              header={
                isCreateGroup
                  ? "Create Group"
                  : isAddChannel
                  ? "Add Channel"
                  : "Edit Group"
              }
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              truncateString={truncateString}
              setNewGroupModal={setNewGroupModal}
              db={db}
              userId={userId}
              emu={emu}
              chatObj={chatObj}
              firebase={firebase}
              isCreateGroup={isCreateGroup}
              setIsCreateGroup={setIsCreateGroup}
              isAddChannel={isAddChannel}
              setIsAddChannel={setIsAddChannel}
              isEditChatGroup={isEditChatGroup}
              setIsEditChatGroup={setIsEditChatGroup}
              isPublic={isPublic}
              //parentChatGroup={parentChatGroup}
            />
          )}
        </>
      )}
    </>
  );
};

export default ChatGroup;
