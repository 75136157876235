import React from 'react';

const TimeSheetDownArrow = ({ width, height, }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9719_27068)">
        <path
          d="M34.534 8.41904C33.9128 7.79785 32.9055 7.79774 32.2842 8.41915L17.5004 23.2033L2.71584 8.41905C2.09464 7.79785 1.08728 7.79775 0.465977 8.41916C-0.155326 9.04046 -0.155325 10.0477 0.465978 10.669L16.3755 26.5781C16.6738 26.8765 17.0785 27.044 17.5004 27.044C17.9223 27.044 18.327 26.8764 18.6252 26.578L34.5339 10.6689C35.1553 10.0477 35.1553 9.04035 34.534 8.41904Z"
          fill="#4361E9"
        />
      </g>
      <defs>
        <clipPath id="clip0_9719_27068">
          <rect
            width="35"
            height="35"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TimeSheetDownArrow;