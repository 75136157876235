import { useEffect, useRef, useState } from "react";

export default function CountdownTimer({
  seconds,
  startTimer,
  resetTimer,
  setStartRecording,
}) {
  const intervalId = useRef(null);
  const minutesRemaining = useRef(0);
  const secondsRemaining = useRef(0);
  const timeRemaining = useRef(seconds);
  const [renderTime, setRenderTime] = useState(undefined);
  const [formattedMinutes, setFormattedMinutes] = useState(0);
  const [formattedSeconds, setFormattedSeconds] = useState(0);

  useEffect(() => {
    timeRemaining.current = seconds;
    setTime(seconds);
  }, []);

  useEffect(() => {
    timeRemaining.current = seconds;
    setTime(seconds);
  }, [resetTimer]);

  useEffect(() => {
    if (timeRemaining.current !== undefined) {
      setTime(timeRemaining.current);
    }
  }, [renderTime]);

  useEffect(() => {
    if (startTimer === true) {
      timeRemaining.current = seconds;

      intervalId.current = setInterval(() => {
        if (timeRemaining.current - 1 <= 0) {
          // End timer
          updateTimeRemaining(0);
          setStartRecording(false);
        } else {
          updateTimeRemaining(timeRemaining.current - 1);
        }
      }, 1000); // Updates every 1 second
    } else {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    }
  }, [startTimer]);

  function updateTimeRemaining(remainingSeconds) {
    timeRemaining.current = remainingSeconds;
    setRenderTime(new Date()); // Set any random value - this will update the UI
  }

  function addZeroForSingleDigit(digit) {
    if (digit <= 9) {
      return `0${digit}`;
    } else {
      return `${digit}`;
    }
  }

  function setTime(seconds) {
    if (seconds > 59) {
      minutesRemaining.current = parseInt(seconds / 60);
      secondsRemaining.current = parseInt(seconds % 60);
    } else {
      minutesRemaining.current = 0;
      secondsRemaining.current = seconds;
    }
    setFormattedMinutes(addZeroForSingleDigit(minutesRemaining.current));
    setFormattedSeconds(addZeroForSingleDigit(secondsRemaining.current));
  }

  return (
    <>
      <div
        style={{
          fontSize: "20px",
          fontWeight: "600",
          color: "grey",
        }}
      >
        {formattedMinutes}:{formattedSeconds}
      </div>
    </>
  );
}
