import { useContext, useEffect, useRef, useState } from "react";
import Spinner from "../../spinner/Spinner";
import Astronaut from "../../../static/nestria/astronaut.webp";
import TextToSpeechPlayer from "../TextToSpeech/Player";
import Controls from "./Controls";
import VideoOffBackground from "../VideoRecorder/VideoOffBackground";
import { likeASubmission, unLikeASubmission } from "../helpers/store";
import { FirebaseContext } from "../Context/Nestria";

export default function AudioAnswer({
    currentUserId,
    submissionDocRef,
    question,
    url,
    currentIndex,
    index,
    userName,
    score,
    likeCount,
    retakePreviousQuestion,
    setIsFeedbackPopupOpen,
    setShowUserAnswers,
    audioContentBase64,
    correctAnswer
}) {

    const firebase = useContext(FirebaseContext);
    const [showLoader, setShowLoader] = useState(true);
    const [isGPTAnswer, setIsGPTAnswer] = useState();
    const [isLikedByThisUser, setIsLikedByThisUser] = useState();
    const [_likeCount, setLikeCount] = useState(likeCount || 0);
    const containerRef = useRef();

    const audioRef = useRef();

    useEffect(() => {
        if (audioContentBase64 || url) {
            setShowLoader(false);
            if (currentIndex === index) {
                audioRef.current.play();
            } else {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        } else {
            setShowLoader(true);
        }
    }, [audioContentBase64, url, currentIndex, index])

    useEffect(() => {
        console.log(`likeCount: `, likeCount);
        const unsubscribeLikeCount = submissionDocRef?.onSnapshot(async (snapshot) => {
            console.log("snapshot change...");
            const likedUsers = await snapshot.get("likedUsers") || [];
            console.log(likedUsers);

            setLikeCount(likedUsers?.length || 0);

            if (likedUsers?.indexOf(currentUserId) !== -1) {
                setIsLikedByThisUser(true);
            } else {
                setIsLikedByThisUser(false);
            }

        });

        return () => {
            if (typeof (unsubscribeLikeCount) === 'function') {
                unsubscribeLikeCount();
                console.log(`closing like count listener`);
            }
        }
    }, [submissionDocRef]);

    useEffect(() => {
        if (userName === 'Nestria') {
            setIsGPTAnswer(true);
        } else {
            setIsGPTAnswer(false);
        }
    }, []);

    useEffect(() => {
        if (isGPTAnswer) {
            containerRef.current.style.backgroundImage = `url(${Astronaut})`;
        } else {
            containerRef.current.style.backgroundImage = ``;
        }
    }, [isGPTAnswer]);

    function playHandler() {
        console.log(audioRef.current.paused);
        console.log('playing')
        audioRef.current.play();
    }

    function pauseHandler() {
        console.log(audioRef.current.paused);
        audioRef.current.pause();
    }

    function divClickHandler() {
        if (audioRef.current.paused === true) {
            playHandler();
        } else {
            pauseHandler();
        }
    }

    function likeHandler() {
        console.log("like clicked");

        if (isLikedByThisUser) {
            unLikeASubmission(firebase, submissionDocRef, currentUserId);
        } else {
            likeASubmission(firebase, submissionDocRef, currentUserId);
        }
    }

    return (
        <>
            <div ref={containerRef} style={{ display: "flex", backgroundRepeat: 'no-repeat', backgroundPosition: "center", backgroundSize: "contain" }} onClick={divClickHandler} className="audio-answer">

                {showLoader &&
                    <div style={{ height: "100vh", width: "100vw", justifyContent: "center", display: "flex", position: "absolute" }} className="audio-answer">
                        <Spinner width="40px" height="40px" />
                    </div>
                }

                <div style={{ height: "100vh", width: "100vw" }}>
                    {isGPTAnswer ||
                        <div style={{ position: "absolute", height: "100vh", width: "100vw" }} className="audio-answer">
                            <VideoOffBackground userObj={{ "first": userName?.split(" ")[0], "last": userName?.split(" ")[1] }} />
                        </div>
                    }
                    <TextToSpeechPlayer audioRef={audioRef} audioContentBase64={audioContentBase64} url={url} />
                </div>

                {/* Gradient Overlay */}
                <div style={{ position: "absolute", height: "100vh", width: "100vw", background: isGPTAnswer ? "linear-gradient(rgb(255 255 255 / 0%) 0%, #00000082 100%)" : "black" }} className="audio-answer">
                    {correctAnswer &&
                        <div style={{ height: "100vh", alignContent: "center", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                            <span style={{ background: "rgb(128 128 128 / 70%)", padding: "20px", borderRadius: "10px", cursor: "pointer" }} className="correct-answer">
                                <span style={{ color: "white", fontSize: "16px", fontWeight: "600", display: "block", textAlign: "center", maxWidth: "70vw" }}>
                                    {correctAnswer}
                                </span>
                            </span>
                        </div>
                    }
                    <div style={{ position: "absolute", width: "100vw", bottom: "8vh", color: "white", textAlign: "left", padding: "20px", fontWeight: "bold" }} className="audio-answer">
                        <h4>{question}</h4>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            {userName &&
                                <p style={{ marginTop: "10px", fontWeight: "100", fontSize: "16px" }}>By {userName}</p>
                            }

                            {score &&
                                <p style={{ marginTop: "10px", fontWeight: "100", fontSize: "16px" }}>Scored: {score}</p>
                            }
                        </div>

                        {/* Controls */}
                        <Controls
                            likeHandler={likeHandler}
                            flagHandler={() => setIsFeedbackPopupOpen(true)}
                            retakeHandler={() => { retakePreviousQuestion(); setShowUserAnswers(false); }}
                            likeCount={_likeCount}
                            isLiked={isLikedByThisUser}
                            hideLike={isGPTAnswer ? true : false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}