import Spinner from "../../../spinner/Spinner";
import CheckButton from "./CheckButton";
import CountdownTimer from "./CountdownTimer";
import RecordButton from "./RecordButton";
import RedoButton from "./RedoButton";
import VideoToggle from "./VideoToggle";


export function VideoControls({recordingComplete, isCameraStreamReady, isAudioToTextReady, startRecording, setRecordingConfirmed, setStartRecording, maxVideoAnswerLengthInSeconds, reset, setReset, show, disable, isVideoOn, setIsVideoOn, hideVideoToggle}) {

    if (!show) {
        return (<></>);
    }
    if (show === true) {
        return (
            <div className="bottom-controls-div">
                <>
                    <RedoButton
                        onClickHandler={() => {
                        // resetStates();
                        setReset(new Date()); // Set any random value to reset
                        }}
                        show={recordingComplete}
                        isDisabled={disable}
                    />

                    <RecordAndCountdownTimer 
                        isCameraStreamReady={isCameraStreamReady}
                        isAudioToTextReady={isAudioToTextReady}
                        startRecording={startRecording}
                        recordingComplete={recordingComplete}
                        setStartRecording={setStartRecording}
                        maxVideoAnswerLengthInSeconds={maxVideoAnswerLengthInSeconds}
                        reset={reset}
                        isVideoOn = {isVideoOn}
                        setIsVideoOn = {setIsVideoOn}
                        hideVideoToggle = {hideVideoToggle}

                    />

                    <CheckButton
                        onClickHandler={() => {
                        setRecordingConfirmed(true);
                        }}
                        show={recordingComplete}
                        isDisabled={disable}
                    />
                    </>
            </div>
        )
    }
}


function RecordAndCountdownTimer({isCameraStreamReady, isAudioToTextReady, startRecording, recordingComplete, setStartRecording, maxVideoAnswerLengthInSeconds, reset, isVideoOn, setIsVideoOn, hideVideoToggle}) {
  
    if (isCameraStreamReady && (isAudioToTextReady || startRecording === false)) {
        return (
            <div
                style={{
                    display: recordingComplete ? "none" : "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                    position:"relative",
                }}
            >

                {hideVideoToggle === false && <VideoToggle
                   show={startRecording === false || startRecording === undefined} // Show this control only when recording is not started
                   isVideoOn={isVideoOn}
                   setIsVideoOn = {setIsVideoOn}
                />}

                <RecordButton
                    setStartRecording={setStartRecording}
                    isRecording={startRecording}
                />
                <CountdownTimer
                    seconds={maxVideoAnswerLengthInSeconds}
                    startTimer={startRecording}
                    stopTimer={!startRecording}
                    resetTimer={reset}
                    setStartRecording={setStartRecording}
                />
            </div>
        )
    } else {
        return (
            <Spinner width="2rem" height="2rem"/>
        )
    }
}
