import React, { useEffect, useState } from "react";
import CreateUnits from "./CreateUnits";
import noAvatar from "../../../static/no-avatar.png";
import NotificationIcon from "../../svgs/NotificationIcon";
import OptionsIcon from "../../svgs/OptionsIcon";
import Spinner from "../../spinner/Spinner";
import { usePapaParse } from "react-papaparse";
import { deleteUnitsFromDb } from "../helpers/store";

const Units = ({ db, currentUser }) => {
  const [isCreateUnitsOpen, setCreateUnitsOpen] = useState(false);
  const [unitsStack, setUnitsStack] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [isViewUnitsOpen, setViewUnitsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isdataimported, setIsDataImported] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [showMessage, setShowMessage] = useState(false);

  const { readString } = usePapaParse();

  useEffect(() => {
    const getQuestionsStack = async () => {
      try {
        const unitSet = new Set(); // Set to store unique unit names
        const unitList = []; // Array to store the units

        const nestriaCatalogStack = db?.collection(`catalog`);
        const catalogStack = await nestriaCatalogStack?.get();

        catalogStack?.forEach(async (catalogDoc) => {
          try {
            const catalogData = catalogDoc?.data();
            const catalogId = catalogDoc?.id;

            const unitsSubcollection = await catalogDoc?.ref
              ?.collection("units")
              ?.get();

            await processUnitsSubcollection(
              unitsSubcollection,
              unitList,
              unitSet,
              catalogId
            );
            setUnitsStack([]);
            setUnitsStack((prevUnitList) =>
              (prevUnitList ?? []).concat(unitList)
            );
            if (unitList !== undefined) {
              setLoading(false);
            }
          } catch (error) {
            console.error("Error accessing units subcollection", error);
          }
        });
      } catch (error) {
        console.error("Error retrieving units", error);
      }
    };

    const processUnitsSubcollection = async (
      subcollection,
      unitList,
      unitSet,
      catalogId
    ) => {
      await Promise.all(
        subcollection?.docs.map(async (unitDoc) => {
          try {
            const unitData = unitDoc?.data();
            const unitName = unitData?.name;

            // Check if the unit name is already in the set
            if (!unitSet.has(unitName)) {
              unitSet.add(unitName);

              const questionsSubcollection = await unitDoc?.ref
                ?.collection("questions")
                ?.get();

              const questionList = await Promise.all(
                questionsSubcollection.docs.map(async (questionDoc) => {
                  try {
                    const questionData = questionDoc?.data();
                    const questionReference = questionData?.ref;

                    const questionSnapshot = await questionReference?.get();
                    const questionName = questionSnapshot?.data()?.question;
                    return {
                      question: questionName,
                      index: questionData?.index,
                      xp: questionData?.xp,
                      reference: questionData?.ref,
                    };
                  } catch (error) {
                    console.error("Error processing question document", error);
                    return null;
                  }
                })
              );

              unitList.push({
                name: unitName,
                desc: unitData?.desc,
                questions: questionList.filter((question) => question !== null),
                avatar: noAvatar,
                unitReference: unitDoc?.ref,
                catalogId: catalogId,
              });
            }
          } catch (error) {
            console.error("Error processing unit document", error);
          }
        })
      );
    };
    getQuestionsStack();
  }, [isCreateUnitsOpen, isViewUnitsOpen, isdataimported]);

  if (unitsStack?.length > 0) {
    unitsStack.sort((a, b) => a.name.localeCompare(b.name));
  }

  const createUnit = () => {
    setMessage("");
    setShowMessage(false);
    setCreateUnitsOpen(true);
  };

  const viewUnits = (unit) => {
    console.log("unit", unit);
    setMessage("");
    setShowMessage(false);
    setSelectedUnit(unit);
    setViewUnitsOpen(true);
  };

  const handleFileUpload = async (event) => {
    setMessage("");
    setShowMessage(false);
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async () => {
      try {
        setLoading(true);
        const csvData = reader.result;

        const parsedData = await processCSV(csvData);
        console.log(`parsedData...`);
        console.log(parsedData);

        await createUnitsAndQuestionsFromImport(parsedData);

        console.log("Data saved to the database successfully!");

        setMessage("Data imported successfully!");
        setIsDataImported(true);
      } catch (error) {
        console.error("Error saving data to the database:", error);

        setMessage(
          error?.message
            ? error?.message
            : "Error importing data. Please try again."
        );
        setIsDataImported(false);
      } finally {
        setShowMessage(true);
        setLoading(false);
      }
    };

    reader.readAsText(file);
  };

  /**
   * Creates Unit and Question docs in database
   * from the imported data.
   * @param {Array} parsedData
   * @returns Promise
   */
  const createUnitsAndQuestionsFromImport = async (parsedData) => {
    try {
      if (!Array.isArray(parsedData)) {
        throw Error(`parsedData must be an array. Received ${parsedData}.`);
      }

      const questionRefs = await createAllQuestions(parsedData);

      console.log(`All question refs: `);
      console.log(questionRefs);

      for (let i = 0; i < parsedData?.length; i++) {
        const unitName = parsedData[i]?.unit;

        // Check if a unit exists with this name
        const existingUnitSnapshot = await getUnitByUnitName(unitName);

        let unitRef;

        if (!existingUnitSnapshot?.empty) {
          unitRef = existingUnitSnapshot?.docs[0]?.ref;
        } else {
          unitRef = await db.collection("catalog/DEFAULT/units")?.add({
            name: parsedData[i].unit,
            desc: parsedData[i].unitdesc,
          });
        }

        console.log("unitRef", unitRef);

        if (unitRef) {
          console.log(`Question Index: ${i}`);
          console.log(`Question: ${parsedData[i]?.question}`);
          const questionRef = await unitRef?.collection("questions")?.add({
            ref: questionRefs[i],
            index: i,
            xp: Number(parsedData[i]?.xp) || 0,
          });
          console.log("questionRef", questionRef);
        }
      }

      return Promise.resolve(true);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * Returns a unit doc from `catalog/DEFAULT/units`
   * by unitName
   * @param {String} unitName
   * @returns Promise
   */
  const getUnitByUnitName = (unitName) => {
    try {
      if (!unitName) {
        throw Error(`Invalid input param value unitName: ${unitName}`);
      }

      return db
        .collection("catalog/DEFAULT/units")
        ?.where("name", "==", unitName)
        ?.get();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * Returns a question doc from `/topics`
   * by question string
   * @param {String} questionString
   * @returns Promise
   */
  const getQuestionByQuestionString = (questionString) => {
    try {
      if (!questionString) {
        throw Error(
          `Invalid input param value questionString: ${questionString}`
        );
      }

      return db
        .collection("/topics")
        .where("question", "==", questionString)
        .get();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * Creates/Updates question on db
   * @param {Object} question
   * @returns Promise
   */
  const addQuestionToDb = async (question) => {
    try {
      if (!question?.question) {
        throw Error(
          `question field missing from question input object. Given question: ${question}`
        );
      }

      if (!question?.QTYPE) {
        // Default question type is 'default'
        question.QTYPE = "default";
      }

      question.QTYPE = question?.QTYPE?.toLowerCase();

      const _ACCEPTED_QTYPES = ["default", "no_input"];

      if (!_ACCEPTED_QTYPES.includes(question.QTYPE)) {
        throw Error(
          `question type: QTYPE value is invalid. Allowed values: ${_ACCEPTED_QTYPES}. Given: ${question.QTYPE}`
        );
      }

      if (question?.QTYPE?.toLowerCase() === "default") {
        // Make sure the prompt is not missing
        if (!question?.prompt || question?.prompt?.length <= 0) {
          throw Error(
            `Prompt is required for DEFAULT question type. Given prompt: ${question?.prompt}`
          );
        }
      }

      const questionData = {
        notes: "",
        prompt: question?.prompt ? question?.prompt : "",
        question: question.question,
        status: question?.QSTATUS,
        type: question?.QTYPE,
      };

      const existingQuestionSnapshot = await getQuestionByQuestionString(
        question.question
      );

      if (!existingQuestionSnapshot.empty) {
        const existingQuestionRef = existingQuestionSnapshot.docs[0].ref;
        return Promise.resolve(existingQuestionRef);
      } else {
        const questionDocRef = await db.collection("/topics").add(questionData);
        return Promise.resolve(questionDocRef);
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  };

  /**
   *
   * @param {Array} parsedData
   * @returns Promise
   */
  const createAllQuestions = async (parsedData) => {
    try {
      if (Array.isArray(parsedData) !== true) {
        throw Error(
          `Input param parsedData invalid. Expecting an array. Got ${parsedData}`
        );
      }

      // Create questions
      const questionRefs = [];

      await Promise.all(
        parsedData.map(async (question) => {
          const questionDocRef = await addQuestionToDb(question);

          questionRefs.push(questionDocRef);
        })
      );

      return Promise.resolve(questionRefs);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * Takes in raw csv file string data and parses it to an
   * array of json objects.
   * @param {String} str
   * @param {String} delim
   * @returns Promise
   */
  const processCSV = (str) => {
    return new Promise((resolve, reject) => {
      try {
        if (!str) {
          throw Error(`Invalid input param str : ${str}`);
        }

        if (typeof str !== "string") {
          throw Error(`Invalid input param str : ${str}. Expecting string.`);
        }

        readString(str, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            if (results?.errors?.length > 0) {
              console.error(results?.errors[0]);
              throw Error(results?.errors[0]);
            }

            if (!results?.data) {
              throw Error(`results data undefined.`);
            }

            resolve(results?.data);
            return;
          },
        });
      } catch (e) {
        console.error(e?.message);
        reject(e);
        return;
      }
    });
  };

  return (
    <>
      <div className="unit-center-div transition-6">
        <div className="unit_main">
          <div className="user-units">
            <div className="search_box_container">
              <input
                type="text"
                placeholder="Search Units"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {showMessage && (
              <div className="message-container">
                <p className="message">{message}</p>
              </div>
            )}
            <div className="units-btn">
              <div className="unit-bn3">
                <label htmlFor="importFile" className="import-file-label">
                  Import Unit
                </label>
                <input
                  id="importFile"
                  type="file"
                  accept=".CSV"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
              </div>
              <div className="unit-bn3" onClick={createUnit}>
                <label>Create Unit</label>
              </div>
            </div>
            <div className="units-stack">
              {loading ? (
                <Spinner
                  width="6.25rem"
                  height="6.25rem"
                  overlayHeight="50vh"
                />
              ) : (
                unitsStack
                  ?.filter((unit) =>
                    unit.name.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((unit) => (
                    <div className="units-card">
                      <div className="unit-avatar">
                        <img
                          src={unit.avatar}
                          alt="roger"
                          height="20px"
                          width="20px"
                        />
                      </div>
                      <div className="tech-units">
                        <div>{unit.name}</div>
                        {/* <div className="unit-part-interview">
                          Part of: Interview Skills
                        </div>
                        <div className="unit-pallen-part-interview">
                          Pellentesque sagittis laoreet justo, ulaoreet justo, u
                          ed... part of Interview skills.
                        </div> */}
                      </div>

                      <div className="units_msg_notifs_option_btn">
                        <div className="bn3" onClick={() => viewUnits(unit)}>
                          View
                        </div>

                        <div>
                          {/* {unit?.unitReference === unitToDelete && (
                            <Spinner
                              width="15px"
                              height="15px"
                              overlayHeight="15px"
                            />
                          )} */}
                          {/* {unit?.unitReference === unitToDelete || ( */}
                          <img
                            src={require("../../../static/delete-icon-png-19.jpg")}
                            className="delete-icon"
                            alt="delete unit for user"
                            onClick={async () => {
                              console.log(unit);
                              const unitDeleteConfirmation = window.prompt(
                                "Are you sure you want to completely DELETE this unit? Type the unit name to confirm."
                              );

                              if (unitDeleteConfirmation) {
                                if (
                                  unit?.name?.toLowerCase() ===
                                  unitDeleteConfirmation?.toLocaleLowerCase()
                                ) {
                                  console.log(`Deletion confirmed...`);
                                  await deleteUnitsFromDb(
                                    db,
                                    unit?.unitReference
                                  );
                                }
                              }
                              console.log(unitDeleteConfirmation);
                            }}
                          />
                          {/* )} */}
                        </div>

                        {/* <div className="notification_btn_container">
                          <NotificationIcon width="24" height="24" />
                        </div>
                        <div>
                          <OptionsIcon width="24" height="24" color="black" />
                        </div> */}
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>

        {(isCreateUnitsOpen || isViewUnitsOpen) && (
          <CreateUnits
            db={db}
            currentUser={currentUser}
            setCreateUnitsOpen={setCreateUnitsOpen}
            selectedUnit={selectedUnit}
            isCreateUnitsOpen={isCreateUnitsOpen}
            isViewUnitsOpen={isViewUnitsOpen}
            setViewUnitsOpen={setViewUnitsOpen}
            setMessage={setMessage}
            setShowMessage={setShowMessage}
            unitsStack={unitsStack}
            setSelectedUnit={setSelectedUnit}
          />
        )}
      </div>
    </>
  );
};

export default Units;
