import React, { useContext, useEffect } from "react";
import OratioContext from "../../oratioContext/OratioContext";
import logo from "../../static/logo2.png";
import ActiveCheckinIcon from "../svgs/ActiveCheckinIcon";
import InactiveCheckinIcon from "../svgs/InactiveCheckinIcon";
import PauseCheckinIcon from "../svgs/PausedCheckinIcon";
import nestriaLogo from "../../static/nestria/600-nestria-full-logo.png";
import SpryteLogoIcon from "../svgs/SpryteLogoIcon";

const NestriaNavSection = ({
  // switchToSpryteLogo,
  switchWindowConnections,
  switchWindowDiscussion,
  switchToRequest,
  switchToCheckIn,
  switchToSettings,
  activeTab,
  setActiveTab,
  currentUserObj,
  devsReportingAccount,
  isShowcheckIn,
  isShowPause,
  truncateNum,
  checkinDate,
  setIsShowCheckIn,
  isNestria,
}) => {
  const { setConnectionId, connectionsCount, discussionCount, requestCount } =
    useContext(OratioContext);
  useEffect(() => {
    const getDevReportingReference = async () => {
      let currentDate = new Date();
      if (
        currentDate > checkinDate &&
        !(
          currentDate?.getMonth() === checkinDate?.getMonth() &&
          currentDate?.getDate() === checkinDate?.getDate() &&
          currentDate?.getFullYear() === checkinDate?.getFullYear()
        )
      ) {
        setIsShowCheckIn(true);
      }
    };
    getDevReportingReference();
  }, [activeTab]);

  return (
    <>
      <div className="nav_container">
        <div className="nav">
          <div className="nav_title">
            <>
              <div
                className={
                  activeTab === "SpryteLogo"
                    ? "spryte_logo active_tab"
                    : "spryte_logo inactive_tab"
                }
                // onClick={() => {
                //   switchToSpryteLogo();
                //   setConnectionId('');
                //   setActiveTab('SpryteLogo');
                // }}
                onClick={(e) => {
                  e.stopPropagation(); // stop event propagation
                }}
              >
                <img src={nestriaLogo} width={22} height={22} alt="logo" />
              </div>
            </>
            <>
              <div
                className={
                  activeTab === "Connections"
                    ? "connections active_tab"
                    : "connections inactive_tab"
                }
                onClick={() => {
                  switchWindowConnections();
                  setConnectionId("");
                  setActiveTab("Connections");
                }}
              >
                <p
                  className={
                    activeTab === "Connections"
                      ? "isActive desktop"
                      : "inactive desktop"
                  }
                >
                  Users
                </p>
                {/* <span className={activeTab === 'Connections' ? 'active_counts active_big_counts' : connectionsCount?.toString()?.length > 2 ? 'big_counts' : 'counts'}>{truncateNum(connectionsCount)}</span> */}
              </div>
            </>

            <>
              <>
                {/* checkin tab */}
                <div
                  className={
                    activeTab === "Checkins"
                      ? "checkins active_tab"
                      : "checkins inactive_tab"
                  }
                  onClick={() => {
                    switchToCheckIn();
                    setConnectionId("");
                    setActiveTab("Checkins");
                  }}
                >
                  <div className="checkin_sub_container">
                    <p
                      className={
                        activeTab === "Checkins"
                          ? "isActive desktop checkin_tab"
                          : "inactive desktop checkin_tab"
                      }
                    >
                      Courses
                    </p>
                  </div>
                </div>
              </>
            </>
            {/* spryte logo */}

            {/* discussions tab */}
            <>
              <div
                className={
                  activeTab === "Discussions"
                    ? "discussion active_tab"
                    : "discussion inactive_tab"
                }
                onClick={() => {
                  switchWindowDiscussion();
                  setConnectionId("");
                  setActiveTab("Discussions");
                }}
              >
                <p
                  className={
                    activeTab === "Discussions"
                      ? "isActive desktop"
                      : "inactive desktop"
                  }
                >
                  Units
                </p>
                {/* <span className={activeTab === 'Discussions' ? 'active_counts active_big_counts' : discussionCount?.toString()?.length > 2 ? 'big_counts' : 'counts'}>{truncateNum(discussionCount)}</span> */}
              </div>
            </>
            {/* connections tab */}

            {/* requests tab */}
            <>
              <div
                className={
                  activeTab === "Requests"
                    ? "requests active_tab"
                    : "requests inactive_tab"
                }
                onClick={() => {
                  switchToRequest();
                  setConnectionId("");
                  setActiveTab("Requests");
                }}
              >
                <p
                  className={
                    activeTab === "Requests"
                      ? "isActive desktop"
                      : "inactive desktop"
                  }
                >
                  Questions
                </p>
              </div>
            </>

            <div
              // className='settings'
              className={
                activeTab === "Settings"
                  ? "settings active_settings"
                  : "settings inactive_settings"
              }
            >
              {/* settings tab */}
              <>
                <div
                  className="user_avatar"
                  onClick={() => {
                    switchToSettings();
                    setConnectionId("");
                    setActiveTab("Settings");
                  }}
                >
                  <img src={currentUserObj?.avatar ?? logo} alt="avatar" />
                </div>
                <div className="nav_status_container">
                  <p className="nav_status"></p>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NestriaNavSection;
