import React from "react";

const ListIcon = ({ width, height, top, bottom }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4643 12.8571H0.535714C0.393634 12.8571 0.257373 12.9136 0.156907 13.014C0.0564412 13.1145 0 13.2508 0 13.3929L0 14.4643C0 14.6064 0.0564412 14.7426 0.156907 14.8431C0.257373 14.9436 0.393634 15 0.535714 15H14.4643C14.6064 15 14.7426 14.9436 14.8431 14.8431C14.9436 14.7426 15 14.6064 15 14.4643V13.3929C15 13.2508 14.9436 13.1145 14.8431 13.014C14.7426 12.9136 14.6064 12.8571 14.4643 12.8571ZM14.4643 8.57143H0.535714C0.393634 8.57143 0.257373 8.62787 0.156907 8.72834C0.0564412 8.8288 0 8.96506 0 9.10714L0 10.1786C0 10.3207 0.0564412 10.4569 0.156907 10.5574C0.257373 10.6578 0.393634 10.7143 0.535714 10.7143H14.4643C14.6064 10.7143 14.7426 10.6578 14.8431 10.5574C14.9436 10.4569 15 10.3207 15 10.1786V9.10714C15 8.96506 14.9436 8.8288 14.8431 8.72834C14.7426 8.62787 14.6064 8.57143 14.4643 8.57143ZM14.4643 4.28571H0.535714C0.393634 4.28571 0.257373 4.34216 0.156907 4.44262C0.0564412 4.54309 0 4.67935 0 4.82143L0 5.89286C0 6.03494 0.0564412 6.1712 0.156907 6.27166C0.257373 6.37213 0.393634 6.42857 0.535714 6.42857H14.4643C14.6064 6.42857 14.7426 6.37213 14.8431 6.27166C14.9436 6.1712 15 6.03494 15 5.89286V4.82143C15 4.67935 14.9436 4.54309 14.8431 4.44262C14.7426 4.34216 14.6064 4.28571 14.4643 4.28571ZM14.4643 0H0.535714C0.393634 0 0.257373 0.0564411 0.156907 0.156907C0.0564412 0.257373 0 0.393634 0 0.535714L0 1.60714C0 1.74922 0.0564412 1.88548 0.156907 1.98595C0.257373 2.08642 0.393634 2.14286 0.535714 2.14286H14.4643C14.6064 2.14286 14.7426 2.08642 14.8431 1.98595C14.9436 1.88548 15 1.74922 15 1.60714V0.535714C15 0.393634 14.9436 0.257373 14.8431 0.156907C14.7426 0.0564411 14.6064 0 14.4643 0Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};

export default ListIcon;
