import { useEffect, useState } from "react";

export default function RecordButton({ setStartRecording, isRecording }) {
  return (
    <>
      {!isRecording ? (
        <RecordIcon setIsRecording={setStartRecording} />
      ) : (
        <RecordStopIcon setIsRecording={setStartRecording} />
      )}
    </>
  );
}

function RecordIcon({ setIsRecording }) {
  return (
    <>
      <div
        style={{
          background: "white",
          borderRadius: "100px",
          height: "50px",
          width: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          setIsRecording(true);
        }}
      >
        <div
          style={{
            background: "red",
            borderRadius: "100px",
            height: "40px",
            width: "40px",
          }}
        ></div>
      </div>
    </>
  );
}

function RecordStopIcon({ setIsRecording }) {
  return (
    <>
      <div
        style={{
          background: "white",
          borderRadius: "100px",
          height: "50px",
          width: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          setIsRecording(false);
        }}
      >
        <div
          style={{
            background: "red",
            borderRadius: "100px",
            height: "44px",
            width: "44px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              background: "white",
              borderRadius: "4px",
              height: "22px",
              width: "22px",
            }}
          ></div>
        </div>
      </div>
    </>
  );
}
