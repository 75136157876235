import React, { useContext, useEffect, useRef, useState } from "react";
import EditIcon from "../../svgs/EditIcon";
import DelChannelIcon from "../../svgs/DelChannelIcon";
import TimeSheetPrevIcon from "../../svgs/TimeSheetPrevIcon";

const CreateQuestions = ({
  db,
  currentUser,
  setCreateQuestionsOpen,
  selectedQuestion,
  isCreateQuestionsOpen,
  isViewQuestionsOpen,
  setViewQuestionsOpen,
  setMessage,
  setShowMessage,
  questionsStack,
  setSelectedQuestion,
}) => {
  const [isOn, setIsOn] = useState(
    selectedQuestion ? selectedQuestion?.status : false
  );
  const [questionName, setQuestionName] = useState(selectedQuestion?.question);
  const [gptPrompt, setGptPrompt] = useState(selectedQuestion?.prompt);
  const [adminNotes, setAdminNotes] = useState(selectedQuestion?.notes);

  const [questionType, setQuestionType] = useState(selectedQuestion?.type);
  const [questionNameError, setQuestionNameError] = useState("");
  const [gptPromptError, setGptPromptError] = useState("");
  const [adminNotesError, setAdminNotesError] = useState("");

  const handleToggle = () => {
    setIsOn(!isOn);
  };

  const _QUESTION_TYPES = [
    {
      id: "default",
      label: "Default",
      conditions: {
        evaluate: true,
        showInput: true,
      },
    },
    {
      id: "no_input",
      label: "No Input",
      conditions: {
        evaluate: false,
        showInput: false,
      },
    },
    {
      id: "profiling",
      label: "Profile",
      conditions: {
        evaluate: false,
        showInput: true,
      },
    },
  ];

  const validateInputs = () => {
    let isValid = true;

    if ((questionName ?? "").trim() === "") {
      setQuestionNameError("Question name is required");
      isValid = false;
    } else if (
      isCreateQuestionsOpen &&
      questionsStack.some((question) => question.name === questionName)
    ) {
      setQuestionNameError("Question already exists");
      isValid = false;
    } else {
      setQuestionNameError("");
    }

    return isValid;
  };

  const handleDelete = async () => {
    if (selectedQuestion) {
      const questionRef = await db.doc(
        selectedQuestion?.questionreference?.path
      );
      try {
        await questionRef.delete();
        setMessage("Question deleted successfully!");
        setShowMessage(true);
        setViewQuestionsOpen(false);
        setCreateQuestionsOpen(false);
      } catch (error) {
        console.error("Error deleting question:", error);
      }
    }
  };

  const handleCreateAndSave = async () => {
    try {
      if (!validateInputs()) {
        return;
      }
      console.log("selected questions ", selectedQuestion);
      const questionData = {
        notes: adminNotes ? adminNotes : "",
        prompt: gptPrompt ? gptPrompt : "",
        question: questionName,
        status: isOn ? isOn : false,
        type: questionType,
      };

      if (selectedQuestion) {
        await selectedQuestion?.questionreference?.update(questionData);
      } else {
        await db.collection("/topics")?.add(questionData);
      }
      setMessage("Question successfully created/saved.!");
      setShowMessage(true);
      setViewQuestionsOpen(false);
      setCreateQuestionsOpen(false);
    } catch (error) {
      console.error("Failed to create and save the question:", error);
      setMessage("Failed to create question. Please try again.");
      setShowMessage(true);
    }
  };

  return (
    <>
      <div className="create-ques-container">
        <div className="create-ques-main">
          <div className="back_btn">
            <TimeSheetPrevIcon width="12" height="12" icon="backArrow" />
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                setViewQuestionsOpen(false);
                setCreateQuestionsOpen(false);
                setSelectedQuestion(null);
              }}
            >
              Back to all Questions
            </p>
          </div>
          <div className="create-ques-header">
            <div className="header">
              {isViewQuestionsOpen ? (
                <label>View question</label>
              ) : (
                <>
                  <label>Create a question</label>
                  <EditIcon width="10" height="10" top="auto" bottom="auto" />
                </>
              )}
            </div>

            <div className="toggle_switch_container">
              <p>Draft Question</p>
              <div
                className={`toggle_switch ${isOn ? "off" : "on"}`}
                // className={`toggle_switch ${isOn ? 'on' : 'off'} ${isEditChatGroup ? (isOn ? 'public' : 'private') : ((isPublic || isPublic === undefined) ? 'public' : 'private')}`}
                onClick={handleToggle}
              >
                <div className="toggle_handle" />
              </div>
              <p>Published questions</p>
            </div>
          </div>

          <div className="ques-container">
            <label>Question</label>
            <input
              type="text"
              class="ques_input_message"
              value={questionName}
              onChange={(e) => setQuestionName(e.target.value)}
            />

            {questionNameError && (
              <p className="error-message">{questionNameError}</p>
            )}
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                flexBasis: "20%",
                paddingRight: "10px",
                marginBottom: "7.5rem",
              }}
            >
              Question type:
            </div>
            <div style={{ flexBasis: "20%" }}>
              <select
                style={{ width: "100%" }}
                value={questionType}
                onChange={(e) => setQuestionType(e.target.value)}
              >
                {_QUESTION_TYPES.map((questionType) => (
                  <option value={questionType.id} key={questionType.id}>
                    {" "}
                    {questionType.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="gpt-prompt-container">
            <label>GPT Prompt</label>
            <textarea
              className="gpt_input_message"
              value={gptPrompt}
              onChange={(e) => setGptPrompt(e.target.value)}
            ></textarea>
            {gptPromptError && (
              <p className="error-message">{gptPromptError}</p>
            )}
          </div>

          {/* <div class="test-btn-container">
            <div class="button">Test</div>
          </div> */}

          <div className="admin-notes-container">
            <textarea
              className="admin_notes"
              placeholder="Admin Notes"
              value={adminNotes}
              onChange={(e) => setAdminNotes(e.target.value)}
            ></textarea>

            {adminNotesError && (
              <p className="error-message">{adminNotesError}</p>
            )}
          </div>
          <div className="delete-ques-container" onClick={handleDelete}>
            <p>Delete Question</p>
          </div>

          <div class="create_save_btn">
            {isViewQuestionsOpen ? (
              <div className="button" onClick={handleCreateAndSave}>
                View and update
              </div>
            ) : (
              <div className="button" onClick={handleCreateAndSave}>
                Create and save
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateQuestions;
