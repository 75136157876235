import React, { useContext, useEffect, useState } from "react";
import CreateUnits from "./CreateUnits";
// import noAvatar from "../../static/no-avatar.png";
import NotificationIcon from "../../svgs/NotificationIcon";
import OptionsIcon from "../../svgs/OptionsIcon";
import CreateCourse from "./CreateCourse";
import NoAvatar from "../../noAvatar/NoAvatar";
import TimeSheetPrevIcon from "../../svgs/TimeSheetPrevIcon";
import RemoveIcon from "../../svgs/RemoveIcon";
import ListIcon from "../../svgs/ListIcon";
import AddIcon from "../../svgs/AddIcon";
import OratioContext from "../../../oratioContext/OratioContext";
import Spinner from "../../spinner/Spinner";
import { getLatestScore, removeUnitForUser } from "../helpers/store";
import { assignUnitToUser, getCourseDataFromUnitSource } from "../helpers/utils";
import { CheckmarkSvg, SkippedSvg, LockSvg } from "../helpers/Svgs";
import VideoThumbnail from "../VideoRecorder/VideoThumbnail";
const noAvatar = require("../../../static/default-avatar.png");

const AdminUserView = ({
  db,
  setIsAdminUserViewShow,
  setActiveTab,
  setIsCompConnectionsShown,
  setIsUserConnectionsShown,
  setIsTechConnectionsShown,
  setIsRequirementsShown,
  setIsDefault,
  currentUserObj,
  firebase,
}) => {
  const [expandedUnits, setExpandedUnits] = useState([]);
  const [userUnits, setUserUnits] = useState([]);
  const [unitName, setUnitName] = useState("");
  const [questionName, setQuestionName] = useState("");
  const [questionPrompt, setQuestionPrompt] = useState("");
  const [questionStatus, setQuestionStatus] = useState("");
  const [memberDetails, setMemberDetails] = useState([]);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [questionID, setQuestionID] = useState(null);
  const [questionref, setQuestionref] = useState(null);
  const [unitID, setUnitID] = useState(null);
  const [courseID, setCourseID] = useState(null);
  const [unitReference, setUnitReference] = useState(null);
  const [messageData, setMessageData] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [unitsStack, setUnitsStack] = useState(null);
  const [courseStack, setCourseStack] = useState([]);
  const [isAssign, setIsAssign] = useState(true);
  const [addedUnits, setAddedUnits] = useState([]);
  const [unitSaved, setUnitSaved] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [unitToDelete, setUnitToDelete] = useState(null); // This state also determines when to show and hide spinner

  const [questionIndex, setQuestionIndex] = useState(0);

  // destructures prop/value from the OratioContext component
  const { connectionObj } = useContext(OratioContext);
  const currentUserRef = connectionObj?.ref;

  const [currentCourse, setCurrentCourse] = useState("");
  const [userCourseData, setUserCourseData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setUserUnits([]);
        setUserCourseData({});
        const userRef = connectionObj?.ref;
        const collectionRef = db
          ?.collection("careerPrep")
          .where("userRef", "==", currentUserRef)
          .orderBy("ts", "asc");

        const unsubscribe = collectionRef?.onSnapshot((querySnapshot) => {
          let units = [];

          querySnapshot.docs.forEach(async (documentSnapshot) => {
            const documentData = documentSnapshot?.data();

            const unitName = documentData?.name;

            const unitSourceRef = await documentData?.source?.get();
            const memberMessages = [];
            const questionsPromises = documentData?.questions?.map(
              async (questionRef) => {
                const questionSnapshot = await questionRef?.get();
                const questionData = questionSnapshot?.data()?.questionData;

                const question = questionData?.question;
                const difficulty = questionData?.difficulty || "NA";
                const index = questionData?.index;
                const questionStatus = questionData?.status;
                const questionId = questionSnapshot?.data()?.source?.id;
                const latestScore = await getLatestScore(questionRef);

                return {
                  questionId,
                  question,
                  difficulty,
                  index,
                  status: questionStatus,
                  reference: questionRef,
                  isAnswered: questionSnapshot?.data()?.isAnswered,
                  isQuestionSkipped:
                    questionSnapshot?.data()?.isQuestionSkipped,
                  userScored: latestScore,
                  memberMessages,
                };
              }
            );

            let questions;
            if (questionsPromises) {
              questions = await Promise.all(questionsPromises);
            } else {
              questions = [];
            }

            let { courseName, courseID } = await getCourseDataFromUnitSource(
              unitSourceRef
            );

            const questionObjects = await Promise.all(
              questions?.map((questionObj) => {
                return {
                  unitReference: documentSnapshot.ref,
                  question: questionObj?.question,
                  index: questionObj?.index,
                  difficulty: questionObj?.difficulty,
                  status: questionObj?.status,
                  reference: questionObj?.reference,
                  memberMessages: questionObj?.memberMessages,
                  isAnswered: questionObj?.isAnswered,
                  isQuestionSkipped: questionObj?.isQuestionSkipped,
                  score: questionObj?.userScored,
                  questionId: questionObj.questionId,
                };
              })
            );

            // Calculate total score
            const totalScore = questionObjects.reduce(
              (acc, { score = 0 }) => acc + score,
              0
            );

            // Filter questions with score >= 3 and find the highest difficulty
            const { highScoringQuestions, highestDifficulty } =
              questionObjects.reduce(
                (acc, { score, difficulty }) => {
                  if (score >= 3) {
                    acc.highScoringQuestions.push({ score, difficulty });
                    if (
                      difficulty !== "NA" &&
                      (difficulty > acc.highestDifficulty ||
                        acc.highestDifficulty === 1)
                    ) {
                      acc.highestDifficulty = difficulty;
                    }
                  }
                  return acc;
                },
                { highScoringQuestions: [], highestDifficulty: 1 }
              );

            let questionsSortedByIndex = questionObjects.sort(
              (a, b) => a.index - b.index
            );

            if (questionObjects?.length > 0) {
              units.push({
                id: unitSourceRef?.id,
                courseName,
                courseID,
                title: unitName,
                description: "",
                unitReference: documentSnapshot.ref,
                questions: questionsSortedByIndex,
                expanded: expandedUnits[0] || true,
                memberDetails: memberDetails,
                ts: documentData?.ts,
                totalScore,
                highestDifficulty,
              });

              setUserCourseData((prevUserCourseData) => {
                const updatedUserCourseData = { ...prevUserCourseData };
                const scoreToAdd = isNaN(totalScore) ? 0 : totalScore;
                const levelToAdd = isNaN(highestDifficulty)
                  ? 1
                  : highestDifficulty;
                const questionCount = questionsSortedByIndex.length;
                const maxScore = questionCount * 5;

                if (updatedUserCourseData[courseName]) {
                  updatedUserCourseData[courseName].totalScore += scoreToAdd;
                  updatedUserCourseData[courseName].unitCount++;
                  updatedUserCourseData[courseName].totalLevels += levelToAdd;
                  updatedUserCourseData[courseName].averageLevel =
                    updatedUserCourseData[courseName].totalLevels /
                    updatedUserCourseData[courseName].unitCount;
                  updatedUserCourseData[courseName].maxScore += maxScore;
                } else {
                  updatedUserCourseData[courseName] = {
                    totalScore: scoreToAdd,
                    totalLevels: levelToAdd,
                    unitCount: 1,
                    maxScore: maxScore,
                  };
                }

                updatedUserCourseData[courseName].percentageScore = (
                  (updatedUserCourseData[courseName].totalScore /
                    updatedUserCourseData[courseName].maxScore) *
                  100
                ).toFixed(2);

                setCurrentCourse(courseName);
                console.log("currentCourse: ", courseName);
                return updatedUserCourseData;
              });

              setUserUnits(units);

              console.log(units);

              let mostRecentQuestionRef = null;
              let unitRef = null;

              if (units?.length > 0 && units[0]?.questions?.length > 0) {
                const firstQuestion = units[0].questions[0];
                mostRecentQuestionRef = firstQuestion.reference;
                unitRef = firstQuestion.unitReference;
              }

              if (mostRecentQuestionRef) {
                setQuestionref(mostRecentQuestionRef);
                setUnitReference(unitRef);
                setQuestionIndex(1);
                setCourseID(units[0].courseID);
                setUnitID(units[0].id);
                setQuestionID(units[0].questions[0].questionId);
              }
            }
          });

          setIsLoading(false);
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const getQuestionsStack = async () => {
      try {
        const unitSet = new Set(); // Set to store unique unit names
        const unitList = []; // Array to store the units

        const nestriaCatalogStack = db?.collection(`catalog`);
        const catalogStack = await nestriaCatalogStack?.get();

        catalogStack?.forEach(async (catalogDoc) => {
          try {
            const catalogData = catalogDoc?.data();
            const catalogId = catalogDoc?.id;

            const unitsSubcollection = await catalogDoc?.ref
              ?.collection("units")
              ?.get();

            setUnitsStack([]);
            setUnitsStack((prevUnitList) =>
              (prevUnitList ?? []).concat(unitList)
            );

            await processUnitsSubcollection(
              unitsSubcollection,
              unitList,
              unitSet,
              catalogId
            );
          } catch (error) {
            console.error("Error accessing units subcollection", error);
          }
        });
      } catch (error) {
        console.error("Error retrieving units", error);
      }
    };

    const processUnitsSubcollection = async (
      subcollection,
      unitList,
      unitSet,
      catalogId
    ) => {
      await Promise.all(
        subcollection?.docs.map(async (unitDoc) => {
          try {
            const unitData = unitDoc?.data();
            const unitName = unitData?.name;

            // Check if the unit id is already in the set
            if (!unitSet.has(unitDoc?.id)) {
              unitSet.add(unitDoc?.id);

              const questionsSubcollection = await unitDoc?.ref
                ?.collection("questions")
                ?.get();

              const questionList = await Promise.all(
                questionsSubcollection.docs.map(async (questionDoc) => {
                  try {
                    const questionData = questionDoc?.data();
                    const questionReference = questionData?.ref;

                    const questionSnapshot = await questionReference?.get();
                    const questionName = questionSnapshot?.data()?.question;
                    return {
                      question: questionName,
                      xp: questionData?.xp,
                      index: questionData?.index,
                      reference: questionData?.ref,
                    };
                  } catch (error) {
                    console.error("Error processing question document", error);
                    return null;
                  }
                })
              );

              unitList.push({
                id: unitDoc?.id,
                type: "unit",
                name: unitName,
                questions: questionList
                  .filter((question) => question !== null)
                  .sort((a, b) => {
                    return a?.index - b?.index;
                  }),
                reference: unitDoc?.ref,
              });

              const filteredUnitsStack = unitList?.filter((unit) => {
                const isAssigned = userUnits?.some(
                  (userUnit) => userUnit?.id === unit?.id
                );

                return !isAssigned;
              });

              setUnitsStack(filteredUnitsStack);
            }
          } catch (error) {
            console.error("Error processing unit document", error);
          }
        })
      );
    };
    getQuestionsStack();
  }, [userUnits]);

  useEffect(() => {
    let submissionListener;
    const fetchQuestionData = async () => {
      if (questionref) {
        setSelectedQuestion(questionref);
        const questionSnapshot = await questionref?.get();
        const questionName = questionSnapshot?.data()?.questionData?.question;

        const _videoAnswered =
          questionSnapshot?.data()?.questionData?.videoAnswerRequired;

        setQuestionName(questionName);
        const members = questionSnapshot?.data()?.members;
        const memberDetails = await Promise.all(
          members?.map(async (memberRef) => {
            const memberSnapshot = await memberRef?.get();
            const memberData = memberSnapshot?.data();
            const memberReference = memberSnapshot?.ref?.path;
            let memberName = "";
            let avatar;

            if (memberReference?.includes("admin")) {
              memberName = "Admin";
            } else if (
              memberReference?.includes("nestria") &&
              !memberReference?.includes("chatGPT")
            ) {
              memberName = "Nestria";
            } else if (
              memberReference?.includes("chatGPT") &&
              memberReference?.includes("plugins")
            ) {
              memberName = "Chat GPT";
            } else {
              const membersnapshot = await memberRef?.get();
              const userdata = membersnapshot?.data();
              memberName = userdata?.first + " " + userdata?.last;
              avatar =
                userdata?.avatar !== undefined ? userdata?.avatar : noAvatar;
            }
            return {
              ...memberData,
              name: memberName,
              avatar: avatar,
              reference: memberReference,
            };
          })
        );
        setMemberDetails(memberDetails);
        const memberMessages = [];
        if (submissionListener) {
          submissionListener();
        }

        submissionListener = await questionref
          .collection("submissions")
          .orderBy("ts", "asc")
          .onSnapshot(async (snapshot) => {
            const promises = snapshot.docs.map(async (documentSnapshot) => {
              const documentData = documentSnapshot.data();
              let authorReference = documentData?.author;
              let authorName = "";
              let avatar = "";
              let memeberRating = "";
              let newmessage = "";

              let _videoAssessmentLink = "";

              if (documentData?.msg) {
                const splitMsg = documentData.msg.split("<>");
                if (splitMsg.length === 2) {
                  memeberRating = splitMsg[0].trim();
                  newmessage = splitMsg[1].trim();
                } else {
                  newmessage = documentData?.msg;
                  memeberRating = "";
                }
              }
              const existingmessage = memberMessages.find(
                (message) => message.message === newmessage
              );

              if (!existingmessage) {
                if (typeof authorReference === "string") {
                  if (authorReference?.includes("chatGPT")) {
                    authorName = "Chat GPT";
                  }
                } else {
                  let referencePath = authorReference?.path;
                  if (referencePath?.includes("admin")) {
                    authorName = "Admin";
                  } else if (
                    referencePath === "organisations/nestria/plugins/chatGPT"
                  ) {
                    authorName = "Chat GPT";
                  } else if (referencePath?.includes("organisations/nestria")) {
                    authorName = "Nestria";
                  } else {
                    const membersnapshot = await authorReference?.get();
                    const userdata = membersnapshot?.data();
                    authorName = userdata?.first + " " + userdata?.last;
                    avatar =
                      userdata?.avatar !== undefined ? userdata?.avatar : "";
                    if (_videoAnswered)
                      _videoAssessmentLink = documentData?.videoAssessmentLink;
                  }
                }
                const memberMessage = {
                  member: authorName,
                  avatar: avatar,
                  message: newmessage,
                  rating: memeberRating,
                  ts: documentData?.ts?.toDate()?.toLocaleString("en-US", {
                    weekday: "short",
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  }),
                  videoAssessmentLink: _videoAssessmentLink,
                };

                memberMessages.push(memberMessage);
              }
            });

            await Promise.all(promises);
            //memberMessages.sort((a, b) => a.ts.localeCompare(b.ts));
            const updatedMessageData = memberMessages?.map((message) => {
              return {
                avatar: message.avatar,
                sender: message?.member,
                ts: new Date(message.ts),
                time: message.ts,
                message: message?.message,
                rating: message?.rating,
                videoAssessmentLink: message.videoAssessmentLink,
              };
            });
            updatedMessageData.sort((a, b) => {
              const timestampA = a?.ts?.getTime();
              const timestampB = b?.ts?.getTime();

              if (timestampA === timestampB) {
                if (a?.sender === "Chat GPT") {
                  return 1;
                } else if (b?.sender === "Chat GPT") {
                  return -1;
                }
                return 0;
              } else {
                return timestampA - timestampB;
              }
            });
            setMessageData(updatedMessageData);
            if (updatedMessageData !== undefined) {
            }
          });
      }
    };
    fetchQuestionData();

    return () => {
      if (submissionListener) {
        submissionListener(); // Call the function reference to unsubscribe
      }
    };
  }, [questionref]);

  useEffect(() => {
    try {
      const assignableCourses = [];
      if (unitsStack) {
        Promise.all(
          // unitsStack contains all the units that are not currently assigned to the user
          // assuming that their associated courses are also not currentlt assigned to the user
          unitsStack.map((unit) => {
            let courseDocPath = unit?.reference?.parent?.parent?.path; // Gets the course document path
            if (
              courseDocPath &&
              !assignableCourses.includes(courseDocPath) &&
              courseDocPath !== "catalog/DEFAULT" // We cannot let the admin assign the DEFAULT course
            ) {
              assignableCourses.push(courseDocPath);
            }
          })
        )
          .then(async () => {
            const assignableCourseStack = await Promise.all(
              assignableCourses.map(async (coursePath) => {
                const courseRef = await db.doc(coursePath).get();
                const courseData = courseRef.data();
                return {
                  type: "course",
                  name: courseData?.name,
                  reference: courseRef,
                };
              })
            );

            setCourseStack(assignableCourseStack);
          })
          .catch((e) => {
            console.error(e);
            setCourseStack([]);
          });
      }
    } catch (e) {
      console.error(e);
    }
  }, [unitsStack]);

  async function handleDeleteUnitForUser(unitRef) {
    try {
      setUnitToDelete(unitRef);
      await removeUnitForUser(db, unitRef);
    } catch (e) {
      console.log(e);
    } finally {
      setUnitToDelete(null);
    }
  }

  const toggleExpansion = (unitIndex) => {
    setExpandedUnits((prevExpandedUnits) => {
      const updatedExpandedUnits = [...prevExpandedUnits];
      updatedExpandedUnits[unitIndex] = !updatedExpandedUnits[unitIndex];
      return updatedExpandedUnits;
    });
  };

  const handleSendMessage = async () => {
    try {
      const careerpresnapshot = await questionref
        .collection("submissions")
        .add({
          author: db.doc("/organisations/nestria"),
          msg: message,
          received: false,
          ts: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      const NewMessageData = {
        avatar: noAvatar,
        sender: "Admin",
        time: new Date().toUTCString(),
        message: message,
      };
    } catch (error) {
      console.error("Error sending message:", error);
    }
    setMessage("");
  };

  const handleChangeMessage = (event) => {
    // Update the "message" state value as the user types
    setMessage(event.target.value);
  };

  const handleAddCourseOrUnit = async (unit) => {
    try {
      setIsLoading(true);

      if (unit.type === "unit") {
        const careerpresnapshot = await assignUnitToUser(
          db,
          unit,
          currentUserRef,
          false,
          firebase
        );

        if (careerpresnapshot) {
          // Add the unit to the addedUnits state
          setAddedUnits((prevAddedUnits) => [...prevAddedUnits, unit]);
        }
        setUnitsStack((prevUnitsStack) =>
          prevUnitsStack.filter((u) => u.name !== unit.name)
        );
      }

      if (unit.type === "course") {
        console.log(`[INFO] assigning course ...`);

        let courseRef = unit.reference;
        const courseUnitRef = await courseRef.ref
          .collection("units")
          .orderBy("index", "asc")
          .get();

        if (courseUnitRef.empty === false) {
          let unitCounter = 0;
          for (let unitDoc of courseUnitRef.docs) {
            let unitData = unitDoc.data();
            let unitReference = unitDoc?.ref;
            let isLastUnitOfTheCourse = false;

            if (unitCounter === courseUnitRef.docs.length - 1) {
              isLastUnitOfTheCourse = true;
            }

            await assignUnitToUser(
              db,
              { name: unitData?.name, reference: unitReference },
              currentUserRef,
              isLastUnitOfTheCourse,
              firebase
            );

            unitCounter += 1;
          }
        }
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 10000);
    } catch (error) {
      console.error("Error adding unit:", error);
      setIsLoading(false);
    }
  };

  const toggleUnitExpansion = (unitIndex) => {
    toggleExpansion(unitIndex);
  };

  const handleQuestionClick = (questionObj, questionIndex, unit) => {
    setQuestionref(questionObj?.reference);
    setUnitReference(questionObj?.unitReference);
    setQuestionIndex(questionIndex + 1);
    setCourseID(unit.courseID);
    setUnitID(unit.id);
    setQuestionID(questionObj.questionId);
  };

  let lastCourseName = null;
  let isNewCourse = false;

  return (
    <div className="admin-user_view_container">
      <div className="admin-user_view-main">
        <div className="user_units_container">
          <div className="back_btn">
            <TimeSheetPrevIcon width="12" height="12" icon="backArrow" />
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                setActiveTab("Connections");
                setIsAdminUserViewShow(false);
                setIsCompConnectionsShown(false);
                setIsUserConnectionsShown(true);
                setIsTechConnectionsShown(false);
                setIsRequirementsShown(false);
                setIsDefault(false);
              }}
            >
              Back to all Users
            </p>
          </div>
          <div className="user_units">
  {isLoading ? (
    <Spinner width="6.25rem" height="6.25rem" overlayHeight="50vh" />
  ) : (
    <>
      {userUnits[0]?.questions.length === 0 ? (
        <Spinner
          width="6.25rem"
          height="6.25rem"
          overlayHeight="50vh"
        />
      ) : (
        userUnits
          .sort((a, b) => a.ts - b.ts)
          .map((unit, index) => {
            isNewCourse = lastCourseName !== unit.courseName;
            lastCourseName = unit.courseName;
            return (
              <>
                {isNewCourse && (
                  <div className="courseData">
                    <h2>{unit.courseName}</h2>
                    {/* Fetch courseData for unit.courseName from userCourseData */}
                    {userCourseData[unit.courseName] && (
                      <>
                        <div className="courseScores">
                          <p>
                            Total Score:{" "}
                            {userCourseData[unit.courseName].totalScore}
                          </p>
                          <p>
                            Percentage:{" "}
                            {userCourseData[unit.courseName].percentageScore}
                          </p>
                          <p>
                            Average Level:{" "}
                            {userCourseData[unit.courseName].averageLevel}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                )}
                <div className="unit_info" key={index}>
                  <div className="unit">
                    {/* <NoAvatar text="r" size={2.5} radius={50} /> */}
                    <img
                      src={
                        connectionObj?.avatar
                          ? connectionObj?.avatar
                          : noAvatar
                      }
                      alt="avatar"
                      className="avatar-image"
                    />
                    <div
                      className="unit-title-description"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "90%",
                        alignItems: "baseline",
                      }}
                    >
                      <p className="unit-header">
                        Unit {index + 1} : {unit.courseName} - {unit.title}
                        <div className="unitScore">
                          <p>Unit Total Score: {unit.totalScore}</p>
                          <p>Level: {unit.highestDifficulty}</p>
                        </div>
                      </p>
                      {unit?.unitReference === unitToDelete && (
                        <Spinner
                          width="15px"
                          height="15px"
                          overlayHeight="15px"
                        />
                      )}
                      {unit?.unitReference === unitToDelete || (
                        <img
                          src={require("../../../static/delete-icon-png-19.jpg")}
                          className="delete-icon"
                          alt="delete unit for user"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this unit for this user?"
                              )
                            ) {
                                    handleDeleteUnitForUser(
                                      unit?.unitReference
                                    );
                            }
                          }}
                        />
                      )}
                      {/* <p className="personal-work">{unit.description}</p> */}
                    </div>
                  </div>

                  <div className="unit_assigned_questions">
                    {unit.questions.map((questionObj, questionIndex) => {
                      const isAnswered = questionObj.isAnswered;
                            const isQuestionSkipped =
                              questionObj.isQuestionSkipped;

                      const opacity =
                        !isAnswered && !isQuestionSkipped ? 0.5 : 1;
                      const isClickable = isAnswered || isQuestionSkipped;
                      return (
                        <div
                          className={`question ${
                            selectedQuestion === questionObj.reference
                              ? "selected "
                              : "non-selected "
                          }${isClickable ? "" : "non-clickable"}`}
                          key={questionIndex}
                          onClick={() =>
                            handleQuestionClick(
                              questionObj,
                              questionIndex,
                              unit
                            )
                          }
                        >
                          <div className="row">
                            <div className="icon">
                              {isAnswered ? (
                                <CheckmarkSvg />
                              ) : isQuestionSkipped ? (
                                <SkippedSvg />
                              ) : (
                                <LockSvg />
                              )}
                            </div>

                            <div className="question-text">
                              <div style={{ flexDirection: "column", display: "flex"}}>
                                <div>
                                  {questionIndex + 1} {questionObj.question}
                                </div>

                                <div>
                                  {questionObj?.reference?.path}
                                </div>
                              </div>

                              {questionObj?.difficulty &&
                                questionObj?.difficulty !== "NA" && (
                                  <div
                                    className="question-xp"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    L{questionObj.difficulty}
                                  </div>
                                )}
                              {isAnswered && questionObj.score === 0 ? (
                                <div className="question-xp">NA</div>
                              ) : isAnswered && questionObj.score > 0 ? (
                                <input
                                  type="text"
                                  className="question-xp"
                                  style={{ textAlign: "center" }}
                                  value={questionObj.score}
                                  readOnly
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            );
          })
      )}
    </>
  )}
</div>

          <div className="search-unit-container">
            {searchQuery &&
              unitsStack
                .concat(courseStack)
                ?.filter((content) =>
                  content.name.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((content, index) => {
                  return (
                    <div className="unit-list" key={index}>
                      <div className="available-units">
                        <span className="unit-text">
                          #{content.type === "course" ? "Course: " : ""}
                          {content.name}
                        </span>
                      </div>
                      <span
                        className="gray-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleAddCourseOrUnit(content)}
                      >
                        <AddIcon
                          width="10"
                          height="10"
                          top="auto"
                          bottom="auto"
                        />
                      </span>
                    </div>
                  );
                })}
          </div>

          <div className="unit_search_box_container">
            <input
              type="text"
              placeholder="Search Units"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="question-view">
          <div className="back_btn">
            <TimeSheetPrevIcon width="12" height="12" icon="backArrow" />
            <p
              onClick={() => {
                setActiveTab("Connections");
                setIsAdminUserViewShow(false);
                setIsCompConnectionsShown(false);
                setIsUserConnectionsShown(true);
                setIsTechConnectionsShown(false);
                setIsRequirementsShown(false);
                setIsDefault(false);
              }}
            >
              Back to user track
            </p>
          </div>

          <div className="question-header">
            <div className="questions-role">
              <label>
                Question # {questionIndex} : {questionName}
              </label>
            </div>
            <div className="question-tech-roles">
              {memberDetails.map((member, index) => (
                <div key={index} className="member">
                  {member.avatar ? (
                    <img
                      src={member.avatar}
                      alt="avatar"
                      className="avatar-image"
                    />
                  ) : (
                    <img
                      alt="nestria-avatar"
                      src="https://info.nestria.org/hubfs/Tombstone%20Static%20Website%20Images/astronaut.png"
                      className="avatar-image"
                    />
                  )}
                  <span>{member.name}</span>
                </div>
              ))}
            </div>
          </div>

          <hr />
          <div className="message-chat">
            {messageData.map((chat, index) => (
              <div className="nestria-chat-section" key={index}>
                <div className="circle">
                  {chat.avatar ? (
                    <img
                      src={chat.avatar}
                      alt="avatar"
                      className="avatar-image"
                    />
                  ) : (
                    <img
                      alt="nestria-avatar"
                      src="https://info.nestria.org/hubfs/Tombstone%20Static%20Website%20Images/astronaut.png"
                      className="avatar-image"
                    />
                  )}
                </div>
                <div className="messages">
                  <p className="message-sender">{`${chat.sender} | ${chat.time}`}</p>
                  <div className="message-content">
                    {chat?.rating !== null && chat?.rating !== "" && (
                      <>
                        <span className="rating">{chat.rating}</span>
                        <br />
                      </>
                    )}
                    {chat.sender !== "Chat GPT" &&
                      chat.sender !== "Nestria" && (
                        <VideoThumbnail
                          questionID={questionID}
                          unitID={unitID}
                          courseID={courseID}
                          currentUser={currentUserRef}
                          firebase={firebase}
                        />
                      )}
                    <p className="message">{chat.message}</p>
                  </div>
                </div>
              </div>
            ))}

            <hr />
          </div>
          <div className="write-msg">
            <textarea
              className="write-msg_notes"
              placeholder="Write a message"
              value={message}
              onChange={handleChangeMessage}
            ></textarea>
          </div>

          <div className="send-btn-container">
            <button
              className="send-btn"
              style={{
                color: "white",
                backgroundColor: "black",
                width: "100%",
              }}
              onClick={handleSendMessage}
            >
              Send
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default AdminUserView;
