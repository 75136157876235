import { useEffect, useState } from "react";
import Spinner from "../../spinner/Spinner";

const BOX_TYPES = {
  unit: {
    colorCode: "#49c0f8" // Blue
  },
  final: {
    colorCode: "rgb(253, 199, 0)" // Yellow
  },
  level: {
    colorCode: "#92d332" // Green
  }
};

export function LessonScoreBox({ type, label, score, isLoading }) {
  const [boxColor, setBoxColor] = useState("");
  const [boxScore, setBoxScore] = useState();

  useEffect(() => {
    console.log(`score: `, score);

    switch (type) {
      case "unit":
        setBoxColor(BOX_TYPES[type].colorCode);
        setBoxScore(`${Math.round(score * 100)} %`);
        break;
      case "final":
        setBoxColor(BOX_TYPES[type].colorCode);
        setBoxScore(`${Math.round(score * 100)} %`);
        break;
      case "level":
        setBoxColor(BOX_TYPES[type].colorCode);
        setBoxScore(`L${Math.round(score)}`);
        break;
      default:
        setBoxColor("#49c0f");
        break;
    }
  }, [score, type]);

  return (
    <>
      <div className="flex flex-wrap justify-center gap-5">
        <div className="rounded-xl border-2 border-green-400 bg-green-400" style={{minWidth: "95px", backgroundColor: boxColor, borderColor: boxColor}}>
          <h2 className="py-1 text-center text-white" style={{color: "rgb(54, 73, 93)", fontWeight: "500"}}>{label}</h2>
          <div className="flex justify-center rounded-xl bg-white py-4 text-green-400" style={{fontWeight: "bold", color: boxColor}}>
            {isLoading || score === null ? (
              <Spinner width="2rem" height="2rem" overlayHeight="4vh" />
            ) : (
              boxScore
            )}
          </div>
        </div>
      </div>
    </>
  );
}
