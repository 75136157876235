export const Like = ({ onClickHandler, isLiked }) => {
  return (
    <svg
      style={{ cursor: "pointer" }}
      onClick={onClickHandler}
      viewBox="0 0 24 24"
      width={"40px"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          style={{
            fill: isLiked ? "#ec9321" : "#ffffff",
            animation: "fade 1s",
          }}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.62436 4.4241C3.96537 5.18243 2.75 6.98614 2.75 9.13701C2.75 11.3344 3.64922 13.0281 4.93829 14.4797C6.00072 15.676 7.28684 16.6675 8.54113 17.6345C8.83904 17.8642 9.13515 18.0925 9.42605 18.3218C9.95208 18.7365 10.4213 19.1004 10.8736 19.3647C11.3261 19.6292 11.6904 19.7499 12 19.7499C12.3096 19.7499 12.6739 19.6292 13.1264 19.3647C13.5787 19.1004 14.0479 18.7365 14.574 18.3218C14.8649 18.0925 15.161 17.8642 15.4589 17.6345C16.7132 16.6675 17.9993 15.676 19.0617 14.4797C20.3508 13.0281 21.25 11.3344 21.25 9.13701C21.25 6.98614 20.0346 5.18243 18.3756 4.4241C16.7639 3.68739 14.5983 3.88249 12.5404 6.02065C12.399 6.16754 12.2039 6.25054 12 6.25054C11.7961 6.25054 11.601 6.16754 11.4596 6.02065C9.40166 3.88249 7.23607 3.68739 5.62436 4.4241ZM12 4.45873C9.68795 2.39015 7.09896 2.10078 5.00076 3.05987C2.78471 4.07283 1.25 6.42494 1.25 9.13701C1.25 11.8025 2.3605 13.836 3.81672 15.4757C4.98287 16.7888 6.41022 17.8879 7.67083 18.8585C7.95659 19.0785 8.23378 19.292 8.49742 19.4998C9.00965 19.9036 9.55954 20.3342 10.1168 20.6598C10.6739 20.9853 11.3096 21.2499 12 21.2499C12.6904 21.2499 13.3261 20.9853 13.8832 20.6598C14.4405 20.3342 14.9903 19.9036 15.5026 19.4998C15.7662 19.292 16.0434 19.0785 16.3292 18.8585C17.5898 17.8879 19.0171 16.7888 20.1833 15.4757C21.6395 13.836 22.75 11.8025 22.75 9.13701C22.75 6.42494 21.2153 4.07283 18.9992 3.05987C16.901 2.10078 14.3121 2.39015 12 4.45873Z"
          fill="white"
        ></path>
      </g>
    </svg>
  );
};

export const Flag = ({ onClickHandler, width }) => {
  return (
    <svg
      style={{ cursor: "pointer" }}
      onClick={onClickHandler}
      width={width || "40px"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#ffffff"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M5 22V14M5 14V4M5 14L7.47067 13.5059C9.1212 13.1758 10.8321 13.3328 12.3949 13.958C14.0885 14.6354 15.9524 14.7619 17.722 14.3195L17.9364 14.2659C18.5615 14.1096 19 13.548 19 12.9037V5.53669C19 4.75613 18.2665 4.18339 17.5092 4.3727C15.878 4.78051 14.1597 4.66389 12.5986 4.03943L12.3949 3.95797C10.8321 3.33284 9.1212 3.17576 7.47067 3.50587L5 4M5 4V2"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        ></path>
      </g>
    </svg>
  );
};

export const Info = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={width || "100"} height={height || "100"} viewBox="0 0 50 50">
      <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
    </svg>
  )
}

export const Retake = ({ onClickHandler }) => {
  return (
    <svg
      style={{ cursor: "pointer" }}
      onClick={onClickHandler}
      width={"40px"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#ffffff"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M13 2L11 3.99545L11.0592 4.05474M11 18.0001L13 19.9108L12.9703 19.9417M11.0592 4.05474L13 6M11.0592 4.05474C11.3677 4.01859 11.6817 4 12 4C16.4183 4 20 7.58172 20 12C20 14.5264 18.8289 16.7793 17 18.2454M7 5.75463C5.17107 7.22075 4 9.47362 4 12C4 16.4183 7.58172 20 12 20C12.3284 20 12.6523 19.9802 12.9703 19.9417M11 22.0001L12.9703 19.9417"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </svg>
  );
};


export const VideoOnSvg = () => {
    return (
    <svg
        fill="#ffffff"
        width="161px"
        height="161px"
        viewBox="-672 -672 3264.00 3264.00"
        xmlns="http://www.w3.org/2000/svg"
        stroke="#ffffff"
        stroke-width="0.019200000000000002"
        >
        <g
            id="SVGRepo_bgCarrier"
            stroke-width="0"
            transform="translate(0,0), scale(1)"
        >
            <rect
            x="-672"
            y="-672"
            width="3264.00"
            height="3264.00"
            rx="1632"
            fill="#858585"
            strokewidth="0"
            ></rect>
        </g>
        <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke="#CCCCCC"
            stroke-width="142.07999999999998"
        >
            {" "}
            <path
            d="M0 240v1440h1500.536v-551.83L1920 1574.06V346.051l-419.464 445.78V240z"
            fill-rule="evenodd"
            ></path>{" "}
        </g>
        <g id="SVGRepo_iconCarrier">
            {" "}
            <path
            d="M0 240v1440h1500.536v-551.83L1920 1574.06V346.051l-419.464 445.78V240z"
            fill-rule="evenodd"
            ></path>{" "}
        </g>
    </svg>
  );
};


export const VideoOffSvg = () => {
    return (
        <svg fill="#ffffff" width="161px" height="161px" viewBox="-672 -672 3264.00 3264.00" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" stroke-width="0.019200000000000002"><g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)"><rect x="-672" y="-672" width="3264.00" height="3264.00" rx="1632" fill="#ff0000" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#ff0000" stroke-width="3.84"></g><g id="SVGRepo_iconCarrier"> <path d="m1421.141 3.007 91.775 64.262L215.481 1919.55l.595.417-.378.54-92.37-64.679L246.487 1680H0V240h1255.139L1421.141 3.007Zm79.395 278.487V791.83L1920 346.05v1228.01l-419.464-445.892V1680H520.95l979.586-1398.506Z" fill-rule="evenodd"></path> </g></svg>
  );
};