import React, { useContext, useState } from 'react';
import OratioContext from '../../oratioContext/OratioContext';
import AcceptModal from '../modal/AcceptModal';

const SearchBox = ({
  handleChange,
  searchData,
  emu,
  truncateString,
  userId,
  userConnections,
  companyConnections,
  techConnections,
  requirementsConnections,
  setIsConnectModal,
  setSelectedSearchData,
  setConnectTypes,
  incomingReqData,
  db,
  selectedData,
  setSelectedData,
  acceptConnectTypes,
  setAcceptConnectTypes,
  setCurrentUserRef2,
  acceptConnection,
  switchWindowDiscussion,
  setActiveTab,
  parent,
  handleSearchResultsClick,
  handleSearchResultsBlur,
  setChatObj
}) => {

  const [isAcceptModal, setIsAcceptModal] = useState(false);

  const {
    setConnectionObj,
    searchTerm,
    setSearchTerm,
    addedUserRefs,
    setAddedUserRefs,
    addedUsers,
    setAddedUsers,
    setFilteredResults
  } = useContext(OratioContext);

  // updates connect data obj for sending connection requests
  const updateConnectData = item => {
    const key = item?.ref?.parent?.id;
    setIsConnectModal(true);
    setSelectedSearchData(item);
    setConnectTypes(
      key === 'users' ?
      'user'
      :
      key === 'technologies' ?
      'Technology'
      :
      key === 'lists' ?
      'requirements'
      :
      'company'
      );
  };

  // switches to the discussion section
  const updateSendMessageData = async (
    item,
    connection,
    compConnection,
    techConnection,
    reqConnection
  ) => {
    // gets chat data from the chatRef embeded in each connection
    let chatRef;
    if (item?.key === 'user') {
      chatRef = connection?.chatRef;
    } else if (item?.key === 'company') {
      chatRef = compConnection?.chatRef;
    } else if (item?.key === 'technologies') {
      chatRef = techConnection?.chatRef;
    } else if (item?.key === 'requirements') {
      chatRef = reqConnection?.chatRef;
    };

    const chatDoc = await chatRef?.get();
    const chatData = chatDoc?.data();
    const chatDataObj = { ...chatData, ref: chatRef };

    setChatObj(chatDataObj);
    setConnectionObj(item);
    switchWindowDiscussion();
    setActiveTab('Discussions');
  };
    
  // updates accept data obj for accepting connection requests
  const updateAcceptConnectData = item => {
    const key = item?.ref?.parent?.id;
    setIsAcceptModal(true);
    setSelectedData(item);
    setCurrentUserRef2(item?.ref);
    setAcceptConnectTypes(
      key === 'users' ?
      'user'
      :
      key === 'technologies' ?
      'Technology'
      :
      key === 'lists' ?
      'requirements'
      :
      'company'
      );
  };


  // adds the selected user & user ref object to the addedUserRefs & addedUsers arrays respectively
  const handleUserClick = (user) => {
    // Checks if the user is already in the addedUserRefs array
    if (!addedUserRefs.some((addedUser) => addedUser?.id === user?.id)) {
      setAddedUserRefs([...addedUserRefs, user?.ref]);
      setAddedUsers([...addedUsers, user]);
    };
    setSearchTerm('');
    setFilteredResults([]);
  };

  return(
    <>
      <div className={parent === 'ConnectionWindow' ? 'search_box_container' : 'new_group_search_box_container'}>
        <input
          type="text"
          // placeholder="&#61442;"
          placeholder={parent === 'ConnectionWindow' ? 'Search and add' : 'Search users'}
          onChange={handleChange}
          onFocus={handleSearchResultsClick}
          onBlur={handleSearchResultsBlur}
          // disabled={isErrorMsgModal}
        />
        {searchData?.length > 0 ?
        <div className='search_dropdown'>
          {searchData?.map((item, idx) => {
            // checks if search results (users) exist in the `userConnections` array
            const connection = userConnections?.find((connection) => connection?.ref?.id === item?.ref?.id);
            // checks if search results (users) exist in the `incomingReqData` array
            const incomingReq = incomingReqData?.find((req) => req?.incomingUserReqRef?.id === item?.ref?.id);
            // checks if search results (company) exist in the `companyConnections` array
            const compConnection = companyConnections?.find((connection) => connection?.ref?.id === item?.ref?.id);
            // checks if search results (technology) exist in the `techConnections` array
            const techConnection = techConnections?.find((connection) => connection?.ref?.id === item?.ref?.id);
            // checks if search results (technology) exist in the `requirementsConnections` array
            const reqConnection = requirementsConnections?.find((connection) => connection?.ref?.id === item?.ref?.id);

            return (
            <div key={idx}>
              {parent === 'ConnectionWindow' ?
                // return these data if the parent comp is ConnectionWindow
                <div
                  className='search_results'
                >
                  {item?.ref?.parent?.id !== 'lists' ?
                    <img src={item?.avatar ?? item?.logoStorage ?? emu} className='search_results_avatar' alt="avatar" />
                    :
                    null
                  }
                  <div className='search_result_data'>
                    <p>
                      {item?.key === 'user' ?
                      truncateString(item?.first + " " + item?.last, 21)
                      :
                      item?.key === 'company' ?
                      truncateString(item?.crm?.data?.properties?.name?.value ?? item?.profile?.name, 50)
                      :
                      (item?.key === 'tech' || item?.key === 'requirements') ?
                      truncateString(item?.name, 50)
                      :
                      null
                      }
                    </p>
                    <p>
                      {item?.key === 'user' && <span className='dots'></span>}
                      {item?.email}
                    </p>
                    <p className='motto'>
                      {(item?.key === 'user' && item?.name !== undefined) &&
                        <>
                          <span className='dots'></span>
                          {truncateString(item?.name, 15)}
                        </>
                      }
                      {(item?.key === 'company' && item?.profile?.motto !== undefined) &&
                        <>
                          <span className='dots'></span>
                          {truncateString(item?.profile?.motto, 50)}
                        </>
                      }
                      {((item?.key === 'tech' || item?.key === 'requirements') && item?.description !== undefined) &&
                        <>
                          <span className='dots'></span>
                          {truncateString(item?.description, 50)}
                        </>
                      }
                    </p>
                  </div>
                  {/* renders `Message` & `Pending` buttons if search result(s) exist in user connections
                  and if search result isn't the current user */}
                  {((connection || compConnection || techConnection || reqConnection) && userId !== item?.ref?.id) ? (
                    (connection?.reciprocal || compConnection?.reciprocal || techConnection?.reciprocal || reqConnection?.reciprocal) ?
                    <>
                      <button
                        className='search_message_btn'
                        onClick={() => updateSendMessageData(item, connection, compConnection, techConnection, reqConnection)}
                      >
                        Message
                      </button>
                    </>
                    :
                    <>
                      <div className='search_pending_btn'>
                        Pending
                      </div>
                    </>
                  )
                  :
                  // renders `Connect` button if search result(s) do not exist in user connections
                  // and if search result isn't the current user
                  (incomingReq === undefined && userId !== item?.ref?.id) &&
                    <>
                      <button
                        className='search_connect_btn'
                        onClick={() => updateConnectData(item)}
                      >
                        Connect
                      </button>
                    </>
                    
                  }
                  {/* renders `Accept` button if search result(s) exist in incoming requests array
                  and if search result isn't the current user */}
                  {(incomingReq && userId !== item?.ref?.id) &&
                    <button
                      className='accept_request_btn'
                      onClick={() => updateAcceptConnectData(item)}
                    >
                      Accept
                    </button>
                  }
                </div>
                :
                // return these data if the parent comp is NewGroupModal
                <>
                  {/* only return search results when the input field is not empty */}
                  <div
                    className='search_results'
                    onClick={() => {
                      handleUserClick(item);
                    }}
                  >
                    <>
                      <img src={item?.avatar ?? item?.logoStorage ?? emu} className='search_results_avatar' alt="avatar" />
                      <div className='search_result_data'>
                        <p>
                          {truncateString(item?.first + " " + item?.last, 21)}
                        </p>
                      </div>
                    </>
                  </div>
                </>
              }
            </div>
          )})}
        </div>
        :
        searchTerm !== '' && <div className='no_data_found'>No matching data found!</div>
        }
      </div>
      {/* accept connection request modal */}
      {isAcceptModal &&
        <AcceptModal
          closeModal={() => setIsAcceptModal(false)}
          acceptConnection={acceptConnection}
          db={db}
          selectedData={selectedData}
          setCurrentUserRef2={setCurrentUserRef2}
          acceptConnectTypes={acceptConnectTypes}
        />
      }
    </>
  );
};

export default SearchBox;