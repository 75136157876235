import React, { useEffect, useState } from "react";
import "./style.css";
import ConnectionWindow from "../pages/connectionWindow/ConnectionWindow";
import Avatar from "../components/chatButton/Avatar.js";
import "../styles_css/scroll_bar_styling.css";
// import logo from '../static/spryte-logo.png';
import logo from "../static/logo2.png";
import Discussion from "./discussionWindow/Discussion";
import { OratioProvider } from "../oratioContext/OratioContext";
import ConnectionRequest from "./connectionRequest/ConnectionRequest";
import SettingsPanel from "./settingsPanel/SettingsPanel";
import Login from "./login/Login";
import NestriaLogin from "./login/Login.nestria";
import NestriaNavSection from "../components/navSection/NavSection.nestria";
import NavSection from "../components/navSection/NavSection";
import UserStatusModal from "../components/modal/UserStatusModal";
import CheckIn from "./checkin/Checkin";
import Questions from "../components/nestria/Admin/Questions";
import Units from "../components/nestria/Admin/Units";
import Courses from "../components/nestria/Admin/Courses";
import Nestria from "../components/nestria/Home/Home";
import NestriaRegister from "../components/nestria/Register";
import Spinner from "../components/spinner/Spinner";
import { getDevIdFromUserId } from "../components/nestria/helpers/store";
import {
  AuthContext,
  DbContext,
  FirebaseContext,
  CurrentUserContext,
} from "../components/nestria/Context/Nestria";

function SpryteConnections({
  firebase,
  isLoggedIn,
  app,
  auth,
  db,
  forceShowLogin,
  isNestriaUserLoggedIn,
}) {
  const [visible, setVisible] = useState(false);
  const [discussionVisible, setDiscussionVisible] = useState(false);
  const [requestVisible, setRequestVisible] = useState(false);
  const [checkinVisible, setCheckinVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const [registerVisible, setRegisterVisible] = useState(false);
  // const [spryteLogoVisible, setSpryteLogoVisible] = useState(false);
  const [isPackageVisible, setIsPackageVisible] = useState(false);
  const [currentUserObj, setCurrentUserObj] = useState(null);
  const [activeTab, setActiveTab] = useState("Connections");
  const [devsReportingAccount, setDevsReportingAccount] = useState(false);
  const [devsReportingReference, setDevsReportingReference] = useState(false);
  const [isStatusModal, setIsStatusModal] = useState(false);
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [showInviteDismissBtn, setShowInviteDismissBtn] = useState(true);
  const [isShowcheckIn, setIsShowCheckIn] = useState(true);
  const [isShowPause, setIsShowPause] = useState(true);
  const [checkinDate, setCheckinDate] = useState(null);
  const [chatObj, setChatObj] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState("");
  const [userRole, setUserRole] = useState("");
  const [currentUserRole2, setCurrentUserRole2] = useState("");
  const [userRole2, setUserRole2] = useState("");
  const windowSize = window.innerWidth;

  const [isNestria, setIsNestria] = useState(false);
  const [isNestriaAdminApp, setIsNestriaAdminApp] = useState(false);
  const [isNestriaStudentApp, setIsNestriaStudentApp] = useState(false);
  const [justRegistered, setJustRegistered] = useState(false);

  // // firebase app
  // let app = firebase.app();
  // // firebase auth
  // const auth = app.auth();
  // // firestore db
  // const db = app.firestore();
  // current user obj
  const currentUser = auth?.currentUser;
  // current user ID
  const currUserId = currentUser?.uid;

  useEffect(() => {
    const hostName = window.location.hostname;
    // const hostName = window.location.pathname;
    // nestria.localhost -> to access student app
    // admin.nestria.localhost -> to access admin app
    if (hostName.indexOf("nestria") !== -1) {
      setIsNestria(true);
      if (hostName.indexOf("admin") !== -1) {
        setIsNestriaAdminApp(true);
      } else {
        setIsNestriaStudentApp(true);
      }
    }

    if (hostName.indexOf("localhost") !== -1) {
      localStorage.setItem("debugMode", "true");
    }

    if (localStorage.getItem("debugMode")) {
      if (localStorage.getItem("debugMode") !== "true") {
        console.log = () => {};
        console.error = () => {};
      }
    } else {
      console.log = () => {};
      console.error = () => {};
    }
  }, []);

  useEffect(() => {
    if (forceShowLogin === true) {
      setLoginVisible(true);
    }
  }, [forceShowLogin]);

  //check for user devreporting account
  useEffect(() => {
    const getDevReportingReference = async () => {
      if (currUserId) {
        let spryteDevUserSnapShot = db.doc(
          `/users/${currUserId}/accounts/spryte-dev`
        );
        let user = await spryteDevUserSnapShot.get();
        let devreportingref;
        if (user?.data()?.devsReportingId !== undefined) {
          setDevsReportingAccount(true);
          setDevsReportingReference(user?.data()?.devsReportingId);
          devreportingref = user?.data()?.devsReportingId;
        }
        const presenceRef = devreportingref?.collection("presence");
        // Get the current date
        const currentDate = new Date();
        // Set the start and end timestamps for the current date
        const startOfDay = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        const endOfDay = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 1
        );
        const presenceQuery = presenceRef
          ?.where("checkIn", ">=", startOfDay)
          ?.where("checkIn", "<", endOfDay);
        let presence = await presenceQuery?.get();
        presence?.forEach((doc) => {
          let timesheetData = doc?.data();
          timesheetData?.presenceData?.map((data, i) => {
            setCheckinDate(timesheetData?.checkIn?.toDate());
            setIsShowCheckIn(false);
            if (i === timesheetData?.presenceData?.length - 1) {
              if (data?.type === "unpause") {
                setIsShowPause(true);
              } else if (data?.type === "pause") {
                setIsShowPause(false);
              } else if (data?.type === "checkin") {
                setIsShowPause(true);
              }
            }
          });
        });
      }
    };
    getDevReportingReference();

    if (currUserId === undefined || currUserId === null) {
      setCurrentUserObj(null);
    }
  }, [currUserId]);

  // current user details object
  useEffect(() => {
    const getProfileDetails = async () => {
      if (currUserId !== undefined) {
        const currUserRef = db.doc(`users/${currUserId}`);
        const devId = await getDevIdFromUserId(currUserId);

        const currUserRes = currUserRef?.onSnapshot((snapshot) => {
          const currUserRef = snapshot?.ref;
          const currUserData = snapshot?.data();

          const userDetailObj = {
            id: currUserRef?.id,
            ref: currUserRef,
            devId,
            ...currUserData,
          };
          setCurrentUserObj(userDetailObj);
        });

        return currUserRes;
      }
    };
    getProfileDetails();
  }, [currUserId]);

  // truncates strings longer than a specified num of chars
  const truncateString = (str, limit) => {
    if (str?.length > limit) {
      return str.slice(0, limit) + "...";
    } else {
      return str;
    }
  };

  // truncates numbers greater than 99
  const truncateNum = (num) => {
    if (num > 99) {
      return 99 + "+";
    } else {
      return num;
    }
  };

  const switchToSpryteLogo = () => {
    // setSpryteLogoVisible(true);
    setVisible(false);
    setDiscussionVisible(false);
    setRequestVisible(false);
    setCheckinVisible(false);
    setSettingsVisible(false);
  };

  const switchWindowConnections = () => {
    setVisible(true);
    setDiscussionVisible(false);
    setRequestVisible(false);
    setCheckinVisible(false);
    setSettingsVisible(false);
    // setSpryteLogoVisible(false);
  };

  const switchWindowDiscussion = () => {
    setDiscussionVisible(true);
    setVisible(false);
    setRequestVisible(false);
    setCheckinVisible(false);
    setSettingsVisible(false);
    // setSpryteLogoVisible(false);
  };

  const switchToRequest = () => {
    setRequestVisible(true);
    setDiscussionVisible(false);
    setVisible(false);
    setSettingsVisible(false);
    setCheckinVisible(false);
    // setSpryteLogoVisible(false);
  };

  const switchToCheckIn = () => {
    setRequestVisible(false);
    setDiscussionVisible(false);
    setVisible(false);
    setCheckinVisible(true);
    setSettingsVisible(false);
    // setSpryteLogoVisible(false);
  };

  const switchToSettings = () => {
    setSettingsVisible(true);
    setRequestVisible(false);
    setDiscussionVisible(false);
    setCheckinVisible(false);
    setVisible(false);
    // setSpryteLogoVisible(false);
  };

  // toggles SpryteConnections package as well as its pages
  const showPackage = () => {
    setVisible(true);
    setDiscussionVisible(false);
    setRequestVisible(false);
    setSettingsVisible(false);
    setIsPackageVisible((prev) => !prev);
    setIsStatusModal(false);
    setCheckinVisible(false);

    if (!isLoggedIn && !isNestriaUserLoggedIn) {
      setLoginVisible(true);
    }
    // setSpryteLogoVisible(false);
  };

  // launches the app on first render on tablet and mobile
  // useMemo(() => {
  //   if (windowSize <= 1040) {
  //     showPackage();
  //   };
  // }, [windowSize]);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1040);
  }, []);

  // launches the app on first render on tablet and mobile
  useEffect(() => {
    if (isMobile) {
      showPackage();
    }
    if (isNestria && isNestriaAdminApp) {
      showPackage();
    }
  }, [isMobile]);

  // get current user role and role refs
  useEffect(() => {
    const roleRef = db.collection(
      "/userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-DEV/ROLES"
    );
    const roleRef2 = db.collection(
      "/userRoles/XMCNKKi1kZJjpzSOKa52/PLATFORMS/SPRYTE-PARTNER/ROLES"
    );
    const getCurrentUser = async () => {
      if (!isNestria) {
        // spryte-dev role ref
        const currentUserRef = await db
          .doc(`users/${currUserId}/accounts/spryte-dev`)
          .get();
        const currUserRoleData = currentUserRef?.data();
        // spryte-partner role ref
        const currentUserRef2 = await db
          .doc(`users/${currUserId}/accounts/spryte-partner`)
          .get();
        const currUserRoleData2 = currentUserRef2?.data();
        // console.log({ currUserRoleData, currUserRoleData2 })
        setCurrentUserRole(currUserRoleData);
        setCurrentUserRole2(currUserRoleData2);
      }
      setUserRole(roleRef);
      setUserRole2(roleRef2);
      getCurrentUser();
    };
  }, [currUserId]);

  // If Nestria Student App - Increase font size to 100% and change background
  if (isNestria && isNestriaStudentApp) {
    if (document.getElementsByTagName("html")[0]) {
      document.getElementsByTagName("html")[0].style.fontSize = "100%";
      document.getElementsByTagName("html")[0].style.scrollBehavior = "smooth";
    }
    if (document.getElementsByTagName("body")[0]) {
      document.getElementsByTagName("body")[0].style.backgroundColor =
        "rgb(256, 256, 256)";
    }
  }

  if (isNestria && isNestriaAdminApp) {
    if (document.getElementsByTagName("html")[0]) {
      document.getElementsByTagName("html")[0].style.fontSize = "85%";
    }
  }

  return (
    <div
      className={
        isNestria && isNestriaStudentApp ? "enable-scrolling-for-nestria" : ""
      }
    >
      {isPackageVisible && (
        <>
          {isLoggedIn ? (
            <OratioProvider>
              {isNestria && isNestriaAdminApp ? (
                <NestriaNavSection
                  switchToSpryteLogo={switchToSpryteLogo}
                  switchWindowConnections={switchWindowConnections}
                  switchWindowDiscussion={switchWindowDiscussion}
                  switchToRequest={switchToRequest}
                  switchToCheckIn={switchToCheckIn}
                  switchToSettings={switchToSettings}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  currentUserObj={currentUserObj}
                  devsReportingAccount={devsReportingAccount}
                  isShowcheckIn={isShowcheckIn}
                  isShowPause={isShowPause}
                  truncateNum={truncateNum}
                  checkinDate={checkinDate}
                  setIsShowCheckIn={setIsShowCheckIn}
                  isNestria={isNestria}
                />
              ) : isNestria && isNestriaStudentApp ? (
                currentUserObj?.ref ? (
                  <>
                    <AuthContext.Provider value={auth}>
                      <DbContext.Provider value={db}>
                        <FirebaseContext.Provider value={firebase}>
                          <CurrentUserContext.Provider value={currentUserObj}>
                            <Nestria justRegistered={justRegistered} />
                          </CurrentUserContext.Provider>
                        </FirebaseContext.Provider>
                      </DbContext.Provider>
                    </AuthContext.Provider>
                  </>
                ) : (
                  <Spinner
                    width="6.25rem"
                    height="6.25rem"
                    overlayHeight="50vh"
                  />
                )
              ) : (
                <NavSection
                  switchToSpryteLogo={switchToSpryteLogo}
                  switchWindowConnections={switchWindowConnections}
                  switchWindowDiscussion={switchWindowDiscussion}
                  switchToRequest={switchToRequest}
                  switchToCheckIn={switchToCheckIn}
                  switchToSettings={switchToSettings}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  currentUserObj={currentUserObj}
                  devsReportingAccount={devsReportingAccount}
                  isShowcheckIn={isShowcheckIn}
                  isShowPause={isShowPause}
                  truncateNum={truncateNum}
                  checkinDate={checkinDate}
                  setIsShowCheckIn={setIsShowCheckIn}
                />
              )}

              {isNestria && isNestriaAdminApp && activeTab === "Discussions" ? (
                <Units db={db} currentUser={currentUser} />
              ) : isNestria && isNestriaStudentApp ? (
                <></>
              ) : (
                <ConnectionWindow
                  visible={visible}
                  currentUser={currentUser}
                  firebase={firebase}
                  db={db}
                  truncateString={truncateString}
                  switchWindowDiscussion={switchWindowDiscussion}
                  setActiveTab={setActiveTab}
                  currentUserObj={currentUserObj}
                  showInviteUser={showInviteUser}
                  setShowInviteUser={setShowInviteUser}
                  showInviteDismissBtn={showInviteDismissBtn}
                  setShowInviteDismissBtn={setShowInviteDismissBtn}
                  chatObj={chatObj}
                  setChatObj={setChatObj}
                  currentUserRole={currentUserRole}
                  userRole={userRole}
                  currentUserRole2={currentUserRole2}
                  userRole2={userRole2}
                  isNestria={isNestria}
                />
              )}
              {isNestria && isNestriaAdminApp && activeTab === "Discussions" ? (
                <></>
              ) : isNestria && isNestriaStudentApp ? (
                <></>
              ) : (
                <Discussion
                  currentUser={currentUser}
                  discussionVisible={discussionVisible}
                  db={db}
                  firebase={firebase}
                  truncateString={truncateString}
                  chatObj={chatObj}
                  setChatObj={setChatObj}
                  windowSize={windowSize}
                  currentUserRole={currentUserRole}
                  userRole={userRole}
                  currentUserRole2={currentUserRole2}
                  userRole2={userRole2}
                  isNestria={isNestria}
                />
              )}
              {isNestria && isNestriaAdminApp && activeTab === "Requests" ? (
                <Questions db={db} currentUser={currentUser} />
              ) : isNestria && isNestriaStudentApp ? (
                <></>
              ) : (
                <ConnectionRequest
                  currentUser={currentUser}
                  requestVisible={requestVisible}
                  db={db}
                  currentUserObj={currentUserObj}
                  setShowInviteUser={setShowInviteUser}
                  setShowInviteDismissBtn={setShowInviteDismissBtn}
                  truncateString={truncateString}
                  windowSize={windowSize}
                  isNestria={isNestria}
                />
              )}

              {isNestria && activeTab === "Checkins" ? (
                <Courses db={db} currentUser={currentUser} />
              ) : (
                <>
                  {devsReportingAccount && (
                    <CheckIn
                      db={db}
                      checkinVisible={checkinVisible}
                      currentUserObj={currentUserObj}
                      firebase={firebase}
                      devsReportingReference={devsReportingReference}
                      isShowcheckIn={isShowcheckIn}
                      setIsShowCheckIn={setIsShowCheckIn}
                      isShowPause={isShowPause}
                      setIsShowPause={setIsShowPause}
                    />
                  )}
                </>
              )}
              <SettingsPanel
                settingsVisible={settingsVisible}
                firebase={firebase}
                auth={auth}
                currentUser={currentUser}
                currentUserObj={currentUserObj}
                db={db}
                truncateString={truncateString}
                isNestria={isNestria}
              />
            </OratioProvider>
          ) : isNestria ? (
            <>
             <AuthContext.Provider value={auth}>
                      <DbContext.Provider value={db}>
                        <FirebaseContext.Provider value={firebase}>
                          <CurrentUserContext.Provider value={currentUserObj}>
              <NestriaLogin
                auth={auth}
                loginVisible={loginVisible}
                setLoginVisible={setLoginVisible}
                setRegisterVisible={setRegisterVisible}
              />
              </CurrentUserContext.Provider>
              </FirebaseContext.Provider>
              </DbContext.Provider>
              </AuthContext.Provider>
              
              <AuthContext.Provider value={auth}>
                      <DbContext.Provider value={db}>
                        <FirebaseContext.Provider value={firebase}>
                          <CurrentUserContext.Provider value={currentUserObj}>

              <NestriaRegister
                db={db}
                setJustRegistered={setJustRegistered}
                registerVisible={registerVisible}
                setRegisterVisible={setRegisterVisible}
                setLoginVisible={setLoginVisible}
                firebase={firebase}
              />
              </CurrentUserContext.Provider>
              </FirebaseContext.Provider>
              </DbContext.Provider>
              </AuthContext.Provider>
            </>
          ) : (
            <React.Fragment>
              <NestriaLogin
                auth={auth}
                loginVisible={loginVisible}
                setLoginVisible={setLoginVisible}
                setRegisterVisible={setRegisterVisible}
              />
            </React.Fragment>
          )}
        </>
      )}
      <>
        {isLoggedIn ? (
          <>
            <div
              className="loggedIn_avatar"
              // className={currentUserObj?.avatar ? 'loggedIn_avatar curr_user_avatar' : 'loggedIn_avatar'}
              onClick={() => showPackage()}
            >
              <img src={currentUserObj?.avatar ?? logo} alt="avatar" />
            </div>
            <div
              onClick={() =>
                isPackageVisible && setIsStatusModal((prev) => !prev)
              }
            >
              <p className="loggedIn_status"></p>
              <div className="status_modal">
                {isStatusModal && <UserStatusModal />}
              </div>
            </div>
          </>
        ) : (
          <Avatar
            onClick={() => showPackage()}
            style={{
              position: "fixed",
              // bottom: '1.5rem',
              // right: '1.5rem',
              bottom: "0.75rem",
              right: "4.5rem",
              cursor: "pointer",
            }}
          />
        )}
      </>
    </div>
  );
}

export default SpryteConnections;
