import React from 'react';

const SettingsCatsDotsIcon = ({ width, height, bottom, right }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom: bottom, marginRight: right }}
    >
      <rect
        x="1"
        y="1"
        width="53"
        height="53"
        rx="26.5"
        fill="white"
        stroke="#B9B9B9"
        strokeWidth="2"
      />
    </svg>
  );
};

export default SettingsCatsDotsIcon;
