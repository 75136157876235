import AudioAnswer from "./AudioAnswer";
import VideoAnswer from "./VideoAnswer";

export default function AnswerCard({
    question,
    index,
    url,
    currentIndex,
    userName,
    score,
    likeCount,
    currentUserId,
    retakePreviousQuestion,
    setShowUserAnswers,
    setIsFeedbackPopupOpen,
    submissionDocRef,
    contentType,
    audioContentBase64,
    correctAnswer,
}) {

    if (!contentType || typeof (contentType) !== 'string') {
        return;
    }

    if (contentType.toLowerCase().includes("video")) {
        return <VideoAnswer
            question={question}
            index={index}
            url={url}
            currentIndex={currentIndex}
            userName={userName}
            score={score}
            likeCount={likeCount}
            currentUserId={currentUserId}
            retakePreviousQuestion={retakePreviousQuestion}
            setShowUserAnswers={setShowUserAnswers}
            setIsFeedbackPopupOpen={setIsFeedbackPopupOpen}
            submissionDocRef={submissionDocRef}
            contentType={contentType}
        />
    }

    if (contentType.toLowerCase().includes("audio")) {
        return <AudioAnswer
            question={question}
            url={url}
            index={index}
            currentIndex={currentIndex}
            userName={userName}
            likeCount={likeCount}
            retakePreviousQuestion={retakePreviousQuestion}
            setIsFeedbackPopupOpen={setIsFeedbackPopupOpen}
            setShowUserAnswers={setShowUserAnswers}
            correctAnswer={correctAnswer}
            submissionDocRef={submissionDocRef}
            currentUserId={currentUserId}
        />
    }
}