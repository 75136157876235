import React, { useContext, useEffect, useState } from "react";
import Spinner from "../../spinner/Spinner";
import OratioContext from "../../../oratioContext/OratioContext";
import DisconnectModal from "../../modal/DisconnectModal";
import NotificationModal from "../../modal/NotificationModal";
// import DownArrow from '../svgs/DownArrow';
import NotificationIcon from "../../svgs/NotificationIcon";
import OptionsIcon from "../../svgs/OptionsIcon";
import NoAvatar from "../../noAvatar/NoAvatar";
import AdminUserView from "./AdminUserView";
import { DataGrid } from "@mui/x-data-grid";
import {
  countStreak,
  coutStreakNoPromise,
  formatDateToISO8601,
  getNestriaDataPointTypes,
} from "../helpers/store";

const NestriaUserConnections = ({
  db,
  loading,
  disconnect,
  isDisconnectModal,
  setIsDisconnectModal,
  SwitchToViewsSection,
}) => {
  // Destructure prop/value from the OratioContext component
  const { connectionObj, setConnectionObj } = useContext(OratioContext);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [columns, setColumns] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [dataPointsByCategory, setDataPointsByCategory] = useState({});

  // Responsible for fetching users from db and setting them as table rows
  useEffect(() => {
    db.collection("users")
      .get()
      .then((allUsers) => {
        Promise.all(
          allUsers.docs.map((user) => {
            let userObj = user.data();
            userObj.ref = user.ref;
            return userObj;
          })
        ).then((userData) => {
          let rows = [];
          Promise.all(
            userData.map((user) => {
              rows.push({
                id: user?.ref?.id,
                user: {
                  email: user?.email,
                  first: user?.first,
                  last: user?.last,
                  avatar: user?.avatar,
                },
                nestriaStats: user?.nestriaStats,
                userConnection: user,
              });
            })
          ).then(() => {
            setRows(rows);
          });
        });
      });
  }, []);

  useEffect(() => {
    if (searchQuery != "" && rows?.length > 0) {
      let rowsFiltered = [];
      Promise.all(
        rows.map((row) => {
          if (
            row?.user?.email
              ?.toLowerCase()
              ?.includes(searchQuery.toLowerCase()) ||
            row?.user?.first
              ?.toLowerCase()
              ?.includes(searchQuery.toLowerCase()) ||
            row?.user?.last?.toLowerCase()?.includes(searchQuery.toLowerCase())
          ) {
            rowsFiltered.push(row);
          }
        })
      ).then(() => {
        setFilteredRows(rowsFiltered);
      });
    }
  }, [searchQuery]);

  /**
   * Process and render stats columns
   */
  useEffect(() => {
    getNestriaDataPointTypes().then((dataPointCategories) => {
      Promise.all(
        dataPointCategories.docs.map((category) => {
          let categoryData = category.data();
          category.ref
            .collection("dataPoints")
            .get()
            .then((dataPoints) => {
              if (!dataPoints?.empty) {
                let thisCategoryDataPoints = [];
                formatColumnValues(dataPoints, thisCategoryDataPoints).then(
                  () => {
                    let tempDataPointsByCategory = dataPointsByCategory;
                    tempDataPointsByCategory[categoryData?.displayName] =
                      thisCategoryDataPoints;
                    setDataPointsByCategory(tempDataPointsByCategory);
                    console.log(`all columns`);
                    console.log(allColumns);
                  }
                );
              }
            });
        })
      );
    });
  }, []);

  /**
   * Formats column values
   * @param {*} dataPoints
   * @param {*} thisCategoryDataPoints
   * @returns
   */
  function formatColumnValues(dataPoints, thisCategoryDataPoints) {
    return Promise.all(
      dataPoints?.docs.map((dataPoint) => {
        let dataPointData = dataPoint.data();
        thisCategoryDataPoints.push(dataPoint?.id);
        setAllColumns((oldColumns) => [
          ...oldColumns,
          {
            field: `nestriaStats.${dataPoint?.id}`,
            valueGetter: (row) => {
              if (row?.row?.nestriaStats) {
                if (!row?.row?.nestriaStats[dataPoint?.id]) {
                  return 0;
                }

                if (dataPointData?.data_type === "date") {
                  let ts = row?.row?.nestriaStats[dataPoint?.id];
                  return formatDateXDaysAgo(ts);
                }

                if (dataPoint?.id === "streak") {
                  let streakDates = row?.row?.nestriaStats[dataPoint?.id];
                  if (streakDates?.length === 0) {
                    return 0;
                  }
                  return coutStreakNoPromise(streakDates);
                }

                return row?.row?.nestriaStats[dataPoint?.id];
              } else {
                return 0;
              }
            },
            headerName: dataPointData?.display_text,
            hideable: true,
            description: dataPointData?.tool_tip_text,
          },
        ]);
      })
    );
  }

  /**
   * Returns how many days ago the given timestamp
   * is from today.
   * @param {Firebase Timestamp} timestamp
   * @returns
   */
  function formatDateXDaysAgo(timestamp) {
    const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
    let diffInDays = Math.round(
      (new Date() - timestamp?.toDate()) / ONE_DAY_IN_MS
    ).toFixed(0);
    if (diffInDays <= 0) {
      return "Today";
    } else {
      return `${diffInDays} day(s) ago`;
    }
  }

  useEffect(() => {
    setColumns(allColumns);
  }, [allColumns]);

  /**
   * Click Handler to show all columns
   */
  function showAllColumnsClickHandler() {
    console.log(allColumns);
    setColumns(allColumns);
  }

  /**
   * Click handler to show filtered columns
   * filtered by category
   * @param {String} category
   */
  function categoryFilterOnClickHandler(category) {
    let tempFilteredColumns = [];
    if (dataPointsByCategory[category]) {
      console.log(dataPointsByCategory[category]);
      Promise.all(
        allColumns.map((column) => {
          console.log(column);
          let cleanColumnName = column?.field?.replace("nestriaStats.", "");
          if (dataPointsByCategory[category]?.includes(cleanColumnName)) {
            tempFilteredColumns.push(column);
          }
        })
      ).then(() => {
        setColumns(tempFilteredColumns);
      });
    }
  }

  return (
    <div className="users-connections">
      <div className="search_box_container">
        <input
          type="text"
          placeholder="Search Users by Name or Email"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {loading ? (
        <Spinner width="6.25rem" height="6.25rem" overlayHeight="50vh" />
      ) : (
        <div>
          <div
            style={{
              display: "grid",
              alignItems: "center",
              gridAutoFlow: "column",
            }}
          >
            <button
              style={{
                padding: "5px",
                paddingLeft: "10px",
                paddingRight: "10px",
                borderRadius: "3px",
                background: "#dfdede",
                margin: "20px",
              }}
              onClick={() => showAllColumnsClickHandler()}
            >
              All
            </button>

            {Object.keys(dataPointsByCategory)?.map((cat) => {
              return (
                <button
                  style={{
                    padding: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    borderRadius: "3px",
                    background: "#dfdede",
                    margin: "20px",
                  }}
                  onClick={() => categoryFilterOnClickHandler(cat)}
                >
                  {cat}
                </button>
              );
            })}
          </div>
          <DataGrid
            rows={searchQuery !== "" ? filteredRows : rows}
            columns={[
              {
                field: "user",
                headerName: "Email",
                width: 350,
                sortable: false,
                hideable: false,
                renderCell: (props) => {
                  return (
                    <div
                      className="user-card"
                      key={props?.row?.id}
                      onClick={() =>
                        SwitchToViewsSection(props?.row?.userConnection)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {props?.row?.user?.avatar ? (
                        <div className="user-avatar">
                          <img
                            src={props?.row?.user?.avatar}
                            className="avatar_image"
                            alt="avatar"
                          />
                        </div>
                      ) : (
                        <div className="user-avatar">
                          <NoAvatar
                            text={props?.row?.user?.first}
                            size={3.5}
                            radius={0}
                          />
                        </div>
                      )}
                      <div className="info-section">
                        <div className="user-info">
                          <p className="name">
                            {props?.row?.user?.first +
                              " " +
                              props?.row?.user?.last}
                          </p>
                          <p id="email" style={{ opacity: 0.7 }}>
                            {props?.row?.user?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                },
              },
              ...columns,
            ]}
            getRowHeight={() => {
              return 80;
            }}
          ></DataGrid>
        </div>
      )}
      {/* Confirmation modal */}
      {isDisconnectModal && (
        <DisconnectModal
          connectionObj={connectionObj}
          closeModal={() => setIsDisconnectModal(false)}
          disconnect={disconnect}
        />
      )}
    </div>
  );
};

export default NestriaUserConnections;
