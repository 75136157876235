import { Mixpanel } from "../helpers/Mixpanel";

/**
 * A confirmation pop up to quit the question and answer screen
 */
export const QuitMessage = ({
    quitMessageShown,
    setQuitMessageShown,
    setShowLesson,
    setReload,
    _DATA_POINTS,
  }) => {
    return (
      <>
        <div
          className={
            quitMessageShown
            ? "quit-message-container fixed top-0 bottom-0 left-0 right-0 z-30 bg-black bg-opacity-60 transition-all duration-300"
            : "pointer-events-none quit-message-container fixed top-0 bottom-0 left-0 right-0 z-30 bg-black bg-opacity-0 transition-all duration-300"
          }
          onClick={() => setQuitMessageShown(false)}
          aria-label="Close quit message"
          role="button"
        ></div>
  
        <article
          className={
            quitMessageShown
              ? "quit-message-container fixed bottom-0 left-0 right-0 z-40 flex flex-col gap-4 bg-white px-4 py-12 text-center transition-all duration-300 "
              : "quit-message-container fixed -bottom-96 left-0 right-0 z-40 flex flex-col bg-white px-5 py-12 text-center transition-all duration-300"
          }
          aria-hidden={!quitMessageShown}
        >
          <div className="flex grow flex-col gap-4">
            <h2 className="text-lg font-bold sm:text-2xl">
              Are you sure you want to exit?
            </h2>
          </div>
          <div className="flex grow flex-col items-center justify-center gap-4 sm:flex-row-reverse">
            <button
              className="flex w-full items-center justify-center rounded-2xl border-b-4 border-blue-500 bg-blue-400 py-3 font-bold uppercase text-white transition hover:brightness-105 sm:w-48"
              href="/learn"
              onClick={() => {
                setShowLesson(false);
                setQuitMessageShown(false);
                setReload(new Date());
  
                // Mixpanel
                Mixpanel.track("Question Exited", {
                  xp: _DATA_POINTS?.xp,
                  unit: _DATA_POINTS?.unit_name,
                  unit_number: _DATA_POINTS?.unit_number,
                  question: _DATA_POINTS?.question,
                });
              }}
            >
              Exit
            </button>
            <button
              className="w-full rounded-2xl py-3 font-bold uppercase text-blue-400 transition hover:brightness-90 sm:w-48 sm:border-2 sm:border-b-4 sm:border-gray-300 sm:text-gray-400 sm:hover:bg-gray-100"
              onClick={() => setQuitMessageShown(false)}
            >
              Stay
            </button>
          </div>
        </article>
      </>
    );
  };