import React, { useEffect, useState } from "react";
import AddCheckIn from "../../components/addCheckIn/AddCheckIn";
import getGeoLocation from "../../components/geoLoc/getGeoLocation";

import "./map.css";

function CheckIn({
  db,
  checkinVisible,
  currentUserObj,
  firebase,
  devsReportingReference,
  isShowcheckIn,
  setIsShowCheckIn,
  isShowPause,
  setIsShowPause,
  currUserId,
}) {
  //Get User Location & Display Map
  const [locData, setData] = useState({ lat: 40, lng: 55 });

  useEffect(() => {
    const hostName = window.location.hostname;
    if (hostName.indexOf("nestria") !== -1) {
      // Don't request location for Nestria
      return;
    }
    (async () => {
      const res = await getGeoLocation();
      setData(res);
      console.log("Location found:", locData);
    })();
  }, []);

  return (
    <>
      {checkinVisible && (
        <div className="checkin transition-6">
          <AddCheckIn
            db={db}
            currentUserObj={currentUserObj}
            firebase={firebase}
            devsReportingReference={devsReportingReference}
            isShowcheckIn={isShowcheckIn}
            setIsShowCheckIn={setIsShowCheckIn}
            isShowPause={isShowPause}
            setIsShowPause={setIsShowPause}
            currUserId={currUserId}
          />
        </div>
      )}
    </>
  );
}

export default CheckIn;
