import React, { useContext, useState } from 'react';
import OratioContext from '../../oratioContext/OratioContext';
import DisconnectModal from '../modal/DisconnectModal';
import NoAvatar from '../noAvatar/NoAvatar';
import NotificationIcon from '../svgs/NotificationIcon';
import OptionsIcon from '../svgs/OptionsIcon';

const Requirements = ({
  requirementsConnections,
  windowSize,
  truncateString,
  currConnectionId,
  showTruncatedBio,
  SwitchToMessageSection,
  isOptions,
  disconnect,
  isDisconnectModal,
  setIsDisconnectModal,
  updateOptionsData,
  setShowTruncatedBio,
  isAsciiOnly,
  db,
  userId
}) => {
  const [requirementsId, setRequirementsId] = useState('');
  // destructures prop/value from the OratioContext component
  const {
    connectionObj,
    setConnectionObj
  } = useContext(OratioContext);

  // on `Message` button click;
  const moveToMessageSection = async (requirement) => {
    // navigate to message section if the selected requirement connection has chatRef key
    if (requirement?.chatRef) {
      SwitchToMessageSection(requirement);
    } else {
      // create a chat doc if the selected requirement connection does not have a chatRef key
      const currUserRef = db.doc(`users/${userId}`);
      const newReqChatData = {
        groupName: requirement?.name,
        description: requirement?.description,
        members: [currUserRef],
        isRequirement: true,
        isChannel: false,
        public: false,
        createdOn: new Date(),
      };
      const newReqChatRef = await db.collection('/chats').add(newReqChatData);
  
      if (newReqChatRef !== undefined) {
        const chatRefData = {
          chatRef: newReqChatRef,
        };

        try {
          // updates the selected requirement connection with chatRef key and the ref of the newly created chat doc as its val
          await requirement?.connectionRef.set(chatRefData, { merge: true });
          const reqConnectionRef = requirement?.connectionRef;
          // retrieves the data from the updated requirement connection reference
          const reqConnectionRes = await reqConnectionRef.get();
          const reqConnectionData = reqConnectionRes?.data();

          // check if the retrieved requirement connection has chatRef key
          // and then switches to the message section if it does has a chatRef key
          if (reqConnectionData?.chatRef) {
            SwitchToMessageSection(reqConnectionData);
          };
        } catch (error) {
          console.error('Failed to update requirement with chatRef:', error);
        };
      }
    }
  };  

  return(
    <div className='requirements'>
      {requirementsConnections?.length > 0 &&
        requirementsConnections?.map((requirement) => {
          const allTypesArr = requirement?.types.join(' | ');
          return(
          <div
            className="requirements_card"
            key={requirement?.id}
          >
            <div className="requirements_avatar">
              <NoAvatar text="r" size={3.5} radius={50} />
            </div>
            <div className="requirements_section">  
              <div className="requirements_info">
                <p className='name'>
                  {(windowSize <= 1040 && windowSize > 280) ?
                    truncateString(requirement?.name, 17)
                    :
                    windowSize <= 280 ?
                    truncateString(requirement?.name, 14)
                    :
                    truncateString(requirement?.name, 55)}
                  <small>
                    {(windowSize <= 1040 && windowSize > 280) ?
                    truncateString(allTypesArr, 8)
                    :
                    windowSize <= 280 ?
                    truncateString(allTypesArr, 3)
                    :
                    truncateString(allTypesArr, 35)}
                  </small>
                </p>
              </div>
              <div className="requirements_desc">
                {requirement?.description?.length > 88 ?
                  <>
                    {showTruncatedBio ?
                      <>
                        <p className='bio'>
                          {isAsciiOnly(requirement?.description) === true ?
                            <>
                              {truncateString(requirement?.description, 25)}
                            </>
                            :
                            <>
                              {truncateString(requirement?.description, 88)}
                            </>
                          }
                        </p>
                        <p
                          onClick={() => {
                            setShowTruncatedBio(false);
                            setRequirementsId(requirement?.id);
                          }}
                          className='show_btn'
                        >
                          Show more
                        </p>
                      </>
                      :
                      <>
                        {requirementsId === requirement?.id ?
                          <>
                            <p className='bio'>
                              {requirement?.description}
                            </p>
                            <p
                              onClick={() => {
                                setShowTruncatedBio(true);
                                setRequirementsId('');
                              }}
                              className='show_btn'
                            >
                              Show less
                            </p>
                          </>
                          :
                          <>
                            <p className='bio'>
                              {isAsciiOnly(requirement?.description) === true ?
                                <>
                                  {truncateString(requirement?.description, 25)}
                                </>
                                :
                                <>
                                  {truncateString(requirement?.description, 88)}
                                </>
                              }
                            </p>
                            <p
                              onClick={() => {
                                setShowTruncatedBio(false);
                                setRequirementsId(requirement?.id);
                              }}
                              className='show_btn'
                            >
                              Show more
                            </p>
                          </>
                        }
                      </>
                    }
                  </>
                  :
                  <p className='bio'>
                    {requirement?.description}
                  </p>
                }
              </div>          
            </div>
            <div className="msg_notifs_option_btn">
              {requirement?.reciprocal &&
                <>
                  <div
                    className="bn3" 
                    onClick={() => moveToMessageSection(requirement)}
                  >
                    Message
                  </div>
                  <div className='notifications'>
                  <div
                    className='notification_btn_container'
                    // onClick={() => {
                    //   setNotificationModal(prev => !prev);
                    //   setRequirementsId(requirements?.id);
                    // }}
                  >
                    <NotificationIcon
                      width='24'
                      height='24'
                    />
                  </div>
                  {/* notification modal */}
                  {/* {(notificationModal && requirementsId === requirements?.id) &&
                    <NotificationModal
                      firebase={firebase}
                      db={db}
                      requirement={requirement}
                      userId={userId}
                      notificationFreq={notificationFreq}
                      notificationChannel={notificationChannel}
                    />
                  } */}
                </div>
                  <div
                    className="options"
                  >
                    <div
                      onClick={() => updateOptionsData(requirement)}
                    >
                      <OptionsIcon
                        width='24'
                        height='24'
                        color='black'
                      />
                    </div>
                    {(isOptions && currConnectionId === requirement?.id) &&
                      <div
                        className="disconnect_btn" 
                        onClick={() => {
                          setIsDisconnectModal(true);
                          setConnectionObj(requirement);
                        }}
                      >
                        Disconnect
                      </div>
                    }
                  </div>
                </>
              }
            </div>
          </div>)
        })
      }
      {/* confirmation modal */}
      {isDisconnectModal &&
        <DisconnectModal
          connectionObj={connectionObj}
          closeModal={() => setIsDisconnectModal(false)}
          disconnect={disconnect}
        />
      }
    </div>
  );
};

export default Requirements;
