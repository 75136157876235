import React from 'react';

const DeleteGroupModal 
= ({
  header,
  subHeader,
  chatObj,
  closeModal,
  truncateString,
  closeNewGroupModal
}) => {
  // deletes active chat group/channel
  const deleteChatGroup = async () => {
    await chatObj?.ref?.delete();
    closeModal(); // closes modal when a chat group/channel is deleted
    closeNewGroupModal() // closes newGroupModal when a chat group/channel is retracted
  };

  return (
    <>
      <div className='del_modal'>
        <p
          className='close_modal'
          onClick={closeModal}
        >
          X
        </p>
        <div className='confirm_del'>
          <h3>{header}</h3>
          <p>{subHeader} <b>{truncateString(chatObj?.groupName, 50)}</b>?</p>
        </div>
        <div className='confirm_btn_container'>
          <button
            className='cancel_confirm_btn'
            onClick={closeModal}
          >
            No
          </button>
          <button
            className='confirm_btn'
            onClick={deleteChatGroup}
          >
            Yes
          </button>
        </div>
      </div>
    </>
  );
};

export default DeleteGroupModal;
