import { ReturnSVG } from "../../helpers/Svgs";

export default function RedoButton({ onClickHandler, show, isDisabled }) {
  if (!show) {
    return (<></>);
  }

  if (show === true) {
    return (
      <div
        style={{
          background: "white",
          borderRadius: "100px",
          height: "50px",
          width: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          opacity: isDisabled ? 0.5 : 1
        }}
        onClick={() => {
          if (!isDisabled) {
            onClickHandler();
          }
        }}
      >
        <ReturnSVG />
      </div>
    );
  }
}
