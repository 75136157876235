import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FirstToAnswerPopup } from "../FirstToAnswerPopup";
import UserAnswerFeed from "../../UserAnswers/Feed";
import { QuitMessage } from "../QuitMessage";
import {
  downloadAsBlob,
  getTopThreeAnswers,
  setQuestionAsAnswered,
  setQuestionAsSkippedIfNotAnswered,
} from "../../helpers/store";
import { Mixpanel } from "../../helpers/Mixpanel";
import textToSpeech from "../../TextToSpeech/TextToSpeech";
import { LessonComplete } from "../LessonComplete";
import VideoRecorder from "../../VideoRecorder/Recorder";
import { MiscQuestion } from "./Misc";
import { QuestionType } from "../../Home/helpers/Content";
import Spinner from "../../../spinner/Spinner";
import { CurrentUserContext, FirebaseContext } from "../../Context/Nestria";

interface UserAnswers {
  url?: string;
}

type AlertSeverity = "error" | "success" | "info" | "warning" | undefined;

interface Props {
  isReviewing: boolean;
  selectedQuestions: Array<QuestionType>;
  question: QuestionType;
  questionIndex: number;
  setQuestionIndex;
  quitMessageShown;
  setQuitMessageShown;
  setShowLesson;
  questionReference;
  selectedUnit;
  isLastUnitOfTheCourse;
  setQuestionReference;
  setReload;
  userUnits;
  setComputeProgress;
  lessons;
  hasReachedMaxSubmissions;
  isFinalAssessmentError;
  lessonComplete;
  setLessonComplete;
  isTakingProfilingQuestionsInFinal;
  _DATA_POINTS;
}

const NestriaQuestion = ({
  isReviewing,
  selectedQuestions,
  question,
  questionIndex,
  setQuestionIndex,
  quitMessageShown,
  setQuitMessageShown,
  setShowLesson,
  questionReference,
  selectedUnit,
  isLastUnitOfTheCourse,
  setQuestionReference,
  setReload,
  userUnits,
  setComputeProgress,
  lessons,
  hasReachedMaxSubmissions,
  isFinalAssessmentError,
  lessonComplete,
  setLessonComplete,
  isTakingProfilingQuestionsInFinal,
  _DATA_POINTS,
}: Props) => {
  const firebase = useContext(FirebaseContext);
  const currentUserObj = useContext(CurrentUserContext);
  const [questionDesc, setQuestionDesc] = useState<string | null>(null);
  const [currentQuestion, setCurrentQuestion] =
    useState<QuestionType>(question);
  const [message, setMessage] = useState("");
  const [submittedTranscript, setSubmittedTranscript] = useState("");
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);
  const [questionType, setQuestionType] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertSeverity>("info");

  const [showUserAnswersFeed, setShowUserAnswersFeed] = useState(false);
  const [showFirstToAnswerPopUp, setShowFirstToAnswerPopUp] = useState(false);

  const [correctAnswer, setCorrectAnswer] = useState();
  const [audioContentBase64, setAudioContentBase64] = useState();
  const [rawGPTFeedback, setRawGPTFeedback] = useState<null | string>();

  const [userAnswers, setUserAnswers] = useState<Array<UserAnswers>>([]);

  const initialQuestionFeedback = {
    unit_name: selectedUnit?.unitName,
    question: questionDesc,
    user_answer: "",
    gpt_feedback: "",
    question_feedback: "",
  };

  const [questionFeedback, setQuestionFeedback] = useState(
    initialQuestionFeedback
  );

  const onContinue = async () => {
    if (questionType === "no_input") {
      await setQuestionAsAnswered(questionReference);
      setComputeProgress(new Date()); // set a random number to begin progress computation
    }

    // V2 would show UserAnswers Video feed right after the question is answered
    // This only applies to Practice assessment
    const thisIsAnAssessment = selectedUnit?.courseName
      ?.toLowerCase()
      ?.includes("final");
    const thisIsOrientation = selectedUnit?.courseName
      ?.toLowerCase()
      ?.includes("orientation");
    console.log(
      `questionType: ${questionType}, thisIsOrientation: ${thisIsOrientation}, thisIsAnAssessment: ${thisIsAnAssessment}`
    );
    if (
      questionType === "routing_type" ||
      questionType === "no_input" ||
      thisIsOrientation ||
      thisIsAnAssessment === true
    ) {
      goToNextQuestion();
    }
  };

  const onSkip = async () => {
    Mixpanel.track("Question Skipped", _DATA_POINTS);

    await setQuestionAsSkippedIfNotAnswered(questionReference);

    goToNextQuestion();
  };

  const markQuestionAsAnswered = async (questionReference) => {
    try {
      let evaluateThisQuestion = true;

      const _prompt = "prompt";

      const questionSnapshot = await questionReference.get();

      if (!questionSnapshot?.exists) {
        throw new Error(
          `Question Document with reference: ${questionReference}, does not exist.`
        );
      }

      const questionDetails = questionSnapshot.data();

      const questionData = questionDetails?.questionData;

      if (questionData?.isDynamic !== true) {
        evaluateThisQuestion = !(
          questionData[_prompt] === undefined || questionData[_prompt] === ""
        );
      }

      // Since the question is answered - set question as not skipped
      if (questionDetails?.hasOwnProperty("isQuestionSkipped")) {
        await questionReference.update({
          isQuestionSkipped: false,
        });
      }

      await questionReference.update({
        isAnswered: true,
      });

      return evaluateThisQuestion;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  };

  const onSubmit = async (message) => {
    try {
      setIsSubmittingAnswer(true);

      const thisQuestion = selectedQuestions[questionIndex];

      let evaluateThisQuestion = await markQuestionAsAnswered(
        questionReference
      );

      const thisIsAnAssessment = thisQuestion?.courseName
        ?.toLowerCase()
        ?.includes("final");

      if (
        evaluateThisQuestion &&
        thisQuestion?.routingType === "" &&
        selectedUnit?.courseName?.toLowerCase() !== "orientation" &&
        thisIsAnAssessment === false &&
        isTakingProfilingQuestionsInFinal === false
      ) {
        // Don't show the pop up if the answer is blank
        if (correctAnswer && userAnswers?.length === 0 && message?.length > 0) {
          // Show the "First to answer" pop up
          setShowFirstToAnswerPopUp(true);
        } else {
          setShowFirstToAnswerPopUp(false);
          setShowUserAnswersFeed(true);
        }
      }

      setComputeProgress(new Date()); // set a random number to begin progress computation

      console.log(`thisQuestion: `, thisQuestion?.tileObject?.questionData);
      console.log(`thisQuestion: `, thisQuestion?.routingType);

      setIsAnswerSubmitted(true);
      setIsSubmittingAnswer(false);
      setMessage("");

      setSubmittedTranscript(message);

      const submissionDocRef = await questionReference
        .collection("submissions")
        .add({
          author: currentUserObj?.ref,
          msg: message,
          received: false,
          ts: firebase?.firestore.Timestamp.fromDate(new Date()),
          evaluate: evaluateThisQuestion,
        });

      // Keep watching submissionDocRef for feedback
      if (evaluateThisQuestion === true) {
        const unsubscribe = submissionDocRef.onSnapshot(async (doc) => {
          const submissionData = await doc.data();
          const { evaluate, evalError, gptResponseRef } = submissionData;

          if (evaluate === false && evalError === false && gptResponseRef) {
            // Feedback ready
            const gptResponseDoc = await gptResponseRef.get();
            const feedback = await gptResponseDoc.data();
            const gptFeedback = feedback?.msg;

            if (!gptFeedback) {
              // Missing GPT feedback
              Mixpanel.track("error_gpt_rating", {
                ..._DATA_POINTS,
                gptResponse: feedback,
                answer: message,
              });

              unsubscribe();
            } else {
              // Extract score and text feedback
              setRawGPTFeedback(gptFeedback);

              Mixpanel.track("QuestionEvaluated", {
                ..._DATA_POINTS,
                rating: gptFeedback,
                answer: message,
              });

              unsubscribe();
            }
          }

          if (evaluate === false && evalError === true) {
            // Something went wrong with evaluation
            Mixpanel.track("error_gpt_eval", {
              ..._DATA_POINTS,
              gptResponse: submissionData,
              answer: message,
            });

            unsubscribe();
          }
        });
      }

      Mixpanel.track("QuestionAnswered", {
        ..._DATA_POINTS,
        evaluate: evaluateThisQuestion,
        answer: message || "",
      });
    } catch (error) {
      console.error("Error sending message:", error);
      setIsAnswerSubmitted(false);
      setIsSubmittingAnswer(false);
    } finally {
      setMessage("");
      onContinue();
    }
  };

  const onLessonComplete = async () => {
    if (lessonComplete && isLastUnitOfTheCourse) {
      setShowLesson(false);
      setReload(new Date());
    } else if (lessonComplete) {
      setShowLesson(false);
      setReload(new Date());
    }
    setShowLesson(false);
    setReload(new Date());
  };

  const goToNextQuestion = async () => {
    let nextTileIndex =
      selectedQuestions.findIndex((q) => q.id === currentQuestion.id) + 1;

    const nextTile = selectedQuestions[nextTileIndex];

    setRawGPTFeedback(null);

    setIsAnswerSubmitted(false); // This resets the "continue" | "submit" button states

    // Show the next question or show the end of unit
    if (nextTile) {
      const nextQuestionRef = nextTile?.tileRef;
      // questionObject.current = nextTile;
      setCurrentQuestion(nextTile);
      console.log(`next question: `);
      console.log(nextTile);
      // setQuestionObject(nextTile); // This sets course, unit and question IDs for Video Interface
      // setLessonProblem(nextTileIndex);
      setQuestionIndex(nextTileIndex);
      setQuestionReference(nextQuestionRef);
    } else {
      setLessonComplete(true);
    }
  };

  /**
      // Get question name and type from question reference
      // Set a few states
      // Get submissions from question reference
      // Loop over submissions and set updated message
     */
  const setStates = async (questionReference) => {
    const questionSnapshot = await questionReference.get();
    const questionName = questionSnapshot?.data()?.questionData?.question;
    const questionType = questionSnapshot?.data()?.questionData?.type;

    setQuestionFeedback((prevQuestionFeedback) => ({
      ...prevQuestionFeedback,
      question: questionName || "",
    }));

    if (currentQuestion?.question) {
      setQuestionDesc(currentQuestion.question);
    }

    setQuestionType(questionType || "DEFAULT");

    return;
  };

  useEffect(() => {
    if (questionReference && currentQuestion) {
      // Load user answers to show
      const {
        courseID,
        courseName,
        unitId,
        id,
        originalUnitID,
        originalQuestionID,
        description,
      } = currentQuestion;
      const thisIsAnAssessment = courseName?.toLowerCase()?.includes("final");
      const thisIsNotOrientation = !courseName
        ?.toLowerCase()
        ?.startsWith("orientation");

      if (thisIsAnAssessment === false && thisIsNotOrientation) {
        const questionPayload = {
          question: description,
          courseID,
          originalUnitID,
          originalQuestionID,
        };

        const _cutOffScore = 2;

        getTopThreeAnswers(
          firebase,
          currentUserObj?.devId || currentUserObj?.id,
          questionPayload,
          _cutOffScore
        )
          .then((res) => {
            const { userAnswers, gptAnswer } = res;

            // Download all user answers as a blobs
            Promise.all(
              userAnswers.map((item, index) => {
                downloadAsBlob(item?.url)
                  .then((blobUrl) => {
                    userAnswers[index].url = blobUrl;
                  })
                  .catch((e) => {
                    console.error(e);
                    throw Error(e);
                  });
              })
            )
              .then(() => {
                console.log(`userAnswers: `, userAnswers);
                setUserAnswers(userAnswers);
              })
              .catch((e) => {
                console.error(e);
                handleSnackbar("Error downloading blobs");
              });

            setUserAnswers(userAnswers);
            setCorrectAnswer(gptAnswer);
          })
          .catch((e) => {
            console.error(e);
            // TODO: Handle Error
          });
      }

      setStates(questionReference).then(() => {});
    }
  }, [questionReference, selectedUnit]);

  useEffect(() => {
    if (!correctAnswer) {
      return;
    }
    // Get the audio as base64
    textToSpeech(correctAnswer).then((audioContent) => {
      setAudioContentBase64(audioContent);
    });
  }, [correctAnswer]);

  useEffect(() => {
    // questionObject.current = question;
    console.log(
      `isTakingProfilingQuestionsInFinal: `,
      isTakingProfilingQuestionsInFinal
    );
  }, [isTakingProfilingQuestionsInFinal]);

  const handleSnackbar = (msg, show = true, type: AlertSeverity = "info") => {
    if (typeof show !== "boolean") {
      return;
    }
    setSnackbarMsg(msg);
    setSnackbarSeverity(type);
    setShowSnackbar(show);

    if (show) {
      setTimeout(() => {
        setShowSnackbar(false);
      }, 1500);
    }
  };

  const renderQuestion = () => {
    if (lessonComplete) {
      return (
        <div className="flex min-h-screen flex-col gap-5 px-4 spy sm:px-0 sm:py-0">
          <LessonComplete
            selectedQuestions={selectedQuestions}
            selectedUnit={selectedUnit}
            allUnits={userUnits}
            courseType={selectedUnit?.courseType}
            isLastUnitOfTheCourse={isLastUnitOfTheCourse}
            courseName={selectedUnit?.courseName}
            unitName={selectedUnit?.unitName}
            onLessonComplete={onLessonComplete}
          />
        </div>
      );
    }

    if (
      currentQuestion?.videoAnswerRequired &&
      !lessonComplete &&
      currentQuestion?.tileObject?.questionData?.type !== "routing_type"
    ) {
      return (
        <div className="flex justify-center w-full">
        <div className="max-w-full sm:max-w-2xl w-full">
        <VideoRecorder
          maxVideoAnswerLengthInSeconds={
            currentQuestion?.maxVideoAnswerLengthInSeconds
          }
          setQuitMessageShown={setQuitMessageShown}
          questionText={questionDesc}
          question={currentQuestion}
          onSubmit={onSubmit}
          hasReachedMaxSubmissions={hasReachedMaxSubmissions}
          isTakingProfilingQuestionsInFinal={isTakingProfilingQuestionsInFinal}
        />
        </div>
      </div>
  
      );
    } else if (currentQuestion?.question) {
      return (
        <MiscQuestion
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          lessonComplete={lessonComplete}
          questionDesc={questionDesc}
          setQuitMessageShown={setQuitMessageShown}
          onSkip={onSkip}
          onContinue={onContinue}
          onSubmit={onSubmit}
          onLessonComplete={onLessonComplete}
          question={currentQuestion}
          message={message}
          isAnswerSubmitted={isAnswerSubmitted}
          isSubmittingAnswer={isSubmittingAnswer}
          questionType={questionType}
          questionFeedback={questionFeedback}
          setQuestionFeedback={setQuestionFeedback}
          hasReachedMaxSubmissions={hasReachedMaxSubmissions}
          isFinalAssessmentError={isFinalAssessmentError}
        />
      );
    }
  };

  return (
    <div>
      <Snackbar open={showSnackbar} onClose={() => setShowSnackbar(false)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setShowSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </MuiAlert>
      </Snackbar>

      {showUserAnswersFeed || renderQuestion()}

      {showFirstToAnswerPopUp && (
        <FirstToAnswerPopup
          onClickHandler={() => {
            setShowUserAnswersFeed(true);
            setShowFirstToAnswerPopUp(false);
          }}
        />
      )}

      {showUserAnswersFeed && (
        <UserAnswerFeed
          userAnswers={userAnswers}
          gptAnswer={correctAnswer}
          audioContentBase64={audioContentBase64}
          setShowFeed={setShowUserAnswersFeed}
          questionText={questionDesc}
          currentAnswerTranscript={submittedTranscript}
          rawGPTFeedback={rawGPTFeedback}
          setQuitMessageShown={setQuitMessageShown}
          setShowConfirmation={setShowConfirmation}
          nextQuestionElmCtx={{
            setQuitMessageShown: setQuitMessageShown,
            quitMessageShown: quitMessageShown,
            onContinue: onContinue,
            onSubmit: onSubmit,
            isAnswerSubmitted: isAnswerSubmitted,
            hasReachedMaxSubmissions: hasReachedMaxSubmissions,
            setShowSnackBar: setShowSnackbar,
            snackBarRoutingSeverity: snackbarSeverity,
            snackBarRoutingMsg: snackbarMsg,
            isVisible: showUserAnswersFeed,
            selectedQuestions: selectedQuestions,
            questionIndex: questionIndex,
            goToNextQuestion: goToNextQuestion,
          }}
        />
      )}

      <QuitMessage
        quitMessageShown={quitMessageShown}
        setQuitMessageShown={setQuitMessageShown}
        setShowLesson={setShowLesson}
        setReload={setReload}
        _DATA_POINTS={_DATA_POINTS}
      />
    </div>
  );
};

export default NestriaQuestion;
