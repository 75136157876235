import React from 'react';

const UserStatusModal = () => {
  return(
    <>
      <div className='user_status_container'>
        <div className='online_status'>
          <p className='online'>Online</p>
          <p className='dot'></p>
        </div>
        <div className='idle_status'>
          <p className='idle'>Idle</p>
          <p className='dot'></p>
        </div>
        <div className='do_not_disturb_status'>
          <p className='do_not_disturb'>Do-not-disturb</p>
          <p className='dot'></p>
        </div>
        <div className='unavailable_status'>
          <p className='unavailable'>Unavailable</p>
          <p className='dot'></p>
        </div>
      </div>
    </>
  );
};

export default UserStatusModal;