import React from "react";

const AddIcon = ({ width, height, top, bottom }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0C3.35685 0 0 3.35685 0 7.5C0 11.6431 3.35685 15 7.5 15C11.6431 15 15 11.6431 15 7.5C15 3.35685 11.6431 0 7.5 0ZM11.8548 8.34677C11.8548 8.54637 11.6915 8.70968 11.4919 8.70968H8.70968V11.4919C8.70968 11.6915 8.54637 11.8548 8.34677 11.8548H6.65323C6.45363 11.8548 6.29032 11.6915 6.29032 11.4919V8.70968H3.50806C3.30847 8.70968 3.14516 8.54637 3.14516 8.34677V6.65323C3.14516 6.45363 3.30847 6.29032 3.50806 6.29032H6.29032V3.50806C6.29032 3.30847 6.45363 3.14516 6.65323 3.14516H8.34677C8.54637 3.14516 8.70968 3.30847 8.70968 3.50806V6.29032H11.4919C11.6915 6.29032 11.8548 6.45363 11.8548 6.65323V8.34677Z"
        fill="black"
      />
    </svg>
  );
};

export default AddIcon;
