import { db } from "../../../utils/services";

export async function removeUnitForUser(db, unitRef) {
  try {
    if (!db || !unitRef) {
      throw Error(`invalid input params: db: ${db}, unitRef: ${unitRef}`);
    }

    // Unit delete logic goes here
    const userUnitDoc = await unitRef?.get();

    if (userUnitDoc?.exists === undefined || userUnitDoc?.exists === false) {
      throw Error(`unitRef: ${unitRef} does not exist.`);
    }

    const { questions } = userUnitDoc.data();

    if (Array.isArray(questions)) {
      // Remove all the assigned questions doc(s)
      await Promise.all(
        questions.map(async (questionRef) => {
          // Delete the docs within "submissions"
          // sub-collection before deleting the question document
          const questionSubmissions = await questionRef
            .collection("submissions")
            .get();
          //console.log(questionSubmissions);

          if (questionSubmissions?.empty === false) {
            await Promise.all(
              questionSubmissions?.docs?.map((docRef) => {
                return docRef?.ref?.delete();
              })
            );
            return questionRef?.delete();
          } else {
            return questionRef?.delete();
          }
        })
      );
    }

    // Remove the assigned unit doc
    await unitRef?.delete();

    //console.log(`Successfully deleted unit for this user.`);
    return Promise.resolve();
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export async function deleteUnitsFromDb(db, unitRef) {
  try {
    if (!db || !unitRef) {
      throw Error(`invalid input params: db: ${db}, unitRef: ${unitRef}`);
    }

    const unitQuestions = await unitRef?.collection("questions").get();

    if (unitQuestions?.empty === false) {
      await Promise.all(
        unitQuestions.docs.map((docRef) => {
          return docRef?.ref?.delete();
        })
      );

      await unitRef?.delete();
    } else {
      await unitRef?.delete();
    }
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export async function removeAllUserUnits(db, userId) {
  try {
    if (!db || !userId) {
      throw Error(`invalid input params: db: ${db}, unitRef: ${userId}`);
    }
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export async function setQuestionAsAnswered(questionRef) {
  try {
    if (!questionRef) {
      throw Error(`invalid input param questionRef: ${questionRef}`);
    }

    const questionSnapshot = await questionRef.get();

    if (questionSnapshot?.exists) {
      const questionData = questionSnapshot.data();
      const isAnswered = questionData?.isAnswered;

      if (isAnswered === true) {
        return Promise.resolve();
      }

      await questionRef.update({
        isAnswered: true,
      });

      return Promise.resolve();
    }
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export async function setQuestionAsSkippedIfNotAnswered(questionRef) {
  try {
    if (!questionRef) {
      throw Error(`invalid input param questionRef: ${questionRef}`);
    }

    const questionSnapshot = await questionRef.get();

    if (questionSnapshot?.exists) {
      const questionData = questionSnapshot.data();
      const isAnswered = questionData?.isAnswered;

      if (isAnswered === false) {
        await questionRef.update({
          isQuestionSkipped: true,
        });
      }

      return Promise.resolve();
    }
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export async function getNumberOfQuestionsAnsweredInTheLessonToday(
  lessonQuestions,
  currentUserObj
) {
  try {
    if (!lessonQuestions || !Array.isArray(lessonQuestions)) {
      throw new Error(
        `Invalid input param unitQuestions. It should be an array. lessonQuestions: ${lessonQuestions}`
      );
    }

    if (typeof currentUserObj !== "object" || currentUserObj === null) {
      throw new Error(
        "Invalid input param currentUserObj. It should be a valid object."
      );
    }

    const questionDateSet = new Set();
    const currentDate = new Date().toDateString();
    const totalAnswered = await Promise.all(
      lessonQuestions.map(async (tile) => {
        try {
          const tileSnapshot = await tile.tileRef.get();
          let tileDate;

          if (tileSnapshot.exists) {
            const tileData = tileSnapshot.data();
            const latestSubmissionDoc = await getLatestSubmission(
              tile.tileRef,
              currentUserObj
            );
            const latestSubmission = latestSubmissionDoc?.data();

            if (tileData?.isAnswered === true) {
              if (latestSubmission) {
                tileDate = latestSubmission?.ts?.toDate().toDateString();
              }

              questionDateSet.add(tileDate);

              if (
                tileData.isAnswered === true &&
                questionDateSet.size === 1 &&
                tileDate === currentDate
              ) {
                return true;
              }
            }
          }

          return false;
        } catch (error) {
          console.error("Error fetching tile data:", error.message);
          throw error;
        }
      })
    );

    return totalAnswered.filter(Boolean).length;
  } catch (error) {
    console.error("General error:", error.message);
    throw error;
  }
}

export async function getLatestSubmission(tileRef, currentUserObj) {
  try {
    const submissionSnapshot = await tileRef
      .collection("submissions")
      .where("author", "==", currentUserObj?.ref)
      .orderBy("ts", "desc")
      .limit(1)
      .get();

    if (!submissionSnapshot.empty) {
      //const latestSubmission = submissionSnapshot.docs[0].data();
      const latestSubmissionDoc = submissionSnapshot.docs[0];
      return latestSubmissionDoc;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching latest submission:", error.message);
    throw error;
  }
}

export async function hasPreviousSubmissions(tileRef, currentUserObj) {
  try {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const submissionSnapshot = await tileRef
      .collection("submissions")
      .where("author", "==", currentUserObj?.ref)
      .where("ts", "<", currentDate)
      .get();

    return !submissionSnapshot.empty;
  } catch (error) {
    console.error(
      "Error checking for previous day submissions:",
      error.message
    );
    throw error;
  }
}

export async function isLessonEligibleForStreak(
  lessonQuestions,
  currentUserObj
) {
  if (!Array.isArray(lessonQuestions)) {
    throw new Error(
      `Invalid input param lessonQuestions. It should be an array. Got ${lessonQuestions}`
    );
  }
  if (typeof currentUserObj !== "object" || currentUserObj === null) {
    throw new Error(
      "Invalid input param currentUserObj. It should be a valid object."
    );
  }

  const someAnsweredToday = await areSomeQuestionsAnsweredToday(
    lessonQuestions,
    currentUserObj
  );
  if (areAllQuestionsUnansweredAndNotSkipped(lessonQuestions)) {
    return true;
  } else if (someAnsweredToday) {
    return true;
  }

  return false;
}

export function areAllQuestionsUnansweredAndNotSkipped(lessonQuestions) {
  let counter = 0;
  const allUnansweredAndNotSkipped = lessonQuestions.every((question) => {
    counter = counter + 1;

    return !question?.isAnswered && !question?.isSkipped;
  });

  return allUnansweredAndNotSkipped;
}

export async function areSomeQuestionsAnsweredToday(
  lessonQuestions,
  currentUserObj
) {
  const currentDate = new Date().toDateString();
  let answeredToday = false; // Initialize a flag for answered questions today

  for (const question of lessonQuestions) {
    try {
      if (!question.isAnswered) {
        continue;
      }

      const latestSubmissionDoc = await getLatestSubmission(
        question.tileRef,
        currentUserObj
      );

      const latestSubmission = latestSubmissionDoc?.data();

      const hasPreviousSubmission = await hasPreviousSubmissions(
        question.tileRef,
        currentUserObj
      );

      if (latestSubmission) {
        const tileDate = latestSubmission?.ts?.toDate().toDateString();

        if (tileDate === currentDate && !hasPreviousSubmission) {
          answeredToday = true; //
        }
      }
    } catch (error) {
      console.error("Error fetching tile data:", error.message);
      throw error;
    }
  }

  return answeredToday;
}

export async function setStreakActive(firebase, uid, date) {
  try {
    if (!uid || !date) {
      throw Error(`invalid input params uid: ${uid}, date: ${date}`);
    }

    const formattedDate = date.toISOString().split("T")[0]; // YYYY-MM-DD (ISO 8601)
    const firestoreDate = firebase.firestore.Timestamp.fromDate(
      new Date(formattedDate)
    );

    console.log("Inside this streak active update");
    db.collection("users")
      .doc(uid)
      .update({
        "nestriaStats.streak":
          firebase.firestore.FieldValue.arrayUnion(firestoreDate),
      });
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export async function getStreak(uid) {
  try {
    if (!uid) {
      throw Error(`invalid input param uid: ${uid}`);
    }
    const userDocRef = await db.collection("users").doc(uid).get();
    const userData = userDocRef.data();
    if (userData?.nestriaStats?.streak) {
      return Promise.resolve(userData?.nestriaStats?.streak);
    } else {
      return Promise.resolve([]);
    }
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export function getUserStreakDocRef(uid) {
  try {
    if (!uid) {
      throw Error(`invalid input param uid: ${uid}`);
    }
    return db.collection("users").doc(uid);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function countStreak(datesActive) {
  try {
    if (!Array.isArray(datesActive)) {
      throw Error(
        `invalid input param datesActive: ${datesActive}. Expecting an array.`
      );
    }

    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    let streakCount = 0;
    if (datesActive.includes(formatDateToISO8601(today))) {
      streakCount += 1;
    }

    for (let i in datesActive) {
      if (datesActive.includes(formatDateToISO8601(yesterday))) {
        // Increment streakCount by 1
        streakCount += 1;
        yesterday.setDate(yesterday.getDate() - 1);
      } else {
        break;
      }
    }

    return Promise.resolve(streakCount);
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export function coutStreakNoPromise(streakDates) {
  let formattedDates = [];
  for (let i = 0; i < streakDates?.length; i++) {
    formattedDates.push(formatDateToISO8601(streakDates[i]?.toDate()));
  }

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  let streakCount = 0;

  if (formattedDates.includes(formatDateToISO8601(today))) {
    streakCount += 1;
  }

  for (let i in formattedDates) {
    if (formattedDates.includes(formatDateToISO8601(yesterday))) {
      // Increment streakCount by 1
      streakCount += 1;
      yesterday.setDate(yesterday.getDate() - 1);
    } else {
      break;
    }
  }
  return streakCount;
}

export function formatDateToISO8601(date) {
  try {
    if (!date?.toISOString()) {
      throw Error(`invalid input param date: ${date}.`);
    }
    return date.toISOString().split("T")[0];
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getLatestScore(questionRef) {
  if (!questionRef) {
    throw new Error("Question Reference is required");
  }

  const submissionSnapshot = await questionRef
    .collection("submissions")
    .where("author", "==", db.doc("organisations/nestria/plugins/chatGPT"))
    .orderBy("ts", "desc")
    .limit(1)
    .get();

  let score = 0;

  if (!submissionSnapshot.empty) {
    const latestSubmission = submissionSnapshot.docs[0].data();

    if (latestSubmission && !latestSubmission.empty) {
      const msg = latestSubmission.msg;
      const pattern = /(\d+(\.\d+)?\/\d+)/g; // This pattern is searching for chatGPT grading in decimal numbers (e.g., 3.5) or integer fractions (e.g., 4/5) within the text.
      const match = msg.match(pattern);
      if (match) {
        const gradeScored = match[0];
        if (gradeScored?.includes("/")) {
          const [numerator, denominator] = gradeScored.split("/");
          if (numerator) {
            score = numerator;
          }
        }
      }
    }
  }

  return Number.isNaN(score) ? "!" : Number(score);
}

export function getNestriaDataPointTypes() {
  // This document location is fixed and hardcoded for Nestria use
  return db
    .collection("organisations")
    .doc("T6BAcTjwbXleCFpmWTmu")
    .collection("nestriaDataPointTypes")
    .get();
}

export function getUserScoreAndFeedbackFromGPTResponse(gptResponse) {
  // TODO: Add Input validations here.
  let score = "";
  let feedback = gptResponse;

  const splitMsg = gptResponse.split("<>");

  if (splitMsg.length === 2) {
    score = splitMsg[0].trim();
    feedback = splitMsg[1].trim();
  }
  return { score, feedback };
}

export function resolveAuthorNameFromDocRefPath(referencePath) {
  let authorName;

  if (referencePath?.includes("admin")) {
    authorName = "Admin";
  } else if (referencePath?.includes("organisations/nestria")) {
    authorName = "Nestria";
  } else if (referencePath?.includes("chatGPT")) {
    authorName = "Chat GPT";
  }

  return authorName;
}

export async function getAuthorNameAndAvatarFromUserDocRef(userDocRef) {
  const memberSnapshot = await userDocRef.get();
  const userData = memberSnapshot.data();
  const authorName =
    (userData.first ?? "") +
    (userData.first && userData.last ? " " : "") +
    (userData.last ?? "Unknown");
  const avatar = userData.avatar !== undefined ? userData.avatar : "";
  return { authorName, avatar };
}

export async function getRecentScoresFromSelectedQuestions(
  selectedQuestions,
  currentUserObj
) {
  const finalUnitScores = [];
  const pendingScores = [];

  await Promise.all(
    selectedQuestions.map(async (question) => {
      const _qType = question?.tileObject?.questionData.type;

      if (_qType && _qType === "no_input") {
        finalUnitScores.push({ score: 5 }); // For profiling questions that will not have any submissions,assigning a max grade of 5 to these questions.
      } else {
        const latestSubmissionDoc = await getLatestSubmission(
          question.tileRef,
          currentUserObj
        );

        if (latestSubmissionDoc) {
          const latestSubmission = latestSubmissionDoc.data();

          if (latestSubmission && latestSubmission.evaluate === true) {
            const latestSubmissionDocRef = latestSubmissionDoc.ref;
            pendingScores.push(latestSubmissionDocRef);
          } else if (latestSubmission && latestSubmission.evaluate === false) {
            const gptResponseRef = latestSubmission.gptResponseRef;

            const score = await getLatestScore(gptResponseRef.parent.parent);

            finalUnitScores.push({ score: isNaN(score) ? 0 : score });
          }
        }
      }
    })
  );
  return { finalUnitScores, pendingScores };
}

export async function getRecentScoresFromSelectedCourseUnits(
  courseUnits,
  currentUserObj
) {
  const finalAssessmentScores = [];
  const pendingScores = [];

  for (const unitDoc of courseUnits) {
    const unitData = await unitDoc.unitRef.get();
    const questions = unitData.data().questions || [];

    await Promise.all(
      questions.map(async (questionRef) => {
        const questionSnapshot = await questionRef.get();

        const questionSnapshotData = questionSnapshot?.data();

        const questionData = questionSnapshotData?.questionData;
        const _qType = questionData?.type;
        if (_qType && _qType === "no_input") {
          finalAssessmentScores.push({ score: 5 }); // For profiling questions that will not have any submissions,assigning a max grade of 5 to these questions.
        } else {
          const latestSubmissionDoc = await getLatestSubmission(
            questionRef,
            currentUserObj
          );
          const latestSubmission = latestSubmissionDoc?.data();

          if (latestSubmission && latestSubmission.evaluate === true) {
            const latestSubmissionDocRef = latestSubmissionDoc.ref;
            pendingScores.push(latestSubmissionDocRef);
          } else if (latestSubmission && latestSubmission.evaluate === false) {
            const gptResponseRef = latestSubmission.gptResponseRef;
            const score = await getLatestScore(gptResponseRef.parent.parent);
            
            finalAssessmentScores.push({ score: isNaN(score) ? 0 : score, difficulty: questionData?.difficulty, unitID: questionSnapshotData?.unitRef?.id});
          }
        }
      })
    );
  }

  return { finalAssessmentScores, pendingScores };
}

export async function getSizeOfUserSubmissions(tileRef, currentUserObj) {
  try {
    const submissionSnapshot = await tileRef
      .collection("submissions")
      .where("author", "==", currentUserObj?.ref)
      .get();

    const totalSubmissions = submissionSnapshot.size;

    return totalSubmissions;
  } catch (error) {
    console.error("Error fetching submissions:", error.message);
    throw error;
  }
}

export async function getSubjectDocRef(subject) {
  try {
    const subjectQuery = await db
      .collection("questionBank")
      .where("name", "==", subject)
      .get();

    if (!subjectQuery.empty) {
      throw Error(
        `Subject: ${subject} question bank already exists. /questionBank/${subjectQuery.docs[0].id}`
      );
    }

    const subjectRef = await db.collection("questionBank").add({
      name: subject,
      createdOn: new Date(),
    });

    return subjectRef;
  } catch (e) {
    return Promise.reject(e.message);
  }
}

export async function getTopicDocRef(topic, subjectRef, index) {
  try {
    const topicQuery = await subjectRef
      .collection("topics")
      .where("name", "==", topic)
      .get();

    if (!topicQuery.empty) {
      throw Error(
        `Topic: ${topic} question bank already exists. /questionBank/${
          subjectRef.id / topicQuery.docs[0].id
        }`
      );
    }

    const topicRef = await subjectRef.collection("topics").add({
      name: topic,
      index,
    });

    return topicRef;
  } catch (e) {
    return Promise.reject(e.message);
  }
}

export async function getSubTopicDocRef(subTopic, topicRef, index) {
  try {
    const subTopicQuery = await topicRef
      .collection("subTopics")
      .where("name", "==", subTopic)
      .get();

    if (!subTopicQuery.empty) {
      throw Error(
        `Sub Topic: ${subTopic} question bank already exists. /questionBank/${
          topicRef.id / subTopicQuery.docs[0].id
        }`
      );
    }

    const subTopicRef = await topicRef.collection("subTopics").add({
      name: subTopic,
      index,
    });

    return subTopicRef;
  } catch (e) {
    return Promise.reject(e.message);
  }
}

export async function assignOrientationCourse(firebase, uid) {
  const nestriaCatalogStack = db?.collection(`catalog`);

  const catalogStack = await nestriaCatalogStack?.get();

  const userRef = db.doc(`users/${uid}`);

  catalogStack?.forEach(async (catalogDoc) => {
    try {
      const catalogData = catalogDoc?.data();
      const catalogId = catalogDoc?.id;

      if (catalogData?.name === "Orientation") {
        const unitsSubcollection = await catalogDoc?.ref
          ?.collection("units")
          ?.orderBy("index", "asc")
          ?.get();

        const sortedUnitDocs = unitsSubcollection?.docs;

        for (let i = 0; i < sortedUnitDocs?.length; i++) {
          let unitDoc = sortedUnitDocs[i];
          try {
            console.log("Unit document", unitDoc);
            const unitData = unitDoc?.data();
            const unitName = unitData?.name;

            console.log("unitName", unitName);

            await db.collection("careerPrep").add({
              name: unitName,
              show: true,
              source: unitDoc?.ref,
              ts: firebase.firestore.Timestamp.fromDate(new Date()),
              userRef: userRef,
            });
          } catch (error) {
            console.error("Error processing unit document", error);
          }
        }
      }
    } catch (error) {
      console.error("Error accessing units subcollection", error);
    }
  });
}




//Get Video Url

export async function getVideoDownloadURL(firebase, devId, courseId, unitId, questionId) {
  const storage = firebase.storage();
  
  const path = `devs/${devId}/assessments/${courseId}/${unitId}/${questionId}`;
  const ref = storage.ref(path);

  console.log(`path: `, path);
  console.log("ref", ref);

  try {
      const [metadata, url] = await Promise.all([ref.getMetadata(), ref.getDownloadURL()]);
      const { contentType } = metadata;

      return { contentType, url };
  } catch (error) {
      console.error("Error getting download URL:", error);
      return null;
  }
}

export async function getCorrectAnswerFromChatGPT(question) {
  try {
    const body = {
      "prompt": `Assume I am interviewing you for a job. For the question: '${question}', give me a 5 rated answer, 
      where 5 being excellent and 0 being non relevant answer. Limit the answer to 40 words. Return only the answer.`
    };

    const rawResponse = await fetch(`https://us-central1-spryte-app.cloudfunctions.net/nestriaEvaluateRoutingQuestion`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
  
    const jsonResponse = await rawResponse.json();
    const {selectedChoice} = jsonResponse;

    return selectedChoice;
  } catch (e) {
    console.error(e);
  }
}


export async function downloadAsBlob(url) {
  try {
      console.log(`fetching: `, url);
      // Fetch the video data from the URL
      const response = await fetch(url);

      console.log(response);

      // Check if the request was successful
      if (!response.ok) {
          throw new Error(`Failed to fetch video: ${response.status} ${response.statusText}`);
      }

      // Convert the response into a Blob
      const videoBlob = await response.blob();

      // Optionally, save the Blob to a file
      // You can modify this part according to your needs
      const blobUrl = URL.createObjectURL(videoBlob);
      return blobUrl;
  } catch (error) {
      console.error('Error downloading video:', error);
  }
}

//If we use question object, then we can get question.id and question.text
export async function getTopThreeAnswers(firebase, devId, question, cutOffScore=0) {
  try {
      const CUTOFF_SCORE = cutOffScore;

      const { courseID: courseId, originalUnitID: unitId, originalQuestionID: questionId, question: questionText }  = question;

      if (!courseId || !unitId || !questionId || !questionText || !devId) {
        throw Error(`Invalid value(s) for devId: ${devId}, question.courseID: ${courseId}, 
          question.originalUnitID: ${unitId}, question.originalQuestionID: ${questionId}, question.question: ${questionText}`);
      }

      const [gptAnswer, careerPrepQuerySnapshot] = await Promise.all([
        getCorrectAnswerFromChatGPT(questionText), 
        db.collection('careerPrep')
        .where("questionData.question", "==", questionText)
        .where("isAnswered", "==", true)
        .where("mp4_conversion.status", "==", "complete")
        .get()
      ]);

      if (careerPrepQuerySnapshot.empty) {
        // Return early
        return {
          questionId,
          userAnswers: [],
          gptAnswer
        }
      }

      const qualifiedSubmissions = [];

      await Promise.all(careerPrepQuerySnapshot.docs.map(async (questionDoc) => {
          const submissionDataSnapshot = await questionDoc.ref.collection('submissions')
          .where("evalError", "==", false)
          .where("evaluate", "==", false)
          .orderBy("ts", "desc")
          .orderBy("msg")
          .orderBy("gptResponseRef")
          .limit(1)
          .get();

          if (!submissionDataSnapshot.empty) {
            const doc = submissionDataSnapshot.docs[0];
            const data = await doc.data();
            
            const { msg } = data;
            if (msg !== "") {
              qualifiedSubmissions.push({data, ref: doc?.ref});
            }
          }
      }));

      const finalQualifiedAnswers = [];

      await Promise.all(qualifiedSubmissions.map(async (submission) => {
        const submissionData = submission?.data;
        const textAnswer = submissionData?.msg;
        const likedUsers = submissionData?.likedUsers || [];
        
        // Show only the liked videos
        if (likedUsers.length === 0) {
          return;
        }

        let score = null;
        let userFirstName = null;
        let userLastName = null;
        let userId = null;
        let scoreValue = null;
        let url = null;
        let devId = null;

        if (submissionData?.gptResponseRef) {
          const _feedbackDoc = await submissionData.gptResponseRef.get();
          const { msg } = await _feedbackDoc.data();

          const { score: gptScore } = getUserScoreAndFeedbackFromGPTResponse(msg);

          if (gptScore) {
              score = gptScore;
              scoreValue = parseFloat(score.split('/')[0]);
          }
        }

        if (submissionData?.author) {
            const authorSnapshot = await submissionData.author.get();
            if (authorSnapshot.exists) {
                const data = await authorSnapshot.data();
                userId = authorSnapshot?.id;
                devId = await getDevIdFromUserId(userId) || userId;
                userFirstName = data?.first;
                userLastName = data?.last;
            }
        }

        if (scoreValue > CUTOFF_SCORE) {
          const userAnswer = await getVideoDownloadURL(firebase, devId, courseId, unitId, questionId);

          if (userAnswer) {
            let url = userAnswer?.url;
            let contentType = userAnswer?.contentType;

            if (url) {
              console.log(url);

              finalQualifiedAnswers.push({
                score: score,
                scoreValue: scoreValue,
                firstName: userFirstName,
                lastName: userLastName,
                userId,
                url,
                contentType,
                likedUsers,
                submissionDocRef: submission?.ref,
                textAnswer
              });
            }
          }
        }
      }));

      if (finalQualifiedAnswers?.length === 0) {
        // Return early
        return {
          questionId,
          userAnswers: [],
          gptAnswer
        }
      }

      // Sort by scores
      finalQualifiedAnswers.sort((a, b) => {
        return b.scoreValue - a.scoreValue; 
      });

      // Sort by likes
      finalQualifiedAnswers.sort((a, b) => {
        return b.likedUsers.length - a.likedUsers.length; 
      });

      // Removing Duplicates based on UserID, keeping top scored results
      const filteredList = Object.values(finalQualifiedAnswers.reduce((unique, obj) => {
          if (obj?.userId) {
            if (!unique[obj?.userId]) {
                unique[obj.userId] = obj;
            }
            return unique;
          }
      }, {}));

      const result = {
          questionId: question.originalQuestionID,
          userAnswers: filteredList.slice(0, 3),
          gptAnswer
      };

      // Return the result
      return result;
  } catch (error) {
      console.error("Error fetching submissions:", error.message);
      throw error;
  }
}

export function likeASubmission(firebase, submissionRef, userIdThatLiked) {
  if (!submissionRef) {
    return;
  }

  return submissionRef?.update({
    likedUsers: firebase.firestore.FieldValue.arrayUnion(userIdThatLiked)
  });
}

export function unLikeASubmission(firebase, submissionRef, userIdThatUnLiked) {
  if (!submissionRef) {
    return;
  }

  return submissionRef?.update({
    likedUsers: firebase.firestore.FieldValue.arrayRemove(userIdThatUnLiked)
  });
}

export async function getDevIdFromUserId(userId) {
  try {
      if (!userId) {
          throw Error(`Invalid userId value: ${userId}`);
      }

      const partnerDocRef = await db.doc(`users/${userId}/accounts/spryte-partner`).get();

      if (partnerDocRef.exists) {
          const { devRef } = await partnerDocRef.data();

          if (!devRef) {
              const devId = await getDevIdFromDevAccount(userId);
              return devId;
          }

          return devRef.id;
      }

      const devId = await getDevIdFromDevAccount(userId);

      return devId;
  } catch (e) {
      console.error(e);
      return null;
  }
}

async function getDevIdFromDevAccount(userId) {
    try {
        const devDocRef = await db.doc(`users/${userId}/accounts/spryte-dev`).get();

        if (devDocRef.exists) {
            const { devsReportingId } = await devDocRef.data();

            if (!devsReportingId) {
                throw Error(`devsReportingId inside users/${userId}/accounts/spryte-dev is invalid: ${devsReportingId}`);
            }

            const devsReportingRef = await devsReportingId.get();

            if (!devsReportingRef.exists) {
                throw Error(`devsReporting Document doesn't exist at: ${devsReportingId.ref.path}`);
            }

            const { devId } = await devsReportingRef.data();

            if (!devId) {
                throw Error(`devId inside ${devsReportingRef?.ref?.path} is invalid: ${devId}`);
            }

            return devId.id;
        }
    } catch (e) {
        throw Error(e);
    }
}


//Get Icons Url
export async function getAllCourseIconUrls(firebase, allNestriaSubjects) {
  const urls = {};
  const cache = await caches.open('course-icons');
  
  await Promise.all(allNestriaSubjects.map(async subject => {
    const subjectName = subject.toLowerCase().replace(/\s+/g, '');
    const path = `Nestria/Assets/CourseIcons/${subjectName}.webp`;

    try {
      const ref = firebase.storage().ref(path);
      const metadata = await ref.getMetadata();
      console.log(metadata);
      const lastUpdated = metadata.updated; // here we are getting the latest timestmp

      const request = new Request(`${path}?timestamp=${lastUpdated}`);
      
      // Check the cache first
      let response = await cache.match(request);
      if (!response) {
        const url = await ref.getDownloadURL();
        response = await fetch(url);
        // Store the response in the cache
        await cache.put(request, response.clone());

         // Remove old cache entries for the subject
         const cacheKeys = await cache.keys();
         console.log(cacheKeys);
         const oldRequest = cacheKeys.find(req => req.url.includes(path) && req.url !== request.url);
         if (oldRequest) {
          console.log("Deleting Cache");
           await cache.delete(oldRequest);
         }
      }
      
      const blobUrl = await downloadAsBlob(response.url);
      urls[subject] = blobUrl;
    } catch (error) {
      urls[subject] = null;
    }
  }));
  
  return urls;
}


