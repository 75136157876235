import React from 'react';

const Categories = ({
  categories,
  windowSize,
  updatePeopleCategory,
  updateCompanyCategory,
  updateTechCategory,
  updateRequirementsCategory,
  updateOtherCategories,
  isDefault,
  isUserConnectionsShown,
  isCompConnectionsShown,
  isTechConnectionsShown,
  isRequirementsShown,
  userConnections,
  companyConnections,
  techConnections,
  requirementsConnections,
  isAdmin,
  isAdmin2
}) => {
  return (
    <div className="categories_bar">
      {categories?.map((category, idx) => {
        // only show "Requirements" category if user is admin
        if (category === "Requirements" && !isAdmin && !isAdmin2) {
          return null;
        };
        
        return (
          <div
            key={idx}
            // className={(windowSize <= 1040 && (category === 'People' || category === 'Companies' || category === 'Technologies' || category === 'Requirements')) ?
            //   'bn1'
            //   :
            //   windowSize > 1040 ?
            //   'bn1'
            //   :
            //   'none'}
              className={`categories_item ${(isUserConnectionsShown || isDefault) && category === 'People' ? 
              'clicked' : ''} 
              ${isCompConnectionsShown && category === 'Companies' ? 
              'clicked' : ''} 
              ${isTechConnectionsShown && category === 'Technologies' ? 
              'clicked' : ''} 
              ${isRequirementsShown && category === 'Requirements' ? 
              'clicked' : ''} 
              ${windowSize <= 1040 && (category === 'People' || category === 'Companies' || category === 'Technologies' || category === 'Requirements') ? 
              'bn1' : windowSize > 1040 ? 
              'bn1' : 'none'}`}
            onClick={() => {
              if (category === 'People') {
                updatePeopleCategory();
              } else if (category === 'Companies') {
                updateCompanyCategory();
              } else if (category === 'Technologies') {
                updateTechCategory();
              } else if (category === 'Requirements') {
                updateRequirementsCategory();
              } else {
                updateOtherCategories();
              }
            }}
          >
            {category === 'People' ?
              userConnections?.length !== undefined ? category + ' ' + userConnections?.length : category
              :
              category === 'Companies' ?
              companyConnections?.length !== undefined ? category + ' ' + companyConnections?.length : category
              :
              category === 'Technologies' ?
              techConnections?.length !== undefined ? category + ' ' + techConnections?.length : category
              :
              category === 'Requirements' ?
              requirementsConnections?.length !== undefined ? category + ' ' + requirementsConnections?.length : category
              :
              category
            }
          </div>
        );
      })}
    </div>
  );
};

export default Categories;
