import React from 'react';

const PreviousIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 24"
      fill="current"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.94897 12L14.813 2.09193C15.0623 1.86454 15.0623 1.49586 14.813 1.26854L13.6092 0.170562C13.4895 0.061331 13.3271 0 13.1578 0C12.9885 0 12.826 0.061331 12.7064 0.170562L0.186996 11.5883C-0.062332 11.8157 -0.062332 12.1844 0.186996 12.4117L12.7063 23.8294C12.826 23.9387 12.9884 24 13.1577 24C13.327 24 13.4894 23.9387 13.6091 23.8294L14.8129 22.7315C15.0622 22.5041 15.0622 22.1354 14.8129 21.9081L3.94897 12Z"
        fill="black"
      />
    </svg>
  );
};

export default PreviousIcon;