import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { useState, useEffect } from "react";
import ClockOutFormModal from "./ClockOutFormModal";
import { Button, DialogActions } from "@mui/material";

export default function StaticTimePickerLandscape({
  db,
  date,
  docRef,
  setClockOutResolve,
  clockInTime,
  resolveTimesheet,
  setResolveTimesheet,
  firebase,
  devsReportingReference,
  setIsAccountNotGood,
  misssingClockOutData,
  setTimeSheetData,
  hideCancel,
  setHideCancel,
  setShowClockDates,
  setShowTimePicker,
}) {
  const [clockOutTime, setClockOutTime] = useState(dayjs(date));
  const [isShowTimePickerModal, setIsShowTimePickerModal] = useState(true);
  const [isShowClockOutFormModal, setIsShowClockOutFormModal] = useState(false);
  const [lastIndexType, setLastIndexType] = useState(null);
  const [timeWorkedInSeconds, setTimeWorkedInSeconds] = useState(0);
  const [lastIndexTime, setLastIndexTime] = useState(null);
  const [isShowClockOutModal, setIsShowClockOutModal] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    let lastindextime;
    let _lastindextime;
    let _clockoutTime;

    let _timesheet = resolveTimesheet?.data();
    _timesheet &&
      _timesheet?.presenceData?.map((data, i) => {
        if (i === _timesheet?.presenceData?.length - 1) {
          lastindextime = data?.dateTime?.toDate();
        }
      });
    const isBeforeMinValidTime = lastindextime - clockOutTime?.$d > 0;
    {
      lastindextime > 0
        ? (_lastindextime = getTimeWithAmPm(lastindextime))
        : (_lastindextime = 0);
    }
    if (isBeforeMinValidTime) {
      setIsDisabled(true);
      setIsErrorMessage(
        "Selected time must be after" + _lastindextime + ". Please try again."
      );
    }
  }, [clockOutTime]);

  const getTimeWithAmPm = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const isPM = hours > 11;
    const amPmString = isPM ? "PM" : "AM";
    const hourString = (hours % 12 === 0 ? 12 : hours % 12)
      .toString()
      .padStart(2, "0");
    const minuteString =
      minutes < 10 ? "0" + minutes.toString() : minutes.toString();
    return `${hourString}:${minuteString} ${amPmString}`;
  };

  const resolveCheckOut = async (e) => {
    let _timesheet = resolveTimesheet?.data();
    _timesheet &&
      _timesheet?.presenceData?.map((data, i) => {
        if (i === _timesheet?.presenceData?.length - 1) {
          setLastIndexType(data?.type);
          setLastIndexTime(data?.dateTime?.toDate());
        }
      });
    let timedifference = 0;
    timedifference = clockOutTime?.$d - lastIndexTime;
    if (timedifference > 0) {
      if (_timesheet?.presenceData?.length % 2 === 0) {
        let editLastPresenceDataDateTime =
          _timesheet?.presenceData[_timesheet?.presenceData?.length - 1];
        editLastPresenceDataDateTime = clockOutTime?.$d;
      } else {
        const presenceDataMap = {
          dateTime: firebase.firestore.Timestamp.fromDate(clockOutTime?.$d),
          type: "pause",
        };

        _timesheet?.presenceData?.push(presenceDataMap);
      }
    }
    let tempTimeWorked = 0;
    let workingtime;
    {
      _timesheet &&
        _timesheet?.presenceData?.map((data, i) => {
          if (
            data?.type === "checkIn" ||
            data?.type === "checkin" ||
            data?.type === "unpause"
          ) {
            workingtime = data?.dateTime?.toDate();
          }
          if (data?.type === "pause") {
            let timedifference = data?.dateTime?.toDate() - workingtime;
            tempTimeWorked += timedifference;
          }
        });
      setTimeWorkedInSeconds(tempTimeWorked);
      setIsShowClockOutFormModal(true);
      setIsShowTimePickerModal(false);
      setIsErrorMessage("");
      setIsDisabled(false);
    }
  };

  return (
    <>
      {isShowTimePickerModal && (
        <div className="timePicker_container">
          <div className="timePicker_modal">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticTimePicker
                ampm
                orientation="landscape"
                openTo="minutes"
                value={clockOutTime}
                onChange={(newValue) => {
                  setClockOutTime(newValue);
                  setIsDisabled(false);
                  setIsErrorMessage("");
                }}
                minTime={dayjs(date)}
                maxTime={dayjs(date)?.set("hour", 23)?.set("minutes", 59)}
                renderInput={(params) => <TextField {...params} />}
                componentsProps={{ actionBar: { actions: [] } }}
              />
            </LocalizationProvider>
            <DialogActions>
              <span style={{ color: "red" }}>{isErrorMessage}</span>
              {hideCancel ? null : (
                <Button
                  onClick={() => {
                    setIsShowTimePickerModal(false);
                    if (!hideCancel) {
                      setClockOutResolve(false);
                    }
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                style={
                  isDisabled ? { color: "grey", pointerEvents: "none" } : {}
                }
                onClick={(e) => resolveCheckOut(e)}
              >
                Set New ClockOut{" "}
              </Button>
            </DialogActions>
          </div>
        </div>
      )}
      {isShowClockOutFormModal && (
        <ClockOutFormModal
          isShowClockOutModal={isShowClockOutModal}
          docRef={docRef}
          clockOutTime={clockOutTime?.$d}
          setIsShowClockOutModal={setIsShowClockOutModal}
          setClockOutResolve={setClockOutResolve}
          timeSheetData={resolveTimesheet}
          setTimeSheetData={setResolveTimesheet}
          firebase={firebase}
          devsReportingReference={devsReportingReference}
          timeWorkedInSeconds={timeWorkedInSeconds}
          misssingClockOutData={misssingClockOutData}
          setIsAccountNotGood={setIsAccountNotGood}
          hideCancel={hideCancel}
          setHideCancel={setHideCancel}
          setShowClockDates={setShowClockDates}
          setShowTimePicker={setShowTimePicker}
        />
      )}
    </>
  );
}
