import { useState } from "react";
import { LessonScoreBox } from "./LessonScoreBox";

export function ScoreBox({hide, unitScore, finalAssessmentScore, level, isLoading}) {

    if (hide) {
        return (
            <></>
        );
    }

    return (
        <div style={{display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center"}}>
            <LessonScoreBox
            score={unitScore}
            type={"unit"}
            label="Unit Score"
            isLoading={isLoading}
            />

            <LessonScoreBox
            score={finalAssessmentScore}
            type={"final"}
            label="Final Score"
            isLoading={isLoading}
            />

            <LessonScoreBox
            score={level}
            type={"level"}
            label="Level"
            isLoading={isLoading}
            />
        </div>
    )
}