import React, { useState, useEffect } from "react";
import TimeSheetPrevIcon from "../svgs/TimeSheetPrevIcon";
import TimeSheetNextIcon from "../svgs/TimeSheetNextIcon";
import ReviewFormSlider from "./ReviewFormSlider";
import MyChart from "./MyChart";
import LocationIcon from "../svgs/LocationIcon";
import VectorIcon from "../svgs/VectorIcon";
import noAvatar from "../../static/no-avatar.png";
import Spinner from "../spinner/Spinner";
import TimeSheetDownArrow from "../svgs/TimeSheetDownArrow";

const ReviewModal = ({
  selectedUser,
  setReviewModal,
  db,
  firebase,
  currUserId,
  currentUserObj,
}) => {
  const [timeSheetData, setTimeSheetData] = useState(null);
  const [firstDayofWeek, setFirstDayofWeek] = useState(new Date());
  const [firstDayofMonth, setFirstDayofMonth] = useState(null);
  const [weeklyAverages, setWeeklyAverages] = useState(0);
  const [monthlyAverages, setMonthlyAverages] = useState(0);
  const [weeklyTotal, setWeeklyTotal] = useState(0);
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [checkinDtObj, setCheckinDtObj] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(null);
  const [currentDateObj, setCurrentDateObj] = useState(null);
  const [previousDate, setPreviousDate] = useState(null);
  const [previousDateObj, setPreviousDateObj] = useState(null);
  const [nextDate, setNextDate] = useState(null);
  const [nextDateObj, setNextDateObj] = useState(null);
  const [presenceMap, setPresenceMap] = useState(null);
  const [timeWorked, setTimeWorked] = useState();
  const [activeDate, setActiveDate] = useState("current_date");
  const [showPrevious, setShowPrevious] = useState(false);
  const [presenceData, setPresenceData] = useState([]);
  const [misssingClockOutData, setMisssingClockOutData] = useState(null);
  const [isToday, setIsToday] = useState(false);
  const [devFeedback, setDevFeedback] = useState([]);
  const [selectedScore, setSelectedScore] = useState(0);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [performanceFeedbacks, setperformanceFeedbacks] = useState([]);
  const currentUserRef = currentUserObj?.ref;
  const [isOpen, setIsOpen] = useState(true);
  const toggleAccordion = () => setIsOpen(!isOpen);

  const months_arr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    let _currentday = new Date();
    checkinDtObj?.getDate() === _currentday?.getDate() &&
    checkinDtObj?.getMonth() === _currentday?.getMonth() &&
    checkinDtObj?.getFullYear() === _currentday?.getFullYear()
      ? setIsToday(true)
      : setIsToday(false);
  }, [checkinDtObj]);

  useEffect(() => {
    getFirstDayOfweek();
    getFirstDayOfMonth();
    //getDevFeedback();
  }, []);

  useEffect(() => {
    const setData = () => {
      let _currentdate = new Date();
      let tempTimeWorked = 0;
      let workingtime;
      {
        timeSheetData &&
          timeSheetData?.data()?.presenceData?.map((data, i) => {
            if (["checkin", "checkIn", "unpause"].includes(data?.type)) {
              workingtime = data?.dateTime?.toDate();
            }
            if (["checkout", "pause"].includes(data?.type)) {
              let timedifference = data?.dateTime?.toDate() - workingtime;
              tempTimeWorked += timedifference;
            }
            if (
              checkinDtObj?.getDate() === _currentdate?.getDate() &&
              checkinDtObj?.getMonth() === _currentdate?.getMonth() &&
              checkinDtObj?.getFullYear() === _currentdate?.getFullYear() &&
              i === timeSheetData.data().presenceData.length - 1 &&
              ["checkin", "checkIn", "unpause"].includes(data?.type)
            ) {
              let timedifference = new Date() - data?.dateTime?.toDate();
              tempTimeWorked += timedifference;
            }
          });
        let timeworkedObj = new Date(tempTimeWorked);
        let seconds = timeworkedObj?.getUTCSeconds();
        let minutes = timeworkedObj?.getUTCMinutes();
        let hours = timeworkedObj?.getUTCHours();
        setTimeWorked(
          hours?.toString()?.padStart(2, "0") +
            " hr " +
            minutes?.toString()?.padStart(2, "0") +
            " min " +
            "worked"
        );
      }
    };
    setData();
  }, [timeSheetData]);

  const getLast30Days = () => {
    const dates = [];
    const currentDate = new Date();
    for (let i = 0; i < 30; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - i
      );
      const dateString = `${months_arr[date.getMonth()]} ${date
        .getDate()
        .toString()
        .padStart(2, "0")} ${date.getFullYear()}`;
      dates.push(dateString);
    }
    return dates;
  };

  // const getDevFeedback = async () => {
  //   const currentDate = new Date();

  //   const feedbackCollectionRef =
  //     selectedUser?.devreportingRefrence.collection("feedback");

  //   const existingDocQuery = feedbackCollectionRef?.where(
  //     "createdBy",
  //     "==",
  //     currentUserRef?.path
  //   );

  //   const existingDocSnapshot = await feedbackCollectionRef.get();
  //   const feedbackData = [];
  //   existingDocSnapshot.docs.forEach((doc) => {
  //     const feedback = doc.data();
  //     let d = feedback?.createdOn?.toDate();
  //     let formattedDate = d?.toLocaleString("en-US", {
  //       month: "short",
  //       day: "numeric",
  //       year: "numeric",
  //     });
  //     formattedDate = formattedDate.replace(
  //       /(\d+)(st|nd|rd|th)/,
  //       "$1<sup>$2</sup>"
  //     );

  //     console.log("formattedDate", formattedDate);
  //     feedbackData.push({
  //       id: doc.id,
  //       createdOn: formattedDate,
  //       score: feedback.score,
  //       feedback: feedback.feedback,
  //       givenBy: currentUserObj?.first + " " + currentUserObj?.last,
  //     });
  //   });
  //   setperformanceFeedbacks(feedbackData);
  //   console.log("feedbackData", feedbackData);
  //   const userData = existingDocSnapshot.docs[0]?.data();
  //   console.log("userData", userData);
  //   setSelectedScore(userData?.score);
  //   setSelectedLabel(userData?.feedback);
  // };

  useEffect(() => {
    const getDevFeedback = async () => {
      const currentDate = new Date();

      const feedbackCollectionRef =
        selectedUser?.devreportingRefrence?.collection("feedback");

      const existingDocQuery = feedbackCollectionRef?.where(
        "createdBy",
        "==",
        currentUserRef?.path
      );

      const existingDocSnapshot = await feedbackCollectionRef?.get();
      const feedbackData = [];
      existingDocSnapshot?.docs?.forEach((doc) => {
        const feedback = doc?.data();
        let d = feedback?.createdOn?.toDate();
        let formattedDate = d?.toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
        formattedDate = formattedDate?.replace(
          /(\d+)(st|nd|rd|th)/,
          "$1<sup>$2</sup>"
        );

        feedbackData.push({
          id: doc.id,
          createdOn: formattedDate,
          score: feedback.score,
          feedback: feedback.feedback,
          givenBy: currentUserObj?.first + " " + currentUserObj?.last,
        });
      });
      setperformanceFeedbacks(feedbackData);

      const mostRecentDocSnapshot = await feedbackCollectionRef
        ?.orderBy("createdOn", "desc")
        ?.limit(1)
        ?.get();

      const mostRecentFeedback = mostRecentDocSnapshot?.docs[0]?.data();
      setSelectedScore(mostRecentFeedback?.score);
      setSelectedLabel(mostRecentFeedback?.feedback);
    };

    getDevFeedback();
  }, [feedbackSubmitted]);

  const submitDevFeedback = async () => {
    setLoading(true);
    const currentDate = new Date();
    const startOfDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const endOfDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1
    );

    const feedbackCollectionRef =
      selectedUser?.devreportingRefrence?.collection("feedback");

    const existingDocQuery = feedbackCollectionRef
      ?.where("createdBy", "==", currentUserRef?.path)
      ?.where("createdOn", ">=", startOfDay)
      ?.where("createdOn", "<", endOfDay);
    const existingDocSnapshot = await existingDocQuery?.get();

    const devFeedbackObj = {
      createdBy: currentUserRef?.path,
      createdOn: new Date(),
      feedback: selectedLabel,
      score: selectedScore,
    };
    if (!existingDocSnapshot?.empty) {
      const existingDocRef = existingDocSnapshot?.docs[0]?.ref;
      await existingDocRef?.update(devFeedbackObj);
    } else {
      const newDocRef = await feedbackCollectionRef?.add(devFeedbackObj);
    }
    setLoading(false);
    setFeedbackSubmitted(true); // show feedback submitted message
  };

  useEffect(() => {
    if (timeSheetData) return;
    const setDate = () => {
      const _currentdate = new Date();
      const year = _currentdate.getFullYear();
      const month = _currentdate.getMonth();
      const startOfMonth = new Date(year, month, 1);
      const endOfMonth = new Date(year, month + 1, 0);

      setSelectedDate(
        _currentdate?.getFullYear() +
          "-" +
          (_currentdate?.getMonth() + 1) +
          "-" +
          _currentdate?.getDate()
      );
      let currentDayInMilli = new Date(
        _currentdate?.getFullYear() +
          "-" +
          (_currentdate?.getMonth() + 1) +
          "-" +
          _currentdate?.getDate()
      )?.getTime();
      let oneDay = 1000 * 60 * 60 * 24;
      let twoDay = 2 * oneDay;
      let currDate = _currentdate?.getDate();
      if (currDate && currDate?.toString()?.length === 1) {
        currDate = "0".concat(currDate);
      }
      let currentmonth = months_arr[_currentdate?.getMonth()];
      let currentyear = _currentdate?.getFullYear();
      setCheckinDtObj(new Date());
      setCurrentDateObj(_currentdate);
      setCurrentDate(
        currentmonth?.concat(" " + currDate)?.concat(" " + currentyear)
      );

      let previousDayInMilli = currentDayInMilli - oneDay;
      let previousDate = new Date(previousDayInMilli);
      setPreviousDateObj(previousDate);
      let premonth = months_arr[previousDate?.getMonth()];
      let preyear = previousDate?.getFullYear();
      let preDate = previousDate?.getDate();
      if (preDate && preDate?.toString()?.length === 1) {
        preDate = "0".concat(preDate);
      }
      setPreviousDate(premonth?.concat(" " + preDate)?.concat(" " + preyear));

      let nextDayInMilli = currentDayInMilli + oneDay;
      let nextDate = new Date(nextDayInMilli);
      setNextDateObj(nextDate);
      let nextmonth = months_arr[nextDate?.getMonth()];
      let nextyear = nextDate?.getFullYear();
      let nxtDate = nextDate?.getDate();
      if (nxtDate && nxtDate?.toString()?.length === 1) {
        nxtDate = "0".concat(nxtDate);
      }
      setNextDate(nextmonth?.concat(" " + nxtDate)?.concat(" " + nextyear));
      let checkinPresenceSnapshot = selectedUser?.devreportingRefrence
        ?.collection(`presence`)
        ?.orderBy("checkIn", "asc");

      let res = checkinPresenceSnapshot?.onSnapshot((snap) => {
        let checkinPrsenceDataMap = {};
        let clockOutReportMap = [];
        let dates = [];
        const data = [];
        let weeklyPresenceMap = [];
        let monthlyPresenceMap = [];
        var todaysKey = currentmonth
          ?.concat(" " + currDate)
          ?.concat(" " + currentyear);

        ////////////Last 30 days baar data

        const last30Days = getLast30Days();

        last30Days.forEach((date) => {
          const id = date;
          const value = 0;
          data.push({ id, value });
        });
        snap.forEach((doc, id) => {
          let _presenceData = doc?.data();
          let checkIn = doc?.data()?.checkIn?.toDate();
          let checkOut = doc?.data()?.checkOut?.toDate();
          let timeworked = doc?.data()?.timeWorked;
          let lastIndexDateTime;
          let tempTimeWorked = 0;
          let workingtime;
          let hours;
          let decimalHours;
          checkinPrsenceDataMap[checkIn?.toString()?.substring(4, 15)] = doc;
          _presenceData?.presenceData?.map((data, i) => {
            if (i === _presenceData?.presenceData?.length - 1) {
              lastIndexDateTime = data?.dateTime?.toDate();
            }
          });

          const dateIndex = last30Days.indexOf(
            checkIn?.toString()?.substring(4, 15)
          );
          if (dateIndex !== -1) {
            {
              _presenceData &&
                _presenceData?.presenceData?.map((data, i) => {
                  if (
                    data?.type === "checkin" ||
                    data?.type === "checkIn" ||
                    data?.type === "unpause"
                  ) {
                    workingtime = data?.dateTime?.toDate();
                  }
                  if (data?.type === "checkout" || data?.type === "pause") {
                    let timedifference = data?.dateTime?.toDate() - workingtime;
                    tempTimeWorked += timedifference;
                  }
                  if (
                    isToday &&
                    i === timeSheetData?.presenceData?.length - 1
                  ) {
                    if (data?.type === "unpause") {
                      let timedifference =
                        new Date() - data?.dateTime?.toDate();
                      tempTimeWorked += timedifference;
                    }
                  }
                });
              let timeworkedObj = new Date(tempTimeWorked);
              let seconds = timeworkedObj?.getUTCSeconds();
              let minutes = timeworkedObj?.getUTCMinutes();
              hours = timeworkedObj?.getUTCHours();
              decimalHours =
                hours !== 0
                  ? parseFloat(
                      hours +
                        "." +
                        (minutes !== 0 ? Math.round(minutes / 6) : 0)
                    )
                  : 0;
            }
            data[dateIndex].value = decimalHours;
          }
          // condition to check for previous checkout: if previous checkout is missing, allowing user to resolve checkout for previous days
          // another condition to check for: second checkout onwards if checkout time and presence map last record time doesnot match,allowing user to resolve checkout for previous days.
          if (!_presenceData?.checkoutReport) {
            if (
              checkIn?.toString()?.substring(4, 15) !== todaysKey?.toString() &&
              checkIn < _currentdate
            ) {
              clockOutReportMap.push(checkIn?.toString()?.substring(4, 15));
            }
          }
          if (
            checkIn > firstDayofWeek ||
            (checkIn?.getMonth() === firstDayofWeek?.getMonth() &&
              checkIn?.getDate() === firstDayofWeek?.getDate() &&
              checkIn?.getFullYear() === firstDayofWeek?.getFullYear())
          ) {
            weeklyPresenceMap.push(_presenceData);
          }
          if (
            checkIn > firstDayofMonth ||
            (checkIn?.getMonth() === firstDayofMonth?.getMonth() &&
              checkIn?.getDate() === firstDayofMonth?.getDate() &&
              checkIn?.getFullYear() === firstDayofMonth?.getFullYear())
          ) {
            monthlyPresenceMap.push(_presenceData);
          }
        });

        setMisssingClockOutData(clockOutReportMap);
        setPresenceData(data);
        setPresenceMap(checkinPrsenceDataMap);

        let _weeklytimeWorked = 0;
        let _monthlytimeWorked = 0;
        let _weeklyaverages = 0;
        let _monthlyaverages = 0;
        let weeklytotal = 0;
        let monthlyTotal = 0;
        weeklyPresenceMap &&
          weeklyPresenceMap?.map((data) => {
            let _timeworked = getTotalTimeWorkedInSeconds(data);
            //let _timeworked = data?.timeWorked;
            _weeklytimeWorked += _timeworked;
          });
        weeklytotal =
          _weeklytimeWorked !== 0 ? Math.floor(_weeklytimeWorked / 3600) : 0;
        _weeklyaverages =
          weeklytotal !== 0
            ? Math.floor(weeklytotal / weeklyPresenceMap?.length)
            : 0;
        setWeeklyTotal(weeklytotal);
        setWeeklyAverages(_weeklyaverages);
        monthlyPresenceMap &&
          monthlyPresenceMap?.map((data) => {
            let _timeworked = getTotalTimeWorkedInSeconds(data);
            _monthlytimeWorked += _timeworked;
          });
        monthlyTotal =
          _monthlytimeWorked !== 0 ? Math.floor(_monthlytimeWorked / 3600) : 0;
        _monthlyaverages =
          monthlyTotal !== 0
            ? Math.floor(monthlyTotal / monthlyPresenceMap?.length)
            : 0;
        setMonthlyTotal(monthlyTotal);
        setMonthlyAverages(_monthlyaverages);
        //curret day presence data
        var keys = Object.keys(checkinPrsenceDataMap);
        keys &&
          keys?.map((key) => {
            let tempTimeWorked = 0;
            if (key?.toString() === todaysKey?.toString()) {
              //setDocRef(checkinPrsenceDataMap[key]?.ref);
              tempTimeWorked = getHoursworked(checkinPrsenceDataMap[key]);
              setTimeSheetData(checkinPrsenceDataMap[key]);
              setTimeWorked(tempTimeWorked);
            }
          });
      });
      return res;
    };
    if (firstDayofWeek != null || firstDayofMonth != null) setDate();
  }, [firstDayofWeek, firstDayofMonth]);

  const getHoursworked = (timeSheetData) => {
    let tempTimeWorked = 0;
    let workingtime;
    {
      timeSheetData &&
        timeSheetData?.presenceData?.map((data, i) => {
          if (["checkin", "checkIn", "unpause"].includes(data?.type)) {
            workingtime = data?.dateTime?.toDate();
          }
          if (["checkout", "pause"].includes(data?.type)) {
            let timedifference = data?.dateTime?.toDate() - workingtime;
            tempTimeWorked += timedifference;
          }
          if (
            isToday &&
            i === timeSheetData.data().presenceData.length - 1 &&
            ["checkin", "checkIn", "unpause"].includes(data?.type)
          ) {
            let timedifference = new Date() - data?.dateTime?.toDate();
            tempTimeWorked += timedifference;
          }
        });

      let timeworkedObj = new Date(tempTimeWorked);
      let seconds = timeworkedObj?.getUTCSeconds();
      let minutes = timeworkedObj?.getUTCMinutes();
      let hours = timeworkedObj?.getUTCHours();
      let timeworked =
        hours?.toString()?.padStart(2, "0") +
        "hr " +
        minutes?.toString()?.padStart(2, "0") +
        "min ";
      return timeworked;
    }
  };

  const getTotalTimeWorkedInSeconds = (timeSheetData) => {
    let tempTimeWorked = 0;
    let workingtime;
    let checkIn = timeSheetData?.checkIn?.toDate();
    let currentDate = new Date();
    {
      timeSheetData &&
        timeSheetData?.presenceData?.map((data, i) => {
          if (["checkin", "checkIn", "unpause"].includes(data?.type)) {
            workingtime = data?.dateTime?.toDate();
          }
          if (["checkout", "pause"].includes(data?.type)) {
            let timedifference = data?.dateTime?.toDate() - workingtime;
            tempTimeWorked += timedifference;
          }
          if (
            checkIn?.getMonth() === currentDate?.getMonth() &&
            checkIn?.getDate() === currentDate?.getDate() &&
            checkIn?.getFullYear() === currentDate?.getFullYear() &&
            i === timeSheetData?.presenceData?.length - 1 &&
            ["checkin", "checkIn", "unpause"].includes(data?.type)
          ) {
            let timedifference = new Date() - data?.dateTime?.toDate();
            tempTimeWorked += timedifference;
          }
        });
      return tempTimeWorked ? Math.floor(tempTimeWorked / 1000) : 0; // convert milliseconds to seconds
    }
  };

  const statusColors = {
    checkIn: "#60C4AB",
    checkin: "#60C4AB",
    unpause: "#60C4AB",
    pause: "#484848",
    "NOT CHECKED-IN": "#E8693F",
  };

  const dotStyle = {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: statusColors[selectedUser.status] || "gray",
    position: "absolute",
    top: "0px",
    right: "5px",
  };

  const getFirstDayOfweek = () => {
    let currentDate = new Date();
    let _currentday = currentDate?.getDay();
    let _firstDayOfweek = new Date();
    let diff =
      currentDate?.getDate() - _currentday + (_currentday == 0 ? -6 : 1); // adjust when day is sunday
    _firstDayOfweek = new Date(currentDate?.setDate(diff));
    setFirstDayofWeek(_firstDayOfweek);
  };

  const getFirstDayOfMonth = () => {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let _firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);
    setFirstDayofMonth(new Date(_firstDay));
  };
  const previous = () => {
    setShowPrevious(true);
    setTimeSheetData(null);
    setActiveDate("current_date");
    let todayDateinMilli = new Date(selectedDate)?.getTime();
    let oneDay = 1000 * 60 * 60 * 24;
    let twoDay = 2 * oneDay;
    let threeDay = 3 * oneDay;
    let currentDayInMilli = todayDateinMilli - oneDay;
    let currentDate = new Date(currentDayInMilli);
    currentDate?.setSeconds(new Date()?.getSeconds());
    currentDate?.setMinutes(new Date()?.getMinutes());
    currentDate?.setHours(new Date()?.getHours());
    setCheckinDtObj(currentDate);
    setCurrentDateObj(currentDate);
    let currentmonth = months_arr[currentDate?.getMonth()];
    let currentyear = currentDate?.getFullYear();
    let currDate = currentDate?.getDate();
    if (currDate && currDate?.toString()?.length === 1) {
      currDate = "0".concat(currDate);
    }
    setCurrentDate(
      currentmonth?.concat(" " + currDate)?.concat(" " + currentyear)
    );
    let nextDate = new Date(todayDateinMilli);
    setNextDateObj(nextDate);
    let nextmonth = months_arr[nextDate?.getMonth()];
    let nextyear = nextDate?.getFullYear();
    let nxtDate = nextDate?.getDate();
    if (nxtDate && nxtDate?.toString()?.length === 1) {
      nxtDate = "0".concat(nxtDate);
    }
    setNextDate(nextmonth?.concat(" " + nxtDate)?.concat(" " + nextyear));
    let previousDayInMilli = todayDateinMilli - twoDay;
    let previousDate = new Date(previousDayInMilli);
    setPreviousDateObj(previousDate);
    let previousmonth = months_arr[previousDate?.getMonth()];
    let previousyear = previousDate?.getFullYear();
    let preDate = previousDate?.getDate();
    if (preDate && preDate?.toString()?.length === 1) {
      preDate = "0".concat(preDate);
    }
    setPreviousDate(
      previousmonth?.concat(" " + preDate)?.concat(" " + previousyear)
    );
    setSelectedDate(currentDate);
    var todaysKey = currentmonth
      ?.concat(" " + currDate)
      ?.concat(" " + currentyear);
    let keys = Object.keys(presenceMap);
    keys &&
      keys?.map((key) => {
        if (key?.toString() === todaysKey?.toString()) {
          setTimeSheetData(presenceMap[key]);
        }
      });
  };

  const next = () => {
    setTimeSheetData(null);
    setActiveDate("current_date");
    let todayDateinMilli = new Date(selectedDate).getTime();
    let oneDay = 1000 * 60 * 60 * 24;
    let currentDayInMilli = todayDateinMilli + oneDay;
    let currentDate = new Date(currentDayInMilli);
    currentDate?.setSeconds(new Date()?.getSeconds());
    currentDate?.setMinutes(new Date()?.getMinutes());
    currentDate?.setHours(new Date()?.getHours());
    setCheckinDtObj(currentDate);
    setCurrentDateObj(currentDate);
    let currentmonth = months_arr[currentDate?.getMonth()];
    let currentyear = currentDate?.getFullYear();
    let currDate = currentDate?.getDate();
    if (currDate && currDate?.toString().length === 1) {
      currDate = "0".concat(currDate);
    }
    setCurrentDate(
      currentmonth?.concat(" " + currDate)?.concat(" " + currentyear)
    );
    let previousDayInMilli = currentDayInMilli - oneDay;
    let previousDate = new Date(previousDayInMilli);
    setPreviousDateObj(previousDate);
    let previousmonth = months_arr[previousDate?.getMonth()];
    let previousyear = previousDate?.getFullYear();
    let preDate = previousDate?.getDate();
    if (preDate && preDate?.toString()?.length === 1) {
      preDate = "0".concat(preDate);
    }
    setPreviousDate(
      previousmonth?.concat(" " + preDate)?.concat(" " + previousyear)
    );
    let nextDayInMilli = currentDayInMilli + oneDay;
    let nextDate = new Date(nextDayInMilli);
    setNextDateObj(nextDate);
    let nextmonth = months_arr[nextDate?.getMonth()];
    let nextyear = nextDate?.getFullYear();
    let nxtDate = nextDate?.getDate();
    if (nxtDate && nxtDate?.toString()?.length === 1) {
      nxtDate = "0".concat(nxtDate);
    }
    setNextDate(nextmonth?.concat(" " + nxtDate)?.concat(" " + nextyear));
    setSelectedDate(currentDate);
    var todaysKey = currentmonth
      ?.concat(" " + currDate)
      ?.concat(" " + currentyear);
    let keys = Object.keys(presenceMap);
    keys &&
      keys?.map((key) => {
        if (key?.toString() === todaysKey?.toString()) {
          setTimeSheetData(presenceMap[key]);
        }
      });
  };
  const getPresenceData = (checkinDate, checkinDateObj) => {
    setCheckinDtObj(checkinDateObj);
    setTimeSheetData(null);
    let keys = Object.keys(presenceMap);
    keys &&
      keys?.map((key) => {
        if (key?.toString() === checkinDate?.toString()) {
          //setNoRecordsMsg(false);
          //setDocRef(presenceMap[key]?.ref);
          setTimeSheetData(presenceMap[key]);
        }
      });
  };

  return (
    <>
      <div className="review_modal_form_container">
        <div className="review_modal">
          <div className="review_modal_item">
            <div className="back_btn">
              <TimeSheetPrevIcon width="12" height="12" icon="backArrow" />
              <p
                onClick={() => {
                  setReviewModal(false);
                }}
              >
                Back to all Resources
              </p>
            </div>
            <div className="dev">
              <div className="dev-details">
                <div className="dev_avatar">
                  {selectedUser.avatar ? (
                    <img src={selectedUser.avatar} alt={selectedUser.name} />
                  ) : (
                    <img src={noAvatar} alt={selectedUser.name} />
                  )}
                  <div style={dotStyle}></div>
                </div>
                <div className="dev-data">
                  <p className="dev-name">{selectedUser.name}</p>
                  <p>{selectedUser.designation}</p>
                  <div className="location">
                    <LocationIcon width="18" height="18" />

                    <p>{selectedUser.location}</p>
                  </div>
                </div>
              </div>
              <div className="dev-days-active">
                <p className="activedays">{selectedUser.activedays} </p>
                <div className="vector">
                  <VectorIcon width="15" height="15" />
                  <p>days active</p>
                </div>
              </div>
            </div>
            <div className="activity-graph">
              <div className="activity">
                <p>Activity</p>
                <VectorIcon width="15" height="15" />
              </div>
              {presenceData.length > 0 ? (
                <div className="graph">
                  <MyChart
                    presenceData={presenceData}
                    misssingClockOutData={misssingClockOutData}
                  />
                  <p className="dev-lastlogin">
                    {selectedUser.name} last logged in: {selectedUser.lastlogin}
                  </p>
                </div>
              ) : (
                <div className="no-data">
                  <p>No data available.</p>
                </div>
              )}
            </div>
            <div className="dev-timesheet">
              <div className="timesheets">Involvement</div>
              <div className="worked">{timeWorked}</div>
            </div>
            <div className="dev-presence-data">
              <div className="prev-date-container">
                <div
                  style={{ padding: "1.1rem 0 0.8rem 0" }}
                  onClick={previous}
                >
                  <TimeSheetPrevIcon width="13" height="13" />
                </div>
                <div
                  className={
                    activeDate === "prev_date"
                      ? "header prev_date active_date"
                      : "header prev_date"
                  }
                  // onClick={() => {
                  //   getPresenceData(previousDate, previousDateObj);
                  //   setActiveDate("prev_date");
                  // }}
                >
                  {previousDate?.toString()?.substring(0, 6)}
                </div>
              </div>
              <div
                className={
                  activeDate === "current_date"
                    ? "header current_date active_date"
                    : "header current_date"
                }
                // onClick={() => {
                //   getPresenceData(currentDate, currentDateObj);
                //   setActiveDate("current_date");
                // }}
              >
                {currentDate?.toString()?.substring(0, 6)}
              </div>

              <div className="next-date-container">
                {nextDateObj < new Date() ? (
                  <div
                    className={
                      activeDate === "next_date"
                        ? "header next_date active_date"
                        : "header next_date"
                    }
                    // onClick={() => {
                    //   getPresenceData(nextDate, nextDateObj);
                    //   setActiveDate("next_date");
                    // }}
                  >
                    {nextDate?.toString()?.substring(0, 6)}
                  </div>
                ) : (
                  <div
                    className={
                      activeDate === "next_date"
                        ? "header next_date active_date"
                        : "header next_date"
                    }
                  >
                    {nextDate?.toString()?.substring(0, 6)}
                  </div>
                )}
                {showPrevious &&
                  checkinDtObj < new Date() &&
                  !(
                    currentDateObj?.getDate() === new Date()?.getDate() &&
                    currentDateObj?.getMonth() === new Date()?.getMonth() &&
                    currentDateObj?.getFullYear() === new Date()?.getFullYear()
                  ) && (
                    <div onClick={next}>
                      {/* <div style={{ padding: "1rem 0" }} > */}
                      <TimeSheetNextIcon width="12" height="12" />
                    </div>
                  )}
              </div>
            </div>
            <div className="checkin-content">
              <div className="checkin-content-header">
                <div className="header header_type">Type</div>
                <div className="header header_time">Time</div>
                <div className="header header_access">Action</div>
              </div>
              {timeSheetData !== null ? (
                timeSheetData?.data()?.presenceData?.map((data, i) => {
                  const isLastRecord =
                    i === timeSheetData.data().presenceData.length - 1; // check if this is the last record in the presenceData array
                  const dateTime = data?.dateTime?.toDate();

                  if (!dateTime) {
                    return null;
                  }
                  const hours = dateTime.getHours();
                  const minutes = dateTime.getMinutes();
                  const isPM = hours > 11;
                  const amPmString = isPM ? "PM" : "AM";
                  const hourString =
                    hours % 12 === 0 ? "12" : (hours % 12).toString();
                  const minuteString =
                    minutes < 10
                      ? "0" + minutes.toString()
                      : minutes.toString();
                  const finalString = `${hourString}:${minuteString} ${amPmString}`;
                  return (
                    <div
                      className="checkin-content-data"
                      key={i}
                      style={{ display: "flex", marginTop: "10px" }}
                    >
                      <div className="child font-green uppercase">
                        {!isToday &&
                        isLastRecord &&
                        timeSheetData?.data()?.checkOut
                          ? "checkOut"
                          : data?.type}
                      </div>
                      <div className="child time">{finalString}</div>
                      <div className="child font-blue"></div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="empty_timesheet">
                    <p>No Records found</p>
                  </div>
                </>
              )}

              <div className="checkin-content-report">
                <button className="checkin-button week">
                  <span className="first_child">
                    <b>This Week</b>
                  </span>
                  <span className="border">|</span>
                  <span>
                    <b>{weeklyAverages}</b> per day
                  </span>
                  <span className="border">|</span>
                  <span>
                    <b>{weeklyTotal}</b> total
                  </span>
                </button>

                <button className="checkin-button button-spacing month">
                  <span className="first_child">
                    <b>This Month</b>
                  </span>
                  <span className="border">|</span>
                  <span>
                    <b>{monthlyAverages}</b> per day
                  </span>
                  <span className="border">|</span>
                  <span>
                    <b>{monthlyTotal}</b> total
                  </span>
                </button>
              </div>
              <div className="performance-feedback">
                Real Time Performance Feedback
              </div>

              <div className="performance-feedback-feature-text">
                <VectorIcon width="20" height="20" />
                <p>
                  Performance feedback is the most important feature to use to
                  improve your project success. This feedback is only shared
                  with your account & success managers, not the resource
                  directly. Blunt Honesty is the best policy.
                </p>
              </div>

              <div className="feedback-slider">
                <ReviewFormSlider
                  db={db}
                  firebase={firebase}
                  currUserId={currUserId}
                  currentUserObj={currentUserObj}
                  selectedScore={selectedScore}
                  selectedLabel={selectedLabel}
                  setSelectedScore={setSelectedScore}
                  setSelectedLabel={setSelectedLabel}
                  setFeedbackSubmitted={setFeedbackSubmitted}
                />
              </div>

              <div className="performance-satisfied">
                How satisfied are you with {selectedUser.name} right now?
              </div>

              <div className="medium-feedback-text">
                <strong>Consiquences of this feedback:</strong> Submitting a
                MEDIUM feedback rating does not trigger any immediate review.
                Five (5) subsequent MEDIUM reviews trigger a review with your
                Account Manager to discuss options.
              </div>

              {isLoading ? (
                <>
                  <Spinner width="4rem" height="4rem" overlayHeight="10vh" />
                </>
              ) : (
                <div className="submit">
                  {feedbackSubmitted ? (
                    <span>Feedback submitted successfully</span>
                  ) : (
                    <span onClick={submitDevFeedback}>Submit</span>
                  )}
                </div>
              )}
              <div className="past-feedback">
                <div className="feedback-accordion" onClick={toggleAccordion}>
                  <p className="past-feedback-header">Past Feedback</p>
                  <p className="accordion_arrows">
                    {isOpen ? (
                      "-"
                    ) : (
                      <TimeSheetDownArrow width="12" height="12" />
                    )}
                  </p>
                </div>

                {isOpen && (
                  <div className="feedback">
                    {performanceFeedbacks &&
                      performanceFeedbacks.map((feedback, index) => {
                        return (
                          <div className="feedback-data" key={index}>
                            <p>{feedback.createdOn}</p>
                            <p>{feedback.feedback}</p>
                            <p>{feedback.score}</p>
                            <p>{feedback.givenBy}</p>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReviewModal;
