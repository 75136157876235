import React from 'react';

const DeleteIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="22"
        height="22"
        rx="11"
        fill="#EEEEEE"
      />
      <path
        d="M10.9209 5C11.992 5 12.865 5.84812 12.905 6.90945L12.9065 6.98561H15.4101C15.6485 6.98561 15.8417 7.17887 15.8417 7.41727C15.8417 7.64076 15.6719 7.82459 15.4542 7.84669L15.4101 7.84892H15.0333L14.4452 15.6428C14.3874 16.4083 13.7494 17 12.9817 17H8.86001C8.09231 17 7.45432 16.4083 7.39655 15.6428L6.80806 7.84892H6.43165C6.20816 7.84892 6.02433 7.67906 6.00223 7.4614L6 7.41727C6 7.19377 6.16986 7.00995 6.38752 6.98784L6.43165 6.98561H8.93525C8.93525 5.88899 9.82424 5 10.9209 5ZM14.1676 7.84892H7.67409L8.25741 15.5779C8.2812 15.8931 8.5439 16.1367 8.86001 16.1367H12.9817C13.2978 16.1367 13.5605 15.8931 13.5843 15.5779L14.1676 7.84892ZM12.0432 9.66187C12.2667 9.66187 12.4505 9.83173 12.4726 10.0494L12.4748 10.0935V13.8921C12.4748 14.1305 12.2816 14.3237 12.0432 14.3237C11.8197 14.3237 11.6358 14.1539 11.6137 13.9362L11.6115 13.8921V10.0935C11.6115 9.85513 11.8048 9.66187 12.0432 9.66187ZM9.79856 9.66187C10.0221 9.66187 10.2059 9.83173 10.228 10.0494L10.2302 10.0935V13.8921C10.2302 14.1305 10.037 14.3237 9.79856 14.3237C9.57506 14.3237 9.39124 14.1539 9.36914 13.9362L9.36691 13.8921V10.0935C9.36691 9.85513 9.56016 9.66187 9.79856 9.66187ZM10.9209 5.86331C10.3224 5.86331 9.83335 6.33173 9.80034 6.92193L9.79856 6.98561H12.0432C12.0432 6.36578 11.5407 5.86331 10.9209 5.86331Z"
        fill="#212121"
      />
    </svg>
  );
};

export default DeleteIcon;