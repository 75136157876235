import React from 'react';
import newMsgIcon from '../../static/notification.svg';
import NoAvatar from '../noAvatar/NoAvatar';
import ChatGroupAvatar from '../svgs/ChatGroupAvatar';

const PublicChats = ({
  pubDiscussion,
  publicChats,
  selectChatGroup,
  updateReadReceipts,
  setIsScrolledUp,
  chatObj,
  truncateString,
  windowSize,
  userId
}) => {
  return (
    <>
      {pubDiscussion && publicChats &&
        <div className='pub_discussion_container'>
          {publicChats?.map(pubChat => {
            // gets the refs for every group member
            const groupMemRef = pubChat?.allChat?.members?.map(mem => {
              return mem?.path;
            });

            // gets the next user data if the group members are less than 3, 
            // ideally, group members should be 2 except for multi-members group chats
            let nextUserInfo = {};
            if (pubChat?.allChat?.members?.length === 2) {
              pubChat?.otherUserData?.map(data => {
                if (groupMemRef.includes(data?.nextUserRef?.path)) {
                  nextUserInfo = data;
                }
              })
            };

            return (
              <React.Fragment key={pubChat?.allChat?.ref?.id}>
                <div
                  className='disc_user_container'
                  style={ chatObj?.ref?.id === pubChat?.allChat?.ref?.id ?
                    {borderLeft: "0.3rem solid black", backgroundColor: "#FFFFFF"}
                    :
                    {cursor :'pointer'}
                  }
                >
                  <div className="disc_user_card">
                    <div
                      className='disc_user_data'
                      onClick={() => {
                        selectChatGroup(pubChat?.allChat);
                        updateReadReceipts(pubChat?.allChat);
                        setIsScrolledUp(false);
                      }}
                    >
                      <>
                        {pubChat?.allChat?.members?.length === 2 ?
                          <>
                            {groupMemRef.includes(nextUserInfo?.nextUserRef?.path) &&
                              <>
                                {nextUserInfo?.avatar ?
                                  <div
                                    className={`disc_user_avatar 
                                      ${chatObj?.ref?.id === pubChat?.allChat?.ref?.id ? 
                                      'active_disc_user_avatar' : 'inactive_disc_user_avatar'}`
                                    }
                                  >
                                    <img src={nextUserInfo?.avatar} className="disc_avatar_image" alt={nextUserInfo?.first} />
                                  </div>
                                  :
                                  <div
                                    className={`disc_user_avatar 
                                      ${chatObj?.ref?.id === pubChat?.allChat?.ref?.id ? 
                                      'active_disc_user_avatar' : 'inactive_disc_user_avatar'}`
                                    }
                                  >
                                    <NoAvatar text={nextUserInfo?.first} size={4.3} radius={50} />
                                  </div>
                                }
                              </>
                            }
                          </>
                          :
                          pubChat?.allChat?.isRequirement ?
                            <div
                              className={`disc_user_avatar 
                                ${chatObj?.ref?.id === pubChat?.allChat?.ref?.id ? 
                                'active_disc_user_avatar' : 'inactive_disc_user_avatar'}`
                              }
                            >
                              <NoAvatar text="r" size={4.3} radius={50} />
                            </div>
                          :
                          <div
                            className={`disc_user_avatar 
                              ${chatObj?.ref?.id === pubChat?.allChat?.ref?.id ? 
                              'active_disc_user_avatar' : 'inactive_disc_user_avatar'}`
                            }
                          >
                            <ChatGroupAvatar
                              width="4.3rem"
                              height="4.3rem"
                            />
                          </div>
                        }
                      </>
                      <div className="disc-info-section">
                        <div className='groupName_container'>
                          <p style={{paddingLeft:"0px"}}>
                            {pubChat?.allChat?.members?.length === 2 ?
                              <>
                                {groupMemRef.includes(nextUserInfo?.nextUserRef?.path) &&
                                  <span>{nextUserInfo?.first}</span>
                                }
                              </>
                              :
                              <>
                                {windowSize <= 1040 ?
                                  truncateString(pubChat?.allChat?.groupName, 40)
                                  :
                                  truncateString(pubChat?.allChat?.groupName, 30)
                                }
                              </>
                            }
                            {(pubChat?.allChat?.msgReceived?.received === false && pubChat?.allChat?.author?.author?.id !== userId) &&
                              <img
                                src={newMsgIcon}
                                alt='new msg'
                                className='new_msg_icon'
                              />
                            }
                          </p>
                        </div>
                        {' '}
                        <p className='last_msg'>
                          {truncateString(pubChat?.allChat?.lastMessage?.lastMessage, 100)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          })}
        </div>
      }
    </>
  );
};

export default PublicChats;
