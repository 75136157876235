import React, { useState, useEffect, useContext, useRef } from "react";
import ChatGroup from "../../components/chatGroup/ChatGroup";
import GroupMemberSection from "../../components/groupMemberSection/GroupMemberSection";
import MessageSection from "../../components/messageSection/MessageSection";
import PreviousIcon from "../../components/svgs/PreviousIcon";
import { updateFavicon } from "../../components/updateFavicon/updateFavicon";
import OratioContext from "../../oratioContext/OratioContext";
import notification from "../../static/spryte-notification-v1.mp3";
import AdminUserQuestions from "../../components/nestria/Admin/AdminUserQuestions";

function Discussion({
  discussionVisible,
  db,
  currentUser,
  firebase,
  truncateString,
  chatObj,
  setChatObj,
  windowSize,
  currentUserRole,
  userRole,
  currentUserRole2,
  userRole2,
  isNestria,
}) {
  const [chats, setChats] = useState([]);
  const [usersSearchTerm, setUsersSearchTerm] = useState("");
  const [chatsData, setChatsData] = useState([]);
  const [currentMembers, setCurrentMembers] = useState([]);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [groupName, setGroupName] = useState("");
  const [showGroupName, setShowGroupName] = useState(false);
  const [currentGroupName, setCurrentGroupName] = useState(null);
  const [showAddUser, setShowAddUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [mouseHover, setMouseHover] = useState(false);
  const [file, setFile] = useState(null); // updates file upload when the image path is copy/pasted on the message input field
  const [files, setFiles] = useState([]); // update message files downloaded from storage using the file attachment icon
  const [isAttachmentIcon, setIsAttachmentIcon] = useState(false);
  const [filePreview, setFilePreview] = useState([]);
  const [allChats, setAllChats] = useState();
  const [myDiscussion, setMyDiscussion] = useState(true);
  const [allDiscussion, setAllDiscussion] = useState(false);
  const [pubDiscussion, setPubDiscussion] = useState(false);
  const [chatMembersRef, setChatMembersRef] = useState();
  const [addUserBtn, setAddUserBtn] = useState(false);
  const [nextUserData, setNextUserData] = useState([]);
  const [nextUserRef, setNextUserRef] = useState(null);
  const [cursor, setCursor] = useState(null);
  const [isScrolledUp, setIsScrolledUp] = useState(false);
  const [scrollCount, setScrollCount] = useState(0);
  const [unreadMsgs, setUnreadMsgs] = useState([]);
  const [newGroupModal, setNewGroupModal] = useState(false);
  const [isCreateGroup, setIsCreateGroup] = useState(false);
  const [isAddChannel, setIsAddChannel] = useState(false);
  const [isEditChatGroup, setIsEditChatGroup] = useState(false);
  const [isNewMsg, setIsNewMsg] = useState(false);

  const msgReceivedRef = useRef();

  // current user ID
  const userId = currentUser?.uid;
  // const userId = 'na1gsFN400NhwkC8pM4R1oWy1D33'; // Admin
  // const userId = '2DlfveaxtJdO6KcSAxA8oPWIyqg2';

  // checks if user is an admin
  const isAdmin =
    // currentUserRole?.role?.path === `${userRole?.path}/ADMIN` ||
    currentUserRole?.role?.path === `${userRole?.path}/SUPER-ADMIN` ||
    currentUserRole?.role?.path === `${userRole?.path}/PARTNER-ADMIN` ||
    currentUserRole?.role?.path === `${userRole?.path}/SALES-ADMIN`;

  const isAdmin2 =
    // currentUserRole2?.role?.path === `${userRole2?.path}/ADMIN` ||
    currentUserRole2?.role?.path === `${userRole2?.path}/SUPER-ADMIN` ||
    currentUserRole2?.role?.path === `${userRole2?.path}/PARTNER-ADMIN` ||
    currentUserRole2?.role?.path === `${userRole2?.path}/SALES-ADMIN`;

  // destructures prop/value from the OratioContext component
  const {
    connectionObj,
    setDiscussionCount,
    searchTerm,
    setSearchTerm,
    showGroupChatSection,
    setShowGroupChatSection,
    showMessageSection,
    setShowMessageSection,
  } = useContext(OratioContext);

  // filter users using first, last, and email params
  const filterChatsAndUsers = async (chatsSearchVal, usersSearchVal) => {
    // converted chats' search values
    const lowerCaseChatsSearchVal = chatsSearchVal?.toLowerCase(); // converts all search values to lower case
    const initialCapChatsSearchVal =
      lowerCaseChatsSearchVal?.charAt(0)?.toUpperCase() +
      lowerCaseChatsSearchVal?.slice(1); // converts first letter to upper case
    const upperCaseChatsSearchVal = chatsSearchVal?.toUpperCase(); // converts all search values to upper case

    // converted users' search values
    const lowerCaseUsersSearchVal = usersSearchVal?.toLowerCase(); // converts all search values to lower case
    const initialCapUsersSearchVal =
      lowerCaseUsersSearchVal?.charAt(0)?.toUpperCase() +
      lowerCaseUsersSearchVal?.slice(1); // converts first letter to upper case
    const upperCaseUsersSearchVal = usersSearchVal?.toUpperCase(); // converts all search values to upper case

    // an array of converted search values which ignores cases irrespective of search inputs
    let convertedChatsSearchVal = [
      initialCapChatsSearchVal,
      lowerCaseChatsSearchVal,
      upperCaseChatsSearchVal,
    ];
    let convertedUsersSearchVal = [
      initialCapUsersSearchVal,
      lowerCaseUsersSearchVal,
      upperCaseUsersSearchVal,
    ];

    // queries the db and gets the docs/details of the search parameters e.g groupName, searchIndex
    const chatGroupName = await db
      .collection("chats")
      .where("groupName", "in", convertedChatsSearchVal)
      .limit(10)
      .get();
    const searchIndex = await db
      .collection("users")
      .where("searchIndex", "array-contains-any", convertedUsersSearchVal)
      .limit(10)
      .get();

    // looping through the docs snapshots to obtain the chats' data
    const chatsData = [];
    chatGroupName?.forEach((data) => {
      const groupNameData = data?.data();
      const groupNameRef = data?.ref;
      const groupNameObj = { ...groupNameData, ref: groupNameRef };
      chatsData.push(groupNameObj);
    });

    const usersData = [];
    searchIndex?.forEach((data) => {
      const searchIndexData = data?.data();
      const searchIndexRef = data?.ref;
      const searchIndexObj = { ...searchIndexData, ref: searchIndexRef };
      usersData.push(searchIndexObj);
    });

    // update chatsData and users states
    setChatsData(chatsData);
    setUsers(usersData);
  };

  // searches for users when adding a new member to a group chat
  useEffect(() => {
    filterChatsAndUsers(searchTerm, usersSearchTerm);
  }, [searchTerm, usersSearchTerm]);

  const imagePaste = (pasteEvent) => {
    // consider the first item (can be easily extended for multiple items)
    const item = pasteEvent.clipboardData.items[0];

    if (item.type.indexOf("image") === 0) {
      const blob = item.getAsFile();

      const reader = new FileReader();
      reader.onload = function (event) {
        // document.getElementById("container").src = event.target.result;

        const result = event.target.result;
        setFile(result);
      };

      reader.readAsDataURL(blob);
    }
  };

  // const handleChange = e => {
  //   setSearchTerm(e.target.value);
  // };

  // handle users search input change
  const handleSearchUsers = (e) => {
    setUsersSearchTerm(e.target.value);
    setShowDropdown(false);
  };

  // plays the notification sound when a message is sent (chime on every new chat)
  const start = () => {
    const audio = new Audio(notification);
    audio.play();
  };

  const addMessage = async () => {
    const documentRef = chatObj?.ref?.path;
    const authorRef = db.doc(`/users/${userId}`);
    const message = {
      author: authorRef,
      ts: new Date(),
      msg: newMessage,
      file: isAttachmentIcon ? { files } : file, // assign files array as an object to the file field if isAttachmentIcon true
      received: false,
    };

    await db.collection(`${documentRef}/msg/`).add(message);

    setNewMessage("");
    setFile(null);
    setFiles([]);
    setIsAttachmentIcon(false);
    setFilePreview([]);
    setIsNewMsg(true);
  };

  // sends a message when the Enter key is clicked
  const handleKeypress = (e) => {
    // adds a msg if on "My Discussions" window & the enter key is pressed
    if ((e.charCode === 13 || e.key === "Enter") && myDiscussion) {
      addMessage();
      // adds a msg if on "All Discussions" window, curr user is a member of the group chat, curr user is an Admin & the enter key is pressed
    } else if (
      (e.charCode === 13 || e.key === "Enter") &&
      allDiscussion &&
      chatMembersRef?.indexOf(`users/${userId}`) > -1 &&
      (isAdmin || isAdmin2)
    ) {
      addMessage();
    } else {
      return;
    }
  };

  // gets all members within a selected chat group
  const getCurrMembers = async () => {
    if (chatObj?.members?.length > 0) {
      let membersData = [];
      chatObj.ref.onSnapshot((doc) => {
        const chatData = doc.data();
        const chatMembers = chatData?.members || []; // fallback to empty array if members field is not defined
        const existingMembers = membersData?.map((m) => m.id); // get ids of existing members

        // Add new members to membersData
        const newMembers = chatMembers?.filter(
          (m) => !existingMembers.includes(m.id)
        );
        newMembers?.forEach((m) => {
          const memberRef = db.doc(`users/${m.id}`);
          memberRef.get().then((doc) => {
            const userData = doc?.data();
            // const memberData = {...userData, ref: memberRef};
            const memberData = {
              ...userData,
              id: memberRef.id,
              ref: memberRef,
            };
            membersData.push(memberData);
            setCurrentMembers([...membersData]);
          });
        });

        // Remove members from membersData
        const removedMembers = membersData.filter(
          (m) => !chatMembers.find((cm) => cm.id === m.id)
        );
        removedMembers.forEach((m) => {
          membersData = membersData.filter((md) => md.id !== m.id);
          setCurrentMembers([...membersData]);
        });
      });
    }
  };

  // gets all members within a selected chat group
  useEffect(() => {
    if (!chatObj) return;
    getCurrMembers();
  }, [chatObj?.ref?.path]);

  // it's fired when the message vertical scrollbar is scrolled
  const scrolledUp = () => {
    if (msgReceivedRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = msgReceivedRef.current;
      if (scrollTop + clientHeight === scrollHeight && clientHeight !== 0) {
        setIsScrolledUp(true);
        // keeps track of the number of times the user has scrolled the scrollbar
        // just so the messages would be pulled in batches of 10 (which is the limit)
        setScrollCount((prevScrollCount) => prevScrollCount + 1);
      }
    }
  };

  // gets all messages in batches of the specified limit within a selected chat group
  useEffect(() => {
    if (!chatObj) return;
    const documentRef = chatObj?.ref?.path;
    let unsubscribe;
    if (isScrolledUp) {
      if (cursor) {
        const messagesRef = db
          .collection(`${documentRef}/msg`)
          .orderBy("ts", "desc")
          .startAfter(cursor)
          .limit(50);
        const chatRef = chatObj?.ref;

        const playOnce = false;
        unsubscribe = messagesRef?.onSnapshot(async (snapshot) => {
          let authorRef = {};
          let data = await Promise.all(
            snapshot?.docs?.map(async (doc) => {
              const msgRef = doc?.ref;
              const msgInfo = await msgRef?.get();
              const msgData = msgInfo?.data();
              authorRef = msgData?.author;
              const authorInfo = await authorRef?.get();
              const authorData = authorInfo?.data();
              const userFirst = authorData?.first;
              const userLast = authorData?.last;
              const userAvatar = authorData?.avatar;
              const userEmail = authorData?.email;
              const authorObj = {
                last: userFirst,
                first: userLast,
                avatar: userAvatar,
                authorRef: authorRef,
                email: userEmail,
              };
              return {
                ...msgData,
                ...authorObj,
                ref: msgRef,
                chatRef: chatRef,
              };
            })
          );

          // updates the cursor variable with last item in every batch
          // just so the next batch would be pulled starting from the latest item in the cursor var (i.e the last item in the last batch)
          setCursor(snapshot.docs[snapshot.docs.length - 1]);

          // chime when a new msg doc is added
          if (!playOnce) {
            start();
          }
          setCurrentMessages((prevData) => [...prevData, ...data]);
        });
      }
    } else {
      const messagesRef = db
        .collection(`${documentRef}/msg`)
        .orderBy("ts", "desc")
        .limit(50);
      const chatRef = chatObj?.ref;

      const playOnce = false;
      unsubscribe = messagesRef?.onSnapshot(async (snapshot) => {
        let authorRef = {};
        let data = await Promise.all(
          snapshot?.docs?.map(async (doc) => {
            const msgRef = doc?.ref;
            const msgInfo = await msgRef?.get();
            const msgData = msgInfo?.data();
            authorRef = msgData?.author;
            const authorInfo = await authorRef?.get();
            const authorData = authorInfo?.data();
            const userFirst = authorData?.first;
            const userLast = authorData?.last;
            const userAvatar = authorData?.avatar;
            const userEmail = authorData?.email;
            const authorObj = {
              last: userFirst,
              first: userLast,
              avatar: userAvatar,
              authorRef: authorRef,
              email: userEmail,
            };
            return { ...msgData, ...authorObj, ref: msgRef, chatRef: chatRef };
          })
        );
        // updates the cursor variable with last item in every batch
        // just so the next batch would be pulled starting from the latest item in the cursor var (i.e the last item in the last batch)
        setCursor(snapshot.docs[snapshot.docs.length - 1]);

        // changes read receipts (check marks) to green on message read
        if (chatObj !== null) {
          const msgReceivedData = {
            received: true,
          };
          if (chatObj?.author?.author?.id !== userId) {
            // updates read receipts when the next user has new msg(s)
            // and the next user is currently on the sender's chat group
            snapshot?.docChanges().forEach(async (change) => {
              if (change?.type === "added") {
                const newMsgDoc = await change?.doc?.ref?.get();
                const newMsgData = newMsgDoc?.data();
                const newMsgRef = newMsgDoc?.ref;
                if (
                  newMsgData?.author?.id !== userId &&
                  newMsgData?.received === false
                ) {
                  await newMsgRef?.set(msgReceivedData, { merge: true });
                }
              }
            });
          }
        }

        // chime when a new msg doc is added
        if (!playOnce) {
          start();
        }
        setCurrentMessages(data);
        // setChatGroupData(chatGroupData);
      });
    }
    return unsubscribe;
  }, [chatObj?.ref?.path, chatObj, isScrolledUp, scrollCount]);

  // streaming chats, group members, next user data, and messages/last messages
  useEffect(() => {
    const getCurrUsersChats = () => {
      const userRef = db.doc(`/users/${userId}`);
      let chatsRef = db
        .collection("chats")
        .where("members", "array-contains", userRef);
      const chatsRes = chatsRef.onSnapshot(async (snapshot) => {
        let chatsList = [];
        let orderedChatsList = [];
        let allUnreadMessages = new Map();
        snapshot.forEach((doc) => {
          const docRef = { ref: doc?.ref };
          const chatData = doc?.data();
          const messagesRef = db
            .collection(`${doc?.ref?.path}/msg`)
            .orderBy("ts", "desc")
            .limit(50);

          let chatsData = {};
          const msgRef = {};
          const lastMessage = {};
          const author = {};
          const msgReceived = {};
          const msgsRes = messagesRef?.onSnapshot((snapshot) => {
            // last msg document
            const lastMsgDoc = snapshot?.docs[0];
            const msgData = lastMsgDoc?.data();

            msgRef["msgRef"] = lastMsgDoc?.ref;
            // sets last message to email subject if the last chat is an email
            // then last message becomes the last msg if the last chat isn't an email
            if (msgData?.message) {
              lastMessage["lastMessage"] = msgData?.message?.subject;
            } else {
              lastMessage["lastMessage"] = msgData?.msg;
            }
            // grabs the received key for all msgs
            msgReceived["received"] = msgData?.received;
            // saves the author of a specific message
            author["author"] = msgData?.author;

            // loops through each document snapshot and
            // retrieves every message not sent by the current user whose received key is false
            snapshot.docs.forEach((doc) => {
              const msgData = doc?.data();
              const msgId = doc?.ref?.id;
              const chatGroupRef = doc?.ref?.parent?.parent;

              const authorId = msgData.author.id;
              if (!msgData?.received && authorId !== userId) {
                const msg = {
                  author: authorId,
                  msg: msgData.msg,
                  file: msgData.file,
                  received: msgData.received,
                  ts: msgData.ts,
                  msgId: msgId,
                  chatGroupRef: chatGroupRef,
                };
                // retrieves an existing list of unread messages for the current chat
                // member (specified by authorId), or creates an empty array if no msg exist yet.
                const existingUnreadMsgs =
                  allUnreadMessages.get(authorId) || [];
                // adds the new msg to the end of existingUnreadMsgs array and updates the allUnreadMessages map with the new array.
                allUnreadMessages.set(authorId, [...existingUnreadMsgs, msg]);
              }
            });
            // extracts Map vals and flattens the array into a single array and stores the vals in unreadMsgs
            let unreadMessages = Array.from(allUnreadMessages.values()).flat();

            // removes dupliactes message data obj (msgDataObj) in unreadMessages variable

            unreadMessages = unreadMessages.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.msgId === value.msgId)
            );

            snapshot.docChanges().forEach(async (msgChange) => {
              if (msgChange.type === "modified") {
                const modifiedMsgRef = msgChange?.doc?.ref;
                const authorId = msgChange?.doc?.data()?.author?.id;

                // remove all read messages from a specific author from the unreadMessages array
                unreadMessages = unreadMessages?.filter((msg) => {
                  return msg?.msgId !== modifiedMsgRef?.id;
                });

                // remove the read message from the corresponding array in the allUnreadMessages map
                const existingUnreadMsgs =
                  allUnreadMessages.get(authorId) || [];
                const updatedUnreadMsgs = existingUnreadMsgs.filter((msg) => {
                  return msg?.msgId !== modifiedMsgRef?.id;
                });
                // updates allUnreadMessages map with the updated (filtered) unread msgs
                allUnreadMessages.set(authorId, updatedUnreadMsgs);

                // update the state with the updated array
                setUnreadMsgs(unreadMessages);
              } else if (msgChange.type === "removed") {
                const deletedMsgRef = msgChange?.doc?.ref;
                unreadMessages = unreadMessages?.filter((msg) => {
                  return msg?.msgId !== deletedMsgRef?.id;
                });

                // remove the deleted message from the corresponding array in the allUnreadMessages map
                const authorId = msgChange?.doc?.data()?.author?.id;
                const existingUnreadMsgs =
                  allUnreadMessages.get(authorId) || [];
                const updatedUnreadMsgs = existingUnreadMsgs.filter((msg) => {
                  return msg?.msgId !== deletedMsgRef?.id;
                });
                // updates allUnreadMessages map with the updated (filtered) unread msgs
                allUnreadMessages.set(authorId, updatedUnreadMsgs);

                setUnreadMsgs(unreadMessages);
              }
            });

            setUnreadMsgs(unreadMessages);
            // chats data, last msg and unread msgs inclusive
            chatsData["chatsData"] = {
              ...chatData,
              ...docRef,
              msgRef,
              lastMessage,
              author,
              msgReceived,
            };

            // listens to changes | re-orders the chat groups in desc order when a message doc is created
            snapshot?.docChanges().forEach(async (msgChange) => {
              if (msgChange.type === "added") {
                const addedChatDoc = await db
                  .doc(msgChange?.doc?.ref?.parent?.parent?.path)
                  .get();
                // chat group of the newly added msg, last msg and unread msgs inclusive
                const addedChatData = {};
                addedChatData["chatsData"] = {
                  ...addedChatDoc?.data(),
                  ...docRef,
                  msgRef,
                  lastMessage,
                  msgReceived,
                  author,
                };
                // orderedChatsList = chatsList?.filter(chat => (JSON.stringify(addedChatData) !== JSON.stringify(chat)));
                orderedChatsList = chatsList?.filter(
                  (chat) =>
                    msgChange?.doc?.ref?.parent?.parent?.path !==
                    chat?.chatsData?.ref?.path
                );
                orderedChatsList.unshift(addedChatData);
                setChats(orderedChatsList);
              }
            });
          });

          chatsList.push(chatsData);
          return msgsRes;
        });

        // next user data (users other than the current user) who're on the current user's chat list
        snapshot?.docs?.forEach(async (doc) => {
          const data = doc?.data();
          await Promise.all(
            data?.members?.map(async (mem) => {
              if (mem?.id !== userId) {
                const nextUserId = mem?.id;
                const nextUserReference = db.doc(`/users/${nextUserId}`);
                const nextUserDoc = await nextUserReference?.get();
                const data = nextUserDoc?.data();
                const nextUserRef = nextUserDoc?.ref;
                const nextUserDataObj = { ...data, nextUserRef: nextUserRef };
                nextUserData.push(nextUserDataObj);
              }
            })
          );
          setNextUserData(nextUserData);
        });

        // setChats(chatsList);
        setDiscussionCount(chatsList?.length);
      });
      return chatsRes;
    };
    getCurrUsersChats();
  }, []);

  // updates unread message count on the favicon area
  updateFavicon(unreadMsgs?.length);

  // all chats
  useEffect(() => {
    // const allChatsRef = db.collection('chats').orderBy("messageTs", "desc");
    const getAllChats = async () => {
      const allChatsRef = db.collection("chats");

      const allChatsRes = allChatsRef.onSnapshot(async (snapshot) => {
        let allChatsList = [];
        let orderedallChatsList = [];
        // let allUnreadMessages = new Map();
        snapshot.forEach((doc) => {
          const chatRef = doc?.ref;
          const allChatsData = doc?.data();
          const messagesRef = db
            .collection(`${doc?.ref?.path}/msg`)
            .orderBy("ts", "desc")
            .limit(50);

          let allChatsObj = {};
          const msgRef = {};
          const lastMessage = {};
          const author = {};
          // const msgReceived = {};
          const msgsRes = messagesRef?.onSnapshot((snapshot) => {
            // last msg document
            const lastMsgDoc = snapshot?.docs[0];
            const msgData = lastMsgDoc?.data();

            msgRef["msgRef"] = lastMsgDoc?.ref;
            // sets last message to email subject if the last chat is an email
            // then last message becomes the last msg if the last chat isn't an email
            if (msgData?.message) {
              lastMessage["lastMessage"] = msgData?.message?.subject;
            } else {
              lastMessage["lastMessage"] = msgData?.msg;
            }
            // grabs the received key for all msgs
            // msgReceived["received"] = msgData?.received;

            // saves the author of a specific message
            author["author"] = msgData?.author;

            // chats data, last msg and author inclusive
            allChatsObj["allChatsObj"] = {
              ...allChatsData,
              ref: chatRef,
              msgRef,
              lastMessage,
              // msgReceived,
              author,
            };

            // listens to changes | re-orders the chat groups in desc order when a message doc is created
            let addedChatData = {};
            snapshot?.docChanges().forEach(async (msgChange) => {
              if (msgChange.type === "added") {
                const addedChatDoc = await db
                  .doc(msgChange?.doc?.ref?.parent?.parent?.path)
                  .get();
                const addedChat = addedChatDoc?.data();
                // chat group of the newly added msg, last msg and unread msgs inclusive
                addedChatData["allChatsObj"] = {
                  ...addedChat,
                  ref: chatRef,
                  msgRef,
                  lastMessage,
                  // msgReceived,
                  author,
                };
                // orderedallChatsList = allChatsList?.filter(chat => (JSON.stringify(addedChatData) !== JSON.stringify(chat)));
                orderedallChatsList = allChatsList?.filter(
                  (chat) =>
                    msgChange?.doc?.ref?.parent?.parent?.path !==
                    chat?.allChatsObj?.ref?.path
                );
                orderedallChatsList.unshift(addedChatData);
                setAllChats(orderedallChatsList);
              }
            });
          });

          allChatsList.push(allChatsObj);
          return msgsRes;
        });

        // next user data (users other than the current user) who're on the current user's chat list
        snapshot?.docs?.forEach(async (doc) => {
          const data = doc?.data();
          await Promise.all(
            data?.members?.map(async (mem) => {
              if (mem?.id !== userId) {
                const nextUserId = mem?.id;
                const nextUserReference = db.doc(`/users/${nextUserId}`);
                const nextUserDoc = await nextUserReference?.get();
                const data = nextUserDoc?.data();
                const nextUserRef = nextUserDoc?.ref;
                const nextUserDataObj = { ...data, nextUserRef: nextUserRef };
                nextUserData.push(nextUserDataObj);
              }
            })
          );
          setNextUserData(nextUserData);
        });

        setAllChats(allChatsList);
        setDiscussionCount(allChatsList?.length);
      });
      return allChatsRes;
    };
    getAllChats();
  }, []);

  // creates a group chat (if it does not exist) when the Message button is clicked
  // const createChatGroup = async () => {
  //   const currentUserRef = db.doc(`/users/${userId}`);
  //   const currUserRes = await currentUserRef.get();
  //   const currUserData = currUserRes?.data();
  //   const userRef2 = connectionObj?.ref;

  //   let chatGroupData = {
  //     groupName: (currUserData?.first && connectionObj?.first) ?
  //                 currUserData?.first + " & " + connectionObj?.first
  //                 :
  //                 (currUserData?.first && connectionObj?.crm?.data?.properties?.name?.value || connectionObj?.profile?.name || connectionObj?.name) ?
  //                 currUserData?.first + " & " + connectionObj?.crm?.data?.properties?.name?.value ?? connectionObj?.profile?.name ?? connectionObj?.name
  //                 :
  //                 'New Chat Group',
  //     members: [currentUserRef, userRef2],
  //     description: '',
  //     createdOn: new Date()
  //   };

  //   await db.collection('/chats').add(chatGroupData);
  // };

  // prefils chat group names on edit
  const handleEditGroupName = (e) => {
    setGroupName(e.target.value);
  };

  // updates chat group names when edited and saved
  const updateGroupName = async (e, ref) => {
    e.preventDefault();
    const groupNameData = {
      groupName: groupName,
      updatedOn: new Date(),
    };

    await db.doc(ref).set(groupNameData, { merge: true });

    setShowGroupName(false);
  };

  // cancels edit group name action
  const cancelUpdateGroupName = (e) => {
    e.preventDefault();
    setShowGroupName(false);
  };

  // adds new members/users to chat
  const addUsersToGroupChat = async (e, ref) => {
    e.preventDefault();

    // const chatRef = chatObj?.ref?.path ? chatObj?.ref?.path : chatObj?.ref;
    const chatRef = chatObj?.ref?.path;
    const selectedRef = db.doc(ref);

    const membersData = {
      members: firebase.firestore.FieldValue.arrayUnion(selectedRef),
      updatedOn: new Date(),
    };

    await db.doc(chatRef).set(membersData, { merge: true });
  };

  // removes a member from a group chat
  // const removeMember = async (e, ref) => {
  //   e.preventDefault();

  //   const memberRef = db?.doc(ref);
  //   // const chatObj = chats[currentChat]?.ref?.path;
  //   const chatObjRef = chatObj?.ref?.path;
  //   const membersData = {
  //      members: firebase.firestore.FieldValue.arrayRemove(memberRef),
  //      updatedOn: new Date()
  //    };
  //   await db.doc(chatObjRef).update(membersData);
  //   // addCurrentChat(currentChat);
  // };

  // adds double checkmark when message(s) is read
  const updateReadReceipts = async (item) => {
    // checks if user scrolls to the target html ele
    if (msgReceivedRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = msgReceivedRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // updates all msg docs if received === false and the author isn't the current user
        if (item?.author?.id !== userId && item?.received === false) {
          const msgReceivedData = {
            received: true,
          };

          const allMsgRef = item?.ref?.parent;
          const allMsgDoc = await allMsgRef?.get();
          allMsgDoc?.docs?.forEach(async (doc) => {
            // msg refs for updating msg docs whose received field === false
            const msgRef = doc?.ref;
            await msgRef?.set(msgReceivedData, { merge: true });
          });
        }
      }

      // updates all msg docs whose received field === false when the chat group name is
      // clicked by other members of the group other than the current user
      if (item?.members?.length > 0) {
        const chatMemberIds = item?.members?.map((mem) => mem?.id);
        const otherMembersIds = chatMemberIds?.filter((memId) => {
          const otherMemsIds = memId !== userId;
          return otherMemsIds;
        });

        // checks if any other member id other than the current user is present in the group
        const includesAny = (arr, values) =>
          values.some((v) => {
            return arr.includes(v);
          });

        // console.log('otherMembersIds', otherMembersIds, 'chatMemberIds', chatMemberIds);
        if (includesAny(chatMemberIds, otherMembersIds)) {
          const msgReceivedData = {
            received: true,
          };

          const allMsgRef = db.collection(`${item?.ref?.path}/msg`);
          const allMsgDoc = await allMsgRef?.get();
          allMsgDoc?.docs?.forEach(async (doc) => {
            // msg refs for updating msg docs whose received field === false
            const msgRef = doc?.ref;
            const msgData = doc?.data();
            if (msgData?.author?.id !== userId && msgData?.received === false) {
              await msgRef?.set(msgReceivedData, { merge: true });
            }
          });
        }
      }
    }
  };

  // opens the options modal for editing/deleting chat groups
  const optionsModal = (chat) => {
    setChatObj(chat);
    setNewGroupModal(true);
    setIsEditChatGroup(true);
    setIsCreateGroup(false);
    setIsAddChannel(false);
    setShowMessageSection(false);
  };

  return (
    <>
      {discussionVisible && (
        <div className="disc-center-div transition-6">
          <div className="disc_main">
            <div className="disc-section">
              <div
                className={
                  showGroupChatSection
                    ? "disc-left-section"
                    : "disc-left-section left_mobile"
                }
              >
                <ChatGroup
                  chats={chats}
                  chatObj={chatObj}
                  userId={userId}
                  setChatObj={setChatObj}
                  showGroupName={showGroupName}
                  currentGroupName={currentGroupName}
                  groupName={groupName}
                  handleEditGroupName={handleEditGroupName}
                  updateGroupName={updateGroupName}
                  cancelUpdateGroupName={cancelUpdateGroupName}
                  setCurrentGroupName={setCurrentGroupName}
                  setShowGroupName={setShowGroupName}
                  setGroupName={setGroupName}
                  setMouseHover={setMouseHover}
                  mouseHover={mouseHover}
                  allChats={allChats}
                  isAdmin={isAdmin}
                  isAdmin2={isAdmin2}
                  myDiscussion={myDiscussion}
                  setMyDiscussion={setMyDiscussion}
                  allDiscussion={allDiscussion}
                  setAllDiscussion={setAllDiscussion}
                  pubDiscussion={pubDiscussion}
                  setPubDiscussion={setPubDiscussion}
                  truncateString={truncateString}
                  setAddUserBtn={setAddUserBtn}
                  setShowGroupChatSection={setShowGroupChatSection}
                  setShowMessageSection={setShowMessageSection}
                  optionsModal={optionsModal}
                  msgReceivedRef={msgReceivedRef}
                  updateReadReceipts={updateReadReceipts}
                  nextUserData={nextUserData}
                  setNextUserRef={setNextUserRef}
                  unreadMsgs={unreadMsgs}
                  setIsScrolledUp={setIsScrolledUp}
                  db={db}
                  firebase={firebase}
                  newGroupModal={newGroupModal}
                  setNewGroupModal={setNewGroupModal}
                  isCreateGroup={isCreateGroup}
                  setIsCreateGroup={setIsCreateGroup}
                  isAddChannel={isAddChannel}
                  setIsAddChannel={setIsAddChannel}
                  isEditChatGroup={isEditChatGroup}
                  setIsEditChatGroup={setIsEditChatGroup}
                  windowSize={windowSize}
                  isNestria={isNestria}
                />
              </div>
              <div
                className={
                  showMessageSection
                    ? "disc-right-section"
                    : "disc-right-section right_mobile"
                }
              >
                <div
                  className="previous_icon"
                  onClick={() => {
                    setShowGroupChatSection(true);
                    setShowMessageSection(false);
                    setNextUserRef(null);
                  }}
                >
                  <PreviousIcon width={15} height={15} />
                  <p>Back to all discussions</p>
                </div>

                {isNestria ? (
                  <>
                    {" "}
                    <AdminUserQuestions />
                  </>
                ) : (
                  <>
                    <GroupMemberSection
                      currentMembers={currentMembers}
                      isAdmin={isAdmin}
                      isAdmin2={isAdmin2}
                      truncateString={truncateString}
                      showAddUser={showAddUser}
                      setShowAddUser={setShowAddUser}
                      handleSearchUsers={handleSearchUsers}
                      users={users}
                      showDropdown={showDropdown}
                      setShowDropdown={setShowDropdown}
                      addUsersToGroupChat={addUsersToGroupChat}
                      usersSearchTerm={usersSearchTerm}
                      addUserBtn={addUserBtn}
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      db={db}
                      userId={userId}
                      setNewGroupModal={setNewGroupModal}
                      setIsCreateGroup={setIsCreateGroup}
                      setIsAddChannel={setIsAddChannel}
                      setIsEditChatGroup={setIsEditChatGroup}
                      chatObj={chatObj}
                      setShowMessageSection={setShowMessageSection}
                      setShowGroupChatSection={setShowGroupChatSection}
                      optionsModal={optionsModal}
                    />
                    <MessageSection
                      currentMessages={currentMessages}
                      newMessage={newMessage}
                      setNewMessage={setNewMessage}
                      imagePaste={imagePaste}
                      handleKeypress={handleKeypress}
                      addMessage={addMessage}
                      files={files}
                      setFiles={setFiles}
                      isAttachmentIcon={isAttachmentIcon}
                      setIsAttachmentIcon={setIsAttachmentIcon}
                      filePreview={filePreview}
                      setFilePreview={setFilePreview}
                      firebase={firebase}
                      db={db}
                      chatObj={chatObj}
                      userId={userId}
                      isAdmin={isAdmin}
                      isAdmin2={isAdmin2}
                      myDiscussion={myDiscussion}
                      pubDiscussion={pubDiscussion}
                      allDiscussion={allDiscussion}
                      chatMembersRef={chatMembersRef}
                      setChatMembersRef={setChatMembersRef}
                      truncateString={truncateString}
                      showMessageSection={showMessageSection}
                      msgReceivedRef={msgReceivedRef}
                      updateReadReceipts={updateReadReceipts}
                      nextUserData={nextUserData}
                      nextUserRef={nextUserRef}
                      currentMembers={currentMembers}
                      scrolledUp={scrolledUp}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Discussion;
