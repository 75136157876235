import React, { useContext, useState } from "react";
import { AddCourseSvg, FinalMode, PracticeMode } from "../helpers/Svgs";
import SubjectSelector from "./SubjectSelector";
import { AssessmentModeContext } from "../Context/Nestria";

const CourseSwitcher = ({setMenu, assignedCourseSubjects, courseIcons, allNestriaSubjects }) => {
    const [addUnitCalled, setAddUnitCalled] = useState(false);
    const { mode, setMode, activeSubject, setActiveSubject } = useContext(AssessmentModeContext);

      const toggleMode = () => {
        setMode(mode === "Practice" ? "Final" : "Practice");
      };

      const handleSubjectClick = (subjectName) => {
        setActiveSubject(subjectName);
        setMenu("HIDDEN");
      };

      const onAddUnit = () => {
        setAddUnitCalled(true);
      };

    const orderedAssignedCourseSubjects = activeSubject
    ? [activeSubject, ...assignedCourseSubjects.filter((subject) => subject !== activeSubject)]
    : assignedCourseSubjects;

    return (
        <div className="flex flex-col justify-between gap-2">
          <div
            className="flex items-center justify-between"
            style={{ padding: "0px 25px" }}
          >
            <span
              className="font-bold text-gray-600"
              style={{ fontSize: "25px" }}
            >
              {mode}
            </span>
            <button onClick={toggleMode}>
              {mode === "Practice" ? <PracticeMode /> : <FinalMode />}
            </button>
          </div>
          <hr />
          <div
            className="flex gap-5 overflow-x-auto p-4"
            style={{
              overflow: "auto",
              width: "100vw",
              scrollbarWidth: "none",
              textAlign: "center",
            }}
          >
            {courseIcons && orderedAssignedCourseSubjects.map((subject) => (
              <div
                key={subject}
                className="rounded-2xl"
                style={{ marginTop: ".8rem" }}
              >
                <button
                  onClick={() => handleSubjectClick(subject)}
                  className="bg-white"
                  style={{
                    borderRadius: "18%",
                    borderWidth: "5.5px",
                    width: "95px",
                    height: "72px",
                    borderStyle: "solid",
                    borderColor:
                      activeSubject === subject
                        ? "orange"
                        : "#AFAFAF",
                    display: "inline-block",
                    marginBottom: "8px",
                  }}
                >
                  <img
                    src={courseIcons[subject]}
                    alt={subject}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "14%",
                    }}
                  />
                </button>
                <div className="font-bold text-gray-400">
                  {subject}
                </div>
              </div>
            ))}
            <div
              className="flex flex-col items-center rounded-2xl border-4 border-white"
              style={{ flex: "0 0 auto" }}
            >
              <button onClick={() => onAddUnit()}>
                <AddCourseSvg />
              </button>
              <span className="font-bold text-gray-400">
                Units
              </span>
            </div>
          </div>

          {addUnitCalled &&
          <div>
            <div
              className="flex max-w-2xl grow flex-col"
              style={{ maxHeight: "100vh", zIndex: "1000", position: "fixed", top: "0px"}}
            >
              <SubjectSelector
              subjectIcons={courseIcons}
              onClose={setAddUnitCalled}
              assignedUserCourses = {orderedAssignedCourseSubjects}
              setMenu = {setMenu}
              allNestriaSubjects = {allNestriaSubjects}
              />
            </div>
          </div>}
        </div>
      );
}

export default CourseSwitcher;