import React, { useEffect, forwardRef } from "react";

const TextToSpeechPlayer = forwardRef(({ audioContentBase64, audioRef, url }) => {

    useEffect(() => {
        audioRef.current.pause();
        audioRef.current.volume = 0.3;

        if (audioContentBase64) {
            audioRef.current.src = "data:audio/wav;base64," + audioContentBase64;
        }

        if (url) {
            audioRef.current.src = url;
        }
    }, [audioContentBase64, url]);

    return (
        <div>
            <audio ref={audioRef} />
        </div>
    );
});

export default TextToSpeechPlayer;