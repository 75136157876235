import React, { useEffect, useState } from "react";
import ClockOutFormSliderModal from "./ClockOutFormSliderModal";

const ClockOutModal = ({
  isShowClockOutModal,
  docRef,
  clockOutTime,
  setClockOutResolve,
  setIsShowClockOutModal,
  timeSheetData,
  setTimeSheetData,
  firebase,
  devsReportingReference,
  timeWorkedInSeconds,
  misssingClockOutData,
  setIsAccountNotGood,
  hideCancel,
  setHideCancel,
  setShowClockDates,
  setShowTimePicker,
}) => {
  const [lastIndexType, setLastIndexType] = useState(null);
  const [lastIndexTime, setLastIndexTime] = useState(null);
  const [activeSprints, SetActiveScprints] = useState(null);
  const [featuresData, setFeaturesData] = useState(null);
  const [sprintFeatureMap, setSprintFeatureMap] = useState(null);
  const [clientsSprintsMap, setClientsSprintsMap] = useState(null);
  const [totalTimeWorked, setTotalTimeWorked] = useState("");
  const [accomplished, setAccomplished] = useState(null);
  const [comments, setComments] = useState(null);
  const [feeling, setFeeling] = useState(null);
  const [reasonForNo, setReasonForNo] = useState(null);
  const [isToday, setIsToday] = useState(false);
  useEffect(() => {
    let _currentday = new Date();

    clockOutTime?.getDate() === _currentday?.getDate() &&
    clockOutTime?.getMonth() === _currentday?.getMonth() &&
    clockOutTime?.getFullYear() === _currentday?.getFullYear()
      ? setIsToday(true)
      : setIsToday(false);
  }, [clockOutTime]);

  useEffect(() => {
    const setActiveSprints = async () => {
      let activeSprintsSnapshot = await devsReportingReference?.get();
      let sprints = activeSprintsSnapshot?.data()?.sprints;
      let activeSprints = [];
      let sprintFeaturesMap = {};
      let checkInDate = new Date();
      let sprintProjectFeautures = [];

      let timeworkedObj = new Date(timeWorkedInSeconds);
      let seconds = timeworkedObj?.getUTCSeconds();
      let minutes = timeworkedObj?.getUTCMinutes();
      let hours = timeworkedObj?.getUTCHours();
      setTotalTimeWorked(
        hours?.toString()?.padStart(2, "0") +
          " hr " +
          minutes?.toString()?.padStart(2, "0") +
          " min " +
          "worked"
      );

      await Promise.all(
        sprints?.map(async (data, i) => {
          let sprintDoc = await data?.get();
          let sprintStartDate = sprintDoc?.data()?.start;
          let sprintEndDate = sprintDoc?.data()?.end;

          if (
            checkInDate > sprintStartDate?.toDate() &&
            sprintEndDate?.toDate() > checkInDate
          ) {
            activeSprints.push(data);
          }
        })
      );
      await Promise.all(
        activeSprints?.map(async (data, i) => {
          let sprintRef = await data?.get();
          let clientRef = await data?.parent?.parent?.get();
          let projectRef = sprintRef?.data()?.projectRef;
          let featuresData = [];
          let sprintProjectInformation = await projectRef?.get();
          let projectName = sprintProjectInformation?.data()?.name;
          if (
            sprintProjectInformation?.data()?.projectInformation !== undefined
          ) {
            featuresData =
              sprintProjectInformation?.data()?.projectInformation?.features;
          }
          sprintFeaturesMap[sprintRef?.ref?.path] = {
            feature: featuresData,
            timeInHrMin: " ",
            sprintErrorMessage: "",
            sprintAllocation: 0,
            sprintPercentage: 0,
            sprintData: sprintRef,
            clientData: clientRef,
            sprintProjectName: projectName,
          };
        })
      );

      timeSheetData?.data()?.allocation?.map((data, i) => {
        let sprintreference = data?.sprintRef?.path;
        sprintFeaturesMap[sprintreference].sprintPercentage =
          data?.allocationPerc;
        sprintFeaturesMap[sprintreference].sprintAllocation =
          data?.allocationSecs * 1000;

        let timeworkedObj = new Date(data?.allocationSecs * 1000);
        let seconds = timeworkedObj?.getUTCSeconds();
        let minutes = timeworkedObj?.getUTCMinutes();
        let hours = timeworkedObj?.getUTCHours();
        sprintFeaturesMap[sprintreference].timeInHrMin =
          data?.allocationPerc +
          "%: " +
          hours?.toString()?.padStart(2, "0") +
          " hr " +
          minutes?.toString()?.padStart(2, "0") +
          " min ";

        data?.features?.map((item, j) => {
          sprintFeaturesMap[sprintreference].feature[j].featurePercentage =
            item?.allocationPerc;
          sprintFeaturesMap[sprintreference].feature[j].featureAllocation =
            item?.allocationSecs * 1000;
        });
      });
      setSprintFeatureMap(sprintFeaturesMap);
    };
    setActiveSprints();
  }, []);

  useEffect(() => {
    let _accomplished = timeSheetData?.checkoutReport?.accomplished;
    let _comments = timeSheetData?.checkoutReport?.comments;
    let _feeling = timeSheetData?.checkoutReport?.feeling;
    let _reasonForNo = timeSheetData?.checkoutReport?.reasonForNo;

    setAccomplished(_accomplished);
    setComments(_comments);
    setFeeling(_feeling);
    setReasonForNo(_reasonForNo);

    timeSheetData?.presenceData?.map((data, i) => {
      if (i === timeSheetData?.presenceData?.length - 1) {
        let timedifference = 0;
        timedifference = clockOutTime - lastIndexTime;
        setLastIndexType(data?.type);
        setLastIndexTime(data?.dateTime?.toDate());
      }
    });
  }, [timeSheetData]);

  const [inputValues, setInputValues] = useState({
    button1: accomplished,
    button2: feeling,
    sprintNote: comments,
    noTextBox: reasonForNo,
  });

  const [errors, setErrors] = useState({
    button1: "",
    button2: "",
    noTextBox: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const submit = async (e) => {
    e.preventDefault();
    const allocationReportMap = [];

    if (!inputValues) {
      return;
    }
    let newErrors = {};

    const checkRequired = (inputValue, inputName) => {
      if (inputValue === null || inputValue.trim() === "") {
        newErrors[inputName] = "This field is required";
      }
    };
    checkRequired(inputValues.button1, "button1");
    checkRequired(inputValues.button2, "button2");
    if (inputValues.button1 === "No") {
      checkRequired(inputValues.noTextBox, "noTextBox");
    }
    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== "")) {
      return;
    }

    sprintFeatureMap !== null &&
      Object.keys(sprintFeatureMap).map((item, i) => {
        const featuresMap = [];
        let sprintReference;
        let sprintallocationSecs = 0;
        let allocationSprintPerc;
        sprintReference = sprintFeatureMap[item]?.sprintData?.ref;
        allocationSprintPerc = sprintFeatureMap[item].sprintPercentage;
        sprintallocationSecs = sprintFeatureMap[item]?.sprintAllocation / 1000;
        sprintallocationSecs =
          sprintallocationSecs !== 0
            ? Math.round(sprintallocationSecs * 100) / 100
            : 0;
        sprintFeatureMap[item]?.feature?.map((data, j) => {
          let featureAllocationsSec = 0;
          let allocationfeaturePerc;
          allocationfeaturePerc =
            sprintFeatureMap[item]?.feature[j]?.featurePercentage;

          featureAllocationsSec =
            sprintFeatureMap[item]?.feature[j]?.featureAllocation !== undefined
              ? sprintFeatureMap[item]?.feature[j]?.featureAllocation / 1000
              : 0;
          featureAllocationsSec =
            featureAllocationsSec !== 0
              ? Math.round(featureAllocationsSec * 100) / 100
              : 0;
          featuresMap.push({
            allocationPerc:
              allocationfeaturePerc !== undefined ? allocationfeaturePerc : 0,
            allocationSecs: featureAllocationsSec,
            featureName: data?.name,
            id: data?.featureID,
            projectName: sprintFeatureMap[item]?.sprintProjectName,
          });
        });
        allocationReportMap.push({
          allocationPerc:
            allocationSprintPerc !== undefined ? allocationSprintPerc : 0,
          allocationSecs: sprintallocationSecs,
          features: featuresMap,
          sprintRef: sprintReference,
        });
      });

    const checkOutReportMap = {
      accomplished: inputValues.button1,
      feeling: inputValues.button2,
      feelingEmoticon: ":)",
      reasonForNo: inputValues.noTextBox,
      comments: inputValues.sprintNote,
    };
    let timedifference = 0;
    timedifference = clockOutTime - lastIndexTime;
    let _timeWorkedInSeconds =
      timeWorkedInSeconds !== 0 ? parseInt(timeWorkedInSeconds / 1000) : 0;
    if (timeSheetData?.data()?.presenceData?.length % 2 === 0) {
      if (timedifference > 0) {
        let editPresenceData = timeSheetData?.data()?.presenceData;
        editPresenceData[editPresenceData.length - 1].dateTime = clockOutTime;
        docRef?.update({
          checkOut: clockOutTime,
          presenceData: editPresenceData,
          checkoutReport: checkOutReportMap,
          allocation: allocationReportMap,
          timeWorked: _timeWorkedInSeconds,
        });
      }
    } else {
      if (timedifference > 0) {
        const presenceDataMap = { dateTime: clockOutTime, type: "pause" };
        const clockOutObj = {
          presenceData:
            firebase.firestore.FieldValue.arrayUnion(presenceDataMap),
          checkoutReport: checkOutReportMap,
          checkOut: clockOutTime,
          allocation: allocationReportMap,
          timeWorked: _timeWorkedInSeconds,
        };

        docRef?.update(clockOutObj);
      }
    }
    const timesheetDt = await docRef?.get();
    if (isToday) setTimeSheetData(timesheetDt);
    setIsShowClockOutModal(false);
    if (!hideCancel) {
      if (misssingClockOutData && misssingClockOutData?.length === 1) {
        setIsAccountNotGood && setIsAccountNotGood(false);
      }
    }

    setHideCancel && setHideCancel(misssingClockOutData?.length > 1);
    setClockOutResolve && setClockOutResolve(misssingClockOutData?.length > 1);
    setShowClockDates &&
      misssingClockOutData?.length > 1 &&
      setShowClockDates(true);
    setShowTimePicker && setShowTimePicker(false);
  };

  return (
    <>
      {isShowClockOutModal && (
        <div className="clock_out_form_container">
          <div className="clock_out_modal">
            <h3 className="checkOutHeader">Checkout Conflict Resolution</h3>
            <hr className="horizontal-line" />
            <div className="checkOut-section-first">
              <div className="use_report_container">
                <p className="use_report">
                  {" "}
                  Use this report to detail your day's work with spryte.
                </p>
                <span className="precise">Be Precise</span>
              </div>
              <p className="not_ready">
                If you are not ready to clockout yet.please return to the home
                screen and proceed as normal.
              </p>
              <p>
                {/* <button variant="confident" className="support_btn">
                  Confused? Contact support
                </button> */}
              </p>
            </div>
            <hr className="horizontal-line" />
            <div className="checkOut-section">
              <p className="accomplished">
                {" "}
                Do you feel as though you have accomplished all of your tasks
                for the day?
              </p>
              <div className="feeling_container">
                <div>
                  <input
                    type="radio"
                    name="button1"
                    value="No"
                    id="button1"
                    onChange={handleChange}
                  />

                  <label className="radio" htmlFor="button1">
                    {" "}
                    No{" "}
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="button1"
                    value="Yes"
                    id="button2"
                    onChange={handleChange}
                  />
                  <label className="radio" htmlFor="button1">
                    {" "}
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="button1"
                    value="Yes++"
                    id="button3"
                    onChange={handleChange}
                  />
                  <label className="radio" htmlFor="button1">
                    {" "}
                    Yes++
                  </label>
                </div>
              </div>
              {errors.button1 && (
                <div style={{ color: "red", marginTop: "0.5rem" }}>
                  {errors.button1}
                </div>
              )}
              {inputValues.button1 === "No" && (
                <input
                  type="text"
                  name="noTextBox"
                  className="note_input"
                  value={inputValues.noTextBox}
                  placeholder="Why? Please describe what may have went..."
                  onChange={handleChange}
                />
              )}
              {errors.noTextBox && (
                <div style={{ color: "red", marginTop: "0.5rem" }}>
                  {errors.noTextBox}
                </div>
              )}
            </div>
            <h3 className="checkOutHeader">Today</h3>
            <hr className="horizontal-line" />
            <div className="checkOut-section" onChange={handleChange}>
              <div className="time_worked_header">
                <p>Total time worked today</p>
                <span>Be Mindful of your pauses!</span>
              </div>
              <p className="time_worked_today">
                <b>{totalTimeWorked}</b>
              </p>
              <p className="today_feeling_header">
                How do you feel about today ?
              </p>

              <div className="today_feeling_container">
                <div>
                  <input
                    type="radio"
                    name="button2"
                    value="Frustated"
                    id="feelGrp1"
                    onChange={handleChange}
                  />
                  <label className="radio" htmlFor="button2">
                    {" "}
                    Frustated{" "}
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="button2"
                    value="Sad"
                    id="feelGrp2"
                    onChange={handleChange}
                  />
                  <label className="radio" htmlFor="button2">
                    {" "}
                    Sad{" "}
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="button2"
                    value="Tired / Struggling"
                    id="feelGrp3"
                    onChange={handleChange}
                  />
                  <label className="radio" htmlFor="button2">
                    {" "}
                    Tired / Struggling{" "}
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="button2"
                    value="Normal"
                    id="feelGrp4"
                    onChange={handleChange}
                  />
                  <label className="radio" htmlFor="button2">
                    {" "}
                    Normal{" "}
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="button2"
                    value="Alright "
                    id="feelGrp5"
                    onChange={handleChange}
                  />
                  <label className="radio" htmlFor="button2">
                    {" "}
                    Alright{" "}
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="button2"
                    value="Fantastic"
                    id="feelGrp6"
                    onChange={handleChange}
                  />
                  <label className="radio" htmlFor="button2">
                    {" "}
                    Fantastic{" "}
                  </label>
                </div>
              </div>
              {errors.button2 && (
                <div style={{ color: "red", marginTop: "0.5rem" }}>
                  {errors.button2}
                </div>
              )}
            </div>
            <hr className="horizontal-line" />
            <div className="checkOut-section">
              <h3 className="checkOutHeader">Your Sprint Allocation</h3>
              <p className="please_note">
                <span className="precise">PLEASE NOTE </span>
                Boxes below surrounded with a colored box are for individual,
                sprint-based feature reporting.
              </p>
              <ClockOutFormSliderModal
                timeSheetData={timeSheetData}
                sprintFeatureMap={sprintFeatureMap}
                timeWorkedInSeconds={timeWorkedInSeconds}
                setSprintFeatureMap={setSprintFeatureMap}
              />

              {/* <p> Anything else we should know about ? how can we help?</p> */}
              <input
                type="text"
                name="sprintNote"
                className="anything_else_input"
                value={inputValues.sprintNote}
                placeholder="Anything else we should know about? How can we help?"
                onChange={handleChange}
              />
            </div>
            <div className="checkOut_btns">
              <button
                onClick={() => {
                  setIsShowClockOutModal(false);
                  setClockOutResolve && setClockOutResolve(false);
                }}
              >
                Cancel
              </button>
              <button onClick={submit}>Submit</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClockOutModal;
