import React from 'react';
import NoAvatar from '../noAvatar/NoAvatar';
import ChatGroupAvatar from '../svgs/ChatGroupAvatar';

const AllChats = ({
  isAdmin,
  isAdmin2,
  allDiscussion,
  allMyChats,
  selectAllChatGroup,
  chatObj,
  truncateString,
  windowSize
}) => {
  return (
    <>
      {((allDiscussion && isAdmin) || (allDiscussion && isAdmin2)) ?
        <div className='all_discussion_container'>
          {allMyChats &&
            allMyChats?.map((chat, index) => {
              // gets the refs for every group member
              const groupMemRef = chat?.allChat?.members?.map(mem => {
                return mem?.path;
              });

              // gets the next user data if the group members are less than 3, 
              // ideally, group members should be 2 except for multi-members group chats
              let nextUserInfo = {};
              if (chat?.allChat?.members?.length === 2) {
                chat?.otherUserData?.map(data => {
                  if (groupMemRef.includes(data?.nextUserRef?.path)) {
                    nextUserInfo = data;
                  }
                })
              };

              return (
                <div
                  className='disc_user_container'
                  key={index}
                  style={ chatObj?.ref?.id === chat?.allChat?.ref?.id ?
                    {borderLeft: "0.3rem solid black", backgroundColor: "#FFFFFF"}
                    :
                    {cursor :'pointer'}
                  }
                >
                  <div className="disc_user_card">
                    <div
                      className="disc_user_data"
                      onClick={() => selectAllChatGroup(chat?.allChat)}
                    >
                      <>
                        {chat?.allChat?.members?.length === 2 ?
                          <>
                            {groupMemRef.includes(nextUserInfo?.nextUserRef?.path) &&
                              <>
                                {nextUserInfo?.avatar ?
                                  <div
                                    className={`disc_user_avatar 
                                      ${chatObj?.ref?.id === chat?.allChat?.ref?.id ? 
                                      'active_disc_user_avatar' : 'inactive_disc_user_avatar'}`
                                    }
                                  >
                                    <img src={nextUserInfo?.avatar} className="disc_avatar_image" alt={nextUserInfo?.first} />
                                  </div>
                                  :
                                  <div
                                    className={`disc_user_avatar 
                                      ${chatObj?.ref?.id === chat?.allChat?.ref?.id ? 
                                      'active_disc_user_avatar' : 'inactive_disc_user_avatar'}`
                                    }
                                  >
                                    <NoAvatar text={nextUserInfo?.first} size={4.3} radius={50} />
                                  </div>
                                }
                              </>
                            }
                          </>
                          :
                          chat?.allChat?.isRequirement ?
                            <div
                              className={`disc_user_avatar 
                                ${chatObj?.ref?.id === chat?.allChat?.ref?.id ? 
                                'active_disc_user_avatar' : 'inactive_disc_user_avatar'}`
                              }
                            >
                              <NoAvatar text="r" size={4.3} radius={50} />
                            </div>
                          :
                          <div
                            className={`disc_user_avatar 
                              ${chatObj?.ref?.id === chat?.allChat?.ref?.id ? 
                              'active_disc_user_avatar' : 'inactive_disc_user_avatar'}`
                            }
                          >
                            <ChatGroupAvatar
                              width="4.3rem"
                              height="4.3rem"
                            />
                          </div>
                        }
                      </>
                      <div className="disc-info-section">
                        <p style={{paddingLeft:"0px"}}>  
                          {chat?.allChat?.members?.length === 2 ?
                            <>
                              {groupMemRef.includes(nextUserInfo?.nextUserRef?.path) &&
                                <span>{nextUserInfo?.first}</span>
                              }
                            </>
                            :
                            <>
                              {windowSize <= 1040 ?
                                truncateString(chat?.allChat?.groupName, 40)
                                :
                                truncateString(chat?.allChat?.groupName, 30)
                              }
                            </>
                          }
                          {/* <small>{chat?.createdOn?.toDate().toString().substring(0, 10)}</small> */}
                        </p>
                        {' '}
                        <p className='last_msg'>
                          {truncateString(chat?.allChat?.lastMessage?.lastMessage, 100)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        :
        null
      }
    </>
  );
};

export default AllChats;
