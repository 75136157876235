import { DoneSvg, Flag } from "../helpers/Svgs";

export function ContinuePopUp({
  isFeedbackPopupOpen,
  setIsFeedbackPopupOpen,
  onClickHandler,
  textPrompt,
}) {
  return (
    <>
      <div
        className="fixed duration-2000 bg-lime-100 font-bold text-green-600 bottom-0 left-0 right-0"
        style={{ zIndex: 2 }}
      >
        <div className="flex max-w-5xl flex-col gap-4 p-5 sm:mx-auto sm:flex-row sm:items-center sm:justify-between sm:p-10 sm:py-14">
          <>
            <div className="mb-2 flex justify-between gap-5 sm:justify-center sm:flex-row sm:items-center">
              <div className="flex items-center justify-center gap-2">
                <div className="rounded-full bg-green-500 p-1 text-green-500 sm:block">
                  <DoneSvg className="h-5 w-5 text-white" />
                </div>
                <div className="text-2xl">{textPrompt}</div>
              </div>
              <button
                onClick={() => setIsFeedbackPopupOpen(!isFeedbackPopupOpen)}
              >
                <Flag className="h-5 w-5 font-bold" />
              </button>
            </div>
            <button
              onClick={onClickHandler}
              className="grow rounded-2xl border-b-4 border-green-600 bg-green-500 p-9 font-bold uppercase text-white sm:min-w-[50px] sm:max-w-[100px] sm:grow-0"
              style={{
                flex: "1 1 20%",
                marginRight: "10px",
                boxShadow: "0 2px 0 rgb(88, 167, 0), 0 0 0 rgb(24, 153, 214)",
              }}
            >
              Continue
            </button>
          </>
        </div>
      </div>
    </>
  );
}
