import React, { useState } from 'react';

const EmailTextArea = ({
  to,
  setTo,
  cc,
  setCc,
  bcc,
  setBcc,
  subject,
  setSubject,
  message,
  setMessage,
  nextUserEmail
}) => {
  const [isCcInput, setIsCcInput] = useState(false);
  const [isBccInput, setIsBccInput] = useState(false);

  const handleChange = e => {
    e.persist();
    const { value } = e.target;
    if (e.target.name === 'to') {
      // setTo(value);
      setTo(nextUserEmail);
    } else if (e.target.name === 'cc') {
      setCc(value);
    } else if (e.target.name === 'bcc') {
      setBcc(value);
    } else if (e.target.name === 'subject') {
      setSubject(value);
    } else {
      setMessage(value);
    };
  };

  return(
    <>
      <div className='email_textarea_container'>
        <div className='to_container'>
          <label>To</label>
          <input
            type='email'
            name='to'
            // value={to}
            value={nextUserEmail}
            placeholder='Recipients'
            onChange={handleChange}
          />
          <div className='cc_bcc_btns'>
            {!isCcInput &&
              <span
                className='cc'
                onClick={() => setIsCcInput(true)}
              >
                Cc
              </span>
            }
            {!isBccInput &&
              <span
                className='bcc'
                onClick={() => setIsBccInput(true)}
              >
                Bcc
              </span>
            }
          </div>
        </div>
        <div className='cc_bcc_input_container'>
          {isCcInput &&
            <label>
              Cc
              <input
                type='email'
                name='cc'
                value={cc}
                onChange={handleChange}
              />
            </label>
          }
          {isBccInput &&
            <label>
              Bcc
              <input
                type='email'
                name='bcc'
                value={bcc}
                onChange={handleChange}
              />
            </label>
          }
        </div>
        <div className='subject_container'>
          <label>Subject</label>
          <input
            type='text'
            placeholder='Subject'
            name='subject'
            value={subject}
            onChange={handleChange}
            onClick={() => {
              setIsCcInput(false);
              setIsBccInput(false);
            }}
          />
        </div>
        <div className='textarea_container'>
          <textarea
            type='text'
            name='message'
            placeholder='Write your message here...'
            value={message}
            onChange={handleChange}
            onClick={() => {
              setIsCcInput(false);
              setIsBccInput(false);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EmailTextArea;
