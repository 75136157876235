import { style } from 'd3';
import React from 'react';

const TimeSheetPrevIcon = ({ width, height, icon }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5404 29.6006C24.0907 29.0681 24.0907 28.2047 23.5404 27.6721L10.4459 15.0003L23.5404 2.32786C24.0907 1.79541 24.0907 0.931955 23.5404 0.399409C22.9901 -0.133136 22.0979 -0.133136 21.5476 0.399409L7.45671 14.0361C7.19246 14.2919 7.04403 14.6387 7.04403 15.0003C7.04403 15.362 7.19255 15.7089 7.4568 15.9645L21.5477 29.6005C22.0979 30.1331 22.9902 30.1331 23.5404 29.6006Z"
        //fill="#A5A6A8"
        fill={icon === "backArrow" ? 'black' : '#A5A6A8'} 

      />
    </svg>
  );
};

export default TimeSheetPrevIcon;