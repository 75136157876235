import React from 'react';

const UnreadReceiptsIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66667 11.1327L0 6.46601L1.63333 4.83268L4.66667 7.86602L12.3667 0.166016L14 1.79935L4.66667 11.1327Z"
        fill="#25C7AA"
      />
      <path
        d="M16.6667 11.1327L12 6.46601L13.6333 4.83268L16.6667 7.86602L24.3667 0.166016L26 1.79935L16.6667 11.1327Z"
        fill="#D0D0D0"
      />
    </svg>
  );
};

export default UnreadReceiptsIcon;