/* eslint-disable default-case */
import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { CourseHeader } from "./CourseHeader";
import {
  ActiveBookSvg,
  ActiveDumbbellSvg,
  ActiveTreasureSvg,
  ActiveTrophySvg,
  CheckmarkSvg,
  FastForwardSvg,
  GoldenBookSvg,
  GoldenDumbbellSvg,
  GoldenTreasureSvg,
  GoldenTrophySvg,
  LockSvg,
  LockedBookSvg,
  LockedDumbbellSvg,
  LockedTreasureSvg,
  LockedTrophySvg,
  SkippedSvg,
  StarSvg,
} from "../helpers/Svgs";
import { TileTooltip } from "../Tile/ToolTip";
import { HoverLabel } from "../Tile/HoverLabel";
import { LessonCompletionSvg } from "../Lesson/LessonCompletionSvg";
import { assignFinalAssessment, assignPracticeCourse, hasTakenFinalAssessmentThisMonth } from "../helpers/assignNextAssessment";
import { CurrentUserContext, DbContext, FirebaseContext } from "../Context/Nestria";

interface Unit {
  type: string;
  status: string;
  unitName: string;
  unitNumber: number;
  textColor: string;
  id: string;
  borderColor: string;
  backgroundColor: string;
  xp: number;
}

export const CourseSection = ({
  setIsReviewing,
  isActiveCourse,
  course,
  units,
  questions,
  userQuestions,
  setShowLesson,
  setSelectedUnit,
  isLastCourse,
  currentActiveSubject,
  assessmentMode,
  setAreAllUnitsCompleted,
}) => {
  const currentUserObj = useContext(CurrentUserContext);
  const db = useContext(DbContext);
  const firebase = useContext(FirebaseContext);
  const [selectedTile, setSelectedTile] = useState<number | null>(null);
  const [sortedUnits, setSortedUnits] = useState<null | Array<Unit>>(null);
  const [refresh, setRefresh] = useState(true);

  /**
   * Returns the active tile id from the given course questions
   * @param {Array} courseQuestions
   * @returns String : activeUnitId
   */
  const localActiveIndex = async (courseQuestions) => {
    try {
      if (!Array.isArray(courseQuestions)) {
        throw Error(
          `Input parameter courseQuestions is expected to be an array.`
        );
      }

      const _courseQuestions = [...courseQuestions];

      _courseQuestions.sort((a, b) => a.index - b.index); // Sort by question index

      _courseQuestions.sort((a, b) => a.unitNumber - b.unitNumber); // Sort by unit number

      const tileQuestions = await Promise.all(
        _courseQuestions.map((item) => {
          return {
            unitId: item.unitId,
            isAnswered: item.isAnswered,
          };
        })
      );

      let activeUnitId;

      for (let i = 0; i < tileQuestions.length; i++) {
        // This is assuming questions are in order
        if (tileQuestions[i].isAnswered === false) {
          // Set as Active tile
          activeUnitId = tileQuestions[i].unitId;
          break;
        }
      }

      return activeUnitId;
    } catch (err) {
      throw Error(err);
    }
  };

  const processUnits = async (activeUnitId) => {
    try {
      const _sortedUnits = await units.sort(
        (a, b) => a.unitIndex - b.unitIndex
      );
      await Promise.all(
        _sortedUnits.map(async (unit) => {
          const currentUnitId = unit.id;
          const status = await tileStatus(
            activeUnitId,
            currentUnitId,
            userQuestions?.filter((x) => x.unitId === unit.id)
          );
          unit.status = status;
        })
      );
      // const allCompleted = _sortedUnits.every(unit => unit.status === "COMPLETE");
      
      setSortedUnits(_sortedUnits);

    } catch (e) {
      throw Error(e);
    }
  };

  useEffect(() => {
    const unselectTile = () => setSelectedTile(null);
    window.addEventListener("scroll", unselectTile);
    return () => window.removeEventListener("scroll", unselectTile);
  }, []);

  useEffect(() => {
    localActiveIndex(questions)
      .then((activeUnitId) => {
        processUnits(activeUnitId); // Call sorting function
      })
      .catch((e) => {
        console.error(e);
      });
  }, [units, userQuestions]);

  const closeTooltip = useCallback(() => setSelectedTile(null), []);

  
  
  const assignAssessment = async (subject, mode) => {
    const assignmentInProgress = JSON.parse(localStorage.getItem('assignmentInProgress') || '{}');
    const currentTimestamp = Date.now();
  
    if (assignmentInProgress[subject] && assignmentInProgress[subject][mode]) {
      const elapsedTime = currentTimestamp - assignmentInProgress[subject][mode];
      if (elapsedTime < 60000) { 
        return;
      }
    }
  
    if (!assignmentInProgress[subject]) {
      assignmentInProgress[subject] = {};
    }
    assignmentInProgress[subject][mode] = currentTimestamp;
    localStorage.setItem('assignmentInProgress', JSON.stringify(assignmentInProgress));
  
    const activeSubjectQuestions = userQuestions.filter(
      (question) =>
        question.courseSubject === currentActiveSubject?.toLowerCase() &&
        question.courseName.toLowerCase().includes(assessmentMode?.toLowerCase())
    );

    const areAllActiveSubjectQuestionsCompleted =
      activeSubjectQuestions.length > 0 &&
      activeSubjectQuestions.every((question) => question.isAnswered === true);

    if (
      areAllActiveSubjectQuestionsCompleted &&
      assessmentMode === "Practice" &&
      currentActiveSubject !== "Orientation"
    ) {
      const subject = currentActiveSubject?.toLowerCase();
      console.log("Assigning Practice Unit for ", subject);
  
      await assignPracticeCourse(db, subject, currentUserObj?.ref, firebase);
  
    } else if (
      areAllActiveSubjectQuestionsCompleted &&
      assessmentMode === "Final" &&
      currentActiveSubject !== "Orientation"
    ) {
  
      const subject = currentActiveSubject?.toLowerCase();
      const subjectsTakenInLast30Days = await hasTakenFinalAssessmentThisMonth(
        db,
        userQuestions
      );
  
      if (!subjectsTakenInLast30Days.includes(subject)) {
        console.log("Assigning Final Unit for ", subject);
  
        await assignFinalAssessment(db, subject, currentUserObj?.ref, firebase);
      } else {
        setAreAllUnitsCompleted(true);
      }
    }
  
    if (
      userQuestions.length > 0 &&
      activeSubjectQuestions.length === 0 &&
      currentActiveSubject !== "Orientation"
    ) {
      const subject = currentActiveSubject?.toLowerCase();
  
      console.log(`Assigning Course for Subject - ${currentActiveSubject} and Mode is ${assessmentMode}.`);
  
      if (assessmentMode?.toLowerCase() === "final") {
        await assignFinalAssessment(db, subject, currentUserObj?.ref, firebase);
      } else if (assessmentMode?.toLowerCase() === "practice") {
        await assignPracticeCourse(db, subject, currentUserObj?.ref, firebase);
      } else {
        console.log("Not Assigning any course");
      }
    }
  };
  
  

  useEffect(() => {
    const subject = currentActiveSubject?.toLowerCase();
    const mode = assessmentMode?.toLowerCase();
    assignAssessment(subject, mode);
    
  }, [userQuestions, currentActiveSubject, assessmentMode]);
  
  


  useEffect(() => {
    setRefresh(!refresh);
  }, [units]);

  return (
    <>
      <div className="unitContainer" id={isActiveCourse ? 'currentActiveCourse' : ''}>
        <CourseHeader
          courseNumber={course.index + 1}
          courseName={course.courseName}
          courseType={course.courseType}
        />
        <div className="relative mt-[67px] mb-8 flex max-w-2xl flex-col items-center gap-4">
          {sortedUnits &&
            sortedUnits.map((unit, i) => {
              const status = unit.status;

              return (
                <Fragment key={i}>
                  {(() => {
                    // TODO: Turn this into a function
                    switch (unit.type) {
                      case "star":
                      case "book":
                      case "dumbbell":
                      case "trophy":
                      case "fast-forward":
                        if (unit.type === "trophy" && status === "COMPLETE") {
                          return (
                            <div className="relative">
                              <TileIcon tileType={unit.type} status={status} />
                              <div className="absolute top-6 left-0 right-0 flex justify-center text-lg font-bold text-yellow-700">
                                {unit.unitNumber}
                              </div>
                            </div>
                          );
                        }
                        return (
                          <div
                            className={[
                              "relative -mb-4 h-[93px] w-[98px]",
                              getTileLeftClassName({
                                index: i,
                                courseNumber: course.index + 1,
                                tilesLength: units.length,
                              }),
                            ].join(" ")}
                          >
                            {unit.type === "fast-forward" &&
                            status === "LOCKED" ? (
                              <HoverLabel
                                text="Jump here?"
                                textColor={unit.textColor}
                              />
                            ) : status === "ACTIVE" && isLastCourse === true ? (
                              <div id="activeQuestionId">
                                <HoverLabel
                                  text="Start"
                                  textColor={unit.textColor}
                                />
                              </div>
                            ) : null}
                            <LessonCompletionSvg
                              status={status}
                              unitQuestions={userQuestions?.filter(
                                (x) => x.unitId === unit.id
                              )}
                            />

                            <button
                              className={[
                                "absolute m-3 rounded-full border-b-8 p-4",
                                getTileColors({
                                  tileType: unit.type,
                                  status,
                                  defaultColors: `${unit.borderColor} ${unit.backgroundColor}`,
                                }),
                              ].join(" ")}
                              onClick={async () => {
                                setSelectedTile(i);
                              }}
                            >
                              <TileIcon tileType={unit.type} status={status} />
                              <span className="sr-only">Show lesson</span>
                            </button>
                          </div>
                        );
                      case "treasure":
                        return (
                          <div
                            className={[
                              "relative -mb-4",
                              getTileLeftClassName({
                                index: i,
                                courseNumber: course.index + 1,
                                tilesLength: units.length,
                              }),
                            ].join(" ")}
                            onClick={() => {
                              if (status === "ACTIVE") {
                                // increaseLessonsCompleted(4);
                                // increaseLingots(1);
                              }
                            }}
                            role="button"
                            tabIndex={status === "ACTIVE" ? 0 : undefined}
                            aria-hidden={status !== "ACTIVE"}
                            aria-label={
                              status === "ACTIVE" ? "Collect reward" : ""
                            }
                          >
                            {status === "ACTIVE" && (
                              <HoverLabel
                                text="Open"
                                textColor="text-yellow-400"
                              />
                            )}
                            <TileIcon tileType={unit.type} status={status} />
                          </div>
                        );
                    }
                  })()}
                  <TileTooltip
                    setIsReviewing={setIsReviewing}
                    setShowLesson={setShowLesson}
                    selectedTile={selectedTile}
                    xp={unit?.xp ? unit?.xp : 10} // if no xp, set default xp to 10
                    index={i}
                    allQuestions={userQuestions}
                    tilesLength={sortedUnits.length}
                    description={(() => {
                      switch (unit.type) {
                        case "book":
                        case "dumbbell":
                        case "star":
                          return unit?.unitName;
                        case "fast-forward":
                          return status === "LOCKED"
                            ? "Jump here?"
                            : unit?.unitName;
                        case "trophy":
                          return `Unit ${unit.unitNumber} review`;
                        case "treasure":
                          return "";
                      }
                    })()}
                    status={status}
                    closeTooltip={closeTooltip}
                    setSelectedTile={setSelectedTile}
                    setSelectedUnit={setSelectedUnit}
                    courseNumber={course.index + 1}
                    unit={unit}
                  />
                </Fragment>
              );
            })}
        </div>
      </div>
    </>
  );
};

const tileStatus = async (activeUnitTileId, currentUnitId, tileQuestions) => {
  try {
    if (!currentUnitId || !tileQuestions) {
      throw Error(`Required input parameters are missing: 
          Got currentUnitId: ${currentUnitId}, and, tileQuestions: ${tileQuestions}`);
    }

    if (!Array.isArray(tileQuestions)) {
      throw Error(
        `tileQuestions is expected to be an array. Got tileQuestions: ${tileQuestions}`
      );
    }

    // Return early
    if (activeUnitTileId === currentUnitId) {
      return "ACTIVE";
    }

    let answeredCount = 0;

    await Promise.all(
      tileQuestions.map((question) => {
        if (question.isAnswered === true) {
          answeredCount += 1;
        }
      })
    );

    if (answeredCount === tileQuestions.length) {
      return "COMPLETE";
    } else if (answeredCount === 0) {
      return "LOCKED";
    } else {
      console.error(
        `[TRACK] answeredCount is neither fully complete nor fully unanswered. This is tile is not marked active either.`
      );
      return "LOCKED"; // Return Locked in this case
    }
  } catch (e) {
    throw Error(e);
  }
};

const TileIcon = ({ tileType, status }) => {
  switch (tileType) {
    case "star":
      return status === "COMPLETE" ? (
        <CheckmarkSvg />
      ) : status === "ACTIVE" ? (
        <StarSvg />
      ) : status === "SKIPPED" ? (
        <SkippedSvg />
      ) : (
        <LockSvg />
      );
    case "book":
      return status === "COMPLETE" ? (
        <GoldenBookSvg />
      ) : status === "ACTIVE" ? (
        <ActiveBookSvg />
      ) : (
        <LockedBookSvg />
      );
    case "dumbbell":
      return status === "COMPLETE" ? (
        <GoldenDumbbellSvg />
      ) : status === "ACTIVE" ? (
        <ActiveDumbbellSvg />
      ) : (
        <LockedDumbbellSvg />
      );
    case "fast-forward":
      return status === "COMPLETE" ? (
        <CheckmarkSvg />
      ) : status === "ACTIVE" ? (
        <StarSvg />
      ) : status === "SKIPPED" ? (
        <SkippedSvg />
      ) : (
        <FastForwardSvg />
      );
    case "treasure":
      return status === "COMPLETE" ? (
        <GoldenTreasureSvg />
      ) : status === "ACTIVE" ? (
        <ActiveTreasureSvg />
      ) : (
        <LockedTreasureSvg />
      );
    case "trophy":
      return status === "COMPLETE" ? (
        <GoldenTrophySvg />
      ) : status === "ACTIVE" ? (
        <ActiveTrophySvg />
      ) : (
        <LockedTrophySvg />
      );
  }
};

const getTileLeftClassName = ({ index, courseNumber, tilesLength }) => {
  if (index >= tilesLength - 1) {
    return "left-0";
  }

  const classNames =
    courseNumber % 2 === 1
      ? tileLeftClassNames
      : [...tileLeftClassNames.slice(4), ...tileLeftClassNames.slice(0, 4)];

  return classNames[index % classNames.length] ?? "left-0";
};

const tileLeftClassNames = [
  "left-0",
  "left-[-45px]",
  "left-[-70px]",
  "left-[-45px]",
  "left-0",
  "left-[45px]",
  "left-[70px]",
  "left-[45px]",
];

const getTileColors = ({ tileType, status, defaultColors }) => {
  switch (status) {
    case "LOCKED":
      if (tileType === "fast-forward") {
        return defaultColors;
      }
      return "border-[#b7b7b7] bg-[#e5e5e5]";
    case "COMPLETE":
      return "border-yellow-500 bg-yellow-400";
    case "SKIPPED":
      return "border-gray-300 bg-gray-200";
    case "ACTIVE":
      return defaultColors;
  }
};
