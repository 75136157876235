import React, { useEffect, useRef, useState } from "react";
import { Mixpanel } from "../helpers/Mixpanel";
import { limitWords } from "../helpers/utils";

export const TileTooltip = ({
  setIsReviewing,
  setShowLesson,
  selectedTile,
  index,
  allQuestions,
  tilesLength,
  description,
  status,
  closeTooltip,
  setSelectedTile,
  setSelectedUnit,
  xp,
  courseNumber,
  unit,
}) => {
  const tileTooltipRef = useRef<HTMLDivElement | null>();
  const [activeBackgroundColor, setActiveBackgroundColor] = useState();
  const [activeTextColor, setActiveTextColor] = useState();

  useEffect(() => {
    setActiveBackgroundColor(unit?.backgroundColor ?? "bg-[#58cc02]");
    setActiveTextColor(unit?.textColor ?? "text-[#58cc02]");
  }, [allQuestions]);

  useEffect(() => {
    const containsTileTooltip = (event) => {
      if (selectedTile !== index) return;
      const clickIsInsideTooltip = tileTooltipRef?.current?.contains(
        event.target
      );
      if (clickIsInsideTooltip) return;
      closeTooltip();
    };

    window.addEventListener("click", containsTileTooltip, true);
    return () => window.removeEventListener("click", containsTileTooltip, true);
  }, [selectedTile, tileTooltipRef, closeTooltip, index]);

  return (
    <div
      className={[
        "relative h-0 w-full",
        index === selectedTile ? "" : "invisible",
      ].join(" ")}
      // ref={tileTooltipRef}
    >
      <div
        className={[
          "absolute z-30 flex w-[300px] flex-col gap-4 rounded-xl p-4 font-bold transition-all duration-300",
          status === "ACTIVE" || status === "SKIPPED"
            ? activeBackgroundColor
            : status === "LOCKED"
            ? "border-2 border-gray-200 bg-gray-100" // Add your else condition here
            : "bg-yellow-400",
          index === selectedTile ? "top-4 scale-100" : "-top-14 scale-0",
        ].join(" ")}
        style={{ left: "calc(50% - 150px)" }}
      >
        <div
          className={[
            "absolute top-[-8px] left-[140px] h-4 w-4 rotate-45",
            status === "ACTIVE" || status === "SKIPPED"
              ? activeBackgroundColor
              : status === "LOCKED"
              ? "border-t-2 border-l-2 border-gray-200 bg-gray-100"
              : "bg-yellow-400",
          ].join(" ")}
          style={{
            left: getTileTooltipLeftOffset({
              index,
              tilesLength,
              courseNumber,
            }),
          }}
        ></div>
        <div
          className={[
            "text-lg",
            status === "ACTIVE" || status === "SKIPPED"
              ? "text-white"
              : status === "LOCKED"
              ? "text-gray-400"
              : "text-yellow-600",
          ].join(" ")}
        >
          {limitWords(description, 15)}
        </div>
        {status === "ACTIVE" || status === "SKIPPED" ? (
          <button
            onClick={() => {
              if (status === "SKIPPED") {
                setIsReviewing(true);
              } else {
                setIsReviewing(false);
              }
              setShowLesson(true);
              setSelectedTile(index);
              setSelectedUnit(unit);
              // setSelectedUnitTileIndex(index);

              // Mixpanel
              Mixpanel.track("Question Started", {
                xp: xp,
                unit: unit?.description,
                unit_number: unit?.unitNumber,
                question: description,
              });
            }}
            className={[
              "flex w-full items-center justify-center rounded-xl border-b-4 border-gray-200 bg-white p-3 uppercase",
              activeTextColor,
            ].join(" ")}
          >
            Start +{xp} XP
          </button>
        ) : status === "LOCKED" ? (
          <button className="w-full rounded-xl bg-gray-200 p-3 uppercase text-gray-400">
            Locked
          </button>
        ) : (
          <button
            onClick={() => {
              setIsReviewing(true);
              setShowLesson(true);
              setSelectedTile(index);
              setSelectedUnit(unit);
              // setSelectedUnitTileIndex(index);
              // setSelectedUnitTileIndex(0);

              // Mixpanel
              Mixpanel.track("Question Started to Practice", {
                xp: xp,
                unit: unit?.description,
                unit_number: unit?.unitNumber,
                question: description,
              });
            }}
            className="flex w-full items-center justify-center rounded-xl border-b-4 border-yellow-200 bg-white p-3 uppercase text-yellow-400"
          >
            Review
            {/* +{xp} XP */}
          </button>
        )}
      </div>
    </div>
  );
};

const getTileTooltipLeftOffset = ({ index, tilesLength, courseNumber }) => {
  if (index >= tilesLength - 1) {
    return tileTooltipLeftOffsets[0];
  }

  const offsets =
    courseNumber % 2 === 1
      ? tileTooltipLeftOffsets
      : [
          ...tileTooltipLeftOffsets.slice(4),
          ...tileTooltipLeftOffsets.slice(0, 4),
        ];

  return offsets[index % offsets.length] ?? tileTooltipLeftOffsets[0];
};

const tileTooltipLeftOffsets = [140, 95, 70, 95, 140, 185, 210, 185];
