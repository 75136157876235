export default async function textToSpeech(text) {
  if (!text) {
    return;
  }

  const ENV = process.env.REACT_APP_ENV || "development";

  console.log(`Speech Service: `, ENV);

  let SPEECH_TO_TEXT_WS_URL = process.env.REACT_APP_DEV_TEXT_TO_SPEECH;

  if (ENV === "staging") {
    SPEECH_TO_TEXT_WS_URL = process.env.REACT_APP_STAGING_TEXT_TO_SPEECH;
  } else if (ENV === "production") {
    SPEECH_TO_TEXT_WS_URL = process.env.REACT_APP_PROD_TEXT_TO_SPEECH; 
  }

  function stripHtmlTags(html) {
    return html.replace(/[<>]/g, '');
  }  

  const body = {
    "audioConfig": {
      "audioEncoding": "LINEAR16",
      "effectsProfileId": [
        "small-bluetooth-speaker-class-device"
      ],
      "pitch": 0,
      "speakingRate": 1
    },
    "input": {
      "text": stripHtmlTags(text)
    },
    "voice": {
      "languageCode": "en-US",
      "name": "en-US-Studio-O"
    }
  };

  const rawResponse = await fetch(`https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=${SPEECH_TO_TEXT_WS_URL}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });

  const jsonResponse = await rawResponse.json();
  console.log(jsonResponse);

  const { audioContent } = jsonResponse;

  return audioContent;
}