import React from 'react';

const DelChannelIcon = ({ width, height, top, bottom }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: top, marginBottom: bottom }}
    >
      <path
        d="M20 6.04102C22.5848 6.04102 24.6916 8.0877 24.7882 10.6489L24.7917 10.8327H30.8333C31.4086 10.8327 31.875 11.2991 31.875 11.8743C31.875 12.4137 31.4651 12.8573 30.9398 12.9106L30.8333 12.916H29.9242L28.5049 31.7242C28.3654 33.5716 26.8259 34.9993 24.9732 34.9993H15.0268C13.1741 34.9993 11.6346 33.5716 11.4951 31.7242L10.075 12.916H9.16667C8.62733 12.916 8.18372 12.5061 8.13038 11.9809L8.125 11.8743C8.125 11.335 8.5349 10.8914 9.06016 10.8381L9.16667 10.8327H15.2083C15.2083 8.18632 17.3536 6.04102 20 6.04102ZM27.8351 12.916H12.1649L13.5726 31.5674C13.63 32.3281 14.2639 32.916 15.0268 32.916H24.9732C25.7361 32.916 26.37 32.3281 26.4274 31.5674L27.8351 12.916ZM22.7083 17.291C23.2477 17.291 23.6913 17.7009 23.7446 18.2262L23.75 18.3327V27.4993C23.75 28.0746 23.2836 28.541 22.7083 28.541C22.169 28.541 21.7254 28.1311 21.672 27.6059L21.6667 27.4993V18.3327C21.6667 17.7574 22.133 17.291 22.7083 17.291ZM17.2917 17.291C17.831 17.291 18.2746 17.7009 18.328 18.2262L18.3333 18.3327V27.4993C18.3333 28.0746 17.867 28.541 17.2917 28.541C16.7523 28.541 16.3087 28.1311 16.2554 27.6059L16.25 27.4993V18.3327C16.25 17.7574 16.7164 17.291 17.2917 17.291ZM20 8.12435C18.5558 8.12435 17.3756 9.25473 17.296 10.679L17.2917 10.8327H22.7083C22.7083 9.33691 21.4958 8.12435 20 8.12435Z"
        fill="#FF223D"
      />
    </svg>
  );
};

export default DelChannelIcon;