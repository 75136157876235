import React from "react"

export const EndOfStreamMessage = ({assessmentMode, currentActiveSubject}) => {
  let message;
    
  if (assessmentMode === 'Final') {
    message = "Final Assessments are limited to once a month per subject. We recommend exploring other subjects or waiting until next month.";
  } else if (currentActiveSubject === "Orientation") {
    message = "Select your next unit and continue your learning journey!";
  } else {
    return null;
  } 


    return (
        <div
        style={{
          backgroundColor: "#7ac70c",
          display: "flex",
          margin: "0.5rem auto",
          padding: "1rem 1rem",
        }}
      ><div
       style={{
         fontSize: "19px",
         lineHeight: "1.4",
         fontFamily: "din-round, sans-serif",
         color: "rgb(255 255 255)",
         textAlign: "justify",
       }}
     >
       {message}
     </div>
      </div>
    )
}