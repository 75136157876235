import React from 'react';

const EditIcon = ({ width, height, top, bottom }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: top, marginBottom: bottom }}
    >
      <path
        d="M11.7411 1.26277C10.7265 0.245744 9.0767 0.245744 8.06219 1.26277L1.31718 8.00527C1.26445 8.058 1.2318 8.12581 1.22176 8.19863L0.722032 11.9001C0.706965 12.0056 0.744632 12.111 0.817456 12.1839C0.880236 12.2466 0.968127 12.2843 1.05602 12.2843C1.07108 12.2843 1.08615 12.2843 1.10122 12.2818L3.33114 11.9805C3.51697 11.9553 3.64755 11.7846 3.62244 11.5988C3.59732 11.4129 3.42656 11.2823 3.24074 11.3075L1.45278 11.5485L1.80184 8.96705L4.51893 11.6841C4.5817 11.7469 4.6696 11.7846 4.75749 11.7846C4.84538 11.7846 4.93327 11.7494 4.99605 11.6841L11.7411 4.94164C12.2332 4.44945 12.5045 3.79654 12.5045 3.10095C12.5045 2.40535 12.2332 1.75245 11.7411 1.26277ZM8.19277 2.09146L9.32531 3.22399L3.17043 9.37888L2.03789 8.24634L8.19277 2.09146ZM4.76 10.9659L3.65257 9.85851L9.80746 3.70363L10.9149 4.81106L4.76 10.9659ZM11.387 4.3264L8.67743 1.61684C9.02146 1.33308 9.45087 1.17739 9.90288 1.17739C10.4177 1.17739 10.8998 1.37828 11.2639 1.73989C11.6281 2.1015 11.8264 2.58616 11.8264 3.10095C11.8264 3.55547 11.6707 3.98237 11.387 4.3264Z"
        fill="#FF3F3F"
      />
    </svg>
  );
};

export default EditIcon;