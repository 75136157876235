import { useState } from "react";
import { ContinuePopUp } from "./ContinuePopUp";
import {
  RANDOM_SKIP_RESPONSES,
  generateRandomResponse,
} from "../helpers/utils";
import ReportContentIssues from "./ReportContentIssues";

/**
 * Contains action buttons displayed at the bottom of the Question Answer screen.
 * Action buttons include "Skip", "Submit", and "Continue"
 */
export default function CheckAnswer({
  onContinue,
  onSkip,
  onSubmit,
  onLessonComplete,
  lessonComplete,
  question,
  message,
  isAnswerSubmitted,
  isSubmittingAnswer,
  questionType,
  questionFeedback,
  setQuestionFeedback,
  setShowConfirmation,
  hasReachedMaxSubmissions,
  isFinalAssessmentError,
}) {
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);
  const [continuePopUp, setContinuePopUp] = useState(false);

  const onContinuePopUp = () => {
    return setContinuePopUp(!continuePopUp);
  };
  const courseType = question?.courseType;

  return (
    <>
      <section
        className="border-gray-200 max-h-lesson-section-buttons sm:border-t-2 sm:p-10"
        style={{ position: "absolute", bottom: "20px", alignSelf: "center" }}
      >
        <div
          className="mx-auto flex max-w-5xl sm:justify-between"
          style={{ width: "90vw" }}
        >
          {lessonComplete ? (
            <>
              <button
                onClick={onLessonComplete}
                className="grow rounded-2xl border-b-4 border-green-600 bg-green-500 p-9 font-bold uppercase text-white sm:min-w-[150px] sm:max-w-[250px] sm:grow-0"
                style={{
                  flex: "1 1 96%",
                  boxShadow: "0 2px 0 rgb(88, 167, 0), 0 0 0 rgb(24, 153, 214)",
                }}
              >
                Continue
              </button>
            </>
          ) : (
            <>
              {questionType?.trim()?.toLowerCase() === "default" && (
                <>
                  {question?.skippable === false && ( // All questions are skippable by default
                    <button
                      className="grow rounded-2xl border-b-4 border-grey-300 bg-gray-200 p-9 font-bold uppercase text-white sm:min-w-[50px] sm:max-w-[100px] sm:grow-0"
                      style={{
                        flex: "1 1 20%",
                        marginRight: "10px",
                        boxShadow: "0 4px 0 rgb(229,229,229)",
                      }}
                      disabled
                    >
                      Skip
                    </button>
                  )}
                  {question?.skippable === false || (
                    <button
                      onClick={() => {
                        onSkip();
                      }}
                      className={`grow rounded-2xl border-b-4 border-green-600 bg-green-500 p-9 font-bold uppercase text-white sm:min-w-[50px] sm:max-w-[100px] sm:grow-0`}
                      style={{
                        flex: "1 1 20%",
                        marginRight: "10px",
                        boxShadow:
                          "0 2px 0 rgb(88, 167, 0), 0 0 0 rgb(24, 153, 214)",
                      }}
                    >
                      skip
                    </button>
                  )}
                  {continuePopUp && (
                    <ContinuePopUp
                      isFeedbackPopupOpen={isFeedbackPopupOpen}
                      setIsFeedbackPopupOpen={setIsFeedbackPopupOpen}
                      onClickHandler={() => {
                        onSkip();
                        onContinuePopUp();
                      }}
                      textPrompt={
                        isFinalAssessmentError
                          ? "Final Assessments are limited to once a month per language. We recommend exploring alternative practice assessments or waiting until the following month"
                          : courseType === "assessment"
                          ? "Sure"
                          : generateRandomResponse(
                              RANDOM_SKIP_RESPONSES,
                              "No problem!"
                            )
                      }
                    />
                  )}
                </>
              )}
              {(questionType?.trim()?.toLowerCase() === "default" ||
                questionType?.trim()?.toLowerCase() === "routing_type") &&
              message &&
              !isSubmittingAnswer ? (
                <>
                  {" "}
                  <button
                    onClick={() => {
                      onSubmit(message);
                      onContinue();
                    }}
                    className="grow rounded-2xl border-b-4 border-green-600 bg-green-500 p-9 font-bold uppercase text-white sm:min-w-[150px] sm:max-w-[250px] sm:grow-0"
                    style={{
                      flex: "1 1 75%",
                      boxShadow:
                        "0 2px 0 rgb(88, 167, 0), 0 0 0 rgb(24, 153, 214)",
                    }}
                  >
                    Submit
                  </button>
                </>
              ) : (
                <>
                  {isAnswerSubmitted ? (
                    <></>
                  ) : (
                    <>
                      {questionType?.trim()?.toLowerCase() !== "default" ||
                      questionType?.trim()?.toLowerCase() === "routing_type" ? (
                        <>
                          <button
                            onClick={onContinue}
                            className="grow rounded-2xl border-b-4 border-green-600 bg-green-500 p-9 font-bold uppercase text-white sm:min-w-[150px] sm:max-w-[250px] sm:grow-0"
                            style={{
                              flex: "1 1 96%",
                              boxShadow:
                                "0 2px 0 rgb(88, 167, 0), 0 0 0 rgb(24, 153, 214)",
                            }}
                          >
                            Continue
                          </button>

                          {continuePopUp && (
                            <ContinuePopUp
                              isFeedbackPopupOpen={isFeedbackPopupOpen}
                              setIsFeedbackPopupOpen={setIsFeedbackPopupOpen}
                              onClickHandler={() => {
                                onContinue();
                                onContinuePopUp();
                              }}
                              textPrompt={
                                isFinalAssessmentError
                                  ? "Final Assessments are limited to once a month per language. We recommend exploring alternative practice assessments or waiting until the following month"
                                  : "Good job!"
                              }
                            />
                          )}
                        </>
                      ) : hasReachedMaxSubmissions === true ? (
                        <button
                          onClick={() => {
                            onContinue();
                            onContinuePopUp();
                          }}
                          className="grow rounded-2xl border-b-4 border-green-600 bg-green-500 p-9 font-bold uppercase text-white sm:min-w-[150px] sm:max-w-[250px] sm:grow-0"
                          style={{
                            flex: "1 1 75%",
                            marginRight: "10px",
                            boxShadow:
                              "0 2px 0 rgb(88, 167, 0), 0 0 0 rgb(24, 153, 214)",
                          }}
                        >
                          Continue
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            onSubmit(message);
                          }}
                          className="grow rounded-2xl border-b-4 border-grey-300 bg-gray-200 p-9 font-bold uppercase text-white sm:min-w-[150px] sm:max-w-[250px] sm:grow-0"
                          style={{
                            flex: "1 1 75%",
                            boxShadow: "0 4px 0 rgb(229,229,229)",
                          }}
                          disabled
                        >
                          {isSubmittingAnswer ? "Submitting..." : "Submit"}
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </section>
      {isFeedbackPopupOpen && (
        <ReportContentIssues
          isOpen={isFeedbackPopupOpen}
          setIsOpen={setIsFeedbackPopupOpen}
          questionFeedback={questionFeedback}
          setQuestionFeedback={setQuestionFeedback}
          setShowConfirmation={setShowConfirmation}
        />
      )}
    </>
  );
}
