import React, { useEffect, useState } from "react";
import CreateQuestions from "./CreateQuestions";
import noAvatar from "../../../static/no-avatar.png";
import NotificationIcon from "../../svgs/NotificationIcon";
import OptionsIcon from "../../svgs/OptionsIcon";
import Spinner from "../../spinner/Spinner";

const Questions = ({ db, currentUser }) => {
  const [isCreateQuestionsOpen, setCreateQuestionsOpen] = useState(false);
  const [questionsStack, setQuestionsStack] = useState(null);
  const [isViewQuestionsOpen, setViewQuestionsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isdataimported, setIsDataImported] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const getQuestionsStack = async () => {
      const quesSet = new Set(); // Set to store unique questions
      const nestriaQuesStack = db?.collection(`topics`);
      const quesStack = await nestriaQuesStack?.get();

      const newQuestions = [];

      quesStack?.forEach((doc) => {
        const documentData = doc?.data();
        const question = documentData?.question.trim();

        if (!quesSet.has(question)) {
          quesSet.add(question);

          const newQuestion = {
            question: question,
            status: documentData?.status,
            prompt: documentData?.prompt,
            notes: documentData?.notes,
            avatar: noAvatar,
            questionreference: doc?.ref,
            type: documentData?.type,
          };

          newQuestions.push(newQuestion);
        }
      });

      console.log("Questions length ", newQuestions.length);
      setQuestionsStack(newQuestions);
      // setQuestionsStack((prevStack) =>
      //   prevStack ? [...prevStack, ...newQuestions] : newQuestions
      // );
      setLoading(false);
    };

    getQuestionsStack();
  }, [isCreateQuestionsOpen, isViewQuestionsOpen, isdataimported]);

  if (questionsStack?.length > 0) {
    questionsStack.sort((a, b) => a.question.localeCompare(b.question));
  }

  const createQuestions = () => {
    setMessage("");
    setShowMessage(false);
    setCreateQuestionsOpen(true);
  };

  const viewQuestions = (question) => {
    console.log("selected questions it is", question);
    setMessage("");
    setShowMessage(false);
    setSelectedQuestion(question);
    setViewQuestionsOpen(true);
  };

  const handleFileUpload = async (event) => {
    setMessage("");
    setShowMessage(false);
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async () => {
      setLoading(true);
      const csvData = reader.result;
      const parsedData = parseCSV(csvData);

      try {
        for (const question of parsedData) {
          console.log("question", question);
          const questionData = {
            notes: question?.QNOTES,
            prompt: question?.prompt,
            question: question?.question,
            status: question?.QSTATUS,
            type: question?.QTYPE,
          };

          const questionDocRef = await db
            ?.collection("/topics")
            ?.add(questionData);
        }
        console.log("Data saved to the database successfully!");
        setMessage("Data imported successfully!");
        setShowMessage(true);
        setIsDataImported(true);
        setLoading(false);
      } catch (error) {
        console.error("Error saving data to the database:", error);
        setMessage("Error importing data. Please try again.");
        setShowMessage(true);
        setIsDataImported(false);
        setLoading(false);
      }
    };

    reader.readAsText(file);
  };

  const addQuestion = async (question) => {
    const questionData = {
      notes: "",
      prompt: question?.prompt,
      question: question?.question,
      status: question?.QSTATUS,
      ts: new Date(),
    };

    const existingQuestionSnapshot = await db
      .collection("/topics")
      .where("question", "==", question?.question)
      .get();

    if (!existingQuestionSnapshot.empty) {
      const existingQuestionRef = existingQuestionSnapshot.docs[0].ref;
      console.log("existingQuestionRef", existingQuestionRef);
      return existingQuestionRef;
    } else {
      const questionDocRef = await db.collection("/topics").add(questionData);
      console.log("questionDocRef", questionDocRef);
      return questionDocRef;
    }
  };

  const parseCSV = (csvData) => {
    const lines = csvData?.split("\n");
    const headers = lines[0]?.split(",");

    const parsedData = [];

    for (let i = 1; i < lines?.length; i++) {
      const currentLine = lines[i]?.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g);

      if (!currentLine || currentLine?.length === 0) {
        continue;
      }

      console.log("currentLine", currentLine);
      console.log("headers", headers);
      const rowData = {};

      if (currentLine?.length === headers?.length) {
        for (let j = 0; j < headers.length; j++) {
          const header = headers[j]?.replace(/[^\w\s]/gi, "");
          const value = currentLine[j]?.replace(/"/g, "");
          rowData[header] = value;
        }
        parsedData?.push(rowData);
      }
    }
    return parsedData;
  };

  return (
    <>
      <div className="ques-center-div transition-6">
        <div className="ques_main">
          <div className="user-questions">
            <div className="search_box_container">
              <input
                type="text"
                placeholder="Search Questions"
                fdprocessedid="7j5tol"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {showMessage && (
              <div className="message-container">
                <p className="message">{message}</p>
              </div>
            )}
            <div className="questions-btn">
              <div className="bn3">
                <label htmlFor="importFile" className="import-file-label">
                  Import Questions
                </label>
                <input
                  id="importFile"
                  type="file"
                  accept=".CSV"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
              </div>
              <div className="bn3" onClick={createQuestions}>
                <label>Create questions</label>
              </div>
            </div>
            <div className="questions-stack">
              {loading ? (
                <Spinner
                  width="6.25rem"
                  height="6.25rem"
                  overlayHeight="50vh"
                />
              ) : (
                questionsStack
                  ?.filter((question) =>
                    question.question
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  )
                  .map((question) => (
                    <div key={question.id} className="questions-card">
                      <div className="ques-avatar">
                        <img
                          src={question.avatar}
                          alt="roger"
                          height="25px"
                          width="25px"
                        />
                      </div>
                      <div className="tech-questions">{question.question}</div>

                      <div className="msg_notifs_option_btn">
                        <div
                          className="bn3"
                          onClick={() => viewQuestions(question)}
                        >
                          View
                        </div>
                        {/* <div className="notification_btn_container">
                          <NotificationIcon width="24" height="24" />
                        </div>
                        <div>
                          <OptionsIcon width="24" height="24" color="black" />
                        </div> */}
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>

        {(isCreateQuestionsOpen || isViewQuestionsOpen) && (
          <CreateQuestions
            db={db}
            currentUser={currentUser}
            setCreateQuestionsOpen={setCreateQuestionsOpen}
            selectedQuestion={selectedQuestion}
            isCreateQuestionsOpen={isCreateQuestionsOpen}
            isViewQuestionsOpen={isViewQuestionsOpen}
            setViewQuestionsOpen={setViewQuestionsOpen}
            setMessage={setMessage}
            setShowMessage={setShowMessage}
            questionsStack={questionsStack}
            setSelectedQuestion={setSelectedQuestion}
          />
        )}
      </div>
    </>
  );
};

export default Questions;
