import React from "react"
import { UpArrowSvg } from "../helpers/Svgs"

export const JumpToTopButton = () => {
    return (
        <>
        <div className="sticky bottom-27 left-0 right-0 flex items-end justify-bottom">        
            <button
            className="absolute right-4 flex h-14 w-14 items-center justify-center self-end rounded-2xl border-2 border-b-4 border-gray-200 bg-white transition hover:bg-gray-50 hover:brightness-90 md:right-0 mb-6"
            onClick={() =>
                document
                .getElementById("activeQuestionId")
                ?.scrollIntoView({
                    block: "center",
                    behavior: "auto",
                })
            }
            >
            <span className="sr-only">Jump to top</span>
                <UpArrowSvg />
            </button>
        </div>
    </>
    )
} 