import React from 'react';

const DeleteModal = ({
  header,
  subHeader,
  db,
  messageObj,
  setShowEditInput,
  setCurrentMessageId,
  setCurrentMsg,
  closeModal,
  truncateString
}) => {
  // removes a specific message obj
  const deleteMessage = async (e) => {
    e.preventDefault();

    await db.doc(messageObj?.ref?.path).delete();
    setShowEditInput(false);
    setCurrentMessageId('');
    setCurrentMsg('');
  };

  return (
    <>
      <div className='del_modal'>
        <p
          className='close_modal'
          onClick={closeModal}
        >
          X
        </p>
        <div className='confirm_del'>
          <h3>{header}</h3>
          <p>{subHeader} <b>{truncateString(messageObj?.msg, 50)}</b>?</p>
        </div>
        <div className='confirm_btn_container'>
          <button
            className='cancel_confirm_btn'
            onClick={closeModal}
          >
            No
          </button>
          <button
            className='confirm_btn'
            onClick={(e) => {
              deleteMessage(e);
              closeModal();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;