import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { useState } from 'react';
import { Button, DialogActions } from '@mui/material';



export default function StaticTimePickerLandscape({
  isShowClockIn,
  db,
  devsReportingReference,
  checkinDtObj,
  setTimeSheetData,
  setIsShowClockIn
}) {
    const [isShowEditTimePickerModal, setIsShowEditTimePickerModal] = useState(true);
    const [checkinTime, setCheckinTime] = useState(dayjs(checkinDtObj));

    const clockIn = async (e, checkinTime) => {
        setIsShowClockIn(false);
        console.log('checkinDate',checkinDtObj);
        e.preventDefault();
        const presenceDataMap = [
          { dateTime: checkinTime?.$d, type: "checkIn" }
        ];
        const clockInObj = {
          checkIn: checkinTime?.$d,
          presenceData: presenceDataMap
  
        };
        const clockInRef = await devsReportingReference.collection(`presence`).add(clockInObj);
        const docReferencePath = clockInRef?.path;
        const timesheetDt = await db.doc(clockInRef?.path)?.get();
        setTimeSheetData(timesheetDt);
  
    };
 
    return (
        <>
            {isShowClockIn && <div className="clockIn_timePicker_container">
                <div className="clockIn_timePicker_modal">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticTimePicker
                            ampm
                            orientation="landscape"
                            openTo="minutes"
                            value={checkinTime}
                            id="timePicker"
                            onChange={(newValue) => {
                                setCheckinTime(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            //componentsProps={{ actionBar: { actions: [ 'cancel', 'accept'] } }}
                            componentsProps={{ actionBar: { actions: [] } }}
                        />
                    </LocalizationProvider>


                    <DialogActions>
                        <Button onClick={() => {
                            setIsShowClockIn(false);
                        }}> Cancel </Button>
                        <Button onClick={(e) => clockIn(e, checkinTime)}>Set New Time </Button>
                    </DialogActions>

                </div>
            </div>}

        </>


    );
}