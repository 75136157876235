import React from 'react';

const ClockIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.8153 29.5254L24.4635 22.9859V11.7463C24.4635 11.2166 24.0301 10.7832 23.5004 10.7832C22.9707 10.7832 22.5373 11.2166 22.5373 11.7463V23.4963C22.5373 23.8141 22.6914 24.1031 22.9514 24.2861L32.7174 31.1146C32.8811 31.2301 33.0737 31.2879 33.2664 31.2879C33.5649 31.2879 33.8635 31.1434 34.0561 30.8641C34.3547 30.4307 34.2487 29.8336 33.8153 29.5254Z"
        fill="black"
      />
      <path
        d="M40.1041 6.89591C35.6545 2.44631 29.7602 0 23.5 0C17.2398 0 11.3455 2.44631 6.8959 6.89591C2.44631 11.3455 0 17.2398 0 23.5C0 29.7602 2.44631 35.6545 6.8959 40.1041C11.3455 44.5537 17.2398 47 23.5 47C29.7602 47 35.6545 44.5537 40.1041 40.1041C44.5537 35.6545 47 29.7602 47 23.5C47 17.2398 44.5537 11.3455 40.1041 6.89591ZM24.4631 45.0545V41.125C24.4631 40.5953 24.0297 40.1619 23.5 40.1619C22.9703 40.1619 22.5369 40.5953 22.5369 41.125V45.0545C11.4033 44.5633 2.43668 35.5967 1.94549 24.4631H5.875C6.40471 24.4631 6.83811 24.0297 6.83811 23.5C6.83811 22.9703 6.40471 22.5369 5.875 22.5369H1.94549C2.43668 11.4033 11.4033 2.43668 22.5369 1.94549V5.875C22.5369 6.40471 22.9703 6.83811 23.5 6.83811C24.0297 6.83811 24.4631 6.40471 24.4631 5.875V1.94549C35.5967 2.43668 44.5633 11.4033 45.0545 22.5369H41.125C40.5953 22.5369 40.1619 22.9703 40.1619 23.5C40.1619 24.0297 40.5953 24.4631 41.125 24.4631H45.0545C44.5633 35.5967 35.5967 44.5633 24.4631 45.0545Z"
        fill="black"
      />
    </svg>
  );
};

export default ClockIcon;