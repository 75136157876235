export const getSystemInfo = () => {
    return {
      browser: window.navigator.userAgent,
      os: window.navigator?.platform || "",
      device: /Mobi|Android/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop'
    };
}

export const getStateInfo = () => {
    return {
      currentUrl: window.location.href,
      referrerUrl: document.referrer,
      localStorage: { ...localStorage },
      sessionStorage: { ...sessionStorage },
      cookies: document.cookie
    };
  }
  