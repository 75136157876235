import React from 'react';

const TimeWorkedIcon = ({ width, height }) => {
  return(
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: '-0.25rem'}}
    >
      <path
        d="M6.19141 12.4019C7.08981 6.3662 11.246 1 20.0019 1C30.0019 1 34.0019 8 34.0019 15C34.0019 22 34.0019 28 30.0019 28"
        stroke="#4960E1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8711 19C25.8711 20.1045 24.9756 21 23.8711 21C22.7667 21 21.8711 20.1045 21.8711 19C21.8711 17.8955 22.7667 17 23.8711 17C24.9756 17 25.8711 17.8955 25.8711 19Z"
        fill="#4960E1"
      />
      <path
        d="M21.4375 27C23.1875 27 20.5625 27 23 27"
        stroke="#4960E1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.9991 49V46C38.9991 41.0059 33.9932 37 28.9991 37C25.6339 39.8027 22.1602 40.6934 18.9054 40.6934C18.2012 40.6934 17.4639 40.6279 16.8516 40.5532"
        stroke="#4960E1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3457 10.543C14.1553 13.8286 14.4473 13.3472 11 20.001"
        stroke="#4960E1"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 21.6025C8.25 17.2695 10.1094 13.7925 11.5313 12"
        stroke="#4960E1"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 49C3 40 5 31.5889 5 31.5889C5 31.5889 4.4141 30.8286 3 28C1 24 3 19 5 16"
        stroke="#4960E1"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 12C17.7168 14.5649 16.1738 17.4771 15 21"
        stroke="#4960E1"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0002 49C13.0051 48.4297 12.3029 39.5488 13.0002 34.9863C13.2668 33.2402 15.1916 32.6973 16.0002 31C17.9065 27 18.3342 23.1943 19.9367 17"
        stroke="#4960E1"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3262 8.7114C21.8369 8.1509 22.2324 7.5762 23 7C23 7 27 12.0005 30 15C30 15 31 17.0005 31 20.0005C31 29.0005 25.873 34.0005 19.9365 34.0005C19.7178 34.0005 19.377 33.9776 19 33.9443"
        stroke="#4960E1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TimeWorkedIcon;
