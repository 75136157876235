import React, { useState, useEffect } from "react";
import firebase, {
  addOrUpdateDocFromPath,
  getVapidKey,
} from "./utils/services";
import SpryteConnections from "./pages/index";
import "./styles/_style.scss";
import { getMessaging, getToken } from "firebase/messaging";
import { Mixpanel } from "./components/nestria/helpers/Mixpanel";

// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
// register Swiper custom elements
register();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isNestriaUserLoggedIn, setIsNestriaUserLoggedIn] = useState(false); // Locally keep track if the user is logged in or not. Relies on localStorage item "isNestriaUserLoggedIn"
  const [isNestria, setIsNestria] = useState(false);
  const [isNestriaAdminApp, setIsNestriaAdminApp] = useState(false);
  const [isNestriaStudentApp, setIsNestriaStudentApp] = useState(false);
  const [forceShowLogin, setForceShowLogin] = useState(false);

  // firebase app
  let app = firebase.app();
  // firebase auth
  const auth = app.auth();
  // firestore db
  const db = app.firestore();
  //User
  var user = firebase.auth().currentUser;

  // checks if the user is signed in or not
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      Mixpanel.identify(user?.uid);
      if (user?.uid) {
        db.doc(`users/${user?.uid}`)
          .get()
          .then((userDoc) => {
            const { first, last } = userDoc.data();
            Mixpanel.people.set({
              email: user?.email,
              first_name: first,
              last_name: last,
              name: `${first} ${last}`,
            });
          });
      }
      Mixpanel.track("Login", { status: "success" });
      localStorage.setItem("isNestriaUserLoggedIn", "true");
      setIsNestriaUserLoggedIn(true);
      db.collection("users").doc(user?.uid).update({
        "nestriaStats.last_login": new Date(),
      });
    } else {
      Mixpanel.track("Logout", { status: "success" });
      localStorage.clear("isNestriaUserLoggedIn");
      setIsNestriaUserLoggedIn(false);
      setForceShowLogin(true); // This is required sometimes when login screen won't show by itself
    }
    setIsLoggedIn(!!user);
    user = user;
  });

  function requestPermission() {
    console.log("Requesting permission...");
    Notification.requestPermission().then(async (permission) => {
      // console.log('permission', permission);
      if (permission === "granted") {
        console.log("Notification permission granted. Getting Token...");
        Mixpanel.identify(user?.uid);
        Mixpanel.people.set({ NotificationPermission: true });
        const messaging = getMessaging();
        // console.log("Got messaging: ", messaging);

        getToken(messaging, { vapidKey: getVapidKey() })
          .then(async (currentToken) => {
            // console.log("Token: ", currentToken);
            // Send the token to your server and update the UI if necessary

            // Save Token in user doc so app can send personalized messages
            // Each time the user connects, their latest token will be updated

            const currentUser = user;

            // if (currentUser) {
            // console.log("we have a user:", currentUser);
            const emailTypeRef = db.doc(
              "organisations/T6BAcTjwbXleCFpmWTmu/notificationChannels/EMAIL"
            );
            const msgTokenTypeRef = db.doc(
              "organisations/T6BAcTjwbXleCFpmWTmu/notificationChannels/PUSH"
            );

            const userRef = db.doc(`users/${currentUser?.uid}`);
            const userDoc = await userRef.get();
            const userData = userDoc?.data();
            // console.log("userData: ", userData);

            // loops through user's notification channel data,
            userData?.notificationChannelData?.map(async (data) => {
              // console.log("Data: ", data);
              // Figure out is the current MSG TOKEN in the DB is the same as this client's, and update if it's been more than a minute.
              if (data?.type?.id === msgTokenTypeRef?.id) {
                // console.log("Data.type: ", data?.type?.id);
                console.log(
                  "looping through, getting the right PUSH REF ITEM iN ARRAY. Done."
                );
                //Has it been more than a minute?
                const minusMin = new Date().getMinutes() - 1;
                const newT = new Date().setMinutes(minusMin);
                // console.log("time: ", newT);
                // console.log("timetype: ", newT.type);
                const tCompare = firebase.firestore.Timestamp.fromDate(
                  new Date(newT)
                );
                if (data?.lastUpdated <= tCompare) {
                  console.log("More than a Minute? Yes");
                  // is the current client token the same as the DB token?
                  if (data?.msgToken == currentToken) {
                    console.log(
                      "Current token same as DB token? YES. Do nothing."
                    );
                    //do nothing, user is on the same client still
                  } else {
                    console.log(
                      "Current token same as DB token? NO. Update DB"
                    );
                    // update the notificationChannelData field with generated msg token when user allows notification
                    const msgTokenChannelData = [
                      {
                        msgToken: currentToken,
                        type: msgTokenTypeRef,
                        lastUpdated: firebase.firestore.Timestamp.fromDate(
                          new Date()
                        ),
                      },
                    ];

                    //Remove the offending array item (old token)
                    const removeNotifsChanData = {
                      notificationChannelData:
                        firebase.firestore.FieldValue.arrayRemove(data),
                    };
                    await userRef.update(removeNotifsChanData);

                    //Save the new Token Data (entire notifChannel Array Item)
                    const updateMsgTokenChannel = {
                      notificationChannelData:
                        firebase.firestore.FieldValue.arrayUnion(
                          ...msgTokenChannelData
                        ),
                    };
                    await userRef.set(updateMsgTokenChannel, { merge: true });
                  }
                }
              }
            });

            //For dev debugging only -- remove to deploy
            // console.log("Current Token: ", currentToken);
            // Save Token in user doc so app can send personalized messages
            // Each time the user connects, their latest token will be updated
            // addOrUpdateDocFromPath('users/'+firebase.auth().currentUser.uid, {msgToken: currentToken}, true)

            // } else {
            //   // Show permission request UI
            //   console.log('No registration token available. Request permission to generate one.');
            //   // ...
            // }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            // ...
          });
      } else {
        Mixpanel.identify(user?.uid);
        Mixpanel.people.set({ NotificationPermission: false });
      }
    });
  }

  useEffect(() => {
    const isNestriaUserLoggedIn = localStorage.getItem("isNestriaUserLoggedIn");
    if (isNestriaUserLoggedIn === "true") {
      setIsNestriaUserLoggedIn(true);
      setTimeout(() => {
        // If firebase user is not detected in 2 seconds
        // reset the localStorage variable
        if (!firebase.auth().currentUser) {
          localStorage.clear("isNestriaUserLoggedIn");
          setIsNestriaUserLoggedIn(false);
        }
      }, 2000);
    } else {
      setIsNestriaUserLoggedIn(false);
    }

    const hostName = window.location.hostname;
    // const hostName = window.location.pathname;
    // nestria.localhost -> to access student app
    // admin.nestria.localhost -> to access admin app
    if (hostName.indexOf("nestria") !== -1) {
      setIsNestria(true);
      if (hostName.indexOf("admin") !== -1) {
        console.log(`Admin App Last release: Aug 22, 2023`);
        setIsNestriaAdminApp(true);
      } else {
        console.log(`Student App Last release: Aug 22, 2023`);
        setIsNestriaStudentApp(true);
      }
    }

    if (hostName.indexOf("localhost") !== -1) {
      localStorage.setItem("debugMode", "true");
    } else {
      localStorage.setItem("debugMode", "false");
    }
  }, []);

  // const requestIosPermission = async () => {
  //   const permission = await Notification.requestPermission();
  //   console.log({ permission });
  //   if (permission === 'default') {
  //     alert('Default notifs');
  //   } else if (permission === 'granted') {
  //     alert('Granted notifs');
  //   } else {
  //     alert('Denied notifs');
  //   }
  // };

  // const requestIosPermission = async (deviceToken, message) => {
  //   try {
  //     const messaging = getMessaging();
  //     const currentToken = await getToken(messaging, {vapidKey: getVapidKey()});

  //     const apnProvider = new apn.Provider({
  //       token: {
  //         key: '/path/to/APNsAuthKey_<YourKeyID>.p8',
  //         keyId: '<YourKeyID>',
  //         teamId: '<YourTeamID>'
  //       },
  //       production: false
  //     });

  //     const note = new apn.Notification();
  //     note.alert = message;
  //     note.sound = 'default';

  //     await apnProvider.send(note, deviceToken);
  //     console.log(`Push notification sent to ${deviceToken}`);

  //     const msgTokenChannelData = [
  //       {
  //         msgToken: currentToken,
  //         type: msgTokenTypeRef,
  //         lastUpdated: firebase.firestore.Timestamp.fromDate(new Date())
  //       }
  //     ];
  //     const updateMsgTokenChannel = {
  //       notificationChannelData: firebase.firestore.FieldValue.arrayUnion(...msgTokenChannelData)
  //     };
  //     await userRef.set(updateMsgTokenChannel, { merge: true });
  //   } catch (error) {
  //     console.error(`Error sending push notification: ${error}`);
  //   }
  // };

  // React.useEffect(() => {
  //   requestPermission();
  // }, [user]);

  React.useEffect(() => {
    if (navigator.userAgent.includes("iPhone")) {
      console.log("This is an iPhone");
      // requestIosPermission();
    } else {
      console.log("This is not an iPhone");
      const hostName = window.location.hostname;
      if (hostName.indexOf("nestria") === -1) {
        requestPermission(); // Request access if this is not Nestria
      }
    }
  }, [user]);

  return (
    <div className={isNestriaStudentApp ? "App-Nestria" : "App"}>
      <link rel="shortcut icon" href="astronaut.webp" />
      <link rel="manifest" href="./manifest.json" />
      <SpryteConnections
        forceShowLogin={forceShowLogin}
        isNestriaUserLoggedIn={isNestriaUserLoggedIn}
        firebase={firebase}
        isLoggedIn={isLoggedIn}
        app={app}
        auth={auth}
        db={db}
      />
      {/* <script>
      if ('serviceWorker' in navigator) {
        window.addEventListener('load', function() {
          navigator.serviceWorker.register('./service-worker.js').then(function(registration) {
            console.log('Worker registration successful', registration.scope);
            // askForMessagingNotificationPermission();
            requestPermission();
          }, function(err) {
            console.log('Worker registration failed', err);
          }).catch(function(err) {
            console.log(err);
          });
        })
      } else {
        console.log('Service Worker is not supported by browser.')
      }
    </script> */}
    </div>
  );
}

export default App;
