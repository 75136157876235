import React, { useContext, useState } from "react";
import logo from "../../static/nestria/astronaut.webp";
import { registerNewUser } from "../../utils/services";
import Spinner from "../spinner/Spinner";
import { Mixpanel } from "./helpers/Mixpanel";
import { DbContext, FirebaseContext } from "./Context/Nestria";

const NestriaRegister = ({
  setJustRegistered,
  registerVisible,
  setLoginVisible,
  setRegisterVisible,
}) => {
  const db = useContext(DbContext);
  const firebase = useContext(FirebaseContext);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("Nestria");
  const [website, setWebsite] = useState("www.nestria.org");
  const [industry, setIndustry] = useState("EdTech");
  const [numEmployees, setNumEmployees] = useState("50");
  const [step, setStep] = useState(1);
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [error, setError] = useState(""); // use this to display error to user while registration
  const [loading, setLoading] = useState(false);
  const [phoneInputError, setPhoneInputError] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [emailInputError, setEmailInputError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [numEmployeesInputError, setNumEmployeesInputError] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [fetchedCompanyData, setFetchedCompanyData] = useState();
  const [createCompany, setCreateCompany] = useState(false);
  const [websiteInputError, setWebsiteInputError] = useState(false);
  const [websiteInputErrorMsg, setWebsiteInputErrorMsg] = useState("");
  const [isChecked, setIsChecked] = useState(true); // Keep it checked by default
  // const personalEmailWarning =
  //   "Spryte works best with your work email. You can still create an account with a personal email, but you won't get access to company-features.";
  const genericMailProviders = [
    "gmail.com",
    "googlemail.com",
    "outlook.com",
    "hotmail.com",
    "protonmail.com",
    "aol.com",
    "aim.com",
    "zoho.com",
    "icloud.com",
    "yahoo.com",
    "gmx.com",
    "tutanota.com",
    "tuta.com",
    "keemail.com",
    "yandex.com",
  ];

  const registerNestriaUser = async () => {
    setLoading(true);
    if (confirmationPassword != "" && password !== confirmationPassword) {
      console.log("password", password);
      console.log("confirmationPassword", confirmationPassword);

      setError("These passwords do not match.");
      setLoading(false);
    } else if (password.length < 6) {
      setError("Password should be at least 6 characters long.");
      setLoading(false);
    } else {
      const registrationData = {
        email: email,
        password: password,
        phone: phone,
        first: first,
        last: last,
        numEmployees: numEmployees,
        industry: industry,
        website: website,
        companyName: companyName,
        companyRef: fetchedCompanyData?.ref ?? null,
        createCompany: false, // Don't create company for Nestria users,
      };
      setJustRegistered(true);
      const isNestriaUser = true;
      var x = await registerNewUser(registrationData, isNestriaUser);

      console.log("variable x ", x);

      if (x["error"] != null) {
        setLoading(false);
        setError(x["error"]);
        Mixpanel.track("Error Signing Up", { errorMsg: x["error"] });
        setJustRegistered(false);
      } else {
        try {
          setLoading(true);
          const userRef = db?.doc(`users/${x.uid}`);
          Mixpanel.track("New Sign Up", {});
          Mixpanel.identify(x?.uid);
          console.log("Track Analytics", userRef);
          console.log("${x}", x);
          const nestriaCatalogStack = db?.collection(`catalog`);
          const catalogStack = await nestriaCatalogStack?.get();
          catalogStack?.forEach(async (catalogDoc) => {
            try {
              const catalogData = catalogDoc?.data();
              const catalogId = catalogDoc?.id;

              if (catalogData?.name === "Orientation") {
                const unitsSubcollection = await catalogDoc?.ref
                  ?.collection("units")
                  ?.orderBy("index", "asc")
                  ?.get();

                const sortedUnitDocs = unitsSubcollection?.docs;

                for (let i = 0; i < sortedUnitDocs?.length; i++) {
                  let unitDoc = sortedUnitDocs[i];
                  try {
                    console.log("Unit document", unitDoc);
                    const unitData = unitDoc?.data();
                    const unitName = unitData?.name;

                    console.log("unitName", unitName);

                    await db.collection("careerPrep").add({
                      name: unitName,
                      show: true,
                      source: unitDoc?.ref,
                      ts: firebase.firestore.Timestamp.fromDate(new Date()),
                      userRef: userRef,
                    });
                  } catch (error) {
                    console.error("Error processing unit document", error);
                  }
                }

                setLoading(false);
              }
            } catch (error) {
              setLoading(false);
              console.error("Error accessing units subcollection", error);
            }
          });
        } catch (error) {
          console.error("Error retrieving units", error);
        }
      }
    }
  };

  const resetCompanyForm = () => {
    setFetchedCompanyData(null);
    setCompanyName("Nestria");
    setWebsite("www.nestria.org");
    setIndustry("EdTech");
    setNumEmployees("50");
    setWebsiteInputError(false);
    setWebsiteInputErrorMsg("");
  };

  const assignDefaultUnit = async (uid) => {
    const _DEFAULT_UNITS = [
      {
        name: "",
        reference: "",
      },
    ];
    const currentUserRef = db.doc(`users/${uid}`);
    for (let unit in _DEFAULT_UNITS.map) {
      await db.collection("careerPrep").add({
        name: unit.name,
        show: true,
        source: unit.reference,
        ts: new Date(),
        userRef: currentUserRef,
      });
    }
  };

  const fetchCompanyData = async () => {
    const company = await db
      .collection("companies")
      .where("crm.data.properties.domain.value", "==", email.split("@")[1])
      .get();
    if (company.docs.length) {
      var companyData = company?.docs?.[0]?.data()?.crm?.data?.properties;
      setCreateCompany(false);
      setFetchedCompanyData({
        ...(company?.docs?.[0]?.data() ?? {}),
        ref: company.docs[0].ref,
      });
      setCompanyName(companyData?.name?.value ?? "");
      setWebsite(companyData?.domain?.value ?? "");
      setIndustry(companyData?.industry?.value ?? "");
      setNumEmployees(companyData?.numberofemployees?.value ?? "0");
    } else {
      setCreateCompany(true);
      resetCompanyForm();
    }
  };

  const validateSteps = () => {
    if (step === 1) {
      email !== "" && !genericMailProviders.includes(email.split("@")[1])
        ? fetchCompanyData()
        : resetCompanyForm();
      if (
        first !== "" &&
        last !== "" &&
        email !== "" &&
        phone !== "" &&
        !emailInputError &&
        !phoneInputError
      ) {
        setStep(3);
      } else {
        setStep(1);
      }
    }
    if (step === 2) {
      if (
        (companyName !== "" &&
          website !== "" &&
          industry !== "" &&
          numEmployees !== "" &&
          !numEmployeesInputError &&
          !websiteInputError) ||
        !createCompany
      ) {
        setStep(3);
      } else {
        setStep(2);
      }
    }
  };

  // num values
  const checkVal = (val) => {
    if (!/^\d+$/.test(val) && val !== "") {
      setError("Please enter a valid value.");
    } else {
      setError("");
    }
  };

  // validates email addresses
  const checkEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email) && email !== "") {
      setEmailErrorMessage("Please enter a valid email address.");
    } else {
      setEmailErrorMessage("");
    }
  };

  // checks if password and confirmPassword match
  const checkPasswordMatch = (value) => {
    setPasswordMatch(password === value || value === "");
  };

  const checkPhoneNumber = (phone) => {
    const phoneRegex =
      /^\+?[0-9]{1,3}[ -]?\(?[0-9]{1,3}\)?[ -]?[0-9]{3,4}[ -]?[0-9]{4}$/;
    if (!phoneRegex.test(phone) || phone === "") {
      setPhoneErrorMessage("Please enter a valid 10 digit phone number.");
      setPhoneInputError(true);
    } else {
      setPhoneErrorMessage("");
      setPhoneInputError(false);
    }
  };

  return (
    <React.Fragment>
      {registerVisible && (
        <div className="register_container">
          <div className="register_contents">
            <div className="logo_container">
              <img src={logo} alt="Nestria logo" />
            </div>
            {/* <h3 className='spryte'>Nestria</h3> */}
            <h2>{step !== 3 ? "Sign Up" : "Almost there!"}</h2>
            {step !== 3 && (
              <h3
                className="input_container"
                style={{ color: "white", textAlign: "center" }}
              >
                Practice for Interviews and get assessed for your dream job.
              </h3>
            )}
            {step === 1 ? (
              <>
                <div className="input_container">
                  <label htmlFor="firstName">
                    First Name<span>*</span>
                  </label>
                  <input
                    onChange={(event) => {
                      setFirst(event.target.value);
                    }}
                    id="firstName"
                    placeholder="John"
                    autoComplete="given-name"
                    value={first}
                    required
                  />
                </div>
                <div className="input_container">
                  <label htmlFor="lastName">
                    Last Name<span>*</span>
                  </label>
                  <input
                    onChange={(event) => {
                      setLast(event.target.value);
                    }}
                    id="lastName"
                    placeholder="Doe"
                    autoComplete="family-name"
                    value={last}
                    required
                  />
                </div>
                <div className="input_container">
                  <label htmlFor="email">
                    Email<span>*</span>
                  </label>
                  <input
                    style={{
                      backgroundColor: emailInputError ? "#ffcccb" : "",
                    }}
                    onChange={(event) => {
                      setEmail(event.target.value);
                      setCreateCompany(
                        genericMailProviders.includes(
                          event.target.value.split("@")[1]
                        )
                      );
                      checkEmail(event.target.value);
                      setEmailInputError(
                        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                          event.target.value
                        ) && event.target.value !== ""
                      );
                    }}
                    id="email"
                    placeholder="contact@example.com"
                    autoComplete="email"
                    value={email}
                  />
                  {emailErrorMessage !== "" ? (
                    <p className="error">{emailErrorMessage}</p>
                  ) : null}
                  {genericMailProviders.includes(email.split("@")[1])}
                </div>
                <div className="input_container">
                  <label htmlFor="phone">
                    Phone<span>*</span>
                  </label>
                  <input
                    type="number"
                    style={{
                      backgroundColor: phoneInputError ? "#ffcccb" : "",
                    }}
                    onChange={(event) => {
                      setPhone(event.target.value);
                      checkPhoneNumber(event.target.value);
                      setPhoneInputError(
                        !/^\+?[0-9]{1,3}[ -]?\(?[0-9]{1,3}\)?[ -]?[0-9]{3,4}[ -]?[0-9]{4}$/.test(
                          event.target.value
                        ) && event.target.value !== ""
                      );
                    }}
                    id="phone"
                    placeholder="Phone Number"
                    autoComplete="tel-national"
                    value={phone}
                  />
                  {phoneErrorMessage !== "" ? (
                    <p className="error">{phoneErrorMessage}</p>
                  ) : null}
                </div>
              </>
            ) : step === 2 ? (
              <>
                <div className="input_container">
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    onChange={(event) => setCompanyName(event.target.value)}
                    id="companyName"
                    placeholder="Facebook"
                    name="username"
                    autoComplete="off"
                    value={companyName}
                    disabled={!createCompany}
                  />
                </div>
                <div className="input_container">
                  <label htmlFor="companyWebsite">Company Website</label>
                  <input
                    style={{
                      backgroundColor: websiteInputError ? "#ffcccb" : "",
                    }}
                    onBlur={(event) => {
                      setWebsiteInputError(
                        event.target.value.match(
                          /^[a-z\d]([a-z\d\-]{0,61}[a-z\d])?(\.[a-z\d]([a-z\d\-]{0,61}[a-z\d])?)*$/
                        ) == null
                      );
                    }}
                    onChange={(event) => setWebsite(event.target.value)}
                    id="companyWebsite"
                    placeholder="facebook.com"
                    name="password"
                    value={website}
                    disabled={!createCompany}
                  />
                  {websiteInputError && (
                    <p className="error">{websiteInputErrorMsg}</p>
                  )}
                </div>
                <div className="input_container">
                  <label htmlFor="industry">Domain/Industry</label>
                  <input
                    onChange={(event) => setIndustry(event.target.value)}
                    id="industry"
                    placeholder="Social Media"
                    name="password"
                    value={industry}
                    disabled={!createCompany}
                  />
                </div>
                <div className="input_container">
                  <label htmlFor="numOfEmployees"># of Employees</label>
                  <input
                    style={{
                      backgroundColor: numEmployeesInputError ? "#ffcccb" : "",
                    }}
                    onChange={(event) => {
                      setNumEmployees(event.target.value);
                      checkVal(event.target.value);
                      setNumEmployeesInputError(
                        !/^\d+$/.test(event.target.value) &&
                          event.target.value !== ""
                      );
                    }}
                    id="numOfEmployees"
                    placeholder="5500"
                    name="password"
                    value={numEmployees}
                    disabled={!createCompany}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="input_container">
                  <label htmlFor="password">Password</label>
                  <input
                    onChange={(event) => {
                      setPassword(event.target.value);
                      checkPasswordMatch(event.target.value);
                    }}
                    id="password"
                    placeholder="Enter your password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                  />
                </div>
                <div className="input_container">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    onChange={(event) => {
                      setConfirmationPassword(event.target.value);
                      checkPasswordMatch(event.target.value);
                    }}
                    id="confirmPassword"
                    placeholder="Confirm Your Password"
                    type="password"
                    autoComplete="new-password"
                    value={confirmationPassword}
                  />
                  {error !== "" ? <p className="error">{error}</p> : null}
                  {!passwordMatch && confirmationPassword !== "" && (
                    <p className="error">Passwords do not match.</p>
                  )}
                </div>
              </>
            )}

            {step === 3 && (
              <div className="agreement_container">
                <div style={{ display: "flex" }}>
                  {/* <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  /> */}
                  <p>
                    By creating an account you agree to the terms of use and our
                    privacy policy.
                  </p>
                </div>
              </div>
            )}
            <div className="button_container">
              {step === 2 || step === 3 ? (
                <div
                  onClick={() => {
                    setStep(step === 3 ? 1 : 3);
                  }}
                  className="back_btn"
                >
                  <h3>&#8810;</h3>
                </div>
              ) : null}
              {step === 1 || step === 2 ? (
                <button
                  onClick={() => validateSteps()}
                  style={{
                    opacity: (
                      step === 1
                        ? first === "" ||
                          last === "" ||
                          email === "" ||
                          phone === "" ||
                          phoneInputError ||
                          emailInputError
                        : step === 2
                        ? createCompany
                          ? companyName === "" ||
                            website === "" ||
                            industry === "" ||
                            numEmployees === "" ||
                            numEmployeesInputError ||
                            websiteInputError
                          : createCompany
                        : password === "" || confirmationPassword === ""
                    )
                      ? ".5"
                      : "1",
                    cursor: (
                      step === 1
                        ? first === "" ||
                          last === "" ||
                          email === "" ||
                          phone === "" ||
                          phoneInputError ||
                          emailInputError
                        : step === 2
                        ? createCompany
                          ? companyName === "" ||
                            website === "" ||
                            industry === "" ||
                            numEmployees === "" ||
                            numEmployeesInputError ||
                            websiteInputError
                          : createCompany
                        : password === "" || confirmationPassword === ""
                    )
                      ? "default"
                      : "pointer",
                  }}
                  className={step === 1 ? "step1_btn" : "step2_btn"}
                >
                  Next Step
                </button>
              ) : !loading ? (
                <button
                  onClick={() => {
                    if (
                      isChecked &&
                      !numEmployeesInputError &&
                      !emailInputError &&
                      !phoneInputError &&
                      password !== "" &&
                      confirmationPassword !== ""
                    )
                      registerNestriaUser();
                  }}
                  style={{
                    opacity:
                      first === "" ||
                      last === "" ||
                      email === "" ||
                      phone === "" ||
                      password === "" ||
                      confirmationPassword === "" ||
                      error !== "" ||
                      !passwordMatch ||
                      !isChecked
                        ? "0.5"
                        : "1",
                    cursor:
                      password === "" ||
                      confirmationPassword === "" ||
                      error !== "" ||
                      !passwordMatch ||
                      !isChecked
                        ? "default"
                        : "pointer",
                  }}
                  className={step === 3 && "step3_btn"}
                >
                  Submit
                </button>
              ) : (
                <Spinner
                  width="2rem"
                  height="2rem"
                  overlayHeight="2rem"
                  margin="0 35%"
                />
              )}
            </div>
            <p className="sign_in">
              Already have an account?{" "}
              <span
                onClick={() => {
                  setLoginVisible(true);
                  setRegisterVisible(false);
                }}
              >
                Log In
              </span>
            </p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default NestriaRegister;
