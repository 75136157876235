import React, { useContext, useState } from 'react';
import { CloseSvg } from '../helpers/Svgs';
import firebase, { db } from '../../../utils/services';
import { AssessmentModeContext, CurrentUserContext } from '../Context/Nestria';
import { assignFinalAssessment, assignPracticeCourse } from '../helpers/assignNextAssessment';

const SubjectSelector = ({ 
  subjectIcons, 
  onClose, 
  assignedUserCourses,
  setMenu, 
  allNestriaSubjects,
}) => {
  const currentUserObj = useContext(CurrentUserContext);
  const { setActiveSubject, mode } = useContext(AssessmentModeContext);
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);

  const unassignedSubjects = allNestriaSubjects.filter(subject => !assignedUserCourses.includes(subject));

  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
  }

  const handleContinue = async () => {
    if (selectedSubject) {
      setActiveSubject(selectedSubject);
      setMenu('HIDDEN');
      // Additional logic to assign units or perform other actions based on the selectedSubject
    }
  }
  

  return (
    <div style={{ height: "100vh", overflowY: "scroll", background: "white", position: 'relative' }}>
      <div className="subject-selector-container fixed top-1 w-full flex justify-center p-4 bg-white border-b-2 b-gray-400">
        <span style={{fontWeight:"bold", fontSize:"18px"}}>Units</span>
      </div>
      <div className='ss-close-btn'>
        <button
          className="text-gray p-2"
          onClick={() => onClose(false)}
        >
          <CloseSvg />
        </button>
        
      </div>
      <div className="subject-selector-container flex flex-col items-center justify-center w-screen p-4 box-border" style={{ marginTop: "60px", paddingBottom: "80px" }}>
        {unassignedSubjects.map((subject, index) => (
          <div 
            key={index} 
            className="subject-selector-container w-full flex items-center justify-between p-4 rounded-xl cursor-pointer" 
            style={{ 
              borderWidth: '2px 2px 4px 2px', 
              marginBottom: '30px', 
              height: '60px', 
              borderColor: selectedSubject === subject ? 'orange' : '#AFAFAF'
            }}
            onClick={() => handleSubjectClick(subject)}
          >
            <img src={subjectIcons[subject]} alt={subject} style={{ height: '40px', width: '60px', border:'1px solid #AFAFAF', borderRadius:"10px" }} />
            <span className="subject-selector-container text-grey-400 font-bold" style={{ flex: 1, textAlign: 'left', paddingLeft:"20px" }}>
              {subject}
            </span>
          </div>
        ))}
      </div>
      <div className="subject-selector-container fixed bottom-0 w-full flex justify-center p-4 bg-white" style={{paddingBottom:"30px"}}>
        <button
          onClick={handleContinue}    
          className="w-full border-b-2 rounded-2xl p-3 font-bold uppercase text-white"
          style={{
            backgroundColor: selectedSubject ? '#58cc02' : '#AFAFAF',
            borderColor: selectedSubject ? '#58cc02' : '#AFAFAF'
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default SubjectSelector;
