import React, { useEffect, useState } from 'react';

const NotificationModal = ({
  firebase,
  db,
  connection,
  notificationFreq,
  notificationChannel
}) => {
  const [freqDescription, setFreqDescription] = useState(false);
  const [freqId, setFreqId] = useState(null);

  // adds selected connection obj to the channels data
  const notificationChannelData = notificationChannel?.map(channelData => {
    return { channelData, notificationConnObj: connection };
  });

  const handleCheckBox = async (e, freq, channel) => {
    const { checked } = e.target;

    const freqName = freq?.name?.toUpperCase();
    const channelRef = channel?.channelData?.ref;
    const connectionRef = channel?.notificationConnObj?.connectionRef?.path;

    // connection frequency data for adding a specific channel to the frequency map
    const addFrequencyData = {
      frequency: { 
        [freqName]: firebase.firestore.FieldValue.arrayUnion(channelRef)
      },
      updatedOn: new Date()
    };

    // connection frequency data for removing a specific channel from the frequency map
    const removeFrequencyData = {
      [`frequency.${freqName}`]: firebase.firestore.FieldValue.arrayRemove(channelRef),
      updatedOn: new Date()
    };

    if (checked) {
      await db.doc(connectionRef).set(addFrequencyData, { merge: true });
    } else {
      await db.doc(connectionRef).update(removeFrequencyData);
    }
  };

  return(
    <>
      <div className='notification_modal_container'>
        <div className='notification_modal'>
          <div className='notifications_contents'>
            <div className='notification_header'>
              <h3>Frequency</h3>
              <h3>Channels</h3>
            </div>
            {notificationFreq &&
              notificationFreq?.map(freq => {
                return(
                  <div
                    className='notification_data'
                    key={freq?.rank}
                  >
                    <div
                      className='frequency'
                      onMouseOver={() => {
                        setFreqDescription(true);
                        setFreqId(freq?.rank)
                      }}
                      onMouseOut={() => {
                        setFreqDescription(false);
                        setFreqId(null)
                      }}
                    >
                      <p>
                        {freq?.name}
                      </p>
                      {(freqDescription && freqId === freq?.rank) &&
                        <div className='freq_description'>
                          <span className='description'>{freq?.desc}</span>
                        </div>
                      }
                    </div>
                    <>
                      {notificationChannelData &&
                        notificationChannelData?.map(chan => {
                          const channelFreq = chan?.notificationConnObj?.frequency;

                          const realtimeEmailId = channelFreq?.REALTIME?.map(realtimeId => realtimeId?.id);
                          const dailyEmailId = channelFreq?.DAILY?.map(dailyId => dailyId?.id);
                          const weeklyEmailId = channelFreq?.WEEKLY?.map(weeklyId => weeklyId?.id);
                          const monthlyEmailId = channelFreq?.MONTHLY?.map(quarterlyId => quarterlyId?.id);
                          const quarterlyEmailId = channelFreq?.QUARTERLY?.map(quarterlyId => quarterlyId?.id);
                          const yearlyEmailId = channelFreq?.YEARLY?.map(yearlyId => yearlyId?.id);
                          const emailId = 'Email';

                          const realtimePushId = channelFreq?.REALTIME?.map(realtimeId => realtimeId?.id);
                          const dailyPushId = channelFreq?.DAILY?.map(dailyId => dailyId?.id);
                          const weeklyPushId = channelFreq?.WEEKLY?.map(weeklyId => weeklyId?.id);
                          const monthlyPushId = channelFreq?.MONTHLY?.map(monthlyId => monthlyId?.id);
                          const quarterlyPushId = channelFreq?.QUARTERLY?.map(quarterlyId => quarterlyId?.id);
                          const yearlyPushId = channelFreq?.YEARLY?.map(yearlyId => yearlyId?.id);
                          const pushId = 'Push';
  
                          const emailFreqLabel = [];
                          const pushFreqLabel = [];

                          if (realtimeEmailId?.includes('EMAIL')) {
                            emailFreqLabel.push('Realtime');
                          };
                          if (dailyEmailId?.includes('EMAIL')) {
                            emailFreqLabel.push('Daily');
                          };
                          if (weeklyEmailId?.includes('EMAIL')) {
                            emailFreqLabel.push('Weekly');
                          };
                          if (monthlyEmailId?.includes('EMAIL')) {
                            emailFreqLabel.push('Monthly');
                          };
                          if (quarterlyEmailId?.includes('EMAIL')) {
                            emailFreqLabel.push('Quarterly');
                          };
                          if (yearlyEmailId?.includes('EMAIL')) {
                            emailFreqLabel.push('Yearly');
                          };

                          {realtimePushId?.includes('PUSH') && pushFreqLabel.push('Realtime')}
                          {dailyPushId?.includes('PUSH') && pushFreqLabel.push('Daily')}
                          {weeklyPushId?.includes('PUSH') && pushFreqLabel.push('Weekly')}
                          {monthlyPushId?.includes('PUSH') && pushFreqLabel.push('Monthly')}
                          {quarterlyPushId?.includes('PUSH') && pushFreqLabel.push('Quaterly')}
                          {yearlyPushId?.includes('PUSH') && pushFreqLabel.push('Yearly')}

                          return(
                            <div
                              className='channel'
                              key={chan?.channelData?.name}
                            >
                              <input
                                type='checkbox'
                                name='channel'
                                value={chan?.channelData?.name}
                                defaultChecked={emailFreqLabel?.includes(freq?.name) & chan?.channelData?.name === emailId ||
                                  pushFreqLabel?.includes(freq?.name) & chan?.channelData?.name === pushId ||
                                  (emailFreqLabel?.includes(freq?.name) & chan?.channelData?.name === emailId &
                                  pushFreqLabel?.includes(freq?.name) & chan?.channelData?.name === pushId)}
                                onChange={(e) => {
                                  handleCheckBox(e, freq, chan);
                                }}
                              />
                              <label>{chan?.channelData?.name}</label>
                            </div>
                          )
                        })
                      }
                    </>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationModal;
