import { assignUnitToUser } from "./utils";

export const assignNextAssessment = async (
  db,
  subject,
  isFinal,
  currentUserRef,
  firebase
) => {
  subject = subject.toLowerCase();
  console.log("  subject  ", subject);
  console.log("  isFinal  ", isFinal);
  console.log("  currentUserRef  ", currentUserRef);

  try {
    if (isFinal) {
      await assignFinalAssessment(db, subject, currentUserRef, firebase);
    } else {
      await assignPracticeCourse(db, subject, currentUserRef, firebase);
    }
  } catch (error) {
    console.error("Error assigning next assessment:", error);
    return Promise.reject(error);
  }
};

export const assignPracticeCourse = async (db, subject, currentUserRef, firebase) => {
  try {
    
    const catalogRef = db.collection("catalog");
    const subjectQuery = catalogRef
      .where("subject", "==", subject)
      .where("isFinalAssessment", "==", false);
    const subjectSnapshot = await subjectQuery.get();
    const availableCourses = subjectSnapshot.docs.map((courseDoc) => ({
      ...courseDoc.data(),
      reference: courseDoc.ref.id,
    }));

    console.log("Available Practice Assessments:", availableCourses);

    const userUnitsQuery = db
      .collection("careerPrep")
      .where("userRef", "==", currentUserRef);
    const unitsSnapshot = await userUnitsQuery.get();

    const userAssignedCourses = new Set();
    const promises = [];

    unitsSnapshot.docs.forEach((unitDoc) => {
      const unitData = unitDoc.data();
      const unitSource = unitData.source;

      if (unitSource) {
        promises.push(
          (async () => {
            try {
              const unitParentDoc = await unitSource.parent.parent.get();

              if (unitParentDoc.exists) {
                userAssignedCourses.add(unitParentDoc.ref.id);
              }
            } catch (error) {
              // console.error("Error fetching unit parent document:", error);
            }
          })()
        );
      }
    });

    await Promise.all(promises);

    const userCourseIDs = Array.from(userAssignedCourses);
    const userCoursesPromises = userCourseIDs.map(async (courseID) => {
      const courseDoc = await catalogRef.doc(courseID).get();
      return {
        ...courseDoc.data(),
        reference: courseDoc.ref.id,
      };
    });

    const userCourses = await Promise.all(userCoursesPromises);
    console.log("User Assigned Courses:", userCourses);

    const unassignedCourses = availableCourses
      .filter(
        (course) =>
          !userCourses.some(
            (userCourse) => userCourse.reference === course.reference
          )
      )
      .sort((a, b) => a.createdOn - b.createdOn); // Sort based on the "createdOn" timestamp

    console.log("Sorted Unassigned Practice Courses:", unassignedCourses);

    if (unassignedCourses.length > 0) {
      const nextUnassignedCourse = unassignedCourses[0];

      console.log(
        "Next sequentially picked unassigned practice course: ",
        nextUnassignedCourse
      );

      if (nextUnassignedCourse) {
        try {
          const courseReference = nextUnassignedCourse.reference;

          const courseDoc = await catalogRef.doc(courseReference).get();
          const courseUnitRef = await courseDoc.ref
            .collection("units")
            .orderBy("index", "asc")
            .get();

          if (!courseUnitRef.empty) {
            let unitCounter = 0;
            for (let unitDoc of courseUnitRef.docs) {
              let unitData = unitDoc.data();
              let unitReference = unitDoc?.ref;
              let isLastUnitOfTheCourse = false;

              if (unitCounter === courseUnitRef.docs.length - 1) {
                isLastUnitOfTheCourse = true;
              }

              await assignUnitToUser(
                db,
                { name: unitData?.name, reference: unitReference },
                currentUserRef,
                isLastUnitOfTheCourse,
                firebase
              );
              unitCounter += 1;
            }
          }
          
        } catch (error) {
          console.error("Error processing practice course and units:", error);
          
          // Handle the error as needed
        }
        console.log("Practice assessment assigned to the user successfully");
        
      } else {
        console.warn("No unassigned course available for the ", { subject });
        
      }
    } else {
      console.log("No unassigned practice courses available.");
      
    }
  } catch (error) {
    console.error("Error assigning practice assessment:", error);
    
  }
};

export const assignFinalAssessment = async (db, subject, currentUserRef, firebase) => {
  try {
    
    const catalogRef = db.collection("catalog");
    const finalCoursesQuery = catalogRef
      .where("subject", "==", subject)
      .where("isFinalAssessment", "==", true);
    const finalCoursesSnapshot = await finalCoursesQuery.get();

    const availableFinalCoursesID = finalCoursesSnapshot.docs.map(
      (doc) => doc.ref.id
    );
    console.log(
      "All available Final Assessments for subject: ",
      subject,
      availableFinalCoursesID
    );

    const userQuestionRef = db
      .collection("careerPrep")
      .where("userRef", "==", currentUserRef);
    const questionSnapshot = await userQuestionRef.get();

    const userQuestions = [];
    const userCourseID = [];

    questionSnapshot.docs.forEach((doc) => {
      const questionData = doc.data();
      const questionSource = questionData.source.parent;

      if (questionSource && questionSource.parent) {
        const parentCollectionID = questionSource.parent.id;
        const questionTS = questionData.ts;

        userQuestions.push({
          questionData: questionData,
          questionSource: questionSource,
          parentCollectionID: parentCollectionID,
          questionTS: questionTS,
        });

        userCourseID.push(parentCollectionID);
      }
    });

    const userFinalCoursesData = {};

    for (const question of userQuestions) {
      const courseID = question.parentCollectionID;
      const userCourseSnapshot = await catalogRef.doc(courseID).get();

      if (userCourseSnapshot.exists) {
        const courseData = userCourseSnapshot.data();
        if (availableFinalCoursesID.includes(courseID)) {
          if (!(courseID in userFinalCoursesData)) {
            userFinalCoursesData[courseID] = question.questionTS;
          }
        }
      }
    }

    console.log(
      "User Assigned Final Assessments for subject: ",
      subject,
      userFinalCoursesData
    );

    function hasAssessmentInLastMonth(userCoursesData) {
      const currentDate = new Date();
      for (const [courseID, timestamp] of Object.entries(userCoursesData)) {
        const courseTimestamp = new Date(
          timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6
        );
        // Check if the assessment was assigned in the current month
        if (
          courseTimestamp.getFullYear() === currentDate.getFullYear() &&
          courseTimestamp.getMonth() === currentDate.getMonth()
        ) {
          console.warn(
            `No new assessments can be assigned for user: ${currentUserRef} for the subject ${subject} until the end of the month.`
          );
          return true;
        }
      }
      return false;
    }

    const assessmentAssignedInLast30Days =
      hasAssessmentInLastMonth(userFinalCoursesData);

    if (!assessmentAssignedInLast30Days) {
      const eligibleFinalCourseIDs = availableFinalCoursesID.filter(
        (courseID) =>
          !(courseID in userFinalCoursesData)
      );

      if (eligibleFinalCourseIDs.length > 0) {
        const eligibleFinalCourses = [];
        for (const courseID of eligibleFinalCourseIDs) {
          const courseDocRef = db.collection('catalog').doc(courseID);
          await courseDocRef.get().then((doc) => {
            if (doc.exists) {
              eligibleFinalCourses.push(doc);
            }
          });
        }
        const sortedEligibleFinalCourses = eligibleFinalCourses.sort(
          (a, b) => a.data().createdOn - b.data().createdOn
        );
        console.log(
          "Sorted eligible Final Courses:",
          sortedEligibleFinalCourses
        );
        const nextEligibleFinalCourse = sortedEligibleFinalCourses[0];
        console.log(
          "Next sequentially picked unassigned final course: ",
          nextEligibleFinalCourse?.ref?.id
        );

        const courseReference = nextEligibleFinalCourse?.ref?.id;
        if (courseReference) {
          const courseDoc = await catalogRef.doc(courseReference).get();
          const unitCollectionRef = courseDoc.ref.collection("units");
          const unitSnapshot = await unitCollectionRef
            .orderBy("index", "asc")
            .get();

          let unitCounter = 0;
          for (let unitDoc of unitSnapshot.docs) {
            let unitData = unitDoc.data();
            let unitReference = unitDoc?.ref;
            let isLastUnitOfTheCourse = false;

            if (unitCounter === unitSnapshot.docs.length - 1) {
              isLastUnitOfTheCourse = true;
            }
            await assignUnitToUser(
              db,
              { name: unitData?.name, reference: unitReference },
              currentUserRef,
              isLastUnitOfTheCourse,
              firebase
            );
            unitCounter += 1;
          }

          console.log(
            `Units from course ID ${nextEligibleFinalCourse} assigned to the user successfully.`
          );
          
          return Promise.resolve();
        } else {
          console.error("Failed to retrieve course reference!");
          
        }
      } else {
        console.log(
          "No eligible final assessments available for subject: ",
          subject
        );
        
      }
    }
  } catch (error) {
    console.error("Error assigning final assessment:", error);
    
    return Promise.reject(error);
  }
};


export   const hasTakenFinalAssessmentThisMonth = async (db, userQuestions) => {
  const catalogRef = db.collection("catalog");

  const finalCoursesQuery = catalogRef.where("isFinalAssessment", "==", true);

  const finalCoursesSnapshot = await finalCoursesQuery.get();

  const allFinalCoursesID = finalCoursesSnapshot.docs.map(
    (doc) => doc.ref.id
  );

  const uniqueCourseIDs = new Set();

  // Filter out objects with duplicate courseIDs
  const uniqueObjectsList = userQuestions.filter((obj) => {
    if (!uniqueCourseIDs.has(obj.courseID)) {
      uniqueCourseIDs.add(obj.courseID);
      return true;
    }
    return false;
  });

  const userFinalCoursesData = {};
  for (const obj of uniqueObjectsList) {
    const courseID = obj.courseID;
    if (allFinalCoursesID.includes(courseID)) {
      if (!(courseID in userFinalCoursesData)) {
        userFinalCoursesData[courseID] = obj;
      }
    }
  }
  const currentDate = new Date();

  if (Object.keys(userFinalCoursesData).length === 0) {
    console.log("userFinalCoursesData is empty.");
    return [];
  }

  const courseSubjects = [];

  for (const courseID of Object.keys(userFinalCoursesData)) {
    // console.log("Processing course:", courseID);
    const courseData = userFinalCoursesData[courseID];
    const questionTS = userFinalCoursesData[courseID].tileObject.ts;

    const courseTimestamp = new Date(
      questionTS.seconds * 1000 + questionTS.nanoseconds / 1e6
    );

    const timeDifference = currentDate - courseTimestamp;

    const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000;

    if (timeDifference < thirtyDaysInMillis) {
      console.warn(
        `No new assessments can be assigned for ${courseID} until the end of the month.`
      );
      courseSubjects.push(courseData); // Add courseData to the list
    }
  }

  if (courseSubjects.length > 0) {
    const subjectNames = courseSubjects.map(
      (courseData) => courseData.courseSubject
    );

    console.log("Returning courseSubjects:", subjectNames);
    return subjectNames
  } else {
    console.log("No assessments can be assigned until the end of the month.");
    return [];
  }
};